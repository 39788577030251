import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetAffiliationMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  affiliationId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type SetAffiliationMutation = { __typename?: 'Mutation', setAffiliation?: { __typename?: 'SetAffiliationPayload', successful: boolean } | null };

export const SetAffiliationDocument = gql`
    mutation SetAffiliation($poolId: ID!, $questionGroupIds: [ID!]!, $affiliationId: ID) {
  setAffiliation(
    poolId: $poolId
    questionGroupIds: $questionGroupIds
    affiliationId: $affiliationId
  ) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetAffiliationGQL extends Apollo.Mutation<SetAffiliationMutation, SetAffiliationMutationVariables> {
    document = SetAffiliationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }