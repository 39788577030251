angular.module('examManagement').controller('QuestionGroupsPresortCtrl', [
  '$scope',
  'dimensions',
  'exam',
  '$uibModalInstance',
  'ConfirmMessages',
  'ExamPresort',
  'ConfirmationModal',
  function(
    $scope,
    dimensions,
    exam,
    $uibModalInstance,
    ConfirmMessages,
    ExamPresort,
    ConfirmationModal
  ) {
    var dimension, i, len, updateSecondOptions, updateThirdOptions;

    var randomizationToParameterMap = {
      A: {
        true: { scramblingA: 'byId' },
        false: { scramblingA: 'randomized' }
      },
      B: {
        true: { scramblingA: 'byId', scramblingB: 'inverted' },
        false: { scramblingA: 'randomized', scramblingB: 'randomized' }
      },
      individual: {
        true: { randomize: true },
        false: { randomize: true }
      }
    };

    function getRandomizationParameters() {
      var isOrdered = $scope.selection.orderById;
      var randomization = $scope.selection.randomization;

      return randomizationToParameterMap[randomization][isOrdered];
    }

    updateSecondOptions = function() {
      $scope.options.secondCriterionVisible = $scope.options.secondCriterion.filter(
        function(option) {
          return (
            angular.isUndefined(option.value) ||
            option.value !== $scope.selection.firstCriterion
          );
        }
      );
    };

    updateThirdOptions = function() {
      $scope.options.thirdCriterionVisible = $scope.options.thirdCriterion.filter(
        function(option) {
          return (
            angular.isUndefined(option.value) ||
            (option.value !== $scope.selection.firstCriterion &&
              option.value !== $scope.selection.secondCriterion)
          );
        }
      );
    };

    $scope.I18n = I18n;

    $scope.options = {
      firstCriterion: [
        {
          label: I18n.t(
            'exams.question_group_presort.presort.options.sort_by_question_type'
          ),
          value: {
            type: 'question_type'
          }
        }
      ]
    };

    for (i = 0, len = dimensions.length; i < len; i++) {
      dimension = dimensions[i];
      $scope.options.firstCriterion.push({
        label: dimension.name,
        value: {
          type: 'dimension',
          position: dimension.position
        }
      });
    }

    $scope.options.secondCriterion = $scope.options.firstCriterion.slice(0);
    $scope.options.secondCriterion.splice(0, 0, {
      label: I18n.t('exams.question_group_presort.presort.options.empty'),
      value: void 0
    });
    $scope.options.secondCriterionVisible = $scope.options.secondCriterion;
    $scope.options.thirdCriterion = $scope.options.secondCriterion.slice(0);
    $scope.options.thirdCriterionVisible = $scope.options.thirdCriterion;

    $scope.selection = {
      firstCriterion: $scope.options.firstCriterion[0].value,
      secondCriterion: $scope.options.secondCriterion[0].value,
      thirdCriterion: $scope.options.thirdCriterion[0].value,
      randomization: 'A',
      orderById: false
    };

    $scope.isElectronicExam = exam.medium === 'electronic';

    $scope.$watch('selection.firstCriterion', function() {
      updateSecondOptions();
      updateThirdOptions();
      $scope.selection.secondCriterion = void 0;
      $scope.selection.thirdCriterion = void 0;
    });

    $scope.$watch('selection.secondCriterion', function(newValue) {
      updateThirdOptions();
      $scope.selection.thirdCriterion = void 0;
    });

    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

    $scope.create = function() {
      ConfirmationModal.show(
        I18n.t('exams.presort.confirmation_modal.title'),
        ConfirmMessages.examPresortMessage()
      ).result.then(function() {
        var presortCriteria;
        presortCriteria = {
          exam_id: exam.id,
          firstCriterion: $scope.selection.firstCriterion,
          secondCriterion: $scope.selection.secondCriterion,
          thirdCriterion: $scope.selection.thirdCriterion
        };
        Object.assign(presortCriteria, getRandomizationParameters());
        $uibModalInstance.close(presortCriteria);
      });
    };
  }
]);
