angular.module('common').factory("Selectors::QuestionGroupNavigationList", function() {
  function getIds(object) {
    return (
      (object &&
        object.questionGroupNavigationList &&
        object.questionGroupNavigationList.ids) ||
      []
    );
  }
  return {
    getIds: getIds
  };
});
