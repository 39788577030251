angular.module('examManagement').factory('Actions::ExamQuestionGroupList', function () {
  var actions = {
    SET_COLUMN_DEFINITIONS: 'EXAM_QUESTION_GROUP_LIST_SET_COLUMN_DEFINITIONS',
    UPDATE_COLUMN_DEFINITIONS: 'EXAM_QUESTION_GROUP_LIST_UPDATE_COLUMN_DEFINITIONS',
    SET_COLUMN_VISIBILITY: 'EXAM_QUESTION_GROUP_LIST_SET_COLUMN_VISIBILITY',
    ENABLE_COLUMN: 'EXAM_QUESTION_GROUP_LIST_ENABLE_COLUMN',
    DISABLE_COLUMN: 'EXAM_QUESTION_GROUP_LIST_DISABLE_COLUMN',
    SET_SELECTION: 'EXAM_QUESTION_GROUP_LIST_SET_SELECTION',
    REMOVE_SELECTION: 'EXAM_QUESTION_GROUP_LIST_REMOVE_SELECTION',
    SET_SCRAMBLING: 'EXAM_QUESTION_GROUP_LIST_SET_SCRAMBLING',
    DELETE_EXAM_QUESTION_GROUP: 'EXAM_QUESTION_GROUP_LIST_DELETE_EXAM_QUESTION_GROUP',
    UPDATE_EXAM_QUESTION_GROUPS: 'EXAM_QUESTION_GROUP_LIST_UPDATE_EXAM_QUESTION_GROUPS',
    SET_REQUEST_IN_PROGRESS: 'EXAM_QUESTION_GROUP_LIST_SET_REQUEST_IN_PROGRESS',
    FETCH_EXAM_QUESTION_GROUP_CLUSTERS_REQUEST: 'EXAM_QUESTION_GROUP_FETCH_EXAM_QUESTION_GROUP_CLUSTERS_REQUEST',
    FETCH_EXAM_QUESTION_GROUP_CLUSTERS_SUCCESS: 'EXAM_QUESTION_GROUP_FETCH_EXAM_QUESTION_GROUP_CLUSTERS_SUCCESS'
  };

  var defaultScrambling = 'A';

  function fetchExamQuestionGroupClustersRequest () {
    return {
      type: actions.FETCH_EXAM_QUESTION_GROUP_CLUSTERS_REQUEST
    };
  }

  function fetchExamQuestionGroupClustersSuccess (exam) {
    return {
      type: actions.FETCH_EXAM_QUESTION_GROUP_CLUSTERS_SUCCESS,
      exam: exam,
      scrambling: defaultScrambling
    };
  }

  function setScrambling (scrambling) {
    return {
      type: actions.SET_SCRAMBLING,
      scrambling: scrambling
    };
  }

  function setRequestInProgress (state) {
    return {
      type: actions.SET_REQUEST_IN_PROGRESS,
      requestInProgress: state
    };
  }

  function deleteExamQuestionGroup (examQuestionGroupId) {
    return {
      type: actions.DELETE_EXAM_QUESTION_GROUP,
      examQuestionGroupId: examQuestionGroupId
    };
  }


  function setColumnDefinitions (columns) {
    return {
      type: actions.SET_COLUMN_DEFINITIONS,
      columns: columns
    };
  }

  function updateColumnDefinitions (columns) {
    return {
      type: actions.UPDATE_COLUMN_DEFINITIONS,
      columns: columns
    };
  }

  function setColumnVisibility (column, visible) {
    return {
      type: actions.SET_COLUMN_VISIBILITY,
      column: column,
      visible: visible
    };
  }

  function enableColumn (column) {
    return {
      type: actions.ENABLE_COLUMN,
      column: column
    };
  }

  function disableColumn (column) {
    return {
      type: actions.DISABLE_COLUMN,
      column: column
    };
  }

  function setSelection (id) {
    return {
      type: actions.SET_SELECTION,
      id: id
    };
  }

  function updateExamQuestionGroups (examQuestionGroups) {
    return {
      type: actions.UPDATE_EXAM_QUESTION_GROUPS,
      examQuestionGroups: examQuestionGroups
    };
  }

  function removeSelection () {
    return {
      type: actions.REMOVE_SELECTION
    };
  }

  return Object.assign({
    setColumnDefinitions: setColumnDefinitions,
    updateColumnDefinitions: updateColumnDefinitions,
    setColumnVisibility: setColumnVisibility,
    enableColumn: enableColumn,
    disableColumn: disableColumn,
    setSelection: setSelection,
    removeSelection: removeSelection,
    setScrambling: setScrambling,
    setRequestInProgress: setRequestInProgress,
    deleteExamQuestionGroup: deleteExamQuestionGroup,
    updateExamQuestionGroups: updateExamQuestionGroups,
    fetchExamQuestionGroupClustersRequest: fetchExamQuestionGroupClustersRequest,
    fetchExamQuestionGroupClustersSuccess: fetchExamQuestionGroupClustersSuccess,
  }, actions);
});
