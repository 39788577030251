import { isValidNG2Route } from "../../../routing-utils";

angular.module('main').controller('mainCtrl', [
  '$scope',
  '$window',
  function ($scope, $window) {
    $scope.isNG2Route = isNG2Route;
    function isNG2Route() {
      return isValidNG2Route($window.location.pathname);
    }
  }
])
