import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AssignLabelsMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  labelIdsToAdd: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  labelIdsToRemove: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type AssignLabelsMutation = { __typename?: 'Mutation', assignLabels?: { __typename?: 'AssignLabelsPayload', successful: boolean } | null };

export const AssignLabelsDocument = gql`
    mutation AssignLabels($poolId: ID!, $questionGroupIds: [ID!]!, $labelIdsToAdd: [ID!]!, $labelIdsToRemove: [ID!]!) {
  assignLabels(
    poolId: $poolId
    questionGroupIds: $questionGroupIds
    labelIdsToAdd: $labelIdsToAdd
    labelIdsToRemove: $labelIdsToRemove
  ) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignLabelsGQL extends Apollo.Mutation<AssignLabelsMutation, AssignLabelsMutationVariables> {
    document = AssignLabelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }