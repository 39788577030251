/* eslint-disable unicorn/no-null */
import { Injectable } from '@angular/core';
import { QuestionInput, QuestionType } from 'src/generated/base-types';

@Injectable({
  providedIn: 'root'
})
export class QuestionFactoryService {
  public createQuestion(type: QuestionType): Promise<QuestionInput> {
    switch (type) {
      case QuestionType.A: {
        return this.createQuestionOfTypeA();
      }
      case QuestionType.AMinus: {
        return this.createQuestionOfTypeAMinus();
      }
      case QuestionType.E: {
        return this.createQuestionOfTypeE();
      }
      case QuestionType.KPrime: {
        return this.createQuestionOfTypeKprime();
      }
      case QuestionType.FreeText: {
        return this.createQuestionOfTypeFreeText();
      }
      case QuestionType.LongMenu: {
        return this.createQuestionOfTypeLongMenu();
      }
    }
  }

  public createQuestionOfTypeA(): Promise<QuestionInput> {
    return Promise.resolve({
      score: 1,
      type: QuestionType.A,
      a: {
        content: {},
        responseOptions: [
          { id: '1', content: {} },
          { id: '2', content: {} },
          { id: '3', content: {} },
          { id: '4', content: {} },
          { id: '5', content: {} }
        ]
      }
    });
  }

  public createQuestionOfTypeAMinus(): Promise<QuestionInput> {
    return Promise.resolve({
      score: 1,
      type: QuestionType.AMinus,
      aMinus: {
        content: {},
        responseOptions: [
          { id: '1', content: {} },
          { id: '2', content: {} },
          { id: '3', content: {} },
          { id: '4', content: {} },
          { id: '5', content: {} }
        ]
      }
    });
  }

  public createQuestionOfTypeE(): Promise<QuestionInput> {
    return Promise.resolve({
      score: 1,
      type: QuestionType.E,
      e: {
        statementA: {},
        statementB: {}
      }
    });
  }

  public createQuestionOfTypeKprime(): Promise<QuestionInput> {
    return Promise.resolve({
      score: 1,
      type: QuestionType.KPrime,
      kPrime: {
        content: {},
        solution: [null, null, null, null],
        propositions: [
          { content: {} },
          { content: {} },
          { content: {} },
          { content: {} }
        ]
      }
    });
  }

  public createQuestionOfTypeFreeText(): Promise<QuestionInput> {
    return Promise.resolve({
      score: 1,
      type: QuestionType.FreeText,
      freeText: {
        content: {},
        suggestedNumberOfLines: 0,
        solution: {}
      }
    });
  }

  public createQuestionOfTypeLongMenu(): Promise<QuestionInput> {
    return Promise.resolve({
      score: 1,
      type: QuestionType.LongMenu,
      longMenu: {
        content: {},
        numberOfResponses: 0,
        list: null
      }
    });
  }
}
