angular.module('taskManagement').factory('Actions::TaskQuestionGroupList', function () {
  var actions = {
    SET_COLUMN_DEFINITIONS: 'TASK_QUESTION_GROUP_LIST_SET_COLUMN_DEFINITIONS',
    SET_COLUMN_VISIBILITY: 'TASK_QUESTION_GROUP_LIST_SET_COLUMN_VISIBILITY',
    SET_SELECTION: 'TASK_QUESTION_GROUP_LIST_SET_SELECTION',
    ADD_TO_SELECTION: 'TASK_QUESTION_GROUP_LIST_ADD_TO_SELECTION',
    REMOVE_FROM_SELECTION: 'TASK_QUESTION_GROUP_LIST_REMOVE_FROM_SELECTION',
    SET_ORDER: 'TASK_QUESTION_GROUP_LIST_SET_ORDER',
    FETCH_ITEMS_REQUEST: 'TASK_QUESTION_GROUP_LIST_FETCH_ITEMS_REQUEST',
    FETCH_ITEMS_SUCCESS: 'TASK_QUESTION_GROUP_LIST_FETCH_ITEMS_SUCCESS'
  };

  function setColumnDefinitions (columns) {
    return {
      type: actions.SET_COLUMN_DEFINITIONS,
      columns: columns
    };
  }

  function setColumnVisibility (column, visible) {
    return {
      type: actions.SET_COLUMN_VISIBILITY,
      column: column,
      visible: visible
    };
  }

  function setSelection (ids) {
    return {
      type: actions.SET_SELECTION,
      ids: ids
    };
  }

  function addToSelection (ids) {
    return {
      type: actions.ADD_TO_SELECTION,
      ids: ids
    };
  }

  function removeFromSelection (id) {
    return {
      type: actions.REMOVE_FROM_SELECTION,
      id: id
    };
  }

  function setOrder (order) {
    return {
      type: actions.SET_ORDER,
      order: order
    };
  }

  function fetchItemsRequest () {
    return {
      type: actions.FETCH_ITEMS_REQUEST
    };
  }

  function fetchItemsSuccess (items) {
    return {
      type: actions.FETCH_ITEMS_SUCCESS,
      items: items
    };
  }

  return Object.assign({
    setColumnDefinitions: setColumnDefinitions,
    setColumnVisibility: setColumnVisibility,
    setSelection: setSelection,
    addToSelection: addToSelection,
    removeFromSelection: removeFromSelection,
    setOrder: setOrder,
    fetchItemsRequest: fetchItemsRequest,
    fetchItemsSuccess: fetchItemsSuccess
  }, actions);
});
