import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';

export const NG_MODAL_WINDOW_CLASS = 'ng-bootstrap-modal';

export const NG_MODAL_DEFAULT_OPTIONS: NgbModalOptions = {
  windowClass: NG_MODAL_WINDOW_CLASS
};

export const NG_MODAL_LG_OPTIONS: NgbModalOptions = {
  ...NG_MODAL_DEFAULT_OPTIONS,
  size: 'lg'
};
