<ng-container *ngIf="questionGroupStatistics.length === 0">
  <h5>
    {{ 'common.question_group_details.statistics.no_entries' | translate }}
  </h5>
</ng-container>
<ng-container *ngIf="questionGroupStatistics.length > 0">
  <h5>
    {{ 'common.question_group_details.statistics.overview.title' | translate }}
  </h5>
  <table class="table table-selectable">
    <thead>
      <tr>
        <th>
          {{
            'common.question_group_details.statistics.overview.exam' | translate
          }}
        </th>
        <th>
          {{
            'common.question_group_details.statistics.overview.exam_date'
              | translate
          }}
        </th>
        <th>
          {{ 'common.question_group_details.statistics.table.n' | translate }}
        </th>

        <th>
          {{
            'common.question_group_details.statistics.table.p_partial'
              | translate
          }}
          <button
            class="btn btn-secondary xxs"
            [ngbPopover]="
              'common.question_group_details.statistics.overview.hint.p_partial'
                | translate
            "
            container="body"
            placement="top"
            triggers="mouseenter:mouseleave"
            [animation]="false"
          >
            <i class="fa fa-info"></i>
          </button>
        </th>

        <th>
          {{
            'common.question_group_details.statistics.table.p_dichotomous'
              | translate
          }}
          <button
            class="btn btn-secondary xxs"
            [ngbPopover]="
              'common.question_group_details.statistics.overview.hint.p_dichotomous'
                | translate
            "
            container="body"
            placement="top"
            triggers="mouseenter:mouseleave"
            [animation]="false"
          >
            <i class="fa fa-info"></i>
          </button>
        </th>

        <th>
          {{ 'common.question_group_details.statistics.table.r' | translate }}
        </th>

        <th>
          {{ 'common.question_group_details.statistics.table.lne' | translate }}
        </th>
        <th>
          {{ 'common.question_group_details.statistics.table.xi' | translate }}
        </th>
        <th>
          {{
            'common.question_group_details.statistics.table.eliminated'
              | translate
          }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container
        *ngFor="
          let questionGroupStatistic of questionGroupStatistics;
          let questionGroupIndex = index
        "
      >
        <tr (click)="selectQuestionGroup(questionGroupIndex)">
          <td>{{ questionGroupStatistic.exam.name }}</td>
          <td>{{ questionGroupStatistic.exam.date | date : 'dd.MM.yyyy' }}</td>
          <td>{{ questionGroupStatistic.n | fixedNumberRange : 0 }}</td>
          <td>
            {{ questionGroupStatistic.p | percentages | fixedNumberRange : 1 }}
          </td>
          <td>
            {{
              questionGroupStatistic.pDichotomous
                | percentages
                | fixedNumberRange : 1
            }}
          </td>
          <td>{{ questionGroupStatistic.r | fixedNumberRange : 2 }}</td>
          <td>{{ questionGroupStatistic.lne | fixedNumberRange : 3 }}</td>
          <td>
            [{{ questionGroupStatistic.xi1 | fixedNumberRange : 3 }},
            {{ questionGroupStatistic.xi2 | fixedNumberRange : 3 }}]
          </td>
          <td>{{ questionGroupStatistic.eliminated | yesOrBlank }}</td>
        </tr>
        <tr
          *ngFor="
            let questionStatistic of questionGroupStatistic.questions;
            let questionIndex = index
          "
          [hidden]="questionGroupIndex !== selectedQuestionGroup"
          (click)="selectQuestion(questionIndex)"
          [ngClass]="{
            selected:
              questionGroupIndex === selectedQuestionGroup &&
              questionIndex === selectedQuestion
          }"
        >
          <td>
            {{
              'common.question_group_details.statistics.table.question'
                | translate : { number: questionIndex + 1 }
            }}
          </td>
          <td></td>
          <td>
            {{
              questionStatistic.total.n !== null &&
              questionStatistic.total.n !== undefined
                ? (questionStatistic.total.n | fixedNumber : 0)
                : '-'
            }}
          </td>
          <td>
            {{
              questionStatistic.total.p !== null &&
              questionStatistic.total.p !== undefined
                ? (questionStatistic.total.p | percentage | fixedNumber : 1)
                : '-'
            }}
          </td>
          <td>
            {{
              questionStatistic.total.pDichotomous !== null &&
              questionStatistic.total.pDichotomous !== undefined
                ? (questionStatistic.total.pDichotomous
                  | percentage
                  | fixedNumber : 1)
                : '-'
            }}
          </td>
          <td>
            {{
              questionStatistic.total.r !== null &&
              questionStatistic.total.r !== undefined
                ? (questionStatistic.total.r | fixedNumber : 2)
                : '-'
            }}
          </td>
          <td>
            {{
              questionStatistic.total.lne !== null &&
              questionStatistic.total.lne !== undefined
                ? (questionStatistic.total.lne | fixedNumber : 3)
                : '-'
            }}
          </td>
          <td>
            [{{
              questionStatistic.total.xi1 !== null &&
              questionStatistic.total.xi1 !== undefined
                ? (questionStatistic.total.xi1 | fixedNumber : 3)
                : '-'
            }},
            {{
              questionStatistic.total.xi2 !== null &&
              questionStatistic.total.xi2 !== undefined
                ? (questionStatistic.total.xi2 | fixedNumber : 3)
                : '-'
            }}]
          </td>
          <td>{{ questionStatistic.total.eliminated | yesOrBlank }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="currentQuestionStatistics !== undefined">
    <hr />
    <co-subgroup-statistics
      [statistics]="currentQuestionStatistics"
    ></co-subgroup-statistics>
  </ng-container>
  <ng-container [ngSwitch]="currentQuestionStatistics?.total?.__typename">
    <ng-container *ngSwitchCase="'TotalStatisticTypeA'">
      <hr />
      <co-type-a-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></co-type-a-statistics>
    </ng-container>
    <ng-container *ngSwitchCase="'TotalStatisticTypeAMinus'">
      <hr />
      <co-type-a-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></co-type-a-statistics>
    </ng-container>
    <ng-container *ngSwitchCase="'TotalStatisticTypeKprime'">
      <hr />
      <co-type-kprime-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></co-type-kprime-statistics>
    </ng-container>
    <ng-container *ngSwitchCase="'TotalStatisticTypeE'">
      <hr />
      <co-type-e-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></co-type-e-statistics>
    </ng-container>
    <ng-container *ngSwitchCase="'TotalStatisticTypeFreeText'">
      <hr />
      <co-type-free-text-statistics
        [statistics]="$any(currentQuestionStatistics?.total)"
      ></co-type-free-text-statistics>
    </ng-container>
  </ng-container>
</ng-container>
