angular.module('examManagement').factory('Actions::ExamList', function () {
  var actions = {
    FETCH_EXAMS_REQUEST: 'EXAMS_LIST_FETCH_EXAMS_REQUEST',
    FETCH_EXAMS_SUCCESS: 'EXAMS_LIST_FETCH_EXAMS_SUCCESS',
    DELETE_EXAM: 'EXAMS_LIST_DELETE_EXAM',
    ADD_EXAM: 'EXAMS_LIST_ADD_EXAM',
    SET_EXAM: 'EXAMS_LIST_SET_EXAM',
    SET_SELECTION: 'EXAMS_LIST_SET_SELECTION',
    REMOVE_SELECTION: 'EXAMS_LIST_REMOVE_SELECTION'
  };

  function fetchExamsRequest () {
    return {
      type: actions.FETCH_EXAMS_REQUEST
    };
  }

  function fetchExamsSuccess (exams) {
    return {
      type: actions.FETCH_EXAMS_SUCCESS,
      exams: exams
    };
  }

  function deleteExam (examId) {
    return {
      type: actions.DELETE_EXAM,
      examId: examId
    };
  }

  function addExam (exam) {
    return {
      type: actions.ADD_EXAM,
      exam: exam
    };
  }

  function setExam(exam) {
    return {
      type: actions.SET_EXAM,
      exam: exam
    };
  }

  function setSelection (id) {
    return {
      type: actions.SET_SELECTION,
      id: id
    };
  }

  function removeSelection () {
    return {
      type: actions.REMOVE_SELECTION
    };
  }

  return Object.assign({
    fetchExamsRequest: fetchExamsRequest,
    fetchExamsSuccess: fetchExamsSuccess,
    deleteExam: deleteExam,
    addExam: addExam,
    setExam: setExam,
    setSelection: setSelection,
    removeSelection: removeSelection
  }, actions);
});
