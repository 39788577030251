import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CommonModule as ManagerCommonModule } from '../common/common.module';
import './angularjs/module';
import { ExamDetailsComponent } from './components/exam-details.component';
import './downgraded';
import { ExamQuestionGroupDetailsComponent } from './components/exam-question-group-details/exam-question-group-details.component';
import { ExamQuestionGroupExportModalComponent } from './modals/exam-question-group-export-modal/exam-question-group-export-modal.component';
import { ExamListState } from './state/exam-list.state';

@NgModule({
  declarations: [
    ExamDetailsComponent,
    ExamQuestionGroupExportModalComponent,
    ExamQuestionGroupDetailsComponent
  ],
  imports: [ManagerCommonModule, NgxsModule.forFeature([ExamListState])]
})
export class ExamManagementModule {}
