/* eslint-disable camelcase */
import { Store } from '@ngxs/store';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Editor, EditorOptions } from 'tinymce';
import { isVoid } from '../../common/utils/type-guards/voidable';
import { SetUploadingImage } from '../state/form.actions';
import {
  LATEX_PLUGIN_CSS_CLASS,
  LATEX_PLUGIN_CSS,
  LATEX_PLUGIN_VALID_ELEMENTS
} from './plugins/latex/latex.plugin';
import { RESOLUTION_INFO_PLUGIN_CSS } from './resolution-info.plugin';
import { SPECIAL_CHARS } from './special-chars';

/*
https://www.tiny.cloud/docs/tinymce/6/basic-setup/
https://www.tiny.cloud/docs/tinymce/6/upload-images/
https://www.tiny.cloud/docs/tinymce/6/editor-important-options/#init_instance_callback
*/

/* https://www.tiny.cloud/docs/tinymce/6/content-filtering/#valid_elements */
export const TINYMCE_VALID_ELEMENTS_BASE =
  'p,b/strong,i/em,sup,sub,br,@[*],img[*],table,tr,th,td,caption,colgroup,thead,tfoot,tbody';

/* https://www.tiny.cloud/docs/tinymce/6/content-filtering/#valid_styles */
const TINYMCE_VALID_STYLES = {
  '*': '' // remove all styles on any element (we use elements (<b> etc.) for styling)
};

export const ASSET_ENDPOINT = '/api/v1/assets';

// CSS changes to match look and feel of TinyMCE to CKEditor
// and preview generated by Apache FOP
const CUSTOM_EDITOR_CSS = `
img {
  vertical-align: middle
}

body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
}
p {
  margin: 0
}
table:not([cellpadding]) td {
  display: table-cell;
  vertical-align: top;
  padding: 2px 10px;
}
table:not([cellpadding]) th {
  display: table-cell;
  vertical-align: top;
  padding: 2px 10px;
}
`;

export const TINY_MCE_CONFIG: Partial<EditorOptions> = {
  base_url: '/tinymce', // Root for resources
  suffix: '.min', // Root for resources
  init_instance_callback: function (editor: Editor) {
    editor.getContainer().className += ' with-border';
  },
  // we load in the image handler in the rich editor component
  // as we want to set the state to the store when an image is being uploaded
  images_upload_handler: undefined,
  menubar: false,
  forced_root_block: 'p',
  newline_behavior: 'linebreak',
  skin: 'tinymce-5',
  valid_elements: TINYMCE_VALID_ELEMENTS_BASE,
  valid_styles: TINYMCE_VALID_STYLES,
  noneditable_class: LATEX_PLUGIN_CSS_CLASS,
  extended_valid_elements: LATEX_PLUGIN_VALID_ELEMENTS,
  plugins: [
    'autoresize',
    'table',
    'nonbreaking',
    'visualchars',
    'pagebreak',
    'charmap',
    'image',
    'iml-image',
    'iml-resolution-info',
    'latex'
  ],
  contextmenu: ['table'],
  paste_data_images: true,
  relative_urls: false,
  content_style: `
  ${LATEX_PLUGIN_CSS}
  ${RESOLUTION_INFO_PLUGIN_CSS}
  ${CUSTOM_EDITOR_CSS}
  `,
  toolbar:
    'copy cut paste pastetext | undo redo | bold italic | subscript superscript | charmap | nonbreaking visualchars | image | table | pagebreak | latex',
  charmap: SPECIAL_CHARS,
  statusbar: false,
  autoresize_bottom_margin: 0,
  object_resizing: 'img',
  table_advtab: false,
  table_style_by_css: false,
  setup(editor: Editor) {
    editor.on('GetContent', function (event: { content: string }) {
      event.content = cleanUpResolutionInfoCSS(event.content);
    });
  },
  pagebreak_separator: '<hr>',
  nonbreaking_wrap: false
};

// taken from https://www.tiny.cloud/docs/tinymce/6/upload-images/#example-using-images_upload_handler
export const handleImageUploadWrapper = (store: Store) => {
  return (
    blobInfo: { blob: () => Blob; filename: () => string },
    progress: (percent: number) => void
  ) =>
    new Promise((resolve, reject) => {
      store.dispatch(new SetUploadingImage(true));
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', ASSET_ENDPOINT);

      xhr.upload.addEventListener('progress', e => {
        progress((e.loaded / e.total) * 100);
      });

      xhr.addEventListener('load', () => {
        if (xhr.status === 403) {
          reject({ message: 'HTTP Error: ' + xhr.status, remove: true });

          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject('HTTP Error: ' + xhr.status);

          return;
        }

        const json = JSON.parse(xhr.responseText);

        if (isVoid(json) || typeof json.location !== 'string') {
          reject('Invalid JSON: ' + xhr.responseText);

          return;
        }

        store.dispatch(new SetUploadingImage(false));

        resolve(json.location);
      });

      const formData = new FormData();
      formData.append('asset', blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    });
};

function cleanUpResolutionInfoCSS(content: string): string {
  return content
    .replaceAll('class="resolution-marker resolution-marker--valid"', '')
    .replaceAll('class="resolution-marker resolution-marker--invalid"', '');
}
