angular.module('common').directive('examPopover', [
  '$window',
  '$compile',
  'QuestionGroupActions',
  'Repositories::Exam',
  function($window, $compile, QuestionGroupActions, Repository) {
    return {
      restrict: 'E',
      scope: 'inherit',
      template: '<i class="fa fa-caret-down fa-lg is-clickable"></i>',
      replace: false,
      scope: {
        questionGroup: '='
      },
      link: function($scope, $elm) {
        $scope.I18n = I18n;

        $scope.examSelected = function(exam) {
          return $scope.questionGroup.future_exams.some(function(e) {
            return e.id == exam.id;
          });
        };

        $scope.selectExam = function(exam) {
          if ($scope.examSelected(exam)) {
            QuestionGroupActions.assignExams([$scope.questionGroup], [], [exam.id]);
          } else {
            QuestionGroupActions.assignExams([$scope.questionGroup], [exam.id], []);
          }
        };

        var content = function() {
          return $compile(
            '<div>' +
              '<span ng-if="exams.length === 0">{{::I18n.t("exam_popover.no_exams_available")}}</span>' +
              '<label class="checkbox" ng-repeat="exam in exams track by exam.id">' +
              '<input type="checkbox" ng-checked="examSelected(exam)" ng-click="selectExam(exam)" /> {{exam.name}}' +
              '</label>' +
              '</div>'
          )($scope);
        };

        $elm
          .popover({
            html: true,
            animation: false,
            placement: 'bottom',
            content: content
          })
          .on('show.bs.popover', function() {
            Repository.getUnlockedExams().then(function(exams) {
              $scope.exams = exams;
            });
          })
          .bind('click', function(event) {
            angular
              .element('exam-popover')
              .not($elm)
              .popover('hide');
            $scope.$digest();
          });
      }
    };
  }
]);
