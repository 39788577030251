import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { ReplaceListConfirmModalComponent } from './components/replace-list-confirm-modal/replace-list-confirm-modal.component';

angular
  .module('questionForm')
  .directive(
    'qfReplaceListConfirmModal',
    downgradeComponent({ component: ReplaceListConfirmModalComponent })
  );
