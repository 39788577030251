angular.module('common').directive('pvSelect', [
  '$parse',
  function($parse) {
    return {
      restrict: 'E',
      template: require('./pv_select.html'),
      scope: {
        sourceOptions: '=options',
        emptyOption: '@',
        tabindex: '=',
        disabled: '=',
        mandatory: '=',
        modelStatement: '@ngModel'
      },
      link: function($scope, $elm, $attrs) {
        function setValueToModel(value) {
          if (parentModelGetter($scope.$parent) !== undefined) {
            modelSetter($scope.$parent, value);
          }
        }

        function updateSelectedOption(value) {
          $scope.selectedOption = $scope.options.find(function(option) {
            return option.value == value;
          });
          if ($scope.selectedOption === undefined && $scope.options.length > 0) {
            setValueToModel($scope.options[0].value);
          }
        }

        var modelGetter = $parse($scope.modelStatement),
          modelSetter = modelGetter.assign,
          parentModelStatement = $scope.modelStatement.substring(
            0,
            $scope.modelStatement.lastIndexOf('.')
          ),
          parentModelGetter = function() {
            return {};
          };

        if (parentModelStatement.length > 0) {
          parentModelGetter = $parse(parentModelStatement);
        }

        $elm.find('.pv-select__button').attr('tabindex', $scope.tabindex);
        $elm.removeAttr('tabindex');
        $scope.options = [];

        $scope.$watch('sourceOptions', function(value) {
          $scope.options = (value || []).slice(0);

          if (
            $scope.emptyOption !== undefined &&
            !($scope.mandatory && $scope.options.length == 1)
          ) {
            $scope.options.unshift({
              value: null,
              label: $scope.emptyOption
            });
          }

          updateSelectedOption(modelGetter($scope.$parent));
        });

        $scope.$watch(
          function() {
            return modelGetter($scope.$parent);
          },
          function(value) {
            updateSelectedOption(value);
          }
        );

        $scope.isOpen = false;

        $scope.selectOption = function(option) {
          setValueToModel(option.value);
          $scope.isOpen = false;
          $elm.find('.pv-select__button').focus();
          $elm.trigger('change');
          return false;
        };
      }
    };
  }
]);
