import { TranslateService } from '@ngx-translate/core';
import {
  QuestionGroupType,
  QuestionSeries,
  SingleQuestion
} from '../../../generated/base-types';
import { ExportQuestionGroupFragment } from '../../question-management/components/question-group-export-modal/question-group-export.fragment.generated';
import { Voidable } from '../utils/type-guards/voidable';

export interface ExportColumnDefinition<T> {
  value: (object: T) => string;
  name: string;
}

function stringOrEmptyValue(value: string | Voidable): string {
  return value ?? '';
}

function numberValue(value: number): string {
  return '' + value;
}

function numberOrEmptyValue(value: number | Voidable): string {
  if (value !== null && value !== undefined) {
    return numberValue(value);
  }

  return '';
}

function percentageOrEmptyValue(value: number | Voidable): string {
  if (value !== null && value !== undefined) {
    return (value * 100).toFixed(1);
  }

  return '';
}

function booleanValue(value: boolean, translate: TranslateService): string {
  return value
    ? translate.instant('common.true')
    : translate.instant('common.false');
}

export function commonColumnDefinitions<T>(
  translate: TranslateService,
  questionGroupGetter: (o: T) => ExportQuestionGroupFragment
): ExportColumnDefinition<T>[] {
  return [
    {
      name: translate.instant('common.question_list.columns.id'),
      value: (o: T): string => stringOrEmptyValue(questionGroupGetter(o).id)
    },
    {
      name: translate.instant('common.question_list.columns.legacy_id'),
      value: (o: T): string =>
        stringOrEmptyValue(questionGroupGetter(o).legacyId)
    },
    {
      name: translate.instant('common.question_list.columns.sequential_number'),
      value: (o: T): string =>
        numberValue(questionGroupGetter(o).sequentialNumber)
    },
    {
      name: translate.instant(
        'common.question_list.columns.literary_reference'
      ),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions.map(q => q?.literaryReference).join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.source_language'),
      value: (o: T): string => questionGroupGetter(o).sourceLanguage
    },
    {
      name: translate.instant('common.question_list.columns.title'),
      value: (o: T): string => stringOrEmptyValue(questionGroupGetter(o).title)
    },
    {
      name: translate.instant('common.question_list.columns.supervisor'),
      value: (o: T): string =>
        stringOrEmptyValue(questionGroupGetter(o).supervisor)
    },
    {
      name: translate.instant('common.question_list.columns.affiliation'),
      value: (o: T): string =>
        stringOrEmptyValue(questionGroupGetter(o).affiliation?.name)
    },
    {
      name: translate.instant(
        'common.question_list.columns.content_validation_state_de'
      ),
      value: (o: T): string =>
        questionGroupGetter(o).contentValidation.de.toLowerCase()
    },
    {
      name: translate.instant(
        'common.question_list.columns.content_validation_state_fr'
      ),
      value: (o: T): string =>
        questionGroupGetter(o).contentValidation.fr.toLowerCase()
    },
    {
      name: translate.instant(
        'common.question_list.columns.content_validation_state_it'
      ),
      value: (o: T): string =>
        questionGroupGetter(o).contentValidation.it.toLowerCase()
    },
    {
      name: translate.instant(
        'common.question_list.columns.content_validation_state_en'
      ),
      value: (o: T): string =>
        questionGroupGetter(o).contentValidation.en.toLowerCase()
    },
    {
      name: translate.instant(
        'common.question_list.columns.structurally_complete'
      ),
      value: (o: T): string =>
        booleanValue(
          questionGroupGetter(o).contentValidation.structurallyComplete,
          translate
        )
    },
    {
      name: translate.instant('common.question_list.columns.type'),
      value: (o: T): string =>
        translate.instant(
          'activerecord.choices.question_group.type.' +
            questionGroupGetter(o).type
        )
    },
    {
      name: translate.instant('common.question_list.columns.question_types'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions
          .map(q => translate.instant('questions.type.' + q?.type))
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.score'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return numberValue(
          questions
            .map(q => (q?.score === undefined ? 0 : q?.score))
            .reduce((a, b) => a + b, 0)
        );
      }
    },
    {
      name: translate.instant('common.question_list.columns.has_images'),
      value: (o: T): string =>
        booleanValue(questionGroupGetter(o).hasImages, translate)
    },
    {
      name: translate.instant('common.question_list.columns.has_videos'),
      value: (o: T): string =>
        booleanValue(questionGroupGetter(o).hasVideos, translate)
    },
    {
      name: translate.instant('common.question_list.columns.category_0'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions
          .map(q => q?.category0?.name)
          .filter(Boolean)
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.category_1'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions
          .map(q => q?.category1?.name)
          .filter(Boolean)
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.category_2'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions
          .map(q => q?.category2?.name)
          .filter(Boolean)
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.category_3'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions
          .map(q => q?.category3?.name)
          .filter(Boolean)
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.category_4'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions
          .map(q => q?.category4?.name)
          .filter(Boolean)
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.category_5'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions
          .map(q => q?.category5?.name)
          .filter(Boolean)
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.category_6'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions
          .map(q => q?.category6?.name)
          .filter(Boolean)
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.category_7'),
      value: (o: T): string => {
        const questionGroup = questionGroupGetter(o);
        const questions =
          questionGroup.type === QuestionGroupType.Single
            ? [(questionGroup as SingleQuestion).question]
            : (questionGroup as QuestionSeries).questions || [];

        return questions
          .map(q => q?.category7?.name)
          .filter(Boolean)
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.created_at'),
      value: (o: T): string => questionGroupGetter(o).createdAt
    },
    {
      name: translate.instant('common.question_list.columns.updated_at'),
      value: (o: T): string => questionGroupGetter(o).updatedAt
    },
    {
      name: translate.instant('common.question_list.columns.editor'),
      value: (o: T): string =>
        stringOrEmptyValue(
          questionGroupGetter(o).editor &&
            `${questionGroupGetter(o).editor?.firstName} ${
              questionGroupGetter(o).editor?.lastName
            }`
        )
    },
    {
      name: translate.instant('common.question_list.columns.author'),
      value: (o: T): string => stringOrEmptyValue(questionGroupGetter(o).author)
    },
    {
      name: translate.instant('common.question_list.columns.number_of_usages'),
      value: (o: T): string =>
        numberValue(questionGroupGetter(o).numberOfUsages)
    },
    {
      name: translate.instant('common.question_list.columns.exams'),
      value: (o: T): string => {
        return questionGroupGetter(o)
          .pastExams.map(
            exam => exam.name + ' (' + exam.id + ', ' + exam.date + ')'
          )
          .join(', ');
      }
    },
    {
      name: translate.instant('common.question_list.columns.last_usage'),
      value: (o: T): string =>
        stringOrEmptyValue(questionGroupGetter(o).lastUsage)
    },
    {
      name: translate.instant('common.question_list.columns.latest_statistic'),
      value: (o: T): string =>
        numberOrEmptyValue(questionGroupGetter(o).latestStatistic)
    },
    {
      name: translate.instant(
        'common.question_list.columns.cache_last_usage_n'
      ),
      value: (o: T): string =>
        numberOrEmptyValue(questionGroupGetter(o).cacheLastUsageN)
    },
    {
      name: translate.instant(
        'common.question_list.columns.cache_last_usage_p'
      ),
      value: (o: T): string =>
        percentageOrEmptyValue(questionGroupGetter(o).cacheLastUsageP)
    },
    {
      name: translate.instant(
        'common.question_list.columns.cache_last_usage_r'
      ),
      value: (o: T): string =>
        numberOrEmptyValue(questionGroupGetter(o).cacheLastUsageR)
    },
    {
      name: translate.instant(
        'common.question_list.columns.cache_last_usage_eliminated'
      ),
      value: (o: T): string =>
        booleanValue(
          questionGroupGetter(o).cacheLastUsageEliminated ?? false,
          translate
        )
    },
    {
      name: translate.instant('common.question_list.columns.exam_ids'),
      value: (o: T): string =>
        questionGroupGetter(o)
          .futureExams.map(exam => exam.id)
          .join(',')
    },
    {
      name: translate.instant('common.question_list.columns.exam_names'),
      value: (o: T): string =>
        questionGroupGetter(o)
          .futureExams.map(exam => exam.name)
          .join(',')
    },
    {
      name: translate.instant('common.question_list.columns.labels'),
      value: (o: T): string =>
        questionGroupGetter(o)
          .labels.map(label => label.name)
          .join(',')
    },
    {
      name: translate.instant('common.question_list.columns.label_ids'),
      value: (o: T): string =>
        questionGroupGetter(o)
          .labels.map(label => label.id)
          .join(',')
    },
    {
      name: translate.instant('common.question_list.columns.revision_status'),
      value: (o: T): string =>
        // eslint-disable-next-line camelcase
        stringOrEmptyValue(questionGroupGetter(o).revisionStatus?.shortName)
    },
    {
      name: translate.instant('common.question_list.columns.revision_year'),
      value: (o: T): string =>
        numberOrEmptyValue(questionGroupGetter(o).revisionYear)
    },
    {
      name: translate.instant('common.question_list.columns.has_active_tasks'),
      value: (o: T): string =>
        booleanValue(questionGroupGetter(o).hasActiveTasks, translate)
    },
    {
      name: translate.instant(
        'common.question_list.columns.content_changed_since_last_usage'
      ),
      value: (o: T): string =>
        booleanValue(
          questionGroupGetter(o).contentChangedSinceLastUsage,
          translate
        )
    },
    {
      name: translate.instant(
        'common.question_list.columns.metadata_changed_since_last_usage'
      ),
      value: (o: T): string =>
        booleanValue(
          questionGroupGetter(o).metadataChangedSinceLastUsage,
          translate
        )
    }
  ];
}
