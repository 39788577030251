angular.module('common').factory('OrderedList', ['$cookies', function ($cookies) {
  var defaultOrderingColumn = 'sequential_number',
  OrderedList = {
    setOrdering: function (index, column, ascending) {
      if (this.order === undefined) {
        this.order = [];
      }

      if (index > 0 && (column === null || column === undefined)) {
        this.order.length = index;
        return;
      }
      if (column === null || column === undefined) {
        column = defaultOrderingColumn;
      }
      if (index > this.order.length) {
        throw 'Must set the ordering of column ' + (index - 1) + ' first';
      }

      this.order[index] = {name: column, ascending: ascending};
      this.requestOptions['orderBy[]'] = this.getOrderAsParameter();
      this._saveOrdering();
    },

    _saveOrdering: function () {
      $cookies.putObject(this.storagePrefix + '-order', this.order);
    },

    getOrder: function () {
      return this.order;
    },

    getOrderAsParameter: function () {
      return this.order.map(function (spec) {
        return spec.name + '.' + (spec.ascending ? 'asc' : 'desc');
      });
    }
  };

  return {
    extend: function (baseObject, storagePrefix) {
      var order = $cookies.getObject(storagePrefix + '-order') || [];

      angular.extend(Object.getPrototypeOf(baseObject), OrderedList);
      baseObject.storagePrefix = storagePrefix;

      if (order.length === 0) {
        order.push({name: defaultOrderingColumn, ascending: false});
      }
      order.forEach(function (spec, index) {
        baseObject.setOrdering(index, spec.name, spec.ascending);
      });
    }
  };
}]);
