import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  InputMaybe,
  QuestionType,
  Scalars
} from '../../../generated/base-types';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../common/utils/ng-bootstrap-modal';
import { isDefined } from '../../common/utils/type-guards/is-defined';
import { ChangeTypeModalComponent } from '../change-type-modal/change-type-modal.component';

@Component({
  selector: 'qf-change-type-modal-toggle',
  templateUrl: './change-type-modal-toggle.component.html'
})
export class ChangeTypeModalToggleComponent {
  @Input() public questionId: Scalars['ID'];
  @Input() public language: string;
  @Input() public text: string;
  public get questionType(): QuestionType {
    return this._questionType;
  }
  @Input()
  public set questionType(value: InputMaybe<QuestionType> | undefined) {
    if (isDefined(value)) {
      this._questionType = value;
    }
  }

  private _questionType: QuestionType;

  constructor(private readonly ngbModal: NgbModal) {}

  public openChangeTypeModal(): Promise<void> {
    const ref = this.ngbModal.open(
      ChangeTypeModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );
    ref.componentInstance.modalInstance = ref;
    ref.componentInstance.questionId = this.questionId;
    ref.componentInstance.language = this.language;

    return ref.result
      .then((result: QuestionType) => {
        this._questionType = result;
      })
      .catch(() => {
        // modal dismissed
      });
  }
}
