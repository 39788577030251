<ng-container *ngIf="pools$ | async as pools">
  <p-dropdown
    [placeholder]="'common.messages.please_select' | translate"
    [options]="pools"
    [optionLabel]="'name'"
    [optionValue]="'id'"
    [filter]="true"
    [filterBy]="'name'"
    [(ngModel)]="selectedPoolId"
    (ngModelChange)="onPoolChange($event)"
    [showClear]="true"
    [dropdownIcon]="
      pools.length === 0 ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'
    "
  >
  </p-dropdown>
</ng-container>
