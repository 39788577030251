import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const requireOneOfValidatorFactory = (
  ...controlNames: string[]
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = controlNames.some(name => Boolean(control.get(name)?.value));

    return valid ? null : { requireOneOf: true }; // eslint-disable-line unicorn/no-null -- null is the correct return value for a validator
  };
};
