import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { resetTimeToZero } from './date';

export const dateGteValidatorFactory = (
  compareTo: Date | string,
  validationKey = 'mustBeAtOrAfterDate'
): ValidatorFn => {
  const compareToDate = resetTimeToZero(compareTo);

  return (control: AbstractControl): ValidationErrors | null => {
    const date = new Date(control.value);

    return date < compareToDate
      ? { [validationKey]: { date: compareToDate } }
      : null; // eslint-disable-line unicorn/no-null -- null is the correct return value for a validator
  };
};

export const gteTodayValidator = dateGteValidatorFactory(
  new Date(),
  'mustBeAtOrAfterToday'
);
