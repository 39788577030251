import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, catchError, map, of, switchMap, take } from 'rxjs';
import { LoadPool } from '../../state/pool-details/form/pool-form.actions';
import { PoolFormState } from '../../state/pool-details/form/pool-form.state';

@Injectable({ providedIn: 'root' })
export class PoolGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> {
    const id = route.params.id as string;
    if (!id) return of(false);

    return this.store.dispatch(new LoadPool(id)).pipe(
      switchMap(() => this.store.select(PoolFormState.requestState)),
      take(1),
      map(requestState => {
        return requestState === 'success' ? true : this.poolsUrlTree();
      }),
      catchError(() => {
        return of(this.poolsUrlTree());
      })
    );
  }

  private poolsUrlTree(): UrlTree {
    return this.router.createUrlTree(['/newadmin/pools']);
  }
}
