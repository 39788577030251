import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { ExamQuestionGroupDetailsComponent } from '../components/exam-question-group-details/exam-question-group-details.component';

angular.module('examManagement').directive(
  'emExamQuestionGroupDetails',
  downgradeComponent({
    component: ExamQuestionGroupDetailsComponent,
    inputs: ['examId']
  })
);
