import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QuestionGroupType } from '../../../../generated/base-types';
import { AssetService, Metadata } from '../../../services/asset.service';
import { ExamQuestionGroupDetailsGQL } from './exam-question-groups.generated';

@Component({
  selector: 'em-exam-question-group-details',
  templateUrl: './exam-question-group-details.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ExamQuestionGroupDetailsComponent {
  @Input()
  public set examId(value: string | undefined) {
    if (value === undefined) {
      return;
    }
    this.fetchDetails(value).then();
  }

  public singleQuestionsCount = 0;
  public questionSeriesCount = 0;
  public pointsCount = 0;
  public assetMbSize = 0;
  public loading = true;

  constructor(
    private readonly examQuestionGroupDetailsGQL: ExamQuestionGroupDetailsGQL,
    private readonly assetService: AssetService
  ) {}

  private async fetchDetails(examId: string): Promise<void> {
    this.loading = true;

    const result = await firstValueFrom(
      this.examQuestionGroupDetailsGQL.fetch({ examId })
    );

    const { questionGroups, imageAssets, videoAssets } = result.data.exam;
    this.singleQuestionsCount = questionGroups.filter(
      qg => qg.type === QuestionGroupType.Single
    ).length;
    this.questionSeriesCount =
      questionGroups.length - this.singleQuestionsCount;
    this.pointsCount = questionGroups.reduce((acc, qg) => {
      return qg.totalScore + acc;
    }, 0);
    const assetIds = [...imageAssets, ...videoAssets];
    this.loading = false;

    this.assetMbSize = await this.getTotalAssetMbSize(assetIds);
  }

  private async getTotalAssetMbSize(assetIds: string[]): Promise<number> {
    if (assetIds.length === 0) {
      return 0;
    }

    const metadata = await this.assetService.getMultiMetadata<Metadata>(
      assetIds
    );
    const assetByteSize = metadata.reduce((acc, m) => acc + m.size, 0);

    return assetByteSize / (1000 * 1024);
  }
}
