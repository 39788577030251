import { Injectable } from '@angular/core';
import { QuestionGroupInput } from 'src/generated/base-types';
import {
  transformHTMLToInternal,
  transformInternalToHTML
} from './question-content-asset-transformer';
import { QuestionContentTransformer } from './question-content-transformer';

@Injectable({
  providedIn: 'root'
})
export class QuestionContentAssetTransformerService {
  public transformInternalRepresentationToHTML(
    questionGroupInput: QuestionGroupInput
  ): QuestionGroupInput {
    const transformer = new QuestionContentTransformer(transformInternalToHTML);

    return {
      ...questionGroupInput,
      questions: (questionGroupInput.questions || []).map(questionInput =>
        transformer.transform(questionInput)
      )
    };
  }

  public transformHTMLToInternalRepresentation(
    questionGroupInput: QuestionGroupInput
  ): QuestionGroupInput {
    const transformer = new QuestionContentTransformer(transformHTMLToInternal);

    return {
      ...questionGroupInput,
      questions: (questionGroupInput?.questions || []).map(questionInput =>
        transformer.transform(questionInput)
      )
    };
  }
}
