import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppContextState } from './state/app-context.state';

@Injectable({
  providedIn: 'root'
})
export class SuperUserGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(AppContextState.currentUserState).pipe(
      // wait for app-context state to fetch the current user
      filter(state => state.requestState === 'success'),
      // TODO: if it fails then we just stick in this guard??
      map(state => {
        if (state?.data?.superUser === true) {
          return true;
        }

        return this.router.createUrlTree(['/']);
      })
    );
  }
}
