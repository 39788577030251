import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesOrBlank'
})
export class YesOrBlankPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  public transform(value: boolean): string {
    if (value) {
      return this.translate.instant('common.yes');
    }

    return '';
  }
}
