/*jshint multistr: true */
angular.module('common').controller('ExamUnlockModalCtrl', ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
  $scope.unlockCode = '';

  $scope.confirm = function () {
    $uibModalInstance.close($scope.unlockCode);
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss();
  };
}]);

angular.module('common').service('ExamUnlockModal', ['ConfirmationModal', function (ConfirmationModal) {
  return {
    show: function (title, content, options) {
      content = content + '<div class="form-horizontal layout-cell--top-spaced"><div class="form-group">\
  <label for="unlockCode" class="col-sm-3 control-label">' + I18n.t('exams.toolbar.examinator_unlock_token') + '</label>\
  <div class="col-sm-9"><input id="unlockCode" class="form-control" type="text" ng-model="unlockCode"></div>\
</div></div>';
      options = options || {};
      options.controller = 'ExamUnlockModalCtrl';
      return ConfirmationModal.show(title, content, options);
    }
  };
}]);
