/* eslint-disable unicorn/no-null */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/admin/common/modal/modal.service';
import { UpdateDimensionMutationVariables } from 'src/app/admin/services/update-dimension.generated';
import { DimensionData } from 'src/app/admin/state/pool-details/dimensions/dimensions.state';
import { PoolFormFragment } from 'src/app/services/load-pool-form.generated';
import { Dimension, DimensionInput } from 'src/generated/base-types';

@Component({
  selector: 'man-dimension-form',
  templateUrl: './dimension-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionFormComponent implements OnInit {
  @Input() public dimension?: DimensionData;
  @Input() public dimensionNumber?: string;
  @Input() public pool: PoolFormFragment;

  @Input() public set disabled(value: boolean | undefined) {
    value === true ? this.form.disable() : this.form.enable();
  }

  public get disabled(): boolean {
    return this.form.disabled;
  }

  public get edit(): boolean {
    return this.dimension !== undefined;
  }

  @Output() public create = new EventEmitter<DimensionInput>();
  @Output() public update =
    new EventEmitter<UpdateDimensionMutationVariables>();
  @Output() public closed = new EventEmitter<DimensionInput>();

  public form = new FormGroup({
    nameDe: new FormControl<Dimension['nameDe']>(null),
    nameEn: new FormControl<Dimension['nameEn']>(null),
    nameFr: new FormControl<Dimension['nameFr']>(null),
    nameIt: new FormControl<Dimension['nameIt']>(null)
  });

  constructor(private readonly modalService: ModalService) {}

  public ngOnInit(): void {
    this.form.patchValue(this.dimension ?? {});
  }

  public onSave(): void {
    if (!this.form.valid || !this.form.dirty) return;

    this.dimension === undefined
      ? this.create.emit(this.form.value)
      : this.update.emit({
          id: this.dimension.id,
          attributes: this.form.value
        });
  }

  public async onClose(): Promise<void> {
    if (this.disabled) return;
    await this.safeClose();
  }

  private async safeClose(): Promise<void> {
    if (this.form.dirty) {
      const discardChanges = await this.modalService.confirmUnsavedChanges();
      if (discardChanges) {
        this.closed.emit();
      }
    } else {
      this.closed.emit();
    }
  }
}
