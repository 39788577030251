angular.module('common').factory('TableColumns', ['$cookies', function($cookies) {
  function TableColumns(columnSpecs, cookiestorePrefix) {
    this._cookiestorePrefix = cookiestorePrefix;
    this._changeCallbacks = [];
    this._buildColumns(columnSpecs);
    this._loadFromCookies();
  }

  TableColumns.prototype._buildColumns = function(specs) {
    this.columns = [];
    for(var i = 0; i < specs.length; i++) {
      if (specs[i].visible === undefined) {
        specs[i].visible = true;
      }
      if (specs[i].enabled === undefined) {
        specs[i].enabled = true;
      }
      if (specs[i].sortable === undefined) {
        specs[i].sortable = false;
      }
      this.columns.push(specs[i]);
    }
  };

  TableColumns.prototype._loadFromCookies = function() {
    var settings = $cookies.getObject(this._cookiestorePrefix + '-columns');
    for(var k in settings) {
      if (settings.hasOwnProperty(k)) {
        this.setVisibility(k, settings[k]);
      }
    }
  };

  TableColumns.prototype.findColumn = function (name) {
    return this.columns.find(function (column) {
      return column.name == name;
    });
  };

  TableColumns.prototype.isVisible = function (name) {
    var column = this.findColumn(name);
    return column && column.visible;
  };

  TableColumns.prototype.setVisibility = function (name, value) {
    var column = this.findColumn(name);
    if (column) {
      column.visible = value;
      this._notfiyChangeListeners(name);
    }
  };

  TableColumns.prototype.isSortable = function (name) {
    var column = this.findColumn(name);
    return column && column.sortable;
  };

  TableColumns.prototype._notfiyChangeListeners = function (columnName) {
    for(var i = 0; i < this._changeCallbacks.length; i++) {
      this._changeCallbacks[i](columnName);
    }
  };

  TableColumns.prototype.onChange = function (callback) {
    this._changeCallbacks.push(callback);
  };

  TableColumns.prototype.setMetadata = function (name, key, value) {
    var column = this.findColumn(name);
    if (column) {
      column.metadata = column.metadata || {};
      column.metadata[key] = value;
    }
  };

  TableColumns.prototype.enable = function(name) {
    var column = this.findColumn(name);
    column.enabled = true;
    this._notfiyChangeListeners(name);
  };

  TableColumns.prototype.disable = function(name) {
    var column = this.findColumn(name);
    column.enabled = false;
    this._notfiyChangeListeners(name);
  };

  TableColumns.prototype.isEnabled = function (name) {
    var column = this.findColumn(name);
    return column && column.enabled;
  };

  TableColumns.prototype.getEnabledColumns = function() {
    return this.columns.filter(function (column) {
      return column.enabled;
    });
  };

  TableColumns.prototype.save = function() {
    var settings = this.columns.reduce(function (acc, column) {
      acc[column.name] = column.visible;
      return acc;
    }, {});
    $cookies.putObject(this._cookiestorePrefix + '-columns', settings);
  };

  return TableColumns;
}]);
