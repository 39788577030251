import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostListener,
  ElementRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'co-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  host: { class: 'co-radio-button-group' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonGroupComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonGroupComponent
  implements ControlValueAccessor, AfterContentInit
{
  private buttons: HTMLButtonElement[];
  private value: string;
  constructor(
    private changeDetection: ChangeDetectorRef,
    private readonly elementRef: ElementRef
  ) {}

  @HostListener('blur')
  public onTouched: () => void = () => void 0;

  public ngAfterContentInit(): void {
    this.buttons = Array.from(this.elementRef.nativeElement.children);
    this.registerClickEvents();
  }

  public writeValue(value: string): void {
    this.value = value;
    this.updateActiveStates();
  }

  public registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private readonly clickCallback: (button: HTMLButtonElement) => void = (
    button: HTMLButtonElement
  ) => {
    this.writeValue(button.value);
    this.onChange(this.value);
  };
  private onChange: (_: string) => void = () => void 0;

  private registerClickEvents(): void {
    if (this.buttons === undefined) {
      return;
    }
    this.buttons.forEach(button => {
      button.addEventListener('click', () => this.clickCallback(button));
    });
  }

  private updateActiveStates(): void {
    if (this.buttons === undefined) {
      return;
    }

    this.buttons.forEach(button => {
      if (button.value === this.value) {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
      // native element only allow strings as values - but kprime uses boolean
      try {
        const buttonValueJSON = JSON.parse(button.value);
        const valueJSON = JSON.parse(this.value);

        if (buttonValueJSON === valueJSON) {
          button.classList.add('active');
        } else {
          button.classList.remove('active');
        }
      } catch (_e) {
        // not parsable
      }
    });
    this.changeDetection.markForCheck();
  }
}
