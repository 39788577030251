angular.module('common').factory('Actions::ConnectionStatus', function() {
  var actions = {
    SET_CONNECTION_PRESENT: 'CONNECTION_STATUS_SET_CONNECTION_PRESENT',
    SET_CONNECTION_MISSING: 'CONNECTION_STATUS_SET_CONNECTION_MISSING'
  };

  function setConnectionPresent() {
    return {
      type: actions.SET_CONNECTION_PRESENT
    };
  }

  function setConnectionMissing() {
    return {
      type: actions.SET_CONNECTION_MISSING
    };
  }

  return Object.assign(
    {
      setConnectionPresent: setConnectionPresent,
      setConnectionMissing: setConnectionMissing
    },
    actions
  );
});
