<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_management.translate_task_modal.title' | translate }}</h3>
  </div>
  <div class="loading p-5" *ngIf="isLoading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div class="modal-body" *ngIf="!isLoading">
    <form class="form-horizontal" [formGroup]="form">
      <div class="form-group">
        <div class="control-label col-sm-2" for="receiverIds">
          {{ 'question_management.task_modal.form.receiver' | translate }}
        </div>
        <div class="col-sm-9">
          <ng-select
            [class.is-invalid]="form.get('receiverIds')?.invalid"
            id="receiverIds"
            [multiple]="true"
            [items]="userOptions"
            bindValue="value"
            formControlName="receiverIds"
          ></ng-select>
          <div
            class="invalid-feedback"
            *ngIf="form.get('receiverIds')?.invalid"
          >
            {{ 'validation.warning.should_not_be_empty' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="control-label col-sm-2" for="emailSubject">
          {{ 'question_management.task_modal.form.email_subject' | translate }}
        </div>
        <div class="col-sm-9">
          <input
            [class.is-invalid]="form.get('emailSubject')?.invalid"
            class="form-control"
            id="emailSubject"
            formControlName="emailSubject"
            type="text"
          />
          <div
            class="invalid-feedback"
            *ngIf="form.get('emailSubject')?.invalid"
          >
            {{ 'validation.warning.should_not_be_empty' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="control-label col-sm-2" for="emailBody">
          {{ 'question_management.task_modal.form.email_body' | translate }}
        </div>
        <div class="col-sm-9">
          <ng-select-wrapper
            [id]="'recentEmailBodies'"
            [options]="recentEmailBodies"
            (valueChange)="setEmailBody($event)"
          ></ng-select-wrapper>
          <p class="help-block">
            {{
              'question_management.task_modal.recent_emails_hint' | translate
            }}
          </p>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-9 col-sm-offset-2">
          <textarea
            [class.is-invalid]="form.get('emailBody')?.invalid"
            class="form-control"
            id="emailBody"
            rows="10"
            formControlName="emailBody"
          ></textarea>
          <div class="invalid-feedback" *ngIf="form.get('emailBody')?.invalid">
            {{ 'validation.warning.should_not_be_empty' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="control-label col-sm-2">
          {{ 'question_management.task_modal.form.ends_at' | translate }}
        </div>
        <div class="col-sm-9">
          <input
            [class.is-invalid]="form.get('deadline')?.invalid"
            id="deadline"
            class="form-control"
            placeholder="dd.mm.yyyy"
            name="datepicker"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            formControlName="deadline"
            [autoClose]="true"
            [restoreFocus]="true"
            (focus)="datepicker.toggle()"
            (keydown)="$event.preventDefault(); $event.stopPropagation()"
            [minDate]="minDate"
            [maxDate]="maxDate"
          />
          <div class="invalid-feedback" *ngIf="form.get('deadline')?.invalid">
            {{ 'validation.warning.should_not_be_empty' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="control-label col-sm-2" for="targetLanguage">
          {{
            'question_management.translate_task_modal.target_language'
              | translate
          }}
        </div>
        <div class="col-sm-9">
          <ng-select
            id="targetLanguage"
            [items]="languageOptions"
            bindLabel="label"
            bindValue="value"
            formControlName="targetLanguage"
            [class.is-invalid]="form.get('targetLanguage')?.errors?.required"
            [class.is-warning]="questionGroupsWithSameLang > 0"
            (change)="setLanguageWarning($event)"
          ></ng-select>
          <div
            class="invalid-feedback"
            *ngIf="
              form.get('targetLanguage')?.invalid &&
              form.get('targetLanguage')?.errors?.required
            "
          >
            {{ 'validation.warning.should_not_be_empty' | translate }}
          </div>
          <div
            class="warning-feedback"
            *ngIf="
              !form.get('targetLanguage')?.errors?.required &&
              questionGroupsWithSameLang > 0
            "
          >
            <span
              *ngIf="
                questionGroups.length > 1 && questionGroupsWithSameLang === 1
              "
            >
              {{
                'question_management.translate_task_modal.warnings.target_language.some_source_languages_identical.one'
                  | translate
              }}
            </span>

            <span
              *ngIf="
                questionGroups.length > 1 &&
                questionGroupsWithSameLang > 1 &&
                questionGroupsWithSameLang !== questionGroups.length
              "
            >
              {{
                'question_management.translate_task_modal.warnings.target_language.some_source_languages_identical.other'
                  | translate
                    : {
                        count: questionGroupsWithSameLang
                      }
              }}
            </span>

            <span
              *ngIf="
                questionGroups.length === 1 ||
                questionGroupsWithSameLang === questionGroups.length
              "
            >
              {{
                'question_management.translate_task_modal.warnings.target_language.all_source_languages_identical'
                  | translate
              }}
            </span>
          </div>
        </div>
      </div>
    </form>
    <div class="text-center mb-4">
      {{
        'question_management.task_modal.info'
          | translate : { amount: questionGroups.length }
      }}
      <label
        id="showDetails"
        class="btn-link text-decoration-none"
        (click)="showDetails = !showDetails"
      >
        {{ 'question_management.task_modal.show_details' | translate }}
        <i
          class="fa fa-lg pr-3"
          [ngClass]="
            showDetails ? 'fa-chevron-circle-right' : 'fa-chevron-circle-down'
          "
        ></i>
      </label>
    </div>
    <ng-container *ngIf="showDetails">
      <div class="card mb-4">
        <div class="card-body">
          {{ 'question_management.task_modal.authorization.info' | translate }}
          <ul>
            <li>
              {{
                'question_management.task_modal.authorization.see_source_language'
                  | translate
              }}
            </li>
            <li>
              {{
                'question_management.task_modal.authorization.edit_target_language'
                  | translate
              }}
            </li>
            <li>
              {{
                'question_management.task_modal.authorization.comment'
                  | translate
              }}
            </li>
          </ul>
        </div>
      </div>
      <co-translate-task-table
        [languages]="languages"
        [questionGroups]="questionGroups"
      >
      </co-translate-task-table>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button
      (click)="dismiss()"
      class="btn btn-secondary-migrated"
      [disabled]="isSubmitting"
    >
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      (click)="confirm()"
      class="btn btn-primary-migrated"
      [disabled]="isSubmitting || form.invalid"
    >
      {{ 'question_management.task_modal.buttons.save' | translate }}
      <div
        class="spinner-border text-light"
        role="status"
        *ngIf="isSubmitting"
      ></div>
    </button>
  </div>
</div>
