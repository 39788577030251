<button
  type="button"
  class="offcanvas-backdrop show"
  [disabled]="!closeable"
  (click)="onClose()"
  [@backdrop]
></button>
<div class="offcanvas show" [class.large]="large" [@panel]>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">{{ title }}</h5>
    <button
      [disabled]="!closeable"
      (click)="onClose()"
      type="button"
      class="btn-close"
      [attr.aria-label]="'common.actions.close' | translate"
    ></button>
  </div>
  <div class="offcanvas-body" cdkTrapFocus cdkTrapFocusAutoCapture>
    <ng-content></ng-content>
  </div>
</div>
