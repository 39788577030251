import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TotalStatisticTypeFreeTextFragment } from 'src/app/question-management/services/question-group-statistics.generated';

@Component({
  selector: 'co-type-free-text-statistics',
  templateUrl: './type-free-text-statistics.component.html',
  styleUrls: ['./type-free-text-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeFreeTextStatisticsComponent {
  @Input()
  public statistics: TotalStatisticTypeFreeTextFragment;
}
