/* eslint-disable import/order */
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import {
  APP_INITIALIZER,
  DoBootstrap,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule, setAngularJSGlobal } from '@angular/upgrade/static';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import angular from 'angular';
import 'angular-animate';
import 'angular-cookies';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls';
import { ApolloModule } from 'apollo-angular';
import 'ng-file-upload/dist/ng-file-upload';
import 'ui-select/dist/select';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { ajsUpgradedProviders } from './ajs-upgraded-providers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './common/app/app.component';
import { CommonModule } from './common/common.module';
import { HoneybadgerHandler } from './common/handlers/honeybadger.handler';
import { NgxTranslateHandler } from './common/handlers/ngx-translate.handler';
import { OldTranslationHandler } from './common/utils/old-translation-handler';
import { ExamManagementModule } from './exam-management/exam-management.module';
import { GraphQLModule } from './graphql.module';
import './lib/i18n';
import { MainModule } from './main/main.module';
import { PoolModule } from './pool/pool.module';
import { ExamState } from './pool/state/exam.state';
import { ExcelStatisticsState } from './pool/state/excel-statistics.state';
import { PoolState as NewPoolState } from './pool/state/pool.state';
import { ProfileModule } from './profile/profile.module';
import { ProfileFormState } from './profile/state/profile-form.state';
import { QuestionFormModule } from './question-form/question-form.module';
import { setupIMLImagePlugin } from './question-form/rich-editor/image.plugin';
import { setupLatexPlugin } from './question-form/rich-editor/plugins/latex/latex.plugin';
import { setupIMLResolutionPlugin } from './question-form/rich-editor/resolution-info.plugin';
import { QuestionManagementModule } from './question-management/question-management.module';
import { QuestionDetailsState } from './question-management/state/question-details.state';
import { QuestionFilterState } from './question-management/state/question-filter/question-filter.state';
import { QuestionListState } from './question-management/state/question-list.state';
import { DynamicLocaleId } from './settings/dynamic-locale-id';
import { SettingsModule } from './settings/settings.module';
import { AppContextState } from './state/app-context.state';
import { PoolState } from './state/pool.state';
import { PoolsState } from './state/pools.state';
import { SettingsState } from './state/settings.state';
import { StaticTranslateLoader } from './static-translation-loader';
import { TaskManagementModule } from './task-management/task-management.module';

// eslint-disable-next-line @typescript-eslint/no-var-requires, unicorn/prefer-module

require('./bootstrap.index');

setAngularJSGlobal(angular);

// loading in translations which are used for the ng-bootstrap datepicker
registerLocaleData(localeEn, 'en', localeEnExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeFr, 'fr', localeFrExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    UpgradeModule,
    MainModule,
    CommonModule,
    ExamManagementModule,
    QuestionManagementModule,
    QuestionFormModule,
    SettingsModule,
    TaskManagementModule,
    AdminModule,
    ProfileModule,
    PoolModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: StaticTranslateLoader },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: OldTranslationHandler
      }
    }),
    GraphQLModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot(
      [
        AppContextState,
        NewPoolState,
        ExamState,
        ExcelStatisticsState,
        PoolState,
        PoolsState,
        SettingsState,
        ProfileFormState
      ],
      {
        developmentMode: !environment.production
      }
    ),
    ApolloModule,
    NgxsStoragePluginModule.forRoot({
      key: [
        SettingsState,
        QuestionDetailsState,
        QuestionFilterState,
        QuestionListState,
        'taskList.loadedQuestionGroupIds'
      ]
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'msrd-manager',
      disabled: environment.production
    }),
    EditorModule,
    AppRoutingModule
  ],
  providers: [
    ...ajsUpgradedProviders,
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        // Noop handler for factory function
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return function () {};
      },
      deps: [NgxTranslateHandler, HoneybadgerHandler],
      multi: true
    }
  ],
  bootstrap: [],
  entryComponents: []
})
export class AppModule implements DoBootstrap {
  constructor(
    private upgrade: UpgradeModule,
    private readonly translateService: TranslateService
  ) {}

  public ngDoBootstrap(): void {
    this.upgrade.bootstrap(document.body, ['main'], { strictDi: false });
    // setup the custom IML plugin
    this.initTinyMCE();
  }

  private initTinyMCE(): void {
    setupIMLResolutionPlugin(this.translateService);
    setupIMLImagePlugin();
    setupLatexPlugin();
  }
}
