import {
  ChangeDetectionStrategy,
  Component,
  HostListener
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserInput } from '../../generated/base-types';
import { UnsavedChanges } from '../admin/common/guard/unsaved-changes.guard';
import { LoadUsersFieldsFragment } from '../admin/services/load-users.fragments.generated';
import { isDefined } from '../common/utils/type-guards/is-defined';
import { UpdateProfile } from './state/profile-form.actions';
import { ProfileFormState } from './state/profile-form.state';

@Component({
  template: `
    <div class="main-content" *ngIf="currentUser$ | async as currentUser">
      <man-profile-form
        [user]="currentUser"
        (update)="onUpdate($event)"
        (stateChange)="onStateChange($event)"
      ></man-profile-form>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'main'
  }
})
export class ProfileComponent implements UnsavedChanges {
  public currentUser$: Observable<LoadUsersFieldsFragment>;
  public unsavedChanges = false;

  constructor(private store: Store) {
    this.currentUser$ = this.store
      .select(ProfileFormState.profile)
      .pipe(filter(isDefined));
  }

  @HostListener('window:beforeunload', ['$event'])
  public onUnloadListener(event: BeforeUnloadEvent): void {
    if (!this.unsavedChanges) return;

    event.preventDefault();
  }

  public onUpdate(input: UserInput): void {
    this.store.dispatch(new UpdateProfile(input));
  }

  public onStateChange(unsavedChanges: boolean): void {
    this.unsavedChanges = unsavedChanges;
  }
}
