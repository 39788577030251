import * as Types from '../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PoolFormFragment = { __typename?: 'Pool', id: string, name: string, selfAssessment: boolean, deEnabled: boolean, frEnabled: boolean, itEnabled: boolean, enEnabled: boolean, responsibleFirstName?: string | null, responsibleLastName?: string | null, responsibleEmail: string, responsibleLanguage?: Types.Language | null };

export type LoadPoolFormQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type LoadPoolFormQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', id: string, name: string, selfAssessment: boolean, deEnabled: boolean, frEnabled: boolean, itEnabled: boolean, enEnabled: boolean, responsibleFirstName?: string | null, responsibleLastName?: string | null, responsibleEmail: string, responsibleLanguage?: Types.Language | null } };

export const PoolFormFragmentDoc = gql`
    fragment PoolForm on Pool {
  id
  name
  selfAssessment
  deEnabled
  frEnabled
  itEnabled
  enEnabled
  responsibleFirstName
  responsibleLastName
  responsibleEmail
  responsibleLanguage
}
    `;
export const LoadPoolFormDocument = gql`
    query LoadPoolForm($poolId: ID!) {
  pool(id: $poolId) {
    ...PoolForm
  }
}
    ${PoolFormFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadPoolFormGQL extends Apollo.Query<LoadPoolFormQuery, LoadPoolFormQueryVariables> {
    document = LoadPoolFormDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }