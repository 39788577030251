import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { syncReduxWithNgXs } from './app/legacy-syncing/store';
import { initializeHoneybadger } from './app/lib/honeybadger';
import { environment } from './environments/environment';
import './legacy-globals';

if (environment.production) {
  enableProdMode();
}

initializeHoneybadger();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line unicorn/prefer-top-level-await
  .then((ref: NgModuleRef<AppModule>) => {
    syncReduxWithNgXs(ref.injector);
  });
