import { Injectable } from '@angular/core';
import Fuse from 'fuse.js';

@Injectable({
  providedIn: 'root'
})
export class FuzzySearchService<T> {
  public search(
    values: T[],
    searchString: string,
    filterFields: string[]
  ): T[] {
    if (searchString === '') return values;

    const searchOptions = {
      keys: filterFields
    };

    const fuse = new Fuse(values, searchOptions);

    return fuse.search(searchString).map(match => match.item);
  }
}
