import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Actions, ofAction, Selector, State } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { takeGraphQLResult } from '../../common/operators/take-graphql-response';
import { ToastsService } from '../../common/services/toasts.service';
import { parseError } from '../../common/utils/error-parser';
import { RemoteData } from '../../common/utils/remote-data';
import {
  requestStateFailure,
  requestStateLoading
} from '../../common/utils/remote-data-utils';
import { isDefined } from '../../common/utils/type-guards/is-defined';
import { LoadUserFormGQL } from '../../services/load-user-form.generated';
import { ModalService } from '../common/modal/modal.service';
import { CreateUserGQL } from '../services/create-user.generated';
import { LoadUsersFieldsFragment } from '../services/load-users.fragments.generated';
import { UpdateUserGQL } from '../services/update-user.generated';

import {
  CreateUser,
  CreateUserFailure,
  CreateUserSuccess,
  LoadUser,
  LoadUserFailure,
  LoadUserSuccess,
  Reset,
  ResetMutation,
  UpdateUser,
  UpdateUserFailure,
  UpdateUserSuccess
} from './user-form.actions';
import { LoadUsers } from './users.actions';

export interface UserFormStateModel {
  user: RemoteData<LoadUsersFieldsFragment>;
  mutation: RemoteData<void>;
}

@State<UserFormStateModel>({
  name: 'userform',
  defaults: {
    user: { requestState: 'initial' },
    mutation: { requestState: 'initial' }
  }
})
@Injectable({
  providedIn: 'root'
})
export class UserFormState {
  constructor(
    private readonly actions$: Actions,
    private readonly loadUserFormService: LoadUserFormGQL,
    private readonly createUserService: CreateUserGQL,
    private readonly updateUserService: UpdateUserGQL,
    private readonly toastsService: ToastsService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService
  ) {}

  @Selector()
  public static state(state: UserFormStateModel): UserFormStateModel {
    return state;
  }

  @Selector()
  public static user(
    state: UserFormStateModel
  ): RemoteData<LoadUsersFieldsFragment> {
    return state.user;
  }

  @Selector()
  public static userLoading(state: UserFormStateModel): boolean {
    return requestStateLoading(state.user);
  }

  @Selector()
  public static userFailure(state: UserFormStateModel): boolean {
    return requestStateFailure(state.user);
  }

  @Selector()
  public static mutation(state: UserFormStateModel): RemoteData<void> {
    return state.mutation;
  }

  @Selector()
  public static mutationLoading(state: UserFormStateModel): boolean {
    return requestStateLoading(state.mutation);
  }

  @Action(LoadUser, { cancelUncompleted: true })
  public loadUser(
    ctx: StateContext<UserFormStateModel>,
    { id }: LoadUser
  ): Observable<void> {
    ctx.patchState({ user: { requestState: 'loading' } });

    return this.loadUserFormService.fetch({ userId: id }).pipe(
      takeGraphQLResult(),
      switchMap(res => ctx.dispatch(new LoadUserSuccess(res.user))),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadUserFailure(parseError(err), id))
      ),
      takeUntil(this.actions$.pipe(ofAction(Reset)))
    );
  }

  @Action(LoadUserSuccess)
  public loadUserSuccess(
    ctx: StateContext<UserFormStateModel>,
    { user }: LoadUserSuccess
  ): void {
    ctx.patchState({
      user: {
        data: user,
        requestState: 'success'
      }
    });
  }

  @Action(LoadUserFailure)
  public loadUserFailure(
    ctx: StateContext<UserFormStateModel>,
    { error, id }: LoadUserFailure
  ): void {
    ctx.patchState({
      user: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (!retry) return;

      ctx.dispatch(new LoadUser(id));
    });
  }

  @Action(CreateUser)
  public createUser(
    ctx: StateContext<UserFormStateModel>,
    { attributes }: CreateUser
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.createUserService.mutate({ attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.createUser?.user),
      filter(isDefined),
      mergeMap(user => ctx.dispatch(new CreateUserSuccess(user))),
      catchError((err: unknown) =>
        ctx.dispatch(new CreateUserFailure(parseError(err), attributes))
      )
    );
  }

  @Action(CreateUserSuccess)
  public createUserSuccess(
    ctx: StateContext<UserFormStateModel>,
    { user }: CreateUserSuccess
  ): Observable<void> {
    ctx.patchState({
      user: { requestState: 'success', data: user },
      mutation: {
        requestState: 'success'
      }
    });

    this.toastsService.addSuccess(
      this.translateService.instant('toast_success_messages.create', {
        resource: this.translateService.instant('common.models.user.one')
      })
    );

    return ctx.dispatch(new LoadUsers());
  }

  @Action(CreateUserFailure)
  public createUserFailure(
    ctx: StateContext<UserFormStateModel>,
    { error, attributes }: CreateUserFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      ctx.dispatch(retry ? new CreateUser(attributes) : new ResetMutation());
    });
  }

  @Action(UpdateUser)
  public updateUser(
    ctx: StateContext<UserFormStateModel>,
    { id, attributes }: UpdateUser
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.updateUserService.mutate({ id, attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.updateUser?.user),
      filter(isDefined),
      mergeMap(user => ctx.dispatch(new UpdateUserSuccess(user))),
      catchError((err: unknown) =>
        ctx.dispatch(new UpdateUserFailure(parseError(err), id, attributes))
      )
    );
  }

  @Action(UpdateUserSuccess)
  public updateUserSuccess(
    ctx: StateContext<UserFormStateModel>,
    { user }: UpdateUserSuccess
  ): Observable<void> {
    ctx.patchState({
      user: { requestState: 'success', data: user },
      mutation: { requestState: 'success' }
    });

    this.toastsService.addSuccess(
      this.translateService.instant('toast_success_messages.update', {
        resource: this.translateService.instant('common.models.user.one')
      })
    );

    return ctx.dispatch(new LoadUsers());
  }

  @Action(UpdateUserFailure)
  public updateUserFailure(
    ctx: StateContext<UserFormStateModel>,
    { error, id, attributes }: UpdateUserFailure
  ): void {
    ctx.patchState({
      mutation: { requestState: 'failure', error }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      ctx.dispatch(
        retry ? new UpdateUser(id, attributes) : new ResetMutation()
      );
    });
  }

  @Action(Reset)
  public reset(ctx: StateContext<UserFormStateModel>): void {
    ctx.setState({
      user: { requestState: 'initial' },
      mutation: { requestState: 'initial' }
    });
  }

  @Action(ResetMutation)
  public resetMutation(ctx: StateContext<UserFormStateModel>): void {
    ctx.patchState({
      mutation: {
        requestState: 'initial'
      }
    });
  }
}
