import { DatePipe } from '@angular/common';
import { LabelValuePair } from '../ng-select-wrapper/ng-select-wrapper.component';
import { assertIsDefined } from '../utils/type-guards/is-defined';
import {
  ModalTaskFragment,
  TaskUserFragment
} from './create-task-modal/load-task-options.generated';

const TRUNCATED_SUBJECT_WORD_COUNT = 5;

export const buildRecentEmailBodyOptions = (
  tasks: ModalTaskFragment[],
  datePipe: DatePipe
): LabelValuePair<string>[] => {
  return tasks.map(task => {
    const taskDate = datePipe.transform(task.startsAt, 'dd.MM.y HH:mm');
    const truncatetTaskEmailBody = task.emailBody
      .split(' ', TRUNCATED_SUBJECT_WORD_COUNT)
      .join(' ');
    const label = `${taskDate} - ${truncatetTaskEmailBody}`;

    return {
      label,
      value: task.emailBody
    };
  });
};

export const buildUserOptions = (
  users: TaskUserFragment[]
): LabelValuePair<string>[] => {
  return users.map(user => {
    assertIsDefined(user.id);

    return {
      label: `${user.firstName} ${user.lastName} <${user.email}>`,
      value: user.id as string
    };
  });
};
