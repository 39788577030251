// used for the question group, exam question group and task question group exports

export interface ExportConfig {
  format: ExportFormats;
  type: ExportTypes;
}

export enum ExportFormats {
  Csv = 'csv',
  Excel = 'excel'
}
export enum ExportTypes {
  Selected = 'S',
  All = 'A'
}
