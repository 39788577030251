import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Scalars } from 'src/generated/base-types';
import {
  SetDetailsWidth,
  ToggleDetailsPanel
} from '../../state/question-details.actions';
import { QuestionDetailsState } from '../../state/question-details.state';
import { ToggleFilterPanel } from '../../state/question-filter/question-filter.actions';
import { QuestionFilterState } from '../../state/question-filter/question-filter.state';
import { QuestionListState } from '../../state/question-list.state';

@Component({
  selector: 'app-question-management',
  templateUrl: './question-management.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'layout-cell--resizable layout--horizontal collapsable-watcher'
  }
})
export class QuestionManagementComponent implements AfterViewInit, OnDestroy {
  @ViewChild('detailsContainer')
  public container: ElementRef;

  public questionGroupId$: Observable<Scalars['ID'] | undefined>;
  public questionDetailsHidden$: Observable<boolean>;
  public filterHidden$: Observable<boolean>;

  private width$: Observable<number>;
  private boundingBox: {
    left: number;
    right: number;
  };
  private unbindGlobalListeners?: () => void;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly ngZone: NgZone,
    private readonly renderer: Renderer2
  ) {
    this.questionGroupId$ = this.store.select(
      QuestionListState.idOfLastSelectedQuestionGroup
    );
    this.questionDetailsHidden$ = this.store.select(
      QuestionDetailsState.hidden
    );
    this.filterHidden$ = this.store.select(QuestionFilterState.hidden);
    this.width$ = this.store.select(QuestionDetailsState.width);
  }

  public ngAfterViewInit(): void {
    this.width$.pipe(take(1), takeUntil(this.destroy$)).subscribe(width => {
      this.setComponentWidth(width);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onToggleDetailsPanel(): void {
    this.store.dispatch(new ToggleDetailsPanel());
  }

  public onToggleFilterPanel(): void {
    this.store.dispatch(new ToggleFilterPanel());
  }

  public onMouseDown(): void {
    this.boundingBox = this.getBoundingBox();
    this.bindGlobalListeners();
  }

  private onMouseMove(event: MouseEvent): void {
    const width = this.getWidth(event);
    this.setComponentWidth(width);
  }

  private onMouseUp(event: MouseEvent): void {
    const width = this.getWidth(event);

    if (this.unbindGlobalListeners) {
      this.unbindGlobalListeners();
    }
    this.ngZone.run(() => {
      this.store.dispatch(new SetDetailsWidth(width));
    });
  }

  private bindGlobalListeners(): void {
    this.unbindGlobalListeners = this.ngZone.runOutsideAngular(() => {
      const unlistenMove = this.renderer.listen('body', 'mousemove', ev =>
        this.onMouseMove(ev)
      );
      const unlistenUp = this.renderer.listen('body', 'mouseup', ev =>
        this.onMouseUp(ev)
      );

      return () => {
        unlistenMove();
        unlistenUp();
      };
    });
  }

  private getBoundingBox(): { left: number; right: number } {
    const { left, right } =
      this.container.nativeElement.getBoundingClientRect();

    return { left, right };
  }

  private setComponentWidth(width: number): void {
    this.renderer.setStyle(this.container.nativeElement, 'width', `${width}px`);
  }

  private getWidth(event: MouseEvent): number {
    const mouseX = event.clientX;

    return this.boundingBox.right - mouseX;
  }
}
