import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { SetSupervisorModalComponent } from '../modals/set-supervisor-modal/set-supervisor-modal.component';

angular
  .module('common')
  .directive(
    'coSetSupervisorModal',
    downgradeComponent({ component: SetSupervisorModalComponent })
  );
