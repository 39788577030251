import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Language,
  LongMenuListEntryInput
} from '../../../generated/base-types';
import { Voidable } from '../../common/utils/type-guards/voidable';

@Injectable({
  providedIn: 'root'
})
export class LongMenuSolutionTransformerService {
  constructor(private readonly translate: TranslateService) {}

  public convertSolution(
    list: LongMenuListEntryInput[] | null = [],
    solutions: number[][],
    sequentialNumber: number
  ): string {
    const solutionLines: string[] = [];
    solutions.forEach((alternativesIDs: number[], i: number) => {
      const solutionTitle = `${this.translate.instant(
        'question_groups.edit.longmenu_solution.title'
      )} ${i + 1}`;
      const lines = alternativesIDs.map(alternativeID => {
        const alternative = list?.find(
          longMenuListEntry => longMenuListEntry.id === alternativeID
        );
        if (!alternative) {
          return '';
        }
        let line = '\t';
        if (alternative.code !== undefined) {
          line += alternative.code + ' ';
        }

        // get solutions in all languages
        line += this.getAlternativeTranslation(
          alternative.name,
          Object.values(Language)
        );

        return line;
      });
      solutionLines.push(solutionTitle, lines.join('\n') + '\n');
    });
    const header = `${this.translate.instant(
      'question_groups.edit.longmenu_solution.title_export'
    )} ${sequentialNumber}\n--------------------------------------------`;
    const content: string = header + '\n' + solutionLines.join('\n');

    return content;
  }

  private getAlternativeTranslation(
    name: { [index: string]: string | Voidable },
    langs: string[]
  ): string {
    return langs
      .map(lang => name[lang])
      .filter(n => n !== undefined)
      .join(', ');
  }
}
