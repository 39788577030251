import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { assertIsDefined } from '../../../common/utils/type-guards/is-defined';
import { FilterLabelType } from '../../services/filter/filter-helper.service';
import { SimpleFilter } from '../../state/question-filter/question-filter.state.model';

@Component({
  selector: 'qm-filter-checkbox',
  templateUrl: './filter-checkbox.component.html',
  styleUrls: ['./filter-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterCheckboxComponent implements OnInit, OnDestroy {
  @Input() public name: string;
  @Input() public filter: SimpleFilter;
  @Input() public isOpen: boolean;
  @Output() public filterChange = new EventEmitter<SimpleFilter>();
  @Output() public isOpenChange = new EventEmitter<boolean>();

  public readonly filterID = Math.random().toString(36).slice(2, 6);
  public form = new UntypedFormGroup({});
  private formSubscription: Subscription;

  public ngOnInit(): void {
    this.initalize();
  }

  public ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
  }

  public get checkedCheckboxCount(): number {
    return this.checkboxStates.filter(Boolean).length;
  }

  public get isEmptySelection(): boolean {
    return this.checkedCheckboxCount === 0;
  }

  public get isFullSelection(): boolean {
    return this.checkedCheckboxCount === this.filter.options.length;
  }

  public get isPartialSelection(): boolean {
    return !this.isEmptySelection && !this.isFullSelection;
  }

  public toggleFullSelection(checkbox: HTMLInputElement): void {
    const isChecked = this.isEmptySelection;
    const newValue = Object.keys(this.form.controls).reduce(
      (acc, key) => ({ ...acc, [+key]: isChecked }),
      {} as Record<number, boolean>
    );
    this.form.setValue(newValue);
    checkbox.checked = isChecked;
  }

  public toggleOpen(): void {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }

  private initalize(): void {
    if (this.filter.options?.length === 0) return;

    this.filter.options.forEach((option, i) => {
      const allSelected = this.filter.selection?.length === 0;
      const isSelected = this.filter.selection?.includes(
        (option as FilterLabelType).value
      );
      this.form.addControl(
        `${i}`,
        new UntypedFormControl(allSelected || isSelected)
      );
    });

    this.formSubscription = this.form.valueChanges.subscribe(() => {
      if (!this.isEmptySelection) this.updateSelection();
    });
  }

  private get checkboxStates(): boolean[] {
    return Object.keys(this.form.controls).map(key => {
      const value = this.form.get(key)?.value;

      assertIsDefined(value, `Control with key ${key} is not defined`);

      return value;
    });
  }

  private updateSelection(): void {
    const selectedIndexes = this.isFullSelection
      ? []
      : this.checkboxStates.reduce(
          (acc, checked, index) => (checked ? acc.concat(index) : acc),
          [] as number[]
        );
    const selection = selectedIndexes.map(
      index => (this.filter.options[+index] as FilterLabelType).value
    );

    this.filterChange.emit({ ...this.filter, selection });
  }
}
