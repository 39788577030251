<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_management.create_task_modal.title' | translate }}</h3>
  </div>
  <div class="loading p-5" *ngIf="loading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div class="modal-body" *ngIf="!loading" [class.disabled]="isSubmitting">
    <form class="form-horizontal" [formGroup]="form">
      <div class="form-group">
        <div class="control-label col-sm-2">
          {{ 'question_management.task_modal.form.receiver' | translate }}
        </div>
        <div class="col-sm-9">
          <ng-select
            [class.is-invalid]="isControlInvalid('receiverIds')"
            id="receiverIds"
            [multiple]="true"
            [items]="userOptions"
            bindValue="value"
            bindLabel="label"
            formControlName="receiverIds"
          ></ng-select>
          <div class="invalid-feedback" *ngIf="isControlInvalid('receiverIds')">
            {{ 'validation.warning.should_not_be_empty' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="control-label col-sm-2">
          {{ 'question_management.task_modal.form.email_subject' | translate }}
        </div>
        <div class="col-sm-9">
          <input
            [class.is-invalid]="isControlInvalid('emailSubject')"
            class="form-control"
            id="emailSubject"
            type="text"
            formControlName="emailSubject"
          />
          <div
            class="invalid-feedback"
            *ngIf="isControlInvalid('emailSubject')"
          >
            {{ 'validation.warning.should_not_be_empty' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="control-label col-sm-2">
          {{ 'question_management.task_modal.form.email_body' | translate }}
        </div>
        <div class="col-sm-9">
          <ng-select-wrapper
            [options]="recentEmailBodies"
            (valueChange)="setEmailBody($event)"
            [id]="'recentEmailBodies'"
          ></ng-select-wrapper>
          <p class="help-block">
            {{
              'question_management.task_modal.recent_emails_hint' | translate
            }}
          </p>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-9 col-sm-offset-2">
          <textarea
            [class.is-invalid]="isControlInvalid('emailBody')"
            class="form-control"
            id="emailBody"
            rows="10"
            formControlName="emailBody"
          ></textarea>
          <div class="invalid-feedback" *ngIf="isControlInvalid('emailBody')">
            {{ 'validation.warning.should_not_be_empty' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="control-label col-sm-2">
          {{ 'question_management.task_modal.form.ends_at' | translate }}
        </div>
        <div class="col-sm-9">
          <input
            [class.is-invalid]="isControlInvalid('deadline')"
            class="form-control"
            placeholder="dd.mm.yyyy"
            name="dp"
            id="deadline"
            ngbDatepicker
            #d="ngbDatepicker"
            formControlName="deadline"
            [autoClose]="true"
            [restoreFocus]="true"
            (focus)="d.toggle()"
            (keydown)="$event.preventDefault(); $event.stopPropagation()"
            [minDate]="minDate"
            [maxDate]="maxDate"
          />
          <div class="invalid-feedback" *ngIf="isControlInvalid('deadline')">
            {{ 'validation.warning.should_not_be_empty' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="control-label col-sm-2">
          {{
            'question_management.create_task_modal.question_types' | translate
          }}
        </div>
        <div class="col-sm-9">
          <div class="form-group row">
            <div class="col-sm-6">
              <label class="control-label bottom-spaced">{{
                'question_management.create_task_modal.single_question'
                  | translate
              }}</label>
            </div>
            <div class="col-sm-6">
              <label class="control-label bottom-spaced">{{
                'question_management.create_task_modal.question_group_type.title'
                  | translate
              }}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 font-weight-normal" for="a">
              {{ 'questions.type.a' | translate }}
            </label>
            <div class="col-sm-2 filter-form">
              <input type="checkbox" formControlName="a" [id]="'a'" />
            </div>
            <label class="col-sm-4 font-weight-normal" for="series">
              {{
                'question_management.create_task_modal.question_group_type.series'
                  | translate
              }}
            </label>
            <div class="col-sm-2 filter-form">
              <input type="checkbox" [id]="'series'" formControlName="series" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 font-weight-normal" for="aMinus">
              {{ 'questions.type.aMinus' | translate }}
            </label>
            <div class="col-sm-2 filter-form">
              <input type="checkbox" formControlName="aMinus" [id]="'aMinus'" />
            </div>
            <label class="col-sm-4 font-weight-normal" for="sequence">
              {{
                'question_management.create_task_modal.question_group_type.sequence'
                  | translate
              }}
            </label>
            <div class="col-sm-2 filter-form">
              <input
                type="checkbox"
                [id]="'sequence'"
                formControlName="sequence"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 font-weight-normal" for="kPrime">
              {{ 'questions.type.kPrime' | translate }}
            </label>
            <div class="col-sm-2 filter-form">
              <input type="checkbox" formControlName="kPrime" [id]="'kPrime'" />
            </div>
            <div class="col-sm-4">
              <p class="hint">
                {{
                  'question_management.create_task_modal.question_group_type.info'
                    | translate
                }}
              </p>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 font-weight-normal" for="e">
              {{ 'questions.type.e' | translate }}
            </label>
            <div class="col-sm-2 filter-form">
              <input type="checkbox" formControlName="e" [id]="'e'" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 font-weight-normal" for="freeText">
              {{ 'questions.type.freeText' | translate }}
            </label>
            <div class="col-sm-2 filter-form">
              <input
                type="checkbox"
                formControlName="freeText"
                [id]="'freeText'"
              />
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="text-center mb-4">
      {{
        'question_management.task_modal.info'
          | translate : { amount: questionGroups.length }
      }}
      <label
        id="showDetails"
        class="btn-link text-decoration-none"
        (click)="showDetails = !showDetails"
      >
        {{ 'question_management.task_modal.show_details' | translate }}
        <i
          class="fa fa-lg pr-3"
          [ngClass]="
            showDetails ? 'fa-chevron-circle-right' : 'fa-chevron-circle-down'
          "
        ></i>
      </label>
    </div>

    <ng-container *ngIf="showDetails">
      <div class="card mb-4">
        <div class="card-body">
          {{ 'question_management.task_modal.authorization.info' | translate }}
          <ul>
            <li>
              {{
                'question_management.task_modal.authorization.create'
                  | translate
              }}
            </li>
            <li>
              {{
                'question_management.task_modal.authorization.view' | translate
              }}
            </li>
            <li>
              {{
                'question_management.task_modal.authorization.comment'
                  | translate
              }}
            </li>
            <li>
              {{
                'question_management.task_modal.authorization.see_statistics'
                  | translate
              }}
            </li>
          </ul>
        </div>
      </div>
      <div class="horizontal-scroll">
        <co-task-questions-table
          [questionGroups]="questionGroups"
          [dimensions]="dimensions"
        ></co-task-questions-table>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button
      (click)="dismiss()"
      class="btn btn-secondary-migrated"
      [disabled]="isSubmitting"
    >
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      (click)="save()"
      class="btn btn-primary-migrated"
      [disabled]="isSubmitting || form.invalid"
    >
      {{ 'question_management.task_modal.buttons.save' | translate }}
      <div
        class="spinner-border text-light"
        role="status"
        *ngIf="isSubmitting"
      ></div>
    </button>
  </div>
</div>
