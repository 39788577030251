angular.module('common').directive('columnVisibility', [
  function() {
    return {
      restrict: 'A',
      link: function($scope, $element, $attrs) {
        $scope.$watch($attrs.columnVisibility, function(visibilities) {
          Object.keys(visibilities).forEach(function(key) {
            $element.find('[column-id="' + key + '"]').removeClass('ng-hide');
            if (!visibilities[key]) {
              $element.find('[column-id="' + key + '"]').addClass('ng-hide');
            }
          });
        });
      }
    };
  }
]);
