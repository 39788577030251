import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { Scalars } from '../../../../generated/base-types';
import {
  CurrentPoolForFilterGQL,
  CurrentPoolForFilterQuery
} from './current-pool-for-filter.generated';
import {
  FilterHelperService,
  FilterLabelType,
  FilterDimensionType,
  FilterHtmlLabelType,
  FilterAttributeType
} from './filter-helper.service';

export interface SimpleFilterType {
  affiliations: FilterLabelType[];
  contentValidationStates: FilterLabelType[];
  dimensions: FilterDimensionType[];
  exams: FilterLabelType[];
  labels: (FilterLabelType | FilterHtmlLabelType)[];
  questionTypes: FilterLabelType[];
  revisionStatus: FilterLabelType[];
  supervisors: FilterLabelType[];
}

export interface FilterType {
  simpleFilter: SimpleFilterType;
  advancedFilter: FilterAttributeType[];
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private readonly translate: TranslateService;
  private readonly helper: FilterHelperService;
  private readonly poolGQL: CurrentPoolForFilterGQL;

  constructor(
    translate: TranslateService,
    helper: FilterHelperService,
    poolGQL: CurrentPoolForFilterGQL
  ) {
    this.translate = translate;
    this.helper = helper;
    this.poolGQL = poolGQL;
  }

  public async get(id: Scalars['ID']): Promise<FilterType> {
    const result = await firstValueFrom(this.poolGQL.fetch({ poolId: id }));

    return {
      simpleFilter: this.simpleFilter(result.data.pool),
      advancedFilter: this.advancedFilter(result.data.pool)
    };
  }

  private simpleFilter(
    currentPool: CurrentPoolForFilterQuery['pool']
  ): SimpleFilterType {
    return {
      affiliations: this.helper.affiliationOptions(currentPool.affiliations),
      contentValidationStates: this.helper.contentValidationStateOptions(
        currentPool.languages
      ),
      dimensions: this.helper.dimensionsWithAllCategoriesOptions(
        currentPool.dimensions
      ),
      exams: this.helper.examOptions(currentPool.exams),
      labels: this.helper.labelOptions(currentPool.labels, false),
      questionTypes: this.helper.questionTypeOptions(),
      revisionStatus: this.helper.revisionStatusOptions(
        currentPool.revisionStatus
      ),
      supervisors: this.helper.supervisorOptions(currentPool.supervisors)
    };
  }

  private advancedFilter(
    currentPool: CurrentPoolForFilterQuery['pool']
  ): FilterAttributeType[] {
    return [
      this.helper.numberAttribute(
        this.translate.instant(
          'common.question_list.columns.sequential_number'
        ),
        'sequential_number'
      ),
      this.helper.stringAttribute(
        this.translate.instant('common.question_list.columns.legacy_id'),
        'legacy_id'
      ),
      this.helper.stringAttribute(
        this.translate.instant(
          'common.question_list.columns.literary_reference'
        ),
        'literary_reference'
      ),
      this.helper.selectAttribute(
        this.translate.instant('common.question_list.columns.source_language'),
        'source_language',
        this.helper.languageOptions(currentPool.languages)
      ),
      this.helper.stringAttribute(
        this.translate.instant('common.question_list.columns.title'),
        'title'
      ),
      this.helper.stringAttribute(
        this.translate.instant('common.question_list.columns.supervisor'),
        'supervisor'
      ),
      this.helper.selectAttribute(
        this.translate.instant('common.question_list.columns.affiliation'),
        'affiliation',
        this.helper.affiliationOptions(currentPool.affiliations)
      ),
      this.helper.selectAttribute(
        this.translate.instant('question_groups.advanced_filter.language.name'),
        'language',
        this.helper.contentValidationStateOptions(currentPool.languages, false)
      ),
      this.helper.selectAttribute(
        this.translate.instant(
          'question_groups.advanced_filter.question_group_type.name'
        ),
        'question_group_type',
        this.helper.questionGroupTypeOptions()
      ),
      this.helper.selectAttribute(
        this.translate.instant(
          'question_groups.advanced_filter.question_type.name'
        ),
        'question_type',
        this.helper.questionTypeOptions()
      ),
      this.helper.booleanAttribute(
        this.translate.instant(
          'question_groups.advanced_filter.has_images.name'
        ),
        'has_images'
      ),
      this.helper.booleanAttribute(
        this.translate.instant(
          'question_groups.advanced_filter.has_videos.name'
        ),
        'has_videos'
      ),
      this.helper.booleanAttribute(
        this.translate.instant('activerecord.attributes.comment.read'),
        'all_comments_read'
      ),
      ...this.helper
        .dimensionsWithNoCategoriesOptions(currentPool.dimensions)
        .map(dimension => {
          return this.helper.selectAttribute(
            this.translate.instant(
              `common.question_list.columns.category_${dimension.position}`
            ),
            `dimension_${dimension.position + 1}`,
            dimension.items
          );
        }),
      this.helper.dateAttribute(
        this.translate.instant('common.question_list.columns.created_at'),
        'created_at'
      ),
      this.helper.dateAttribute(
        this.translate.instant('common.question_list.columns.updated_at'),
        'updated_at'
      ),
      this.helper.stringAttribute(
        this.translate.instant('common.question_list.columns.editor'),
        'editor'
      ),
      this.helper.stringAttribute(
        this.translate.instant('common.question_list.columns.author'),
        'author'
      ),
      this.helper.numberAttribute(
        this.translate.instant('common.question_list.columns.number_of_usages'),
        'number_of_usages'
      ),
      this.helper.dateAttribute(
        this.translate.instant('common.question_list.columns.last_usage'),
        'last_usage'
      ),
      this.helper.numberAttribute(
        this.translate.instant(
          'question_groups.advanced_filter.latest_statistic.name'
        ),
        'latest_statistic'
      ),
      this.helper.numberAttribute(
        this.translate.instant(
          'common.question_list.columns.cache_last_usage_n'
        ),
        'statistic_n'
      ),
      this.helper.numberAttribute(
        this.translate.instant(
          'question_groups.advanced_filter.statistic_p.name'
        ),
        'statistic_p'
      ),
      this.helper.numberAttribute(
        this.translate.instant(
          'common.question_list.columns.cache_last_usage_r'
        ),
        'statistic_r'
      ),
      this.helper.booleanAttribute(
        this.translate.instant(
          'question_groups.advanced_filter.statistic_eliminated.name'
        ),
        'statistic_eliminated'
      ),
      this.helper.selectAttribute(
        this.translate.instant('common.question_list.columns.exams'),
        'exam',
        this.helper.examOptions(currentPool.exams)
      ),
      this.helper.selectAttribute(
        this.translate.instant('question_groups.advanced_filter.label.name'),
        'label',
        this.helper.labelOptions(currentPool.labels)
      ),
      this.helper.selectAttribute(
        this.translate.instant('common.question_list.columns.revision_status'),
        'revision_status',
        this.helper.revisionStatusOptions(currentPool.revisionStatus)
      ),
      this.helper.numberAttribute(
        this.translate.instant('common.question_list.columns.revision_year'),
        'revision_year'
      ),
      this.helper.stringAttribute(
        this.translate.instant('question_groups.form.learning_target'),
        'learning_target'
      ),
      this.helper.booleanAttribute(
        this.translate.instant(
          'activerecord.attributes.question_group.duplicates'
        ),
        'duplicates'
      )
    ];
  }
}
