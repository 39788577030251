angular.module('common').factory('Repositories::QuestionGroup', [
  'GraphQLClient',
  'QuestionGroup',
  'Store',
  'Actions::QuestionGroup',
  function(GraphQLClient, QuestionGroup, Store, Actions) {
    var Repository = {},
      client = new GraphQLClient('/api/v1/graphql'),
      questionGroupsQuery =
        'query ($filter: FilterQueryItem, $order: [String!]) {' +
        '  question_group_list(filter: $filter, order: $order) {' +
        '    items {' +
        '      id type legacy_id sequential_number source_language' +
        '      title supervisor affiliation {name} content_validation_state_de content_validation_state_fr' +
        '      content_validation_state_it content_validation_state_en structurally_complete has_images has_videos' +
        '      category_0 { id name } category_1 { id name } category_2 { id name } category_3 { id name }' +
        '      category_4 { id name } category_5 { id name } category_6 { id name } category_7 { id name }' +
        '      created_at updated_at editor { name }' +
        '      author number_of_usages past_exams { name date id } last_usage latest_statistic' +
        '      cache_last_usage_n cache_last_usage_p cache_last_usage_r cache_last_usage_eliminated' +
        '      cache_last_usage_lne' +
        '      labels { id color name } revision_status { id short_name } revision_year' +
        '      has_active_tasks content_changed_since_last_usage metadata_changed_since_last_usage' +
        '      future_exams {id name}' +
        '      duplicates {sequential_number updated_at pool {name}}' +
        '      questions {' +
        '        literary_reference type score' +
        '      }' +
        '    }' +
        '    metadata {' +
        '      from to total_number_of_items filtered_number_of_items all_filtered_ids' +
        '    }' +
        '  }' +
        '}',
      questionGroupsWindowQuery =
        'query ($filter: FilterQueryItem, $order: [String!], $from: Int, $to: Int) {' +
        '  question_group_list(filter: $filter, order: $order, from: $from, to: $to) {' +
        '    items {' +
        '      id type legacy_id sequential_number source_language' +
        '      title supervisor affiliation {name} content_validation_state_de content_validation_state_fr' +
        '      content_validation_state_it content_validation_state_en structurally_complete has_images has_videos' +
        '      category_0 { id name } category_1 { id name } category_2 { id name } category_3 { id name }' +
        '      category_4 { id name } category_5 { id name } category_6 { id name } category_7 { id name }' +
        '      created_at updated_at editor { name }' +
        '      author number_of_usages past_exams { name date id } last_usage' +
        '      cache_last_usage_n cache_last_usage_p cache_last_usage_r cache_last_usage_eliminated' +
        '      cache_last_usage_lne' +
        '      labels { id color name } revision_status { id short_name } revision_year' +
        '      has_active_tasks content_changed_since_last_usage metadata_changed_since_last_usage' +
        '      future_exams {id name}' +
        '      duplicates {sequential_number updated_at pool {name}}' +
        '      latest_statistics {' +
        '        n_min n_max p_min p_max r_min r_max lne_min lne_max' +
        '        last_usage_year_min last_usage_year_max eliminations' +
        '      }' +
        '      questions {' +
        '        literary_reference type score' +
        '      }' +
        '    }' +
        '    metadata {' +
        '      from to total_number_of_items filtered_number_of_items all_filtered_ids' +
        '    }' +
        '  }' +
        '}',
      questionGroupsIdsQuery =
        'query ($filter: FilterQueryItem, $order: [String!], $including_ids: [Int!], $min_number_of_items: Int) {' +
        '  question_group_list(filter: $filter, order: $order, including_ids: $including_ids, min_number_of_items: $min_number_of_items) {' +
        '    items {' +
        '      id type legacy_id sequential_number source_language' +
        '      title supervisor affiliation {name} content_validation_state_de content_validation_state_fr' +
        '      content_validation_state_it content_validation_state_en structurally_complete has_images has_videos' +
        '      category_0 { id name } category_1 { id name } category_2 { id name } category_3 { id name }' +
        '      category_4 { id name } category_5 { id name } category_6 { id name } category_7 { id name }' +
        '      created_at updated_at editor { name }' +
        '      author number_of_usages past_exams { name date id } last_usage' +
        '      cache_last_usage_n cache_last_usage_p cache_last_usage_r cache_last_usage_eliminated' +
        '      cache_last_usage_lne' +
        '      labels { id color name } revision_status { id short_name } revision_year' +
        '      has_active_tasks content_changed_since_last_usage metadata_changed_since_last_usage' +
        '      future_exams {id name}' +
        '      duplicates {sequential_number updated_at pool {name}}' +
        '      latest_statistics {' +
        '        n_min n_max p_min p_max r_min r_max lne_min lne_max' +
        '        last_usage_year_min last_usage_year_max eliminations' +
        '      }' +
        '      questions {' +
        '        literary_reference type score' +
        '      }' +
        '    }' +
        '    metadata {' +
        '      from to total_number_of_items filtered_number_of_items all_filtered_ids' +
        '    }' +
        '  }' +
        '}',
      getQuestionGroupsQuery =
        'query ($ids: [Int!]!) {' +
        '  question_groups(ids: $ids) {' +
        '    id type legacy_id sequential_number source_language' +
        '    title supervisor affiliation {name} content_validation_state_de content_validation_state_fr' +
        '    content_validation_state_it content_validation_state_en structurally_complete has_images has_videos' +
        '    category_0 { id name } category_1 { id name } category_2 { id name } category_3 { id name }' +
        '    category_4 { id name } category_5 { id name } category_6 { id name } category_7 { id name }' +
        '    created_at updated_at editor { name }' +
        '    author number_of_usages past_exams { name date id } last_usage latest_statistic' +
        '    cache_last_usage_n cache_last_usage_p cache_last_usage_r cache_last_usage_eliminated' +
        '    cache_last_usage_lne' +
        '    labels { id color name } revision_status { id short_name } revision_year' +
        '    has_active_tasks content_changed_since_last_usage metadata_changed_since_last_usage' +
        '    future_exams {id name}' +
        '    duplicates {sequential_number updated_at pool {name}}' +
        '    questions {' +
        '      literary_reference type score' +
        '    }' +
        '  }' +
        '}',
      getQuestionGroupDetailsQuery =
        'query ($ids: [Int!]!) {' +
        '  question_groups(ids: $ids) { id' +
        '    messages {' +
        '      id created_at' +
        '      ... on Comment {type text author {first_name last_name} read}' +
        '      ... on SystemMessage {type text}' +
        '    }' +
        '    previews {' +
        '      ready url_de url_fr url_it url_en' +
        '    }' +
        '    statistics {' +
        '      exam { name date }' +
        '      total { n p_gp p_hp r lne eliminated }' +
        '      subgroups { name n p_gp p_hp r lne eliminated }' +
        '      questions {' +
        '        question { type position }' +
        '        total { n p_gp p_hp r lne eliminated }' +
        '        subgroups { name n p_gp p_hp r lne eliminated }' +
        '        diverter {' +
        '          A {p solution} B {p solution} C {p solution}' +
        '          D {p solution} E {p solution}' +
        '        }' +
        '      }' +
        '    }' +
        '  }' +
        '}',
      getQuestionGroupPreviewsQuery =
        'query ($ids: [Int!]!) {' +
        '  question_groups(ids: $ids) {' +
        '    previews {' +
        '      ready url_de url_fr url_it url_en' +
        '    }' +
        '  }' +
        '}',
      getAuthorsQuery =
        'query($query: String!) {' + '  authors(query: $query) {' + '    name' + '  }' + '}',
      getSupervisorsQuery =
        'query($query: String!) {' + '  supervisors(query: $query) {' + '    name' + '  }' + '}',
      duplicateQuery =
        'mutation($question_group_id: Int!, $pool_id: Int!) {' +
        '  duplicate_question_group(question_group_id: $question_group_id, pool_id: $pool_id) {' +
        '    question_group {id duplicates {sequential_number updated_at pool {name}}}' +
        '  }' +
        '}',
      deleteQuery =
        'mutation($question_group_ids: [Int!]!) {' +
        '  delete_question_groups(question_group_ids: $question_group_ids) {' +
        '    question_groups {id}' +
        '  }' +
        '}';

    Repository.query = function(filter, order, options) {
      var requestOptions, query;
      options = options || {};
      if (options.from !== undefined && options.to !== undefined) {
        requestOptions = { filter: filter, order: order, from: options.from, to: options.to };
        query = questionGroupsWindowQuery;
      } else if (options.includingIds !== undefined && options.minNumberOfItems !== undefined) {
        requestOptions = {
          filter: filter,
          order: order,
          including_ids: options.includingIds,
          min_number_of_items: options.minNumberOfItems
        };
        query = questionGroupsIdsQuery;
      } else {
        requestOptions = {
          filter: filter,
          order: order
        };
        query = questionGroupsQuery;
      }
      return client.query(query, requestOptions).then(function(response) {
        return {
          items: response.data.data.question_group_list.items || [],
          metadata: {
            totalNumberOfItems:
              response.data.data.question_group_list.metadata.total_number_of_items,
            filteredNumberOfItems:
              response.data.data.question_group_list.metadata.filtered_number_of_items,
            allFilteredIds: response.data.data.question_group_list.metadata.all_filtered_ids || []
          },
          itemWindow: {
            from: response.data.data.question_group_list.metadata.from,
            to: response.data.data.question_group_list.metadata.to
          }
        };
      });
    };

    Repository.get = function(id) {
      return client.query(getQuestionGroupsQuery, { ids: [id] }).then(function(response) {
        return response.data.data.question_groups[0];
      });
    };

    Repository.getDetails = function(id) {
      return client.query(getQuestionGroupDetailsQuery, { ids: [id] }).then(function(response) {
        return response.data.data.question_groups[0];
      });
    };

    Repository.getPreviews = function(id) {
      return client.query(getQuestionGroupPreviewsQuery, { ids: [id] }).then(function(response) {
        return response.data.data.question_groups[0].previews;
      });
    };

    Repository.getAuthors = function(query) {
      return client.query(getAuthorsQuery, { query: query }).then(function(response) {
        return response.data.data.authors;
      });
    };

    Repository.getSupervisors = function(query) {
      return client.query(getSupervisorsQuery, { query: query }).then(function(response) {
        return response.data.data.supervisors;
      });
    };

    Repository.duplicate = function(questionGroup, pool) {
      return client
        .query(duplicateQuery, { question_group_id: questionGroup.id, pool_id: pool.id })
        .then(function(response) {
          Store.dispatch(
            Actions.updateQuestionGroups([
              response.data.data.duplicate_question_group.question_group
            ])
          );
          return response;
        });
    };

    Repository.delete = function(questionGroups) {
      var ids = questionGroups.map(function(questionGroup) {
        return questionGroup.id;
      });
      return client.query(deleteQuery, { question_group_ids: ids }).then(function(response) {
        questionGroups.forEach(function(questionGroup) {
          questionGroup.destroyed = true;
        });
        return response;
      });
    };

    return Repository;
  }
]);
