<table class="table table-striped">
  <thead>
    <tr>
      <th>
        {{ 'common.question_list.columns.sequential_number' | translate }}
      </th>
      <th>{{ 'common.question_list.columns.supervisor' | translate }}</th>
      <th>
        {{ 'common.question_list.columns.question_types' | translate }}
      </th>
      <th *ngIf="dimensions.length > 0">
        {{ 'common.question_list.columns.category_0' | translate }}
      </th>
      <th *ngIf="dimensions.length > 1">
        {{ 'common.question_list.columns.category_1' | translate }}
      </th>
      <th *ngIf="dimensions.length > 2">
        {{ 'common.question_list.columns.category_2' | translate }}
      </th>
      <th *ngIf="dimensions.length > 3">
        {{ 'common.question_list.columns.category_3' | translate }}
      </th>
      <th *ngIf="dimensions.length > 4">
        {{ 'common.question_list.columns.category_4' | translate }}
      </th>
      <th *ngIf="dimensions.length > 5">
        {{ 'common.question_list.columns.category_5' | translate }}
      </th>
      <th *ngIf="dimensions.length > 6">
        {{ 'common.question_list.columns.category_6' | translate }}
      </th>
      <th *ngIf="dimensions.length > 7">
        {{ 'common.question_list.columns.category_7' | translate }}
      </th>
      <th>{{ 'common.question_list.columns.exams' | translate }}</th>
      <th>
        {{ 'common.question_list.columns.revision_status' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let questionGroup of questionGroups">
      <td>{{ questionGroup.sequentialNumber }}</td>
      <td>{{ questionGroup.supervisor }}</td>
      <td>
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{
            'questions.type.' + $any(questionGroup).question.type | translate
          }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <span *ngFor="let question of $any(questionGroup).questions">
            {{ 'questions.type.' + question.type | translate }}<br />
          </span>
        </ng-container>
      </td>
      <td *ngIf="dimensions.length > 0">
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{ $any(questionGroup).question.category0?.name }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <ng-container *ngFor="let question of $any(questionGroup).questions">
            <ng-container *ngIf="question.category0">
              {{ question.category0.name }}<br />
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
      <td *ngIf="dimensions.length > 1">
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{ $any(questionGroup).question.category1?.name }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <ng-container *ngFor="let question of $any(questionGroup).questions">
            <ng-container *ngIf="question.category1">
              {{ question.category1.name }}<br />
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
      <td *ngIf="dimensions.length > 2">
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{ $any(questionGroup).question.category2?.name }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <ng-container *ngFor="let question of $any(questionGroup).questions">
            <ng-container *ngIf="question.category2">
              {{ question.category2.name }}<br />
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
      <td *ngIf="dimensions.length > 3">
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{ $any(questionGroup).question.category3?.name }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <ng-container *ngFor="let question of $any(questionGroup).questions">
            <ng-container *ngIf="question.category3">
              {{ question.category3.name }}<br />
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
      <td *ngIf="dimensions.length > 4">
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{ $any(questionGroup).question.category4?.name }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <ng-container *ngFor="let question of $any(questionGroup).questions">
            <ng-container *ngIf="question.category4">
              {{ question.category4.name }}<br />
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
      <td *ngIf="dimensions.length > 5">
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{ $any(questionGroup).question.category5?.name }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <ng-container *ngFor="let question of $any(questionGroup).questions">
            <ng-container *ngIf="question.category5">
              {{ question.category5.name }}<br />
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
      <td *ngIf="dimensions.length > 6">
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{ $any(questionGroup).question.category6?.name }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <ng-container *ngFor="let question of $any(questionGroup).questions">
            <ng-container *ngIf="question.category6">
              {{ question.category6.name }}<br />
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
      <td *ngIf="dimensions.length > 7">
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{ $any(questionGroup).question.category7?.name }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <ng-container *ngFor="let question of $any(questionGroup).questions">
            <ng-container *ngIf="question.category7">
              {{ question.category7.name }}<br />
            </ng-container>
          </ng-container>
        </ng-container>
      </td>

      <td>
        <ng-container *ngFor="let futureExam of questionGroup.futureExams">
          {{ futureExam.name }}<br />
        </ng-container>
      </td>
      <td>{{ questionGroup.revisionStatus?.shortName }}<br /></td>
    </tr>
  </tbody>
</table>
