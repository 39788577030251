angular.module('taskManagement').factory('Reducers::TaskQuestionGroupList', [
  'Actions::TaskQuestionGroupList',
  'Actions::QuestionGroup',
  function(Actions, QuestionGroupActions) {
    function unique(element, index, array) {
      return array.lastIndexOf(element) == index;
    }

    function reducer(state, action) {
      var columns, selection;

      state = state || {};

      switch (action.type) {
        case Actions.SET_COLUMN_DEFINITIONS:
          return Object.assign({}, state, {
            columns: action.columns
          });

        case Actions.SET_COLUMN_VISIBILITY:
          columns = Object.assign({}, state.columns);
          columns[action.column].visible = action.visible;
          return Object.assign({}, state, {
            columns: columns
          });

        case Actions.SET_SELECTION:
          selection = (action.ids || []).filter(function(id) {
            return id;
          });
          return Object.assign({}, state, { selection: selection });

        case Actions.ADD_TO_SELECTION:
          return Object.assign({}, state, {
            selection: (state.selection || []).concat(action.ids).filter(unique)
          });

        case Actions.REMOVE_FROM_SELECTION:
          return Object.assign({}, state, {
            selection: (state.selection || []).filter(function(id) {
              return id != action.id;
            })
          });

        case Actions.SET_ORDER:
          return Object.assign({}, state, {
            order: action.order
          });

        case Actions.FETCH_ITEMS_REQUEST:
          return Object.assign({}, state, { requestInProgress: true });

        case Actions.FETCH_ITEMS_SUCCESS:
          var itemIds = action.items.map(function(item) {
            return item.id;
          });
          selection = (state.selection || []).filter(function(id) {
            return (itemIds || []).includes(id);
          });

          return Object.assign({}, state, {
            requestInProgress: false,
            items: action.items,
            selection: selection
          });

        case QuestionGroupActions.UPDATE_QUESTION_GROUPS:
          return Object.assign({}, state, {
            items: (state.items || []).map(function(item) {
              var matchingQuestionGroup = action.questionGroups.find(function(i) {
                return item.question_group.id == i.id;
              });
              if (matchingQuestionGroup === undefined) {
                return item;
              } else {
                return Object.assign({}, item, {
                  question_group: Object.assign({}, item.question_group, matchingQuestionGroup)
                });
              }
            })
          });

        case QuestionGroupActions.REMOVE_LABEL:
          return Object.assign({}, state, {
            items: (state.items || []).map(function(item) {
              return Object.assign({}, item, {
                question_group: Object.assign({}, item.question_group, {
                  labels: item.question_group.labels.filter(function(label) {
                    return label.id !== action.labelId;
                  })
                })
              });
            })
          });

        default:
          return state;
      }
    }

    return reducer;
  }
]);
