<co-base-confirm-modal
  [title]="'question_management.task_modal.confirmation.title' | translate"
  (dismiss)="onDismiss()"
  (confirm)="onConfirm()"
>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.task_modal.confirmation.receiver' | translate }}
    </dt>
    <dd class="col-sm-9 text-break">
      <div *ngFor="let user of users">
        {{ user.firstName }} {{ user.lastName }} <{{ user.email }}>
      </div>
    </dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.task_modal.confirmation.subject' | translate }}
    </dt>
    <dd class="col-sm-9">{{ subject }}</dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.task_modal.confirmation.body' | translate }}
    </dt>
    <dd class="col-sm-9 text-raw text-break">{{ body }}</dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{
        'question_management.translate_task_modal.target_language' | translate
      }}
    </dt>
    <dd class="col-sm-9 text-break">
      {{ 'scrudu.languages.long.' + targetLanguage | translate }}
    </dd>
  </dl>
</co-base-confirm-modal>
