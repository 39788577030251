import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { takeGraphQLResult } from '../../../common/operators/take-graphql-response';
import { ToastsService } from '../../../common/services/toasts.service';
import { parseError } from '../../../common/utils/error-parser';
import { RemoteData, RequestState } from '../../../common/utils/remote-data';
import { isDefined } from '../../../common/utils/type-guards/is-defined';
import { ModalService } from '../../common/modal/modal.service';
import { CreateAnnouncementGQL } from '../../services/create-announcement.generated';
import { DeleteAnnouncementGQL } from '../../services/delete-announcement.generated';
import {
  AnnouncementListElementFragment,
  LoadAnnouncementsGQL
} from '../../services/load-announcements.generated';
import { UpdateAnnouncementGQL } from '../../services/update-announcement.generated';
import {
  CreateAnnouncement,
  CreateAnnouncementFailure,
  CreateAnnouncementSuccess,
  DeleteAnnouncement,
  DeleteAnnouncementFailure,
  DeleteAnnouncementSuccess,
  LoadAnnouncements,
  LoadAnnouncementsFailure,
  LoadAnnouncementsSuccess,
  UpdateAnnouncement,
  UpdateAnnouncementFailure,
  UpdateAnnouncementSuccess
} from './announcements.actions';

export interface AnnouncementsStateModel {
  announcements: RemoteData<AnnouncementListElementFragment[]>;
  mutation: RemoteData<void>;
}

@State<AnnouncementsStateModel>({
  name: 'announcements',
  defaults: {
    announcements: {
      requestState: 'initial'
    },
    mutation: {
      requestState: 'initial'
    }
  }
})
@Injectable({
  providedIn: 'root'
})
export class AnnouncementsState {
  constructor(
    private readonly loadAnnouncementsService: LoadAnnouncementsGQL,
    private readonly createAnnouncementsService: CreateAnnouncementGQL,
    private readonly updateAnnouncementsService: UpdateAnnouncementGQL,
    private readonly deleteAnnouncementsService: DeleteAnnouncementGQL,
    private readonly toastsService: ToastsService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService
  ) {}

  @Selector()
  public static mutationRequestState(
    state: AnnouncementsStateModel
  ): RequestState {
    return state.mutation.requestState;
  }

  @Selector()
  public static announcements(
    state: AnnouncementsStateModel
  ): RemoteData<AnnouncementListElementFragment[]> {
    return { ...state.announcements, actions: { retry: LoadAnnouncements } };
  }

  @Action(LoadAnnouncements)
  public loadAnnouncements(
    ctx: StateContext<AnnouncementsStateModel>
  ): Observable<void> {
    ctx.patchState({ announcements: { requestState: 'loading' } });

    return this.loadAnnouncementsService.fetch().pipe(
      switchMap(res =>
        ctx.dispatch(new LoadAnnouncementsSuccess(res.data.announcements))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadAnnouncementsFailure(parseError(err)))
      )
    );
  }

  @Action(LoadAnnouncementsSuccess)
  public loadAnnouncementsSuccess(
    ctx: StateContext<AnnouncementsStateModel>,
    { announcements }: LoadAnnouncementsSuccess
  ): void {
    ctx.patchState({
      announcements: {
        data: announcements,
        requestState: 'success'
      }
    });
  }

  @Action(LoadAnnouncementsFailure)
  public loadAnnouncementsFailure(
    ctx: StateContext<AnnouncementsStateModel>,
    { error }: LoadAnnouncementsFailure
  ): void {
    ctx.patchState({
      announcements: { requestState: 'failure', error }
    });
  }

  @Action(CreateAnnouncement)
  public createAnnoucement(
    ctx: StateContext<AnnouncementsStateModel>,
    { attributes }: CreateAnnouncement
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.createAnnouncementsService.mutate({ attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.createAnnouncement?.announcement),
      filter(isDefined),
      mergeMap(({ id, message, startsAt, endsAt, severity, enabled }) =>
        ctx.dispatch(
          new CreateAnnouncementSuccess({
            id,
            message,
            startsAt,
            endsAt,
            severity,
            enabled
          })
        )
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new CreateAnnouncementFailure(parseError(err), attributes))
      )
    );
  }

  @Action(CreateAnnouncementSuccess)
  public createAnnouncementSuccess(
    ctx: StateContext<AnnouncementsStateModel>,
    { announcement }: CreateAnnouncementSuccess
  ): void {
    const state = patch<AnnouncementsStateModel>({
      mutation: patch({
        requestState: 'success'
      }),
      announcements: patch({
        data: append([announcement])
      })
    });

    ctx.setState(state);

    this.toastsService.addSuccess(
      this.translateService.instant('admin.announcements.notification.created')
    );
  }

  @Action(CreateAnnouncementFailure)
  public createAnnouncementFailure(
    ctx: StateContext<AnnouncementsStateModel>,
    { error, attributes }: CreateAnnouncementFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (retry) {
        ctx.dispatch(new CreateAnnouncement(attributes));
      }
    });
  }

  @Action(UpdateAnnouncement)
  public updateAnnouncement(
    ctx: StateContext<AnnouncementsStateModel>,
    { id, attributes }: UpdateAnnouncement
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.updateAnnouncementsService.mutate({ id, attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.updateAnnouncement?.announcement),
      filter(isDefined),
      mergeMap(({ id, message, startsAt, endsAt, severity, enabled }) =>
        ctx.dispatch(
          new UpdateAnnouncementSuccess({
            id,
            message,
            startsAt,
            endsAt,
            severity,
            enabled
          })
        )
      ),
      catchError((err: unknown) =>
        ctx.dispatch(
          new UpdateAnnouncementFailure(parseError(err), id, attributes)
        )
      )
    );
  }

  @Action(UpdateAnnouncementSuccess)
  public updateAnnouncementSuccess(
    ctx: StateContext<AnnouncementsStateModel>,
    { announcement }: UpdateAnnouncementSuccess
  ): void {
    const state = patch<AnnouncementsStateModel>({
      mutation: patch({
        requestState: 'success'
      }),
      announcements: patch({
        data: updateItem(a => a?.id === announcement.id, announcement)
      })
    });

    ctx.setState(state);

    this.toastsService.addSuccess(
      this.translateService.instant('admin.announcements.notification.updated')
    );
  }

  @Action(UpdateAnnouncementFailure)
  public updateAnnouncementFailure(
    ctx: StateContext<AnnouncementsStateModel>,
    { error, id, attributes }: UpdateAnnouncementFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (retry) {
        ctx.dispatch(new UpdateAnnouncement(id, attributes));
      }
    });
  }

  @Action(DeleteAnnouncement)
  public deleteAnnouncement(
    ctx: StateContext<AnnouncementsStateModel>,
    { id }: DeleteAnnouncement
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.deleteAnnouncementsService.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeleteAnnouncementSuccess(id))),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteAnnouncementFailure(parseError(err), id))
      )
    );
  }

  @Action(DeleteAnnouncementSuccess)
  public deleteAnnouncementSuccess(
    ctx: StateContext<AnnouncementsStateModel>,
    { id }: DeleteAnnouncementSuccess
  ): void {
    ctx.setState(
      patch<AnnouncementsStateModel>({
        mutation: patch({
          requestState: 'success'
        }),
        announcements: patch({
          data: removeItem(a => a?.id === id)
        })
      })
    );

    this.toastsService.addSuccess(
      this.translateService.instant('admin.announcement.notification.deleted')
    );
  }

  @Action(DeleteAnnouncementFailure)
  public deleteAnnouncementFailure(
    ctx: StateContext<AnnouncementsStateModel>,
    { error, id }: DeleteAnnouncementFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });

    this.modalService.error({ errorCode: error.code }).then(retry => {
      if (retry) {
        ctx.dispatch(new DeleteAnnouncement(id));
      }
    });
  }
}
