import {
  QuestionGroupMessageCommentFragment,
  QuestionGroupMessageFragment,
  QuestionGroupMessageSystemMessageQuestionGroupUpdatedFragment,
  QuestionGroupMessageSystemMessageQuestionTypeTransformedFragment,
  QuestionGroupMessageSystemMessageStatisticsDeletedFragment
} from '../../../question-management/services/question-group-messages.generated';

export function isComment(
  message: QuestionGroupMessageFragment
): message is QuestionGroupMessageCommentFragment {
  return message.__typename === 'Comment';
}

export function isSystemMessageQuestionGroupUpdated(
  message: QuestionGroupMessageFragment
): message is QuestionGroupMessageSystemMessageQuestionGroupUpdatedFragment {
  return message.__typename === 'SystemMessageQuestionGroupUpdated';
}

export function isSystemMessageStatisticsDeleted(
  message: QuestionGroupMessageFragment
): message is QuestionGroupMessageSystemMessageStatisticsDeletedFragment {
  return message.__typename === 'SystemMessageStatisticsDeleted';
}

export function isSystemMessageQuestionTypeTransformed(
  message: QuestionGroupMessageFragment
): message is QuestionGroupMessageSystemMessageQuestionTypeTransformedFragment {
  return message.__typename === 'SystemMessageQuestionTypeTransformed';
}
