/* eslint-disable unicorn/no-null */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Fuse, { IFuseOptions } from 'fuse.js';
import type { UploaderOptions, UploadInput, UploadOutput } from 'ngx-uploader';
import { utils } from 'xlsx';
import {
  Language,
  LongMenuListEntryInput,
  QuestionLongMenuInput,
  ValidationsMessage
} from '../../../../generated/base-types';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../../common/utils/ng-bootstrap-modal';
import { parseBlobAsWorksheet } from '../../../common/utils/xlsx';
import { RemoveListConfirmModalComponent } from '../../downgraded/components/remove-list-confirm-modal/remove-list-confirm-modal.component';
import { ReplaceListConfirmModalComponent } from '../../downgraded/components/replace-list-confirm-modal/replace-list-confirm-modal.component';
import { LongMenuListValidatorService } from '../../services/long-menu-list-validator.service';

export enum LongMenuListHeaders {
  Code = 'code',
  En = 'en',
  Fr = 'fr',
  De = 'de',
  It = 'it'
}

enum ListActions {
  Replace = 'replace',
  Remove = 'remove'
}

export interface ImportedLongMenuEntry {
  [LongMenuListHeaders.Code]: string | undefined;
  [LongMenuListHeaders.De]: string | undefined;
  [LongMenuListHeaders.Fr]: string | undefined;
  [LongMenuListHeaders.It]: string | undefined;
  [LongMenuListHeaders.En]: string | undefined;
}

@Component({
  selector: 'qf-question-long-menu-list',
  templateUrl: './question-long-menu-list.component.html',
  styleUrls: ['./question-long-menu-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionLongMenuListComponent),
      multi: true
    }
  ]
})
export class QuestionLongMenuListComponent implements ControlValueAccessor {
  @Input() public validations: ValidationsMessage[] | undefined;
  @Input() public languages: Language[];
  @Input() public solution: QuestionLongMenuInput['solution'];

  @Input() public selectedEntryId?: number;
  @Output() public selectedEntryIdChange = new EventEmitter<
    number | undefined
  >();

  @Output() public languageChanged = new EventEmitter<Language>();
  @Output() public listChangeEvent = new EventEmitter<boolean>();

  public list: LongMenuListEntryInput[] | null;
  public listLanguage: Language;
  public options: UploaderOptions = {
    concurrency: 1,
    maxUploads: 100,
    maxFileSize: 1_000_000,
    allowedContentTypes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]
  };
  public uploadInput = new EventEmitter<UploadInput>();
  public dragOver: boolean;
  public sheetLanguages: Language[] | undefined;
  public filterKeyword?: string;
  public filteredList?: LongMenuListEntryInput[];
  public fuzzySearch: Fuse<LongMenuListEntryInput>;
  private fuzzySearchOptions: IFuseOptions<LongMenuListEntryInput> = {
    keys: ['code', 'label', 'name.en', 'name.de', 'name.fr', 'name.it']
  };

  constructor(
    private ref: ChangeDetectorRef,
    private validator: LongMenuListValidatorService,
    public readonly ngbModal: NgbModal
  ) {}

  public writeValue(value: LongMenuListEntryInput[] | null): void {
    // eslint-disable-next-line unicorn/no-null
    this.list = value;
    if (value && value.length > 0) {
      this.sheetLanguages = Object.entries(value[0].name).reduce(
        (acc, [key, value]) =>
          value === undefined ? acc : [...acc, key as Language],
        [] as Language[]
      );
      this.setListLanguage(this.sheetLanguages[0]);
      this.updateFuzzySearch(value);
    }
  }

  public registerOnChange(
    fn: (input: LongMenuListEntryInput[] | null) => void
  ): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public setListLanguage(language: Language): void {
    this.listLanguage = language;
    this.languageChanged.emit(language);
  }

  public setSelectedEntryId(id: number): void {
    this.selectedEntryId = id;
    this.selectedEntryIdChange.emit(id);
  }

  public async onUploadOutput(output: UploadOutput): Promise<void> {
    switch (output.type) {
      case 'addedToQueue': {
        if (output.file?.nativeFile) {
          if (this.list) {
            const proceed = !(await this.showConfirmModal(ListActions.Replace));
            if (!proceed) {
              return;
            }
          }

          await this.parseXlsFile(output.file.nativeFile);
        }
        break;
      }
      case 'dragOver': {
        this.dragOver = true;
        break;
      }
      case 'dragOut':
      case 'drop': {
        this.dragOver = false;
        break;
      }
    }
  }

  public search(term: string): void {
    if (term.length > 0) {
      this.filterKeyword = term;
      this.filteredList = this.fuzzySearch
        .search(term)
        .map(result => result.item);
    } else {
      this.clearSearch();
    }
  }

  public clearSearch(): void {
    this.filteredList = this.list || [];
    this.filterKeyword = undefined;
  }

  public async removeList(): Promise<void> {
    const proceed = !(await this.showConfirmModal(ListActions.Remove));
    if (!proceed) {
      return;
    }
    this.filteredList = undefined;
    this.writeValue(null);
    this.onChange(null);
    this.onTouch();
    this.ref.markForCheck();
  }

  public isUsedInSolution(id: number): boolean {
    return (this.solution ?? []).flat().includes(id);
  }

  private showConfirmModal(listAction: ListActions): Promise<boolean> {
    const modal =
      listAction === ListActions.Remove
        ? RemoveListConfirmModalComponent
        : ReplaceListConfirmModalComponent;

    const ref = this.ngbModal.open(modal, NG_MODAL_DEFAULT_OPTIONS);

    ref.componentInstance.modalInstance = ref;

    return ref.result
      .then((result: boolean) => {
        this.listChangeEvent.emit(result);

        return result;
      })
      .catch(() => {
        return true;
      });
  }

  private async parseXlsFile(file: File): Promise<void> {
    const worksheet = await parseBlobAsWorksheet(file);
    const requiredHeaders = this.languages as unknown as LongMenuListHeaders[];
    this.validations = this.validator.validate(worksheet, requiredHeaders);
    this.ref.markForCheck();

    if (this.validations?.length > 0) {
      return;
    }

    const content = utils.sheet_to_json<ImportedLongMenuEntry>(worksheet);
    const parsedData = this.transformImportedData(content);

    if (parsedData.length > 0) {
      this.sheetLanguages = Object.keys(parsedData[0].name) as Language[];
      this.setListLanguage(this.sheetLanguages[0]);
    }

    this.list = parsedData;
    this.onChange(parsedData);
    this.onTouch();
    this.updateFuzzySearch(parsedData);
    this.ref.markForCheck();
  }

  private transformImportedData(
    data: ImportedLongMenuEntry[]
  ): LongMenuListEntryInput[] {
    return data.map((entry, index) => ({
      id: index,
      position: index + 1,
      code: entry[LongMenuListHeaders.Code],
      name: {
        en: entry[LongMenuListHeaders.En],
        de: entry[LongMenuListHeaders.De],
        fr: entry[LongMenuListHeaders.Fr],
        it: entry[LongMenuListHeaders.It]
      }
    }));
  }

  private updateFuzzySearch(list: LongMenuListEntryInput[]): void {
    this.clearSearch();
    this.fuzzySearch = new Fuse(list, this.fuzzySearchOptions);
  }

  private onChange: (input: LongMenuListEntryInput[] | null) => void = () =>
    void 0;
  private onTouch: () => void = () => void 0;
}
