import { Injectable } from '@angular/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Language } from '../../generated/base-types';
import { takeGraphQLResult } from '../common/operators/take-graphql-response';
import { parseError } from '../common/utils/error-parser';
import { assertIsDefined } from '../common/utils/type-guards/is-defined';
import {
  LoadPoolGQL,
  PoolAffiliationFragment,
  PoolDimensionFragment,
  PoolLabelFragment,
  PoolMetadataFragment,
  PoolRevisionStatusFragment
} from '../services/load-pool.generated';
import { AppContextState } from './app-context.state';
import {
  LoadPool,
  LoadPoolFailure,
  LoadPoolSuccess,
  ReloadPool
} from './pool.actions';
import { PoolStateModel } from './pool.state.model';
import {
  SetExamColumnConfig,
  SetQuestionColumnConfig,
  SetTaskColumnConfig,
  SetTaskQuestionColumnConfig
} from './settings.actions';

@State<PoolStateModel>({
  name: 'pool',
  defaults: {
    requestState: 'initial'
  }
})
@Injectable({ providedIn: 'root' })
export class PoolState {
  constructor(
    private readonly loadPoolService: LoadPoolGQL,
    private readonly store: Store
  ) {}

  @Selector()
  public static pool(state: PoolStateModel): PoolMetadataFragment | undefined {
    return state.data;
  }

  @Selector()
  public static affiliations(state: PoolStateModel): PoolAffiliationFragment[] {
    return state.data?.affiliations || [];
  }

  @Selector()
  public static dimensions(state: PoolStateModel): PoolDimensionFragment[] {
    return state.data?.dimensions || [];
  }

  @Selector()
  public static labels(state: PoolStateModel): PoolLabelFragment[] {
    return state.data?.labels || [];
  }

  @Selector()
  public static revisionStatuses(
    state: PoolStateModel
  ): PoolRevisionStatusFragment[] {
    return state.data?.revisionStatus || [];
  }

  @Selector()
  public static languages(state: PoolStateModel): Language[] {
    return state.data?.languages || [];
  }

  @Selector()
  public static supervisors(state: PoolStateModel): string[] {
    return state.data?.supervisors || [];
  }

  @Action(LoadPool)
  public loadPool(
    ctx: StateContext<PoolStateModel>,
    action: LoadPool
  ): Observable<void> {
    ctx.patchState({ requestState: 'loading' });

    return this.loadPoolService.fetch({ poolId: action.id }).pipe(
      takeGraphQLResult(),
      switchMap(({ pool }) => ctx.dispatch(new LoadPoolSuccess(pool))),
      catchError((error: unknown) =>
        ctx.dispatch(new LoadPoolFailure(parseError(error)))
      )
    );
  }

  @Action(LoadPoolSuccess)
  public loadPoolSuccess(
    ctx: StateContext<PoolStateModel>,
    action: LoadPoolSuccess
  ): void {
    ctx.patchState({
      data: action.pool,
      requestState: 'success'
    });

    ctx.dispatch([
      new SetQuestionColumnConfig(),
      new SetTaskColumnConfig(),
      new SetTaskQuestionColumnConfig(),
      new SetExamColumnConfig()
    ]);
  }

  @Action(LoadPoolFailure)
  public loadPoolFailure(
    ctx: StateContext<PoolStateModel>,
    action: LoadPoolFailure
  ): void {
    ctx.patchState({
      requestState: 'failure',
      error: action.error,
      data: undefined
    });
  }

  @Action(ReloadPool)
  public reloadPool(_ctx: StateContext<PoolStateModel>): void {
    const currentPoolId = this.store.selectSnapshot(
      AppContextState.currentPoolId
    );

    assertIsDefined(currentPoolId);

    this.store.dispatch(new LoadPool(currentPoolId));
  }
}
