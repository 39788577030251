<div ngbDropdown>
  <button class="btn btn-xs btn-primary" ngbDropdownToggle>
    <ng-content></ng-content>
  </button>
  <ul class="column-menu" ngbDropdownMenu>
    <li class="column-item" *ngFor="let column of columns$ | async">
      <co-checkbox
        class="column-checkbox"
        [checked]="column.visible"
        (changed)="onToggleVisibility(column)"
      >
        {{
          'activerecord.attributes.question_group.' + column.translationKeyName
            | translate : column.translationParams
        }}
        ({{
          'common.question_list.columns.' + column.translationKeyName
            | translate
        }})
      </co-checkbox>
    </li>
  </ul>
</div>
