import { Pool } from 'src/generated/base-types';
import { ManagerError } from '../common/utils/error-parser';
import { PoolMetadataFragment } from '../services/load-pool.generated';

export class LoadPool {
  public static readonly type = '[Pool] Load Pool';
  constructor(public readonly id: Pool['id']) {}
}

export class LoadPoolSuccess {
  public static readonly type = '[Pool] Load Pool Success';
  constructor(public pool: PoolMetadataFragment) {}
}

export class LoadPoolFailure {
  public static readonly type = '[Pool] Load Pool Failure';
  constructor(public readonly error: ManagerError) {}
}

export class ReloadPool {
  public static readonly type = '[Pool] Reload Pool Failure';
}
