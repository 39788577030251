angular.module('settings').factory('Actions::OauthApplicationList', function() {
  var actions = {
    FETCH_OAUTH_APPLICATIONS_REQUEST: 'OAUTH_APPLICATIONS_LIST_FETCH_APPLICATIONS_REQUEST',
    FETCH_OAUTH_APPLICATIONS_SUCCESS: 'OAUTH_APPLICATIONS_LIST_FETCH_APPLICATIONS_SUCCESS',
    SET_OAUTH_APPLICATION_SELECTION: 'OAUTH_APPLICATIONS_LIST_SET_APPLICATION_SELECTION',
    ADD_OAUTH_APPLICATION: 'OAUTH_APPLICATIONS_LIST_ADD_APPLICATION',
    UPDATE_OAUTH_APPLICATION: 'OAUTH_APPLICATIONS_LIST_UPDATE_APPLICATION',
    DELETE_OAUTH_APPLICATION: 'OAUTH_APPLICATIONS_LIST_DELETE_APPLICATION'
  };

  function fetchApplicationsRequest() {
    return {
      type: actions.FETCH_OAUTH_APPLICATIONS_REQUEST
    };
  }

  function fetchApplicationsSucccess(applications) {
    return {
      type: actions.FETCH_OAUTH_APPLICATIONS_SUCCESS,
      applications: applications
    };
  }

  function setApplicationSelection(uid) {
    return {
      type: actions.SET_OAUTH_APPLICATION_SELECTION,
      selectedUid: uid
    };
  }

  function addApplication(application) {
    return {
      type: actions.ADD_OAUTH_APPLICATION,
      application: application
    };
  }

  function updateApplication(application) {
    return {
      type: actions.UPDATE_OAUTH_APPLICATION,
      application: application
    };
  }

  function deleteApplication(uid) {
    return {
      type: actions.DELETE_OAUTH_APPLICATION,
      uid: uid
    };
  }

  return Object.assign(
    {
      fetchApplicationsRequest: fetchApplicationsRequest,
      fetchApplicationsSucccess: fetchApplicationsSucccess,
      setApplicationSelection: setApplicationSelection,
      addApplication: addApplication,
      updateApplication: updateApplication,
      deleteApplication: deleteApplication
    },
    actions
  );
});
