import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CsvGeneratorService } from '../../common/services/csv-export-generator.service';
import {
  ExportColumnDefinition,
  commonColumnDefinitions
} from '../../common/services/export-column-definition';
import { FileSaverService } from '../../common/services/file-saver.service';
import { XlsxExportGeneratorService } from '../../common/services/xlsx-export-generator.service';
import { ExportTaskQuestionGroupFragment } from '../modals/task-question-group-export-modal/task-question-group-export.generated';

@Injectable({
  providedIn: 'root'
})
export class TaskQuestionGroupExporterService {
  constructor(
    private translate: TranslateService,
    private fileSaver: FileSaverService
  ) {}

  public saveAsCsv(
    taskQuestionGroups: ExportTaskQuestionGroupFragment[]
  ): void {
    const generator = new CsvGeneratorService<ExportTaskQuestionGroupFragment>(
      this.getColumnDefinitions()
    );
    generator.addObjects(taskQuestionGroups);
    this.fileSaver.saveStringAs(
      generator.toString(),
      'task-question-groups.csv',
      'text/csv'
    );
  }

  public saveAsXlsx(
    taskQuestionGroups: ExportTaskQuestionGroupFragment[]
  ): void {
    const generator =
      new XlsxExportGeneratorService<ExportTaskQuestionGroupFragment>(
        this.getColumnDefinitions()
      );
    generator.addObjects(taskQuestionGroups);
    this.fileSaver.saveStringAs(
      generator.toString(),
      'task-question-groups.xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  }

  private getColumnDefinitions(): ExportColumnDefinition<ExportTaskQuestionGroupFragment>[] {
    return [
      ...commonColumnDefinitions(
        this.translate,
        (o: ExportTaskQuestionGroupFragment) => o.questionGroup
      ),
      {
        name: this.translate.instant('common.question_list.columns.task_id'),
        value: (o: ExportTaskQuestionGroupFragment): string => '' + o.task.id
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.has_comment'
        ),
        value: (o: ExportTaskQuestionGroupFragment): string =>
          o.hasComment
            ? this.translate.instant('common.true')
            : this.translate.instant('common.false')
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.changed_by_author'
        ),
        value: (o: ExportTaskQuestionGroupFragment): string =>
          o.changedByAuthor
            ? this.translate.instant('common.true')
            : this.translate.instant('common.false')
      }
    ];
  }
}
