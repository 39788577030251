angular.module('common').directive('classOnMouseHover', function () {
  return {
    restrict: 'A',
    link: function ($scope, $element, $attrs) {
      var mouseenterClass = $attrs.classOnMouseenter,
        mouseleaveClass = $attrs.classOnMouseleave;

      function getChangingElement () {
        if ($attrs.classOnMouseHover === undefined || $attrs.classOnMouseHover.length === 0) {
          return $element;
        }
        else {
          return $element.find($attrs.classOnMouseHover);
        }
      }

      getChangingElement().addClass(mouseleaveClass);

      $element.on('mouseenter', function () {
        getChangingElement().removeClass(mouseleaveClass).addClass(mouseenterClass);
      });
      $element.on('mouseleave click', function () {
        getChangingElement().removeClass(mouseenterClass).addClass(mouseleaveClass);
      });
    }
  };
});
