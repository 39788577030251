angular.module('taskManagement', ['common', 'ui.bootstrap', 'ui.router']);

require('./config');
require('./routes');

require('./actions/task_list');
require('./actions/task_question_group_list');

require('./components/task_list');
require('./components/task_question_group_details');
require('./components/task_question_group_list');
require('./components/task_question_group_list_column_visibility');

require('./reducers/task_list');
require('./reducers/task_management_root');
require('./reducers/task_question_group_list');

require('./selectors/task_list');
require('./selectors/task_question_group_list');
