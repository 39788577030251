import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Range, utils, WorkSheet } from 'xlsx';
import {
  ValidationsMessage,
  ValidationsSeverity
} from '../../../generated/base-types';
import {
  ImportedLongMenuEntry,
  LongMenuListHeaders
} from '../question-content-type-long-menu-form/question-long-menu-list/question-long-menu-list.component';

@Injectable({
  providedIn: 'root'
})
export class LongMenuListValidatorService {
  private headers: LongMenuListHeaders[];
  private content: ImportedLongMenuEntry[];
  private requiredHeaders: LongMenuListHeaders[];

  constructor(private translate: TranslateService) {}

  public validate(
    worksheet: WorkSheet,
    requiredHeaders: LongMenuListHeaders[]
  ): ValidationsMessage[] {
    this.content = utils.sheet_to_json<ImportedLongMenuEntry>(worksheet);
    this.headers = this.parseColumnHeaders(worksheet);
    this.requiredHeaders = requiredHeaders;
    const alert =
      this.isEmpty() || this.hasMissingHeaders() || this.hasEmptyCells();

    return alert ? [alert] : [];
  }

  private isEmpty(): ValidationsMessage | undefined {
    if (this.content.length > 0) {
      return;
    }

    return {
      severity: ValidationsSeverity.Error,
      message: this.translate.instant(
        'question_groups.edit.long_menu_list.errors.empty_file'
      )
    };
  }

  private hasMissingHeaders(): ValidationsMessage | undefined {
    const missingHeaders = this.requiredHeaders.filter(
      requiredHeader => !this.headers.includes(requiredHeader)
    );

    if (missingHeaders.length === 0) {
      return;
    }

    return {
      severity: ValidationsSeverity.Error,
      message: this.translate.instant(
        'question_groups.edit.long_menu_list.errors.missing_headers',
        {
          headers: missingHeaders.join(', ')
        }
      )
    };
  }

  private hasEmptyCells(): ValidationsMessage | undefined {
    const emptyCellContent = this.content.some(cell =>
      this.requiredHeaders.find(
        columnName => (cell[columnName]?.replace(/\s/g, '') ?? []).length === 0
      )
    );

    if (!emptyCellContent) {
      return;
    }

    return {
      severity: ValidationsSeverity.Error,
      message: this.translate.instant(
        'question_groups.edit.long_menu_list.errors.empty_values'
      )
    };
  }

  private parseColumnHeaders(worksheet: WorkSheet): LongMenuListHeaders[] {
    const sheetRange: Range = utils.decode_range(worksheet['!ref'] as string);
    const headerRange = {
      ...sheetRange,
      // e stands for 'end of range', r for 'row', c for 'column'
      e: {
        c: sheetRange.e.c,
        r: 0 // pick the first available row
      }
    };

    return utils.sheet_to_json<LongMenuListHeaders[]>(worksheet, {
      header: 1,
      range: utils.encode_range(headerRange)
    })[0];
  }
}
