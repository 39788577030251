import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'man-field',
  templateUrl: './field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'form-field' }
})
export class FieldComponent {
  @Input() public label?: string;
  @Input() public labelFor?: string;
  @Input() public helpText?: string;
  @Input() public errors?: ValidationErrors;

  public get describedById(): string | undefined {
    if (this.helpText === undefined || this.labelFor === undefined) {
      return;
    }

    return `${this.labelFor}-help`;
  }
}
