import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { AffiliationsState } from './affiliations/affiliations.state';
import { DimensionsState } from './dimensions/dimensions.state';
import { PoolFormState } from './form/pool-form.state';
import { PermissionsState } from './permissions/permissions.state';
import { RevisionStatusState } from './revision-status/revision-status.state';

@State({
  name: 'poolDetails',
  children: [
    PoolFormState,
    AffiliationsState,
    DimensionsState,
    PermissionsState,
    RevisionStatusState
  ]
})
@Injectable()
export class PoolDetailsState {}
