import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/admin/common/modal/modal.service';
import { Affiliation } from 'src/generated/base-types';

@Component({
  selector: 'man-affiliation-form',
  templateUrl: './affiliation-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AffiliationFormComponent implements OnInit {
  @Input() public affiliation?: Partial<Affiliation>;
  @Input() public canDismissWhileDirty?: boolean;
  @Input() public set disabled(value: boolean | undefined) {
    value === true ? this.form?.disable() : this.form?.enable();
  }
  public get disabled(): boolean {
    return this.form.disabled;
  }

  @Output() public create = new EventEmitter<Pick<Affiliation, 'name'>>();
  @Output() public update = new EventEmitter<
    Pick<Affiliation, 'name' | 'id'>
  >();
  @Output() public closed = new EventEmitter<void>();

  public form: FormGroup;

  public get edit(): boolean {
    return this.affiliation !== undefined;
  }
  constructor(
    public translate: TranslateService,
    private readonly modalService: ModalService
  ) {}

  public ngOnInit(): void {
    this.initialiseForm();
  }

  public async onClose(): Promise<void> {
    if (this.disabled) return;
    await this.safeClose();
  }

  public onSave(): void {
    if (!this.form.valid || !this.form.dirty) return;

    this.affiliation === undefined
      ? this.create.emit(this.form.value)
      : this.update.emit({ id: this.affiliation.id, ...this.form.value });
  }

  private initialiseForm(): void {
    this.form = new FormGroup({
      id: new FormControl(),
      name: new FormControl('', [Validators.required])
    });

    this.form.patchValue(this.affiliation || {});
  }

  private async safeClose(): Promise<void> {
    if (this.canDismissWhileDirty === true || !this.form.dirty) {
      this.closed.emit();

      return;
    }

    const discardChanges = await this.modalService.confirmUnsavedChanges();
    if (discardChanges) {
      this.closed.emit();
    }
  }
}
