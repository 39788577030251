<h5>
  {{ 'common.question_group_details.statistics.subgroup.title' | translate }}
</h5>
<b *ngIf="statistics.subgroups.length == 0">
  {{
    'common.question_group_details.statistics.subgroup.no_statistics'
      | translate
  }}
</b>
<table class="table table-striped" *ngIf="statistics.subgroups.length > 0">
  <thead>
    <tr>
      <th>
        {{
          'common.question_group_details.statistics.subgroup.name' | translate
        }}
      </th>
      <th>
        {{ 'common.question_group_details.statistics.table.n' | translate }}
      </th>
      <th>
        {{
          'common.question_group_details.statistics.table.p_partial' | translate
        }}
        <button
          class="btn btn-secondary xxs"
          [ngbPopover]="
            'common.question_group_details.statistics.overview.hint.p_partial'
              | translate
          "
          container="body"
          placement="top"
          triggers="mouseenter:mouseleave"
          [animation]="false"
        >
          <i class="fa fa-info"></i>
        </button>
      </th>
      <th>
        {{
          'common.question_group_details.statistics.table.p_dichotomous'
            | translate
        }}
        <button
          class="btn btn-secondary xxs"
          [ngbPopover]="
            'common.question_group_details.statistics.overview.hint.p_dichotomous'
              | translate
          "
          container="body"
          placement="top"
          triggers="mouseenter:mouseleave"
          [animation]="false"
        >
          <i class="fa fa-info"></i>
        </button>
      </th>
      <th>
        {{ 'common.question_group_details.statistics.table.r' | translate }}
      </th>
      <th>
        {{ 'common.question_group_details.statistics.table.lne' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        {{
          'common.question_group_details.statistics.subgroup.total' | translate
        }}
      </td>
      <td class="">
        {{
          statistics.total.n !== null && statistics.total.n !== undefined
            ? (statistics.total.n | fixedNumber : 0)
            : '-'
        }}
      </td>
      <td class="">
        {{
          statistics.total.p !== null && statistics.total.p !== undefined
            ? (statistics.total.p | percentage | fixedNumber : 1)
            : '-'
        }}
      </td>
      <td class="">
        {{
          statistics.total.pDichotomous !== null &&
          statistics.total.pDichotomous !== undefined
            ? (statistics.total.pDichotomous | percentage | fixedNumber : 1)
            : '-'
        }}
      </td>
      <td class="">
        {{
          statistics.total.r !== null && statistics.total.r !== undefined
            ? (statistics.total.r | fixedNumber : 2)
            : '-'
        }}
      </td>
      <td class="">
        {{
          statistics.total.lne !== null && statistics.total.lne !== undefined
            ? (statistics.total.lne | fixedNumber : 3)
            : '-'
        }}
      </td>
    </tr>
    <tr *ngFor="let subgroup of statistics.subgroups">
      <td>{{ subgroup.name }}</td>
      <td class="">
        {{
          subgroup.n !== null && subgroup.n !== undefined
            ? (subgroup.n | fixedNumber : 0)
            : '-'
        }}
      </td>
      <td class="">
        {{
          subgroup.p !== null && subgroup.p !== undefined
            ? (subgroup.p | percentage | fixedNumber : 1)
            : '-'
        }}
      </td>
      <td class="">
        {{
          subgroup.pDichotomous !== null && subgroup.pDichotomous !== undefined
            ? (subgroup.pDichotomous | percentage | fixedNumber : 1)
            : '-'
        }}
      </td>
      <td class="">
        {{
          subgroup.r !== null && subgroup.r !== undefined
            ? (subgroup.r | fixedNumber : 2)
            : '-'
        }}
      </td>
      <td class="">
        {{
          subgroup.lne !== null && subgroup.lne !== undefined
            ? (subgroup.lne | fixedNumber : 3)
            : '-'
        }}
      </td>
    </tr>
  </tbody>
</table>
