<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'common.blueprint_modal.title' | translate }}</h3>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="dimensions.length > 0">
      <p>{{ 'common.blueprint_modal.information' | translate }}</p>
      <div>
        <label>{{ dimensions[0].label }}</label>
        <ng-select-wrapper
          [options]="dimensions[0].options"
          [value]="blueprint.category0Id"
          (valueChange)="setCategory($event, 0)"
        ></ng-select-wrapper>
      </div>
      <div *ngIf="dimensions.length > 1">
        <label>{{ dimensions[1].label }}</label>
        <ng-select-wrapper
          [options]="dimensions[1].options"
          [value]="blueprint.category1Id"
          (valueChange)="setCategory($event, 1)"
        ></ng-select-wrapper>
      </div>
      <div *ngIf="dimensions.length > 2">
        <label>{{ dimensions[2].label }}</label>
        <ng-select-wrapper
          [options]="dimensions[2].options"
          [value]="blueprint.category2Id"
          (valueChange)="setCategory($event, 2)"
        ></ng-select-wrapper>
      </div>
      <div *ngIf="dimensions.length > 3">
        <label>{{ dimensions[3].label }}</label>
        <ng-select-wrapper
          [options]="dimensions[3].options"
          [value]="blueprint.category3Id"
          (valueChange)="setCategory($event, 3)"
        ></ng-select-wrapper>
      </div>
      <div *ngIf="dimensions.length > 4">
        <label>{{ dimensions[4].label }}</label>
        <ng-select-wrapper
          [options]="dimensions[4].options"
          [value]="blueprint.category4Id"
          (valueChange)="setCategory($event, 4)"
        ></ng-select-wrapper>
      </div>
      <div *ngIf="dimensions.length > 5">
        <label>{{ dimensions[5].label }}</label>
        <ng-select-wrapper
          [options]="dimensions[5].options"
          [value]="blueprint.category5Id"
          (valueChange)="setCategory($event, 5)"
        ></ng-select-wrapper>
      </div>
      <div *ngIf="dimensions.length > 6">
        <label>{{ dimensions[6].label }}</label>
        <ng-select-wrapper
          [options]="dimensions[6].options"
          [value]="blueprint.category6Id"
          (valueChange)="setCategory($event, 6)"
        ></ng-select-wrapper>
      </div>
      <div *ngIf="dimensions.length > 7">
        <label>{{ dimensions[7].label }}</label>
        <ng-select-wrapper
          [options]="dimensions[7].options"
          [value]="blueprint.category7Id"
          (valueChange)="setCategory($event, 7)"
        ></ng-select-wrapper>
      </div>
    </ng-container>
    <p *ngIf="dimensions.length === 0">
      {{ 'common.blueprint_modal.no_dimensions_exists' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button (click)="dismiss()" class="btn btn-secondary-migrated">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button (click)="save()" class="btn btn-primary-migrated">
      {{ 'common.actions.save' | translate }}
    </button>
  </div>
</div>
