import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { requestStateSuccess } from 'src/app/common/utils/remote-data-utils';
import { isDefined } from 'src/app/common/utils/type-guards/is-defined';
import { ExamState } from '../state/exam.state';

@Component({
  templateUrl: './exam-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExamEditComponent {
  public examName$: Observable<string>;
  public failure$: Observable<boolean>;

  constructor(private store: Store) {
    this.failure$ = this.store.select(ExamState.examFailure);
    this.examName$ = this.store.select(ExamState.exam).pipe(
      filter(requestStateSuccess),
      map(({ data }) => data?.name),
      filter(isDefined)
    );
  }
}
