import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SortMeta } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { isVoid } from 'src/app/common/utils/type-guards/voidable';
import { SettingsState } from 'src/app/state/settings.state';
import {
  ColumnConfig,
  QuestionColumnConfiguration
} from 'src/app/state/settings.state.model';
import {
  ListMetadata,
  QuestionGroupSortAttribute,
  Scalars
} from '../../../../generated/base-types';
import { OriginReference } from '../../../common/legacy.types';
import { UiRouterService } from '../../../common/services/ui-router.service';
import {
  primeNGSelectToSortInput,
  sortInputToPrimeNGSelect
} from '../../../common/utils/primeng-sort';
import { isDefined } from '../../../common/utils/type-guards/is-defined';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { QuestionFilterState } from '../../state/question-filter/question-filter.state';
import { QuestionFilters } from '../../state/question-filter/question-filter.state.model';
import {
  LoadQuestionGroupList,
  SetSelectedQuestionGroups
} from '../../state/question-list.actions';
import { QuestionListState } from '../../state/question-list.state';

const DEFAULT_COLUMNS_COUNT = 1; // last info column is always present

// limiting the number of sorting attributes
// we don't allow more than 3 sorting attributes in GraphQL
const MAX_SORTING_ATTRIBUTES = 3;

const countVisibleColumns = (
  columnConfig: QuestionColumnConfiguration
): number => {
  const columns = Object.values(columnConfig) as ColumnConfig[];

  return (
    columns.filter(column => column.enabled && column.visible).length +
    DEFAULT_COLUMNS_COUNT
  );
};

@Component({
  selector: 'qm-question-group-list',
  templateUrl: './question-group-list.component.html',
  styleUrls: ['./question-group-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'bs4 pv-fixed-table-header__scroll-area' }
})
export class QuestionGroupListComponent implements OnInit, OnDestroy {
  @Select(QuestionListState.questionGroups)
  public questionGroups$: Observable<QuestionGroupListElementFragment[]>;

  @Select(QuestionListState.metadata)
  public metadata$: Observable<ListMetadata>;

  @Select(QuestionListState.loading)
  public loading$: Observable<boolean>;

  @Select(QuestionFilterState.currentPoolFilters)
  public filter$: Observable<QuestionFilters>;

  @Select(QuestionListState.selectedQuestionGroups)
  public selectedQuestionGroupIds$: QuestionGroupListElementFragment[];

  @Select(QuestionListState.rows)
  public rows$: Observable<number>;

  public columns$: Observable<QuestionColumnConfiguration>;

  public first = 0;
  public QuestionGroupSortAttribute = QuestionGroupSortAttribute;
  public selection: QuestionGroupListElementFragment[] = [];
  public sortAttributes: SortMeta[] = [];

  public visibleColumnsCount = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly uiRouterService: UiRouterService
  ) {}

  public ngOnInit(): void {
    this.store
      .select(QuestionListState.selectedQuestionGroups)
      .pipe(filter(isDefined), takeUntil(this.destroy$))
      .subscribe(selection => (this.selection = selection));

    this.sortAttributes = sortInputToPrimeNGSelect<QuestionGroupSortAttribute>(
      this.store.selectSnapshot(QuestionListState.sortAttributes) || []
    );

    this.filter$.pipe(takeUntil(this.destroy$)).subscribe(_filter => {
      // navigate table to first page when filter gets updated
      this.first = 0;
    });

    this.columns$ = this.store
      .select(SettingsState.questionColumnConfig)
      .pipe(takeUntil(this.destroy$));
    this.columns$.pipe(filter(isDefined)).subscribe(columns => {
      this.visibleColumnsCount = countVisibleColumns(columns);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.complete();
  }

  public loadMore(data: {
    first: number;
    rows: number;
    multiSortMeta?: { field: QuestionGroupSortAttribute; order: number }[];
  }): void {
    // remove last sorting attribute if there are more than 3
    if (
      isDefined(data.multiSortMeta) &&
      data.multiSortMeta?.length > MAX_SORTING_ATTRIBUTES
    ) {
      this.sortAttributes = data.multiSortMeta.slice(0, MAX_SORTING_ATTRIBUTES);

      return;
    }

    const sortAttributes = primeNGSelectToSortInput<QuestionGroupSortAttribute>(
      isDefined(data.multiSortMeta) ? data.multiSortMeta : []
    );

    this.store.dispatch(
      new LoadQuestionGroupList(data.first, data.rows, sortAttributes)
    );
  }

  public openQuestionGroup(qgID: Scalars['ID']): void {
    this.uiRouterService.go('editQuestionForm', {
      id: qgID,
      ref: OriginReference.QuestionManagement
    });
  }

  public onSelectionChange(): void {
    this.store.dispatch(new SetSelectedQuestionGroups(this.selection));
  }

  public isColumnVisible(column: ColumnConfig | undefined): boolean {
    if (isVoid(column)) return false;

    return column.enabled && column.visible;
  }
}
