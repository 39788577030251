angular.module('common').factory('Repositories::Pool', [
  'GraphQLClient',
  function (GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getAllQuery = 'query {pools {id name}}',
      getCurrentQuery = 'query {current_pool {id name}}',
      getCurrentDetailedQuery =
        'query {current_pool {' +
        '  id name languages allow_self_assessment' +
        '  dimensions {id name position truncated categories' +
        '   {id name position}' +
        '  }' +
        '  affiliations {id name position}' +
        '  supervisors' +
        '}}',
      getAllWithCurrentUserPermissionsQuery =
        'query {' +
        '  pools {id name}' +
        '  current_user {' +
        '    super_user' +
        '    permissions(roles: [administrator, coordinator, basic_evaluator, advanced_evaluator, rater]) {pool {id name}}' +
        '  }' +
        '}',
      updateCurrentQuery =
        'mutation($pool_id: Int!) {set_current_pool(pool_id: $pool_id) {' +
        '  pool {id name} erroneous' +
        '}}';

    return {
      getAll: function () {
        return client.query(getAllQuery).then(function (response) {
          return response.data.data.pools;
        });
      },

      // only returns Pools current_user had access to as
      // super user, basic_evaluator, advanced_evaluator, rater, administrator or coordinator
      getAllViewable: function () {
        return client
          .query(getAllWithCurrentUserPermissionsQuery)
          .then(function (response) {
            const current_user = response.data.data.current_user;
            const permissions = _.uniqWith(current_user.permissions, _.isEqual);
            const pools = response.data.data.pools;

            if (current_user.super_user) {
              return pools;
            } else {
              return permissions.map(({ pool }) => pool);
            }
          });
      },

      getCurrent: function () {
        return client.query(getCurrentQuery).then(function (response) {
          return response.data.data.current_pool;
        });
      },

      getCurrentDetailed: function () {
        return client.query(getCurrentDetailedQuery).then(function (response) {
          return response.data.data.current_pool;
        });
      },

      updateCurrent: function (pool_id) {
        return client
          .query(updateCurrentQuery, { pool_id: pool_id })
          .then(function (response) {
            return response.data.data.set_current_pool;
          });
      }
    };
  }
]);
