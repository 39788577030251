(function () {
  angular.module('settings').component('poolsSubjects', {
    template: require('./pools_subjects.html'),
    controller: PoolsSubjectsController
  });

  PoolsSubjectsController.$inject = [];

  function PoolsSubjectsController() {
    this.I18n = I18n;
  }
})();
