import { isVoid, Voidable } from '../../utils/type-guards/voidable';
import { ContentTransformer } from './question-content-transformer';

const assetEndpointPrefix = '/api/v1/assets/';
const parser = new DOMParser();

export const transformInternalToHTML: ContentTransformer = (
  content: string | Voidable
): string | undefined => {
  if (isVoid(content)) {
    return;
  }

  const transformedContent = transformInternalStringToHTML(content);

  if (!transformedContent) {
    return;
  }

  return removeStyleAttribute(transformedContent);
};

export const transformHTMLToInternal: ContentTransformer = (
  content: string | Voidable
): string | undefined => {
  if (isVoid(content)) {
    return;
  }

  return transformHTMLToInternalString(content);
};

function removeStyleAttribute(content: string): string {
  const doc = parser.parseFromString(content, 'text/html');
  doc.querySelectorAll('img').forEach(element => {
    if (element.hasAttribute('style')) {
      element.removeAttribute('style');
    }
  });

  return doc.body.innerHTML;
}

function transformInternalStringToHTML(internalContent: string): string {
  const doc = parser.parseFromString(internalContent, 'text/html');
  doc.querySelectorAll('img').forEach(element => {
    const assetId = element.dataset.assetId;
    element.setAttribute('src', `${assetEndpointPrefix}${assetId}`);
    delete element.dataset.assetId;
  });

  return doc.body.innerHTML;
}

function transformHTMLToInternalString(html: string): string {
  const doc = parser.parseFromString(html, 'text/html');
  doc.querySelectorAll('img').forEach(element => {
    const url = element.getAttribute('src');
    if (url !== null) {
      element.dataset.assetId = url.replace(assetEndpointPrefix, '');
      element.removeAttribute('src');
    }
  });

  return doc.body.innerHTML;
}
