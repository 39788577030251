<div
  *ngIf="form"
  class="filter-group"
  [ngClass]="{
    'filter-group--invalid': form.invalid
  }"
>
  <div class="filter-group__header">
    <div></div>
    <label (click)="toggleOpen()">
      {{ 'question_groups.advanced_filter.header' | translate | uppercase }}
    </label>
    <button class="btn btn-icon" type="button" (click)="toggleOpen()">
      <i class="fa fa-angle-down" [ngClass]="{ 'fa-rotate-180': !isOpen }"></i>
    </button>
  </div>

  <div class="filter-group__options" *ngIf="isOpen">
    <form [formGroup]="form">
      <div class="criteria" formArrayName="criteria">
        <div
          class="criterion"
          *ngFor="
            let criteronForm of criteriaFormArray.controls;
            index as criterionIndex;
            first as isFirst
          "
        >
          <div [formGroupName]="criterionIndex">
            <div class="criterion-input-group combination-operator">
              <ng-select
                class="mb-4 mx-5"
                *ngIf="!isFirst"
                bindValue="value"
                formControlName="combinationOperator"
                [items]="combinatorOptions"
                [searchable]="false"
                [clearable]="false"
              ></ng-select>
            </div>

            <div class="criterion-input-group mb-4">
              <div class="criterion-input-group-inputs">
                <ng-select
                  class="criterion-input"
                  bindLabel="name"
                  bindValue="id"
                  formControlName="id"
                  [items]="filter.options"
                  [searchable]="false"
                  [clearable]="false"
                  (change)="onChangeCriterionType($event.id, criterionIndex)"
                ></ng-select>
                <ng-select
                  class="criterion-input"
                  *ngIf="
                    getCriterionType(criterionIndex) != criterionType.Boolean
                  "
                  bindValue="value"
                  formControlName="operator"
                  [items]="getCriterionOperators(criterionIndex)"
                  [searchable]="false"
                  [clearable]="false"
                ></ng-select>
                <ng-container [ngSwitch]="getCriterionType(criterionIndex)">
                  <ng-container *ngSwitchCase="criterionType.String">
                    <input
                      type="text"
                      class="form-control criterion-input"
                      formControlName="value"
                    />
                  </ng-container>
                  <ng-container *ngSwitchCase="criterionType.Number">
                    <input
                      type="number"
                      min="0"
                      class="form-control criterion-input"
                      formControlName="value"
                    />
                  </ng-container>
                  <ng-container *ngSwitchCase="criterionType.Select">
                    <ng-container
                      *ngIf="getCriterionOptions(criterionIndex) as options"
                    >
                      <ng-select
                        class="criterion-input"
                        bindValue="value"
                        formControlName="value"
                        [items]="getCriterionOptions(criterionIndex)"
                        [searchable]="false"
                        [clearable]="false"
                      >
                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                          <span [innerHTML]="item.label | safeHtml"></span>
                        </ng-template>
                      </ng-select>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="criterionType.Boolean">
                    <ng-select
                      class="criterion-input"
                      bindValue="value"
                      formControlName="value"
                      [items]="booleanOptions"
                      [searchable]="false"
                      [clearable]="false"
                    ></ng-select>
                  </ng-container>
                  <ng-container *ngSwitchCase="criterionType.Date">
                    <input
                      type="date"
                      min="1980-01-01"
                      class="form-control criterion-input"
                      formControlName="value"
                    />
                  </ng-container>
                </ng-container>
              </div>
              <button
                class="btn btn-icon"
                type="button"
                (click)="onRemoveCriterion(criterionIndex)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="3"
                >
                  <path stroke-linecap="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div>
      <button class="btn btn-primary btn-block" (click)="onAddCriterion()">
        {{ 'question_groups.advanced_filter.new_filter' | translate }}
      </button>
    </div>
  </div>
</div>
