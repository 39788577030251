import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'switch-panel-toggle',
  template: `
    <i
      class="fa fa-lg is-clickable"
      [ngClass]="element.hidden ? 'fa-chevron-down' : 'fa-chevron-up'"
      (click)="toggle()"
    ></i>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchPanelToggleComponent {
  @Input() public set open(value: boolean) {
    if (this.element === undefined || this.element === null) {
      return;
    }

    this.element.hidden = !value;
  }
  @Input() public element: HTMLElement;

  public toggle(): void {
    this.element.hidden = !this.element.hidden;
  }
}
