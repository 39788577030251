angular.module('common').factory('Repositories::User', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getAllQuery =
        'query {' +
        '  users {' +
        '    id name email' +
        '  }' +
        '}',
      getAllOfPoolQuery =
        'query($poolId: Int!) {' +
        '  pool(pool_id: $poolId) {' +
        '    permissions {' +
        '      user {' +
        '        id name email' +
        '      }' +
        '    }' +
        '  }' +
        '}';

    return {
      getAll: function() {
        return client.query(getAllQuery).then(function(response) {
          return response.data.data.users;
        });
      },

      getAllOfPool: function(poolId) {
        return client.query(getAllOfPoolQuery, {poolId: poolId}).then(function(response) {
          return response.data.data.pool.permissions.map(function (permission) {
            return permission.user;
          });
        });
      }
    };
  }
]);
