(function() {
  function PoolsController(
    Store,
    Actions,
    SelectorsPools,
    $transitions,
    $state,
    $element,
    $scope,
    $timeout
  ) {
    var dataSubscription, transitionSubscription, searchTimeout;
    this.I18n = I18n;

    this.$onInit = function() {
      $element.addClass('layout--vertical');
      $element.addClass('settings-pool__component');
    };

    dataSubscription = Store.subscribeOn(
      SelectorsPools.getItems,
      function(items) {
        this.pools = items;
      }.bind(this)
    );

    transitionSubscription = $transitions.onEnter(
      { entering: 'settings.pools.base.details' },
      function(transition, state) {
        var poolId = transition.params().poolId;
        Store.dispatch(Actions.setPoolSelection(parseInt(poolId)));
      }
    );

    this.searchUpdated = function(text) {
      if (searchTimeout) {
        $timeout.cancel(searchTimeout);
      }
      searchTimeout = $timeout(
        function() {
          this.search = text;
        }.bind(this),
        500
      );
    }.bind(this);

    $scope.$on('$destroy', function() {
      dataSubscription();
      transitionSubscription();
    });
  }

  angular.module('settings').component('pools', {
    template: require('./pools.html'),
    controller: PoolsController
  });

  PoolsController.$inject = [
    'Store',
    'Actions::PoolList',
    'Selectors::PoolList',
    '$transitions',
    '$state',
    '$element',
    '$scope',
    '$timeout'
  ];
})();
