import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  attributes: Types.CategoryInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', updateCategory?: { __typename?: 'UpdateCategoryPayload', successful: boolean, category?: { __typename?: 'Category', id: string, position: number, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, customIdentifier?: string | null } | null } | null };

export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($id: ID!, $attributes: CategoryInput!) {
  updateCategory(id: $id, attributes: $attributes) {
    category {
      id
      position
      nameDe
      nameFr
      nameEn
      nameIt
      customIdentifier
    }
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCategoryGQL extends Apollo.Mutation<UpdateCategoryMutation, UpdateCategoryMutationVariables> {
    document = UpdateCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }