<ng-container [ngSwitch]="loading$ | async">
  <co-loader *ngSwitchCase="true"></co-loader>
  <ng-container *ngSwitchDefault>
    <p *ngIf="questionGroupId === undefined">
      {{ 'common.question_group_details.no_question_group' | translate }}
    </p>
    <ng-container *ngIf="questionGroupId !== undefined">
      <ul
        ngbNav
        #nav="ngbNav"
        [activeId]="activeTab$ | async"
        class="nav-tabs nav-fill"
        [animation]="false"
        (navChange)="onTabSelectionChanged($event)"
      >
        <li [ngbNavItem]="questionDetailsTabs.Preview">
          <a ngbNavLink>{{
            'common.question_group_details.tab.preview' | translate
          }}</a>
          <ng-template ngbNavContent>
            <co-preview
              *ngIf="preview$ | async as preview"
              [status]="preview.status"
              [url]="preview.url"
            ></co-preview>
          </ng-template>
        </li>
        <li [ngbNavItem]="questionDetailsTabs.Comments">
          <a ngbNavLink>{{
            'common.question_group_details.tab.history' | translate
          }}</a>
          <ng-template ngbNavContent>
            <co-question-group-messages
              *ngIf="messages$ | async as messages"
              [messages]="messages"
              [showReadMessages]="showReadMessages$ | async : true"
              [showSystemMessages]="showSystemMessages$ | async : true"
              (commentReadStateChanged)="onCommentReadStateChanged($event)"
              (showReadMessageChanged)="onShowReadMessageChanged($event)"
              (showSystemMessageChanged)="onShowSystemMessageChanged($event)"
              (commentDeleted)="onCommentDeleted($event)"
            ></co-question-group-messages>
          </ng-template>
        </li>
        <li [ngbNavItem]="questionDetailsTabs.Statistics">
          <a ngbNavLink>{{
            'common.question_group_details.tab.statistics' | translate
          }}</a>
          <ng-template ngbNavContent>
            <co-question-group-statistics
              *ngIf="statistics$ | async as statistics"
              [statistics]="statistics"
            >
            </co-question-group-statistics>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </ng-container>
  </ng-container>
</ng-container>
