angular.module('main').controller('footerCtrl', [
  '$scope',
  'Repositories::Version',
  function($scope, VersionRepository) {
    VersionRepository.getFullVersion().then(function(version) {
      $scope.appVersion = version.name;
      $scope.appVersionDate = version.date;
    });
  }
]);
