angular.module('common').factory('Repositories::Task', ['GraphQLClient', function (GraphQLClient) {
  var client = new GraphQLClient('/api/v1/graphql'),
      taskQuery = 'query ($order: [String!]) {' +
      '  tasks(order: $order) {' +
      '    id, type, receiver {name}, starts_at' +
      '    ends_at, finished_at, created_at' +
      '    creator {name}, status, soft_deleted' +
      '  }' +
      '}';

  return {
    query: function (order) {
      return client.query(taskQuery, {order: order}).
        then(function (response) {
          return response.data.data.tasks;
        });
    }
  };
}]);
