angular.module('settings').factory('Reducers::SettingsRoot', [
  'Reducers::PoolList',
  'Reducers::SubjectList',
  'Reducers::CategorySelection',
  'Reducers::OauthApplicationList',
  function(
    PoolListReducer,
    SubjectListReducer,
    CategorySelectionReducer,
    OauthApplicationListReducer
  ) {
    function reducer(state, action) {
      state = state || {};
      return Object.assign({}, state, {
        poolList: PoolListReducer(state.poolList, action),
        subjectList: SubjectListReducer(state.subjectList, action),
        categorySelection: CategorySelectionReducer(state.categorySelection, action),
        applicationList: OauthApplicationListReducer(state.applicationList, action)
      });
    }

    return reducer;
  }
]);
