angular.module('common').config([
  'uibDatepickerPopupConfig',
  'uibDatepickerConfig',
  '$uibModalProvider',
  '$httpProvider',
  '$locationProvider',
  '$animateProvider',
  '$cookiesProvider',
  function(
    datepickerPopupConfig,
    datepickerConfig,
    $uibModalProvider,
    $httpProvider,
    $locationProvider,
    $animateProvider,
    $cookiesProvider
  ) {
    $locationProvider.html5Mode(true);

    datepickerPopupConfig.currentText = I18n.t('common.form.datepicker.today');
    datepickerPopupConfig.toggleWeeksText = I18n.t('common.form.datepicker.weeks');
    datepickerPopupConfig.clearText = I18n.t('common.form.datepicker.clear');
    datepickerPopupConfig.closeText = I18n.t('common.form.datepicker.close');

    datepickerConfig.startingDay = 1;
    datepickerConfig.minDate = new Date();

    $uibModalProvider.options.animation = false;

    $httpProvider.defaults.headers.delete = { 'Content-Type': 'application/json;charset=utf-8' };
    $httpProvider.interceptors.push([
      '$q',
      'notifications',
      function($q, notifications) {
        return {
          response: function(response) {
            if (response.headers('content-type') == 'text/html' && response.data.match(/<title>Scheduled maintenance<\/title>/)) {
              notifications.add(I18n.t('maintenance.in_progress'), 'alert');
              return $q.reject(response);
            }

            return response;
          }
        };
      }
    ]);

    $animateProvider.classNameFilter(/^((?!(fa-spinner)).)*$/);

    var cookieExpirationDate = new Date();
    cookieExpirationDate.setFullYear(cookieExpirationDate.getFullYear() + 1);
    $cookiesProvider.defaults.expires = cookieExpirationDate;
  }
]);
