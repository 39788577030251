angular.module('common').directive('btnGroupInlineError', ['BtnGroupErrorHandler', 'baseInlineErrorFunctionality', function (BtnGroupErrorHandler, baseInlineErrorFunctionality) {
  return {
    restrict: 'E',
    scope: true,
    transclude: true,
    template: '<div class="inline-error__tooltip-position-wrapper"><span class="inline-error__tooltip skin-tooltip" ng-show="message.content && (hasFocus || hasMouse)">{{message.content}}</span></div>\
      <div class="inline-error__container"><span class="fa-stack btn-group-inline-error__icon">\
      <i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-stack-1x fa-inverse" ng-class="{\'fa-exclamation-triangle\': message.type == \'alert\', \'fa-info\': message.type == \'info\', \'fa-exclamation\': message.type == \'warning\'}"></i>\
      </span>\
      <ng-transclude/></div>',
    link: function ($scope, $element, $attrs) {
      var errorHandler = new BtnGroupErrorHandler($element);
      errorHandler.onShowMessage(function (message) {
        $scope.message = message;
      });

      $element.on('click', '.btn-group button', function() {
        errorHandler.updateMessageVisibility();
        $scope.$digest();
      });

      baseInlineErrorFunctionality('.btn-group', $scope, $element, $attrs, errorHandler);
    }
  };
}]);
