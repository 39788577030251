/* eslint-disable unicorn/no-null */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Voidable } from '../utils/type-guards/voidable';

type NullValue = -20_000_000;
const NullValue = -20_000_000;

export interface LabelValuePair<T> {
  label: string;
  value: T | NullValue;
}

function undefinedToEmptyOption<T>(
  option: LabelValuePair<T>
): LabelValuePair<T> {
  return {
    label: option.label,
    value: sanitizeToInternalValue(option.value)
  };
}

function sanitizeToInternalValue<T>(value: T | Voidable): T | NullValue {
  if (value === null || value === undefined) {
    return NullValue;
  }

  return value;
}

function sanitizeToExternalValue<T>(value: T | NullValue): T | null {
  if (value === NullValue) {
    return null;
  }

  return value;
}

/*
 * There's currently an open bug report / feature request in ng-select
 * (https://github.com/ng-select/ng-select/issues/761). `null` isn't recognized
 * as a value and is treated like `undefined`. As long as this issue isn't
 * fixed, the wrapper can take care of it.
 */

@Component({
  selector: 'ng-select-wrapper',
  templateUrl: './ng-select-wrapper.component.html'
})
export class NgSelectWrapperComponent<T> {
  @Input()
  public set options(options: LabelValuePair<T>[]) {
    this._options = options.map(undefinedToEmptyOption);
  }
  public get options(): LabelValuePair<T>[] {
    return this._options;
  }

  @Input()
  public set value(value: T | Voidable) {
    this.internalValue = sanitizeToInternalValue(value);
  }
  public get value(): T | Voidable {
    return sanitizeToExternalValue(this.internalValue);
  }
  @Input() public id?: string;

  @Output()
  public readonly valueChange: EventEmitter<T | null> =
    new EventEmitter<T | null>();

  public internalValue: T | NullValue = NullValue;
  private _options: LabelValuePair<T>[] = [];

  public onValueChange(pair: LabelValuePair<T> | undefined): void {
    if (pair) {
      this.valueChange.emit(sanitizeToExternalValue(pair.value));
    }
  }
}
