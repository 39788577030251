(function () {
  angular.module('common').component('announcementList', {
    template: require('./announcement_list.html'),
    controller: AnnouncementsController
  });

  AnnouncementsController.$inject = ['$scope',
                                     '$interval',
                                     'Repositories::Announcement',
                                     'Store',
                                     'Actions::AnnouncementList',
                                     'Selectors::AnnouncementList'];

  function AnnouncementsController($scope, $interval, Repository, Store, Actions, Selectors) {
    var FifteenMinutes   = 900000; // 15 minutes
    this.announcements = [];

    var getAnnouncements = function () {
      Store.dispatch(Actions.fetchAnnouncementsRequest());
      Repository.getAll().then(function(data) {
        Store.dispatch(Actions.fetchAnnouncementsSuccess(data));
        this.announcements = data;
      }.bind(this));
    }.bind(this);

    getAnnouncements();
    var interval = $interval(getAnnouncements, FifteenMinutes);

    $scope.$on('$destroy', function () {
      $interval.cancel(interval);
    }.bind(this));
  }
})();
