import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FieldComponent } from './field/field.component';
import { FieldErrorsPipe } from './utils/field-errors.pipe';

const formComponents = [FieldComponent, FieldErrorsPipe];

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [...formComponents],
  exports: [...formComponents]
})
export class FormModule {}
