<p-table
  dataKey="id"
  [value]="categories | copyArray"
  styleClass="p-datatable-sm"
>
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th translate="admin.pools.subjects.table.header.name"></th>
      <th>DE</th>
      <th>FR</th>
      <th>EN</th>
      <th>IT</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-category>
    <tr class="bg-light">
      <td>
        <em class="text-secondary">
          {{
            category.dimension
              | aggregateLocalisation : 'name'
              | localizeContent
          }}</em
        >
      </td>
      <td>{{ category.name }}</td>
      <td>{{ category.nameDe }}</td>
      <td>{{ category.nameFr }}</td>
      <td>{{ category.nameEn }}</td>
      <td>{{ category.nameIt }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        colspan="6"
        class="text-center"
        translate="admin.pools.subjects.table.messages.empty"
      ></td>
    </tr>
  </ng-template>
</p-table>
