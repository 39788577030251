import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  QuestionGroupStatisticsFragment,
  QuestionStatisticsFragment
} from 'src/app/question-management/services/question-group-statistics.generated';
import { isDefined } from '../../utils/type-guards/is-defined';

interface QuestionGroupStatistics {
  exam: QuestionGroupStatisticsFragment['exam'];
  questions: QuestionGroupStatisticsFragment['questions'];
  n: number[];
  p: number[];
  pDichotomous: number[];
  r: number[];
  lne: number[];
  xi1: number[];
  xi2: number[];
  eliminated: boolean;
}

@Component({
  selector: 'co-question-group-statistics',
  templateUrl: './question-group-statistics.component.html',
  styleUrls: ['./question-group-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionGroupStatisticsComponent {
  @Input()
  public set statistics(value: QuestionGroupStatisticsFragment[]) {
    this.questionGroupStatistics = value.map(fragment => {
      return {
        exam: fragment.exam,
        questions: fragment.questions,
        n: fragment.questions
          .map(question => question.total.n)
          .filter(isDefined),
        p: fragment.questions
          .map(question => question.total.p)
          .filter(isDefined),
        pDichotomous: fragment.questions
          .map(question => question.total.pDichotomous)
          .filter(isDefined),
        r: fragment.questions
          .map(question => question.total.r)
          .filter(isDefined),
        lne: fragment.questions
          .map(question => question.total.lne)
          .filter(isDefined),
        xi1: fragment.questions
          .map(question => question.total.xi1)
          .filter(isDefined),
        xi2: fragment.questions
          .map(question => question.total.xi2)
          .filter(isDefined),
        eliminated: fragment.questions.reduce(
          (eliminated, question) => eliminated || question.total.eliminated,
          false as boolean
        )
      };
    });

    this.resetSelection();
    if (this.questionGroupStatistics.length > 0) {
      this.selectQuestionGroup(0);
    }
  }

  public questionGroupStatistics: QuestionGroupStatistics[] = [];
  public currentQuestionStatistics: QuestionStatisticsFragment | undefined;
  public selectedQuestionGroup: number | undefined;
  public selectedQuestion: number | undefined;

  public selectQuestionGroup(index: number): void {
    this.selectedQuestionGroup = index;
    this.selectQuestion(0);
  }

  public selectQuestion(index: number): void {
    this.selectedQuestion = index;
    if (this.selectedQuestionGroup !== undefined) {
      this.currentQuestionStatistics =
        this.questionGroupStatistics[this.selectedQuestionGroup].questions[
          this.selectedQuestion
        ];
    }
  }

  private resetSelection(): void {
    this.selectedQuestionGroup = undefined;
    this.selectedQuestion = undefined;
    this.currentQuestionStatistics = undefined;
  }
}
