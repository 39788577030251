import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform
} from '@angular/core';
import { Observable } from 'rxjs';

/*
 * This pipe overrides/extends the built-in angular async pipe.
 * Reason:
 * The built-in pipe initially returns "null" so that every component input
 * binded with the async pipe needs to support/handle the type "null".
 * To get around this, the pipe returns "undefined" instead of "null".
 * Additionally the pipe provides an optional "initialValue" parameter which can be
 * used like this "foo$ | async: 'bar'" as an alternative to "(foo$ | async) || 'bar'"
 */
@Pipe({
  name: 'async',
  pure: false
})
export class AsyncNotNull implements PipeTransform, OnDestroy {
  private pipe: AsyncPipe;

  constructor(changeDetectorRef: ChangeDetectorRef) {
    this.pipe = new AsyncPipe(changeDetectorRef);
  }

  public transform<T>(
    obj: Observable<T> | Promise<T> | null | undefined
  ): T | undefined;

  public transform<T>(
    obj: Observable<T> | Promise<T> | null | undefined,
    initialValue: T
  ): T;

  public transform<T>(
    obj: Observable<T> | Promise<T> | null | undefined,
    initialValue?: T
  ): T | undefined {
    if (!obj) {
      return initialValue;
    }

    return this.pipe.transform(obj) ?? undefined;
  }

  public ngOnDestroy(): void {
    this.pipe.ngOnDestroy();
  }
}
