import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Voidable } from '../utils/type-guards/voidable';

const IMAGE_URL_PREFX = '/api/v1/assets/';

@Component({
  selector: 'co-asset-image',
  template: `<img [src]="assetUrl" [hidden]="!visible" />`,
  styleUrls: ['./asset-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetImageComponent {
  public assetUrl: string | undefined;
  public visible = false;

  @Input()
  public set assetId(value: string | Voidable) {
    this.visible = value !== '' && value !== undefined && value !== null;
    this.assetUrl = this.visible ? IMAGE_URL_PREFX + value : undefined;
  }
}
