<div class="page-content" [ngClass]="{ loading: (loading$ | async) }">
  <div class="grid h-100" style="grid-template-rows: auto 1fr">
    <ng-container *ngIf="subjects$ | async as subjects">
      <div class="g-col-6 g-col-xl-8 ms-auto order-2">
        <button
          type="button"
          class="btn btn-dark text-nowrap"
          (click)="onNew()"
        >
          {{ 'admin.pools.subjects.actions.new' | translate }}
        </button>
      </div>
      <div class="g-col-6 g-col-xl-4 order-1">
        <man-search-input
          [disabled]="subjects.requestState !== 'success'"
          [shortcutActive]="true"
          placeholder="admin.pools.subjects.filter.placeholder"
          (search)="onSearch($event)"
          (clear)="onSearchClear()"
        >
        </man-search-input>
      </div>
      <div
        class="g-col-12 order-3 border rounded overflow-hidden bg-stripes-light"
      >
        <p-table
          #tbl
          name="Subjects"
          *ngIf="subjects.requestState !== 'failure'; else error"
          [globalFilterFields]="filterFields"
          [value]="subjects.data | copyArray"
          [loading]="subjects.requestState === 'loading'"
          [scrollable]="true"
          scrollHeight="flex"
          selectionMode="single"
          [sortField]="sortBy"
          dataKey="id"
        >
          <ng-template pTemplate="body" let-subject let-expanded="expanded">
            <tr
              interactiveRow
              (edit)="onEdit(subject)"
              (delete)="onDelete(subject)"
            >
              <td>{{ subject.name }}</td>
              <td class="text-end">
                <div class="d-flex justify-content-end gap-2">
                  <man-action-menu
                    [data]="subject"
                    [options]="menuOptions"
                  ></man-action-menu>
                  <button
                    class="btn btn-icon"
                    type="button"
                    [pRowToggler]="subject"
                    [title]="
                      'admin.pools.subjects.table.actions.subject.expand'
                        | translate
                    "
                  >
                    <i
                      aria-hidden="true"
                      class="fa-regular"
                      [ngClass]="{
                        'fa-chevron-up': expanded,
                        'fa-chevron-down': !expanded
                      }"
                    ></i>
                  </button>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td
                colspan="3"
                class="text-center"
                translate="admin.pools.subjects.table.messages.empty"
              ></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-subject>
            <tr>
              <td colspan="3" class="py-0 px-0">
                <app-category-table
                  [categories]="subject.categories"
                ></app-category-table>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #error>
  <div class="alert alert-danger" role="alert">
    <h4
      class="alert-heading"
      translate="common.messages.something_went_wrong"
    ></h4>
    <p translate="common.messages.reloading_the_page_could_solve_problem"></p>
  </div>
</ng-template>

<ng-container *ngIf="formState$ | async as formState">
  <man-side-panel
    *ngIf="formState.show"
    [title]="
      (formState.data
        ? 'admin.pools.subjects.table.form.title.edit'
        : 'admin.pools.subjects.table.form.title.create'
      ) | translate
    "
    [closeable]="!formState.disabled"
    (closed)="subjectListForm.onClose()"
  >
    <man-subject-form
      #subjectListForm
      class="mx-n3"
      [disabled]="formState.disabled"
      [subject]="formState.data"
      (create)="onCreate($event)"
      (update)="onUpdate($event)"
      (closed)="onDismiss()"
    >
    </man-subject-form>
  </man-side-panel>
</ng-container>
