angular.module('common').factory('Repositories::Job', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getSingleQuery = 'query($id: Int!) { job(id: $id) { id category status progress result } }';

    return {
      get: function(jobId) {
        return client.query(getSingleQuery, { id: jobId }).then(function(response) {
          return response.data.data.job;
        });
      }
    };
  }
]);
