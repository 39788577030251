angular.module('settings').factory('Reducers::CategorySelection', ['Actions::CategorySelection', function (Actions) {
    function reducer (state, action) {
      state = state || {};

      switch (action.type) {
      case Actions.FETCH_DIMENSIONS_REQUEST:
        return Object.assign({}, state, {
          requestInProgress: true
        });

      case Actions.FETCH_DIMENSIONS_SUCCESS:
        return Object.assign({}, state, {
          dimensions: action.dimensions,
          requestInProgress: false,
          selectedDimensionId: action.dimensions.length === 0 ? undefined : action.dimensions[0].id
        });

      case Actions.SET_DIMENSION_SELECTION:
        return Object.assign({}, state, {
          selectedDimensionId: action.selectedId
        });

      case Actions.REMOVE_DIMENSION_SELECTION:
        return Object.assign({}, state, {
          selectedDimensionId: undefined
        });

      default:
        return state;
      }

    }

    return reducer;
  }
]);
