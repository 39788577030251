angular.module('common').directive('responseOptionsStatisticsSingleSolution', [
  function () {
    return {
      restrict: 'E',
      template: require('./response_options_single_solution.html'),
      scope: {
        statistic: '='
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.I18n = I18n;

          $scope.calculateRemainingPercent = function () {
            var data, question, sum, _ref;
            sum = 0;
            _ref = $scope.statistic;
            for (question in _ref) {
              data = _ref[question];
              if (data !== null) {
                sum += data.p * 100;
              }
            }
            return Math.max(100.0 - sum, 0);
          };
        }
      ]
    };
  }
]);
