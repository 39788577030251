import { downgradeInjectable } from '@angular/upgrade/static';
import angular from 'angular';
import { ExamQuestionGroupExporterService } from '../services/exam-question-group-exporter.service';

angular
  .module('examManagement')
  .factory(
    'ExamQuestionGroupExporter',
    downgradeInjectable(ExamQuestionGroupExporterService)
  );
