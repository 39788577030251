angular.module('settings').factory('Repositories::Dimension', ['GraphQLClient',
  function (GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
    getAllQuery = 'query($pool_id: Int!) {' +
    '  pool(pool_id: $pool_id) {' +
    '    dimensions {' +
    '      id name position' +
    '      categories {id name position}' +
    '    }' +
    '  }' +
    '}';

    return {
      getAll: function (poolId) {
        return client.query(getAllQuery, {pool_id: poolId}).then(function (response) {
          return response.data.data.pool.dimensions;
        });
      }
    };
  }
]);
