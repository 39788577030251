angular.module('taskManagement').factory('Reducers::TaskManagementRoot', [
  'Reducers::TaskQuestionGroupList', 'Reducers::TaskList',
  function (TaskQuestionGroupListReducer, TaskListReducer) {
    function reducer (state, action) {
      state = state || {};
      state = Object.assign({}, state, {
        taskList: TaskListReducer(state.taskList, action),
        taskQuestionGroupList: TaskQuestionGroupListReducer(state.taskQuestionGroupList, action)
      });
      return state;
    }

    return reducer;
  }
]);
