angular.module('examManagement').factory('Selectors::CandidateList', [
  function() {
    function getSelection(object) {
      return (object && object.candidateList && object.candidateList.selection) || [];
    }

    function getOrder(object) {
      return (
        (object && object.candidateList && object.candidateList.order) || [
          { name: 'omr', ascending: true }
        ]
      );
    }

    function getItems(object) {
      return (object && object.candidateList && object.candidateList.items) || [];
    }

    function isRequestInProgress(object) {
      return (object && object.candidateList && object.candidateList.requestInProgress) || false;
    }

    function extractAllIds(items) {
      return items.map(function(item) {
        return item.id;
      });
    }

    function filterInvalidIds(selection, items) {
      const itemIds = extractAllIds(items);
      return selection.filter(id => itemIds.includes(id));
    }

    function getSelectionCount(selection, items) {
      const validIds = filterInvalidIds(selection, items);
      return validIds.length;
    }

    function parametrizeOrder(order) {
      return order.map(function(spec) {
        return spec.name + '.' + (spec.ascending ? 'asc' : 'desc');
      });
    }

    return {
      getSelection: Reselect.createSelector(getSelection, getItems, filterInvalidIds),
      getSelectionCount: Reselect.createSelector(getSelection, getItems, getSelectionCount),
      getAllIds: Reselect.createSelector(getItems, extractAllIds),
      getOrder: getOrder,
      getItems: getItems,
      isRequestInProgress: isRequestInProgress,
      getOrderAsParameter: Reselect.createSelector(getOrder, parametrizeOrder)
    };
  }
]);
