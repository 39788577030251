/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input
} from '@angular/core';
import { Store } from '@ngxs/store';
import { RemoteData } from 'src/app/common/utils/remote-data';
import { LOCATION_TOKEN } from '../../location';

@Component({
  selector: 'man-table-error',
  templateUrl: './table-error.component.html',
  styleUrls: ['./table-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableErrorComponent {
  @Input() public request: RemoteData<any>;

  constructor(
    private store: Store,
    @Inject(LOCATION_TOKEN) private location: Location
  ) {}

  public onRetry(): void {
    if (!this.request.actions || !this.request.actions.retry) {
      this.location.reload();

      return;
    }

    const retryActions = Array.isArray(this.request.actions.retry)
      ? this.request.actions.retry
      : [this.request.actions.retry];

    this.store.dispatch(retryActions.map(action => new action()));
  }
}
