import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadApplications } from '../state/applications/applications.actions';

@Injectable({ providedIn: 'root' })
export class ApplicationsGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    this.store.dispatch(new LoadApplications());

    return true;
  }
}
