angular.module('common').factory('Selectors::ConnectionStatus', [
  function() {
    function getStatus(object) {
      if (object && object.connectionStatus) {
        if (angular.isDefined(object.connectionStatus.connected)) {
          return object.connectionStatus.connected;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }

    return {
      getStatus: getStatus
    };
  }
]);
