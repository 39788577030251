/*jshint multistr: true */
angular.module('common').directive('poolSelection', [
  '$window',
  'Repositories::Pool',
  'Store',
  'Selectors::Pool',
  function($window, Repository, Store, Selectors) {
    return {
      restrict: 'E',
      scope: true,
      template:
        '\
        <form class="form-inline">\
        <div class="input-group">\
          <span class="input-group-addon">\
            Pool\
          </span>\
          <select id="pool-selection" class="form-control" ng-options="pool as pool.name for pool in pools track by pool.id" ng-model="selectedPool" ng-change="reloadWithPool()"></select>\
        </div></form>',
      link: function($scope, $element) {
        var currentPool;

        function updateSelection() {
          $scope.selectedPool = ($scope.pools || []).find(function(p) {
            return p.id === (currentPool || {}).id;
          });
        }

        $scope.reloadWithPool = function() {
          Repository.updateCurrent($scope.selectedPool.id).then(function() {
            $window.location.href = '/';
          });
        };

        Repository.getAllViewable().then(function(pools) {
          $scope.pools = pools;
          updateSelection();
        });

        var poolSubscription = Store.subscribeOn(Selectors.getCurrentPool, function(pool) {
          currentPool = pool;
          updateSelection();
        });

        $scope.$on('destroy', function() {
          poolSubscription();
        });
      }
    };
  }
]);
