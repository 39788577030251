import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';

angular
  .module('common')
  .directive(
    'coConfirmModal',
    downgradeComponent({ component: ConfirmModalComponent })
  );
