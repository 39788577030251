angular.module('common').factory('BtnErrorHandler', ['BasicErrorHandler', function (BasicErrorHandler) {
  function BtnErrorHandler (element) {
    BasicErrorHandler.bind(this)(element, {
      fieldSelector: 'button',
      iconSelector: '.btn-inline-error__icon',
      tooltipSelector: '.inline-error__tooltip'
    });
  }

  BtnErrorHandler.prototype = angular.copy(BasicErrorHandler.prototype);

  BtnErrorHandler.prototype.readFieldValue = function () {
    return this.element.find('button').hasClass('active');
  };

  return BtnErrorHandler;
}]);
