/*jshint multistr: true */
angular.module('examManagement').directive('examValidations', [
  'Repositories::Exam',
  function(ExamRepository) {
    return {
      restrict: 'E',
      scope: {
        exam: '=',
        onValidate: '&'
      },
      template:
        '<i class="fa fa-spinner fa-spin" ng-if="loading"></i>\
    <ul ng-if="!loading">\
      <li ng-repeat="message in messages" class="skin-message--{{message.type}}">\
        <span>{{message.content}}</span>\
      </li>\
    </ul>',
      link: function($scope) {
        $scope.loading = true;
        ExamRepository.validate($scope.exam.id).then(
          function() {
            $scope.onValidate({ valid: true });
            $scope.loading = false;
            $scope.messages = [];
          },
          function(data) {
            $scope.onValidate({ valid: false });
            $scope.loading = false;
            $scope.messages = data.notifications;
          }
        );
      }
    };
  }
]);
