import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadDimensions } from '../../../state/pool-details/dimensions/dimensions.actions';
import { LoadSubjects } from '../../../state/pool-details/subjects/subjects.actions';

@Injectable({ providedIn: 'root' })
export class SubjectsListGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    this.store.dispatch([new LoadSubjects(), new LoadDimensions()]);

    return true;
  }
}
