import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '../../../generated/base-types';

type LocalisedStringKey = `${string}${Language}`;

@Pipe({
  name: 'aggregateLocalisation',
  pure: false
})
export class AggregateLocalisationPipe implements PipeTransform {
  public transform(
    object: Record<LocalisedStringKey, string>,
    key: string
  ): Record<string, string> {
    const localisedStrings = Object.entries(object)
      .filter(([localisedStringKey]) => localisedStringKey.startsWith(key))
      .reduce((acc, [localisedStringKey, value]) => {
        const language = localisedStringKey.slice(key.length).toLowerCase();
        acc[language] = value;

        return acc;
      }, {} as Record<string, string>);

    return localisedStrings;
  }
}
