import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastsService, Toast } from '../services/toasts.service';

@Component({
  selector: 'co-toasts-container',
  templateUrl: './toasts-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'toasts' }
})
export class ToastsContainerComponent {
  public readonly toasts$: Observable<Toast[]>;

  constructor(private readonly toastsService: ToastsService) {
    this.toasts$ = this.toastsService.toasts$;
  }

  public onRemove(toast: Toast): void {
    this.toastsService.remove(toast);
  }
}
