<small>{{ filename }}</small>
<progress max="100" [value]="percentage"></progress>
<div class="row">
  <div class="col-sm-6">
    <small>{{ 'common.uploading' | translate }}...</small>
  </div>
  <div class="col-sm-6 text-right">
    <small>{{ percentage }}%</small>
  </div>
</div>
