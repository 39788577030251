/* eslint-disable unicorn/no-null */

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Table } from 'primeng/table';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { FuzzySearchService } from 'src/app/admin/common/fuzzy-search.service';
import { FormState } from 'src/app/common/types/form-types';
import {
  getDirectionFromIndices,
  getMoveQuantity
} from 'src/app/common/utils/drag-and-drop';
import { assertIsDefined } from 'src/app/common/utils/type-guards/is-defined';
import { Affiliation } from 'src/generated/base-types';
import { RemoteData } from '../../../../common/utils/remote-data';
import { ActionMenuOption } from '../../../common/action-menu/action-menu.component';
import { ModalService } from '../../../common/modal/modal.service';
import { CreateAffiliationMutationVariables } from '../../../services/create-affiliation.generated';
import { UpdateAffiliationMutationVariables } from '../../../services/update-affiliation.generated';
import {
  CreateAffiliation,
  DeleteAffiliation,
  SetAffiliationPosition,
  UpdateAffiliation
} from '../../../state/pool-details/affiliations/affiliations.actions';
import { AffiliationsState } from '../../../state/pool-details/affiliations/affiliations.state';

@Component({
  templateUrl: './affiliations-list.component.html',
  host: { class: 'page' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AffiliationsListComponent implements OnDestroy {
  @ViewChild('table', { static: false }) public table: Table;

  public readonly loading$: Observable<boolean>;
  public readonly filterFields = ['id', 'name'];
  public sortBy = 'position';
  public readonly affiliations$: Observable<RemoteData<Affiliation[]>>;
  public readonly formState$: Observable<FormState<Partial<Affiliation>>>;
  public readonly menuOptions: ActionMenuOption[] = [
    {
      label: 'Edit',
      callback: (data: Affiliation) => this.onEdit(data)
    },
    {
      label: 'Delete',
      danger: true,
      callback: (data: Affiliation) => this.onDelete(data)
    }
  ];
  private searchStringSource = new BehaviorSubject<string>('');
  private searchString$ = this.searchStringSource.asObservable();
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private fuzzySearchService: FuzzySearchService<Affiliation>
  ) {
    this.formState$ = this.formState();
    this.dismissSidebarOnSuccessfulMutation();
    this.affiliations$ = combineLatest([
      this.store.select(AffiliationsState.affiliations),
      this.searchString$
    ]).pipe(
      map(([affiliations, searchString]) => {
        const result = this.fuzzySearchService.search(
          affiliations.data || [],
          searchString,
          this.filterFields
        );

        return { ...affiliations, data: result };
      })
    );
    this.loading$ = this.store.select(AffiliationsState.affiliationsLoading);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSearch(value: string): void {
    this.searchStringSource.next(value);
    this.sortBy = '';
  }

  public onSearchClear(): void {
    this.table?.clear();
    this.sortBy = 'position';
  }

  public onNew(): void {
    this.routeToForm('new');
  }

  public onEdit({ id }: Affiliation): void {
    this.routeToForm(id);
  }

  public onCreate({
    name
  }: Pick<CreateAffiliationMutationVariables, 'name'>): void {
    this.store.dispatch(new CreateAffiliation(name));
  }

  public onUpdate({ id, name }: UpdateAffiliationMutationVariables): void {
    this.store.dispatch(new UpdateAffiliation(id, name));
  }

  public onDismiss(): void {
    this.routeToForm();
  }

  public onReorder({
    dragIndex,
    dropIndex
  }: {
    dragIndex: number;
    dropIndex: number;
  }): void {
    if (dragIndex === dropIndex) return;
    const { id } =
      this.store.selectSnapshot(AffiliationsState.findAffiliationAtIndex)(
        dragIndex
      ) ?? {};
    assertIsDefined(id);

    const moveDirection = getDirectionFromIndices(dragIndex, dropIndex);
    const moveQuantity = getMoveQuantity(dragIndex, dropIndex);

    this.store.dispatch(
      new SetAffiliationPosition(id, moveDirection, moveQuantity)
    );
  }

  public async onDelete(affiliation: Affiliation): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();
    if (confirmDelete) {
      this.store.dispatch(new DeleteAffiliation(affiliation.id));
    }
  }

  private formState(): Observable<FormState<Partial<Affiliation>>> {
    const editingParam$ = this.route.queryParamMap.pipe(
      map(params => params.get('editing') ?? undefined)
    );
    const disabled$ = this.store
      .select(AffiliationsState.mutationRequestState)
      .pipe(
        map(requestState => requestState === 'loading'),
        distinctUntilChanged()
      );

    return combineLatest([editingParam$, disabled$]).pipe(
      switchMap(([editingParam, disabled]) => {
        const show: boolean = editingParam !== undefined;
        const id = show && editingParam !== 'new' ? editingParam : undefined;

        return this.affiliations$.pipe(
          filter(({ requestState }) => requestState === 'success'),
          take(1),
          map(({ data }) =>
            id === undefined ? undefined : data?.find(a => a.id === id)
          ),
          map(data => ({ show, disabled, data }))
        );
      })
    );
  }

  private dismissSidebarOnSuccessfulMutation(): void {
    this.store
      .select(AffiliationsState.mutationRequestState)
      .pipe(
        pairwise(),
        filter(
          ([lastState, currentState]) =>
            lastState === 'loading' && currentState === 'success'
        ),
        tap({ next: () => this.onDismiss() }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private routeToForm(editing?: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { editing },
      queryParamsHandling: 'merge'
    });
  }
}
