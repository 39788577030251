import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import type { OperatorFunction } from 'rxjs';
import { distinct, map, skip, takeUntil } from 'rxjs/operators';
import {
  ValidationsSeverity,
  ValidationsMessage
} from 'src/generated/base-types';
import { Voidable } from '../../common/utils/type-guards/voidable';
import { RichEditorComponent } from '../rich-editor/rich-editor.component';
import { TinyMCEEditor } from '../rich-editor/tiny-mce.types';

declare let tinyMCE: TinyMCEEditor;

const alertRanks = {
  [ValidationsSeverity.Error]: 0,
  [ValidationsSeverity.Warning]: 1
};

function mapToMostSevereMessages(): OperatorFunction<
  ValidationsMessage[],
  ValidationsMessage[]
> {
  return map(messages => {
    const sortedMessages = [...messages];
    sortedMessages.sort(
      (a, b) => alertRanks[a.severity] - alertRanks[b.severity]
    );
    const mostSevereType = sortedMessages[0]?.severity;

    return messages.filter(({ severity }) => severity === mostSevereType);
  });
}

@Component({
  selector: 'qf-input-feedback',
  templateUrl: './input-feedback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFeedbackComponent implements OnDestroy {
  public errors$: Observable<string[]>;
  public warnings$: Observable<string[]>;
  private destroy$: Subject<void> = new Subject<void>();
  private field$: Subject<HTMLElement> = new Subject<HTMLElement>();
  private validations$: Subject<ValidationsMessage[]> = new BehaviorSubject<
    ValidationsMessage[]
  >([]);
  private inputValue$: Subject<unknown> = new Subject<unknown>();

  constructor() {
    const mostSevereMessages$ = this.validations$.pipe(
      mapToMostSevereMessages(),
      takeUntil(this.destroy$)
    );

    this.errors$ = mostSevereMessages$.pipe(
      map(messages => {
        return messages
          .filter(({ severity }) => severity === ValidationsSeverity.Error)
          .map(({ message }) => message);
      }),
      takeUntil(this.destroy$)
    );

    this.warnings$ = mostSevereMessages$.pipe(
      map(messages => {
        return messages
          .filter(({ severity }) => severity === ValidationsSeverity.Warning)
          .map(({ message }) => message);
      }),
      takeUntil(this.destroy$)
    );

    this.inputValue$
      .pipe(skip(1), distinct(), takeUntil(this.destroy$))
      .subscribe(value => {
        if (value !== undefined) {
          this.validations$.next([]);
        }
      });

    combineLatest(this.field$, mostSevereMessages$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([field, alerts]) => {
        if (field === undefined) {
          return;
        }
        field.classList.remove('is-invalid', 'is-warning');

        if (alerts[0]?.severity === ValidationsSeverity.Error) {
          field.classList.add('is-invalid');
        } else if (alerts[0]?.severity === ValidationsSeverity.Warning) {
          field.classList.add('is-warning');
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.validations$.complete();
    this.field$.complete();
  }

  @Input() public set validations(value: ValidationsMessage[] | Voidable) {
    this.validations$.next(value === undefined || value === null ? [] : value);
  }

  @Input() public set inputField(
    value:
      | HTMLInputElement
      | NgSelectComponent
      | RichEditorComponent
      | HTMLDivElement
  ) {
    if (value instanceof HTMLInputElement) {
      this.field$.next(value);
    } else if (value instanceof NgSelectComponent) {
      this.field$.next(value.element);
    } else if (value instanceof RichEditorComponent) {
      // get editor and wait until it is fully loaded
      const editor = document.querySelector('#' + value.id);
      tinyMCE.get('' + value.id).on('init', () => {
        if (editor !== undefined && editor !== null) {
          this.field$.next(editor.nextElementSibling as HTMLElement);
        }
      });
    } else if (value instanceof HTMLDivElement) {
      this.field$.next(value);
    }
  }

  @Input() public set inputValue(value: unknown) {
    this.inputValue$.next(value);
  }
}
