<div ngbDropdown placement="bottom-right">
  <button class="btn btn-primary btn-sm" ngbDropdownToggle>
    {{ 'question_management.toolbar.create_task' | translate }}
    <i class="fa fa-caret-down"></i>
  </button>
  <div ngbDropdownMenu>
    <div class="collapsable-target">
      <button
        (click)="createTask()"
        class="btn btn-primary btn-sm"
        ngbDropdownItem
      >
        {{ 'question_management.toolbar.task.create' | translate }}
      </button>
      <button
        (click)="reviseTask()"
        [disabled]="!hasSelection"
        class="btn btn-primary btn-sm"
        ngbDropdownItem
      >
        {{ 'question_management.toolbar.task.revise' | translate }}
      </button>
      <button
        (click)="commentTask()"
        [disabled]="!hasSelection"
        class="btn btn-primary btn-sm"
        ngbDropdownItem
      >
        {{ 'question_management.toolbar.task.comment' | translate }}
      </button>
      <button
        *ngIf="(languages$ | async : []).length > 1"
        (click)="translateTask()"
        [disabled]="!hasSelection"
        class="btn btn-primary btn-sm"
        ngbDropdownItem
      >
        {{ 'question_management.toolbar.task.translate' | translate }}
      </button>
    </div>
  </div>
</div>
