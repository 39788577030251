(function() {
  angular.module('examManagement').controller('AccessPaperFormCtrl', [
    '$scope',
    '$uibModalInstance',
    'Candidate',
    'File',
    'Selectors::CandidateList',
    'Selectors::ExamList',
    'Store',
    function(
      $scope,
      $uibModalInstance,
      Candidate,
      File,
      CandidateListSelectors,
      ExamSelectors,
      Store
    ) {
      function getBeginningOfDay() {
        return new Date(1970, 0, 1, 0, 0, 0);
      }

      function getEndOfDay() {
        return new Date(1970, 0, 1, 23, 59, 0);
      }

      function getSelectedExam() {
        return ExamSelectors.getSelectedItem(Store.getState());
      }

      function updateDateWithTime(date, time) {
        date.setHours(time.getHours());
        date.setMinutes(time.getMinutes());
        date.setSeconds(time.getSeconds());
      }

      function convertToDate(date) {
        if (angular.isDate(date)) {
          return date;
        } else {
          return new Date(date);
        }
      }

      $scope.I18n = I18n;
      $scope.errors = {};
      $scope.accessSettings = {
        mode: 'today',
        validFrom: null,
        validTo: null,
        maxLogins: 100,
        setModeToToday: function() {
          $scope.accessSettings.validFrom = new Date();
          $scope.accessSettings.validFromTime = getBeginningOfDay();
          $scope.accessSettings.validTo = new Date();
          $scope.accessSettings.validToTime = getEndOfDay();
        },
        setModeToExamDate: function() {
          $scope.accessSettings.validFrom = new Date(getSelectedExam().date);
          $scope.accessSettings.validFromTime = getBeginningOfDay();
          $scope.accessSettings.validTo = new Date(getSelectedExam().date);
          $scope.accessSettings.validToTime = getEndOfDay();
        }
      };

      $scope.format = 'qrc';
      $scope.accessSettings.setModeToToday();

      $scope.toggleUnlimitedLogins = function() {
        if ($scope.accessSettings.maxLogins == 999) {
          $scope.accessSettings.maxLogins = 100;
        } else {
          $scope.accessSettings.maxLogins = 999;
        }
      };

      $scope.cancel = function() {
        $uibModalInstance.dismiss();
      };

      $scope.confirm = function() {
        var requestParams,
          errorCallback,
          successCallback,
          validFrom = convertToDate($scope.accessSettings.validFrom),
          validFromTime = convertToDate($scope.accessSettings.validFromTime),
          validTo = convertToDate($scope.accessSettings.validTo),
          validToTime = convertToDate($scope.accessSettings.validToTime);

        updateDateWithTime(validFrom, validFromTime);
        updateDateWithTime(validTo, validToTime);

        requestParams = {
          candidate_ids: CandidateListSelectors.getSelection(Store.getState()),
          access_settings: {
            valid_from: validFrom ? validFrom.getTime() : null,
            valid_to: validTo ? validTo.getTime() : null,
            max_logins: $scope.accessSettings.maxLogins
          },
          orderBy: CandidateListSelectors.getOrderAsParameter(Store.getState()),
          format: $scope.format
        };

        $scope.$on('job.finished', function(event, job) {
          if (job.id == $scope.jobId) {
            var result = JSON.parse(job.result);
            File.download(result.access_papers_path);
            $uibModalInstance.close();
          }
        });

        Candidate.fetchAccessPapers(getSelectedExam().id, requestParams).then(
          function(data) {
            $scope.jobId = data.job_id;
          }
        );
      };
    }
  ]);
}.call(this));
