import { downgradeInjectable } from '@angular/upgrade/static';
import angular from 'angular';
import { TaskQuestionGroupExporterService } from '../services/task-question-group-exporter.service';

angular
  .module('taskManagement')
  .factory(
    'TaskQuestionGroupExporter',
    downgradeInjectable(TaskQuestionGroupExporterService)
  );
