import * as Types from '../../generated/base-types';

import { gql } from 'apollo-angular';
import { LoadUsersFieldsFragmentDoc } from '../admin/services/load-users.fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoadUserFormQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type LoadUserFormQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null, language: Types.Language, organisation?: string | null, alternativeEmail?: string | null, mobilePhone?: string | null, alternativeMobilePhone?: string | null, currentSignInAt?: string | null, superUser: boolean, useEmailForMtan: boolean } };

export const LoadUserFormDocument = gql`
    query LoadUserForm($userId: ID!) {
  user(id: $userId) {
    ...LoadUsersFields
  }
}
    ${LoadUsersFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadUserFormGQL extends Apollo.Query<LoadUserFormQuery, LoadUserFormQueryVariables> {
    document = LoadUserFormDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }