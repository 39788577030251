angular.module('examManagement').config([
  '$httpProvider',
  '$sceProvider',
  '$uibModalProvider',
  function($httpProvider, $sceProvider, $uibModalProvider) {
    var authToken;
    $uibModalProvider.options['backdrop'] = 'static';
    $uibModalProvider.options['keyboard'] = false;
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    authToken = document.querySelector('meta[name="csrf-token"]');
    if (authToken) {
      $httpProvider.defaults.headers.common['X-CSRF-TOKEN'] = authToken.getAttribute('content');
    }

    $sceProvider.enabled(false);
  }
]).run([
  '$rootScope',
  'Store',
  'Actions::ExamQuestionGroupList',
  'Selectors::ExamQuestionGroupList',
  'Selectors::ExamList',
  'Selectors::Pool',
  function($rootScope, Store, Actions, Selectors, ExamSelectors, PoolSelectors) {
    var visibilities = JSON.parse(localStorage.getItem('examQuestionGroupList.columns.visibility')),
      columns = {
        sequential_number: { enabled: true, visible: true },
        id_in_book_a: { enabled: true, visible: false },
        id_in_book_b: { enabled: true, visible: false },
        legacy_id: { enabled: true, visible: false },
        literary_reference: { enabled: true, visible: false },
        title: { enabled: true, visible: false },
        supervisor: { enabled: true, visible: false },
        affiliation: { enabled: true, visible: false },
        content_validation_state_de: { enabled: false, visible: false },
        content_validation_state_fr: { enabled: false, visible: false },
        content_validation_state_it: { enabled: false, visible: false },
        content_validation_state_en: { enabled: false, visible: false },
        type: { enabled: true, visible: true },
        question_types: { enabled: true, visible: true },
        score: { enabled: true, visible: false },
        has_images: { enabled: true, visible: true },
        has_videos: { enabled: true, visible: true },
        category_0: { enabled: false, visible: true },
        category_1: { enabled: false, visible: true },
        category_2: { enabled: false, visible: true },
        category_3: { enabled: false, visible: true },
        category_4: { enabled: false, visible: true },
        category_5: { enabled: false, visible: true },
        category_6: { enabled: false, visible: true },
        category_7: { enabled: false, visible: true },
        created_at: { enabled: true, visible: true },
        updated_at: { enabled: true, visible: false },
        editor: { enabled: true, visible: false },
        author: { enabled: true, visible: false },
        number_of_usages: { enabled: true, visible: true },
        last_usage: { enabled: true, visible: false },
        latest_statistic: { enabled: true, visible: false },
        cache_last_usage_n: { enabled: true, visible: false },
        cache_last_usage_p: { enabled: true, visible: true },
        cache_last_usage_r: { enabled: true, visible: true },
        cache_last_usage_lne: { enabled: true, visible: false },
        cache_last_usage_eliminated: { enabled: true, visible: true },
        exams: { enabled: true, visible: true },
        content_size_de: { enabled: false, visible: true },
        content_size_fr: { enabled: false, visible: true },
        content_size_it: { enabled: false, visible: true },
        content_size_en: { enabled: false, visible: true },
        revision_status: { enabled: true, visible: false },
        revision_year: { enabled: true, visible: false }
      };

    for (var k in visibilities) {
      if (columns[k] !== undefined) {
        columns[k].visible = visibilities[k];
      }
    }
    Store.dispatch(Actions.setColumnDefinitions(columns));

    Store.subscribeOn(Selectors.getColumnVisibilities, function(v) {
      localStorage.setItem('examQuestionGroupList.columns.visibility', JSON.stringify(v));
    });

    Store.subscribeOn(ExamSelectors.getSelectedItem, function(exam) {
      if (exam !== undefined && exam.dimensions !== undefined) {
        var columns = Array(8)
          .fill(0)
          .reduce(function(acc, value, index) {
            acc['category_' + index] = {
              visible: true,
              enabled: index < exam.dimensions.length,
              translationParams: { name: exam.dimensions[index] && exam.dimensions[index].name }
            };
            return acc;
          }, {});
        Store.dispatch(Actions.updateColumnDefinitions(columns));
      }
    });

    Store.subscribeOn(PoolSelectors.getCurrentPool, function (pool) {
      if (pool) {
        var columns = {
          content_validation_state_de: { enabled: pool.languages.indexOf('de') > -1, visible: false },
          content_validation_state_fr: { enabled: pool.languages.indexOf('fr') > -1, visible: false },
          content_validation_state_it: { enabled: pool.languages.indexOf('it') > -1, visible: false },
          content_validation_state_en: { enabled: pool.languages.indexOf('en') > -1, visible: false },
          content_size_de: { enabled: pool.languages.indexOf('de') > -1, visible: true },
          content_size_fr: { enabled: pool.languages.indexOf('fr') > -1, visible: true },
          content_size_it: { enabled: pool.languages.indexOf('it') > -1, visible: true },
          content_size_en: { enabled: pool.languages.indexOf('en') > -1, visible: true },
        };
        Store.dispatch(Actions.updateColumnDefinitions(columns));
      }
    });
  }
]);
