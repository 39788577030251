angular.module('common').directive('togglePanel', ['Panel', function(Panel) {
    return {
      restrict: 'A',
      replace: false,
      link: function (scope, element, attrs) {
        var panelType = attrs.togglePanel;
        element.bind('click', function(e) {
          e.preventDefault();
          Panel.toggle(panelType);
          scope.$apply();
        });
      }
    };
  }
]);
