import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetSourceLanguageMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  language: Types.Language;
}>;


export type SetSourceLanguageMutation = { __typename?: 'Mutation', setSourceLanguage?: { __typename?: 'SetSourceLanguagePayload', successful: boolean } | null };

export const SetSourceLanguageDocument = gql`
    mutation SetSourceLanguage($poolId: ID!, $questionGroupIds: [ID!]!, $language: Language!) {
  setSourceLanguage(
    poolId: $poolId
    questionGroupIds: $questionGroupIds
    sourceLanguage: $language
  ) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetSourceLanguageGQL extends Apollo.Mutation<SetSourceLanguageMutation, SetSourceLanguageMutationVariables> {
    document = SetSourceLanguageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }