/* eslint-disable camelcase, unicorn/no-null */
import {
  Component,
  Input,
  OnDestroy,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileSaverService } from 'src/app/common/services/file-saver.service';
import {
  Language,
  LongMenuListEntryInput,
  QuestionInput,
  QuestionLongMenuInput
} from '../../../generated/base-types';
import { LanguageVisibility } from '../../state/settings.state';
import { QuestionGroupFormComponent } from '../question-group-form/question-group-form.component';
import { ValidationsQuestionLongMenuGraphQL } from '../services/graphql-question-group-types';
import { LongMenuSolutionTransformerService } from './long-menu-solution-transformer.service';

@Component({
  selector: 'qf-question-content-type-long-menu-form',
  templateUrl: './question-content-type-long-menu-form.component.html',
  styleUrls: ['./question-content-type-long-menu-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionContentTypeLongMenuFormComponent),
      multi: true
    }
  ]
})
export class QuestionContentTypeLongMenuFormComponent
  implements OnDestroy, ControlValueAccessor
{
  @Input() public languageVisibility: LanguageVisibility;
  @Input()
  public set validations(
    value: ValidationsQuestionLongMenuGraphQL | undefined
  ) {
    this._validations = value;
    this.validations$.next(value);
  }
  public get validations(): ValidationsQuestionLongMenuGraphQL | undefined {
    return this._validations;
  }
  @Input() public languages: Language[];
  @Input() public sequentialNumber: number;

  public form: UntypedFormGroup;
  public selectedLanguage: Language;
  public selectedListEntryId?: number;
  public list: LongMenuListEntryInput[] | null = null;
  public solution: QuestionLongMenuInput['solution'];

  private _validations: ValidationsQuestionLongMenuGraphQL | undefined;
  private question$ = new Subject<QuestionInput>();
  private validations$ = new Subject<
    ValidationsQuestionLongMenuGraphQL | undefined
  >();
  private destroy$ = new Subject<void>();

  constructor(
    builder: UntypedFormBuilder,
    cd: ChangeDetectorRef,
    private readonly fileSaverService: FileSaverService,
    private readonly longMenuSolutionTransformerService: LongMenuSolutionTransformerService
  ) {
    this.form = builder.group({
      contentDe: null,
      contentFr: null,
      contentEn: null,
      contentIt: null,
      videoDe: null,
      videoFr: null,
      videoEn: null,
      videoIt: null,
      list: null,
      responses: null
    });

    setTimeout(() => {
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(changes => {
          this.onChange({
            video: {
              de: changes.videoDe,
              fr: changes.videoFr,
              en: changes.videoEn,
              it: changes.videoIt
            },
            longMenu: {
              content: {
                de: changes.contentDe,
                fr: changes.contentFr,
                en: changes.contentEn,
                it: changes.contentIt
              },
              list: changes.list,
              numberOfResponses: changes.responses[0],
              solution: changes.responses[1]
            }
          });
          this.onTouch();
          this.list = changes.list ?? null;
          this.solution = [...(changes.responses[1] ?? [])];
          this.selectedListEntryId = undefined;
          cd.markForCheck();
        });
    }, QuestionGroupFormComponent.CHANGE_DETECTION_TIMEOUT);

    this.question$.pipe(takeUntil(this.destroy$)).subscribe(question => {
      this.form.setValue({
        contentDe: question.longMenu?.content.de ?? null,
        contentFr: question.longMenu?.content.fr ?? null,
        contentEn: question.longMenu?.content.en ?? null,
        contentIt: question.longMenu?.content.it ?? null,
        videoDe: question.video?.de ?? null,
        videoFr: question.video?.fr ?? null,
        videoEn: question.video?.en ?? null,
        videoIt: question.video?.it ?? null,
        list: question.longMenu?.list,
        responses: [
          question.longMenu?.numberOfResponses,
          question.longMenu?.solution ?? []
        ]
      });
      this.list = question.longMenu?.list ?? [];
      this.solution = question.longMenu?.solution ?? [];
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(question: QuestionInput): void {
    this.question$.next(question);
  }

  public clearSolution(downloadList: boolean): void {
    if (downloadList) {
      const converted = this.longMenuSolutionTransformerService.convertSolution(
        this.list,
        this.form.controls.responses.value[1],
        this.sequentialNumber
      );
      this.fileSaverService.saveStringAs(
        converted,
        `${this.sequentialNumber}_Solution_${Date.now()}.txt`,
        'text/plain'
      );
    }
    const responses = this.form.controls.responses.value;
    this.form.controls.responses.setValue([responses[0], []]);
  }

  public registerOnChange(fn: (value: QuestionInput) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  private onChange: (value: QuestionInput) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}
