angular.module('common').factory('Reducers::AnnouncementList', [
  'Actions::AnnouncementList',
  function (Actions) {
    function reducer (state, action) {
      state = state || {};
      state = Object.assign({}, state, {
        ids: state.ids || []
      });

      switch (action.type) {
      case Actions.FETCH_ANNOUNCEMENTS_REQUEST:
        return Object.assign({}, state, {requestInProgress: true});

      case Actions.FETCH_ANNOUNCEMENTS_SUCCESS:
        return Object.assign({}, state, {
          items: action.announcements,
          requestInProgress: false,
        });

      default:
        return state;
      }

    }

    return reducer;
  }
]);
