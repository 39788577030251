angular.module('common').factory('Repositories::RevisionStatus', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getAll =
        'query($pool_id: Int!) { pool(pool_id: $pool_id) { revision_status { id name short_name } } }';
    return {
      getAll: function(poolId) {
        return client.query(getAll, {pool_id: poolId}).then(function(response) {
          return response.data.data.pool.revision_status;
        });
      }
    };
  }
]);
