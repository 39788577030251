<form class="page form" [formGroup]="form" (submit)="onSave()" novalidate>
  <div class="page-content">
    <ng-container *ngIf="edit">
      <man-field
        labelFor="uid"
        [label]="'admin.applications.form.label.uuid' | translate"
      >
        <input
          id="uid"
          readonly
          disabled
          type="text"
          class="form-control font-monospace text-muted"
          [value]="application?.uid"
        />
      </man-field>
      <man-field
        labelFor="secret"
        [label]="'admin.applications.form.label.secret' | translate"
      >
        <div class="input-group">
          <input
            id="secret"
            readonly
            disabled
            class="form-control font-monospace text-muted"
            type="password"
            [value]="application?.secret"
            #secretInput
          />
          <button
            (click)="
              secretInput.type === 'password'
                ? (secretInput.type = 'text')
                : (secretInput.type = 'password')
            "
            class="btn btn-secondary"
            type="button"
          >
            <span class="fa fa-eye"></span>
          </button>
        </div>
      </man-field>
    </ng-container>
    <man-field
      labelFor="name"
      [label]="'admin.applications.form.label.name' | translate"
      [errors]="form | fieldErrors : 'name'"
    >
      <input
        id="name"
        type="text"
        formControlName="name"
        class="form-control"
        cdkFocusInitial
      />
    </man-field>
    <man-field
      labelFor="redirect-uri"
      [label]="'admin.applications.form.label.redirect_uri' | translate"
      placeholder="https://example.com/oauth/callback"
      [errors]="form | fieldErrors : 'redirectUri'"
    >
      <input
        id="redirect-uri"
        type="text"
        formControlName="redirectUri"
        class="form-control"
        placeholder="https://example.com/oauth/callback"
      />
    </man-field>
  </div>

  <div class="page-footer">
    <button
      type="submit"
      class="btn btn-primary order-2"
      [disabled]="disabled || !form.dirty || !form.valid"
    >
      <ng-container *ngIf="!disabled; else loading">
        {{
          (edit ? 'common.actions.save_changes' : 'common.actions.create')
            | translate
        }}
      </ng-container>
    </button>
    <button
      type="button"
      [disabled]="disabled"
      class="btn btn-light order-1"
      (click)="onClose()"
    >
      {{ 'common.actions.close' | translate }}
    </button>
  </div>
</form>
<ng-template #loading>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.actions.loading' | translate }}
</ng-template>
