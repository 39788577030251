import { ExportColumnDefinition } from './export-column-definition';

const ROW_DELIMITER = '\r\n';
const FIELD_DELIMITER = ',';

function wrapInQuotes(field: string): string {
  return /,|"/.test(field) ? `"${field.replace(/"/g, '""')}"` : field;
}

export class CsvGeneratorService<T> {
  private objects: T[] = [];

  constructor(private columnDefinitions: ExportColumnDefinition<T>[]) {}

  public add(object: T): void {
    this.objects = [...this.objects, object];
  }

  public addObjects(objects: T[]): void {
    this.objects = [...this.objects, ...objects];
  }

  public toString(): string {
    const headers = this.columnDefinitions
      .map(definition => definition.name)
      .map(wrapInQuotes)
      .join(FIELD_DELIMITER);
    const rows = this.objects.map(object => {
      return this.columnDefinitions
        .map(definition => definition.value(object))
        .map(wrapInQuotes)
        .join(FIELD_DELIMITER);
    });

    return [headers].concat(rows).join(ROW_DELIMITER);
  }

  public toBlob(): Blob {
    return new Blob([this.toString()], { type: 'text/csv' });
  }
}
