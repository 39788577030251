// Question and Question Group types to match load-question-group.graphql

// since the load-question-group.graphql query does not allow for the different types (in this case solutions)
// to be fetched with the same name, we have to also create easy to use types which deviate slightly from the ones
// in base-types.ts

import {
  Maybe,
  Question,
  QuestionA,
  QuestionAMinus,
  QuestionE,
  QuestionFreeText,
  QuestionGroupType,
  QuestionKprime,
  QuestionLongMenu,
  QuestionSequence,
  QuestionSeries,
  QuestionType,
  Scalars,
  SingleQuestion,
  ValidationsMessage,
  ValidationsQuestionA,
  ValidationsQuestionAMinus,
  ValidationsQuestionE,
  ValidationsQuestionFreeText,
  ValidationsQuestionGroup,
  ValidationsQuestionKPrime,
  ValidationsQuestionLongMenu,
  ValidationsTranslatedContent,
  VideoAsset
} from '../../../generated/base-types';

type PartialVideoAsset = Omit<VideoAsset, 'updatedAt'> & {
  updatedAt?: Scalars['ISO8601DateTime'];
};

type PartialVideo = {
  de: Maybe<PartialVideoAsset>;
  en: Maybe<PartialVideoAsset>;
  fr: Maybe<PartialVideoAsset>;
  it: Maybe<PartialVideoAsset>;
};

export type QuestionTypesGraphQL =
  | QuestionAGraphQL
  | QuestionAMinusGraphQL
  | QuestionKPrimeGraphQL
  | QuestionEGraphQL
  | QuestionFreeTextGraphQL;

export type QuestionGraphQL = Partial<
  Omit<Question, 'video'> & { video: PartialVideo }
>;

export type QuestionAGraphQL = Partial<
  Omit<QuestionA, 'solution' | 'video'> & {
    questionASolution: QuestionA['solution'];
    video: PartialVideo;
  }
>;

export type QuestionAMinusGraphQL = Partial<
  Omit<QuestionAMinus, 'solution' | 'video'> & {
    questionAMinusSolution: QuestionAMinus['solution'];
    video: PartialVideo;
  }
>;

export type QuestionKPrimeGraphQL = Partial<
  Omit<QuestionKprime, 'solution' | 'video'> & {
    questionKprimeSolution: QuestionKprime['solution'];
    video: PartialVideo;
  }
>;

export type QuestionEGraphQL = Partial<
  Omit<QuestionE, 'solution' | 'video'> & {
    questionESolution: QuestionE['solution'];
    video: PartialVideo;
  }
>;

export type QuestionLongMenuGraphQL = Partial<
  Omit<QuestionLongMenu, 'solution' | 'video'> & {
    questionLongMenuSolution: QuestionLongMenu['solution'];
    video: PartialVideo;
  }
>;

export type QuestionFreeTextGraphQL = Partial<
  Omit<QuestionFreeText, 'video'> & {
    questionFreeTextSolution: QuestionFreeText['solution'];
    video: PartialVideo;
  }
>;

export type QuestionSequenceGraphQL = Omit<QuestionSequence, 'questions'> & {
  questions: QuestionTypesGraphQL[];
};

export type QuestionSeriesGraphQL = Omit<QuestionSeries, 'questions'> & {
  questions: QuestionTypesGraphQL[];
};

export type SingleQuestionGraphQL = Omit<SingleQuestion, 'question'> & {
  question?: QuestionTypesGraphQL;
};

export type ValidationsQuestionAGraphQL = Omit<
  ValidationsQuestionA,
  'solution'
> & {
  aSolution: ValidationsMessage[];
};

export type ValidationsQuestionAMinusGraphQL = Omit<
  ValidationsQuestionAMinus,
  'solution'
> & {
  aMinusSolution: ValidationsMessage[];
};

export type ValidationsQuestionKPrimeGraphQL = Omit<
  ValidationsQuestionKPrime,
  'solution'
> & {
  kPrimeSolution: ValidationsMessage[];
};

export type ValidationsQuestionEGraphQL = Omit<
  ValidationsQuestionE,
  'solution'
> & {
  eSolution: ValidationsMessage[];
};

export type ValidationsQuestionFreeTextGraphQL = Omit<
  ValidationsQuestionFreeText,
  'solution'
> & {
  freeTextSolution: ValidationsTranslatedContent;
};

export type ValidationsQuestionLongMenuGraphQL = Omit<
  ValidationsQuestionLongMenu,
  'solution'
> & {
  longMenuSolution: ValidationsMessage[];
};

export type ValidationsQuestionGraphQL =
  | ValidationsQuestionAGraphQL
  | ValidationsQuestionAMinusGraphQL
  | ValidationsQuestionKPrimeGraphQL
  | ValidationsQuestionEGraphQL
  | ValidationsQuestionFreeTextGraphQL
  | ValidationsQuestionLongMenuGraphQL;

export type ValidationsQuestionGroupGraphQL = ValidationsQuestionGroup & {
  questions: ValidationsQuestionGraphQL[];
};

export type QuestionGroupTypesGraphQL =
  | QuestionSequenceGraphQL
  | QuestionSeriesGraphQL
  | SingleQuestionGraphQL;

export function isOfQuestionType<T extends QuestionGraphQL>(
  question: QuestionGraphQL,
  type: QuestionType
): question is T {
  return question.type === type;
}

export function isOfQuestionGroupType<T extends QuestionGroupTypesGraphQL>(
  questionGroup: QuestionGroupTypesGraphQL,
  type: QuestionGroupType
): questionGroup is T {
  return questionGroup.type === type;
}
