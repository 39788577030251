angular.module('examManagement').controller('StatisticsImportCtrl', ['$scope', 'Upload', '$uibModalInstance', '$window', 'notifications', 'exam', function ($scope, Upload, $uibModalInstance, $window, notifications, exam) {
  var MAX_NUMBER_OF_NOTIFICATIONS = 5,
      validStatisticsFile;
  $scope.I18n = I18n;
  $scope.hasUploadedFile = false;
  $scope.hasValidStatistics = false;
  $scope.notifications = [];

  function interpolate(path, valueMap) {
    return path.replace(/:(\w+)/, function (match, key) {
      return valueMap[key];
    });
  }

  function isValidFileExtension(name, extensionRegExp) {
    return extensionRegExp.test(name);
  }

  function isValidFileType(file) {
    var type = file.type || '';
    return type.length === 0 ? isValidFileExtension(file.name, /\.json$/) : type === 'application/json';
  }

  function abortPendingUpload(scope) {
    if (scope.upload && $scope.upload.length && scope.upload[0] !== null) {
      $scope.upload[0].abort();
    }
    $scope.upload = [];
  }

  $scope.isFileReaderSupported = function() {
    return $window.FileReader && (!$window.FileAPI || $window.FileAPI.html5);
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss();
  };

  $scope.getDragOverClass = function ($event) {
    var file = $event.dataTransfer.items[0];
    return isValidFileType(file) ? 'drag-over' : 'drag-over-invalid';
  };

  $scope.onFileSelect = function ($files) {
    var file;

    abortPendingUpload($scope);

    if (!$files && !$files.length) {
      return;
    }

    file = $files[0];

    if (isValidFileType(file)) {
      $scope.upload = Upload.upload({ url: '/api/exams/' + exam.id + '/statistics/validate', method: 'PUT', file: file })
        .success(function (data) {
          $scope.hasUploadedFile = true;
          validStatisticsFile = file;
          $scope.hasValidStatistics = true;
          $scope.validationResult = data;
        })
        .error(function (err) {
          $scope.hasUploadedFile = true;
          $scope.notifications = [];
          for(var i = 0; i < err.notifications.length && i < MAX_NUMBER_OF_NOTIFICATIONS; i++) {
            $scope.notifications.push(err.notifications[i]);
          }
        });
    } else {
      notifications.addInfo(I18n.t('exams.statistics_import.supported_file_types'));
    }
  };

  $scope.import = function () {
    $scope.importing = true;
    $scope.upload = Upload.upload({ url: '/api/exams/' + exam.id + '/statistics', file: validStatisticsFile })
      .success(function () {
        $uibModalInstance.close();
      })
      .error(function (err) {
        $scope.importing = false;
        for(var i = 0; i < err.notifications.length && i < MAX_NUMBER_OF_NOTIFICATIONS; i++) {
          notifications.add(err.notifications[i].content, err.notifications[i].type);
        }
      });
  };
}]);
