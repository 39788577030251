<div class="modal-wrapper">
  <div class="modal-body">
    <video
      [width]="video.width"
      [height]="video.height"
      [muted]="'muted'"
      (click)="switchPlaying()"
    >
      <source [type]="video.mime" />
      Your browser does not support the video tag.
    </video>

    <div class="controls" *ngIf="player">
      <div>
        <button
          *ngIf="player.paused"
          (click)="play()"
          type="button"
          class="btn btn-primary"
        >
          <i class="fa fa-play"></i>
        </button>
        <button
          *ngIf="!player.paused"
          (click)="pause()"
          type="button"
          class="btn btn-primary"
        >
          <i class="fa fa-pause"></i>
        </button>
      </div>

      <div class="time-container">
        <span class="current">{{ currentTime }}</span> /
        <span class="total">{{ totalTime }}</span>
      </div>

      <div class="progress-container" (click)="jump($event)">
        <div class="progress-current" [style.width]="currentProgress"></div>
        <div class="progress-remaining" [style.width]="remainingProgress"></div>
      </div>

      <div>
        <button
          *ngIf="!player.muted"
          (click)="mute()"
          type="button"
          class="btn btn-primary"
        >
          <i class="fa fa-volume-off"></i>
        </button>
        <button
          *ngIf="player.muted"
          (click)="unmute()"
          type="button"
          class="btn btn-primary"
        >
          <i class="fa fa-volume-up"></i>
        </button>
      </div>

      <div>
        <button (click)="download()" type="button" class="btn btn-primary">
          <i class="fa fa-download"></i>
        </button>
      </div>

      <div>
        <button (click)="fullscreen()" type="button" class="btn btn-primary">
          <i class="fa fa-arrows-alt"></i>
        </button>
      </div>
    </div>
  </div>
</div>
