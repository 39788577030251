import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { AssignLabelModalComponent } from '../modals/assign-label-modal/assign-label-modal.component';

angular
  .module('common')
  .directive(
    'coAssignLabelModal',
    downgradeComponent({ component: AssignLabelModalComponent })
  );
