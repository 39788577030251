angular.module('common').factory('MultiSelection', [
  'Store', 'Selectors::Pool',
  function (Store, PoolSelectors) {
    var ARROW_UP = 38, ARROW_DOWN = 40;

    function MultiSelection (setSelectionAction, addToSelectionAction, removeFromSelectionAction,
      getSelection, getAllIds, getItems, persistencePath) {
      this.setSelectionAction = setSelectionAction;
      this.addToSelectionAction = addToSelectionAction;
      this.removeFromSelectionAction = removeFromSelectionAction;
      this.getSelection = getSelection;
      this.getAllIds = getAllIds;
      this.getItems = getItems;
      this.subscriptions = [];

      this.subscriptions.push(Store.subscribeOn(PoolSelectors.getCurrentPool, function (pool) {
        if (pool) {
          this.persistencePath = 'pool-' + pool.id + '.' + persistencePath;
          this._loadSelection();
        }
      }.bind(this)));
      this.subscriptions.push(Store.subscribeOn(getSelection, this._saveSelection.bind(this)));
      this.subscriptions.push(Store.subscribeOn(getSelection, this._updateSelection.bind(this)));
      this.subscriptions.push(Store.subscribeOn(getItems, this._updateSelection.bind(this)));
    }

    MultiSelection.prototype._loadSelection = function () {
      Store.dispatch(this.setSelectionAction(JSON.parse(localStorage.getItem(this.persistencePath))));
    };

    MultiSelection.prototype._saveSelection = function (selection) {
      localStorage.setItem(this.persistencePath, JSON.stringify(selection));
    };

    MultiSelection.prototype.destroy = function () {
      this.subscriptions.forEach(function (subscription) {
        subscription();
      });
    };

    MultiSelection.prototype.select = function (event, itemId) {
      if (event.metaKey || event.ctrlKey) {
        this._selectItem(itemId);
      }
      else if (event.shiftKey) {
        this._selectRange(itemId);
      }
      else {
        this._exclusivelySelectItem(itemId);
      }
    };

    MultiSelection.prototype.toggleAll = function () {
      if (this.allSelected) {
        Store.dispatch(this.setSelectionAction([]));
      }
      else {
        var existingIds = this.getItems(Store.getState()).map(function (item) {
          return item.id;
        }),
        missingIds = this.getAllIds(Store.getState()).filter(function (id) {
          return !existingIds.includes(id);
        });

        Store.dispatch(this.setSelectionAction(missingIds.concat(existingIds)));
      }
    };

    MultiSelection.prototype.toggle = function (itemId) {
      var selection = this.getSelection(Store.getState());
      if (selection.includes(itemId)) {
        this._deselectItem(itemId);
      }
      else {
        this._selectItem(itemId);
      }
    };

    MultiSelection.prototype.keyPressed = function (event) {
      var selection = this.getSelection(Store.getState()),
      items = this.getItems(Store.getState()),
      lastSelectedIndex = items.findIndex(function (item) {
        return item.id == selection[selection.length - 1];
      });

      event.preventDefault();
      if (event.keyCode == ARROW_DOWN && lastSelectedIndex > -1 && lastSelectedIndex < items.length - 1) {
        if (event.shiftKey) {
          this._selectItem(items[lastSelectedIndex + 1].id);
        }
        else {
          this._exclusivelySelectItem(items[lastSelectedIndex + 1].id);
        }
        return false;
      }
      else if (event.keyCode == ARROW_UP && lastSelectedIndex > 0) {
        if (event.shiftKey) {
          this._selectItem(items[lastSelectedIndex - 1].id);
        }
        else {
          this._exclusivelySelectItem(items[lastSelectedIndex - 1].id);
        }
        return false;
      }
    };

    MultiSelection.prototype._exclusivelySelectItem = function (itemId) {
      Store.dispatch(this.setSelectionAction([itemId]));
    };

    MultiSelection.prototype._selectItem = function (itemId) {
      Store.dispatch(this.addToSelectionAction([itemId]));
    };

    MultiSelection.prototype._deselectItem = function (itemId) {
      Store.dispatch(this.removeFromSelectionAction(itemId));
    };

    MultiSelection.prototype._selectRange = function (upToId) {
      var selection = this.getSelection(Store.getState()),
      items = this.getItems(Store.getState()),
      lastSelectedIndex = items.findIndex(function (item) {
        return item.id == selection[selection.length - 1];
      }),
      upToIndex = items.findIndex(function (item) {
        return item.id == upToId;
      }), idsToSelect = [];

      if (lastSelectedIndex == -1) {
        Store.dispatch(this.setSelectionAction([upToId]));
      }
      else if (upToIndex > lastSelectedIndex) {
        for(var i = lastSelectedIndex + 1; i <= upToIndex; i++) {
          idsToSelect.push(items[i].id);
        }
        Store.dispatch(this.addToSelectionAction(idsToSelect));
      }
      else if (upToIndex < lastSelectedIndex) {
        for(var i = lastSelectedIndex - 1; i >= upToIndex; i--) {
          idsToSelect.push(items[i].id);
        }
        Store.dispatch(this.addToSelectionAction(idsToSelect));
      }
    };

    MultiSelection.prototype._updateSelection = function () {
      var selection = this.getSelection(Store.getState()),
      items = this.getItems(Store.getState());
      this.choices = items.map(function (item) {
        return selection.includes(item.id);
      });
      this.lastChoices = items.map(function (item) {
        return selection[selection.length - 1] == item.id;
      });
      this.allSelected = (this.getAllIds(Store.getState()).length == selection.length);
    };

    return MultiSelection;
  }
]);
