import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { RevisionStatusListElementFragmentDoc } from './load-revision-status.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetPositionRevisionStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  direction: Types.MoveDirection;
  times?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SetPositionRevisionStatusMutation = { __typename?: 'Mutation', setPositionRevisionStatus?: { __typename?: 'SetPositionRevisionStatusPayload', successful: boolean, revisionStatus?: { __typename?: 'RevisionStatus', id: string, position: number, shortName: string, description: string } | null, revisionStatusList?: Array<{ __typename?: 'RevisionStatus', id: string, position: number, shortName: string, description: string }> | null } | null };

export const SetPositionRevisionStatusDocument = gql`
    mutation SetPositionRevisionStatus($id: ID!, $direction: MoveDirection!, $times: Int) {
  setPositionRevisionStatus(id: $id, direction: $direction, times: $times) {
    revisionStatus {
      ...RevisionStatusListElement
    }
    revisionStatusList {
      ...RevisionStatusListElement
    }
    successful
  }
}
    ${RevisionStatusListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetPositionRevisionStatusGQL extends Apollo.Mutation<SetPositionRevisionStatusMutation, SetPositionRevisionStatusMutationVariables> {
    document = SetPositionRevisionStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }