/* eslint-disable unicorn/numeric-separators-style */
/* This is used to define the special chars for the editor as an hex number */
export const SPECIAL_CHARS = [
  [0x391, ''],
  [0x392, ''],
  [0x393, ''],
  [0x394, ''],
  [0x395, ''],
  [0x396, ''],
  [0x397, ''],
  [0x398, ''],
  [0x399, ''],
  [0x39a, ''],
  [0x39b, ''],
  [0x39c, ''],
  [0x39d, ''],
  [0x39e, ''],
  [0x39f, ''],
  [0x3a0, ''],
  [0x3a1, ''],
  [0x3a3, ''],
  [0x3a4, ''],
  [0x3a5, ''],
  [0x3a6, ''],
  [0x3a7, ''],
  [0x3a8, ''],
  [0x3a9, ''],
  [0x3b1, ''],
  [0x3b2, ''],
  [0x3b3, ''],
  [0x3b4, ''],
  [0x3b5, ''],
  [0x3b6, ''],
  [0x3b7, ''],
  [0x3b8, ''],
  [0x3b9, ''],
  [0x3ba, ''],
  [0x3bb, ''],
  [0x3bc, ''],
  [0x3bd, ''],
  [0x3be, ''],
  [0x3bf, ''],
  [0x3c0, ''],
  [0x3c1, ''],
  [0x3c2, ''],
  [0x3c3, ''],
  [0x3c4, ''],
  [0x3c5, ''],
  [0x3c6, ''],
  [0x3c7, ''],
  [0x3c8, ''],
  [0x3c9, ''],
  [0xa9, ''],
  [0xae, ''],
  [0x2122, ''],
  [0xb1, ''],
  [0xb7, ''],
  [0x2022, ''],
  [0xd7, ''],
  [0x78, ''],
  [0x2030, ''],
  [0x2014, ''],
  [0xb0, ''],
  [0x2113, ''],
  [0x3d1, ''],
  [0x394, ''],
  [0x3d2, ''],
  [0x2190, ''],
  [0x2191, ''],
  [0x2192, ''],
  [0x2193, ''],
  [0x21c4, ''],
  [0x21c6, ''],
  [0x21cb, ''],
  [0x21cc, ''],
  [0xbd, ''],
  [0x2153, ''],
  [0x2154, ''],
  [0xbc, ''],
  [0xbe, ''],
  [0x2155, ''],
  [0x2156, ''],
  [0x2157, ''],
  [0x2158, ''],
  [0x2159, ''],
  [0x215a, ''],
  [0x215b, ''],
  [0x215c, ''],
  [0x215d, ''],
  [0x215e, ''],
  [0x2640, ''],
  [0x2642, ''],
  [0xc5, ''],
  [0x153, ''],
  [0x2200, ''],
  [0x2201, ''],
  [0x2202, ''],
  [0x2203, ''],
  [0x2204, ''],
  [0x2205, ''],
  [0x2206, ''],
  [0x2207, ''],
  [0x2208, ''],
  [0x2209, ''],
  [0x220a, ''],
  [0x220b, ''],
  [0x220c, ''],
  [0x220d, ''],
  [0x220e, ''],
  [0x220f, ''],
  [0x2210, ''],
  [0x2211, ''],
  [0x2212, ''],
  [0x2213, ''],
  [0x2214, ''],
  [0x2215, ''],
  [0x2216, ''],
  [0x2217, ''],
  [0x2218, ''],
  [0x2219, ''],
  [0x221a, ''],
  [0x221b, ''],
  [0x221c, ''],
  [0x221d, ''],
  [0x221e, ''],
  [0x221f, ''],
  [0x2220, ''],
  [0x2221, ''],
  [0x2222, ''],
  [0x2223, ''],
  [0x2224, ''],
  [0x2225, ''],
  [0x2226, ''],
  [0x2227, ''],
  [0x2228, ''],
  [0x2229, ''],
  [0x222a, ''],
  [0x222b, ''],
  [0x222c, ''],
  [0x222d, ''],
  [0x222e, ''],
  [0x222f, ''],
  [0x2230, ''],
  [0x2231, ''],
  [0x2232, ''],
  [0x2233, ''],
  [0x2234, ''],
  [0x2235, ''],
  [0x2236, ''],
  [0x2237, ''],
  [0x2238, ''],
  [0x2239, ''],
  [0x223a, ''],
  [0x223b, ''],
  [0x223c, ''],
  [0x223d, ''],
  [0x223e, ''],
  [0x223f, ''],
  [0x2240, ''],
  [0x2241, ''],
  [0x2242, ''],
  [0x2243, ''],
  [0x2244, ''],
  [0x2245, ''],
  [0x2246, ''],
  [0x2247, ''],
  [0x2248, ''],
  [0x2249, ''],
  [0x224a, ''],
  [0x224b, ''],
  [0x224c, ''],
  [0x224d, ''],
  [0x224e, ''],
  [0x224f, ''],
  [0x2250, ''],
  [0x2251, ''],
  [0x2252, ''],
  [0x2253, ''],
  [0x2254, ''],
  [0x2255, ''],
  [0x2256, ''],
  [0x2257, ''],
  [0x2258, ''],
  [0x2259, ''],
  [0x225a, ''],
  [0x225b, ''],
  [0x225c, ''],
  [0x225d, ''],
  [0x225e, ''],
  [0x225f, ''],
  [0x2260, ''],
  [0x2261, ''],
  [0x2262, ''],
  [0x2263, ''],
  [0x2264, ''],
  [0x2265, ''],
  [0x2266, ''],
  [0x2267, ''],
  [0x2268, ''],
  [0x2269, ''],
  [0x226a, ''],
  [0x226b, ''],
  [0x226c, ''],
  [0x226d, ''],
  [0x226e, ''],
  [0x226f, ''],
  [0x2270, ''],
  [0x2271, ''],
  [0x2272, ''],
  [0x2273, ''],
  [0x2274, ''],
  [0x2275, ''],
  [0x2276, ''],
  [0x2277, ''],
  [0x2278, ''],
  [0x2279, ''],
  [0x227a, ''],
  [0x227b, ''],
  [0x227c, ''],
  [0x227d, ''],
  [0x227e, ''],
  [0x227f, ''],
  [0x2280, ''],
  [0x2281, ''],
  [0x2282, ''],
  [0x2283, ''],
  [0x2284, ''],
  [0x2285, ''],
  [0x2286, ''],
  [0x2287, ''],
  [0x2288, ''],
  [0x2289, ''],
  [0x228a, ''],
  [0x228b, ''],
  [0x228c, ''],
  [0x228d, ''],
  [0x228e, ''],
  [0x228f, ''],
  [0x2290, ''],
  [0x2291, ''],
  [0x2292, ''],
  [0x2293, ''],
  [0x2294, ''],
  [0x2295, ''],
  [0x2296, ''],
  [0x2297, ''],
  [0x2298, ''],
  [0x2299, ''],
  [0x229a, ''],
  [0x229b, ''],
  [0x229c, ''],
  [0x229d, ''],
  [0x229e, ''],
  [0x229f, ''],
  [0x22a0, ''],
  [0x22a1, ''],
  [0x22a2, ''],
  [0x22a3, ''],
  [0x22a4, ''],
  [0x22a5, ''],
  [0x22a6, ''],
  [0x22a7, ''],
  [0x22a8, ''],
  [0x22a9, ''],
  [0x22aa, ''],
  [0x22ab, ''],
  [0x22ac, ''],
  [0x22ad, ''],
  [0x22ae, ''],
  [0x22af, ''],
  [0x22b0, ''],
  [0x22b1, ''],
  [0x22b2, ''],
  [0x22b3, ''],
  [0x22b4, ''],
  [0x22b5, ''],
  [0x22b6, ''],
  [0x22b7, ''],
  [0x22b8, ''],
  [0x22b9, ''],
  [0x22ba, ''],
  [0x22bb, ''],
  [0x22bc, ''],
  [0x22bd, ''],
  [0x22be, ''],
  [0x22bf, ''],
  [0x22c0, ''],
  [0x22c1, ''],
  [0x22c2, ''],
  [0x22c3, ''],
  [0x22c4, ''],
  [0x22c5, ''],
  [0x22c6, ''],
  [0x22c7, ''],
  [0x22c8, ''],
  [0x22c9, ''],
  [0x22ca, ''],
  [0x22cb, ''],
  [0x22cc, ''],
  [0x22cd, ''],
  [0x22ce, ''],
  [0x22cf, ''],
  [0x22d0, ''],
  [0x22d1, ''],
  [0x22d2, ''],
  [0x22d3, ''],
  [0x22d4, ''],
  [0x22d5, ''],
  [0x22d6, ''],
  [0x22d7, ''],
  [0x22d8, ''],
  [0x22d9, ''],
  [0x22da, ''],
  [0x22db, ''],
  [0x22dc, ''],
  [0x22dd, ''],
  [0x22de, ''],
  [0x22df, ''],
  [0x22e0, ''],
  [0x22e1, ''],
  [0x22e2, ''],
  [0x22e3, ''],
  [0x22e4, ''],
  [0x22e5, ''],
  [0x22e6, ''],
  [0x22e7, ''],
  [0x22e8, ''],
  [0x22e9, ''],
  [0x22ea, ''],
  [0x22eb, ''],
  [0x22ec, ''],
  [0x22ed, ''],
  [0x22ee, ''],
  [0x22ef, ''],
  [0x22f0, ''],
  [0x22f1, '']
];
