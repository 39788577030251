import { CreateSubjectMutationVariables } from 'src/app/admin/services/create-subject.generated';
import { ManagerError } from 'src/app/common/utils/error-parser';
import { Scalars } from 'src/generated/base-types';
import { SubjectsListElementFragment } from '../../../services/load-subjects.generated';
import { UpdateSubjectMutationVariables } from '../../../services/update-subject.generated';

export class LoadSubjects {
  public static readonly type = '[Subjects] Load Subjects';
  constructor(public readonly poolId?: Scalars['ID']) {}
}

export class LoadSubjectsSuccess {
  public static readonly type = '[Subjects] Load Subjects Success';
  constructor(public readonly subjects: SubjectsListElementFragment[]) {}
}

export class LoadSubjectsFailure {
  public static readonly type = '[Subjects] Load Subjects Failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeleteSubject {
  public static readonly type = '[Subject] Delete';
  constructor(public readonly id: string) {}
}

export class DeleteSubjectSuccess {
  public static readonly type = '[Subject] Delete Success';
  constructor(public readonly id: string) {}
}

export class DeleteSubjectFailure {
  public static readonly type = '[Subject] Delete Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdateSubject {
  public static readonly type = '[Subject] Update';
  constructor(public readonly payload: UpdateSubjectMutationVariables) {}
}

export class UpdateSubjectSuccess {
  public static readonly type = '[Subject] Update Success';
  constructor(public readonly subject: SubjectsListElementFragment) {}
}

export class UpdateSubjectFailure {
  public static readonly type = '[Subject] Update Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreateSubject {
  public static readonly type = '[Subject] Create';
  constructor(
    public readonly payload: Pick<
      CreateSubjectMutationVariables,
      'name' | 'categoryIds'
    >
  ) {}
}

export class CreateSubjectSuccess {
  public static readonly type = '[Subject] Create Success';
  constructor(public readonly subject: SubjectsListElementFragment) {}
}

export class CreateSubjectFailure {
  public static readonly type = '[Subject] Create Failure';
  constructor(public readonly error: ManagerError) {}
}
