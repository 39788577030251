angular.module('common').filter('percentage', [
  function () {
    return function (input) {
      var values = [].concat(input);
      var containsNaN = values.some(function (value) {
        return isNaN(value) || value === null;
      });

      if (containsNaN) {
        return input;
      }

      var percentageValues = values.map(function (number) {
        return (number * 100).toFixed(1);
      });

      return percentageValues.length === 1
        ? percentageValues[0]
        : percentageValues;
    };
  }
]);
