(function() {
  function Controller($http, $scope, $window, Store, UserSelectors) {
    this.I18n = I18n;

    $scope.logout = function () {
      $http.delete('/logout').then(function () {
        $window.location.href = '/login';
      });
    };

    Store.subscribeOn(UserSelectors.getCurrentUser,
      function (user) {
        if (user) {
          this.user = user
        }
      }.bind(this)
    );
  }

  Controller.$inject = ['$http', '$scope', '$window', 'Store', 'Selectors::User'];

  angular.module('common').component('userMenu', {
    template: require('./user_menu.html'),
    controller: Controller
  });
})();
