import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SetQuestionColumnVisibility } from 'src/app/state/settings.actions';
import { SettingsState } from 'src/app/state/settings.state';
import { ListColumn } from 'src/app/state/settings.state.model';

@Component({
  selector: 'qm-question-group-column-visibility',
  templateUrl: './question-group-column-visibility.component.html',
  styleUrls: ['./question-group-column-visibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionGroupColumnVisibilityComponent {
  public columns$: Observable<ListColumn[]>;

  constructor(private readonly store: Store) {
    this.columns$ = this.store
      .select(SettingsState.questionListColumns)
      .pipe(map(columns => columns.filter(column => column.enabled)));
  }

  public onToggleVisibility(column: ListColumn): void {
    this.store.dispatch(
      new SetQuestionColumnVisibility(column.name, !column.visible)
    );
  }
}
