import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  template: `
    <man-admin-header></man-admin-header>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'main' }
})
export class AdminComponent {}
