import * as Honeybadger from 'honeybadger-js';
import { environment } from '../../environments/environment';
// eslint-disable-next-line @typescript-eslint/no-var-requires, unicorn/prefer-module
const variables = require('../../environments/variables');

export function initializeHoneybadger(): void {
  // eslint-disable-next-line import/namespace
  Honeybadger.configure({
    apiKey: variables.honeybadgerApiKey,
    revision: variables.revision,
    disabled: !environment.production,
    breadcrumbsEnabled: environment.production
  });
}
