angular.module('common').factory('Repositories::Question', [
  'GraphQLClient',
  function (GraphQLClient) {
    var Repository = {},
      client = new GraphQLClient('/api/v1/graphql'),
      getPossibleTypeTransformationsQuery =
        'query ($question_id: Int!) {' +
        '  question_type_transformations(question_id: $question_id) {type necessary_operations delete_solution}' +
        '}',
      transformTypeQuery =
        'mutation ($question_id: Int!, $type: String!, $remove_response_option: Int) {' +
        '  change_question_type(question_id: $question_id, type: $type, remove_response_option: $remove_response_option) {' +
        '    question {id type}' +
        '    erroneous errors { fields {name feedbacks {content type}}}' +
        '  }' +
        '}';

    Repository.getPossibleTypeTransformations = function (question) {
      return client
        .query(getPossibleTypeTransformationsQuery, {
          question_id: parseInt(question.id)
        })
        .then(function (response) {
          return response.data.data.question_type_transformations;
        });
    };

    Repository.transformType = function (question, type, options) {
      return client
        .query(
          transformTypeQuery,
          Object.assign({}, options, {
            question_id: parseInt(question.id),
            type: type
          })
        )
        .then(function (response) {
          return response.data.data.change_question_type;
        });
    };

    return Repository;
  }
]);
