import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AssignExamsMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  examIdsToAdd: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  examIdsToRemove: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type AssignExamsMutation = { __typename?: 'Mutation', assignExams?: { __typename?: 'AssignExamsPayload', successful: boolean } | null };

export const AssignExamsDocument = gql`
    mutation AssignExams($poolId: ID!, $questionGroupIds: [ID!]!, $examIdsToAdd: [ID!]!, $examIdsToRemove: [ID!]!) {
  assignExams(
    poolId: $poolId
    questionGroupIds: $questionGroupIds
    examIdsToAdd: $examIdsToAdd
    examIdsToRemove: $examIdsToRemove
  ) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignExamsGQL extends Apollo.Mutation<AssignExamsMutation, AssignExamsMutationVariables> {
    document = AssignExamsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }