import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PoolNameAndIdFragment = { __typename?: 'Pool', id: string, name: string };

export type DuplicateQuestionDetailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DuplicateQuestionDetailsQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', pools: Array<{ __typename?: 'Pool', id: string, name: string }> } | null };

export const PoolNameAndIdFragmentDoc = gql`
    fragment PoolNameAndID on Pool {
  id
  name
}
    `;
export const DuplicateQuestionDetailsDocument = gql`
    query DuplicateQuestionDetails {
  currentUser {
    pools {
      ...PoolNameAndID
    }
  }
}
    ${PoolNameAndIdFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DuplicateQuestionDetailsGQL extends Apollo.Query<DuplicateQuestionDetailsQuery, DuplicateQuestionDetailsQueryVariables> {
    document = DuplicateQuestionDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }