angular.module('common').factory('Reducers::User', [
  'Actions::User',
  function(Actions) {
    function reducer(state, action) {
      state = state || {};

      switch (action.type) {
        case Actions.SET_LANGUAGE:
          return Object.assign({}, state, { language: action.language });

        case Actions.SET_CURRENT_USER:
          return Object.assign({}, state, { user: action.user });

        default:
          return state;
      }
    }

    return reducer;
  }
]);
