import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteOauthApplicationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteOauthApplicationMutation = { __typename?: 'Mutation', deleteOauthApplication?: { __typename?: 'DeleteOauthApplicationPayload', successful: boolean } | null };

export const DeleteOauthApplicationDocument = gql`
    mutation DeleteOauthApplication($id: ID!) {
  deleteOauthApplication(id: $id) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOauthApplicationGQL extends Apollo.Mutation<DeleteOauthApplicationMutation, DeleteOauthApplicationMutationVariables> {
    document = DeleteOauthApplicationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }