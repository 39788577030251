/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

export type ActionMenuOption = {
  label: string;
  disabled?: boolean;
  danger?: boolean;
  callback?: (...args: any[]) => void;
};

@Component({
  selector: 'man-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionMenuComponent {
  @Input() public options: ActionMenuOption[] = [];
  @Input() public data: any;

  @ViewChild('dropdown', { read: NgbDropdown }) public dropdown: NgbDropdown;

  private isMouseOver = false;

  public onOptionClick(option: ActionMenuOption): void {
    option.callback && option.callback(this.data);
  }

  public onMouseEnter(): void {
    this.isMouseOver = true;
  }

  public onMouseLeave(): void {
    this.isMouseOver = false;

    setTimeout(() => {
      if (this.isMouseOver) return;
      this.dropdown.close();
    }, 300);
  }
}
