import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadRevisionStatus } from '../../../state/pool-details/revision-status/revision-status.actions';

@Injectable({ providedIn: 'root' })
export class RevisionStatusListGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    this.store.dispatch(new LoadRevisionStatus());

    return true;
  }
}
