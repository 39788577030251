import { Directive, OnChanges, OnInit, AfterViewInit } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: '[preselectIfSingleValued]'
})
export class PreselectIfSingleValuedDirective
  implements OnChanges, OnInit, AfterViewInit
{
  constructor(private readonly ngSelectHost: NgSelectComponent) {}

  public ngOnInit(): void {
    this.preselect();
  }

  public ngOnChanges(): void {
    this.preselect();
  }

  public ngAfterViewInit(): void {
    this.preselect();
  }

  private preselect(): void {
    if (this.ngSelectHost.items?.length === 1) {
      setTimeout(() => {
        // execute on next tick
        if (this.ngSelectHost.items !== null) {
          this.ngSelectHost.select(this.ngSelectHost.items[0]);
        }
      });
    }
  }
}
