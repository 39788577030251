import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { LoadUsersFieldsFragmentDoc } from '../../admin/services/load-users.fragments.generated';
import { ValidationsCurrentUserFieldsFragmentDoc } from './current-user-validations.fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateCurrentUserMutationVariables = Types.Exact<{
  attributes: Types.CurrentUserInput;
}>;


export type UpdateCurrentUserMutation = { __typename?: 'Mutation', updateCurrentUser?: { __typename?: 'UpdateCurrentUserPayload', successful: boolean, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null, language: Types.Language, organisation?: string | null, alternativeEmail?: string | null, mobilePhone?: string | null, alternativeMobilePhone?: string | null, currentSignInAt?: string | null, superUser: boolean, useEmailForMtan: boolean } | null, validations?: { __typename?: 'ValidationsCurrentUser', firstName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, lastName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, language?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, password?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null } | null } | null };

export const UpdateCurrentUserDocument = gql`
    mutation UpdateCurrentUser($attributes: CurrentUserInput!) {
  updateCurrentUser(attributes: $attributes) {
    user {
      ...LoadUsersFields
    }
    successful
    validations {
      ...ValidationsCurrentUserFields
    }
  }
}
    ${LoadUsersFieldsFragmentDoc}
${ValidationsCurrentUserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCurrentUserGQL extends Apollo.Mutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables> {
    document = UpdateCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }