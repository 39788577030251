import { Pipe, PipeTransform } from '@angular/core';
import { isVoid } from '../utils/type-guards/voidable';

/**
 * Convert hex to rgb color
 *
 * @param hexColor The color in hex code
 * @returns The rgb values of the color
 */
@Pipe({
  name: 'rgb'
})
export class RgbPipe implements PipeTransform {
  public transform(hexColor: string): string {
    const searchHexResult = /^#([\da-f]{6}|[\da-f]{3})$/i.exec(hexColor);
    if (isVoid(searchHexResult)) return '';

    let r = 0,
      g = 0,
      b = 0;

    if (hexColor.length === 4) {
      r = Number.parseInt(hexColor[1] + hexColor[1], 16);
      g = Number.parseInt(hexColor[2] + hexColor[2], 16);
      b = Number.parseInt(hexColor[3] + hexColor[3], 16);
    } else if (hexColor.length === 7) {
      r = Number.parseInt(hexColor[1] + hexColor[2], 16);
      g = Number.parseInt(hexColor[3] + hexColor[4], 16);
      b = Number.parseInt(hexColor[5] + hexColor[6], 16);
    }

    return `${r}, ${g}, ${b}`;
  }
}
