import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteCommentMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  commentId: Types.Scalars['ID'];
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment?: { __typename?: 'DeleteCommentPayload', successful: boolean } | null };

export const DeleteCommentDocument = gql`
    mutation DeleteComment($poolId: ID!, $commentId: ID!) {
  deleteComment(poolId: $poolId, id: $commentId) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCommentGQL extends Apollo.Mutation<DeleteCommentMutation, DeleteCommentMutationVariables> {
    document = DeleteCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }