angular.module('taskManagement').config(["$stateProvider", '$locationProvider', function($stateProvider, $locationProvider) {
  $stateProvider
    .state('taskManagement', {
      url: '/task_management',
      views: {
        'main@': {
          template: require('./index.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.I18n = I18n;
            }
          ]
        },
      },
      authorize: ['Role', 'user', function(Role, user) {
        return Role.isSuperUser(user) || Role.hasRole(user, 'administrator') || Role.hasRole(user, 'coordinator');
      }]
    });
  $locationProvider.html5Mode(true);
}]);
