/* eslint-disable camelcase */
/* eslint-disable unicorn/no-null */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  forwardRef
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageVisibility } from 'src/app/state/settings.state';
import {
  Language,
  ValidationsQuestion,
  ValidationsMessage,
  QuestionInput,
  QuestionType
} from 'src/generated/base-types';
import { FormDimension } from '../form-types';
import { ValidationsQuestionGraphQL } from '../services/graphql-question-group-types';

@Component({
  selector: 'qf-question-form',
  templateUrl: './question-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionFormComponent),
      multi: true
    }
  ]
})
export class QuestionFormComponent implements ControlValueAccessor, OnDestroy {
  @Input() public languageVisibility: LanguageVisibility;
  @Input() public languages: Language[];
  @Input() public dimensions: FormDimension[];
  @Input() public sourceLanguage: Language;
  @Input() public sequentialNumber: number;

  @Input()
  public set validations(value: ValidationsQuestionGraphQL | undefined) {
    this._validations = value;

    // check if there are alerts for any category
    this.hasAlerts = [
      value?.category0,
      value?.category1,
      value?.category2,
      value?.category3,
      value?.category4,
      value?.category5,
      value?.category6,
      value?.category7
    ].some(category => category && category.length > 0);
  }

  public get validations(): ValidationsQuestionGraphQL | undefined {
    return this._validations;
  }

  public form: UntypedFormGroup;
  public question: QuestionInput;
  public hasAlerts = false;
  public isTypeTransformable = false;
  private _validations: ValidationsQuestionGraphQL | undefined;

  private question$ = new Subject<QuestionInput>();
  private destroy$ = new Subject<void>();

  constructor(builder: UntypedFormBuilder) {
    this.form = builder.group({
      id: null,
      type: null,
      score: 0,
      category0Id: '',
      category1Id: '',
      category2Id: '',
      category3Id: '',
      category4Id: '',
      category5Id: '',
      category6Id: '',
      category7Id: '',
      learningTarget: '',
      literaryReference: '',
      remarks: '',
      content: {}
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      this.onChange({
        ...changes.content,
        id: changes.id,
        type: changes.type,
        score: changes.score,
        category0Id: changes.category0Id,
        category1Id: changes.category1Id,
        category2Id: changes.category2Id,
        category3Id: changes.category3Id,
        category4Id: changes.category4Id,
        category5Id: changes.category5Id,
        category6Id: changes.category6Id,
        category7Id: changes.category7Id,
        learningTarget: changes.learningTarget,
        literaryReference: changes.literaryReference,
        remarks: changes.remarks
      });
      this.onTouch();
    });

    this.question$.pipe(takeUntil(this.destroy$)).subscribe(question => {
      this.question = question;
      this.isTypeTransformable =
        question.id !== undefined &&
        (question.type === QuestionType.A ||
          question.type === QuestionType.AMinus ||
          question.type === QuestionType.KPrime);
      this.form.setValue({
        id: question.id ?? null,
        type: question.type ?? null,
        score: question.score ?? 0,
        category0Id: question.category0Id ?? null,
        category1Id: question.category1Id ?? null,
        category2Id: question.category2Id ?? null,
        category3Id: question.category3Id ?? null,
        category4Id: question.category4Id ?? null,
        category5Id: question.category5Id ?? null,
        category6Id: question.category6Id ?? null,
        category7Id: question.category7Id ?? null,
        learningTarget: question.learningTarget ?? '',
        literaryReference: question.literaryReference ?? '',
        remarks: question.remarks ?? '',
        content: question
      });
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(question: QuestionInput): void {
    this.question$.next(question);
  }

  public registerOnChange(fn: (value: QuestionInput) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public getCategoryAlert(
    categoryId: number
  ): ValidationsMessage[] | undefined {
    if (this.validations === undefined) {
      return;
    }
    const categoryKey = `category${categoryId}` as keyof ValidationsQuestion;

    return this.validations[categoryKey];
  }

  private onChange: (value: QuestionInput) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}
