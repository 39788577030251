<div>
  <!-- Dropdown content -->
  <div [collapsable]="target" [toggleButton]="toggleButton">
    <button
      (click)="createQuestion()"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
      *ngIf="managementType === 'Question'"
    >
      {{ 'question_management.toolbar.new' | translate }}
    </button>
    <button
      (click)="deleteQuestion()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.delete' | translate }}
    </button>
    <button
      (click)="openDuplicateModal()"
      [disabled]="!hasExactlyOneSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
      *ngIf="managementType === 'Question'"
    >
      {{ 'question_management.toolbar.duplicate' | translate }}
    </button>
    <button
      (click)="openExamsModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.for_exam' | translate }}
    </button>
    <button
      (click)="openCommentModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.comment' | translate }}
    </button>
    <button
      (click)="openLabelModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.label' | translate }}
    </button>
    <button
      (click)="openRevisionStatusModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.revision_status' | translate }}
    </button>
    <button
      (click)="openSupervisorModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.supervisor' | translate }}
    </button>
    <button
      (click)="openAffiliationModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.affiliation' | translate }}
    </button>
    <button
      (click)="openRevisionYearModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.revision_year' | translate }}
    </button>
    <button
      (click)="openSourceLanguageModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.source_language' | translate }}
    </button>
    <button
      (click)="openBlueprintModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.blueprint' | translate }}
    </button>
    <button
      (click)="openRevisionDocumentModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.revision_document' | translate }}
    </button>
    <button
      (click)="openExportModal()"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.create_export' | translate }}
    </button>
    <button
      (click)="openDeleteStatisticsModal()"
      [disabled]="!hasSelection"
      class="btn btn-primary btn-sm"
      ngbDropdownItem
    >
      {{ 'question_management.toolbar.delete_statistics' | translate }}
    </button>
  </div>
  <!-- Dropdown control -->
  <div ngbDropdown #toggleButton>
    <button class="btn btn-primary btn-sm toggle" ngbDropdownToggle>
      <i class="fa fa-ellipsis-v"></i>
    </button>
    <div ngbDropdownMenu>
      <div #target></div>
    </div>
  </div>
</div>
