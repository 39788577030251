import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FormOptionsQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type FormOptionsQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', languages: Array<Types.Language>, authors: Array<string>, supervisors: Array<string>, affiliations: Array<{ __typename?: 'Affiliation', id: string, name: string, position: number }>, dimensions: Array<{ __typename?: 'Dimension', id: string, name: string, truncated: boolean, position: number, categories: Array<{ __typename?: 'Category', id: string, name: string }> }> } };

export const FormOptionsDocument = gql`
    query FormOptions($poolId: ID!) {
  pool(id: $poolId) {
    languages
    affiliations {
      id
      name
      position
    }
    dimensions {
      id
      name
      truncated
      position
      categories {
        id
        name
      }
    }
    authors
    supervisors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FormOptionsGQL extends Apollo.Query<FormOptionsQuery, FormOptionsQueryVariables> {
    document = FormOptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }