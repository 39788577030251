angular.module('settings').factory('Reducers::OauthApplicationList', [
  'Actions::OauthApplicationList',
  function(Actions) {
    function byName(a, b) {
      return ('' + a.name).localeCompare(b.name);
    }

    function reducer(state, action) {
      state = state || {};

      switch (action.type) {
        case Actions.FETCH_OAUTH_APPLICATIONS_REQUEST:
          return Object.assign({}, state, { requestInProgress: true });

        case Actions.FETCH_OAUTH_APPLICATIONS_SUCCESS:
          return Object.assign({}, state, {
            items: action.applications,
            requestInProgress: false
          });

        case Actions.SET_OAUTH_APPLICATION_SELECTION:
          return Object.assign({}, state, {
            selectedUid: action.selectedUid
          });

        case Actions.ADD_OAUTH_APPLICATION:
          var items = (state.items || []).concat([action.application]);
          items.sort(byName);
          return Object.assign({}, state, {
            items: items
          });

        case Actions.UPDATE_OAUTH_APPLICATION:
          var itemIndex = (state.items || []).findIndex(function (item) {
            return item.uid === action.application.uid;
          }),
          items = (state.items || []).concat([]);
          if (itemIndex > -1) {
            items[itemIndex] = action.application;
            items.sort(byName);
          }
          return Object.assign({}, state, {
            items: items
          });

        case Actions.DELETE_OAUTH_APPLICATION:
          return Object.assign({}, state, {
            items: state.items.filter(function (item) {
              return item.uid !== action.uid;
            })
          });

        default:
          return state;
      }
    }

    return reducer;
  }
]);
