(function () {
  angular.module('questionForm').component('editQuestionGroupWrapper', {
    controller: Controller,
    template: `
<div class="layout--horizontal layout--adapt-to-content-width layout-cell--resizable layout-cell--small-spaced" style="height: 100%">
  <qf-edit-question-group
    [new-question-type]="$ctrl.newQuestionType"
    [new-question-group-type]="$ctrl.newQuestionGroupType"
    [navigation-ids]="$ctrl.navigationIds"
    [question-group-id]="$ctrl.questionGroupId"
    [duplicate-question-group-id]="$ctrl.duplicateQuestionGroupId" class="layout--vertical layout-cell--resizable skin-container skin-container--brand-primary" panel="center">
  </qf-edit-question-group>
  <div class="panel-separator" resize-panel=".js-panel--right">
    <i class="fa fa-ellipsis-v fa-lg"></i>
  </div>
  <aside ng-if="!$ctrl.newQuestionGroupType" class="side-panel sheet sheet--default js-panel--right" panel="right">
    <div class="sheet-heading">
      <i class="fa fa-chevron-circle-right fa-lg is-clickable pull-left" toggle-panel="right"></i>
      <div class="pull-right">{{::I18n.t('common.question_group_details.title')}}</div>
    </div>
    <div class="sheet-body js-details">
      <qm-question-group-details [question-group-id]="$ctrl.questionGroupId" class="bs4"></qm-question-group-details>
    </div>
  </aside>
</div>
      `,
    bindings: {
      questionGroupId: '<',
      duplicateQuestionGroupId: '<',
      navigationIds: '<',
      newQuestionType: '<',
      newQuestionGroupType: '<'
    }
  });

  function Controller($element, $uibModal) {
    this.modalInstance = $uibModal;

    this.$onInit = function () {
      // this solves a scrolling issue
      $element.css('height', '100%');
    };
  }

  Controller.$inject = ['$element', '$uibModal'];
})();
