import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CsvGeneratorService } from '../../common/services/csv-export-generator.service';
import {
  ExportColumnDefinition,
  commonColumnDefinitions
} from '../../common/services/export-column-definition';
import { FileSaverService } from '../../common/services/file-saver.service';
import { XlsxExportGeneratorService } from '../../common/services/xlsx-export-generator.service';
import { ExportQuestionGroupFragment } from '../components/question-group-export-modal/question-group-export.fragment.generated';

@Injectable({
  providedIn: 'root'
})
export class QuestionGroupExporterService {
  constructor(
    private translate: TranslateService,
    private fileSaver: FileSaverService
  ) {}

  public saveAsCsv(questionGroups: ExportQuestionGroupFragment[]): void {
    const generator = new CsvGeneratorService<ExportQuestionGroupFragment>(
      this.getColumnDefinitions()
    );
    generator.addObjects(questionGroups);
    this.fileSaver.saveStringAs(
      generator.toString(),
      'question-groups.csv',
      'text/csv'
    );
  }

  public saveAsXlsx(questionGroups: ExportQuestionGroupFragment[]): void {
    const generator =
      new XlsxExportGeneratorService<ExportQuestionGroupFragment>(
        this.getColumnDefinitions()
      );
    generator.addObjects(questionGroups);
    this.fileSaver.saveStringAs(
      generator.toString(),
      'question-groups.xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  }

  private getColumnDefinitions(): ExportColumnDefinition<ExportQuestionGroupFragment>[] {
    return commonColumnDefinitions(
      this.translate,
      (o: ExportQuestionGroupFragment) => o
    );
  }
}
