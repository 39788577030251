<ng-container *ngIf="visitableIds && visitableIds.length > 1">
  <button
    (click)="previousToggled.emit()"
    class="btn btn-primary layout-cell layout-cell--center"
    [disabled]="firstPosition"
    type="button"
  >
    <i class="fa fa-chevron-left"></i>
  </button>
  <div class="layout--vertical layout-cell--resizable">
    <div class="layout-cell position">
      <b>{{ currentPosition }}</b>
    </div>
    <div class="layout-cell total">
      {{ visitableIds.length }}
    </div>
  </div>
  <button
    (click)="nextToggled.emit()"
    class="btn btn-primary layout-cell layout-cell--center layout-cell--right-spaced"
    [disabled]="lastPosition"
    type="button"
  >
    <i class="fa fa-chevron-right"></i>
  </button>
</ng-container>
