angular.module('common').service('ScrollToSelectionFunctionality', [
  'Store',
  'VisibilityHelper',
  function(Store, VisibilityHelper) {
    function ScrollToSelectionFunctionality(element, lastSelectedIdSelector, itemsSelector) {
      this.scrollToElement = function () {
        var rowElement = element.find('tr[item-id="' + this.question_group_id + '"]');
        VisibilityHelper.scrollIntoView(rowElement);
      }.bind(this);

      function observerCallback() {
        var count = element.find('tbody tr').length;
        if(this.itemsCount > 0 && count >= this.itemsCount) {
          this.scrollToElement();
          this.observer.disconnect();
        }
      }

      this.lazyScroll = function() {
        this.observer = new MutationObserver(observerCallback.bind(this));

        this.observer.observe(element[0], {
          childList: true,
          subtree: true
        });

        observerCallback.bind(this)();
      }.bind(this);

      this.lastSelectedIdSubscription = Store.subscribeOn(
        lastSelectedIdSelector,
        function(question_group_id) {
          this.question_group_id = question_group_id;
        }.bind(this)
      );

      this.itemsSubscription = Store.subscribeOn(itemsSelector, function(items) {
        this.itemsCount = items.length;
      }.bind(this));
    }

    ScrollToSelectionFunctionality.prototype.destroy = function() {
      this.lastSelectedIdSubscription();
      this.itemsSubscription();
      if(this.observer) {
        this.observer.disconnect();
      }
    };

    return ScrollToSelectionFunctionality;
  }
]);
