/* eslint-disable unicorn/no-null */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const requiredWhenOthersEmptyValidator = (
  ...otherFieldNames: string[]
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (control.value) return null;

    const parent = control.parent;
    if (!parent) return null;

    const targetControls = otherFieldNames.map(fieldName =>
      parent.get(fieldName)
    );

    if (!targetControls.some(targetControl => Boolean(targetControl?.value)))
      return { requiredTrue: true };

    return null;
  };
};
