import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { EditQuestionGroupComponent } from '../edit-question-group/edit-question-group.component';

angular.module('questionForm').directive(
  'qfEditQuestionGroup',
  downgradeComponent({
    component: EditQuestionGroupComponent,
    inputs: [
      'questionGroupId',
      'newQuestionGroupType',
      'duplicateQuestionGroupId',
      'navigationIds'
    ]
  })
);
