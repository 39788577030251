import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { PoolPermissionsFragmentDoc } from './load-pool-permissions.fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoadUserPermissionsQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type LoadUserPermissionsQuery = { __typename?: 'Query', user: { __typename?: 'User', permissions: Array<{ __typename?: 'Permission', id: string, role: Types.PermissionRole, user: { __typename?: 'User', id: string, email?: string | null, firstName: string, lastName: string }, subject?: { __typename?: 'Subject', id: string, name: string } | null, pool: { __typename?: 'Pool', id: string, name: string } }> } };

export const LoadUserPermissionsDocument = gql`
    query LoadUserPermissions($userId: ID!) {
  user(id: $userId) {
    permissions {
      ...PoolPermissions
    }
  }
}
    ${PoolPermissionsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadUserPermissionsGQL extends Apollo.Query<LoadUserPermissionsQuery, LoadUserPermissionsQueryVariables> {
    document = LoadUserPermissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }