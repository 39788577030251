angular.module('examManagement').factory('Repositories::MasterAccessDocument', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      get =
        'query($id: Int!, $valid_from: String!, $valid_to: String!, $max_logins: Int!) {' +
        '  exam(id: $id) { id' +
        '    master_access_document(valid_from: $valid_from, valid_to: $valid_to, max_logins: $max_logins) { ' +
        '      ... on DownloadUrl { url }' +
        '      ... on GenericErrors {erroneous fields {name feedbacks {content type}}}' +
        '    } ' +
        '  } ' +
        '}';

    return {
      get: function(examId, validFrom, validTo, maxLogins) {
        return client
          .query(get, {
            id: examId,
            valid_from: validFrom?.toString(),
            valid_to: validTo?.toString(),
            max_logins: maxLogins
          })
          .then(function(response) {
            return response.data.data.exam.master_access_document;
          });
      }
    };
  }
]);
