(function() {
  function Controller($window, Repository, Store, Actions, Selectors) {
    this.I18n = I18n;

    this.reload = function() {
      $window.location.reload();
    };

    document.addEventListener(
      'visibilitychange',
      function() {
        if (Selectors.getCurrentPool(Store.getState()) !== undefined) {
          Repository.getCurrent().then(
            function(pool) {
              this.currentPoolChanged = pool.id !== Selectors.getCurrentPool(Store.getState()).id;
            }.bind(this)
          );
        }
      }.bind(this)
    );
  }

  Controller.$inject = [
    '$window',
    'Repositories::Pool',
    'Store',
    'Actions::Pool',
    'Selectors::Pool'
  ];

  angular.module('common').component('apiAccessCheck', {
    template: require('./api_access_check.html'),
    controller: Controller
  });
})();
