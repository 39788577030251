angular.module('common').factory('baseInlineErrorFunctionality', function () {
  return function (fieldSelector, $scope, $element, $attrs, errorHandler) {
    $element.addClass('inline-error__tooltip-container');
    $scope.hasFocus = false;
    $scope.hasMouse = false;

    $element.on('mouseenter', fieldSelector, function () {
      $scope.hasMouse = true;
    });
    $element.on('mouseleave', fieldSelector, function () {
      $scope.hasMouse = false;
    });

    $element.on('focusin', fieldSelector, function () {
      $scope.hasFocus = true;
    });
    $element.on('focusout', fieldSelector, function () {
      $scope.hasFocus = false;
    });

    $scope.$watch($attrs.error, function (value) {
      if (value && value[0]) {
        errorHandler.setErrorMessages(value);
        errorHandler.displayErrorMessage();
      } else {
        errorHandler.removeErrorMessage();
      }
    });

    $scope.$watch($attrs.removeErrorsOnChange, function (newValue, oldValue) {
      if (oldValue !== newValue && angular.isDefined(oldValue)) {
        errorHandler.removeErrorMessage();
        errorHandler.setErrorMessages([]);
      }
    }, true);
  };
});
