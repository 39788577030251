angular.module('examManagement').config(["$stateProvider", '$locationProvider', function($stateProvider, $locationProvider) {
  $stateProvider
    .state('examManagement', {
      url: '/exam_management',
      views: {
        'main@': {
          template: require('./index.html'),
          controller: [
            '$scope',
            function ($scope) {
              $scope.I18n = I18n;
            }
          ]
        },
      },
      authorize: ['Role', 'user', function(Role, user) {
        return Role.isSuperUser(user) || Role.hasRole(user, 'administrator');
      }]
    });
  $locationProvider.html5Mode(true);
}]);
