(function () {
  function Controller ($scope, ExamSelectors, Store) {
    var subscription;

    this.I18n = I18n;
    this.visible = false;

    subscription = Store.subscribeOn(ExamSelectors.getSelectedItem, function (exam) {
      if(exam === undefined || exam.examinator_unlock_token === null || !exam.locked) {
        this.visible = false;
      }
      else {
        this.token = exam.examinator_unlock_token;
        this.visible = true;
      }
    }.bind(this));

    $scope.$on('$destroy', function () {
      subscription();
    });
  }

  Controller.$inject = [
    '$scope', 'Selectors::ExamList', 'Store'
  ];

  angular.module('examManagement').component('examinatorUnlockToken', {
    template: '<span ng-show="$ctrl.visible">{{::$ctrl.I18n.t("exams.toolbar.examinator_unlock_token")}}: <b>{{$ctrl.token}}</b></span>',
    controller: Controller
  });
})();
