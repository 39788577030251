angular.module('common').factory("Reducers::QuestionGroupDetails", [
  "Actions::QuestionGroupDetails",
  function(Actions) {
    return function(state, action) {
      state = state || {};
      switch (action.type) {
        case Actions.LOAD_QUESTION_GROUP_REQUEST:
          return Object.assign({}, state, { requestInProgress: true });

        case Actions.LOAD_QUESTION_GROUP_SUCCESS:
          return Object.assign({}, state, {
            questionGroup: action.questionGroup,
            requestInProgress: false
          });

        case Actions.SET_PREVIEWS:
          return Object.assign({}, state, {
            questionGroup: Object.assign({}, state.questionGroup, {
              previews: action.previews
            })
          });

        default:
          return state;
      }
    };
  }
]);
