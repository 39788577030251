import { Language } from 'src/generated/base-types';
import { SettingsStateModel } from './settings.state';
import { ColumnKeys } from './settings.state.model';

export class UpdateLanguageVisibility {
  public static readonly type = '[Settings] Update Language Visibility';

  constructor(
    public readonly language: Language,
    public readonly visible: boolean
  ) {}
}

export class UpdatePreviewsVisibleLanguages {
  public static readonly type = '[Settings] Update Preview Visible Language';

  constructor(public readonly languages: Language[]) {}
}

export class UpdateCommentFilter {
  public static readonly type = '[Settings] Update Comment Filter';

  constructor(
    public readonly kind: keyof SettingsStateModel['comment'],
    public readonly active: boolean
  ) {}
}

export class SetQuestionColumnConfig {
  public static readonly type = '[Settings] Set Question Column Config';
}
export class SetQuestionColumnVisibility {
  public static readonly type = '[Settings] Set Question Column Visibility';

  constructor(
    public readonly column: ColumnKeys,
    public readonly visible: boolean
  ) {}
}

export class SetTaskQuestionColumnConfig {
  public static readonly type = '[Settings] Set Task Question Column Config';
}
export class SetTaskColumnConfig {
  public static readonly type = '[Settings] Set Task Column Config';
}
export class SetExamColumnConfig {
  public static readonly type = '[Settings] Set Exam Column Config';
}
