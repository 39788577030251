angular.module('taskManagement').factory('Selectors::TaskList', function () {
  function getColumnDefinitions (object) {
    return object && object.taskList && object.taskList.columns;
  }

  function transformToArray (columns) {
    return Object.keys(columns).reduce(function (acc, columnName) {
      acc.push(Object.assign({name: columnName}, columns[columnName]));
      return acc;
    }, []);
  }

  function extractColumnVisibilities (columns) {
    return Object.keys(columns).reduce(function (acc, column) {
      acc[column] = columns[column] && columns[column].visible;
      return acc;
    }, {});
  }

  function getSelection (object) {
    return (object && object.taskList &&
      object.taskList.selection) || [];
  }

  function getSelectionCount (selection) {
    return selection.length;
  }

  function extractAllIds (items) {
    return items.map(function (item) {
      return item.id;
    });
  }

  function getItems (object) {
    return (object && object.taskList && object.taskList.items) || [];
  }

  function extractSelectedItems(selection, items) {
    return selection.map(function(id) {
      return items.find(function(item) {
        return item.id == id;
      });
    });
  }

  function isRequestInProgress (object) {
    return (object && object.taskList && object.taskList.requestInProgress) || false;
  }

  function getOrder (object) {
    return (object && object.taskList && object.taskList.order) || [];
  }

  function parametrizeOrder (order) {
    return order.map(function (spec) {
      return spec.name + '.' + (spec.ascending ? 'asc' : 'desc');
    });
  }

  return {
    getColumnDefinitions: getColumnDefinitions,
    getColumnDefinitionsAsArray: Reselect.createSelector(getColumnDefinitions, transformToArray),
    getColumnVisibilities: Reselect.createSelector(getColumnDefinitions, extractColumnVisibilities),
    getSelection: getSelection,
    getSelectionCount: Reselect.createSelector(getSelection, getSelectionCount),
    getSelectedItems: Reselect.createSelector(getSelection, getItems, extractSelectedItems),
    getAllIds: Reselect.createSelector(getItems, extractAllIds),
    getItems: getItems,
    isRequestInProgress: isRequestInProgress,
    getOrder: getOrder,
    getOrderAsParameter: Reselect.createSelector(getOrder, parametrizeOrder)
  };
});
