<div class="page">
  <div class="page-content">
    <man-field
      labelFor="data"
      [label]="'pool.exams.upload_statistics.form.label.file' | translate"
      class="g-col-4"
    >
      <div class="mb-3">
        <input
          class="form-control"
          type="file"
          id="data"
          (change)="onChange($event)"
          accept=".xls,.xlsx"
          #dataInput
        />
      </div>
      <div class="form-text" [attr.id]="'data'">
        <a role="button" (click)="exampleXlsxDownload()">{{
          'pool.exams.upload_statistics.form.hint.example_file' | translate
        }}</a>
      </div>
    </man-field>
    <ng-container *ngIf="examErrors$ | async as errors">
      <div *ngIf="errors.length" class="alert alert-danger">
        <ul>
          <li *ngFor="let error of errors">
            {{
              'common.form.errors.' + error.code
                | translate : { value: error.value }
            }}
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-container *ngIf="validatedStatistics$ | async as statistics">
      <p-table
        *ngIf="statistics.length > 0"
        name="Statistics"
        sortField="position"
        [value]="statistics | copyArray"
        [scrollable]="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of columns">
              <span
                [ngbTooltip]="col.tooltip || '' | translate"
                placement="bottom"
              >
                {{ col.header | translate }}
              </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-statistic>
          <tr class="p-row-fixed">
            <td
              *ngFor="let col of columns"
              [ngClass]="{
                'warning-cell': statistic[col.key]?.error
              }"
            >
              <span [ngbTooltip]="getTooltip(statistic, col.key)">
                {{ statistic[col.key]?.value }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="4" class="text-center">
              {{
                'pool.exams.upload_statistics.table.messages.empty' | translate
              }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>
  </div>
  <div class="page-footer">
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="disabled$ | async"
      (click)="onSave()"
    >
      <ng-container *ngIf="!(saving$ | async); else loader">
        {{ 'common.actions.save' | translate }}
      </ng-container>
    </button>
  </div>
</div>

<ng-template #loader>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.actions.loading' | translate }}
</ng-template>
