import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type QuestionTypeTransformationsQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionId: Types.Scalars['ID'];
}>;


export type QuestionTypeTransformationsQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', questionTypeTransformations?: Array<{ __typename?: 'QuestionTypeTransformation', type: string, necessaryOperations: Array<string>, deleteSolution: boolean }> | null } };

export const QuestionTypeTransformationsDocument = gql`
    query QuestionTypeTransformations($poolId: ID!, $questionId: ID!) {
  pool(id: $poolId) {
    questionTypeTransformations(questionId: $questionId) {
      type
      necessaryOperations
      deleteSolution
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QuestionTypeTransformationsGQL extends Apollo.Query<QuestionTypeTransformationsQuery, QuestionTypeTransformationsQueryVariables> {
    document = QuestionTypeTransformationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }