<h5>
  {{
    'common.question_group_details.statistics.type_free_text_statistics.title'
      | translate
  }}
</h5>
<b *ngIf="statistics.achievedPoints.length === 0">
  {{
    'common.question_group_details.statistics.type_free_text_statistics.no_statistics'
      | translate
  }}
</b>
<table class="table" *ngIf="statistics.achievedPoints.length > 0">
  <thead>
    <tr>
      <th>
        {{
          'common.question_group_details.statistics.type_free_text_statistics.points'
            | translate
        }}
      </th>
      <th>
        {{ 'common.question_group_details.statistics.table.p' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let stats of statistics.achievedPoints">
      <td>{{ stats.points | fixedNumber : 1 }}</td>
      <td>{{ stats.p | percentage | fixedNumber : 1 }}</td>
    </tr>
  </tbody>
</table>
