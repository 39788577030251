<input
  #search
  type="text"
  class="search-input form-control"
  [class.shortcut-active]="shortcutActive"
  aria-label="Search"
  [disabled]="disabled"
  [value]="searchTerm"
  [placeholder]="placeholder || '' | translate"
  (input)="onSearch(search.value)"
  (keydown.esc)="onClear()"
/>

<div class="search-icon">
  <i class="fa-regular fa-magnifying-glass"></i>
</div>

<button *ngIf="searchTerm" class="search-clear" (click)="onClear()">
  <i class="fa-regular fa-xmark"></i>
</button>

<div *ngIf="shortcutActive && !searchTerm" class="search-shortcut-hint">
  <div
    [innerHTML]="
      'admin.common.search_shortcut' | translate : { shortcutKey: shortcutKey }
    "
  ></div>
</div>
