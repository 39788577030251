import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { QuestionGroupType, QuestionType } from 'src/generated/base-types';

@Component({
  selector: 'co-choose-question-group-type-modal',
  templateUrl: './choose-question-group-type-modal.component.html',
  styleUrls: ['./choose-question-group-type-modal.component.scss']
})
export class ChooseQuestionGroupTypeModalComponent {
  @Input()
  public modalInstance: NgbModalRef;

  @Input()
  public hideQuestionGroupTypes: boolean;

  public questionType: QuestionType = QuestionType.A;
  public questionGroupType: QuestionGroupType = QuestionGroupType.Single;
  public readonly allQuestionTypes = [
    QuestionType.A,
    QuestionType.AMinus,
    QuestionType.E,
    QuestionType.KPrime,
    QuestionType.FreeText,
    QuestionType.LongMenu
  ];

  public readonly allQuestionGroupTypes = [
    QuestionGroupType.Single,
    QuestionGroupType.Series,
    QuestionGroupType.Sequence
  ];

  public cancel(): void {
    this.modalInstance.dismiss();
  }

  public close(): void {
    this.modalInstance.close({
      questionType: this.questionType,
      questionGroupType: this.questionGroupType
    });
  }
}
