(function () {
  angular.module('common').component('connectionStatus', {
    template: require('./connection_status.html'),
    controller: ConnectionStatusController
  });

  ConnectionStatusController.$inject = ['$scope',
                                        'Store',
                                        'Selectors::ConnectionStatus'];

  function ConnectionStatusController($scope, Store, Selectors) {
    this.I18n = I18n;
    this.lostConnection = false;

    var connectionSubscription = Store.subscribeOn(
      Selectors.getStatus, function(status) {
        if (angular.isDefined(status)) {
          this.lostConnection = !status;
        }
      }.bind(this)
    );

    $scope.$on('$destroy', function () {
      connectionSubscription();
    }.bind(this));
  }
})();
