angular.module('common').factory('resourceFactory', ['$q', '$http', 'notifications', function ($q, $http, notifications) {
  var MAX_NUMBER_OF_NOTIFICATIONS = 5;

  function UrlFactory(baseUrl) {
    this.baseUrl = baseUrl;
  }

  UrlFactory.prototype.getIndexUrl = function(params) {
    return this._replaceParams(this.baseUrl, params);
  };

  UrlFactory.prototype.getCustomActionUrl = function(action, params) {
    var urlScheme = this.baseUrl + '/' + action;
    return this._replaceParams(urlScheme, params);
  };

  UrlFactory.prototype.getObjectUrl = function(params) {
    var urlScheme = this.baseUrl + '/:id';
    return this._replaceParams(urlScheme, params) ;
  };

  UrlFactory.prototype.getCustomObjectActionUrl = function(action, params) {
    var urlScheme = this.baseUrl + '/:id/' + action;
    return this._replaceParams(urlScheme, params) ;
  };

  UrlFactory.prototype._replaceParams = function(url, params) {
    for(var key in params) {
      if (params.hasOwnProperty(key) && url.indexOf(':' + key) > -1) {
        url = url.replace(':' + key, params[key]);
        delete params[key];
      }
    }
    return url;
  };

  function showNotifications(data) {
    if (data && data.notifications) {
      for(var i = 0; i < data.notifications.length && i < MAX_NUMBER_OF_NOTIFICATIONS; i++) {
        notifications.add(data.notifications[i].content, data.notifications[i].type);
      }
    }
  }

  function makeRequest (method, url, requestOptions, options, transformator) {
    var defer = $q.defer();
    requestOptions = requestOptions || {};
    options = options || {};
    $http(angular.extend({method: method, url: url}, requestOptions))
      .success(function(data) {
        defer.resolve(transformator(data));
      })
      .error(function (data, status) {
        if (!options.ignoreNotifications) {
          showNotifications(data);
        }
        if (status === 401) {
          setTimeout(function () {
            window.location.replace("/login");
          }, 1000);
        }
        else if (status == -1) {
          data = {};
          data.notifications = [{
            content: I18n.t('question_groups.form.save.disconneted'),
            type: 'alert'
          }];
        }
        defer.reject(data);
      });
    return defer.promise;
  }

  var transformators = {
    nothing: function (data) {
      return data;
    },

    makeResourceFromArray: function (rawObjects) {
      var objects = [], Resource = this;
      angular.forEach(rawObjects, function(rawObject) {
        objects.push(new Resource(rawObject));
      });
      return objects;
    },

    makeResourceFromObject: function (data) {
      var Resource = this;
      return new Resource(data);
    },

    assignAttributes: function (data) {
      this.setAttributes(data);
      return data;
    },

    removeId: function () {
      delete this.id;
    }
  },
      defaultResourceOptions = {
        attributeTransformations: {
        },
        query: {
          transform: transformators.makeResourceFromArray
        },
        get: {
          transform: transformators.makeResourceFromObject
        },
        reload: {
          transform: transformators.assignAttributes
        },
        create: {
          transform: transformators.assignAttributes
        },
        update: {
          transform: transformators.assignAttributes
        },
        delete: {
          transform: transformators.removeId
        }
      };

  function factory (baseUrl, options) {
    var resourceOptions = {};

    // extend the options recursively with the default options
    angular.extend(resourceOptions, defaultResourceOptions);
    for(var key in options) {
      if (options.hasOwnProperty(key) && !angular.isFunction(options[key])) {
        resourceOptions[key] = {};
        angular.extend(resourceOptions[key], options[key]);
      }
    }

    function Resource (attributes) {
      this._progressMap = {};
      this.setAttributes(attributes);
      this.init();
    }

    Resource.options = resourceOptions;
    Resource.urlFactory = new UrlFactory(baseUrl);
    Resource.makeRequest = makeRequest;

    Resource.query = function (params, options) {
      var url, resultTransformator = resourceOptions.query.transform;
      params = angular.copy(params);
      url = this.urlFactory.getIndexUrl(params);
      return Resource.makeRequest('GET', url, {params: params}, options, resultTransformator.bind(this));
    };

    Resource.get = function (id, params, options) {
      var url, resultTransformator = resourceOptions.get.transform,
          params = angular.copy(params);
      params.id = id;
      url = this.urlFactory.getObjectUrl(params);
      return Resource.makeRequest('GET', url, {params: params}, options, resultTransformator.bind(this));
    };

    Resource.action = function (action, method, params, options) {
      var requestOptions = {}, url, params = angular.copy(params),
          resultTransformator = transformators.nothing;
      method = method || 'GET';
      if (method === 'GET') {
        requestOptions.params = params;
      }
      else {
        requestOptions.data = params;
      }
      url = this.urlFactory.getCustomActionUrl(action, params);
      return Resource.makeRequest(method, url, requestOptions, options, resultTransformator.bind(this));
    };

    Resource.prototype.setAttributes = function (attributes) {
      for(var key in attributes) {
        if (attributes.hasOwnProperty(key)) {
          this.setAttribute(key, attributes[key]);
        }
      }
    };

    Resource.prototype.setAttribute = function (key, value) {
      this[key] = this.transformFromInput(key, value);
    };

    Resource.prototype.transformFromInput = function (key, value) {
      if (Resource.options.attributeTransformations[key] && Resource.options.attributeTransformations[key].in) {
        return Resource.options.attributeTransformations[key].in(value);
      }
      else {
        return value;
      }
    };

    Resource.prototype.transformToOutput = function (key, value) {
      if (Resource.options.attributeTransformations[key] && Resource.options.attributeTransformations[key].out) {
        return Resource.options.attributeTransformations[key].out(value);
      }
      else {
        return value;
      }
    };

    Resource.prototype.init = function () {};

    Resource.prototype.reload = function (options) {
      var resultTransformator = resourceOptions.reload.transform,
          url = Resource.urlFactory.getObjectUrl(this._getRequestParams());
      return this._wrapInProgress('reload', function () {
        return Resource.makeRequest('GET', url, {}, options, resultTransformator.bind(this));
      });
    };

    Resource.prototype.create = function (options) {
      var resultTransformator = resourceOptions.create.transform,
          params = this._getRequestParams(),
          url = Resource.urlFactory.getIndexUrl(params);
      return this._wrapInProgress('create', function () {
        return Resource.makeRequest('POST', url, {data: params}, options, resultTransformator.bind(this));
      });
    };

    Resource.prototype.update = function (options) {
      var resultTransformator = resourceOptions.update.transform,
          params = this._getRequestParams(),
          url = Resource.urlFactory.getObjectUrl(params);
      return this._wrapInProgress('update', function () {
        return Resource.makeRequest('PUT', url, {data: params}, options, resultTransformator.bind(this));
      });
    };

    Resource.prototype.delete = function (options) {
      var resultTransformator = resourceOptions.delete.transform,
          params = this._getRequestParams(),
          url = Resource.urlFactory.getObjectUrl(params);
      return this._wrapInProgress('delete', function () {
        return Resource.makeRequest('DELETE', url, {}, options, resultTransformator.bind(this));
      });
    };

    Resource.prototype.action = function (name, method, options) {
      var params = this._getRequestParams(),
          resultTransformator = transformators.nothing,
          url = Resource.urlFactory.getCustomObjectActionUrl(name, params),
          requestOptions = {};
      method = method || 'GET';
      if (method !== 'GET') {
        requestOptions = {data: params};
      }
      return this._wrapInProgress(name, function () {
        return Resource.makeRequest(method, url, requestOptions, options, resultTransformator);
      });
    };

    Resource.prototype._getRequestParams = function () {
      var result = {};
      for(var key in this) {
        if (this.hasOwnProperty(key) && key.match(/^[a-zA-Z\d]/)) {
          result[key] = this.transformToOutput(key, this[key]);
        }
      }
      return result;
    };

    Resource.prototype.isInProgress = function (name) {
      return this._progressMap[name];
    };

    Resource.prototype._setInProgress = function (name) {
      this._progressMap[name] = true;
    };

    Resource.prototype._finishProgress = function (name) {
      this._progressMap[name] = false;
    };

    Resource.prototype._wrapInProgress = function (name, callback) {
      var that = this;
      this._setInProgress(name);
      return callback.bind(this)()
        .finally(function () {
          that._finishProgress(name);
        });
    };

    // support the old API where prototype functions have been placed in the
    // second argument
    for(key in options) {
      if (options.hasOwnProperty(key) && angular.isFunction(options[key])) {
        Resource.prototype[key] = options[key];
      }
    }

    return Resource;
  }

  return factory;
}]);
