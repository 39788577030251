angular.module('examManagement').factory('Reducers::CandidateList', [
  'Actions::CandidateList',
  function(Actions) {
    function unique(element, index, array) {
      return array.lastIndexOf(element) == index;
    }

    function reducer(state, action) {
      state = state || {};
      switch (action.type) {
        case Actions.FETCH_CANDIDATES_REQUEST:
          return Object.assign({}, state, { requestInProgress: true });

        case Actions.FETCH_CANDIDATES_SUCCESS:
          return Object.assign({}, state, {
            requestInProgress: false,
            items: action.candidates
          });

        case Actions.SET_REQUEST_IN_PROGRESS:
          return Object.assign({}, state, { requestInProgress: action.requestInProgress });

        case Actions.SET_SELECTION:
          return Object.assign({}, state, { selection: action.ids });

        case Actions.ADD_TO_SELECTION:
          return Object.assign({}, state, {
            selection: (state.selection || []).concat(action.ids).filter(unique)
          });

        case Actions.REMOVE_FROM_SELECTION:
          return Object.assign({}, state, {
            selection: (state.selection || []).filter(function(id) {
              return id != action.id;
            })
          });

        case Actions.DELETE_CANDIDATES:
          return Object.assign({}, state, {
            items: (state.items || []).filter(function(item) {
              return !action.candidateIds.includes(item.id);
            }),
            selection: []
          });

        case Actions.SET_ORDER:
          return Object.assign({}, state, {
            order: action.order
          });

        default:
          return state;
      }
    }

    return reducer;
  }
]);
