angular.module('common').directive('niceCheckbox', function() {
  return {
    restrict: 'E',
    require: 'ngModel',
    transclude: true,
    template: require('./nice_checkbox.html'),
    link: function(scope, element, attrs, modelController) {
      modelController.$render = function() {
        var changingIcon = element.find('.fa-check, .fa-empty');
        changingIcon.removeClass('fa-check fa-empty');
        if (modelController.$modelValue) {
          changingIcon.addClass('fa-check');
        } else {
          changingIcon.addClass('fa-empty');
        }
      };

      element.on('click', function() {
        modelController.$setViewValue(!modelController.$modelValue);
        modelController.$render();
      });
    }
  };
});
