/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Question,
  QuestionGroupType,
  QuestionSeries,
  SingleQuestion
} from '../../../generated/base-types';
import { CsvGeneratorService } from '../../common/services/csv-export-generator.service';
import {
  commonColumnDefinitions,
  ExportColumnDefinition
} from '../../common/services/export-column-definition';
import { FileSaverService } from '../../common/services/file-saver.service';
import { XlsxExportGeneratorService } from '../../common/services/xlsx-export-generator.service';
import { ExportExamQuestionGroupFragment } from '../modals/exam-question-group-export-modal/exam-question-group-export.generated';

@Injectable({
  providedIn: 'root'
})
export class ExamQuestionGroupExporterService {
  constructor(
    private translate: TranslateService,
    private fileSaver: FileSaverService
  ) {}

  public saveAsCsv(
    examQuestionGroups: ExportExamQuestionGroupFragment[]
  ): void {
    const generator = new CsvGeneratorService<ExportExamQuestionGroupFragment>(
      this.getColumnDefinitions()
    );
    generator.addObjects(this.flatten(examQuestionGroups));
    this.fileSaver.saveStringAs(
      generator.toString(),
      'exam-question-groups.csv',
      'text/csv'
    );
  }

  public saveAsXlsx(
    examQuestionGroups: ExportExamQuestionGroupFragment[]
  ): void {
    const generator =
      new XlsxExportGeneratorService<ExportExamQuestionGroupFragment>(
        this.getColumnDefinitions()
      );
    generator.addObjects(this.flatten(examQuestionGroups));
    this.fileSaver.saveStringAs(
      generator.toString(),
      'exam-question-groups.xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  }

  private flatten(
    examQuestionGroups: ExportExamQuestionGroupFragment[]
  ): ExportExamQuestionGroupFragment[] {
    return examQuestionGroups.flatMap(examQuestionGroup => {
      const questions = (
        examQuestionGroup.questionGroup.type === QuestionGroupType.Single
          ? [(examQuestionGroup.questionGroup as SingleQuestion).question]
          : (examQuestionGroup.questionGroup as QuestionSeries).questions || []
      ) as Question[];

      return questions.map(question => {
        return {
          ...examQuestionGroup,
          positionsBookletA: [
            examQuestionGroup.positionsBookletA[question.position]
          ],
          positionsBookletB: [
            examQuestionGroup.positionsBookletB[question.position]
          ],
          questionGroup: {
            ...examQuestionGroup.questionGroup,
            ...(examQuestionGroup.questionGroup.type ===
              QuestionGroupType.Single && { question }),
            ...((examQuestionGroup.questionGroup.type ===
              QuestionGroupType.Series ||
              examQuestionGroup.questionGroup.type ===
                QuestionGroupType.Sequence) && {
              questions: [question as Question]
            })
          }
        };
      });
    });
  }

  private getColumnDefinitions(): ExportColumnDefinition<ExportExamQuestionGroupFragment>[] {
    return [
      {
        name: this.translate.instant('common.question_list.columns.exam_id'),
        value: (o: ExportExamQuestionGroupFragment): string => '' + o.exam.id
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.fixed_number'
        ),
        value: (o: ExportExamQuestionGroupFragment): string => {
          const questionGroup = o.questionGroup;
          const questions =
            questionGroup.type === QuestionGroupType.Single
              ? [(questionGroup as SingleQuestion).question]
              : (questionGroup as QuestionSeries).questions || [];

          return questions.map(q => q?.fixedNumber).join(', ');
        }
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.position_a_as_string'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o.positionsBookletA[0]
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.position_b_as_string'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o.positionsBookletB[0]
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.is_head_version'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o.isHeadVersion
            ? this.translate.instant('common.true')
            : this.translate.instant('common.false')
      },
      {
        name: this.translate.instant('common.question_list.columns.complete'),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o.complete
            ? this.translate.instant('common.true')
            : this.translate.instant('common.false')
      },
      ...commonColumnDefinitions(
        this.translate,
        o => (o as ExportExamQuestionGroupFragment).questionGroup
      ),
      {
        name: this.translate.instant(
          'common.question_list.columns.question_group_height_type_de'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o.questionGroupHeightTypeDe ?? ''
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.question_group_height_in_cm_de'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o?.questionGroupHeightInCmDe !== undefined &&
          o?.questionGroupHeightInCmDe !== null
            ? '' + o.questionGroupHeightInCmDe
            : ''
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.question_group_height_type_fr'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o.questionGroupHeightTypeFr ?? ''
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.question_group_height_in_cm_fr'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o?.questionGroupHeightInCmFr !== undefined &&
          o?.questionGroupHeightInCmFr !== null
            ? '' + o.questionGroupHeightInCmFr
            : ''
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.question_group_height_type_it'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o.questionGroupHeightTypeIt ?? ''
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.question_group_height_in_cm_it'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o?.questionGroupHeightInCmIt !== undefined &&
          o?.questionGroupHeightInCmIt !== null
            ? '' + o.questionGroupHeightInCmIt
            : ''
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.question_group_height_type_en'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o.questionGroupHeightTypeEn ?? ''
      },
      {
        name: this.translate.instant(
          'common.question_list.columns.question_group_height_in_cm_en'
        ),
        value: (o: ExportExamQuestionGroupFragment): string =>
          o?.questionGroupHeightInCmEn !== undefined &&
          o?.questionGroupHeightInCmEn !== null
            ? '' + o.questionGroupHeightInCmEn
            : ''
      }
    ];
  }
}
