angular.module('taskManagement').factory('Selectors::TaskQuestionGroupList', function() {
  function getColumnDefinitions(object) {
    return object && object.taskQuestionGroupList && object.taskQuestionGroupList.columns;
  }

  function transformToArray(columns) {
    return Object.keys(columns).reduce(function(acc, columnName) {
      acc.push(Object.assign({ name: columnName }, columns[columnName]));
      return acc;
    }, []);
  }

  function extractColumnVisibilities(columns) {
    return Object.keys(columns).reduce(function(acc, column) {
      acc[column] = columns[column] && columns[column].visible;
      return acc;
    }, {});
  }

  function getSelection(object) {
    return (object && object.taskQuestionGroupList && object.taskQuestionGroupList.selection) || [];
  }

  function getSelectionCount(selection) {
    return selection.length;
  }

  function getItems(object) {
    return (object && object.taskQuestionGroupList && object.taskQuestionGroupList.items) || [];
  }

  function transformToFlatItems(items) {
    return items.map(function(item) {
      var questions = item.question_group.questions || [],
        future_exams = item.question_group.future_exams || [];

      return Object.assign({}, item, {
        question_group: Object.assign({}, item.question_group, {
          literary_reference: truncate(
            questions
              .map(function(question) {
                return question.literary_reference;
              })
              .join(', ')
          ),
          question_types: questions
            .map(function(question) {
              return I18n.t('questions.type.' + question.type);
            })
            .join(', '),
          score: questions.reduce(function(acc, question) {
            return acc + question.score;
          }, 0),
          future_exam_names: future_exams
            .map(function(exam) {
              return exam.name;
            })
            .join(', ')
        })
      });
    });
  }

  function truncate(string) {
    if (string.length > 20) {
      return string.substr(0, 20) + '...';
    } else {
      return string;
    }
  }

  function extractAllIds(items) {
    return items.map(function(item) {
      return item.id;
    });
  }

  function extractSelectedItems(selection, items) {
    return selection.reduce(function(acc, id) {
      var item = items.find(function(item) {
        return item.id == id;
      });
      if (item !== undefined) {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  function extractSelectedQuestionGroups(selection, items) {
    return selection.reduce(function(acc, id) {
      var item = items.find(function(item) {
        return item.id == id;
      });
      if (item !== undefined) {
        acc.push(item.question_group);
      }
      return acc;
    }, []);
  }

  function extractLastSelectedQuestionGroup(selection, items) {
    var selectedId = selection[selection.length - 1];
    return (
      items.find(function(item) {
        return item.id == selectedId;
      }) || {}
    ).question_group;
  }

  function getOrder(object) {
    return (object && object.taskQuestionGroupList && object.taskQuestionGroupList.order) || [];
  }

  function parametrizeOrder(order) {
    return order.map(function(spec) {
      return spec.name + '.' + (spec.ascending ? 'asc' : 'desc');
    });
  }

  function isRequestInProgress(object) {
    return (
      (object && object.taskQuestionGroupList && object.taskQuestionGroupList.requestInProgress) ||
      false
    );
  }

  function parametrizeOrder(order) {
    return order.map(function(spec) {
      return spec.name + '.' + (spec.ascending ? 'asc' : 'desc');
    });
  }

  return {
    getColumnDefinitions: getColumnDefinitions,
    getColumnDefinitionsAsArray: Reselect.createSelector(getColumnDefinitions, transformToArray),
    getColumnVisibilities: Reselect.createSelector(getColumnDefinitions, extractColumnVisibilities),
    getSelection: getSelection,
    getSelectionCount: Reselect.createSelector(getSelection, getSelectionCount),
    getSelectedItems: Reselect.createSelector(getSelection, getItems, extractSelectedItems),
    getSelectedQuestionGroups: Reselect.createSelector(
      getSelection,
      getItems,
      extractSelectedQuestionGroups
    ),
    getLastSelectedQuestionGroup: Reselect.createSelector(
      getSelection,
      getItems,
      extractLastSelectedQuestionGroup
    ),
    getItems: getItems,
    getFlatItems: Reselect.createSelector(getItems, transformToFlatItems),
    getAllIds: Reselect.createSelector(getItems, extractAllIds),
    getOrder: getOrder,
    getOrderAsParameter: Reselect.createSelector(getOrder, parametrizeOrder),
    isRequestInProgress: isRequestInProgress
  };
});
