angular.module('settings').component('poolSubNavigation', {
  template: require('./pool_sub_navigation.html'),
  controller: PoolSubNavigationController
});

PoolSubNavigationController.$inject = ['$state']

function PoolSubNavigationController($state) {
  this.I18n = I18n;
  this.$state = $state
}
