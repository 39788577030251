import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { QuestionManagementComponent } from '../components/question-management/question-management.component';

angular.module('questionManagement').directive(
  'appQuestionManagement',
  downgradeComponent({
    component: QuestionManagementComponent
  })
);
