(function() {
  function Controller(
    $window,
    Task,
    $scope,
    ConfirmationModal,
    TableColumnVisibilityFunctionality,
    MultiSelection,
    SortByColumnFunctionality,
    Store,
    Selectors,
    Actions,
    Repository
  ) {
    var itemsSubscription, requestInProgressSubscription, listOrderSubscription;

    /*
     * Helper functions
     */

    function getOrderAsParameter() {
      var order = Selectors.getOrder(Store.getState());
      return order.map(function(spec) {
        return spec.name + '.' + (spec.ascending ? 'asc' : 'desc');
      });
    }

    /*
     * Data & callbacks
     */
    this.I18n = I18n;

    this.loadItems = function() {
      Store.dispatch(Actions.fetchItemsRequest());
      Repository.query(getOrderAsParameter()).then(function(data) {
        Store.dispatch(Actions.fetchItemsSuccess(data));
        if (Selectors.getSelectedItems(Store.getState()).length === 0 && data.length > 0) {
          Store.dispatch(Actions.setSelection([data[0].id]));
        }
      });
    };

    this.openExternalView = function(item) {
      var task = new Task(item);
      task.getUrlToExternalView().then(function(data) {
        var url = data.url + '&details=true';
        $window.open(url);
      });
    };

    this.deleteTask = function(item) {
      var task = new Task(item);
      ConfirmationModal.show(
        I18n.t('task_management.task_list.remove_modal.title'),
        I18n.t('task_management.task_list.remove_modal.content'),
        {
          ok: I18n.t('task_management.task_list.remove_modal.ok')
        }
      ).result.then(function() {
        task.delete().then(function() {
          item.soft_deleted = true;
        });
      });
    };

    /*
     * Additional functionality
     */
    this.selection = new MultiSelection(
      Actions.setSelection,
      Actions.addToSelection,
      Actions.removeFromSelection,
      Selectors.getSelection,
      Selectors.getAllIds,
      Selectors.getItems,
      'taskList.selection'
    );

    this.visibility = new TableColumnVisibilityFunctionality(Selectors.getColumnDefinitions);

    this.sortByColumn = new SortByColumnFunctionality(
      Actions.setOrder,
      Selectors.getOrder,
      'created_at',
      'taskList'
    );

    this.$onInit = function() {
      /*
       * Data subscriptions
       */
      itemsSubscription = Store.subscribeOn(
        Selectors.getItems,
        function(items) {
          this.items = items;
        }.bind(this)
      );

      requestInProgressSubscription = Store.subscribeOn(
        Selectors.isRequestInProgress,
        function(requestInProgress) {
          this.requestInProgress = requestInProgress;
        }.bind(this)
      );

      listOrderSubscription = Store.subscribeOn(Selectors.getOrder, this.loadItems.bind(this));
    };

    /*
     * Destruction
     */
    $scope.$on(
      '$destroy',
      function() {
        this.visibility.destroy();
        this.selection.destroy();
        this.sortByColumn.destroy();
        itemsSubscription();
        requestInProgressSubscription();
        listOrderSubscription();
      }.bind(this)
    );
  }

  Controller.$inject = [
    '$window',
    'Task',
    '$scope',
    'ConfirmationModal',
    'TableColumnVisibilityFunctionality',
    'MultiSelection',
    'SortByColumnFunctionality',
    'Store',
    'Selectors::TaskList',
    'Actions::TaskList',
    'Repositories::Task'
  ];

  angular.module('taskManagement').component('taskList', {
    template: require('./task_list.html'),
    controller: Controller
  });
})();
