import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'man-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements AfterViewInit, OnDestroy {
  @Input() public disabled?: boolean;
  @Input() public placeholder?: string = '';
  @Input() public shortcutActive?: boolean; // New input to toggle shortcut

  @Output() public search = new EventEmitter<string>();
  @Output() public clear = new EventEmitter<void>();

  @ViewChild('search', { static: true })
  private inputRef!: ElementRef<HTMLInputElement>;

  public readonly shortcutKey = '/';
  public searchTerm?: string = '';

  public onSearch(value: string): void {
    this.searchTerm = value;
    this.search.emit(this.searchTerm);
  }

  public onClear(): void {
    this.searchTerm = '';
    this.clear.emit();
  }

  public ngAfterViewInit(): void {
    if (this.shortcutActive === true) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  public ngOnDestroy(): void {
    if (this.shortcutActive === true) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  private handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === this.shortcutKey) {
      const activeElement = document.activeElement;
      if (
        !(
          activeElement instanceof HTMLInputElement ||
          activeElement instanceof HTMLTextAreaElement
        )
      ) {
        event.preventDefault();
        this.inputRef.nativeElement.focus();
      }
    }
  };
}
