import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PoolDetailsFragment = { __typename?: 'Pool', id: string, name: string, selfAssessment: boolean, deEnabled: boolean, frEnabled: boolean, itEnabled: boolean, enEnabled: boolean, responsibleFirstName?: string | null, responsibleLastName?: string | null, responsibleEmail: string, responsibleLanguage?: Types.Language | null };

export type LoadPoolDetailsQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type LoadPoolDetailsQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', id: string, name: string, selfAssessment: boolean, deEnabled: boolean, frEnabled: boolean, itEnabled: boolean, enEnabled: boolean, responsibleFirstName?: string | null, responsibleLastName?: string | null, responsibleEmail: string, responsibleLanguage?: Types.Language | null } };

export const PoolDetailsFragmentDoc = gql`
    fragment PoolDetails on Pool {
  id
  name
  selfAssessment
  deEnabled
  frEnabled
  itEnabled
  enEnabled
  responsibleFirstName
  responsibleLastName
  responsibleEmail
  responsibleLanguage
}
    `;
export const LoadPoolDetailsDocument = gql`
    query LoadPoolDetails($poolId: ID!) {
  pool(id: $poolId) {
    ...PoolDetails
  }
}
    ${PoolDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadPoolDetailsGQL extends Apollo.Query<LoadPoolDetailsQuery, LoadPoolDetailsQueryVariables> {
    document = LoadPoolDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }