import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { AnnouncementListElementFragmentDoc } from './load-announcements.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateAnnouncementMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  attributes: Types.AnnouncementInput;
}>;


export type UpdateAnnouncementMutation = { __typename?: 'Mutation', updateAnnouncement?: { __typename?: 'UpdateAnnouncementPayload', successful: boolean, announcement?: { __typename?: 'Announcement', id: string, startsAt: string, endsAt: string, severity: Types.AnnouncementSeverity, enabled: boolean, message: { __typename?: 'TranslatedContent', de?: string | null, fr?: string | null, en?: string | null, it?: string | null } } | null } | null };

export const UpdateAnnouncementDocument = gql`
    mutation UpdateAnnouncement($id: ID!, $attributes: AnnouncementInput!) {
  updateAnnouncement(id: $id, attributes: $attributes) {
    announcement {
      ...AnnouncementListElement
    }
    successful
  }
}
    ${AnnouncementListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAnnouncementGQL extends Apollo.Mutation<UpdateAnnouncementMutation, UpdateAnnouncementMutationVariables> {
    document = UpdateAnnouncementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }