import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Table } from 'primeng/table';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { RemoteData } from '../../common/utils/remote-data';
import { PoolsState } from '../../state/pools.state';
import { FuzzySearchService } from '../common/fuzzy-search.service';
import { PoolListElementFragment } from '../services/load-pools.generated';

@Component({
  templateUrl: './pools.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'container content' }
})
export class PoolsComponent {
  @ViewChild('tbl', { static: false })
  public tbl?: Table;

  public readonly RowHeight = 50;
  public readonly filterFields: (keyof PoolListElementFragment)[] = [
    'id',
    'name'
  ];
  public readonly pools$: Observable<RemoteData<PoolListElementFragment[]>>;
  public readonly selection$: Observable<PoolListElementFragment | undefined>;

  private searchStringSource = new BehaviorSubject<string>('');
  private searchString$ = this.searchStringSource.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private fuzzySearchService: FuzzySearchService<PoolListElementFragment>
  ) {
    this.pools$ = combineLatest([
      this.store.select(PoolsState.pools),
      this.searchString$
    ]).pipe(
      map(([pools, searchString]) => {
        const result = this.fuzzySearchService.search(
          pools.data || [],
          searchString,
          this.filterFields
        );

        return { ...pools, data: result };
      })
    );
  }

  public onSearch(value: string): void {
    this.searchStringSource.next(value);
  }

  public onSearchClear(): void {
    this.tbl?.clear();
  }

  public onCreate(): void {
    this.router.navigate(['create'], {
      relativeTo: this.route
    });
  }

  public onEdit(pool: PoolListElementFragment): void {
    this.router.navigate([pool.id], {
      relativeTo: this.route
    });
  }
}
