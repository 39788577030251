angular.module('examManagement').factory('Repositories::ExamBook', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      get =
        'query($id: Int!, $criteria: [Boolean!]!, $display_exam_name: Boolean!, $display_page_numbers: Boolean!, $display_type_desc: Boolean!, $display_type_desc_position: Boolean!, $inline_title: Boolean!, $language: String!, $display_solution: Boolean!, $scrambling: String!) {' +
        '  exam(id: $id) { id' +
        '    exam_book(criteria: $criteria, display_exam_name: $display_exam_name, display_page_numbers: $display_page_numbers, display_type_desc: $display_type_desc, display_type_desc_position: $display_type_desc_position, inline_title: $inline_title, language: $language, display_solution: $display_solution, scrambling: $scrambling) { ' +
        '      url' +
        '    } ' +
        '  } ' +
        '}';

    return {
      get: function(
        examId,
        criteria,
        display_exam_name,
        display_page_numbers,
        display_type_desc,
        display_type_desc_position,
        inline_title,
        language,
        display_solution,
        scrambling
      ) {
        return client
          .query(get, {
            id: examId,
            criteria: criteria,
            display_exam_name: display_exam_name,
            display_page_numbers: display_page_numbers,
            display_type_desc: display_type_desc,
            display_type_desc_position: display_type_desc_position,
            inline_title: inline_title,
            language: language,
            display_solution: display_solution,
            scrambling: scrambling
          })
          .then(function(response) {
            return response.data.data.exam.exam_book;
          });
      }
    };
  }
]);
