angular.module('settings').factory('Actions::CategorySelection', function () {
  var actions = {
    FETCH_DIMENSIONS_REQUEST: 'DIMENSIONS_LIST_FETCH_DIMENSIONS_REQUEST',
    FETCH_DIMENSIONS_SUCCESS: 'DIMENSIONS_LIST_FETCH_DIMENSIONS_SUCCESS',
    SET_DIMENSION_SELECTION: 'DIMENSIONS_LIST_SET_DIMENSION_SELECTION',
    REMOVE_DIMENSION_SELECTION: 'DIMENSIONS_LIST_REMOVE_DIMENSION_SELECTION'
  };

  function fetchDimensionsRequest () {
    return {
      type: actions.FETCH_DIMENSIONS_REQUEST
    };
  }

  function fetchDimensionsSuccess (dimensions) {
    return {
      type: actions.FETCH_DIMENSIONS_SUCCESS,
      dimensions: dimensions
    };
  }

  function setDimensionSelection (id) {
    return {
      type: actions.SET_DIMENSION_SELECTION,
      selectedId: id
    };
  }

  function removeDimensionSelection () {
    return {
      type: actions.REMOVE_DIMENSION_SELECTION
    };
  }

  return Object.assign({
    fetchDimensionsRequest: fetchDimensionsRequest,
    fetchDimensionsSuccess: fetchDimensionsSuccess,
    setDimensionSelection: setDimensionSelection,
    removeDimensionSelection: removeDimensionSelection
  }, actions);
});
