/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { TinyMCEEditor } from './tiny-mce.types';

declare let tinyMCE: {
  PluginManager: {
    add(name: string, callback: (editor: TinyMCEEditor) => void): void;
  };
};

export const TINY_MCE_PAGEBREAK_CLASS = '.mce-pagebreak';
const TINY_MCE_ID_SELECTOR = '#tinymce';
const IMAGES_OUTSIDE_OF_TABLES_SELECTOR =
  '#tinymce > img:not(' +
  TINY_MCE_PAGEBREAK_CLASS +
  '), #tinymce > p > img:not(' +
  TINY_MCE_PAGEBREAK_CLASS +
  ')';
const RELATIVE_WIDTH_SELECTOR = 'data-relative-width';
const RELATIVE_RATIO_SELECTOR = 'data-aspect-ratio';

// makes sure when the editor size changes when toggling languages
// that the images are resized relative to the editor width
export const setImageAttributesOnResize = (editor: TinyMCEEditor): void => {
  const maxEditorWidth = getEditorWidth(editor);

  // we only resize images outside of tables
  // as currently tables aren't fit perfectly within the editor
  const imagesOutSideOfTables = editor.contentDocument.querySelectorAll(
    IMAGES_OUTSIDE_OF_TABLES_SELECTOR
  );

  imagesOutSideOfTables.forEach(image => {
    const relativeWidth = Number.parseFloat(
      image.getAttribute(RELATIVE_WIDTH_SELECTOR)!
    );
    const width = Number.parseFloat(image.getAttribute('width')!);
    const fittedWidth = (maxEditorWidth * relativeWidth) / 100;

    if (width !== fittedWidth) {
      image.setAttribute('width', `${fittedWidth}`);
    }
  });
};

export const setTableAttributes = (table: HTMLTableElement): void => {
  const width = table.getBoundingClientRect().width;
  table.setAttribute(RELATIVE_WIDTH_SELECTOR, `${width}`);

  // remove width attributes set automatically by TinyMCE
  // that we don't use for preview generation with Apache FOP
  table.setAttribute('style', '');
  delete table.dataset.mceStyle;

  table.querySelectorAll<HTMLTableCellElement>('th, td').forEach(tableCell => {
    const width = tableCell.getBoundingClientRect().width;
    // here we're setting the width in px to the data-relative-width attribute due to legacy reasons
    tableCell.setAttribute(RELATIVE_WIDTH_SELECTOR, `${width}`);
    tableCell.setAttribute('style', '');
  });
};

export const setImageAttributes = (
  image: HTMLImageElement,
  maxEditorWidth: number
): void => {
  let width = Number.parseFloat(image.getAttribute('width')!);
  let height = Number.parseFloat(image.getAttribute('height')!);

  // the attributes width and height are set after resizing
  // if nothings has been applied, use the img defaults
  if (Number.isNaN(width) && Number.isNaN(height)) {
    width = image.width;
    height = image.height;
  }

  const relativeWidth = Number.parseFloat(
    image.getAttribute(RELATIVE_WIDTH_SELECTOR)!
  );

  // only update relative width when available
  // this fixes the issue caused by the https://www.tiny.cloud/docs/plugins/opensource/paste/ plugin
  if (
    !Number.isNaN(width) &&
    !Number.isNaN(height) &&
    !Number.isNaN(width / height)
  ) {
    // sets data-relative-ratio attributes
    // the first time the image is added
    const newRelativeRatio = width / height;
    image.setAttribute(RELATIVE_RATIO_SELECTOR, `${newRelativeRatio}`);
  }

  if (width >= maxEditorWidth) {
    image.setAttribute(RELATIVE_WIDTH_SELECTOR, '100.0');
    image.setAttribute('width', `${maxEditorWidth}`);
    image.setAttribute('height', 'auto');
  } else {
    const newRelativeWidth = (100 * width) / maxEditorWidth;

    if (relativeWidth !== newRelativeWidth) {
      image.setAttribute(RELATIVE_WIDTH_SELECTOR, `${newRelativeWidth}`);
      image.setAttribute('height', 'auto');
    }
  }
};

const setAttributesOnChange = (editor: TinyMCEEditor): void => {
  const maxEditorWidth = getEditorWidth(editor);

  const images: NodeListOf<HTMLImageElement> =
    editor.contentDocument.querySelectorAll(
      'img:not(' + TINY_MCE_PAGEBREAK_CLASS + ')'
    );
  const tables = editor.contentDocument.querySelectorAll('table');

  images.forEach(image => setImageAttributes(image, maxEditorWidth));
  tables.forEach(table => setTableAttributes(table));
};

const getEditorWidth = (editor: TinyMCEEditor): number => {
  const editorElement =
    editor.contentDocument.querySelector(TINY_MCE_ID_SELECTOR);

  if (editorElement === null) {
    throw 'TinyMCE editor not found'; // this should never happen
  }

  return editorElement.getBoundingClientRect().width;
};

export function setupIMLImagePlugin(): void {
  tinyMCE.PluginManager.add('iml-image', function (editor: TinyMCEEditor) {
    let previousEditorWidth: number;

    editor.on('init', function (): void {
      setImageAttributesOnResize(editor);
      previousEditorWidth = getEditorWidth(editor);

      editor.contentWindow.addEventListener('resize', onResize);
    });

    editor.on('change', function (): void {
      setAttributesOnChange(editor);
    });

    editor.on('remove', function (): void {
      editor.contentWindow.removeEventListener('resize', onResize);
    });

    function onResize(): void {
      const currentEditorWidth = getEditorWidth(editor);

      if (previousEditorWidth !== currentEditorWidth) {
        previousEditorWidth = currentEditorWidth;
        setImageAttributesOnResize(editor);

        // we have to trigger the editor after the resizing to make sure the editor resizes accordingly
        editor.focus();
      }
    }
  });
}
