(function () {
  angular.module('settings').component('userSubNavigation', {
    template: require('./user_sub_navigation.html'),
    controller: Controller
  });

  Controller.$inject = ['$state']

  function Controller($state) {
    this.I18n = I18n;
    this.$state = $state
  }
})();
