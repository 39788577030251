<!-- DE -->
<ng-container *ngIf="languageVisibility.de" [formGroup]="form">
  <div class="question-content">
    <label [for]="'question_text_de_1'">{{
      'scrudu.languages.de' | translate
    }}</label>
    <qf-rich-editor
      [id]="'question_text_de_1'"
      #editor="richEditor"
      formControlName="contentDePart1"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.statementA?.de"
      [inputField]="editor"
      [inputValue]="form.get('contentDePart1')?.value"
    ></qf-input-feedback>
  </div>

  <div class="question-reason">
    <label [for]="'question_text_de_2'">{{
      'question_groups.edit.type_e_because' | translateToLanguage : 'de' | async
    }}</label>
    <qf-rich-editor
      [id]="'question_text_de_2'"
      #secondEditor="richEditor"
      formControlName="contentDePart2"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.statementB?.de"
      [inputField]="secondEditor"
      [inputValue]="form.get('contentDePart2')?.value"
    ></qf-input-feedback>
  </div>

  <div class="question-video">
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'de' | async }}"
      [initiallyOpen]="!!form.get('videoDe')?.value"
    >
      <qf-video-upload formControlName="videoDe"></qf-video-upload>
    </co-single-panel-accordion>
  </div>

  <div class="question-solution">
    <qf-question-content-type-e-solution-selection
      [ngModel]="form.get('solution')?.value"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="solutionChanged($event)"
      [validations]="validations"
      [language]="'de'"
    ></qf-question-content-type-e-solution-selection>
  </div>
</ng-container>

<!-- FR -->
<ng-container *ngIf="languageVisibility.fr" [formGroup]="form">
  <div class="question-content">
    <label [for]="'question_text_fr_1'">{{
      'scrudu.languages.fr' | translate
    }}</label>
    <qf-rich-editor
      [id]="'question_text_fr_1'"
      #editor="richEditor"
      formControlName="contentFrPart1"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.statementA?.fr"
      [inputField]="editor"
      [inputValue]="form.get('contentFrPart1')?.value"
    ></qf-input-feedback>
  </div>

  <div class="question-reason">
    <label [for]="'question_text_fr_2'"
      >{{
        'question_groups.edit.type_e_because'
          | translateToLanguage : 'fr'
          | async
      }}
    </label>
    <qf-rich-editor
      [id]="'question_text_fr_2'"
      #secondEditor="richEditor"
      formControlName="contentFrPart2"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.statementB?.fr"
      [inputField]="secondEditor"
      [inputValue]="form.get('contentFrPart2')?.value"
    ></qf-input-feedback>
  </div>

  <div class="question-video">
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'fr' | async }}"
      [initiallyOpen]="!!form.get('videoFr')?.value"
    >
      <qf-video-upload formControlName="videoFr"></qf-video-upload>
    </co-single-panel-accordion>
  </div>

  <div class="question-solution">
    <qf-question-content-type-e-solution-selection
      [ngModel]="form.get('solution')?.value"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="solutionChanged($event)"
      [validations]="validations"
      [language]="'fr'"
    ></qf-question-content-type-e-solution-selection>
  </div>
</ng-container>

<!-- IT -->
<ng-container *ngIf="languageVisibility.it" [formGroup]="form">
  <div class="question-content">
    <label [for]="'question_text_it_1'">{{
      'scrudu.languages.it' | translate
    }}</label>
    <qf-rich-editor
      [id]="'question_text_it_1'"
      #editor="richEditor"
      formControlName="contentItPart1"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.statementA?.it"
      [inputField]="editor"
      [inputValue]="form.get('contentItPart1')?.value"
    ></qf-input-feedback>
  </div>

  <div class="question-reason">
    <label [for]="'question_text_it_2'">{{
      'question_groups.edit.type_e_because' | translateToLanguage : 'it' | async
    }}</label>
    <qf-rich-editor
      [id]="'question_text_it_2'"
      #secondEditor="richEditor"
      formControlName="contentItPart2"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.statementB?.it"
      [inputField]="secondEditor"
      [inputValue]="form.get('contentItPart2')?.value"
    ></qf-input-feedback>
  </div>

  <div class="question-video">
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'it' | async }}"
      [initiallyOpen]="!!form.get('videoIt')?.value"
    >
      <qf-video-upload formControlName="videoIt"></qf-video-upload>
    </co-single-panel-accordion>
  </div>

  <div class="question-solution">
    <qf-question-content-type-e-solution-selection
      [ngModel]="form.get('solution')?.value"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="solutionChanged($event)"
      [validations]="validations"
      [language]="'it'"
    ></qf-question-content-type-e-solution-selection>
  </div>
</ng-container>

<!-- EN -->
<ng-container *ngIf="languageVisibility.en" [formGroup]="form">
  <div class="question-content">
    <label [for]="'question_text_en_1'">{{
      'scrudu.languages.en' | translate
    }}</label>
    <qf-rich-editor
      [id]="'question_text_en_1'"
      #editor="richEditor"
      formControlName="contentEnPart1"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.statementA?.en"
      [inputField]="editor"
      [inputValue]="form.get('contentEnPart1')?.value"
    ></qf-input-feedback>
  </div>

  <div class="question-reason">
    <label [for]="'question_text_en_2'">{{
      'question_groups.edit.type_e_because' | translateToLanguage : 'en' | async
    }}</label>
    <qf-rich-editor
      [id]="'question_text_en_2'"
      #secondEditor="richEditor"
      formControlName="contentEnPart2"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.statementB?.en"
      [inputField]="secondEditor"
      [inputValue]="form.get('contentEnPart2')?.value"
    ></qf-input-feedback>
  </div>

  <div class="question-video">
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'en' | async }}"
      [initiallyOpen]="!!form.get('videoEn')?.value"
    >
      <qf-video-upload formControlName="videoEn"></qf-video-upload>
    </co-single-panel-accordion>
  </div>

  <div class="question-solution" #solutionSelection>
    <qf-question-content-type-e-solution-selection
      [ngModel]="form.get('solution')?.value"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="solutionChanged($event)"
      [validations]="validations"
      [language]="'en'"
    ></qf-question-content-type-e-solution-selection>
  </div>
</ng-container>
