import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Scalars } from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { LabelValuePair } from '../../ng-select-wrapper/ng-select-wrapper.component';
import { SetRevisionYearGQL } from './set-revision-year.generated';

function revisionYears(): LabelValuePair<number>[] {
  const currentYear = new Date().getFullYear();
  const years = [];
  const startYear = 1980;
  for (let i = startYear; i <= currentYear; i++) {
    const label: LabelValuePair<number> = {
      value: i,
      label: i + ''
    };
    years.push(label);
  }

  return years.reverse();
}

@Component({
  selector: 'co-set-revision-year-modal',
  templateUrl: './set-revision-year-modal.component.html',
  styleUrls: ['./set-revision-year-modal.component.scss']
})
export class SetRevisionYearModalComponent {
  @Input()
  public modalInstance: NgbModalRef;

  @Input()
  public questionGroups: QuestionGroupListElementFragment[];

  @Input()
  public currentPoolId: Scalars['ID'];

  public revisions: LabelValuePair<number>[] = revisionYears();
  public revision: number;

  constructor(private setRevisionYearGQL: SetRevisionYearGQL) {}

  public async save(): Promise<void> {
    const result = await this.setRevisionYearGQL
      .mutate({
        poolId: this.currentPoolId,
        questionGroupIds: this.questionGroups.map(qg => qg.id),
        revisionYear: this.revision
      })
      .toPromise();

    if (result?.data?.setRevisionYear?.successful !== true) {
      throw `Unable to set revision year on question groups ${this.questionGroups.map(
        qg => qg.id
      )}`;
    }

    this.modalInstance.close();
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }
}
