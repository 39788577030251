<div class="modal-wrapper bs4" *ngIf="pools">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>
      {{ 'common.duplicate_question_group_modal.title' | translate }}
    </h3>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="form-group">
        <div class="col-sm-12 text-center">
          <div class="btn-group">
            <button
              (click)="setMode(modes.Current)"
              class="btn btn-primary"
              [ngClass]="{ active: mode == modes.Current }"
            >
              {{
                'common.duplicate_question_group_modal.modes.current'
                  | translate
              }}
            </button>
            <button
              class="btn btn-primary"
              (click)="setMode(modes.Other)"
              [ngClass]="{ active: mode == modes.Other }"
              *ngIf="pools && pools.length > 0"
            >
              {{
                'common.duplicate_question_group_modal.modes.other' | translate
              }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="mode == modes.Other">
        <label class="col-sm-2 control-label" for="pool">{{
          'activerecord.models.pool' | translate
        }}</label>
        <div class="col-sm-9 valign">
          <ng-select-wrapper
            [options]="pools"
            (valueChange)="setPool($event)"
          ></ng-select-wrapper>
        </div>
      </div>
      <div class="form-group" *ngIf="mode == modes.Current">
        <div class="col-sm-12 text-center">
          <p class="form-control-static">
            {{
              'common.duplicate_question_group_modal.current_pool.info'
                | translate
                  : { sequential_number: questionGroup.sequentialNumber }
            }}
          </p>
        </div>
      </div>
      <div class="form-group" *ngIf="mode == modes.Other && targetPool">
        <div class="col-sm-12 text-center">
          {{
            'common.duplicate_question_group_modal.other_pool.info'
              | translate
                : {
                    sequential_number: questionGroup.sequentialNumber,
                    pool: targetPool.label
                  }
          }}
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-secondary-migrated"
      (click)="dismiss()"
      type="button"
    >
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      class="btn btn-primary-migrated"
      (click)="duplicate()"
      type="button"
      [disabled]="mode == modes.Other && !targetPool"
    >
      {{
        'common.duplicate_question_group_modal.buttons.duplicate' | translate
      }}
    </button>
  </div>
</div>
