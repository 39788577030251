<ng-container [formGroup]="form" *ngIf="question">
  <div
    class="form-horizontal layout-cell--vertically-scrollable skin-container-cell--filled-light pv-detail__content__sub-content"
  >
    <div class="col-sm-6">
      <div class="form-group mb-0">
        <label class="control-label col-sm-3" for="score">
          {{ 'questions.edit.score' | translate }}
        </label>
        <div class="col-sm-3">
          <input
            #scoreInput
            class="form-control"
            id="score"
            [defaultValue]="1"
            min="0"
            type="number"
            formControlName="score"
            placeholder="{{ 'questions.edit.score' | translate }}"
          />
          <qf-input-feedback
            [validations]="validations?.score"
            [inputField]="scoreInput"
            [inputValue]="form.controls.score.value"
          ></qf-input-feedback>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group mb-0">
        <label class="control-label col-sm-3">
          {{ 'questions.edit.type' | translate }}
        </label>
        <p class="form-control-static col-sm-8 mb-0">
          <qf-change-type-modal-toggle
            *ngIf="isTypeTransformable && question.id"
            [questionId]="question.id"
            [language]="sourceLanguage"
            [text]="'questions.edit.change_type' | translate"
            [questionType]="question.type"
          ></qf-change-type-modal-toggle>
        </p>
        <switch-panel-toggle
          [element]="questionBody"
          [open]="true"
          class="col-sm-1"
        ></switch-panel-toggle>
      </div>
    </div>
  </div>

  <div class="layout-cell switch-panel-body p-0" #questionBody>
    <div
      class="switch-panel layout-cell layout-cell--horizontally-spaced layout-cell--bottom-spaced"
    >
      <div
        class="switch-panel-body skin-panel-body metadata-container pt-5"
        #metadataBody
      >
        <div class="col-sm-6 p-0">
          <div class="form-group">
            <label class="control-label col-sm-3">
              {{ 'questions.edit.blueprint' | translate }}
            </label>
          </div>
          <div
            class="form-group col-sm-12 p-0"
            *ngFor="let dimension of dimensions; let dimensionIndex = index"
          >
            <ng-container *ngIf="dimension.categories">
              <label
                class="control-label col-sm-3"
                [for]="'dimension-' + dimension.id"
              >
                {{ dimension.name }}
              </label>
              <div class="col-sm-9">
                <ng-select
                  #dimensionInput
                  [items]="dimension.categories"
                  [id]="'dimension-' + dimension.id"
                  bindValue="id"
                  bindLabel="name"
                  [formControlName]="'category' + dimensionIndex + 'Id'"
                  [clearable]="false"
                  dropdownPosition="bottom"
                ></ng-select>
                <qf-input-feedback
                  [validations]="getCategoryAlert(dimensionIndex)"
                  [inputField]="dimensionInput"
                  [inputValue]="
                    form.controls['category' + dimensionIndex + 'Id'].value
                  "
                ></qf-input-feedback>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-sm-6 p-0">
          <div class="form-group col-sm-12 p-0">
            <label class="control-label col-sm-3" for="learningTarget">
              {{ 'questions.edit.learning_target' | translate }}
            </label>

            <div class="col-sm-9">
              <textarea
                class="form-control"
                id="learningTarget"
                formControlName="learningTarget"
                placeholder="{{ 'questions.edit.placeholder' | translate }}"
              >
              </textarea>
            </div>
          </div>

          <div class="form-group col-sm-12 p-0">
            <label class="control-label col-sm-3" for="literaryReference">
              {{ 'questions.edit.literary_reference' | translate }}
            </label>

            <div class="col-sm-9">
              <textarea
                class="form-control"
                id="literaryReference"
                formControlName="literaryReference"
                placeholder="{{ 'questions.edit.placeholder' | translate }}"
              >
              </textarea>
            </div>
          </div>

          <div class="form-group col-sm-12 p-0">
            <label class="control-label col-sm-3" for="remarks">
              {{ 'question_groups.edit.remarks' | translate }}
            </label>

            <div class="col-sm-9">
              <textarea
                class="form-control"
                id="remarks"
                formControlName="remarks"
                placeholder="{{ 'questions.edit.placeholder' | translate }}"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="switch-panel-footer skin-panel-footer">
        <switch-panel-toggle
          [element]="metadataBody"
          [open]="form.controls.id.value === null || hasAlerts"
        ></switch-panel-toggle>
        {{ 'questions.edit.meta' | translate }}
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <qf-question-content-type-a-form
          [validations]="$any(validations)"
          [languageVisibility]="languageVisibility"
          *ngIf="question.a !== undefined"
          formControlName="content"
        ></qf-question-content-type-a-form>
        <qf-question-content-type-a-minus-form
          [validations]="$any(validations)"
          [languageVisibility]="languageVisibility"
          *ngIf="question.aMinus !== undefined"
          formControlName="content"
        ></qf-question-content-type-a-minus-form>
        <qf-question-content-type-e-form
          [validations]="$any(validations)"
          [languageVisibility]="languageVisibility"
          *ngIf="question.e !== undefined"
          formControlName="content"
        ></qf-question-content-type-e-form>
        <qf-question-content-type-kprime-form
          [validations]="$any(validations)"
          [languageVisibility]="languageVisibility"
          *ngIf="question.kPrime !== undefined"
          formControlName="content"
        ></qf-question-content-type-kprime-form>
        <qf-question-content-type-free-text-form
          [validations]="$any(validations)"
          [languageVisibility]="languageVisibility"
          *ngIf="question.freeText !== undefined"
          formControlName="content"
        ></qf-question-content-type-free-text-form>
        <qf-question-content-type-long-menu-form
          [languages]="languages"
          [validations]="$any(validations)"
          [languageVisibility]="languageVisibility"
          *ngIf="question.longMenu !== undefined"
          formControlName="content"
          [sequentialNumber]="sequentialNumber"
        ></qf-question-content-type-long-menu-form>
      </div>
    </div>
  </div>
</ng-container>
