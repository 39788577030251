import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'qf-remove-list-confirm-modal',
  templateUrl: './remove-list-confirm-modal.component.html',
  styleUrls: ['./remove-list-confirm-modal.component.scss']
})
export class RemoveListConfirmModalComponent {
  @Input()
  public modalInstance: NgbModalRef;

  public exportSolution = true;

  public cancel(): void {
    this.modalInstance.dismiss();
  }

  public remove(): void {
    this.modalInstance.close(this.exportSolution);
  }
}
