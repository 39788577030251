<div class="modal-header">
  <button
    type="button"
    class="close modal-close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="onDismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <h3>{{ title }}</h3>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
<div class="modal-footer">
  <button (click)="onDismiss()" class="btn btn-secondary-migrated">
    {{ cancelButtonTranslationKey || 'common.actions.cancel' | translate }}
  </button>
  <button ngbAutoFocus (click)="onConfirm()" class="btn btn-primary-migrated">
    {{ confirmButtonTranslationKey || 'common.ok' | translate }}
  </button>
</div>
