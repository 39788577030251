import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateDimensionMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  attributes: Types.DimensionInput;
}>;


export type CreateDimensionMutation = { __typename?: 'Mutation', createDimension?: { __typename?: 'CreateDimensionPayload', successful: boolean, dimension?: { __typename?: 'Dimension', id: string, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, position: number } | null } | null };

export const CreateDimensionDocument = gql`
    mutation CreateDimension($poolId: ID!, $attributes: DimensionInput!) {
  createDimension(poolId: $poolId, attributes: $attributes) {
    dimension {
      id
      name
      nameDe
      nameFr
      nameEn
      nameIt
      position
    }
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDimensionGQL extends Apollo.Mutation<CreateDimensionMutation, CreateDimensionMutationVariables> {
    document = CreateDimensionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }