angular.module('settings').factory('Actions::Subject', function () {
  var actions = {
    FETCH_SUBJECTS_REQUEST: 'SUBJECT_LIST_FETCH_SUBJECTS_REQUEST',
    FETCH_SUBJECTS_SUCCESS: 'SUBJECT_LIST_FETCH_SUBJECTS_SUCCESS',
    SET_SELECTION: 'SUBJECT_LIST_SET_SELECTION',
    REMOVE_SELECTION: 'SUBJECT_LIST_REMOVE_SELECTION',
    SET_SUBJECT: 'SUBJECT_LIST_SET_SUBJECT',
    ADD_SUBJECT: 'SUBJECT_LIST_ADD_SUBJECT',
    DELETE_SUBJECT: 'SUBJECT_LIST_DELETE_SUBJECT'
  };

  function fetchSubjectsRequest () {
    return {
      type: actions.FETCH_SUBJECTS_REQUEST
    };
  }

  function fetchSubjectsSuccess (subjects) {
    return {
      type: actions.FETCH_SUBJECTS_SUCCESS,
      subjects: subjects
    };
  }

  function setSelection (id) {
    return {
      type: actions.SET_SELECTION,
      selectedId: id
    };
  }

  function removeSelection () {
    return {
      type: actions.REMOVE_SELECTION
    };
  }

  function setSubject (subject) {
    return {
      type: actions.SET_SUBJECT,
      subject: subject
    };
  }

  function addSubject (subject) {
    return {
      type: actions.ADD_SUBJECT,
      subject: subject
    };
  }

  function deleteSubject (subjectId) {
    return {
      type: actions.DELETE_SUBJECT,
      subjectId: subjectId
    };
  }

  return Object.assign({
    fetchSubjectsRequest: fetchSubjectsRequest,
    fetchSubjectsSuccess: fetchSubjectsSuccess,
    setSelection: setSelection,
    removeSelection: removeSelection,
    setSubject: setSubject,
    addSubject: addSubject,
    deleteSubject: deleteSubject
  }, actions);
});
