angular.module('common').factory('Repositories::Version', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getVersion = 'query { version { name } }',
      getFullVersion = 'query { version { name, date } }';

    return {
      getVersion: function() {
        return client.query(getVersion).then(function(response) {
          return response.data.data.version.name;
        });
      },
      getFullVersion: function() {
        return client.query(getFullVersion).then(function(response) {
          return response.data.data.version;
        });
      }
    };
  }
]);
