import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UpdatePreviewsVisibleLanguages } from 'src/app/state/settings.actions';
import { SettingsState } from 'src/app/state/settings.state';
import {
  Language,
  LanguageSpecificUrl,
  PreviewGenerationStatus
} from 'src/generated/base-types';

const PANEL_NAMES_TO_LANGUAGE: Record<string, Language> = {
  de: Language.De,
  fr: Language.Fr,
  en: Language.En,
  it: Language.It
};

@Component({
  selector: 'co-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewComponent {
  @Input()
  public set status(value: PreviewGenerationStatus) {
    this.loading = value === PreviewGenerationStatus.InProgress;
  }

  @Input()
  public url: LanguageSpecificUrl | undefined | null;

  @Select(SettingsState.previewVisibleLanguages)
  private visibleLanguages$: Observable<Language[]>;

  public loading = false;
  public visibleLanguages: Language[] = [];

  constructor(private store: Store) {
    this.visibleLanguages$.pipe(take(1)).subscribe(languages => {
      this.visibleLanguages = [...(languages ?? [])];
    });
  }

  public previewVisibilityChanged(event: NgbPanelChangeEvent): void {
    this.visibleLanguages = event.nextState
      ? [...this.visibleLanguages, PANEL_NAMES_TO_LANGUAGE[event.panelId]]
      : this.visibleLanguages.filter(
          language => language !== PANEL_NAMES_TO_LANGUAGE[event.panelId]
        );
    this.store.dispatch(
      new UpdatePreviewsVisibleLanguages(this.visibleLanguages)
    );
  }
}
