angular.module('examManagement').controller('QuestionGroupsCtrl', [
  '$scope',
  '$uibModal',
  '$cookies',
  'ExamPresort',
  'Store',
  'Actions::ExamQuestionGroupList',
  'Actions::ExamList',
  'Selectors::ExamList',
  'Selectors::ExamQuestionGroupList',
  'Repositories::ExamQuestionGroup',
  'Repositories::Solution',
  'Repositories::Exam',
  'File',
  'SolutionCsvBuilder',
  'ExamQuestionGroupExporter',
  function (
    $scope,
    $uibModal,
    $cookies,
    ExamPresort,
    Store,
    Actions,
    ExamActions,
    ExamSelectors,
    ExamQuestionGroupSelectors,
    ExamQuestionGroupRepository,
    SolutionRepository,
    ExamRepository,
    File,
    SolutionCsvBuilder,
    ExamQuestionGroupExporter
  ) {
    function getSelectedExam() {
      return ExamSelectors.getSelectedItem(Store.getState());
    }

    function getExamQuestionGroups() {
      return ExamQuestionGroupSelectors.getItems(Store.getState());
    }

    function extractPositionData(exam_question_groups) {
      return exam_question_groups.map(function (item) {
        var o = {
          id: item.id,
          position_a: item.position_a,
          position_b: item.position_b
        };
        return o;
      });
    }

    $scope.selectedExam = getSelectedExam();
    $scope.scrambling = 'A';

    var scramblingSubscription = Store.subscribeOn(
      ExamQuestionGroupSelectors.getScrambling,
      function (value) {
        $scope.scrambling = value;
      }
    );

    var scramblingColumnVisibility = function () {
      if (
        getSelectedExam() &&
        getSelectedExam().is_presorted &&
        getSelectedExam().scrambling
      ) {
        Store.dispatch(Actions.enableColumn('id_in_book_b'));
      } else {
        Store.dispatch(Actions.disableColumn('id_in_book_b'));
      }
    };

    // ----------- state helpers -------------
    $scope.isScramblingEnabled = function () {
      return getSelectedExam() && getSelectedExam().has_scrambling_b;
    };

    $scope.setScrambling = function (value) {
      Store.dispatch(Actions.setScrambling(value));
    };

    $scope.isExamLocked = function () {
      return getSelectedExam() && getSelectedExam().locked;
    };

    $scope.isQuestionListEmpty = function () {
      return getExamQuestionGroups().length <= 0;
    };

    // ----------- actions -----------------
    $scope.openNewQuestionGroupsPresortForm = function () {
      var instance = $uibModal.open({
        template: require('./question_groups_presort.html'),
        controller: 'QuestionGroupsPresortCtrl',
        resolve: {
          exam: getSelectedExam,
          dimensions: function () {
            return getSelectedExam().dimensions;
          }
        }
      });
      instance.result.then(function (presortCriteria) {
        Store.dispatch(Actions.setRequestInProgress(true));
        ExamPresort.apply(presortCriteria, function () {
          Store.dispatch(Actions.fetchExamQuestionGroupClustersRequest());
          ExamRepository.get(getSelectedExam().id).then(function (exam) {
            Store.dispatch(ExamActions.setExam(exam));
            Store.dispatch(Actions.setRequestInProgress(false));
            scramblingColumnVisibility();
          });
        });
      });
    };

    $scope.openExportModal = function () {
      const wrapper = {
        modal: null
      };

      wrapper.modal = $uibModal.open({
        controller: [
          '$scope',
          'wrapper',
          function ($scope, wrapper) {
            $scope.wrapper = wrapper;
          }
        ],
        template: `
          <em-question-group-export-modal
            [modal-instance]="wrapper.modal"
          </em-question-group-export-modal>
        `,
        resolve: {
          wrapper: function () {
            return wrapper;
          }
        }
      });
    };

    $scope.exportExamSolutions = function () {
      SolutionRepository.getAll(getSelectedExam().id).then(function (data) {
        var builder = new SolutionCsvBuilder(data);
        var csv = builder.build();
        if (csv) {
          File.saveAs('solutions.csv', 'text/csv', csv);
        }
      });
    };

    $scope.applyOrderOfAToB = function () {
      var examQuestionGroups = getExamQuestionGroups();

      for (var i = 0; i < examQuestionGroups.length; i++) {
        var examQuestionGroup = examQuestionGroups[i];
        examQuestionGroup.position_b = examQuestionGroup.position_a;
      }

      examQuestionGroups.sort(function (a, b) {
        return a.position_b - b.position_b;
      });

      Store.dispatch(Actions.setRequestInProgress(true));

      ExamQuestionGroupRepository.updatePositions(
        extractPositionData(examQuestionGroups)
      ).then(function () {
        Store.dispatch(Actions.fetchExamQuestionGroupClustersRequest());
        ExamQuestionGroupRepository.getAll(getSelectedExam().id).then(function (
          data
        ) {
          Store.dispatch(Actions.fetchExamQuestionGroupClustersSuccess(data));
        });
      });
    };

    var visibilitySubscription = Store.subscribeOn(
      ExamSelectors.getSelectedItem,
      function () {
        scramblingColumnVisibility();
      }
    );

    $scope.$on('destroy', function () {
      scramblingSubscription();
      visibilitySubscription();
    });
  }
]);
