import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { PoolPermissionsFragmentDoc } from './load-pool-permissions.fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdatePermissionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  attributes: Types.PermissionInput;
}>;


export type UpdatePermissionMutation = { __typename?: 'Mutation', updatePermission?: { __typename?: 'UpdatePermissionPayload', successful: boolean, permission?: { __typename?: 'Permission', id: string, role: Types.PermissionRole, user: { __typename?: 'User', id: string, email?: string | null, firstName: string, lastName: string }, subject?: { __typename?: 'Subject', id: string, name: string } | null, pool: { __typename?: 'Pool', id: string, name: string } } | null } | null };

export const UpdatePermissionDocument = gql`
    mutation UpdatePermission($id: ID!, $attributes: PermissionInput!) {
  updatePermission(id: $id, attributes: $attributes) {
    permission {
      ...PoolPermissions
    }
    successful
  }
}
    ${PoolPermissionsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePermissionGQL extends Apollo.Mutation<UpdatePermissionMutation, UpdatePermissionMutationVariables> {
    document = UpdatePermissionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }