import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { LoadUsersFieldsFragmentDoc } from './load-users.fragments.generated';
import { ValidationsUserFieldsFragmentDoc } from './create-update-user.fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  attributes: Types.UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'UpdateUserPayload', successful: boolean, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null, language: Types.Language, organisation?: string | null, alternativeEmail?: string | null, mobilePhone?: string | null, alternativeMobilePhone?: string | null, currentSignInAt?: string | null, superUser: boolean, useEmailForMtan: boolean } | null, validations?: { __typename?: 'ValidationsUser', email?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, alternativeEmail?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, firstName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, lastName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, language?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, password?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, passwordConfirmation?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null } | null } | null };

export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $attributes: UserInput!) {
  updateUser(id: $id, attributes: $attributes) {
    user {
      ...LoadUsersFields
    }
    successful
    validations {
      ...ValidationsUserFields
    }
  }
}
    ${LoadUsersFieldsFragmentDoc}
${ValidationsUserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }