angular.module('common').factory('Actions::Version', function () {
  var actions = {
    SET_VERSION: 'COMMON_SET_VERSION',
  };

  function setVersion (version) {
    return {
      type: actions.SET_VERSION,
      version: version
    };
  }

  return Object.assign({
    setVersion: setVersion
  }, actions);
});
