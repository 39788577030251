import { toPng } from 'html-to-image';
import katex from 'katex';
import { Editor } from 'tinymce';
import { LATEX_PLUGIN_CSS_CLASS } from './latex.plugin';
import { TinyMCEDialog, ToggleMenuItemInstanceApi } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateFormConfig = (editor: Editor, mathMLEl?: string): any => {
  const isEditing = mathMLEl !== undefined;

  return {
    title: isEditing
      ? editor.translate('tiny_latex.edit_title')
      : editor.translate('tiny_latex.insert_title'),
    body: {
      type: 'panel',
      items: [
        {
          type: 'textarea',
          name: 'latex',
          label: editor.translate('tiny_latex.input_label')
        },
        {
          type: 'label',
          label: editor.translate('tiny_latex.preview_label'),
          items: [
            {
              type: 'htmlpanel',
              html: `<div id="tiny-latex-preview">${
                isEditing ? mathMLEl : ''
              }</div>`
            }
          ]
        }
      ]
    },
    buttons: [
      {
        type: 'submit',
        text: isEditing
          ? editor.translate('tiny_latex.update_button')
          : editor.translate('tiny_latex.insert_button'),
        primary: true
      },
      {
        type: 'cancel',
        text: editor.translate('tiny_latex.cancel_button')
      }
    ]
  };
};

export const updateMathMLPreview = (dialog: TinyMCEDialog): void => {
  const updatedLatex = dialog.getData().latex || '';

  const mmlSpan = generateMathMLSpan(updatedLatex);
  const previewEl = document.querySelector('#tiny-latex-preview');

  if (previewEl === null) throw new Error('Preview element not found');

  previewEl.innerHTML = mmlSpan.outerHTML;
};

export const replaceMmlSpan = (
  editor: Editor,
  oldMmlSpan: HTMLSpanElement,
  newMmlSpan: HTMLSpanElement
): void => {
  // get raw HTML content to prevent filtering out of span tag with contenteditable="false" attribute
  const oldContent = editor.getContent({ format: 'raw' });

  const mathMLSpanPattern = new RegExp(
    /* eslint-disable-next-line no-useless-escape */
    `(<span[^>]*id="${oldMmlSpan.id}"[^>]*>)([\\s\\S]*?)(<\/span>)`,
    'i'
  );
  const match = oldContent.match(mathMLSpanPattern);

  if (match === null)
    throw new Error('Span tag containing MathML element not found');

  // replaces old span tag containing MathML element
  // with new span tag containing updated MathML element
  const oldMathMlSpan = match[0];

  const newContent = oldContent.replace(oldMathMlSpan, newMmlSpan.outerHTML);
  editor.setContent(newContent, {
    format: 'raw'
  });
};

export const addBase64Png = async (
  mmlSpan: HTMLSpanElement
): Promise<HTMLSpanElement> => {
  const mmlSpanClone = mmlSpan.cloneNode(true) as HTMLSpanElement;
  (mmlSpanClone.firstElementChild as HTMLElement).dataset.base64 =
    await generateBase64Png(mmlSpan);

  return mmlSpanClone;
};

const generateBase64Png = (mml: HTMLSpanElement): Promise<string> => {
  const container = document.createElement('div');
  container.setAttribute('style', 'display: flex');

  container.innerHTML = mml.innerHTML;
  document.body.append(container);

  // make sure that the formula is the same size on all devices
  const MATH_FONT_SIZE = 20 / window.devicePixelRatio;
  container.firstElementChild!.setAttribute(
    'style',
    `font-size: ${MATH_FONT_SIZE}px;`
  );

  return toPng(<HTMLElement>container.firstChild).then(function (dataUrl) {
    container.remove();

    return dataUrl;
  });
};

export const generateMathMLSpan = (latex: string): HTMLSpanElement => {
  const mmlSpan = document.createElement('span');
  mmlSpan.classList.add(LATEX_PLUGIN_CSS_CLASS);
  mmlSpan.setAttribute('contenteditable', 'false');
  mmlSpan.setAttribute('id', crypto.randomUUID());

  katex.render(latex, mmlSpan, {
    throwOnError: false,
    output: 'mathml',
    displayMode: false
  });

  // Get the inner MathML content
  const innerMathML = mmlSpan.firstElementChild!.innerHTML;

  // Remove the second span
  const secondSpan = mmlSpan.querySelector('.katex');
  if (secondSpan) {
    secondSpan.remove();
  }

  mmlSpan.innerHTML = innerMathML;
  (mmlSpan.firstElementChild as HTMLElement).dataset.latex = latex;

  return mmlSpan;
};

export const insertTag = async (
  editor: Editor,
  dialog: TinyMCEDialog
): Promise<void> => {
  const latex = dialog.getData().latex;
  const mmlSpan = generateMathMLSpan(latex);

  editor.insertContent(mmlSpan.outerHTML);
  editor.windowManager.close();

  const mmlSpanWithBase64 = await addBase64Png(mmlSpan);
  await replaceMmlSpan(editor, mmlSpan, mmlSpanWithBase64);
};

export const updateTag = async (
  editor: Editor,
  dialog: TinyMCEDialog,
  element: HTMLElement
): Promise<void> => {
  const latex = dialog.getData().latex;
  const mmlSpan = generateMathMLSpan(latex);

  replaceMmlSpan(editor, element, mmlSpan);
  editor.windowManager.close();

  const mmlSpanWithBase64 = await addBase64Png(mmlSpan);
  replaceMmlSpan(editor, mmlSpan, mmlSpanWithBase64);
};

export const updateActiveState = (
  api: ToggleMenuItemInstanceApi,
  editor: Editor
): void => {
  const selectedNode = editor.selection.getNode();
  const mathMLSpanSelected =
    selectedNode?.classList.contains(LATEX_PLUGIN_CSS_CLASS) ?? false;

  api.setActive(mathMLSpanSelected);
};

export const openInsertModal = (editor: Editor): void => {
  editor.windowManager.open({
    ...generateFormConfig(editor),
    onChange: updateMathMLPreview,
    onSubmit: (dialog: TinyMCEDialog) => insertTag(editor, dialog)
  });
};

export const openEditModal = (element: HTMLElement, editor: Editor): void => {
  const mathMLEl = element.querySelector('math') as HTMLElement;

  if (mathMLEl === null) throw new Error('MathML element not found');

  const mathML = mathMLEl.outerHTML;
  const latex = mathMLEl.dataset.latex;

  editor.windowManager.open({
    ...generateFormConfig(editor, mathML),
    initialData: {
      latex
    },
    onChange: updateMathMLPreview,
    onSubmit: (dialog: TinyMCEDialog) => updateTag(editor, dialog, element)
  });
};
