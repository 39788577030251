angular.module('common').factory('Store', ['Reducers::Root', function (RootReducer) {
  var createStore = Redux.createStore;
  var applyMiddleware = Redux.applyMiddleware;

  var store = createStore(
    RootReducer
  );

  store.subscribeOn = function (valueFunction, callbackFunction) {
    var previousValue = valueFunction(store.getState()),
    result = store.subscribe(function () {
      var newValue = valueFunction(store.getState());
      if (previousValue !== newValue) {
        previousValue = newValue;
        callbackFunction(newValue);
      }
    });

    callbackFunction(previousValue);

    return result;
  };

  return store;
}]);
