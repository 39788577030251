import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Language } from '../../../../generated/base-types';
import { languageOptions } from '../../common/language-options';
import { emailValidator } from '../../form/utils/email.validator';
import { matchValidator } from '../../form/utils/match.validator';
import { requiredWhenOthersEmptyValidator } from '../../form/utils/required-when-others-empty.validator';
import { LoadUsersFieldsFragment } from '../../services/load-users.fragments.generated';

@Component({
  selector: 'man-user-form',
  templateUrl: './user-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent {
  // this is only called in the UserDetail component
  // in UserCreate the input is not even used and so this setter is not called
  @Input() public set user(value: LoadUsersFieldsFragment) {
    this.form.reset(value);
    this.form.get('password')!.removeValidators([Validators.required]);
    this.passwordFieldVisible = false;
  }
  @Input()
  public saving?: boolean;
  @Input()
  public loading?: boolean;
  @Input()
  public set disabled(value: boolean | undefined) {
    value === true ? this.form.disable() : this.form.enable();
  }

  @Output()
  public readonly save = new EventEmitter<LoadUsersFieldsFragment>();
  @Output()
  public readonly exit = new EventEmitter<void>();
  @Output()
  public readonly stateChange: Observable<boolean>;

  @ViewChild('passwordInput')
  public passwordInput: ElementRef<HTMLInputElement>;

  public readonly languageOptions = languageOptions;
  public form: FormGroup;
  public passwordFieldVisible = true;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.createForm();
    this.stateChange = this.form.valueChanges.pipe(
      map(() => this.form.dirty),
      distinctUntilChanged()
    );
  }

  public onSave(): void {
    if (
      this.loading === true ||
      this.saving === true ||
      !this.form.valid ||
      !this.form.dirty
    ) {
      return;
    }

    this.save.emit(this.form.value);
  }

  public onClose(): void {
    if (this.saving === true) return;

    this.exit.emit();
  }

  public showPasswordField(): void {
    this.passwordFieldVisible = true;
    setTimeout(() => {
      this.passwordInput.nativeElement.focus();
    }, 0);
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      id: [undefined],
      email: new FormControl('', [emailValidator, Validators.required]),
      alternativeEmail: new FormControl('', [emailValidator]),
      useEmailForMtan: new FormControl(true, [
        requiredWhenOthersEmptyValidator(
          'mobilePhone',
          'alternativeMobilePhone'
        )
      ]),
      password: new FormControl(undefined, [
        Validators.minLength(8),
        Validators.maxLength(128),
        matchValidator('passwordConfirmation', true),
        Validators.required
      ]),
      passwordConfirmation: new FormControl(undefined, [
        Validators.minLength(8),
        Validators.maxLength(128),
        matchValidator('password')
      ]),
      language: new FormControl(Language.En, [Validators.required]),
      superUser: new FormControl(false),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      mobilePhone: new FormControl(''),
      alternativeMobilePhone: new FormControl(''),
      organisation: new FormControl('')
    });
  }
}
