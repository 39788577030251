angular.module('common').factory('Selectors::AnnouncementList', [
  function() {
    function getItems(object) {
      return (object && object.announcementList && object.announcementList.items) || [];
    }

    return {
      getItems: getItems,
    };
  }
]);
