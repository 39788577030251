import { PoolInput, Scalars } from 'src/generated/base-types';
import { ManagerError } from '../../../../common/utils/error-parser';
import { PoolFormFragment } from '../../../../services/load-pool-form.generated';

export class LoadPool {
  public static readonly type = '[Pool-Form] Load Pools';
  constructor(public readonly id: Scalars['ID']) {}
}

export class LoadPoolSuccess {
  public static readonly type = '[Pool Form] Load Pools Success';
  constructor(public readonly pool: PoolFormFragment) {}
}

export class LoadPoolFailure {
  public static readonly type = '[Pool Form] Load Pools Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID']
  ) {}
}

export class CreatePool {
  public static readonly type = '[Pool Form] Create Pool';
  constructor(public readonly attributes: PoolInput) {}
}

export class CreatePoolSuccess {
  public static readonly type = '[Pool Form] Create Pool Success';
  constructor(public readonly pool: PoolFormFragment) {}
}

export class CreatePoolFailure {
  public static readonly type = '[Pool Form] Create Pool Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly attributes: PoolInput
  ) {}
}

export class UpdatePool {
  public static readonly type = '[Pool Form] Update Pool';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly attributes: PoolInput
  ) {}
}

export class UpdatePoolSuccess {
  public static readonly type = '[Pool Form] Update Pool Success';
  constructor(public readonly pool: PoolFormFragment) {}
}

export class UpdatePoolFailure {
  public static readonly type = '[Pool Form] Update Pool Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID'],
    public readonly attributes: PoolInput
  ) {}
}

export class ResetMutation {
  public static readonly type = '[Pool Form] Reset Mutation';
}

export class Reset {
  public static readonly type = '[Pool Form] Reset';
}
