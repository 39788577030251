<h5>
  {{ 'question_groups.edit.longmenu_solution.title' | translate }}&nbsp;{{
    index + 1
  }}
</h5>
<div class="entries">
  <ng-container *ngFor="let entry of responseSolutionEntries; let i = index">
    <button
      class="btn btn-primary-inverse"
      (click)="assignListEntry(i)"
      title="{{
        'question_groups.edit.longmenu_solution.select_solution_response'
          | translate
      }}"
      [disabled]="disabled || selectedEntryId === undefined"
    >
      <i class="fa fa-lg fa-arrow-circle-right"></i>
    </button>
    <div class="form-control">
      <ng-container *ngIf="entry">
        {{ entry.code }} {{ entry.name[language] }}
      </ng-container>
    </div>
    <button
      class="btn btn-primary-inverse"
      title="{{
        'question_groups.edit.longmenu_solution.remove_solution_response'
          | translate
      }}"
      [disabled]="disabled || entry === undefined"
      (click)="removeListEntry(i, entry?.id)"
    >
      <i class="fa fa-lg fa-minus-circle"></i>
    </button>
  </ng-container>
  <div class="content-column">
    <button
      class="btn btn-primary-inverse"
      title="{{
        'question_groups.edit.longmenu_solution.add_alternative' | translate
      }}"
      [disabled]="disabled || hasEmptyAlternative"
      (click)="addSolutionResponse()"
    >
      <i class="fa fa-lg fa-plus-circle"></i>
      <span>
        {{
          'question_groups.edit.longmenu_solution.add_alternative' | translate
        }}
      </span>
    </button>
  </div>
</div>
<button
  *ngIf="solutionRemovable"
  (click)="removeResponseSolution()"
  class="btn btn-secondary remove-btn"
  title="{{
    'question_groups.edit.longmenu_solution.remove_solution' | translate
  }}"
  [disabled]="disabled"
>
  <i class="fa fa-trash-o"></i>
</button>
