/* eslint-disable camelcase, unicorn/no-null */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  forwardRef
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import type { DndDropEvent } from 'ngx-drag-drop';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageVisibility } from 'src/app/state/settings.state';
import { QuestionInput } from 'src/generated/base-types';
import { FormResponseOptionInput } from '../form-types';
import { QuestionGroupFormComponent } from '../question-group-form/question-group-form.component';
import {
  isSecureIndexRange,
  moveObject,
  normalizeIndexRange,
  transformDropEventToIndexRange
} from '../services/drag-and-drop';
import { ValidationsQuestionAGraphQL } from '../services/graphql-question-group-types';

@Component({
  selector: 'qf-question-content-type-a-form',
  templateUrl: './question-content-type-a-form.component.html',
  styleUrls: ['./question-content-type-a-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionContentTypeAFormComponent),
      multi: true
    }
  ]
})
export class QuestionContentTypeAFormComponent
  implements OnDestroy, ControlValueAccessor
{
  @Input() public languageVisibility: LanguageVisibility;

  @Input()
  public set validations(value: ValidationsQuestionAGraphQL | undefined) {
    this._validations = value;
    this.validations$.next(value);
  }
  public get validations(): ValidationsQuestionAGraphQL | undefined {
    return this._validations;
  }

  public isRearranging = false;
  public form: UntypedFormGroup;

  private _validations: ValidationsQuestionAGraphQL | undefined;

  private question$ = new Subject<QuestionInput>();
  private validations$ = new Subject<ValidationsQuestionAGraphQL | undefined>();
  private destroy$ = new Subject<void>();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    public readonly builder: UntypedFormBuilder
  ) {
    this.form = this.builder.group({
      contentDe: null,
      contentFr: null,
      contentEn: null,
      contentIt: null,
      videoDe: null,
      videoFr: null,
      videoEn: null,
      videoIt: null,
      responseOptions: this.builder.array([])
    });

    setTimeout(() => {
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(changes => {
          this.onChange({
            video: {
              de: changes.videoDe,
              fr: changes.videoFr,
              en: changes.videoEn,
              it: changes.videoIt
            },
            a: {
              content: {
                de: changes.contentDe,
                fr: changes.contentFr,
                en: changes.contentEn,
                it: changes.contentIt
              },
              solution: changes.responseOptions.find(
                (responseOption: FormResponseOptionInput) =>
                  responseOption.correct
              )?.id,
              responseOptions: changes.responseOptions.map(
                (responseOption: FormResponseOptionInput) => ({
                  id: responseOption.id,
                  content: {
                    de: responseOption.contentDe,
                    fr: responseOption.contentFr,
                    en: responseOption.contentEn,
                    it: responseOption.contentIt
                  }
                })
              )
            }
          });
          this.onTouch();
        });
    }, QuestionGroupFormComponent.CHANGE_DETECTION_TIMEOUT);

    this.question$.pipe(takeUntil(this.destroy$)).subscribe(question => {
      this.responseOptionsForm.clear();
      (question.a?.responseOptions || []).forEach(() => {
        this.responseOptionsForm.push(
          this.builder.group({
            id: null,
            correct: null,
            contentDe: null,
            contentFr: null,
            contentEn: null,
            contentIt: null
          })
        );
      });
      this.form.setValue({
        contentDe: question.a?.content.de ?? null,
        contentFr: question.a?.content.fr ?? null,
        contentEn: question.a?.content.en ?? null,
        contentIt: question.a?.content.it ?? null,
        videoDe: question.video?.de ?? null,
        videoFr: question.video?.fr ?? null,
        videoEn: question.video?.en ?? null,
        videoIt: question.video?.it ?? null,
        responseOptions: (question.a?.responseOptions || []).map(option => ({
          id: option.id,
          correct: option.id === question.a?.solution,
          contentDe: option.content?.de ?? null,
          contentFr: option.content?.fr ?? null,
          contentEn: option.content?.en ?? null,
          contentIt: option.content?.it ?? null
        }))
      });
    });
  }

  public writeValue(question: QuestionInput): void {
    this.question$.next(question);
  }

  public registerOnChange(fn: (value: QuestionInput) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get responseOptionsForm(): UntypedFormArray {
    return this.form.get('responseOptions') as UntypedFormArray;
  }

  public responseOptionClicked(responseOptionFormGroup: AbstractControl): void {
    this.responseOptionsForm.controls.forEach(control => {
      if (control instanceof UntypedFormGroup) {
        control.get('correct')?.setValue(false);
      }
    });
    responseOptionFormGroup.get('correct')?.setValue(true);
  }

  public responseOptionMoved(event: DndDropEvent): void {
    this.isRearranging = true;
    this.changeDetector.detectChanges();

    const indexRange = transformDropEventToIndexRange(event);
    if (!isSecureIndexRange(indexRange)) {
      return;
    }

    const normalizedIndexRange = normalizeIndexRange(indexRange);
    this.moveResponseOption(normalizedIndexRange.from, normalizedIndexRange.to);

    this.isRearranging = false;
    this.changeDetector.detectChanges();
  }

  private moveResponseOption(from: number, to: number): void {
    if (this.validations?.responseOptions) {
      this.validations = {
        ...this.validations,
        responseOptions: moveObject(this.validations.responseOptions, from, to)
      };
    }

    const movedFormGroup = this.responseOptionsForm.at(from);
    this.responseOptionsForm.removeAt(from);
    this.responseOptionsForm.insert(to, movedFormGroup);
  }

  private onChange: (value: QuestionInput) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}
