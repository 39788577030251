import { UrlCreationOptions } from '@angular/router';
import { routes } from './app-routing.module';

export function isValidNG2Route(path: string): boolean {
  return routes.some(route => {
    return new RegExp(`^/${route.path}`).test(path);
  });
}

const ANGULAR2_STYLES_HREF = 'angular2-styles.css';
const OLD_STYLES_HREF = 'styles.css';
const NEW_STYLES_HREF = 'new-styles.css';

export function setStyles(url: string): void {
  addStyles(getStyleState(), url);
}

function addStyles(state: StyleState, url: string): void {
  if (isValidNG2Route(url)) {
    state.angular2StylesLinkTag?.remove();
    state.oldStylesLinkTag?.remove();
    addStyleLinkTag(NEW_STYLES_HREF);
  } else {
    state.newStylesLinkTag?.remove();
    addStyleLinkTag(ANGULAR2_STYLES_HREF);
    addStyleLinkTag(OLD_STYLES_HREF);
  }
}

interface StyleState {
  newStylesLinkTag: Element | null;
  angular2StylesLinkTag: Element | null;
  oldStylesLinkTag: Element | null;
}

function getStyleState(): StyleState {
  const newStylesLinkTag = getStyleLinkTagWithHref(NEW_STYLES_HREF);
  const angular2StylesLinkTag = getStyleLinkTagWithHref(ANGULAR2_STYLES_HREF);
  const oldStylesLinkTag = getStyleLinkTagWithHref(OLD_STYLES_HREF);

  return {
    newStylesLinkTag,
    angular2StylesLinkTag,
    oldStylesLinkTag
  };
}

function addStyleLinkTag(href: string): void {
  if (!getStyleLinkTagWithHref(href)) {
    const linkTag = document.createElement('link');
    linkTag.rel = 'stylesheet';
    linkTag.href = href;
    document.head.append(linkTag);
  }
}

function getStyleLinkTagWithHref(href: string): Element | null {
  return document.querySelector(`link[rel="stylesheet"][href="${href}"]`);
}

export function ng2LinkParamsParser(
  url: string
): (string[] | UrlCreationOptions)[] {
  const splitUri = url.split('?');
  const queryParams = new URLSearchParams(splitUri[1]);

  return [
    [splitUri[0]],
    {
      queryParams: Object.fromEntries(queryParams),
      queryParamsHandling: 'merge'
    }
  ];
}
