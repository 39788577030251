/* eslint-disable camelcase, unicorn/no-null */
import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AssetService, VideoMetadata } from 'src/app/services/asset.service';
import {
  ValidationsSeverity,
  ValidationsMessage,
  VideoAssetInput
} from 'src/generated/base-types';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../common/utils/ng-bootstrap-modal';
import { VideoModalComponent } from '../downgraded/components/video-modal.component';
import { VideoRemoveModalComponent } from '../downgraded/components/video-remove-modal.component';
import { NgxUploadAdapter } from './ngx-upload-adapter';

interface UploadResponse {
  id: string;
  metadata: VideoMetadata;
}

@Component({
  selector: 'qf-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VideoUploadComponent),
      multi: true
    }
  ]
})
export class VideoUploadComponent implements ControlValueAccessor {
  public value: VideoAssetInput | null = null;
  public validations: ValidationsMessage[] = [];
  public valueDescription: VideoDescription | null = null;
  public videoUrl = '';

  public videoUpload = new NgxUploadAdapter<UploadResponse>({
    uploaderOptions: {
      maxFileSize: 209_715_200, // 200mb
      allowedContentTypes: ['video/mp4', 'video/quicktime', 'video/avi']
    },
    request: {
      url: '/api/v1/videos',
      method: 'POST',
      fieldName: 'asset'
    }
  });
  public uploadingVideoFilename: string;

  public thumbnailUpload = new NgxUploadAdapter<UploadResponse>({
    uploaderOptions: {
      maxFileSize: 10_485_760, // 10mb
      allowedContentTypes: ['image/jpeg', 'image/png', 'image/gif']
    },
    request: {
      url: '/api/v1/assets',
      method: 'POST',
      fieldName: 'asset'
    }
  });
  public uploadingThumbnailFilename: string;

  constructor(
    public readonly ngbModal: NgbModal,
    private readonly assetService: AssetService,
    private readonly cdr: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
    private translate: TranslateService
  ) {
    this.videoUpload.registerOnStartUpload(name => {
      this.validations = [];
      this.uploadingVideoFilename = name;
    });
    this.videoUpload.registerOnFinishedUpload((name, response) => {
      this.setVideo(response.id, name);
      this.setVideoDescription(response.metadata);
    });
    this.videoUpload.registerOnError(error => {
      this.validations = [
        {
          severity: ValidationsSeverity.Error,
          message: error ?? this.translate.instant('video_upload.video_error')
        }
      ];
    });

    this.thumbnailUpload.registerOnStartUpload(name => {
      this.validations = [];
      this.uploadingThumbnailFilename = name;
    });
    this.thumbnailUpload.registerOnFinishedUpload((name, response) => {
      this.setThumbnail(response.id, name);
    });
    this.thumbnailUpload.registerOnError(error => {
      this.validations = [
        {
          severity: ValidationsSeverity.Error,
          message:
            error ?? this.translate.instant('video_upload.thumbnail_error')
        }
      ];
    });
  }

  public writeValue(value: VideoAssetInput | null): void {
    this.value = value;
    this.loadMetadata();
  }

  public registerOnChange(fn: (input: VideoAssetInput | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public removeFile(): void {
    const ref = this.ngbModal.open(
      VideoRemoveModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );
    ref.componentInstance.modalInstance = ref;

    ref.result
      .then(_result => {
        this.validations = [];
        this.value = null;
        this.onChange(this.value);
        this.onTouch();
        this.cdr.detectChanges();
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public async openVideo(): Promise<void> {
    if (!this.value) {
      return;
    }

    const meta = await this.assetService.getMetadata<VideoMetadata>(
      this.value.assetId
    );
    if (meta !== undefined && meta !== null) {
      const buffer = await this.assetService.getAsset(this.value.assetId);

      const video: Video = {
        mime: meta.mime,
        height: meta.imageHeight,
        width: meta.imageWidth,
        buffer: buffer,
        filename: `${this.value.filename
          .split('.')
          .slice(0, -1)
          .join('.')}-${this.datePipe.transform(Date.now(), 'yyyyMMdd-HHmm')}`
      };

      this.openModal(video);
    }
  }

  private setVideoDescription(meta: VideoMetadata | undefined): void {
    if (meta && this.value) {
      this.valueDescription = {
        filename: this.value.filename,
        size: meta.size,
        duration: meta.duration + '',
        resolution: `${meta.imageWidth} x ${meta.imageHeight}p`
      };
      this.cdr.detectChanges();
    }
  }

  private async loadMetadata(): Promise<void> {
    if (this.value) {
      this.valueDescription = null;
      this.videoUrl = this.assetService.getUrl(this.value.assetId);
      this.cdr.detectChanges();
      const meta = await this.assetService.getMetadata<VideoMetadata>(
        this.value.assetId
      );
      this.setVideoDescription(meta);
    }
  }

  private openModal(video: Video): void {
    const ref = this.ngbModal.open(
      VideoModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );

    ref.componentInstance.video = video;
    ref.componentInstance.modalInstance = ref;
  }

  private setVideo(id: string, filename: string): void {
    this.value = {
      assetId: id,
      filename: filename
    };
    this.videoUrl = this.assetService.getUrl(this.value.assetId);
    this.cdr.detectChanges();
    this.onChange(this.value);
    this.onTouch();
  }

  private setThumbnail(id: string, filename: string): void {
    if (this.value) {
      this.value = {
        ...this.value,
        thumbnailAssetId: id,
        thumbnailFilename: filename
      };
      this.onChange(this.value);
      this.onTouch();
    }
  }

  private onChange: (input: VideoAssetInput | null) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}

interface VideoDescription {
  filename: string;
  size: number;
  duration: string;
  resolution: string;
}

export interface Video {
  mime: string;
  width: number;
  height: number;
  buffer: ArrayBuffer;
  filename: string;
}
