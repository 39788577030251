angular.module('main').factory('Reducers::Root', [
  'Reducers::Common',
  'Reducers::SettingsRoot',
  'Reducers::TaskManagementRoot',
  'Reducers::ExamManagementRoot',
  function (
    CommonReducer,
    SettingsReducer,
    TaskManagementReducer,
    ExamManagementReducer
  ) {
    function reducer(state, action) {
      state = state || {};
      state = CommonReducer(state, action);
      state = SettingsReducer(state, action);
      state = TaskManagementReducer(state, action);
      state = ExamManagementReducer(state, action);
      return state;
    }

    return reducer;
  }
]);
