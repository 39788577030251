import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { UnsavedChangesModalComponent } from '../modals/unsaved-changes-modal/unsaved-changes-modal.component';

angular
  .module('common')
  .directive(
    'qfUnsavedChangesModal',
    downgradeComponent({ component: UnsavedChangesModalComponent })
  );
