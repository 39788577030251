<form
  class="page form"
  [ngClass]="{ loading: saving || loading }"
  [formGroup]="form"
  (submit)="onSave()"
  novalidate
>
  <div class="page-content">
    <fieldset class="form-fieldset grid">
      <legend class="form-legend g-col-4">
        {{ 'admin.users.form.tab.account' | translate }}
      </legend>
      <man-field
        class="g-col-4 g-col-md-2"
        labelFor="email"
        [label]="'admin.users.form.label.email' | translate"
        [errors]="form | fieldErrors : 'email'"
      >
        <input
          id="email"
          type="email"
          formControlName="email"
          class="form-control"
          cdkFocusInitial
        />
      </man-field>
      <man-field
        class="g-col-4 g-col-md-2"
        labelFor="alternative-email"
        [label]="'admin.users.form.label.alternative_email' | translate"
        [errors]="form | fieldErrors : 'alternativeEmail'"
      >
        <input
          id="alternative-email"
          type="email"
          formControlName="alternativeEmail"
          class="form-control"
      /></man-field>
      <man-field
        [errors]="form | fieldErrors : 'useEmailForMtan'"
        class="form-check g-col-4"
      >
        <input
          id="use-email-for-mtan"
          type="checkbox"
          formControlName="useEmailForMtan"
          class="form-check-input"
        />
        <label class="form-check-label" for="use-email-for-mtan">{{
          'admin.users.form.label.use_email_for_mtan' | translate
        }}</label>
      </man-field>
      <div class="g-col-4 my-2 mb-3" *ngIf="!passwordFieldVisible">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="showPasswordField()"
        >
          {{ 'admin.users.form.label.edit_password' | translate }}
        </button>
      </div>
      <ng-container *ngIf="passwordFieldVisible">
        <man-field
          class="g-col-4 g-col-md-2"
          labelFor="password"
          [label]="'admin.users.form.label.password' | translate"
          [errors]="form | fieldErrors : 'password'"
        >
          <input
            id="password"
            type="password"
            formControlName="password"
            class="form-control"
            #passwordInput
        /></man-field>
        <man-field
          class="g-col-4 g-col-md-2"
          labelFor="password-confirmation"
          [label]="'admin.users.form.label.password_confirmation' | translate"
          [errors]="form | fieldErrors : 'passwordConfirmation'"
        >
          <input
            id="password-confirmation"
            type="password"
            formControlName="passwordConfirmation"
            class="form-control"
        /></man-field>
      </ng-container>
    </fieldset>

    <fieldset class="form-fieldset grid">
      <legend class="form-legend g-col-4">
        {{ 'admin.users.form.tab.personal' | translate }}
      </legend>
      <man-field
        class="g-col-4 g-col-md-2"
        labelFor="first-name"
        [label]="'admin.users.form.label.first_name' | translate"
        [errors]="form | fieldErrors : 'firstName'"
        ><input
          id="first-name"
          type="text"
          formControlName="firstName"
          class="form-control"
        />
      </man-field>
      <man-field
        class="g-col-4 g-col-md-2"
        labelFor="last-name"
        [label]="'admin.users.form.label.last_name' | translate"
        [errors]="form | fieldErrors : 'lastName'"
        ><input
          id="last-name"
          type="text"
          formControlName="lastName"
          class="form-control"
        />
      </man-field>
      <man-field
        class="g-col-4"
        labelFor="organisation"
        [label]="'admin.users.form.label.organisation' | translate"
        ><input
          id="organisation"
          type="text"
          formControlName="organisation"
          class="form-control"
        />
      </man-field>
      <man-field
        class="g-col-4 g-col-md-2"
        labelFor="mobile-phone"
        [label]="'admin.users.form.label.mobile_phone' | translate"
        ><input
          id="mobile-phone"
          type="text"
          formControlName="mobilePhone"
          class="form-control"
        />
      </man-field>
      <man-field
        class="g-col-4 g-col-md-2"
        labelFor="alternative-mobile-phone"
        [label]="'admin.users.form.label.alternative_mobile_phone' | translate"
        ><input
          id="alternative-mobile-phone"
          type="text"
          formControlName="alternativeMobilePhone"
          class="form-control"
        />
      </man-field>
    </fieldset>

    <fieldset
      class="form-fieldset grid"
      style="--bs-columns: 4; --bs-gap: 0 1.5rem"
    >
      <legend class="form-legend g-col-4">
        {{ 'admin.users.form.tab.other' | translate }}
      </legend>
      <man-field
        class="g-col-4"
        labelFor="language"
        [label]="'admin.users.form.label.language' | translate"
      >
        <select id="language" formControlName="language" class="form-select">
          <option *ngFor="let option of languageOptions" [value]="option.value">
            {{ option.label | translate }}
          </option>
        </select></man-field
      >
      <man-field class="form-check g-col-4"
        ><input
          id="super-user"
          type="checkbox"
          formControlName="superUser"
          class="form-check-input"
        />
        <label for="super-user" class="form-check-label">{{
          'admin.users.form.label.super_user' | translate
        }}</label>
      </man-field>
    </fieldset>
  </div>
  <div class="page-footer">
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="
        !form.dirty || !form.valid || form.disabled || saving || loading
      "
    >
      <ng-container *ngIf="!saving && !loading; else loader">
        {{ 'actions.save' | translate }}
      </ng-container>
    </button>
  </div>
</form>
<ng-template #loader>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.loading' | translate }}
</ng-template>
