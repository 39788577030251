/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-null */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LabelValuePair } from 'src/app/common/ng-select-wrapper/ng-select-wrapper.component';
import { assertIsDefined } from '../../../common/utils/type-guards/is-defined';
import { AdvancedFilter } from '../../state/question-filter/question-filter.state.model';

enum CriterionType {
  Date = 'date',
  Select = 'select',
  Number = 'number',
  String = 'string',
  Boolean = 'boolean'
}

enum CombinationOperator {
  AND = 'and',
  OR = 'or'
}

@Component({
  selector: 'qm-question-group-filter-advanced',
  templateUrl: './question-group-filter-advanced.component.html',
  styleUrls: ['./question-group-filter-advanced.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionGroupFilterAdvancedComponent implements OnInit, OnDestroy {
  @Input() public name: string;
  @Input() public filter: AdvancedFilter;
  @Input() public isOpen: boolean;
  @Output() public isOpenChange = new EventEmitter<boolean>();
  @Output() public changed = new EventEmitter<AdvancedFilter['selection']>();

  public form = new UntypedFormGroup({
    criteria: new UntypedFormArray([])
  });
  public criterionType = CriterionType;
  public combinatorOptions: LabelValuePair<string>[];
  public booleanOptions: Record<string, string | boolean>[];
  private formSubscription: Subscription;

  constructor(private translate: TranslateService) {}

  public ngOnInit(): void {
    this.initCombinatorOptions();
    this.initBooleanOptions();
    this.initAdvancedFilterForm();

    this.formSubscription = this.form.valueChanges
      .pipe(
        map(data => data.criteria),
        distinctUntilChanged()
      )
      .subscribe(criteria => this.changed.emit([...criteria]));
  }

  public ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
  }

  public get criteriaFormArray(): UntypedFormArray {
    return this.form.get('criteria') as UntypedFormArray;
  }

  public toggleOpen(): void {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }

  public onAddCriterion(): void {
    this.criteriaFormArray.push(this.newCriterionFormGroup());
  }

  public onRemoveCriterion(index: number): void {
    this.criteriaFormArray.removeAt(index);
  }

  public onChangeCriterionType(criterionId: string, index: number): void {
    const critaria = this.criteriaFormArray.at(index);
    const combinationOperator = critaria.value.combinationOperator;

    const newValue = this.newCriterionFormGroup(
      criterionId,
      combinationOperator
    ).value;

    critaria.setValue(newValue);
  }

  public getCriterionOperators(index: number): Record<string, string>[] {
    return this.getCriterionParam(index).operators;
  }

  public getCriterionType(index: number): CriterionType {
    return this.getCriterionParam(index).type;
  }

  public getCriterionOptions(index: number): Record<string, string>[] {
    return this.getCriterionParam(index).options;
  }

  public reset(): void {
    // allows parent filter to reset the form
    // TODO: rewrite this logic so that we have one source of truth for the filter
    this.form.setControl('criteria', new UntypedFormArray([]));
  }

  private initAdvancedFilterForm(): void {
    this.reset();
    Object.keys(this.filter.selection).forEach(index => {
      const criterion = this.newCriterionFormGroup();
      criterion.setValue(this.filter.selection[+index]);
      this.criteriaFormArray.push(criterion);
    });
  }

  private getCriterionParam(index: number): any {
    const criterion = this.criteriaFormArray.get(`${index}`)?.value;

    assertIsDefined(criterion);

    return this.filter.options.find(({ id }: any) => id === criterion.id);
  }

  private newCriterionFormGroup(
    criterionId?: string,
    combinationOperator = CombinationOperator.AND
  ): UntypedFormGroup {
    const option =
      criterionId === undefined
        ? this.filter.options[0]
        : this.filter.options.find((option: any) => option.id === criterionId);

    return new UntypedFormGroup({
      combinationOperator: new UntypedFormControl(combinationOperator),
      id: new UntypedFormControl(option?.id ?? null),
      operator: new UntypedFormControl(option?.operators[0].value ?? null),
      value: new UntypedFormControl(null, [Validators.required])
    });
  }

  private initCombinatorOptions(): void {
    this.combinatorOptions = Object.values(CombinationOperator).map(value => ({
      value,
      label: this.translate.instant(
        `common.filter.combination_operators.${value}`
      )
    }));
  }

  private initBooleanOptions(): void {
    this.booleanOptions = [
      {
        value: true,
        label: this.translate.instant(`common.yes`)
      },
      {
        value: false,
        label: this.translate.instant(`common.no`)
      }
    ];
  }
}
