angular.module('settings').config([
  '$stateProvider',
  '$locationProvider',
  '$injector',
  function($stateProvider, $locationProvider, $injector) {
    $stateProvider
      .state('settings', {
        url: '/settings',
        views: {
          'main@': {
            template: require('./index.html')
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }]
      })

      .state('settings.pools', {
        url: '/pools',
        views: {
          'left@settings': {
            component: 'pools'
          },
          'center@settings': {
            template: require('./pools_center.html')
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
        resolve: {
          pools: [
            'Store',
            'Actions::PoolList',
            'Repositories::Pool',
            function(Store, Actions, Repository) {
              Store.dispatch(Actions.fetchPoolsRequest());
              return Repository.getAll().then(
                function(data) {
                  Store.dispatch(Actions.fetchPoolsSuccess(data));
                  return data;
                }.bind(this)
              );
            }
          ]
        }
      })
      .state('settings.pools.base', {
        url: '/:poolId',
        redirectTo: 'settings.pools.base.details',
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
      })
      .state('settings.pools.base.subjects', {
        url: '/subjects',
        views: {
          'subnavigation@settings.pools': {
            component: 'poolSubNavigation'
          },
          'details@settings.pools': {
            component: 'poolsSubjects'
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
      })
      .state('settings.pools.base.details', {
        url: '/details',
        views: {
          'subnavigation@settings.pools': {
            component: 'poolSubNavigation'
          },
          'details@settings.pools': {
            template: require('./pools_details.html')
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
      })
      .state('settings.pools.base.permissions', {
        url: '/permissions',
        views: {
          'subnavigation@settings.pools': {
            component: 'poolSubNavigation'
          },
          'details@settings.pools': {
            component: 'poolPermissions'
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
        resolve: {
          pool: [
            '$stateParams',
            'pools',
            function($stateParams, pools) {
              var poolId = parseInt($stateParams.poolId, 10);
              return pools.find(function (pool) {
                return pool.id === poolId;
              });
            }
          ]
        }
      })

      .state('settings.applications', {
        url: '/applications',
        views: {
          'left@settings': {
            component: 'oauthApplications'
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
        resolve: {
          applications: [
            'Store',
            'Actions::OauthApplicationList',
            'Repositories::OauthApplication',
            function(Store, Actions, Repository) {
              Store.dispatch(Actions.fetchApplicationsRequest());
              return Repository.getAll().then(function(applications) {
                Store.dispatch(Actions.fetchApplicationsSucccess(applications));
                return applications;
              });
            }
          ]
        }
      })
      .state('settings.applications.new', {
        url: '/new',
        views: {
          'left@settings': {
            component: 'oauthApplications'
          },
          'center@settings': {
            component: 'oauthApplicationDetails'
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
        resolve: {
          application: function() {
            return {
              name: '',
              redirect_uri: '',
              confidential: false
            };
          }
        }
      })
      .state('settings.applications.edit', {
        url: '/edit/:uid',
        views: {
          'left@settings': {
            component: 'oauthApplications'
          },
          'center@settings': {
            component: 'oauthApplicationDetails'
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
        resolve: {
          application: [
            '$stateParams',
            'applications',
            'Store',
            'Selectors::OauthApplicationList',
            function($stateParams, applications, Store, Selectors) {
              return Object.assign(
                {},
                Selectors.getItems(Store.getState()).find(function(item) {
                  return item.uid === $stateParams.uid;
                })
              );
            }
          ]
        }
      })

      .state('settings.users', {
        url: '/users',
        views: {
          'left@settings': {
            component: 'settingsUsers'
          },
          'center@settings': {
            template: require('./users_center.html')
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
        resolve: {
          users: [
            'Repositories::User',
            function (Repository) {
              return Repository.getAll();
            }
          ]
        }
      })
      .state('settings.users.edit', {
        url: '/:id',
        views: {
          'subnavigation@settings.users': {
            component: 'userSubNavigation'
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }]
      }).
      state('settings.users.edit.profile', {
        url: '/profile',
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }]
      }).
      state('settings.users.edit.permissions', {
        url: '/permissions',
        views: {
          'details@settings.users': {
            component: 'settingsUserPermissions'
          }
        },
        authorize: ['Role', 'user', function(Role, user) {
          return Role.isSuperUser(user);
        }],
        resolve: {
          user: [
            'users',
            '$stateParams',
            function (users, $stateParams) {
              var userId = parseInt($stateParams.id, 10);
              return users.find(function (user) {
                return user.id === userId;
              });
            }
          ]
        }
      });

    $locationProvider.html5Mode(true);
  }
]);
