angular.module('examManagement').factory('Reducers::ExamQuestionGroupList', [
  'Actions::ExamQuestionGroupList',
  function (Actions) {
    function replaceExamQuestionGroups(booklet, examQuestionGroups, sortingAttribute) {
      var examQuestionGroupsById = examQuestionGroups.reduce(function (object, examQuestionGroup) {
        object[examQuestionGroup.id] = examQuestionGroup;
        return object;
      }, {});
      var newExamQuestionGroups = booklet.exam_question_groups.map(function (existingExamQuestionGroup) {
        if (examQuestionGroupsById[existingExamQuestionGroup.id] !== undefined) {
          return Object.assign({}, existingExamQuestionGroup, examQuestionGroupsById[existingExamQuestionGroup.id]);
        } else {
          return existingExamQuestionGroup;
        }
      });
      newExamQuestionGroups.sort(function (a, b) {
        return a[sortingAttribute] - b[sortingAttribute];
      });
      return Object.assign({}, booklet, {
        exam_question_groups: newExamQuestionGroups
      });
    }

    function reducer (state, action) {
      var columns, selection;

      state = state || {};

      switch (action.type) {
      case Actions.SET_REQUEST_IN_PROGRESS:
        return Object.assign({}, state, {requestInProgress: action.requestInProgress});

      case Actions.FETCH_EXAM_QUESTION_GROUP_CLUSTERS_REQUEST:
        return Object.assign({}, state, {requestInProgress: true});

      case Actions.FETCH_EXAM_QUESTION_GROUP_CLUSTERS_SUCCESS:
        return Object.assign({}, state, {
          bookletA: action.exam.booklet_a,
          bookletB: action.exam.booklet_b,
          scrambling: action.scrambling,
          requestInProgress: false,
          selection: (action.exam.booklet_a &&
                      action.exam.booklet_a.exam_question_groups[0] &&
                      action.exam.booklet_a.exam_question_groups[0].id)
        });

      case Actions.SET_COLUMN_DEFINITIONS:
        return Object.assign({}, state, {
          columns: action.columns
        });

      case Actions.UPDATE_COLUMN_DEFINITIONS:
        columns = Object.assign({}, state.columns, action.columns);
        return Object.assign({}, state, {
          columns: columns
        });

      case Actions.SET_COLUMN_VISIBILITY:
        columns = Object.assign({}, state.columns);
        columns[action.column].visible = action.visible;
        return Object.assign({}, state, {
          columns: columns
        });

      case Actions.ENABLE_COLUMN:
        columns = Object.assign({}, state.columns);
        columns[action.column].enabled = true;
        return Object.assign({}, state, {columns: columns});

      case Actions.DISABLE_COLUMN:
        columns = Object.assign({}, state.columns);
        columns[action.column].enabled = false;
        return Object.assign({}, state, {columns: columns});

      case Actions.SET_SELECTION:
        return Object.assign({}, state, {
          selection: action.id
        });

      case Actions.SET_SCRAMBLING:
        return Object.assign({}, state, {
          scrambling: action.scrambling
        });

      case Actions.UPDATE_EXAM_QUESTION_GROUPS:
        return Object.assign({}, state, {
          bookletA: replaceExamQuestionGroups(state.bookletA, action.examQuestionGroups, 'position_a'),
          bookletB: replaceExamQuestionGroups(state.bookletB, action.examQuestionGroups, 'position_b')
        });

      case Actions.DELETE_EXAM_QUESTION_GROUP:
        var exam = {
          bookletA: {
            exam_question_groups: state.bookletA.exam_question_groups.filter(function (item) {
              return item.id !== action.examQuestionGroupId && item.id !== undefined;
            })
          },
          bookletB: {
            exam_question_groups: state.bookletB.exam_question_groups.filter(function (item) {
              return item.id !== action.examQuestionGroupId && item.id !== undefined;
            })
          }
        };

        return Object.assign({}, state, {
          bookletA: exam.bookletA,
          bookletB: exam.bookletB,
          selection: (exam["booklet" + state.scrambling].exam_question_groups[0] &&
                      exam["booklet" + state.scrambling].exam_question_groups[0].id)
        });

      case Actions.REMOVE_SELECTION:
        return Object.assign({}, state, {
          selection: undefined
        });

      default:
        return state;
      }
    }

    return reducer;
  }
]);
