angular.module('common').factory('Repositories::Comment', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      updateQuery =
        'mutation($comment_id: Int!, $read: Boolean!) {' +
        '  update_comment(comment_id: $comment_id, read: $read) {comment {id read}}' +
        '}',
      deleteQuery = 'mutation($comment_id: Int!) {delete_comment(comment_id: $comment_id) {comment {id}}}';

    return {
      update: function(id, read) {
        return client
          .query(updateQuery, { comment_id: id, read })
          .then(function(response) {
            return response.data.data.update_comment;
          });
      },

      delete: function(id) {
        return client.query(deleteQuery, { comment_id: id }).then(function(response) {
          return response.data.data.delete_comment;
        });
      }
    };
  }
]);
