<ng-container *ngIf="loading">
  <p class="centered">
    {{ 'common.question_group_details.preview.in_work' | translate }}
  </p>
  <co-loader></co-loader>
</ng-container>
<ngb-accordion
  *ngIf="!loading"
  [closeOthers]="false"
  [activeIds]="visibleLanguages"
  (panelChange)="previewVisibilityChanged($event)"
>
  <ngb-panel id="de" *ngIf="url?.de !== undefined && url?.de !== null">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link text-left full-width">
        {{ 'scrudu.languages.long.de' | translate }}
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <img [src]="url?.de" class="full-width layout-cell--top-small-spaced" />
    </ng-template>
  </ngb-panel>
  <ngb-panel id="fr" *ngIf="url?.fr !== undefined && url?.fr !== null">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link text-left full-width">
        {{ 'scrudu.languages.long.fr' | translate }}
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <img [src]="url?.fr" class="full-width layout-cell--top-small-spaced" />
    </ng-template>
  </ngb-panel>
  <ngb-panel id="en" *ngIf="url?.en !== undefined && url?.en !== null">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link text-left full-width">
        {{ 'scrudu.languages.long.en' | translate }}
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <img [src]="url?.en" class="full-width layout-cell--top-small-spaced" />
    </ng-template>
  </ngb-panel>
  <ngb-panel id="it" *ngIf="url?.it !== undefined && url?.it !== null">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link text-left full-width">
        {{ 'scrudu.languages.long.it' | translate }}
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <img [src]="url?.it" class="full-width layout-cell--top-small-spaced" />
    </ng-template>
  </ngb-panel>
</ngb-accordion>
