<ng-container [formGroup]="form">
  <div
    class="layout-cell skin-container-cell--filled-light skin-large-text pv-detail__content__sub-content"
  >
    <qf-question-group-metadata
      formControlName="metadata"
      [affiliations]="affiliations"
      [supervisors]="supervisors"
      [authors]="authors"
      [languageVisibility]="languageVisibility"
      (languageVisibilityChanged)="
        languageVisibilityChange($event.language, $event.visible)
      "
      [languages]="languages"
      [validations]="validations"
      [questionGroupType]="questionGroupType"
      [questionsSubject]="questions$"
    ></qf-question-group-metadata>
  </div>

  <qf-question-tabs
    class="layout-cell--vertically-scrollable layout-cell--resizable"
    formControlName="questions"
    [questionGroupType]="questionGroupType"
    [validations]="validations"
    [dimensions]="dimensions"
    [languageVisibility]="languageVisibility"
    [languages]="languages"
    [sequentialNumber]="sequentialNumber"
    [sourceLanguage]="sourceLanguage"
  ></qf-question-tabs>
</ng-container>
