angular.module('common').directive('resizePanel', [ '$cookies', '$timeout', '$rootScope', function($cookies, $timeout, $rootScope) {
    return {
      restrict: 'A',
      replace: false,
      scope: true,
      link: function($scope, $element, $attrs) {
        var isLeftOf = function(element, otherElement) {
          return element[0].getBoundingClientRect().left < otherElement[0].getBoundingClientRect().left;
        };

        var storeSettings = function(width) {
          $cookies.putObject(storageKey(), width);
          return $scope.$apply();
        };

        var storageKey = function() {
          return "src.panel.width." + $attrs.resizePanel;
        };

        $timeout(function() {
          var elementToResize = $element.siblings($attrs.resizePanel);
          var resizeDirection = isLeftOf($element, elementToResize) ? 'rtl' : 'ltr';
          var htmlElement = angular.element(document.body.parentElement);
          var lastX = null;

          if ($cookies.getObject(storageKey())) {
            elementToResize.width($cookies.getObject(storageKey()));
          }

          var resizeEvent = function(event) {
            var changedWidth, differenceHandlerAndMouse, newWidth;
            if (lastX !== null && !elementToResize.hasClass('pv-panel--hidden')) {
              changedWidth = lastX - event.originalEvent.clientX;
              if (resizeDirection === 'ltr') {
                changedWidth = -changedWidth;
              }
              newWidth = elementToResize.width() + changedWidth;
              storeSettings(newWidth);
              elementToResize.width(newWidth);
              $rootScope.$broadcast('panel.resize');
            }
            differenceHandlerAndMouse = event.originalEvent.clientX - $element[0].offsetLeft;
            if (!(differenceHandlerAndMouse > $element.width() || differenceHandlerAndMouse < 0)) {
              return lastX = event.originalEvent.clientX;
            }
          };

          $element.bind('mousedown touchstart', function(e) {
            e.preventDefault();
            e.stopPropagation();
            htmlElement.bind('mousemove touchmove', resizeEvent);
            return false;
          });
          htmlElement.bind('mouseup touchend', function() {
            htmlElement.unbind('mousemove touchmove', resizeEvent);
          });
        }, 500);
      }
    };
  }
]);
