<div class="long-menu-list-container__actions" *ngIf="list">
  <div>
    <button (click)="fileInput.click()" class="btn btn-primary">
      <i class="fa fa-upload"></i>
      {{ 'question_groups.edit.long_menu_list.update_list' | translate }}
    </button>

    <button
      (click)="removeList()"
      class="btn btn-secondary-migrated remove-btn"
      title="{{
        'question_groups.edit.longmenu_solution.remove_title' | translate
      }}"
    >
      <i class="fa fa-trash-o"></i>
    </button>
  </div>

  <co-radio-button-group
    id="questionType"
    [ngModel]="listLanguage"
    (ngModelChange)="setListLanguage($event)"
  >
    <ng-container *ngFor="let language of sheetLanguages">
      <button [value]="language" class="btn btn-secondary-migrated">
        {{ 'scrudu.languages.long.' + language | translate }}
      </button>
    </ng-container>
  </co-radio-button-group>
</div>

<h4 class="long-menu-list-container__title">
  {{ 'question_groups.edit.long_menu_list.title' | translate }}
</h4>

<input
  hidden
  #fileInput
  type="file"
  ngFileSelect
  [options]="options"
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="uploadInput"
  [multiple]="false"
/>

<div
  class="card long-menu-list-container__content"
  #dropContainer
  ngFileDrop
  [options]="options"
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="uploadInput"
  [ngClass]="{
    'is-dragging-over': dragOver
  }"
>
  <div class="filter-container" *ngIf="filteredList">
    <input
      #keywordInput
      class="filter-input"
      name="long_menu_list_keyword"
      placeholder="{{
        'question_groups.edit.long_menu_list.search_hint' | translate
      }}"
      (keyup)="search(keywordInput.value)"
      [ngModel]="filterKeyword"
    />
    <i *ngIf="filterKeyword" class="clear-input-icon" (click)="clearSearch()"
      >×</i
    >
  </div>

  <div class="scrollable-container">
    <div class="card-body file-selection text-center" *ngIf="!filteredList">
      <div class="text-center">
        <div>
          {{ 'question_groups.edit.long_menu_list.drag_here' | translate }}
        </div>
        <div>{{ 'common.or' | translate }}</div>
        <button (click)="fileInput.click()" class="btn btn-primary">
          <i class="fa fa-upload"></i>
          {{ 'question_groups.edit.long_menu_list.upload_list' | translate }}
        </button>
      </div>
    </div>

    <ng-container *ngIf="filteredList">
      <div class="card-body file-selection" *ngIf="filteredList.length === 0">
        {{
          'question_groups.edit.long_menu_list.no_results_found'
            | translate : { keyword: filterKeyword }
        }}
      </div>

      <table
        class="table entries-table card-body"
        *ngIf="filteredList.length > 0"
      >
        <thead>
          <tr>
            <th class="header-cell">
              {{
                'question_groups.edit.long_menu_list.headers.code' | translate
              }}
            </th>
            <th class="header-cell">
              {{
                'question_groups.edit.long_menu_list.headers.position'
                  | translate
              }}
            </th>
            <th class="header-cell">
              {{
                'question_groups.edit.long_menu_list.headers.content'
                  | translate
              }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let listEntry of filteredList"
            [ngClass]="{
              'entry-row__selected': selectedEntryId === listEntry.id,
              disabled: isUsedInSolution(listEntry.id)
            }"
            class="entry-row"
            (click)="setSelectedEntryId(listEntry.id)"
          >
            <td class="entry-cell">
              {{ listEntry.code }}
            </td>
            <td class="entry-cell">
              {{ listEntry.position }}
            </td>
            <td class="entry-cell">
              {{ listEntry.name[listLanguage] }}
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>

<div class="long-menu-list-container__list-info">
  <small class="text-muted">
    <span *ngIf="filterKeyword && filteredList && list">{{
      'question_groups.edit.long_menu_list.results_found'
        | translate
          : { foundItems: filteredList.length, totalItems: list.length }
    }}</span>

    <span *ngIf="!filterKeyword && list"
      >{{ list.length }}
      {{ 'question_groups.edit.long_menu_list.entries' | translate }}</span
    >
  </small>
</div>

<qf-input-feedback
  [validations]="validations"
  [inputField]="dropContainer"
  [inputValue]="list"
></qf-input-feedback>
