import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { isVoid } from '../common/utils/type-guards/voidable';
import { LoadProfile } from './state/profile-form.actions';
import { ProfileFormState } from './state/profile-form.state';

@Injectable({ providedIn: 'root' })
export class ProfileGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    const profile = this.store.selectSnapshot(ProfileFormState.profile);

    if (isVoid(profile)) {
      this.store.dispatch(new LoadProfile());
    }

    return true;
  }
}
