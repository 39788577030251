import { isNotUndefined } from 'src/app/common/utils/type-guards/is-not-undefined';
import { FilterQueryItem } from 'src/generated/base-types';
import {
  AdvancedFilterValue,
  QuestionFilters,
  SimpleFilter
} from './question-filter.state.model';

export enum TreeCompositeNodeOperator {
  AND = 'AND',
  OR = 'OR'
}

export type TreeValueNodeOperator =
  | TreeEqualityOperator
  | TreeStringOperator
  | TreeNumberOperator;

enum TreeEqualityOperator {
  EQUAL = '=',
  UNEQUAL = '!='
}

enum TreeStringOperator {
  EQUAL = '=',
  UNEQUAL = '!=',
  INCLUDES = 'includes'
}

enum TreeNumberOperator {
  EQUAL = '=',
  UNEQUAL = '!=',
  GREATER_THAN = '>',
  LESS_THAN = '<',
  GREATER_THAN_OR_EQUAL = '<=',
  LESS_THAN_OR_EQUAL = '>='
}

export type TreeNode = TreeValueNode | TreeCompositeNode;

export interface TreeValueNode {
  name: string;
  value: string | number | boolean | null;
  operator: TreeValueNodeOperator;
}

export interface TreeCompositeNode {
  children: TreeNode[];
  operator: TreeCompositeNodeOperator;
}

const CATEGORY_MAP: { [key: string]: string } = {
  dimensions: 'dimension',
  exams: 'exam',
  labels: 'label',
  contentValidationStates: 'language',
  supervisors: 'supervisor',
  affiliations: 'affiliation',
  revisionStatus: 'revision_status',
  questionTypes: 'question_type'
};

export function getQueryTreeValueNode(query: string): TreeValueNode {
  return {
    operator: TreeEqualityOperator.EQUAL,
    name: 'query',
    value: query || ''
  };
}

export function getAdvancedFilterTreeCompositeNode(
  advancedFilterSelection: AdvancedFilterValue[]
): TreeCompositeNode | undefined {
  if (advancedFilterSelection === undefined) {
    return;
  }

  const internalAdvancedFilterSelection = [...advancedFilterSelection];
  internalAdvancedFilterSelection.reverse();

  let treeCompositeNode: TreeCompositeNode | undefined;

  let currentChildrenReference: TreeNode[];

  internalAdvancedFilterSelection.forEach((advancedFilterValue, index) => {
    if (index === 0) {
      treeCompositeNode =
        createTreeCompositeNodeFromAdvancedFilterValue(advancedFilterValue);

      currentChildrenReference = treeCompositeNode.children;

      return;
    }

    if (index === advancedFilterSelection.length - 1) {
      currentChildrenReference.push(
        createTreeValueNodeFromAdvancedFilterValue(advancedFilterValue)
      );

      return;
    }

    const newCompositeNode: TreeCompositeNode =
      createTreeCompositeNodeFromAdvancedFilterValue(advancedFilterValue);

    currentChildrenReference.push(newCompositeNode);

    currentChildrenReference = newCompositeNode.children;
  });

  return treeCompositeNode as TreeCompositeNode;
}

export function getSimpleFiltersTreeCompositeNodes(
  simpleFilters: SimpleFilter[]
): TreeCompositeNode[] | undefined {
  if (simpleFilters === undefined) {
    return;
  }

  const treeCompositeNodes: TreeCompositeNode[] = [];

  simpleFilters.forEach(filter => {
    const category = filter.category;
    const selection = filter.selection.filter(isNotUndefined);

    const children: TreeValueNode[] = [];
    selection.forEach(value => {
      children.push(
        createTreeValueNode(
          CATEGORY_MAP[category],
          value,
          TreeEqualityOperator.EQUAL
        )
      );
    });

    const treeCompositeNodeOperator =
      category === 'dimensions'
        ? TreeCompositeNodeOperator.AND
        : TreeCompositeNodeOperator.OR;

    treeCompositeNodes.push(
      createTreeCompositeNode(treeCompositeNodeOperator, children)
    );
  });

  return treeCompositeNodes;
}

export function buildFilterQueryItemFromQuestionFilters(
  questionFilters: QuestionFilters
): FilterQueryItem {
  const queryTreeValueNode = getQueryTreeValueNode(questionFilters?.query);
  const advancedFilterTreeCompositeNode = getAdvancedFilterTreeCompositeNode(
    questionFilters?.advancedFilter?.selection
  );
  const simpleFiltersTreeCompositeNodes = getSimpleFiltersTreeCompositeNodes(
    questionFilters?.simpleFilters
  );

  const tree: TreeCompositeNode = {
    operator: TreeCompositeNodeOperator.AND,
    children: [queryTreeValueNode]
  };

  if (advancedFilterTreeCompositeNode) {
    tree.children.push(advancedFilterTreeCompositeNode);
  }

  if (simpleFiltersTreeCompositeNodes) {
    tree.children.push(...simpleFiltersTreeCompositeNodes);
  }

  return tree;
}

function createTreeValueNode(
  name: string,
  value: string | number | boolean | null,
  operator: TreeValueNodeOperator
): TreeValueNode {
  return {
    name,
    value,
    operator
  };
}

function createTreeValueNodeFromAdvancedFilterValue(
  advancedFilterValue: AdvancedFilterValue
): TreeValueNode {
  return createTreeValueNode(
    advancedFilterValue.id,
    advancedFilterValue.value,
    advancedFilterValue.operator as TreeValueNodeOperator
  );
}

function createTreeCompositeNode(
  combinationOperator: TreeCompositeNodeOperator,
  children?: TreeNode[]
): TreeCompositeNode {
  const operator =
    combinationOperator.toUpperCase() === TreeCompositeNodeOperator.AND
      ? TreeCompositeNodeOperator.AND
      : TreeCompositeNodeOperator.OR;

  const treeCompositeNode: TreeCompositeNode = {
    operator,
    children: []
  };

  if (children) {
    treeCompositeNode.children.push(...children);
  }

  return treeCompositeNode;
}

function createTreeCompositeNodeFromAdvancedFilterValue(
  advancedFilterValue: AdvancedFilterValue
): TreeCompositeNode {
  return createTreeCompositeNode(
    advancedFilterValue.combinationOperator as TreeCompositeNodeOperator,
    [{ ...createTreeValueNodeFromAdvancedFilterValue(advancedFilterValue) }]
  );
}
