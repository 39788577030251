import {
  Scalars,
  Pool,
  Language,
  Affiliation,
  QuestionType,
  QuestionGroupType,
  QuestionGroupInput
} from 'src/generated/base-types';
import { ManagerError } from '../../common/utils/error-parser';
import { FormDimension } from '../form-types';

export class LoadFormOptions {
  public static readonly type = '[Form] Load Form Options';

  constructor(public readonly id: Pool['id']) {}
}

export class LoadFormOptionsSuccess {
  public static readonly type = '[Form] Load Form Options Success';

  constructor(
    public readonly languages: Language[],
    public readonly affiliations: Affiliation[],
    public readonly dimensions: FormDimension[],
    public readonly authors: string[],
    public readonly supervisors: string[]
  ) {}
}

export class LoadFormOptionsFailure {
  public static readonly type = '[Form] Load Form Options Failure';
  constructor(public readonly error: ManagerError) {}
}

export class LoadNewQuestionGroupFromExistingFormData {
  public static readonly type =
    '[Form] Load New Question Group From Existing Form Data';
  constructor(public readonly questionGroupInput: QuestionGroupInput) {}
}

export class LoadNewQuestionGroup {
  public static readonly type = '[Form] Load New Question Group';
  constructor(
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly questionType: QuestionType,
    public readonly questionGroupType: QuestionGroupType
  ) {}
}

export class LoadExistingQuestionGroup {
  public static readonly type = '[Form] Load Existing Question Group';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly poolId: Scalars['ID'],
    public readonly isDuplicate: boolean = false
  ) {}
}

export class SetUploadingImage {
  public static readonly type = '[QuestionManagement Form] Set Uploading Image';
  constructor(public readonly uploading: boolean) {}
}
