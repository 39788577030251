angular.module('settings').factory('Repositories::OauthApplication', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getAllQuery =
        'query {' +
        '  oauth_applications {' +
        '    uid name redirect_uri confidential secret' +
        '  }' +
        '}',
      createQuery =
        'mutation($name: String!, $redirect_uri: String!, $confidential: Boolean!) {' +
        '  create_oauth_application(name: $name, redirect_uri: $redirect_uri, confidential: $confidential) {' +
        '    oauth_application {' +
        '      uid name redirect_uri confidential secret' +
        '    }' +
        '    erroneous errors {erroneous field_messages {' +
        '      name {content type} redirect_uri {content type}' +
        '    }}' +
        '  }' +
        '}',
      updateQuery =
        'mutation($uid: String!, $name: String!, $redirect_uri: String!, $confidential: Boolean!) {' +
        '  update_oauth_application(uid: $uid, name: $name, redirect_uri: $redirect_uri, confidential: $confidential) {' +
        '    oauth_application {' +
        '      uid name redirect_uri confidential secret' +
        '    }' +
        '    erroneous errors {erroneous field_messages {' +
        '      name {content type} redirect_uri {content type}' +
        '    }}' +
        '  }' +
        '}',
      deleteQuery =
        'mutation($uid: String!) {delete_oauth_application(uid: $uid) {' +
        '  oauth_application {uid}' +
        '  erroneous' +
        '}}';

    return {
      getAll: function() {
        return client.query(getAllQuery).then(function(response) {
          return response.data.data.oauth_applications;
        });
      },

      create: function(application) {
        return client
          .query(createQuery, {
            name: application.name,
            redirect_uri: application.redirect_uri,
            confidential: application.confidential
          })
          .then(function(response) {
            return response.data.data.create_oauth_application;
          });
      },

      update: function(application) {
        return client
          .query(updateQuery, {
            uid: application.uid,
            name: application.name,
            redirect_uri: application.redirect_uri,
            confidential: application.confidential
          })
          .then(function(response) {
            return response.data.data.update_oauth_application;
          });
      },

      delete: function(uid) {
        return client.query(deleteQuery, { uid: uid }).then(function(response) {
          return response.data.data.delete_oauth_application;
        });
      }
    };
  }
]);
