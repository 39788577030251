/* eslint-disable camelcase, unicorn/no-null */
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LabelValuePair } from 'src/app/common/ng-select-wrapper/ng-select-wrapper.component';
import {
  Language,
  LongMenuListEntryInput,
  Maybe,
  ValidationsMessage
} from '../../../../generated/base-types';

const MAXIMUM_NUMBER_OF_RESPONSES = 20;
type LongMenuSolutionInput = Maybe<number>[][];

@Component({
  selector: 'qf-long-menu-solution-container',
  templateUrl: './long-menu-solution-container.component.html',
  styleUrls: ['./long-menu-solution-container.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LongMenuSolutionContainerComponent),
      multi: true
    }
  ]
})
export class LongMenuSolutionContainerComponent
  implements ControlValueAccessor, OnInit
{
  @Input() public validations: ValidationsMessage[] | undefined;

  @Input()
  public list: LongMenuListEntryInput[] | null;

  @Input()
  public selectedEntryId: number | undefined;

  @Input()
  public language: Language;

  public numberOfResponses: number;
  public solution: LongMenuSolutionInput;
  public readonly numberResponsesList: LabelValuePair<number>[] = [];

  public ngOnInit(): void {
    for (let i = 0; i < MAXIMUM_NUMBER_OF_RESPONSES; i++) {
      this.numberResponsesList.push({ label: i + 1 + '', value: i + 1 });
    }
  }

  public addResponseSolution(): void {
    this.solution = [...this.solution, [null]];
    this.onChange([this.numberOfResponses, this.solution]);
    this.onTouch();
  }

  public removeResponseSolution(index: number): void {
    this.solution.splice(index, 1);
    this.onChange([this.numberOfResponses, this.solution]);
    this.onTouch();
  }

  public changeResponseSolution(index: number, value: Maybe<number>[]): void {
    const updatedSolution = this.solution.slice();
    updatedSolution[index] = value;
    this.solution = updatedSolution;
    this.onChange([this.numberOfResponses, updatedSolution]);
    this.onTouch();
  }

  public setNumberOfResponses(n: number | null): void {
    this.numberOfResponses = n ?? 0;

    while (this.numberOfResponses > this.solution.length) {
      this.solution = [...this.solution, []];
    }

    this.onChange([this.numberOfResponses, this.solution]);
    this.onTouch();
  }

  public writeValue([numberOfResponses, solution]: [
    number,
    LongMenuSolutionInput
  ]): void {
    this.solution = solution;
    this.setNumberOfResponses(numberOfResponses);
  }

  public registerOnChange(
    fn: (value: [number, LongMenuSolutionInput]) => void
  ): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  private onChange: (value: [number, LongMenuSolutionInput]) => void = () =>
    void 0;
  private onTouch: () => void = () => void 0;
}
