import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/admin/common/modal/modal.service';
import { RevisionStatus } from 'src/generated/base-types';

@Component({
  selector: 'man-revision-status-form',
  templateUrl: './revision-status-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RevisionStatusFormComponent implements OnInit {
  @Input() public revisionStatus?: Partial<RevisionStatus>;
  @Input() public set disabled(value: boolean | undefined) {
    value === true ? this.form?.disable() : this.form?.enable();
  }
  public get disabled(): boolean {
    return this.form?.disabled ?? true;
  }

  @Output() public create = new EventEmitter<
    Pick<RevisionStatus, 'shortName' | 'description'>
  >();
  @Output() public update = new EventEmitter<
    Pick<RevisionStatus, 'id' | 'shortName' | 'description'>
  >();
  @Output() public closed = new EventEmitter<void>();

  public form: FormGroup | undefined;

  public get edit(): boolean {
    return this.revisionStatus !== undefined;
  }

  constructor(
    public translate: TranslateService,
    private readonly modalService: ModalService
  ) {}

  public ngOnInit(): void {
    this.initialiseForm();
  }

  public async onClose(): Promise<void> {
    if (this.disabled) return;
    await this.safeClose();
  }

  public onSave(): void {
    if (!(this.form?.valid ?? false) || !(this.form?.dirty ?? false)) return;

    this.revisionStatus === undefined
      ? this.create.emit(this.form?.value)
      : this.update.emit({ id: this.revisionStatus.id, ...this.form?.value });
  }

  private initialiseForm(): void {
    this.form = new FormGroup({
      shortName: new FormControl<RevisionStatus['shortName']>('', [
        Validators.required
      ]),
      description: new FormControl<RevisionStatus['description']>('', [
        Validators.required
      ])
    });

    this.form.patchValue(this.revisionStatus || {});
  }

  private async safeClose(): Promise<void> {
    if (this.form?.dirty ?? false) {
      const discardChanges = await this.modalService.confirmUnsavedChanges();
      if (discardChanges) {
        this.closed.emit();
      }
    } else {
      this.closed.emit();
    }
  }
}
