<form class="page form h-100" [formGroup]="form" (submit)="onSave()" noValidate>
  <div class="page-content d-flex flex-column flex-grow-1">
    <man-field
      class="g-col-4"
      labelFor="name"
      [label]="'admin.pools.subjects.table.form.label.name' | translate"
      [errors]="form | fieldErrors : 'name'"
    >
      <input
        id="name"
        type="text"
        formControlName="name"
        class="form-control"
        cdkFocusInitial
      />
    </man-field>

    <man-field
      class="g-col-4 h-100 pb-4"
      [label]="'admin.pools.subjects.table.form.label.categories' | translate"
      [errors]="form | fieldErrors : 'categoryIds'"
    >
      <app-dimension-categories-table formControlName="categoryIds">
      </app-dimension-categories-table>
    </man-field>
  </div>

  <div class="page-footer">
    <button
      type="submit"
      class="btn btn-primary order-2"
      [disabled]="disabled || !form.dirty || !form.valid"
    >
      <ng-container *ngIf="!disabled; else loading">
        {{ (edit ? 'actions.save' : 'actions.create') | translate }}
      </ng-container>
    </button>
    <button
      type="button"
      [disabled]="disabled"
      class="btn btn-light order-1"
      (click)="onClose()"
    >
      {{ 'actions.close' | translate }}
    </button>
  </div>
</form>

<ng-template #loading>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.loading' | translate }}
</ng-template>
