angular.module('common').factory('PvSelectErrorHandler', ['BasicErrorHandler', function (BasicErrorHandler) {
  function PvSelectErrorHandler (element) {
    BasicErrorHandler.bind(this)(element, {
      fieldSelector: '.pv-select',
      iconSelector: '.pv-select-inline-error__icon',
      tooltipSelector: '.inline-error__tooltip'
    });
  }

  PvSelectErrorHandler.prototype = angular.copy(BasicErrorHandler.prototype);

  PvSelectErrorHandler.prototype.readFieldValue = function () {
    return this.element.find('.pv-select__dropdown li.selected a').attr('rel');
  };

  return PvSelectErrorHandler;
}]);
