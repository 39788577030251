angular.module('settings').factory('Repositories::Subject', ['GraphQLClient',
  function (GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
    getAllQuery = 'query($pool_id: Int!) {' +
    '  pool(pool_id: $pool_id) {subjects {' +
    '    id name categories {id}' +
    '  }}' +
    '}',
    createQuery = 'mutation($pool_id: Int!, $name: String!) {' +
    '  create_subject(pool_id: $pool_id, name: $name) {' +
    '    subject {id name categories {id}}' +
    '    erroneous' +
    '  }' +
    '}',
    updateQuery = 'mutation($subject_id: Int!, $name: String!) {' +
    '  update_subject(subject_id: $subject_id, name: $name) {' +
    '    subject {id name categories {id}}' +
    '    erroneous' +
    '  }' +
    '}',
    updateCategoriesQuery = 'mutation($subject_id: Int!, $category_ids: [Int!]) {' +
    '  update_subject(subject_id: $subject_id, category_ids: $category_ids) {' +
    '    subject {id name categories {id}}' +
    '    erroneous' +
    '  }' +
    '}',
    deleteQuery = 'mutation($subject_id: Int!) {' +
    '  delete_subject(subject_id: $subject_id) {subject {id} erroneous}' +
    '}';

    return {
      getAll: function (poolId) {
        return client.query(getAllQuery, {pool_id: poolId}).then(function (response) {
          return response.data.data.pool.subjects;
        });
      },

      create: function (poolId, subject) {
        return client.query(createQuery, {pool_id: poolId, name: subject.name}).then(function (response) {
          return response.data.data.create_subject;
        });
      },

      update: function (subject) {
        return client.query(updateQuery, {subject_id: subject.id, name: subject.name}).then(function (response) {
          return response.data.data.update_subject;
        });
      },

      updateCategories: function (subject, categoryIds) {
        return client.query(updateCategoriesQuery, {subject_id: subject.id, category_ids: categoryIds}).then(function (response) {
          return response.data.data.update_subject;
        });
      },

      delete: function (subject) {
        return client.query(deleteQuery, {subject_id: subject.id}).then(function (response) {
          return response.data.data.delete_subject;
        });
      }
    };
  }
]);
