import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { AssignBlueprintModalComponent } from '../modals/assign-blueprint-modal/assign-blueprint-modal.component';

angular
  .module('common')
  .directive(
    'coAssignBlueprintModal',
    downgradeComponent({ component: AssignBlueprintModalComponent })
  );
