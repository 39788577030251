angular.module('common').directive('pvSelectInlineError', ['$timeout', 'PvSelectErrorHandler', 'baseInlineErrorFunctionality', function ($timeout, PvSelectErrorHandler, baseInlineErrorFunctionality) {
  return {
    restrict: 'E',
    scope: true,
    transclude: true,
    template: '<div class="inline-error__tooltip-position-wrapper"><span class="inline-error__tooltip inline-error__tooltip--pv-select skin-tooltip" ng-show="message.content && (hasFocus || hasMouse)">{{message.content}}</span></div>\
      <div class="inline-error__container"><span class="fa-stack pv-select-inline-error__icon">\
      <i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-stack-1x fa-inverse" ng-class="{\'fa-exclamation-triangle\': message.type == \'alert\', \'fa-info\': message.type == \'info\', \'fa-exclamation\': message.type == \'warning\'}"></i>\
      </span>\
      <ng-transclude/></div>',
    link: function ($scope, $element, $attrs) {
      var errorHandler = new PvSelectErrorHandler($element);
      errorHandler.onShowMessage(function (message) {
        $scope.message = message;
      });

      $element.on('click', '.pv-select__dropdown li', function () {
        var selectedElement = this;
        $timeout(function () {
          var value = angular.element(selectedElement).find('a').attr('rel');

          if (!(value && value.length > 0)) {
            errorHandler.updateMessageVisibility();
          }
          else {
            errorHandler.removeErrorMessage();
          }

        }, 0);
      });

      baseInlineErrorFunctionality('.pv-select', $scope, $element, $attrs, errorHandler);
    }
  };
}]);
