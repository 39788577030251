import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExamGuard } from './exam.guard';
import { ExamEditComponent } from './exams/exam-edit.component';
import { ImportExcelStatisticsComponent } from './exams/import-excel-statistics/import-excel-statistics.component';
import { PoolComponent } from './pool.component';
import { PoolGuard } from './pool.guard';
import { PoolsComponent } from './pools.component';

const routes: Routes = [
  {
    path: '',
    component: PoolsComponent,
    children: [
      {
        path: 'newpools/:id',
        component: PoolComponent,
        canActivate: [PoolGuard],
        children: [
          {
            path: 'exams/:id',
            canActivate: [ExamGuard],
            component: ExamEditComponent,
            children: [
              {
                path: 'import-excel-statistics',
                component: ImportExcelStatisticsComponent
              }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PoolRoutingModule {}
