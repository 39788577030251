// maps error codes to I18n translation keys
import { ManagerErrorCode } from './error-parser';

export const managerErrorTranslationMap = new Map<ManagerErrorCode, string>([
  [ManagerErrorCode.NotAllowedError, 'state_error.not_allowed'],
  [ManagerErrorCode.NotFoundError, 'state_error.not_found'],
  [ManagerErrorCode.GeneralGraphQLError, 'state_error.general_graphql_error'],
  [ManagerErrorCode.ServerError, 'state_error.server_error'],
  [ManagerErrorCode.ServerParseError, 'state_error.server_parse_error'],
  [ManagerErrorCode.GeneralError, 'state_error.general_error']
]);
