/* eslint-disable unicorn/no-null */
import {
  Proposition,
  QuestionGroupType,
  QuestionType,
  ResponseOption
} from '../../../generated/base-types';
import {
  isOfQuestionGroupType,
  isOfQuestionType,
  QuestionAGraphQL,
  QuestionAMinusGraphQL,
  QuestionGroupTypesGraphQL,
  QuestionKPrimeGraphQL,
  QuestionTypesGraphQL,
  SingleQuestionGraphQL
} from './graphql-question-group-types';

const maxNumberTypeAResponseOptions = 5;
const maxNumberTypeKPrimePropositions = 4;

export function fillQuestionGroupQuestionsResponseOptions(
  questionGroup: QuestionGroupTypesGraphQL
): QuestionGroupTypesGraphQL {
  let questions: QuestionTypesGraphQL[];

  if (
    isOfQuestionGroupType<SingleQuestionGraphQL>(
      questionGroup,
      QuestionGroupType.Single
    )
  ) {
    questions = questionGroup.question ? [questionGroup.question] : [];
  } else {
    questions = questionGroup.questions ?? [];
  }

  questions.map(question => fillQuestionResponseOptions(question));

  return questionGroup;
}

function fillQuestionResponseOptions(
  question: QuestionTypesGraphQL
): QuestionTypesGraphQL {
  if (
    isOfQuestionType<QuestionAGraphQL>(question, QuestionType.A) ||
    isOfQuestionType<QuestionAMinusGraphQL>(question, QuestionType.AMinus)
  ) {
    for (
      let i = (question.responseOptions || []).length;
      i < maxNumberTypeAResponseOptions;
      i++
    ) {
      (question.responseOptions || []).push(
        buildContent(i.toString()) as ResponseOption
      );
    }
  } else if (
    isOfQuestionType<QuestionKPrimeGraphQL>(question, QuestionType.KPrime)
  ) {
    for (
      let i = (question.propositions || []).length;
      i < maxNumberTypeKPrimePropositions;
      i++
    ) {
      (question.propositions || []).push(
        buildContent(i.toString()) as Proposition
      );
    }
  }

  return question;
}

function buildContent(id: string): Proposition | ResponseOption {
  return {
    id,
    content: {
      de: null,
      fr: null,
      it: null,
      en: null
    }
  };
}
