angular.module('common').factory('ReduxHelpers', function () {
  return {
    namespacedReducer: function (namespace, reducer) {
      return function (state, action) {
        if (action.namespace == namespace) {
          return reducer.apply(this, arguments);
        }
        else {
          return state;
        }
      };
    }
  };
});
