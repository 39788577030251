import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'fieldErrors',
  pure: false
})
export class FieldErrorsPipe implements PipeTransform {
  public transform(
    form: FormGroup,
    fieldName: string
  ): ValidationErrors | undefined {
    if (form.valid) {
      return;
    }

    return form.dirty || form.touched
      ? form.get(fieldName)?.errors || undefined
      : undefined;
  }
}
