(function() {
  angular.module('examManagement').component('examList', {
    template: require('./exam_list.html'),
    controller: ExamsController
  });

  function ExamsController(
    ExamRepository,
    ExamActions,
    Store,
    Actions,
    Selectors,
    PoolSelectors,
    ConfirmMessages,
    notifications,
    $uibModal,
    $scope,
    SingleSelection
  ) {
    var dataSubscription, requestInProgressSubscription, poolSubscription, currentPool;
    this.I18n = I18n;
    this.exams = [];

    function getFirstDimension(exam) {
      return exam.presort_criteria && exam.presort_criteria[0];
    }

    Store.dispatch(Actions.fetchExamsRequest());

    dataSubscription = Store.subscribeOn(
      Selectors.getItems,
      function(items) {
        this.exams = items;
      }.bind(this)
    );
    requestInProgressSubscription = Store.subscribeOn(
      Selectors.isRequestInProgress,
      function(inProgress) {
        this.requestInProgress = inProgress;
      }.bind(this)
    );

    poolSubscription = Store.subscribeOn(PoolSelectors.getCurrentPool, function (pool) {
      currentPool = pool;
    });

    this.selection = new SingleSelection(
      Actions.setSelection,
      Actions.removeSelection,
      Selectors.getSelection,
      Selectors.getItems,
      'examList.selection'
    );

    ExamRepository.getAll().then(
      function(data) {
        Store.dispatch(Actions.fetchExamsSuccess(data));
      }.bind(this)
    );

    $scope.$on(
      '$destroy',
      function() {
        dataSubscription();
        this.selection.destroy();
        requestInProgressSubscription();
        poolSubscription();
      }.bind(this)
    );

    this.unlock = function(exam) {
      ExamActions.startUnlockProcess(exam.id).then(function(unlocked_exam) {
        if (unlocked_exam) {
          Store.dispatch(Actions.setExam(unlocked_exam));
        }
      });
    };

    this.lock = function(exam) {
      var wrapper = {
        modal: null
      };
      var data = {
        title: I18n.t('exams.lock.modal.title'),
        content: ConfirmMessages.examLockMessage()
      };
      var instance = $uibModal.open({
        controller: ['$scope', 'data', 'wrapper', function($scope, data, wrapper) {
          $scope.data = data;
          $scope.wrapper = wrapper;
        }],
        template: '<co-confirm-modal [modal-instance]="wrapper.modal" [title]="data.title" [content]="data.content"></co-duplicate-question-group-modal>',
        resolve: {
          data: function() {
            return data;
          },
          wrapper: function() {
            return wrapper;
          }
        }
      });
      wrapper.modal = instance;
      instance.result.then(function() {
        ExamRepository.lock(exam.id).then(function(locked_exam) {
          Store.dispatch(Actions.setExam(locked_exam));
        });
      });
    };

    this.delete = function(exam) {
      if (exam.locked && !exam.self_assessed) {
        notifications.addAlert(ConfirmMessages.cantAct());
      } else {
        var wrapper = {
          modal: null
        };
        var data = {
          title: I18n.t('exams.title_infos.delete'),
          content: ConfirmMessages.examDeleteMessage(exam.id)
        };
        var instance = $uibModal.open({
          controller: ['$scope', 'data', 'wrapper', function($scope, data, wrapper) {
            $scope.data = data;
            $scope.wrapper = wrapper;
          }],
          template: '<co-confirm-modal [modal-instance]="wrapper.modal" [title]="data.title" [content]="data.content"></co-duplicate-question-group-modal>',
          resolve: {
            data: function() {
              return data;
            },
            wrapper: function() {
              return wrapper;
            }
          }
        });
        wrapper.modal = instance;
        instance.result.then(
          function() {
            ExamRepository.delete(exam.id).then(
              function(deleted_exam) {
                Store.dispatch(Actions.deleteExam(deleted_exam.id));
              }.bind(this)
            );
          }.bind(this)
        );
      }
    };

    this.createExamBook = function(exam) {
      var instance;
      instance = $uibModal.open({
        template: require('../controllers/exam_book_form.html'),
        controller: 'ExamBookFormCtrl',
        resolve: {
          exam: function() {
            return exam;
          }
        }
      });
      instance.result.then(function() {
        ExamRepository.get(exam.id).then(function(new_exam) {
          Store.dispatch(Actions.setExam(new_exam));
        });
      });
    };

    this.openStatisticsDialog = function(exam) {
      var instance;
      instance = $uibModal.open({
        template: require('../controllers/statistics_import.html'),
        controller: 'StatisticsImportCtrl',
        resolve: {
          exam: function() {
            return exam;
          }
        }
      });
      instance.result.then(function() {
        ExamRepository.get(exam.id).then(function(new_exam) {
          Store.dispatch(Actions.setExam(new_exam));
        });
      });
    };

    var sendExamToMeasured = function(exam, show_blueprint_dimension, epilogue, allow_question_comments) {
      ExamRepository.sendToMeasured(exam.id, show_blueprint_dimension, epilogue, allow_question_comments).then(
        function(new_exam) {
          Store.dispatch(Actions.setExam(new_exam));
        },
        function(errors) {
          errors.notifications.forEach(function(error) {
            notifications.add(error.content, error.type);
          });
        }
      );
    };

    this.openExamProvisionModal = function(exam) {
      var instance;
      instance = $uibModal.open({
        template: require('../controllers/exam_provision_modal.html'),
        controller: 'ExamProvisionModalCtrl',
        resolve: {
          exam: function() {
            return exam;
          },
          languages: function () {
            return currentPool.languages;
          }
        }
      });
      instance.result.then(function(result) {
        var show_blueprint_dimension;
        if (result.showBlueprintDimension) {
          show_blueprint_dimension = getFirstDimension(exam).position;
        }
        sendExamToMeasured(exam, show_blueprint_dimension, result.epilogue, result.allowQuestionComments);
      });
      return instance;
    };

    this.anExamIsSelected = function() {
      if (this.exams.currentSelectedExam()) {
        return true;
      } else {
        return false;
      }
    };

    this.currentSelectedExam = function() {
      return this.exams.currentSelectedExam();
    };

    this.openNewExamDialog = function() {
      var instance;
      instance = $uibModal.open({
        template: require('../controllers/exam_form.html'),
        controller: 'ExamFormCtrl',
        resolve: {
          exam: function() {
            var exam, i, len;
            exam = {
              pool_id: currentPool.id,
              medium: 'paper',
              typ: 'summative'
            };
            for (i = 0, len = currentPool.languages.length; i < len; i++) {
              exam[currentPool.languages[i]] = true;
            }
            return exam;
          },
          languages: function() {
            return currentPool.languages;
          },
          allowSelfAssessment: function () {
            return currentPool.allow_self_assessment;
          }
        }
      });
      return instance;
    };

    this.openEditExamForm = function(exam) {
      return $uibModal.open({
        template: require('../controllers/exam_form.html'),
        controller: 'ExamFormCtrl',
        resolve: {
          exam: function() {
            return angular.copy(exam);
          },
          languages: function() {
            return currentPool.languages;
          },
          allowSelfAssessment: function () {
            return currentPool.allow_self_assessment;
          }
        }
      });
    };

    this.openSelfAssessmentModal = function(exam) {
      var instance = $uibModal.open({
        template: require('../controllers/self_assessment_modal.html'),
        controller: 'SelfAssessmentModalCtrl',
        resolve: {
          exam: function() {
            return exam;
          }
        }
      });

      instance.result.then(function(result) {
        publishExam(exam, result.password);
      });
      return instance;
    };

    this.openExamDetails = function(exam) {
      var wrapper = {
        modal: null
      };
      var instance = $uibModal.open({
        controller: ['$scope', 'exam', 'wrapper', function($scope, exam, wrapper) {
          $scope.exam = exam;
          $scope.wrapper = wrapper;
        }],
        template: '<em-exam-details-modal [exam]="exam" [modal-instance]="wrapper.modal"></em-exam-details-modal>',
        resolve: {
          exam: function() {
            return exam;
          },
          wrapper: function() {
            return wrapper;
          }
        }
      });
      wrapper.modal = instance;
      return instance;
    };

    var publishExam = function(exam, password) {
      ExamRepository.publishExam(exam.id, password).then(
        function(published_exam) {
          Store.dispatch(Actions.setExam(published_exam));
        },
        function(errors) {
          errors.notifications.forEach(function(error) {
            notifications.add(error.content, error.type);
          });
        }
      );
    };
  }

  ExamsController.$inject = [
    'Repositories::Exam',
    'ExamActions',
    'Store',
    'Actions::ExamList',
    'Selectors::ExamList',
    'Selectors::Pool',
    'ConfirmMessages',
    'notifications',
    '$uibModal',
    '$scope',
    'SingleSelection'
  ];
})();
