import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixedNumberRange'
})
export class FixedNumberRangePipe implements PipeTransform {
  public transform(values: number[], digits?: number): string {
    values = [...new Set(values)];
    if (values.length === 0) {
      return '-';
    } else if (values.length === 1) {
      return round(values[0], digits);
    } else {
      const min = Math.min(...values);
      const max = Math.max(...values);

      return `${round(min, digits)}-${round(max, digits)}`;
    }
  }
}

function round(value: number, digits: number | undefined): string {
  return digits === undefined ? value.toString() : value.toFixed(digits);
}
