angular.module('common').directive('history', ['$cookies', 'Repositories::Comment', function($cookies, Repository) {
  function GroupedEntries() {
    this._groups = {};
  }

  GroupedEntries.prototype.add = function(name, entry) {
    if (!this._groupExists(name)) {
      this._createGroup(name);
    }
    this._groups[name].push(entry);
  };

  GroupedEntries.prototype._groupExists = function(name) {
    return angular.isDefined(this._groups[name]);
  };

  GroupedEntries.prototype._createGroup = function(name) {
    this._groups[name] = [];
  };

  GroupedEntries.prototype.getGroupNames = function() {
    var result = [];
    for(var key in this._groups) {
      if (this._groups.hasOwnProperty(key)) {
        result.push(key);
      }
    }
    return result;
  };

  GroupedEntries.prototype.getGroup = function(name) {
    return this._groups[name];
  };

  return {
    restrict: 'E',
    template: require('./history.html'),
    replace: true,
    scope: {
      originalEntries: '=entries'
    },
    link: function($scope) {
      $scope.I18n = I18n;

      $scope.displayAlreadyReadComments = $cookies.getObject('common.displayAlreadyReadComments') || false;
      $scope.displaySystemComments      = $cookies.getObject('common.displaySystemComments') || false;

      $scope.toggleReadCommentsDisplay = function() {
        $scope.displayAlreadyReadComments = !$scope.displayAlreadyReadComments;
        $cookies.putObject('common.displayAlreadyReadComments', $scope.displayAlreadyReadComments);
      };

      $scope.toggleSystemCommentsDisplay = function() {
        $scope.displaySystemComments = !$scope.displaySystemComments;
        $cookies.putObject('common.displaySystemComments', $scope.displaySystemComments);
      };

      $scope.isYearEntryDisplayed = function(entries) {
        if($scope.displayAlreadyReadComments) {
          return true;
        } else if (entries.filter(function(element) { return !element.read; }).length > 0) {
          return true;
        } else {
          return false;
        }
      };

      $scope.isCommentDisplayed = function(comment) {
        return !comment.read || $scope.displayAlreadyReadComments;
      };

      $scope.isSystemMessageDisplayed = function(comment) {
        return comment.type === 'comment' || $scope.displaySystemComments;
      };

      $scope.update = function(entry) {
        Repository.update(entry.id, entry.read);
      };

      $scope.delete = function(entry) {
        Repository.delete(entry.id).then(function() {
          var index = $scope.currentEntries.indexOf(entry);
          if(index > -1) {
            $scope.currentEntries.splice(index, 1);
          } else {
            for(var i = 0; i < $scope.entriesByYear.length; i++) {
              index = $scope.entriesByYear[i].entries.indexOf(entry);
              if (index > -1) {
                $scope.entriesByYear[i].entries.splice(index, 1);
                break;
              }
            }
          }
        });
      };

      function sortByDate(entries) {
        entries.sort(function(a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
      }

      function initEntryLists(entries) {
        var groups = new GroupedEntries();
        for(var i = 0; i < entries.length; i++) {
          groups.add(new Date(entries[i].created_at).getFullYear(), entries[i]);
        }

        $scope.entries = entries;
        $scope.currentEntries = getGroupOfCurrentYear(groups) || [];
        sortByDate($scope.currentEntries);

        $scope.entriesByYear = getOrderedGroupsOfThePast(groups);
        for(var i = 0; i < $scope.entriesByYear.length; i++) {
          sortByDate($scope.entriesByYear[i].entries);
        }
      }

      function getGroupOfCurrentYear(groups) {
        var currentYear = new Date(Date.now()).getFullYear();
        return groups.getGroup(currentYear);
      }

      function getOrderedGroupsOfThePast(groups) {
        var currentYear = new Date(Date.now()).getFullYear();
        var years = groups.getGroupNames().filter(function(year) {
          return year < currentYear;
        });
        years.sort(function(a, b) {
          return parseInt(b) - parseInt(a);
        });
        return angular.element.map(years, function(year) {
          return {
            year: year,
            entries: groups.getGroup(year)
          };
        });
      }

      $scope.$watchCollection(function() {
        return $scope.originalEntries;
      }, function(newValue) {
        if (angular.isUndefined(newValue)) {
          $scope.entries = [];
          $scope.currentEntries = [];
          $scope.entriesByYear = [];
        }
        else {
          initEntryLists(newValue.slice(0));
        }
      });
    }
  };
}]);
