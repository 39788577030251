import { Injectable } from '@angular/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  LoadPoolsGQL,
  PoolListElementFragment
} from '../admin/services/load-pools.generated';
import { parseError } from '../common/utils/error-parser';
import { RemoteData } from '../common/utils/remote-data';
import { LoadPools, LoadPoolsFailure, LoadPoolsSuccess } from './pools.actions';

export interface PoolsStateModel {
  pools: RemoteData<PoolListElementFragment[]>;
}

@State<PoolsStateModel>({
  name: 'pools',
  defaults: {
    pools: {
      requestState: 'initial'
    }
  }
})
@Injectable({
  providedIn: 'root'
})
export class PoolsState {
  constructor(private readonly loadPoolsService: LoadPoolsGQL) {}

  @Selector()
  public static pools(
    state: PoolsStateModel
  ): RemoteData<PoolListElementFragment[]> {
    return state.pools;
  }

  @Action(LoadPools, { cancelUncompleted: true })
  public loadPools(ctx: StateContext<PoolsStateModel>): Observable<void> {
    ctx.patchState({ pools: { requestState: 'loading' } });

    return this.loadPoolsService.fetch().pipe(
      switchMap(res => ctx.dispatch(new LoadPoolsSuccess(res.data.pools))),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadPoolsFailure(parseError(err)))
      )
    );
  }

  @Action(LoadPoolsSuccess)
  public loadPoolsSuccess(
    ctx: StateContext<PoolsStateModel>,
    { pools }: LoadPoolsSuccess
  ): void {
    ctx.patchState({
      pools: {
        data: pools,
        requestState: 'success'
      }
    });
  }

  @Action(LoadPoolsFailure)
  public loadPoolsFailure(
    ctx: StateContext<PoolsStateModel>,
    { error }: LoadPoolsFailure
  ): void {
    ctx.patchState({
      pools: { requestState: 'failure', error }
    });
  }
}
