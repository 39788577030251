angular.module('common').factory('Actions::Panel', function () {
  var actions = {
    HIDE_PANEL: 'PANEL_HIDE',
    SHOW_PANEL: 'PANEL_SHOW',
    TOGGLE_PANEL: 'PANEL_TOGGLE',
    START_LOADING: 'PANEL_START_LOADING',
    STOP_LOADING: 'PANEL_STOP_LOADING'
  };

  function hide (name) {
    return {
      type: actions.HIDE_PANEL,
      name: name
    };
  }

  function show (name) {
    return {
      type: actions.SHOW_PANEL,
      name: name
    };
  }

  function toggle (name) {
    return {
      type: actions.TOGGLE_PANEL,
      name: name
    };
  }

  function startLoading (name) {
    return {
      type: actions.START_LOADING,
      name: name
    };
  }

  function stopLoading (name) {
    return {
      type: actions.STOP_LOADING,
      name: name
    };
  }

  return Object.assign({
    hide: hide,
    show: show,
    toggle: toggle,
    startLoading: startLoading,
    stopLoading: stopLoading
  }, actions);
});
