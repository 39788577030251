import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CommonModule as ManagerCommonModule } from '../common/common.module';
import './angularjs/module';
import './downgraded';
import { TaskQuestionGroupExportModalComponent } from './modals/task-question-group-export-modal/task-question-group-export-modal.component';
import { TaskListState } from './state/task-list.state';

@NgModule({
  declarations: [TaskQuestionGroupExportModalComponent],
  imports: [ManagerCommonModule, NgxsModule.forFeature([TaskListState])]
})
export class TaskManagementModule {}
