import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { WriteCommentModalComponent } from '../modals/write-comment-modal/write-comment-modal.component';

angular
  .module('common')
  .directive(
    'coWriteCommentModal',
    downgradeComponent({ component: WriteCommentModalComponent })
  );
