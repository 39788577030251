import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import './angularjs/module';

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class SettingsModule {}
