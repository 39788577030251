require('bootstrap-sass/vendor/assets/javascripts/bootstrap/affix.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/alert.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/button.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/carousel.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/collapse.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/dropdown.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/modal.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/scrollspy.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/tab.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/tooltip.js');
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/transition.js');
// popover.js needs tooltip.js to be loaded prior to itself
require('bootstrap-sass/vendor/assets/javascripts/bootstrap/popover.js');
