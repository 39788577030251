angular.module('common').directive('fixedTableHeader', ['$timeout', '$q', '$parse', '$rootScope', '$interval', function($timeout, $q, $parse, $rootScope, $interval) {
  return {
    transclude: true,
    restrict: 'E',
    replace: true,
    template: '<div class="pv-fixed-table-header__container">' +
      '<div class="pv-fixed-table-header__header"></div>' +
      '<div class="pv-fixed-table-header__scroll-area" ng-transclude></div>' +
      '</div>',
    compile: function($element, $attrs) {
      var attributes = $rootScope.$eval($attrs.containerAttributes);
      for(var k in attributes) {
        if (attributes.hasOwnProperty(k)) {
          $element.find('.pv-fixed-table-header__scroll-area').attr(k, attributes[k]);
        }
      }
    },
    controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
      // Set fixed widths for the table headers in case the text overflows.
      // There's no callback for when rendering is complete, so check the visibility of the table
      // periodically -- see http://stackoverflow.com/questions/11125078
      function waitForRender() {
        var deferredRender = $q.defer();
        function wait() {
          if($element.find("table:visible").length === 0) {
            $timeout(wait, 100);
          } else {
            deferredRender.resolve();
          }
        }
        $timeout(wait);
        return deferredRender.promise;
      }

      function initFixedHeader() {
        $element.find("thead th").wrapInner('<div class="pv-fixed-table-header__wrapped-header"></div>');
        $element.find(".pv-fixed-table-header__wrapped-header").each(function(index, el) {
          var el = $(el);
          el.css("padding", el.parent().css("padding"));
          el.parent().css("padding", 0);
        });
      }

      function fixHeaderWidths() {
        $element.find(".pv-fixed-table-header__wrapped-header").each(function(index, el) {
          el = $(el);
          el.css("width", el.parent().outerWidth());
        });
      }

      $interval(fixHeaderWidths, 500);

      waitForRender().then(function() {
        initFixedHeader();
      });

      $scope.asc = !$attrs.hasOwnProperty("desc");
      $scope.sortAttr = $attrs.sortAttr;

      $element.find(".pv-fixed-table-header__scroll-area").scroll(function(event) {
        $element.find(".pv-fixed-table-header__wrapped-header").css('margin-left', 0 - event.target.scrollLeft);
      });
    }]
  };
}]);
