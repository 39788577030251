/*jshint multistr: true */
angular.module('common').directive('progressOfJob', [
  '$timeout',
  '$rootScope',
  'Repositories::Job',
  function($timeout, $rootScope, Repository) {
    return {
      restrict: 'E',
      transclude: true,
      template:
        '\
      <ng-transclude></ng-transclude>\
      <div class="progress">\
        <div class="progress-bar" style="min-width: 2em; width: 0%">\
          0%\
        </div>\
      </div>',
      link: function(scope, element, attrs) {
        var jobInProgress = false,
          lastCheckedId,
          checkTimeout;

        function showJobProgress(progress) {
          element.removeClass('ng-hide');
          element
            .find('.progress-bar')
            .css('width', progress + '%')
            .text(progress + '%');
          scope[attrs.feedback] = true;
        }

        function hideJobProgress() {
          scope[attrs.feedback] = false;
          element
            .find('.progress-bar')
            .css('width', '0%')
            .text('0%');
          element.addClass('ng-hide');
        }

        function checkJobStatus(jobId) {
          if (lastCheckedId !== jobId) {
            jobInProgress = false;
          }
          lastCheckedId = jobId;

          Repository.get(jobId).then(function(job) {
            if (job && (job.status == 'running' || job.status == 'queued')) {
              showJobProgress(job.progress);
              checkTimeout = $timeout(function() {
                checkJobStatus(jobId);
              }, window['GRAPHQL_REFRESH_INTERVAL']);
            } else {
              hideJobProgress();
              $rootScope.$broadcast('job.finished', job);
            }
          });
        }

        element.addClass('ng-hide');

        scope.I18n = I18n;
        scope.$watch(attrs.jobId, function(value) {
          $timeout.cancel(checkTimeout);
          if (value) {
            checkJobStatus(value);
          } else {
            hideJobProgress();
          }
        });

        scope.$on('$destroy', function() {
          $timeout.cancel(checkTimeout);
        });
      }
    };
  }
]);
