import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { requestStateSuccess } from 'src/app/common/utils/remote-data-utils';
import { isDefined } from 'src/app/common/utils/type-guards/is-defined';
import { UserFormState } from '../../state/user-form.state';

@Component({
  templateUrl: './user-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'container content' }
})
export class UserEditComponent {
  public userFullName$: Observable<string>;
  public failure$: Observable<boolean>;

  constructor(private store: Store) {
    this.failure$ = this.store.select(UserFormState.userFailure);
    this.userFullName$ = this.store.select(UserFormState.user).pipe(
      filter(requestStateSuccess),
      map(({ data }) => data),
      filter(isDefined),
      map(user => `${user.lastName} ${user.firstName}`)
    );
  }
}
