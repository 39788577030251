import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreatePoolMutationVariables = Types.Exact<{
  attributes: Types.PoolInput;
}>;


export type CreatePoolMutation = { __typename?: 'Mutation', createPool?: { __typename?: 'CreatePoolPayload', successful: boolean, pool?: { __typename?: 'Pool', id: string, name: string, selfAssessment: boolean, responsibleFirstName?: string | null, responsibleLastName?: string | null, responsibleEmail: string, responsibleLanguage?: Types.Language | null, deEnabled: boolean, frEnabled: boolean, enEnabled: boolean, itEnabled: boolean } | null, validations?: { __typename?: 'ValidationsPool', name?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, selfAssessment?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, responsibleEmail?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, deEnabled?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, frEnabled?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, enEnabled?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, itEnabled?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null } | null } | null };

export const CreatePoolDocument = gql`
    mutation CreatePool($attributes: PoolInput!) {
  createPool(attributes: $attributes) {
    pool {
      id
      name
      selfAssessment
      responsibleFirstName
      responsibleLastName
      responsibleEmail
      responsibleLanguage
      deEnabled
      frEnabled
      enEnabled
      itEnabled
    }
    successful
    validations {
      name {
        message
        severity
      }
      selfAssessment {
        message
        severity
      }
      responsibleEmail {
        message
        severity
      }
      deEnabled {
        message
        severity
      }
      frEnabled {
        message
        severity
      }
      enEnabled {
        message
        severity
      }
      itEnabled {
        message
        severity
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePoolGQL extends Apollo.Mutation<CreatePoolMutation, CreatePoolMutationVariables> {
    document = CreatePoolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }