angular.module('common').factory('Actions::AnnouncementList', function () {
  var actions = {
    FETCH_ANNOUNCEMENTS_REQUEST: 'ANNOUNCEMENT_LIST_FETCH_ANNOUNCEMENTS_REQUEST',
    FETCH_ANNOUNCEMENTS_SUCCESS: 'ANNOUNCEMENT_LIST_FETCH_ANNOUNCEMENTS_SUCCESS',
  };

  function fetchAnnouncementsRequest () {
    return {
      type: actions.FETCH_ANNOUNCEMENTS_REQUEST
    };
  }

  function fetchAnnouncementsSuccess (announcements) {
    return {
      type: actions.FETCH_ANNOUNCEMENTS_SUCCESS,
      announcements: announcements
    };
  }

  return Object.assign({
    fetchAnnouncementsRequest: fetchAnnouncementsRequest,
    fetchAnnouncementsSuccess: fetchAnnouncementsSuccess,
  }, actions);
});
