/* eslint-disable camelcase */
export interface Affiliation {
  name: string;
}

export interface Category {
  id: number;
  name: string;
}

export interface User {
  name: string;
}

export interface Label {
  id: number;
  name: string;
}

export interface RevisionStatus {
  id?: number;
  name: string;
  short_name: string;
}

export enum QuestionGroupType {
  Single = 'SINGLE',
  Series = 'SERIES',
  Sequence = 'SEQUENCE'
}

export enum QuestionType {
  MCA = 'A',
  MCAMinus = 'A-',
  MCE = 'E',
  MCKprime = 'Kprime',
  FreeText = 'Essay',
  LongMenu = 'LongMenu'
}

export interface ObjectWithTranslatedContent {
  content_de?: string;
  content_fr?: string;
  content_it?: string;
  content_en?: string;
}

export interface QuestionGroup {
  id: number;
  type: QuestionGroupType;
  questions: QuestionOfAnyType[];
  complete: boolean;
  legacy_id: string | null;
  sequential_number: number;
  source_language: string;
  title: string;
  supervisor: string | null;
  affiliation?: Affiliation;
  content_validation_state_de: string;
  content_validation_state_fr: string;
  content_validation_state_it: string;
  content_validation_state_en: string;
  structurally_complete: boolean;
  has_images: boolean;
  has_videos: boolean;
  category_0?: Category;
  category_1?: Category;
  category_2?: Category;
  category_3?: Category;
  category_4?: Category;
  category_5?: Category;
  category_6?: Category;
  category_7?: Category;
  created_at: string;
  updated_at: string;
  editor: User | null;
  author: string | null;
  number_of_usages: number;
  past_exams: Exam[];
  last_usage: string | null;
  latest_statistic: number | null;
  cache_last_usage_n: number | null;
  cache_last_usage_p: number | null;
  cache_last_usage_r: number | null;
  cache_last_usage_eliminated: boolean | null;
  future_exams: Exam[];
  labels: Label[];
  revision_status: RevisionStatus | null;
  revision_year: number | null;
  has_active_tasks: boolean;
  content_changed_since_last_usage: boolean;
  metadata_changed_since_last_usage: boolean;
  exam_ids?: number[];
}

export interface Question extends ObjectWithTranslatedContent {
  score: number;
  literary_reference: string;
}

export interface QuestionA extends Question {
  type: QuestionType.MCA;
  response_options: ClosedResponseOption[];
}

export interface QuestionAMinus extends Question {
  type: QuestionType.MCAMinus;
  response_options: ClosedResponseOption[];
}

export interface QuestionE extends Question {
  type: QuestionType.MCE;
  content_de_part_1?: string;
  content_de_part_2?: string;
  content_en_part_1?: string;
  content_en_part_2?: string;
  content_fr_part_1?: string;
  content_fr_part_2?: string;
  content_it_part_1?: string;
  content_it_part_2?: string;
  response_options: ClosedResponseOption[];
}

export interface QuestionKprime extends Question {
  type: QuestionType.MCKprime;
  response_options: ClosedResponseOption[];
}

export interface QuestionFreeText extends Question {
  type: QuestionType.FreeText;
  open_response: OpenResponse;
}

export interface QuestionLongMenu extends Question {
  type: QuestionType.LongMenu;
}

export type QuestionOfAnyType =
  | QuestionA
  | QuestionAMinus
  | QuestionKprime
  | QuestionE
  | QuestionFreeText
  | QuestionLongMenu;

export interface ClosedResponseOption extends ObjectWithTranslatedContent {
  correct: boolean;
}

export interface OpenResponse extends ObjectWithTranslatedContent {
  lines: number;
}

export interface Exam {
  id: number;
  name: string;
  locked: boolean;
  date: string;
}

export interface Task {
  id: number;
}

export interface TaskQuestionGroup {
  question_group: QuestionGroup;
  task: Task;
  has_comment: boolean;
  changed_by_author: boolean;
}
