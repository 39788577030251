import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import type { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'co-single-panel-accordion',
  templateUrl: './single-panel-accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SinglePanelAccordionComponent {
  @Input() public readonly title: string;
  @Input()
  public set initiallyOpen(value: boolean) {
    if (this.open && !value) {
      return;
    }
    this._initiallyOpen = value;
  }
  public get initiallyOpen(): boolean {
    return this._initiallyOpen;
  }

  public _initiallyOpen = false;
  public open = false;

  public toggle({ nextState }: NgbPanelChangeEvent): void {
    this.open = nextState;
  }
}
