<div class="page-content" [ngClass]="{ loading: (loading$ | async) }">
  <div class="grid h-100" style="grid-template-rows: auto 1fr">
    <ng-container *ngIf="affiliations$ | async as affiliations">
      <div class="g-col-6 g-col-xl-8 ms-auto order-2">
        <button (click)="onNew()" type="button" class="btn btn-dark">
          {{ 'admin.pools.affiliations.actions.new' | translate }}
        </button>
      </div>
      <div class="g-col-6 g-col-xl-4 order-1">
        <man-search-input
          [shortcutActive]="true"
          placeholder="admin.pools.affiliations.filter.placeholder"
          [disabled]="affiliations.requestState !== 'success'"
          (search)="onSearch($event)"
          (clear)="onSearchClear()"
        >
        </man-search-input>
      </div>
      <man-table-wrapper class="g-col-12 order-3" [request]="affiliations">
        <p-table
          #table
          name="Affiliations"
          [globalFilterFields]="filterFields"
          [value]="affiliations.data | copyArray"
          [loading]="affiliations.requestState === 'loading'"
          [scrollable]="true"
          scrollHeight="flex"
          selectionMode="single"
          [sortField]="sortBy"
          (onRowReorder)="onReorder($event)"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="p-col-fixed p-col--icon"></th>
              <th>
                {{ 'admin.pools.affiliations.table.position' | translate }}
              </th>
              <th>
                {{ 'admin.pools.affiliations.table.name' | translate }}
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-affiliation let-index="rowIndex">
            <tr
              interactiveRow
              (edit)="onEdit(affiliation)"
              (delete)="onDelete(affiliation)"
              [pReorderableRow]="index"
            >
              <td>
                <i
                  aria-hidden="true"
                  class="fa-regular fa-bars"
                  [title]="'common.messages.drag_and_drop' | translate"
                  [pReorderableRowHandle]="index"
                ></i>
              </td>
              <td>{{ affiliation.position + 1 }}</td>
              <td>{{ affiliation.name }}</td>
              <td class="text-end">
                <man-action-menu
                  [data]="affiliation"
                  [options]="menuOptions"
                ></man-action-menu>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4" class="text-center">
                {{
                  'admin.pools.affiliations.table.messages.empty' | translate
                }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </man-table-wrapper>
    </ng-container>
  </div>
</div>

<ng-template #loader>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.actions.loading' | translate }}
</ng-template>

<ng-container *ngIf="formState$ | async as formState">
  <man-side-panel
    *ngIf="formState.show"
    [title]="
      (formState.data
        ? 'admin.pools.affiliations.form.title.edit'
        : 'admin.pools.affiliations.form.title.create'
      ) | translate
    "
    [closeable]="true"
    (closed)="affiliationForm.onClose()"
  >
    <man-affiliation-form
      #affiliationForm
      class="mx-n3"
      [disabled]="formState.disabled"
      [affiliation]="formState.data"
      (create)="onCreate($event)"
      (update)="onUpdate($event)"
      (closed)="onDismiss()"
    ></man-affiliation-form>
  </man-side-panel>
</ng-container>
