import { Injectable } from '@angular/core';
import { Action, Selector, State, Store } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { Scalars } from '../../../generated/base-types';
import { assertIsDefined } from '../../common/utils/type-guards/is-defined';
import { QuestionGroupListElementFragment } from '../../question-form/services/load-question-group-list.generated';
import { AppContextState } from '../../state/app-context.state';
import { LoadQuestionGroupsGQL } from './load-question-groups.generated';
import {
  SetLoadedTaskIds,
  SetLoadedTaskQuestionGroupIds,
  SetSelectedTaskIds,
  SetSelectedTaskQuestionGroupIds
} from './task-list.actions';

export interface TaskListStateModel {
  selectedTaskIds: Scalars['ID'][];
  loadedTaskIds: Scalars['ID'][];
  selectedTaskQuestionGroupIds: Scalars['ID'][];
  selectedQuestionGroups: QuestionGroupListElementFragment[];
  loadedTaskQuestionGroupIds: Scalars['ID'][];
  loadedQuestionGroupIds: Scalars['ID'][];
}

@State<TaskListStateModel>({
  name: 'taskList',
  defaults: {
    selectedTaskIds: [],
    loadedTaskIds: [],
    selectedTaskQuestionGroupIds: [],
    loadedTaskQuestionGroupIds: [],
    selectedQuestionGroups: [],
    loadedQuestionGroupIds: [] // question groups ids of task question group children
  }
})
@Injectable()
export class TaskListState {
  constructor(
    private readonly loadQuestionGroupsGQL: LoadQuestionGroupsGQL,
    private readonly store: Store
  ) {}

  @Selector()
  public static selectedTaskIds(state: TaskListStateModel): Scalars['ID'][] {
    return state.selectedTaskIds;
  }

  @Selector()
  public static loadedTaskIds(state: TaskListStateModel): Scalars['ID'][] {
    return state.loadedTaskIds;
  }

  @Selector()
  public static selectedTaskQuestionGroupIds(
    state: TaskListStateModel
  ): Scalars['ID'][] {
    return state.selectedTaskQuestionGroupIds;
  }

  @Selector()
  public static selectedQuestionGroups(
    state: TaskListStateModel
  ): QuestionGroupListElementFragment[] {
    return state.selectedQuestionGroups;
  }

  @Selector()
  public static loadedTaskQuestionGroupIds(
    state: TaskListStateModel
  ): Scalars['ID'][] {
    return state.loadedTaskQuestionGroupIds;
  }

  @Selector()
  public static loadedQuestionGroupIds(
    state: TaskListStateModel
  ): Scalars['ID'][] {
    return state.loadedQuestionGroupIds;
  }

  @Action(SetSelectedTaskIds)
  public setSelectedTaskIds(
    ctx: StateContext<TaskListStateModel>,
    action: SetSelectedTaskIds
  ): void {
    ctx.patchState({
      selectedTaskIds: action.ids
    });
  }

  @Action(SetLoadedTaskIds)
  public setLoadedTaskIds(
    ctx: StateContext<TaskListStateModel>,
    action: SetLoadedTaskIds
  ): void {
    ctx.patchState({
      loadedTaskIds: action.ids
    });
  }

  @Action(SetSelectedTaskQuestionGroupIds)
  public setSelectedTaskQuestionGroupIds(
    ctx: StateContext<TaskListStateModel>,
    action: SetSelectedTaskQuestionGroupIds
  ): void {
    // TODO: remove when we don't use the legacy store to set the selected task question group ids
    // this is a workaround to prevent the store from being updated multiple times with an empty array
    if (
      ctx.getState().selectedTaskQuestionGroupIds.length === 0 &&
      action.taskQuestionGroupIds.length === 0
    ) {
      return;
    }

    const poolId = this.store.selectSnapshot(AppContextState.currentPoolId);

    assertIsDefined(poolId);

    firstValueFrom(
      this.loadQuestionGroupsGQL.fetch({
        poolId,
        questionGroupIds: action.questionGroupIds
      })
    ).then(result =>
      ctx.patchState({
        selectedTaskQuestionGroupIds: action.taskQuestionGroupIds,
        selectedQuestionGroups: result.data.pool.questionGroups
      })
    );
  }

  @Action(SetLoadedTaskQuestionGroupIds)
  public setLoadedTaskQuestionGroupIds(
    ctx: StateContext<TaskListStateModel>,
    action: SetLoadedTaskQuestionGroupIds
  ): void {
    ctx.patchState({
      loadedTaskQuestionGroupIds: action.taskQuestionGroupIds,
      loadedQuestionGroupIds: action.questionGroupIds
    });
  }
}
