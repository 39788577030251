import { ValidationError } from '../../../generated/base-types';

// all GraphQL validation errors have this shape
export type GenericGraphQLValidations<T = unknown> = {
  [K in keyof T]?: ValidationError[];
};
export class GraphQLValidationError {
  public validations: GenericGraphQLValidations;

  constructor(validations: GenericGraphQLValidations) {
    this.validations = validations;
  }
}

export function asValidationError(
  error: GenericGraphQLValidations
): GraphQLValidationError {
  return new GraphQLValidationError(error);
}
