angular.module('common').factory('Reducers::QuestionGroupNavigationList', ['Actions::QuestionGroupNavigationList',
  function (Actions) {
    function reducer (state, action) {
      state = state || {};
      state = Object.assign({}, state, {
        ids: state.ids || []
      });

      switch (action.type) {
        case Actions.ADD_IDS:
          return Object.assign({}, state, {
            ids: action.ids
          });

        default:
          return state;
      }
    }

    return reducer;
  }
]);
