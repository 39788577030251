angular.module('common').factory('Repositories::Exam', [
  'GraphQLClient',
  '$q',
  '$filter',
  function(GraphQLClient, $q, $filter) {
    var client = new GraphQLClient('/api/v1/graphql'),
      allExamAttributes =
        '  id duration examinator_unlock_token name' +
        '  content content_de content_fr content_it content_en' +
        '  medium typ de fr it en show_dimensions has_scrambling_b' +
        '  date first_presort_criterion second_presort_criterion third_presort_criterion' +
        '  self_assessed locked locked_at_date provisioned is_presorted is_in_the_future' +
        '  show_first_dimension_presort allow_question_comments' +
        '  presort_criteria {' +
        '    type name position' +
        '  } ' +
        '  pool { id }' +
        '  dimensions {' +
        '    id name position' +
        '    categories {id name position}' +
        '  }',
      errorAttributes =
        '  erroneous' +
        '  notifications { content type }' +
        '  field_messages {' +
        '    name { content type }' +
        '    languages { content type }' +
        '    content_de { content type }' +
        '    content_fr { content type }' +
        '    content_it { content type }' +
        '    content_en { content type }' +
        '    date { content type }' +
        '    duration { content type }' +
        '    medium { content type }' +
        '    typ { content type }' +
        '  }',
      getAllQuery = 'query { exams {' + allExamAttributes + '} }',
      getQuery = 'query($id: Int!) { exam(id: $id) {' + allExamAttributes + '} }',
      getUnlockedExamsQuery = 'query {exams {id name locked date}}',
      lockQuery = 'mutation($id: Int!) { lock_exam(id: $id) { exam {id date locked} } }',
      unlockQuery =
        'mutation($id: Int!, $unlock_code: String) { ' +
        '  unlock_exam(id: $id, unlock_code: $unlock_code) { ' +
        '    exam {id locked provisioned}' +
        '  } ' +
        '}',
      getUnlockStrategyQuery =
        'query($id: Int!) { exam(id: $id) { unlock_strategy { method messages { type text } } }}',
      deleteQuery = 'mutation($id: Int!) { delete_exam(id: $id) { exam {id} erroneous } }',
      validateQuery =
        'query($id: Int!) {' +
        '  exam(id: $id) {' +
        '    validation {' +
        '      erroneous' +
        '      notifications { content type }' +
        '      field_messages {' +
        '        typ { content type }' +
        '        name { content type }' +
        '      }' +
        '    }' +
        '  }' +
        '}',
      createQuery =
        'mutation($attributes: ExamInput!) {' +
        '  create_exam(attributes: $attributes) {' +
        '    exam {' +
        allExamAttributes +
        '    }' +
        '    errors {' +
        errorAttributes +
        '    } erroneous' +
        '  }' +
        '}',
      updateQuery =
        'mutation($attributes: ExamInput!) {' +
        '  update_exam(attributes: $attributes) {' +
        '    exam {' +
        allExamAttributes +
        '    }' +
        '    errors {' +
        errorAttributes +
        '    } erroneous' +
        '  }' +
        '}',
      sendToMeasuredQuery =
        'mutation($id: Int!, $show_blueprint_dimension: Int, $epilogue: EpilogueInput, $allow_question_comments: Boolean) {' +
        '  send_to_measured(id: $id, show_blueprint_dimension: $show_blueprint_dimension, epilogue: $epilogue, allow_question_comments: $allow_question_comments) {' +
        '    exam {' +
        allExamAttributes +
        '    }' +
        '    errors {' +
        errorAttributes +
        '    } erroneous' +
        '  }' +
        '}',
      publishExamQuery =
        'mutation($id: Int!, $password: String) {' +
        '  publish_exam(id: $id, password: $password) {' +
        '    exam {' +
        allExamAttributes +
        '    }' +
        '    errors {' +
        '      notifications { content type }' +
        '    } erroneous' +
        '  }' +
        '}';

    function transformAttributes(obj) {
      return {
        id: obj.id,
        pool_id: obj.pool_id,
        duration: obj.duration,
        name: obj.name,
        content_de: obj.content_de,
        content_fr: obj.content_fr,
        content_it: obj.content_it,
        content_en: obj.content_en,
        medium: obj.medium,
        typ: obj.typ,
        de: obj.de,
        fr: obj.fr,
        it: obj.it,
        en: obj.en,
        date: $filter('date')(obj.date, 'yyyy-MM-dd')
      };
    }

    return {
      getAll: function() {
        return client.query(getAllQuery).then(function(response) {
          return response.data.data.exams;
        });
      },

      get: function(id) {
        return client.query(getQuery, { id: id }).then(function(response) {
          return response.data.data.exam;
        });
      },

      getUnlockedExams: function() {
        return client.query(getUnlockedExamsQuery).then(function(response) {
          var today = new Date();
          return response.data.data.exams.filter(function(exam) {
            return !exam.locked && new Date(exam.date) > today;
          });
        });
      },

      lock: function(id) {
        return client.query(lockQuery, { id: id }).then(function(response) {
          return response.data.data.lock_exam.exam;
        });
      },

      unlock: function(id, unlock_code) {
        return client
          .query(unlockQuery, { id: id, unlock_code: unlock_code })
          .then(function(response) {
            return response.data.data.unlock_exam.exam;
          });
      },

      getUnlockStrategy: function(id) {
        return client.query(getUnlockStrategyQuery, { id: id }).then(function(response) {
          return response.data.data.exam.unlock_strategy;
        });
      },

      delete: function(id) {
        return client.query(deleteQuery, { id: id }).then(function(response) {
          return response.data.data.delete_exam.exam;
        });
      },

      validate: function(id) {
        return client
          .query(validateQuery, { id: id })
          .then(function(response) {
            return response.data.data.exam.validation;
          })
          .then(function(data) {
            if (data.erroneous) {
              return $q.reject(data);
            } else {
              return data;
            }
          });
      },

      create: function(attributes) {
        return client
          .query(createQuery, { attributes: transformAttributes(attributes) })
          .then(function(response) {
            return response.data.data.create_exam;
          })
          .then(function(data) {
            if (data.erroneous) {
              return $q.reject(data.errors);
            } else {
              return data.exam;
            }
          });
      },

      update: function(exam) {
        return client
          .query(updateQuery, { attributes: transformAttributes(exam) })
          .then(function(response) {
            return response.data.data.update_exam;
          })
          .then(function(data) {
            if (data.erroneous) {
              return $q.reject(data.errors);
            } else {
              return data.exam;
            }
          });
      },

      sendToMeasured: function(id, show_blueprint_dimension, epilogue, allow_question_comments) {
        return client
          .query(sendToMeasuredQuery, {
            id: id,
            show_blueprint_dimension: show_blueprint_dimension,
            epilogue: epilogue,
            allow_question_comments: allow_question_comments
          })
          .then(function(response) {
            return response.data.data.send_to_measured;
          })
          .then(function(data) {
            if (data.erroneous) {
              return $q.reject(data.errors);
            } else {
              return data.exam;
            }
          });
      },

      publishExam: function(id, password) {
        return client
          .query(publishExamQuery, { id: id, password: password })
          .then(function(response) {
            return response.data.data.publish_exam;
          })
          .then(function(data) {
            if (data.erroneous) {
              return $q.reject(data.errors);
            } else {
              return data.exam;
            }
          });
      }
    };
  }
]);
