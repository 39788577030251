(function() {
  angular.module('settings').component('oauthApplications', {
    template: require('./oauth_applications.html'),
    controller: Controller
  });

  function Controller($scope, $state, Store, Actions, Selectors, Repository) {
    var dataSubscription;

    this.I18n = I18n;

    dataSubscription = Store.subscribeOn(
      Selectors.getItems,
      function(items) {
        this.applications = items;
      }.bind(this)
    );

    $scope.$on('$destroy', function() {
      dataSubscription();
    });

    this.createApplication = function() {
      $state.go('settings.applications.new');
    };
  }

  Controller.$inject = [
    '$scope',
    '$state',
    'Store',
    'Actions::OauthApplicationList',
    'Selectors::OauthApplicationList',
    'Repositories::OauthApplication'
  ];
})();
