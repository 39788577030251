<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'revision_document.form.title' | translate }}</h3>
  </div>

  <div class="modal-body">
    <div class="form-horizontal" [formGroup]="form">
      <div class="form-group">
        <label class="col-sm-4">
          {{ 'revision_document.form.options.languages' | translate }}
        </label>
        <div class="col-sm-4">
          <div *ngFor="let language of languages$ | async : []">
            <input
              type="checkbox"
              [id]="language + 'Language'"
              [(ngModel)]="languageToggle[language]"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="updateLanguages()"
            />
            <label class="language-label" [for]="language + 'Language'">{{
              'scrudu.languages.long.' + language | translate
            }}</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-4" for="pageBreakBetweenQuestionGroups">
          {{
            'revision_document.form.options.page_break_between_question_groups'
              | translate
          }}
        </label>

        <div class="col-sm-2 filter-form">
          <input
            type="checkbox"
            formControlName="pageBreakBetweenQuestionGroups"
            [id]="'pageBreakBetweenQuestionGroups'"
          />
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-4" for="includePageBreaks">
          {{ 'revision_document.form.options.include_page_breaks' | translate }}
        </label>
        <div class="col-sm-2 filter-form">
          <input
            type="checkbox"
            formControlName="includePageBreaks"
            [id]="'includePageBreaks'"
          />
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-4">
          {{ 'revision_document.form.set' | translate }}
        </label>

        <div class="col-sm-4">
          <co-radio-button-group
            [(ngModel)]="mode"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="toggleMode($event)"
            ngDefaultControl
          >
            <button
              id="short"
              [value]="modes.Short"
              class="btn btn-secondary-migrated"
            >
              {{ 'revision_document.form.sets.short' | translate }}
            </button>
            <button
              id="complete"
              [value]="modes.Complete"
              class="btn btn-secondary-migrated"
            >
              {{ 'revision_document.form.sets.complete' | translate }}
            </button>
          </co-radio-button-group>
        </div>

        <div class="col-sm-4 filter-form text-right">
          <label
            id="moreOptions"
            class="btn btn-link text-decoration-none"
            (click)="showMoreOptions = !showMoreOptions"
          >
            <i
              class="fa fa-lg pr-3"
              [ngClass]="
                showMoreOptions
                  ? 'fa-chevron-circle-right'
                  : 'fa-chevron-circle-down'
              "
            ></i>
            {{ 'revision_document.form.show_more_options' | translate }}
          </label>
        </div>
      </div>

      <ng-container *ngIf="showMoreOptions">
        <div class="form-group row">
          <label class="col-sm-4" for="title">
            {{ 'revision_document.form.options.title' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input type="checkbox" formControlName="title" [id]="'title'" />
          </div>
          <label class="col-sm-4" for="affiliation">
            {{ 'revision_document.question_group.affiliation' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'affiliation'"
              formControlName="questionGroupAffiliation"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="questionGroupNumber">
            {{ 'revision_document.question_group.number' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupNumber'"
              formControlName="questionGroupNumber"
            />
          </div>
          <label class="col-sm-4" for="questionGroupType">
            {{ 'revision_document.question_group.question_type' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupType'"
              formControlName="questionGroupType"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="questionGroupSupervisor">
            {{ 'revision_document.question_group.supervisor' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupSupervisor'"
              formControlName="questionGroupSupervisor"
            />
          </div>
          <label class="col-sm-4" for="questionGroupAuthor">
            {{ 'revision_document.question_group.author' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupAuthor'"
              formControlName="questionGroupAuthor"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="questionGroupCreatedAt">
            {{ 'revision_document.question_group.created_at' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupCreatedAt'"
              formControlName="questionGroupCreatedAt"
            />
          </div>
          <label class="col-sm-4" for="questionGroupNumberOfUsages">
            {{
              'revision_document.question_group.number_of_usages' | translate
            }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupNumberOfUsages'"
              formControlName="questionGroupNumberOfUsages"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="questionGroupLastUsageYear">
            {{ 'revision_document.question_group.last_usage_year' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupLastUsageYear'"
              formControlName="questionGroupLastUsageYear"
            />
          </div>

          <label class="col-sm-4" for="questionGroupUpdatedAt">
            {{ 'revision_document.question_group.updated_at' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupUpdatedAt'"
              formControlName="questionGroupUpdatedAt"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="questionRemarks">
            {{ 'revision_document.question.remarks' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionRemarks'"
              formControlName="questionRemarks"
            />
          </div>

          <label class="col-sm-4" for="questionGroupRevisionYear">
            {{ 'revision_document.question_group.revision_year' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupRevisionYear'"
              formControlName="questionGroupRevisionYear"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="questionGroupSourceLanguage">
            {{ 'revision_document.question_group.source_language' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupSourceLanguage'"
              formControlName="questionGroupSourceLanguage"
            />
          </div>

          <label class="col-sm-4" for="questionGroupRevisionStatus">
            {{ 'revision_document.question_group.revision_status' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupRevisionStatus'"
              formControlName="questionGroupRevisionStatus"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="questionGroupScore">
            {{ 'revision_document.question_group.score' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionGroupScore'"
              formControlName="questionGroupScore"
            />
          </div>

          <label class="col-sm-4" for="questionReference">
            {{ 'revision_document.question.reference' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionReference'"
              formControlName="questionReference"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="questionLearningTarget">
            {{ 'revision_document.question.learning_target' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'questionLearningTarget'"
              formControlName="questionLearningTarget"
            />
          </div>

          <label class="col-sm-4" for="statistics">
            {{ 'revision_document.form.options.statistics' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'statistics'"
              formControlName="statistics"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="blueprint">
            {{ 'revision_document.form.options.blueprint' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'blueprint'"
              formControlName="blueprint"
            />
          </div>

          <label class="col-sm-4" for="statisticsAmount">
            {{ 'revision_document.form.options.statistics_amount' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              class="stats-input"
              id="statisticsAmount"
              [min]="1"
              [max]="3"
              [maxLength]="1"
              [minlength]="1"
              name="statisticsAmount"
              formControlName="statisticsAmount"
              type="number"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="text">
            {{ 'revision_document.form.options.text' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input type="checkbox" [id]="'text'" formControlName="text" />
          </div>

          <label class="col-sm-4" for="preview">
            {{ 'revision_document.form.options.preview' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input type="checkbox" [id]="'preview'" formControlName="preview" />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4" for="solution">
            {{ 'revision_document.form.options.solution' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'solution'"
              formControlName="solution"
            />
          </div>

          <label class="col-sm-4" for="comments">
            {{ 'revision_document.form.options.comments' | translate }}
          </label>
          <div class="col-sm-2 filter-form">
            <input
              type="checkbox"
              [id]="'comments'"
              formControlName="comments"
            />
          </div>
        </div>
      </ng-container>
    </div>

    <div class="modal-footer">
      <button (click)="dismiss()" class="btn btn-secondary-migrated">
        {{ 'revision_document.form.buttons.cancel' | translate }}
      </button>
      <button
        (click)="save()"
        [disabled]="isGenerating || !languages.length"
        class="btn btn-primary-migrated"
      >
        {{ 'revision_document.form.buttons.create' | translate }}
      </button>
    </div>
  </div>
</div>
