<div
  class="filter-group"
  [ngClass]="{ 'filter-group--invalid': isEmptySelection }"
>
  <div class="filter-group__header">
    <input
      type="checkbox"
      [id]="filterID + '_root'"
      [checked]="isFullSelection"
      [indeterminate]="isPartialSelection"
      (click)="toggleFullSelection($event.target)"
    />
    <label [for]="filterID + '_root'">
      {{ name | translate | uppercase }}
    </label>
    <button class="btn btn-icon" type="button" (click)="toggleOpen()">
      <i class="fa fa-angle-down" [ngClass]="{ 'fa-rotate-180': !isOpen }"></i>
    </button>
  </div>
  <ul
    class="filter-group__options"
    [formGroup]="form"
    *ngIf="isOpen && form?.controls"
  >
    <li *ngFor="let option of $any(filter.options); let i = index">
      <ng-container *ngIf="form.controls[i]">
        <input
          type="checkbox"
          [formControl]="$any(form.controls[i])"
          [id]="filterID + '_option_' + i"
        />
        <label [for]="filterID + '_option_' + i">
          <span
            *ngIf="option.color; else defaultCheckbox"
            class="colored-label"
            [title]="option.label"
            [style.--color]="option.color | rgb"
          >
            {{ option.label }}
          </span>
          <ng-template #defaultCheckbox>
            <span [innerHTML]="option.label | safeHtml"></span>
          </ng-template>
        </label>
      </ng-container>
    </li>
  </ul>
</div>
