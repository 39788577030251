angular.module('common').directive('historyItem', [function() {
  return {
    restrict: 'E',
    template: require('./history_item.html'),
    replace: true,
    scope: {
      entry: '=',
      onUpdate: '&',
      onDelete: '&'
    },
    link: function($scope) {
      $scope.I18n = I18n;

      $scope.update = function(entry) {
        $scope.onUpdate();
      };

      $scope.delete = function(entry) {
        $scope.onDelete();
      };
    }
  };
}]);
