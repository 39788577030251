angular.module('examManagement', [
  'common',
  'ngResource',
  'ngCookies',
  'ui.bootstrap',
  'ngFileUpload',
  'ui.router'
]);

require('./config');
require('./routes');

require('./actions/candidate_list');
require('./actions/exam_list');
require('./actions/exam_question_group_list');

require('./components/candidate_list');
require('./components/exam_list');
require('./components/exam_question_group_details');
require('./components/exam_question_group_list');
require('./components/exam_question_group_list_column_visibility');
require('./components/examinator_unlock_token');

require('./controllers/access_paper_form_ctrl');
require('./controllers/candidates_ctrl');
require('./controllers/candidates_import_form_ctrl');
require('./controllers/exam_book_form_ctrl');
require('./controllers/exam_form_ctrl');
require('./controllers/exam_provision_modal_ctrl');
require('./controllers/exam_tabs_ctrl');
require('./controllers/master_access_form_ctrl');
require('./controllers/question_group_ctrl');
require('./controllers/question_groups_ctrl');
require('./controllers/question_groups_presort_ctrl');
require('./controllers/self_assessment_modal_ctrl');
require('./controllers/statistics_import_ctrl');

require('./directives/exam_validations');

require('./reducers/candidate_list');
require('./reducers/exam_list');
require('./reducers/exam_management_root');
require('./reducers/exam_question_group_list');

require('./repositories/candidate');
require('./repositories/exam_book');
require('./repositories/exam_question_group');
require('./repositories/master_access_document');
require('./repositories/solution');

require('./selectors/candidate_list');
require('./selectors/exam_list');
require('./selectors/exam_question_group_list');

require('./services/candidate');
require('./services/confirm_messages');
require('./services/exam_presort');
require('./services/numerator');
require('./services/page_break_determinator');
require('./services/solution_csv_builder');
require('./services/tab_selection');
