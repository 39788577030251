angular.module('common').filter('numberOrPlaceholder', ['numberFilter', function(numberFilter) {
  return function(input, precision) {
    if (!isNaN(input) && input !== null) {
      return numberFilter(input, precision);
    } else if (angular.isDefined(input) && input !== null && input !== '') {
      return input;
    } else {
      return "-";
    }
  };
}]);
