import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { AssignExamModalComponent } from './../modals/assign-exam-modal/assign-exam-modal.component';

angular
  .module('common')
  .directive(
    'coAssignExamModal',
    downgradeComponent({ component: AssignExamModalComponent })
  );
