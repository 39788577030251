angular.module('examManagement').controller('MasterAccessFormCtrl', [
  '$scope',
  '$uibModalInstance',
  'Repositories::MasterAccessDocument',
  'File',
  'Store',
  'Selectors::ExamList',
  function($scope, $uibModalInstance, Repository, File, Store, ExamSelectors) {
    function getSelectedExam() {
      return ExamSelectors.getSelectedItem(Store.getState());
    }

    function getBeginningOfDay() {
      return new Date(1970, 0, 1, 0, 0, 0);
    }

    function getEndOfDay() {
      return new Date(1970, 0, 1, 23, 59, 0);
    }

    function updateDateWithTime(date, time) {
      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());
      date.setSeconds(time.getSeconds());
    }

    function convertToDate(date) {
      if (angular.isDate(date)) {
        return date;
      } else {
        return new Date(date);
      }
    }

    $scope.I18n = I18n;
    $scope.errors = {};
    $scope.exam = getSelectedExam();
    $scope.accessSettings = {
      mode: 'today',
      validFrom: null,
      validTo: null,
      maxLogins: 999,
      setModeToToday: function() {
        $scope.accessSettings.validFrom = new Date();
        $scope.accessSettings.validFromTime = getBeginningOfDay();
        $scope.accessSettings.validTo = new Date();
        $scope.accessSettings.validToTime = getEndOfDay();
      },
      setModeToExamDate: function() {
        var exam = $scope.exam;
        $scope.accessSettings.validFrom = new Date(exam.date);
        $scope.accessSettings.validFromTime = getBeginningOfDay();
        $scope.accessSettings.validTo = new Date(exam.date);
        $scope.accessSettings.validToTime = getEndOfDay();
      }
    };
    $scope.accessSettings.setModeToToday();

    $scope.isDateToday = function() {
      // the datepicker sometimes returns a string instead of a date
      var validFrom = convertToDate($scope.accessSettings.validFrom),
        validTo = convertToDate($scope.accessSettings.validTo),
        today = new Date();

      return (
        validFrom.getFullYear() == today.getFullYear() &&
        validFrom.getMonth() == today.getMonth() &&
        validFrom.getDate() == today.getDate() &&
        validTo.getFullYear() == today.getFullYear() &&
        validTo.getMonth() == today.getMonth() &&
        validTo.getDate() == today.getDate()
      );
    };

    $scope.isDateAtExamDate = function() {
      // the datepicker sometimes returns a string instead of a date
      var validFrom = convertToDate($scope.accessSettings.validFrom),
        validTo = convertToDate($scope.accessSettings.validTo),
        examDate = new Date($scope.exam.date);

      return (
        validFrom.getFullYear() == examDate.getFullYear() &&
        validFrom.getMonth() == examDate.getMonth() &&
        validFrom.getDate() == examDate.getDate() &&
        validTo.getFullYear() == examDate.getFullYear() &&
        validTo.getMonth() == examDate.getMonth() &&
        validTo.getDate() == examDate.getDate()
      );
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

    $scope.confirm = function() {
      var requestParams,
        errorCallback,
        successCallback,
        validFrom = convertToDate($scope.accessSettings.validFrom),
        validFromTime = convertToDate($scope.accessSettings.validFromTime),
        validTo = convertToDate($scope.accessSettings.validTo),
        validToTime = convertToDate($scope.accessSettings.validToTime);

      updateDateWithTime(validFrom, validFromTime);
      updateDateWithTime(validTo, validToTime);

      requestParams = {
        validFrom: validFrom ? validFrom.getTime() : null,
        validTo: validTo ? validTo.getTime() : null,
        maxLogins: $scope.accessSettings.maxLogins
      };

      successCallback = function(data) {
        $uibModalInstance.close();
        File.download(data.url);
      };

      errorCallback = function(data) {
        $scope.errors = data.fields;
      };

      Repository.get(
        $scope.exam.id,
        requestParams.validFrom,
        requestParams.validTo,
        requestParams.maxLogins
      )
        .then(successCallback)
        .catch(errorCallback);
    };
  }
]);
