import * as Types from '../../../../../generated/base-types';
import { MoveDirection, Scalars } from '../../../../../generated/base-types';
import { ManagerError } from '../../../../common/utils/error-parser';
import { RevisionStatusListElementFragment } from '../../../services/load-revision-status.generated';

export class LoadRevisionStatus {
  public static readonly type = '[RevisionStatus] Load';
}

export class LoadRevisionStatusSuccess {
  public static readonly type = '[RevisionStatus] Load Success';
  constructor(
    public readonly revisionStatus: RevisionStatusListElementFragment[]
  ) {}
}

export class LoadRevisionStatusFailure {
  public static readonly type = '[RevisionStatus] Load Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdateRevisionStatus {
  public static readonly type = '[RevisionStatus] Update';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly attributes: Types.RevisionStatusInput
  ) {}
}

export class UpdateRevisionStatusSuccess {
  public static readonly type = '[RevisionStatus] Update Success';
  constructor(
    public readonly revisionStatus: RevisionStatusListElementFragment
  ) {}
}

export class UpdateRevisionStatusFailure {
  public static readonly type = '[RevisionStatus] Update Failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeleteRevisionStatus {
  public static readonly type = '[RevisionStatus] Delete';
  constructor(public readonly id: string) {}
}

export class DeleteRevisionStatusSuccess {
  public static readonly type = '[RevisionStatus] Delete Success';
  constructor(public readonly id: string) {}
}

export class DeleteRevisionStatusFailure {
  public static readonly type = '[RevisionStatus] Delete Failure';
  constructor(public readonly error: ManagerError) {}
}

export class CreateRevisionStatus {
  public static readonly type = '[RevisionStatus] Create Revision Status';
  constructor(public readonly attributes: Types.RevisionStatusInput) {}
}

export class CreateRevisionStatusSuccess {
  public static readonly type =
    '[RevisionStatus] Create Revision Status Success';
  constructor(
    public readonly revisionStatus: RevisionStatusListElementFragment
  ) {}
}

export class CreateRevisionStatusFailure {
  public static readonly type =
    '[RevisionStatus] Create Revision Status Failure';
  constructor(public readonly error: ManagerError) {}
}

export class SetRevisionStatusPosition {
  public static readonly type = '[RevisionStatus] Set Revision Status Position';
  constructor(
    public readonly id: Scalars['ID'],
    public readonly direction: MoveDirection,
    public readonly times?: number
  ) {}
}

export class SetRevisionStatusPositionSuccess {
  public static readonly type =
    '[RevisionStatus] Set Revision Status Position Success';
  constructor(
    public readonly revisionStatus: RevisionStatusListElementFragment,
    public readonly revisionStatusList: RevisionStatusListElementFragment[]
  ) {}
}

export class SetRevisionStatusPositionFailure {
  public static readonly type =
    '[RevisionStatus] Set Revision Status Position Failure';
  constructor(public readonly error: ManagerError) {}
}
