import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadAnnouncements } from '../state/announcements/announcements.actions';

@Injectable({ providedIn: 'root' })
export class AnnouncementsGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    this.store.dispatch(new LoadAnnouncements());

    return true;
  }
}
