angular.module('examManagement').factory('Selectors::ExamList', [
  function() {
    function getItems(object) {
      return (object && object.examList && object.examList.items) || [];
    }

    function getExam(object, id) {
      return getItems(object).find(function(item) {
        return item.id == id;
      });
    }

    function isRequestInProgress(object) {
      return (object && object.examList && object.examList.requestInProgress) || false;
    }

    function getSelection(object) {
      return object && object.examList && object.examList.selection;
    }

    function extractSelectedItem(selection, items) {
      return items.find(function(item) {
        return item.id == selection;
      });
    }

    return {
      getItems: getItems,
      getExam: getExam,
      isRequestInProgress: isRequestInProgress,
      getSelection: getSelection,
      getSelectedItem: Reselect.createSelector(getSelection, getItems, extractSelectedItem)
    };
  }
]);
