import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { PermissionRole } from 'src/generated/base-types';

type RoleOption = { label: string; value: string };

@Component({
  selector: 'man-role-dropdown',
  templateUrl: './role-dropdown.component.html',
  styleUrls: ['./role-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RoleDropdownComponent)
    }
  ]
})
export class RoleDropdownComponent {
  public value?: RoleOption;
  public touched = false;
  public disabled = false;
  public onChange: (value: string) => void;
  public onTouched: () => void;

  public roles$: Observable<RoleOption[]>;

  constructor(private translate: TranslateService) {
    this.roles$ = this.translate
      .get(
        Object.values(PermissionRole).map(role => `common.permissions.${role}`)
      )
      .pipe(
        map(tranlations => {
          return Object.keys(tranlations).map(key => ({
            label: tranlations[key],
            value: key.split('.').pop()!
          }));
        })
      );
  }

  public writeValue(value: RoleOption): void {
    this.value = value;
  }

  public registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public onRoleChange(event: string): void {
    this.onChange(event);
  }
}
