import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { Scalars } from '../../../generated/base-types';
import { UiRouterService } from '../../common/services/ui-router.service';

@Component({
  selector: 'qf-question-navigation',
  templateUrl: './question-navigation.component.html',
  styleUrls: ['./question-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionNavigationComponent implements OnInit {
  @HostBinding('class')
  public cssClass = 'layout--horizontal center-block';

  // we're using these Inputs and Outputs because only the parent component EditQuestionGroupComponent
  // knows whether there are unsaved changes in the form
  @Output() public nextToggled = new EventEmitter<void>();
  @Output() public previousToggled = new EventEmitter<void>();
  @Input() public toggleNext: Observable<void>;
  @Input() public togglePrevious: Observable<void>;

  public currentPosition: number;
  public firstPosition: boolean;
  public lastPosition: boolean;

  // virtual fields for getters and setters
  private visitableIdsInternal: Scalars['ID'][];
  private currentIdInternal: Scalars['ID'];

  @Input()
  public set visitableIds(ids: Scalars['ID'][]) {
    this.visitableIdsInternal = ids;
    this.updateCurrentPosition();
  }
  public get visitableIds(): Scalars['ID'][] {
    return this.visitableIdsInternal;
  }

  @Input()
  public set currentId(id: Scalars['ID']) {
    this.currentIdInternal = id;
    this.updateCurrentPosition();
  }
  public get currentId(): Scalars['ID'] {
    return this.currentIdInternal;
  }

  constructor(private readonly uiRouterService: UiRouterService) {}

  public ngOnInit(): void {
    this.toggleNext.subscribe((): void => this.onNext());
    this.togglePrevious.subscribe((): void => this.onPrevious());
  }

  public onNext(): void {
    const currentIndex = this.visitableIds.indexOf(this.currentId);
    const nextID = this.visitableIds[currentIndex + 1];
    this.uiRouterService.go(
      'editQuestionForm',
      { id: nextID },
      { notify: false, replace: true }
    );
  }

  public onPrevious(): void {
    const currentIndex = this.visitableIds.indexOf(this.currentId);
    const previousID = this.visitableIds[currentIndex - 1];
    this.uiRouterService.go(
      'editQuestionForm',
      { id: previousID },
      { notify: false, replace: true }
    );
  }

  private updateCurrentPosition(): void {
    this.currentPosition = this.visitableIds.indexOf(this.currentId) + 1;
    this.firstPosition = this.currentPosition === 1;
    this.lastPosition = this.currentPosition === this.visitableIds.length;
  }
}
