<ng-container *ngIf="subjects$ | async as subjects">
  <p-dropdown
    [placeholder]="'common.messages.no_selection' | translate"
    [options]="subjects"
    [optionLabel]="'name'"
    [optionValue]="'id'"
    [filter]="true"
    [filterBy]="'name'"
    [(ngModel)]="selectedSubjectId"
    (ngModelChange)="onSubjectChange($event)"
    [showClear]="true"
  >
  </p-dropdown>
</ng-container>
