angular.module('common').directive('showOnTableRowHover', function () {
  function findNearestRow(element) {
    var currentElement = element;
    while(currentElement.prop('tagName') != 'HTML' && currentElement.prop('tagName') != 'TR') {
      currentElement = currentElement.parent();
    }
    return currentElement;
  }

  return {
    restrict: 'A',
    link: function ($scope, $element) {
      var rowElement = findNearestRow($element);

      $element.addClass('ng-hide');

      rowElement.on('mouseenter', function () {
        $element.removeClass('ng-hide');
      });
      rowElement.on('mouseleave', function () {
        $element.addClass('ng-hide');
      });
    }
  };
});
