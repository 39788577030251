import { ExamMetadataFragment } from 'src/app/services/load-exam.generated';
import { Scalars } from 'src/generated/base-types';
import { ManagerError } from '../../common/utils/error-parser';

export class LoadExam {
  public static readonly type = '[Exam List] Load Exam';
  constructor(public readonly id: Scalars['ID']) {}
}

export class LoadExamSuccess {
  public static readonly type = '[Exam List] Load Exam Success';
  constructor(public readonly exam: ExamMetadataFragment) {}
}

export class LoadExamFailure {
  public static readonly type = '[Exam List] Load Exam Failure';

  constructor(public readonly error: ManagerError) {}
}

export class SetSelectedExamId {
  public static readonly type = '[Exam List] Set Selected Exam ID';
  constructor(public readonly id: Scalars['ID']) {}
}

export class SetLoadedExamIds {
  public static readonly type = '[Exam List] Set Loaded Exam IDs';
  constructor(public readonly ids: Scalars['ID'][]) {}
}

export class SetSelectedExamQuestionGroupId {
  public static readonly type = '[Exam List] Set Selected Question Group ID';
  constructor(public readonly id: Scalars['ID']) {}
}

export class SetLoadedExamQuestionGroupIds {
  public static readonly type = '[Exam List] Set Loaded Question Group IDs';
  constructor(public readonly ids: Scalars['ID'][]) {}
}
