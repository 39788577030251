angular.module('examManagement').factory('Actions::CandidateList', function() {
  var actions = {
    SET_SELECTION: 'CANDIDATE_LIST_SET_SELECTION',
    ADD_TO_SELECTION: 'CANDIDATE_LIST_ADD_TO_SELECTION',
    REMOVE_FROM_SELECTION: 'CANDIDATE_LIST_REMOVE_FROM_SELECTION',
    SET_ORDER: 'CANDIDATE_LIST_SET_ORDER',
    SET_REQUEST_IN_PROGRESS: 'CANDIDATE_LIST_SET_REQUEST_IN_PROGRESS',
    FETCH_CANDIDATES_REQUEST: 'CANDIDATE_LIST_FETCH_CANDIDATES_REQUEST',
    FETCH_CANDIDATES_SUCCESS: 'CANDIDATE_LIST_FETCH_CANDIDATES_SUCCESS',
    DELETE_CANDIDATES: 'CANDIDATE_LIST_DELETE_CANDIDATES'
  };

  function fetchCandidatesRequest() {
    return {
      type: actions.FETCH_CANDIDATES_REQUEST
    };
  }

  function fetchCandidatesSuccess(candidates) {
    return {
      type: actions.FETCH_CANDIDATES_SUCCESS,
      candidates: candidates
    };
  }

  function setRequestInProgress(state) {
    return {
      type: actions.SET_REQUEST_IN_PROGRESS,
      requestInProgress: state
    };
  }

  function setSelection(ids) {
    return {
      type: actions.SET_SELECTION,
      ids: ids
    };
  }

  function addToSelection(ids) {
    return {
      type: actions.ADD_TO_SELECTION,
      ids: ids
    };
  }

  function removeFromSelection(id) {
    return {
      type: actions.REMOVE_FROM_SELECTION,
      id: id
    };
  }

  function setOrder(order) {
    return {
      type: actions.SET_ORDER,
      order: order
    };
  }

  function deleteCandidates(candidateIds) {
    return {
      type: actions.DELETE_CANDIDATES,
      candidateIds: candidateIds
    };
  }

  return Object.assign(
    {
      setSelection: setSelection,
      addToSelection: addToSelection,
      removeFromSelection: removeFromSelection,
      setOrder: setOrder,
      setRequestInProgress: setRequestInProgress,
      fetchCandidatesRequest: fetchCandidatesRequest,
      fetchCandidatesSuccess: fetchCandidatesSuccess,
      deleteCandidates: deleteCandidates
    },
    actions
  );
});
