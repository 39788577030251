import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import {
  requestStateFailure,
  requestStateSuccess
} from '../../../common/utils/remote-data-utils';
import { UserFormState } from '../../state/user-form.state';
import { LoadUserPermissions } from '../../state/user-permissions.actions';

@Injectable({ providedIn: 'root' })
export class UserPermissionsGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(UserFormState.user).pipe(
      filter(
        requestData =>
          requestStateSuccess(requestData) || requestStateFailure(requestData)
      ),
      take(1),
      tap(({ data }) => {
        // Just pass if user could not be loaded because the parent route (user route) already handles this case
        if (data?.id === undefined) {
          return;
        }

        this.store.dispatch(new LoadUserPermissions(data.id));
      }),
      map(() => true)
    );
  }
}
