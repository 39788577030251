import { QuestionFilters } from './question-filter.state.model';

export class QuestionFilterUpdate {
  public static readonly type = '[QuestionManagement Filter] Update';
  constructor(public readonly filter: QuestionFilters) {}
}

export class QuestionFilterReset {
  public static readonly type = '[QuestionManagement Filter] Reset';
}

export class ToggleFilterPanel {
  public static readonly type = '[QuestionManagement Filter] Toggle Panel';
}
