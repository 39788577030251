import { Injectable } from '@angular/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { RemoteData } from '../../common/utils/remote-data';
import { isVoid } from '../../common/utils/type-guards/voidable';
import { AppContextState } from '../../state/app-context.state';
import { LoadListQuestionGroupGQL } from '../question-form-toolbar/load-list-question-group.generated';
import { QuestionGroupListElementFragment } from '../services/load-question-group-list.generated';
import {
  LoadQuestionGroup,
  LoadQuestionGroupFailure,
  LoadQuestionGroupSuccess
} from './question-group.actions';

export interface QuestionGroupStateModel {
  questionGroup: RemoteData<QuestionGroupListElementFragment>;
}

@State<QuestionGroupStateModel>({
  name: 'questionGroup',
  defaults: {
    questionGroup: {
      requestState: 'initial'
    }
  }
})
@Injectable({
  providedIn: 'root'
})
export class QuestionGroupState {
  constructor(
    private readonly loadListQuestionGroupGQL: LoadListQuestionGroupGQL,
    private readonly store: Store
  ) {}

  @Selector()
  public static questionGroup(
    state: QuestionGroupStateModel
  ): RemoteData<QuestionGroupListElementFragment> {
    return state.questionGroup;
  }

  @Action(LoadQuestionGroup, { cancelUncompleted: true })
  public loadQuestionGroup(
    ctx: StateContext<QuestionGroupStateModel>,
    action: LoadQuestionGroup
  ): Observable<void> {
    ctx.patchState({
      questionGroup: {
        requestState: 'loading'
      }
    });
    const poolId = this.store.selectSnapshot(AppContextState.currentPoolId);

    if (isVoid(poolId)) {
      throw 'Pool ID not found!';
    }

    return this.loadListQuestionGroupGQL
      .fetch({ poolId, questionGroupId: action.id })
      .pipe(
        take(1),
        switchMap(result => {
          if (isVoid(result.data.pool.questionGroup)) {
            return ctx.dispatch(new LoadQuestionGroupFailure());
          }

          return ctx.dispatch(
            new LoadQuestionGroupSuccess(result.data.pool.questionGroup)
          );
        }),
        catchError(() => {
          return ctx.dispatch(new LoadQuestionGroupFailure());
        })
      );
  }

  @Action(LoadQuestionGroupFailure)
  public loadQuestionGroupFailure(
    ctx: StateContext<QuestionGroupStateModel>,
    _action: LoadQuestionGroupFailure
  ): void {
    ctx.patchState({
      questionGroup: {
        requestState: 'failure'
      }
    });
  }

  @Action(LoadQuestionGroupSuccess)
  public loadQuestionGroupSuccess(
    ctx: StateContext<QuestionGroupStateModel>,
    action: LoadQuestionGroupSuccess
  ): void {
    ctx.patchState({
      questionGroup: {
        requestState: 'success',
        data: action.questionGroup
      }
    });
  }
}
