import type { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { ApolloError } from '@apollo/client/core';
import { GraphQLError } from 'graphql';
import { Observable } from 'rxjs';
import { asValidationError } from '../types/validation-error-types';
import { isDefined } from '../utils/type-guards/is-defined';
import { isOfTypeByProperty } from '../utils/type-guards/is-of-type-by-property';
import { isVoid } from '../utils/type-guards/voidable';

/**
 * This operator is related to graphQL and throw an error for:
 * - errors in the response object (fetch and mutate operations)
 * - null|undefined data values in the response object (mutate operations)
 *
 * All errors can be handled in a `catchError` operator while following operators getting only
 * the data from the response object.
 *
 * Error mapping:
 * - an error in the response object is mapped to an ApolloError
 * - the first error of errors in the response object is mapped to a GraphQLError.
 * - thrown errors are mapped to an ApolloError if the they match the shape of an ApolloError
 * - null|undefined values are mapped to an Error
 */
export function takeGraphQLResult() {
  return function <T>(
    source: Observable<ApolloQueryResult<T> | FetchResult<T>>
  ): Observable<T> {
    return new Observable(subscriber => {
      return source.subscribe({
        next(result) {
          if (
            isDefined(result.data) &&
            isDefined(Object.values(result.data)[0]?.validations)
          ) {
            // NOTE: create an instance of an GraphQLValidationError so instanceof can be used later
            subscriber.error(
              asValidationError(Object.values(result.data)[0]?.validations)
            );
          } else if ('error' in result && isDefined(result.error)) {
            // NOTE: create an instance of an ApolloError so instanceof can be used later
            subscriber.error(asApolloError(result.error));
          } else if (result.errors?.[0]) {
            // NOTE: create an instance of a GraphQLError so instanceof can be used later
            subscriber.error(asGraphQLError(result.errors[0]));
          } else {
            const { data } = result;

            isVoid(data)
              ? subscriber.error(new Error('No data received'))
              : subscriber.next(data);
          }
        },
        error(error: unknown) {
          // NOTE: The error can be an ApolloError but doesn't need to be
          subscriber.error(
            isOfTypeByProperty<ApolloError>(
              error,
              'networkError',
              'graphQLErrors',
              'clientErrors',
              'extraInfo'
            )
              ? asApolloError(error)
              : error
          );
        },
        complete() {
          subscriber.complete();
        }
      });
    });
  };
}

function asGraphQLError({
  message,
  nodes,
  source,
  positions,
  path,
  originalError,
  extensions
}: GraphQLError): GraphQLError {
  return new GraphQLError(
    message,
    nodes,
    source,
    positions,
    path,
    originalError,
    extensions
  );
}

function asApolloError(error: ApolloError): ApolloError {
  return new ApolloError(error);
}
