<label>{{ 'questions.edit.solution_text' | translate }}</label>
<div
  class="skin-container skin-container--default button-container form-control"
  #solutionSelection
>
  <div
    class="line-container"
    *ngFor="let responseOption of responseOptions; let index = index"
  >
    <div>
      <button
        class="btn btn-secondary-migrated"
        [ngClass]="{ active: index === solution }"
        (click)="setAnswer(index)"
      >
        <span *ngIf="index === solution">
          {{ 'question_groups.edit.correct' | translate }}
        </span>
        <span *ngIf="index !== solution">
          {{ 'question_groups.edit.incorrect' | translate }}
        </span>
      </button>
    </div>

    <div>
      <qf-icon-feedback
        [validations]="validations?.eSolution"
      ></qf-icon-feedback>
    </div>

    <span class="bold center">
      {{ index | indexAsCharacter }}
    </span>

    <span>
      {{
        'question_groups.edit.type_e_descriptives_notation.' + index
          | translateToLanguage : language
          | async
      }}
    </span>

    <span class="layout-cell--resizable">
      {{
        'question_groups.edit.type_e_descriptives.' + index
          | translateToLanguage : language
          | async
      }}
    </span>
  </div>
</div>
<qf-input-feedback
  [validations]="validations?.eSolution"
  [inputField]="solutionSelection"
></qf-input-feedback>
