import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { TaskQuestionGroupExportModalComponent } from '../modals/task-question-group-export-modal/task-question-group-export-modal.component';

angular
  .module('taskManagement')
  .directive(
    'tmQuestionGroupExportModal',
    downgradeComponent({ component: TaskQuestionGroupExportModalComponent })
  );
