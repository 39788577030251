import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { RevisionDocumentModalComponent } from '../modals/revision-document-modal/revision-document-modal.component';

angular
  .module('common')
  .directive(
    'coRevisionDocumentModal',
    downgradeComponent({ component: RevisionDocumentModalComponent })
  );
