angular.module('common').factory('notifications', function() {
  function Notifications() {
    this.messages = [];
  }

  Notifications.prototype.add = function(content, type) {
    if (type === undefined) {
      type = 'info';
    }
    this.messages.unshift({content: content, type: type});
    if (this.onAdd) {
      this.onAdd();
    }
  };

  Notifications.prototype.addSuccess = function(content) {
    this.add(content, 'success');
  };

  Notifications.prototype.addInfo = function(content) {
    this.add(content, 'info');
  };

  Notifications.prototype.addWarning = function(content) {
    this.add(content, 'warning');
  };

  Notifications.prototype.addAlert = function(content) {
    this.add(content, 'alert');
  };

  Notifications.prototype.pop = function() {
    this.messages.pop();
  };

  Notifications.prototype.remove = function(index) {
    this.messages.splice(index, 1);
  };

  Notifications.prototype.get = function() {
    return this.messages;
  };

  Notifications.prototype.onAdd = function(callback) {
    this.onAdd = callback;
  };

  return new Notifications();
});
