angular.module('common').factory('Reducers::PoolSelection', [
  'Actions::Pool',
  function(Actions) {
    return function(state, action) {
      switch (action.type) {
        case Actions.SET_CURRENT_POOL:
          return Object.assign({}, state, { currentPool: action.pool });

        default:
          return state;
      }
    };
  }
]);
