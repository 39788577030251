/* eslint-disable unicorn/no-null */
import { Dimension, QuestionGroupInput } from '../../../generated/base-types';

export function preselectTruncatedDimensions(
  input: QuestionGroupInput,
  dimensions: Dimension[]
): QuestionGroupInput {
  const changedInput = { ...input };
  const truncatedDimensions = dimensions.filter(
    dimension => dimension.truncated
  );

  changedInput.questions = (changedInput.questions || []).map(question => {
    return {
      ...question,
      category0Id: truncatedDimensions.find(
        dimension => dimension.position === 0
      )?.categories[0]?.id,
      category1Id: truncatedDimensions.find(
        dimension => dimension.position === 1
      )?.categories[0]?.id,
      category2Id: truncatedDimensions.find(
        dimension => dimension.position === 2
      )?.categories[0]?.id,
      category3Id: truncatedDimensions.find(
        dimension => dimension.position === 3
      )?.categories[0]?.id,
      category4Id: truncatedDimensions.find(
        dimension => dimension.position === 4
      )?.categories[0]?.id,
      category5Id: truncatedDimensions.find(
        dimension => dimension.position === 5
      )?.categories[0]?.id,
      category6Id: truncatedDimensions.find(
        dimension => dimension.position === 6
      )?.categories[0]?.id,
      category7Id: truncatedDimensions.find(
        dimension => dimension.position === 7
      )?.categories[0]?.id
    };
  });

  return changedInput;
}
