<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_management.toolbar.for_exam' | translate }}</h3>
  </div>
  <div class="modal-body">
    <div *ngFor="let exam of exams">
      <co-radio-button-group
        [(ngModel)]="actions[exam.id]"
        [ngModelOptions]="{ standalone: true }"
      >
        <button
          class="btn btn-secondary-migrated"
          [value]="assignmentActions.IGNORE"
          title="{{ 'common.actions.no_action' | translate }}"
        >
          <i class="fa fa-circle"></i>
        </button>
        <button
          class="btn btn-secondary-migrated"
          [value]="assignmentActions.REMOVE"
          title="{{ 'common.actions.remove' | translate }}"
        >
          <i class="fa fa-times"></i>
        </button>
        <button
          class="btn btn-secondary-migrated"
          [value]="assignmentActions.ADD"
          title="{{ 'common.actions.add' | translate }}"
        >
          <i class="fa fa-check"></i>
        </button>
      </co-radio-button-group>
      <p>
        {{ exam.name }}
      </p>
    </div>
    <p *ngIf="exams?.length == 0">
      {{ 'exams.no_exams_exists' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button (click)="dismiss()" class="btn btn-secondary-migrated">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      (click)="save()"
      [disabled]="isSaving"
      class="btn btn-primary-migrated"
    >
      {{ 'common.actions.save' | translate }}
    </button>
  </div>
</div>
