import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'co-base-confirm-modal',
  templateUrl: './base-confirm-modal.component.html',
  host: { class: 'bs4' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseConfirmModalComponent {
  @Input() public title?: string;
  @Input() public cancelButtonTranslationKey?: string;
  @Input() public confirmButtonTranslationKey?: string;

  @Output() public dismiss = new EventEmitter<void>();
  @Output() public confirm = new EventEmitter<void>();

  public onDismiss(): void {
    this.dismiss.emit();
  }

  public onConfirm(): void {
    this.confirm.emit();
  }
}
