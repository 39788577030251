import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { AnnouncementInput, Scalars } from '../../../generated/base-types';
import { FormState } from '../../common/types/form-types';
import { RemoteData } from '../../common/utils/remote-data';
import { ActionMenuOption } from '../common/action-menu/action-menu.component';
import { ModalService } from '../common/modal/modal.service';
import { AnnouncementListElementFragment as AnnouncementListElement } from '../services/load-announcements.generated';
import {
  CreateAnnouncement,
  DeleteAnnouncement,
  UpdateAnnouncement
} from '../state/announcements/announcements.actions';
import { AnnouncementsState } from '../state/announcements/announcements.state';
import { AnnouncementFormComponent } from './announcement-form/announcement-form.component';

@Component({
  templateUrl: './announcements.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'container content' }
})
export class AnnouncementsComponent implements OnDestroy {
  @ViewChild(AnnouncementFormComponent)
  public formComponent: AnnouncementFormComponent;

  public readonly announcements$: Observable<
    RemoteData<AnnouncementListElement[]>
  >;
  public readonly formState$: Observable<FormState<AnnouncementListElement>>;

  public readonly announcementMenuOptions: ActionMenuOption[] = [
    {
      label: 'actions.edit',
      callback: (data: AnnouncementListElement) => this.onEdit(data)
    },
    {
      label: 'actions.delete',
      danger: true,
      callback: (data: AnnouncementListElement) => this.onDelete(data.id)
    }
  ];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.announcements$ = this.store.select(AnnouncementsState.announcements);
    this.formState$ = this.formState();
    this.dismissSidebarOnSuccessfulMutation();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onNew(): void {
    this.routeToForm('new');
  }

  public onEdit(data: AnnouncementListElement): void {
    this.routeToForm(data.id);
  }

  public onDismiss(): void {
    this.routeToForm();
  }

  public onClose(): void {
    this.formComponent.onClose();
  }

  public async onDelete(id: string): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();

    if (confirmDelete) this.store.dispatch(new DeleteAnnouncement(id));
  }

  public onCreate(announcementInput: AnnouncementInput): void {
    this.store.dispatch(new CreateAnnouncement(announcementInput));
  }

  public onUpdate({
    id,
    ...announcementInput
  }: AnnouncementInput & { id: Scalars['ID'] }): void {
    this.store.dispatch(new UpdateAnnouncement(id, announcementInput));
  }

  private formState(): Observable<FormState<AnnouncementListElement>> {
    const editingParam$ = this.route.queryParamMap.pipe(
      map(params => params.get('editing') ?? undefined)
    );
    const disabled$ = this.store
      .select(AnnouncementsState.mutationRequestState)
      .pipe(
        map(requestState => requestState === 'loading'),
        distinctUntilChanged()
      );

    return combineLatest([editingParam$, disabled$]).pipe(
      switchMap(([editingParam, disabled]) => {
        const show: boolean = editingParam !== undefined;
        const id = show && editingParam !== 'new' ? editingParam : undefined;

        // TODO: https://github.com/iml-it/measured-manager/issues/2851
        return this.announcements$.pipe(
          filter(({ requestState }) => requestState === 'success'),
          take(1),
          map(({ data }) =>
            id === undefined ? undefined : data?.find(a => a.id === id)
          ),
          map(data => ({ show, disabled, data }))
        );
      })
    );
  }

  private dismissSidebarOnSuccessfulMutation(): void {
    this.store
      .select(AnnouncementsState.mutationRequestState)
      .pipe(
        // NOTE:
        // The problem is that if we get to the page and the "mutationState" is "Success", "onDismiss" is called and therefore navigate.
        // If navigate is called durring navigation processing, this navigation is canceled.
        // So we need to wait for the "mutationState" to change from "Loading" to "Success" and only navigate then.
        pairwise(),
        filter(
          ([lastState, currentState]) =>
            lastState === 'loading' && currentState === 'success'
        ),
        tap({
          next: () => this.onDismiss()
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private routeToForm(editing?: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { editing },
      queryParamsHandling: 'merge'
    });
  }
}
