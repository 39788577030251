/* eslint-disable @typescript-eslint/no-explicit-any */

// add AngularJS components here which you want to access in Angular 2
import { Injector } from '@angular/core';
import { Notifications } from './common/services/notifications';
import { UIRouter } from './common/services/ui-router.service';

export enum LegacyProvider {
  Store = 'Store',
  SelectorExamQuestionGroupList = 'Selectors::ExamQuestionGroupList',
  SelectorTaskQuestionGroupList = 'Selectors::TaskQuestionGroupList',
  SelectorExamList = 'Selectors::ExamList',
  SelectorPool = 'Selectors::Pool',
  SelectorTaskList = 'Selectors::TaskList',
  SelectorUser = 'Selectors::User',

  ActionQuestionGroup = 'Actions::QuestionGroup',
  ActionTaskQuestionGroupList = 'Actions::TaskQuestionGroupList',

  RepositoryTask = 'Task',
  RepositoryTaskQuestionGroup = 'Repositories::TaskQuestionGroup'
}

function storeFactory(injector: Injector): any {
  return injector.get(LegacyProvider.Store);
}

export const ajsStore = {
  provide: LegacyProvider.Store,
  useFactory: storeFactory,
  deps: ['$injector']
};

function userSelectorsFactory(injector: Injector): any {
  return injector.get(LegacyProvider.SelectorUser);
}

export const ajsUserSelectors = {
  provide: LegacyProvider.SelectorUser,
  useFactory: userSelectorsFactory,
  deps: ['$injector']
};

function taskManagementTaskListSelectorsFactory(injector: Injector): any {
  return injector.get(LegacyProvider.SelectorTaskList);
}

export const ajsTaskManagementTaskListSelectors = {
  provide: LegacyProvider.SelectorTaskList,
  useFactory: taskManagementTaskListSelectorsFactory,
  deps: ['$injector']
};

function taskManagementQuestionGroupListSelectorsFactory(
  injector: Injector
): any {
  return injector.get(LegacyProvider.SelectorTaskQuestionGroupList);
}

export const ajsTaskManagementQuestionGroupListSelectors = {
  provide: LegacyProvider.SelectorTaskQuestionGroupList,
  useFactory: taskManagementQuestionGroupListSelectorsFactory,
  deps: ['$injector']
};

function examManagementExamListSelectorsFactory(injector: Injector): any {
  return injector.get(LegacyProvider.SelectorExamList);
}

export const ajsExamManagementExamListSelectors = {
  provide: LegacyProvider.SelectorExamList,
  useFactory: examManagementExamListSelectorsFactory,
  deps: ['$injector']
};

function ajsNotificationsFactory(injector: Injector): Notifications {
  return injector.get('notifications');
}

export const ajsNotifications = {
  provide: Notifications,
  useFactory: ajsNotificationsFactory,
  deps: ['$injector']
};

export const ajsPoolSelector = {
  provide: LegacyProvider.SelectorPool,
  useFactory(injector: Injector): any {
    return injector.get(LegacyProvider.SelectorPool);
  },
  deps: ['$injector']
};

function stateFactory(injector: Injector): any {
  return injector.get('$state');
}

export const ajsState = {
  provide: UIRouter,
  useFactory: stateFactory,
  deps: ['$injector']
};

export const ajsCommonQuestionGroupActions = {
  provide: LegacyProvider.ActionQuestionGroup,
  useFactory(injector: Injector): any {
    return injector.get(LegacyProvider.ActionQuestionGroup);
  },
  deps: ['$injector']
};

export const ajsTaskQuestionGroupActions = {
  provide: LegacyProvider.ActionTaskQuestionGroupList,
  useFactory(injector: Injector): any {
    return injector.get(LegacyProvider.ActionTaskQuestionGroupList);
  },
  deps: ['$injector']
};

export const ajsTaskRepository = {
  provide: LegacyProvider.RepositoryTask,
  useFactory(injector: Injector): any {
    return injector.get(LegacyProvider.RepositoryTask);
  },
  deps: ['$injector']
};

export const ajsTaskQuestionGroupRepository = {
  provide: LegacyProvider.RepositoryTaskQuestionGroup,
  useFactory(injector: Injector): any {
    return injector.get(LegacyProvider.RepositoryTaskQuestionGroup);
  },
  deps: ['$injector']
};

function examManagementQuestionGroupListSelectorsFactory(
  injector: Injector
): any {
  return injector.get(LegacyProvider.SelectorExamQuestionGroupList);
}

export const ajsExamManagementQuestionGroupListSelectors = {
  provide: LegacyProvider.SelectorExamQuestionGroupList,
  useFactory: examManagementQuestionGroupListSelectorsFactory,
  deps: ['$injector']
};

export const ajsUpgradedProviders = [
  ajsStore,
  ajsUserSelectors,
  ajsPoolSelector,
  ajsNotifications,
  ajsState,
  ajsCommonQuestionGroupActions,
  ajsTaskRepository,
  ajsTaskManagementTaskListSelectors,
  ajsTaskQuestionGroupActions,
  ajsExamManagementExamListSelectors,
  ajsTaskManagementQuestionGroupListSelectors,
  ajsTaskQuestionGroupRepository,
  ajsExamManagementQuestionGroupListSelectors
];
