angular.module('common').factory('BasicErrorHandler', [function () {
  var typePriorities = {
    'alert': 1,
    'warning': 2,
    'info': 3
  };

  function sanitizeMessageType (type) {
    if (['alert', 'warning', 'info'].indexOf(type) > -1) {
      return type;
    }
    else {
      return 'info';
    }
  }

  function BasicErrorHandler (element, options) {
    this.element = element;
    this.options = options;
    this.messages = [];
    this.onShowMessageCallbacks = [];
  }

  BasicErrorHandler.prototype.onShowMessage = function (callback) {
    this.onShowMessageCallbacks.push(callback);
  };

  BasicErrorHandler.prototype._notifyOnShowMessage = function (message) {
    for(var i = 0; i < this.onShowMessageCallbacks.length; i++) {
      this.onShowMessageCallbacks[i](message);
    }
  };

  BasicErrorHandler.prototype.setErrorMessages = function (messages) {
    this.messages = messages;
    this.initialFieldValue = this.readFieldValue();
  };

  BasicErrorHandler.prototype.removeErrorMessage = function () {
    // remove the outline from the input field
    this.element.find(this.options.fieldSelector)
      .removeClass('skin-inline-error__outline skin-inline-error__outline--alert skin-inline-error__outline--warning  skin-inline-error__outline--info');

    // remove the icon from the input field
    this.element.find(this.options.iconSelector)
      .addClass('ng-hide')
      .removeClass('skin-inline-error__icon skin-inline-error__icon--alert skin-inline-error__icon--warning  skin-inline-error__icon--info');

    // remove the tooltip
    this.element.find(this.options.tooltipSelector)
      .removeClass('skin-tooltip--alert skin-tooltip--warning skin-tooltip--info');

    this._notifyOnShowMessage(null);
  };

  BasicErrorHandler.prototype.displayErrorMessage = function () {
    var message = this.getMostImportantMessage();
    this._notifyOnShowMessage(message);

    // add an outline to the input field
    this.element.find(this.options.fieldSelector)
      .addClass('skin-inline-error__outline skin-inline-error__outline--' + sanitizeMessageType(message.type));

    // add an icon to the input field
    this.element.find(this.options.iconSelector)
      .removeClass('ng-hide')
      .addClass('skin-inline-error__icon skin-inline-error__icon--' + sanitizeMessageType(message.type));

    // add the tooltip
    this.element.find(this.options.tooltipSelector)
      .addClass('skin-tooltip--' + sanitizeMessageType(message.type));
  };

  BasicErrorHandler.prototype.updateMessageVisibility = function () {
    if (!this.didFieldChange() && this.isMessagePresent()) {
      this.displayErrorMessage();
    }
    else {
      this.removeErrorMessage();
    }
  };

  BasicErrorHandler.prototype.didFieldChange = function () {
    return this.initialFieldValue !== this.readFieldValue();
  };

  BasicErrorHandler.prototype.isMessagePresent = function () {
    return this.getMostImportantMessage() !== undefined;
  };

  BasicErrorHandler.prototype.getMostImportantMessage = function () {
    this.messages.sort(function (a, b) {
      var aPriority = typePriorities[a.type], bPriority = typePriorities[b.type];
      return aPriority - bPriority;
    });
    return this.messages[0];
  };

  return BasicErrorHandler;
}]);
