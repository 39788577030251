angular.module('taskManagement').factory('Reducers::TaskList', [
  'Actions::TaskList',
  function (Actions) {
    function unique (element, index, array) {
      return array.lastIndexOf(element) == index;
    }

    function reducer (state, action) {
      var columns;

      state = state || {};

      switch (action.type) {
        case Actions.SET_COLUMN_DEFINITIONS:
          return Object.assign({}, state, {
            columns: action.columns
          });

        case Actions.SET_COLUMN_VISIBILITY:
          columns = Object.assign({}, state.columns);
          columns[action.column].visible = action.visible;
          return Object.assign({}, state, {
            columns: columns
          });

        case Actions.SET_SELECTION:
          return Object.assign({}, state, {selection: action.ids});

        case Actions.ADD_TO_SELECTION:
          return Object.assign({}, state, {
            selection: (state.selection || []).concat(action.ids).filter(unique)
          });

        case Actions.REMOVE_FROM_SELECTION:
          return Object.assign({}, state, {
            selection: (state.selection || []).filter(function (id) {
              return id != action.id;
            })
          });

        case Actions.FETCH_ITEMS_REQUEST:
          return Object.assign({}, state, {requestInProgress: true});

        case Actions.FETCH_ITEMS_SUCCESS:
          return Object.assign({}, state, {
            requestInProgress: false,
            items: action.items
          });

        case Actions.SET_ORDER:
          return Object.assign({}, state, {order: action.order});

        default:
          return state;
      }

      return state;
    }

    return reducer;
  }
]);
