import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { SetRevisionModalComponent } from '../modals/set-revision-modal/set-revision-modal.component';

angular
  .module('common')
  .directive(
    'coSetRevisionModal',
    downgradeComponent({ component: SetRevisionModalComponent })
  );
