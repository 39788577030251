<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_management.revision_status_modal.title' | translate }}</h3>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="revisions && revisions.length > 0">
      <div class="btn-group-container">
        <div class="form-group">
          <co-radio-button-group
            [(ngModel)]="mode"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="confirmDeletion = false"
            ngDefaultControl
          >
            <button [value]="modes.SET" class="btn btn-secondary-migrated">
              {{
                'question_management.revision_status_modal.modes.set'
                  | translate
              }}
            </button>
            <button [value]="modes.UNSET" class="btn btn-secondary-migrated">
              {{
                'question_management.revision_status_modal.modes.unset'
                  | translate
              }}
            </button>
          </co-radio-button-group>
        </div>
      </div>
      <div *ngIf="mode === modes.SET">
        <co-radio-button-group>
          <ng-container *ngFor="let revision of revisions">
            <div>
              <input
                type="radio"
                [id]="'select-revision-checkbox-' + revision.id"
                [checked]="revision.id === selected?.id"
                (change)="selectRevision(revision)"
              />
              <label [for]="'select-revision-checkbox-' + revision.id">
                {{ revision.shortName }}<br />
                <small class="text-muted font-weight-normal">
                  {{ revision.description }}
                </small>
              </label>
            </div>
          </ng-container>
        </co-radio-button-group>
      </div>
      <div *ngIf="mode === modes.UNSET" class="unset-container">
        <div class="unset-checkbox-container">
          <input
            [(ngModel)]="confirmDeletion"
            type="checkbox"
            class="unset-checkbox"
            id="unset-revision-checkbox"
          />
          <label class="unset-checkbox" for="unset-revision-checkbox">{{
            'question_management.revision_status_modal.unset' | translate
          }}</label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="revisions?.length === 0">
      <p>
        {{
          'question_management.revision_status_modal.no_revision_status'
            | translate
        }}
      </p>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button (click)="dismiss()" class="btn btn-secondary-migrated">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      (click)="save()"
      class="btn btn-primary-migrated"
      [disabled]="
        (mode == modes.UNSET && !confirmDeletion) || revisions.length === 0
      "
    >
      {{ 'common.actions.save' | translate }}
    </button>
  </div>
</div>
