import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule as ManagerCommonModule } from '../../common/common.module';
import { ConfirmModalComponent } from './modal/confirm-modal.component';
import { ErrorModalComponent } from './modal/error-modal.component';

const components = [ConfirmModalComponent, ErrorModalComponent];

@NgModule({
  imports: [AngularCommonModule, ManagerCommonModule, TranslateModule],
  declarations: components,
  exports: components
})
export class CommonModule {}
