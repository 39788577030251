<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_management.export_modal.title' | translate }}</h3>
  </div>
  <div class="modal-body" [class.disabled]="isSubmitting">
    <div class="form-group list-export">
      <label class="list-export--half-width">
        {{ 'question_management.export_modal.info' | translate }}
      </label>
      <co-radio-button-group
        [(ngModel)]="exportConfig.type"
        class="list-export--half-width"
      >
        <button
          [value]="'S'"
          [class.disabled]="selectedQuestionGroupIds.length === 0"
          class="btn btn-secondary-migrated"
        >
          {{ 'question_management.export_modal.selected' | translate }}
        </button>
        <button [value]="'A'" class="btn btn-secondary-migrated">
          {{ 'question_management.export_modal.by_filter' | translate }}
        </button>
      </co-radio-button-group>
    </div>
    <div class="form-group list-export">
      <label class="list-export--half-width">
        {{ 'question_management.export_modal.format.label' | translate }}
      </label>

      <co-radio-button-group
        [(ngModel)]="exportConfig.format"
        class="list-export--half-width"
      >
        <button [value]="'csv'" class="btn btn-secondary-migrated">
          {{ 'question_management.export_modal.format.csv' | translate }}
        </button>
        <button [value]="'excel'" class="btn btn-secondary-migrated">
          {{ 'question_management.export_modal.format.xlsx' | translate }}
        </button>
      </co-radio-button-group>
    </div>
    <h4>{{ 'question_management.export_modal.notice_title' | translate }}</h4>
    <p>{{ 'question_management.export_modal.notice' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary-migrated" (click)="dismiss()">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      class="btn btn-primary-migrated"
      (click)="generate()"
      [disabled]="isSubmitting"
    >
      {{ 'question_management.export_modal.actions.generate' | translate }}
      <div
        class="spinner-border text-light"
        role="status"
        *ngIf="isSubmitting"
      ></div>
    </button>
  </div>
</div>
