import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngxs/store';
import { isDefined } from 'angular';
import { Observable, map, tap } from 'rxjs';
import { LoadUsers } from 'src/app/admin/state/users.actions';
import { FormatNamePipe } from 'src/app/common/pipes/format-name.pipe';
import { User as BaseUser } from 'src/generated/base-types';
import { UsersState } from '../../../../state/users.state';

type User = Pick<BaseUser, 'id' | 'firstName' | 'lastName' | 'email'> & {
  label?: string;
  fullName?: string;
};

@Component({
  selector: 'man-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UserDropdownComponent)
    }
  ]
})
export class UserDropdownComponent {
  @Input() public format: 'full' | 'short' | 'reverse' = 'full';
  @Input() public showEmail = true;

  public selectedUserId: User['id'] | undefined;
  public value?: User['id'];
  public touched = false;
  public disabled = false;
  public onChange: (value: string) => void;
  public onTouched: () => void;
  public users$: Observable<User[]>;

  private formatName = new FormatNamePipe().transform;

  constructor(private store: Store) {
    this.users$ = this.store.select(UsersState.users).pipe(
      tap(users => {
        if (users.requestState === 'initial') {
          this.store.dispatch(new LoadUsers());
        }
      }),
      map(usersReq => usersReq.data || []),
      map(users =>
        users
          .map(user => this.parseUser(user))
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
      )
    );
  }

  public writeValue(value?: User['id']): void {
    this.value = value;
    this.selectedUserId = isDefined(this.value) ? this.value : undefined;
  }

  public registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public onUserChange(value: string): void {
    this.onChange(value);
  }

  public findUser(users: User[], id?: User['id']): User | undefined {
    return users.find(u => u.id === id);
  }

  private parseUser(user: User): User {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      label: this.formatName(user, this.format),
      fullName: this.formatName(user, 'full')
    };
  }
}
