angular.module('common').factory('Reducers::Panel', ['Actions::Panel',
  function (Actions) {
    function reducer (state, action) {
      state = state || {};
      state = Object.assign({
        left: {hidden: false, loading: false},
        center: {hidden: false, loading: false},
        right: {hidden: false, loading: false}
      }, state);

      switch (action.type) {
        case Actions.SHOW_PANEL:
          state[action.name].hidden = false;
          return Object.assign({}, state);

        case Actions.HIDE_PANEL:
          state[action.name].hidden = true;
          return Object.assign({}, state);

        case Actions.TOGGLE_PANEL:
          state[action.name].hidden = !state[action.name].hidden;
          return Object.assign({}, state);

        case Actions.START_LOADING:
          state[action.name].loading = true;
          return Object.assign({}, state);

        case Actions.STOP_LOADING:
          state[action.name].loading = false;
          return Object.assign({}, state);

        default:
          return state;
      }
    }

    return reducer;
  }
]);
