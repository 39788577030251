(function () {
  function Controller(
    $scope,
    Store,
    Selectors,
    Actions,
    MultiSelection,
    SortByColumnFunctionality,
    Repository,
    ExamSelectors
  ) {
    var dataSubscription,
      requestInProgressSubscription,
      selectionCountSubscription,
      examSubscription,
      orderSubscription;

    /*
     * Helper functions
     */

    var getSelectedExam = function () {
      return ExamSelectors.getSelectedItem(Store.getState());
    };

    var fetchCandidates = function (examId) {
      Store.dispatch(Actions.fetchCandidatesRequest());
      var order = Selectors.getOrderAsParameter(Store.getState());
      Repository.getAll(examId, order[0]).then(
        function (data) {
          Store.dispatch(Actions.fetchCandidatesSuccess(data));
        }.bind(this)
      );
    }.bind(this);

    /*
     * Data & callbacks
     */
    this.I18n = I18n;
    this.items = [];
    this.requestInProgress = false;
    this.columns = {
      omr: { visible: true },
      name: { visible: true },
      reg_number: { visible: true },
      scrambling: { visible: true },
      language: { visible: true },
      location: { visible: true },
      room: { visible: true },
      status: { visible: true },
      email: { visible: true },
      phone_number: { visible: true },
      tags: { visible: true }
    };

    this.selection = new MultiSelection(
      Actions.setSelection,
      Actions.addToSelection,
      Actions.removeFromSelection,
      Selectors.getSelection,
      Selectors.getAllIds,
      Selectors.getItems,
      'candidateList.selection'
    );
    this.sortByColumn = new SortByColumnFunctionality(
      Actions.setOrder,
      Selectors.getOrder,
      'omr',
      'candidateList'
    );

    this.selectWithoutCheckbox = function (event, itemId) {
      if (
        !(event.target instanceof HTMLInputElement) &&
        event.target.type !== 'checkbox'
      ) {
        this.selection.select(event, itemId);
      }
    }.bind(this);

    /*
     * Additional functionality
     */

    examSubscription = Store.subscribeOn(
      ExamSelectors.getSelectedItem,
      function (exam) {
        if (exam !== undefined) {
          fetchCandidates(getSelectedExam().id);
        }
      }.bind(this)
    );
    dataSubscription = Store.subscribeOn(
      Selectors.getItems,
      function (items) {
        this.items = items;
      }.bind(this)
    );
    requestInProgressSubscription = Store.subscribeOn(
      Selectors.isRequestInProgress,
      function (inProgress) {
        this.requestInProgress = inProgress;
      }.bind(this)
    );
    selectionCountSubscription = Store.subscribeOn(
      Selectors.getSelectionCount,
      function (count) {
        this.selectionCount = count;
      }.bind(this)
    );
    orderSubscription = Store.subscribeOn(Selectors.getOrder, function (order) {
      if (getSelectedExam() !== undefined) {
        fetchCandidates(getSelectedExam().id);
      }
    });

    /*
     * Destruction
     */
    $scope.$on(
      '$destroy',
      function () {
        dataSubscription();
        requestInProgressSubscription();
        selectionCountSubscription();
        orderSubscription();
        this.selection.destroy();
      }.bind(this)
    );
  }

  Controller.$inject = [
    '$scope',
    'Store',
    'Selectors::CandidateList',
    'Actions::CandidateList',
    'MultiSelection',
    'SortByColumnFunctionality',
    'Repositories::Candidate',
    'Selectors::ExamList'
  ];

  angular.module('examManagement').component('candidateList', {
    template: require('./candidate_list.html'),
    controller: Controller
  });
})();
