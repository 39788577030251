import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'qf-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss']
})
export class UnsavedChangesModalComponent {
  @Input()
  public modalInstance: NgbModalRef;

  public cancel(): void {
    this.modalInstance.dismiss();
  }

  public save(): void {
    this.modalInstance.close(true);
  }
}
