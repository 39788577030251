import {
  QuestionA,
  QuestionAMinus,
  QuestionE,
  QuestionFreeText,
  QuestionGroup,
  QuestionKprime,
  QuestionLongMenu,
  QuestionOfAnyType,
  QuestionType
} from './question-group';

export interface QuestionGroupVisitor {
  visitQuestionGroup: (questionGroup: QuestionGroup) => void;
  visitQuestionTypeA: (question: QuestionA) => void;
  visitQuestionTypeAMinus: (question: QuestionAMinus) => void;
  visitQuestionTypeKprime: (question: QuestionKprime) => void;
  visitQuestionTypeE: (question: QuestionE) => void;
  visitQuestionTypeFreeText: (question: QuestionFreeText) => void;
  visitQuestionTypeLongMenu: (question: QuestionLongMenu) => void;
}

export const acceptQuestionGroup = (
  questionGroup: QuestionGroup,
  visitor: QuestionGroupVisitor
): void => {
  visitor.visitQuestionGroup(questionGroup);
};

export const acceptQuestion = (
  question: QuestionOfAnyType,
  visitor: QuestionGroupVisitor
): void => {
  switch (question.type) {
    case QuestionType.MCA: {
      visitor.visitQuestionTypeA(question);
      break;
    }
    case QuestionType.MCAMinus: {
      visitor.visitQuestionTypeAMinus(question);
      break;
    }
    case QuestionType.MCKprime: {
      visitor.visitQuestionTypeKprime(question);
      break;
    }
    case QuestionType.MCE: {
      visitor.visitQuestionTypeE(question);
      break;
    }
    case QuestionType.FreeText: {
      visitor.visitQuestionTypeFreeText(question);
      break;
    }
    case QuestionType.LongMenu: {
      visitor.visitQuestionTypeLongMenu(question);
      break;
    }
  }
};
