import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { RemoveListConfirmModalComponent } from './components/remove-list-confirm-modal/remove-list-confirm-modal.component';

angular
  .module('questionForm')
  .directive(
    'qfRemoveListConfirmModal',
    downgradeComponent({ component: RemoveListConfirmModalComponent })
  );
