<div class="grid h-100" style="grid-template-rows: auto 1fr">
  <div class="g-col-1 g-col-md-3 g-col-xl-2">
    <a
      href="/"
      [title]="'common.actions.back' | translate"
      class="btn btn-light d-block d-md-inline-flex align-items-md-center gap-2"
    >
      <i class="fa-solid fa-sm fa-arrow-left" aria-hidden="true"></i>
      <span class="d-none d-md-inline-block">
        {{ 'common.actions.back' | translate }}</span
      ></a
    >
  </div>
  <div class="g-col-11 g-col-md-9 g-col-xl-10 align-self-center">
    <h5 class="my-0">{{ 'profile.title' | translate }}</h5>
  </div>
  <div class="g-col-1 g-col-md-3 g-col-xl-2"></div>
  <div class="g-col-11 g-col-md-9 g-col-xl-8">
    <form class="page form" [formGroup]="form" (submit)="onSave()">
      <div class="page-content g-col-1">
        <fieldset class="form-fieldset grid">
          <legend class="form-legend g-col-4">
            {{ 'admin.users.form.tab.account' | translate }}
          </legend>
          <man-field
            class="g-col-4 g-col-md-2"
            labelFor="email"
            [label]="'admin.users.form.label.email' | translate"
            [errors]="form | fieldErrors : 'email'"
            [helpText]="'profile.form.hint.email' | translate"
          >
            <input
              id="email"
              type="email"
              formControlName="email"
              class="form-control"
            />
          </man-field>
          <man-field
            class="g-col-4 g-col-md-2"
            labelFor="alternative-email"
            [label]="'admin.users.form.label.alternative_email' | translate"
            [errors]="form | fieldErrors : 'alternativeEmail'"
          >
            <input
              id="alternative-email"
              type="email"
              formControlName="alternativeEmail"
              class="form-control"
            />
          </man-field>
          <div class="g-col-4 my-2 mb-3" *ngIf="!passwordFieldVisible">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="showPasswordField()"
            >
              {{ 'admin.users.form.label.edit_password' | translate }}
            </button>
          </div>
          <ng-container *ngIf="passwordFieldVisible">
            <man-field
              class="g-col-4 g-col-md-2"
              labelFor="password"
              [label]="'admin.users.form.label.password' | translate"
              [errors]="form | fieldErrors : 'password'"
            >
              <input
                id="password"
                type="password"
                formControlName="password"
                class="form-control"
                #passwordInput
            /></man-field>
            <man-field
              class="g-col-4 g-col-md-2"
              labelFor="password-confirmation"
              [label]="
                'admin.users.form.label.password_confirmation' | translate
              "
              [errors]="form | fieldErrors : 'passwordConfirmation'"
            >
              <input
                id="password-confirmation"
                type="password"
                formControlName="passwordConfirmation"
                class="form-control"
            /></man-field>
          </ng-container>
        </fieldset>

        <fieldset class="form-fieldset grid">
          <legend class="form-legend g-col-4">
            {{ 'admin.users.form.tab.personal' | translate }}
          </legend>
          <man-field
            class="form-field g-col-4 g-col-md-2"
            labelFor="first-name"
            [label]="'admin.users.form.label.first_name' | translate"
            [errors]="form | fieldErrors : 'firstName'"
            ><input
              id="first-name"
              type="text"
              formControlName="firstName"
              class="form-control"
              cdkFocusInitial
            />
          </man-field>
          <man-field
            class="form-field g-col-4 g-col-md-2"
            labelFor="last-name"
            [label]="'admin.users.form.label.last_name' | translate"
            [errors]="form | fieldErrors : 'lastName'"
            ><input
              id="last-name"
              type="text"
              formControlName="lastName"
              class="form-control"
            />
          </man-field>
          <man-field
            class="form-field g-col-4 g-col-md-2"
            labelFor="mobile-phone"
            [label]="'admin.users.form.label.mobile_phone' | translate"
            ><input
              id="mobile-phone"
              type="text"
              formControlName="mobilePhone"
              class="form-control"
            />
          </man-field>
          <man-field
            class="form-field g-col-4 g-col-md-2"
            labelFor="alternative-mobile-phone"
            [label]="
              'admin.users.form.label.alternative_mobile_phone' | translate
            "
            ><input
              id="alternative-mobile-phone"
              type="text"
              formControlName="alternativeMobilePhone"
              class="form-control"
            />
          </man-field>
        </fieldset>

        <fieldset class="form-fieldset grid">
          <legend class="form-legend g-col-4">
            {{ 'admin.users.form.tab.other' | translate }}
          </legend>
          <man-field
            class="g-col-4"
            labelFor="language"
            [label]="'admin.users.form.label.language' | translate"
          >
            <select
              id="language"
              formControlName="language"
              class="form-select"
            >
              <option
                *ngFor="let option of languageOptions"
                [value]="option.value"
              >
                {{ option.label | translate }}
              </option>
            </select></man-field
          >
        </fieldset>
      </div>

      <div class="page-footer">
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="(disabled$ | async) || !form.dirty || !form.valid"
        >
          <ng-container *ngIf="!(disabled$ | async); else loading">
            {{ 'common.actions.save' | translate }}
          </ng-container>
        </button>
      </div>
    </form>
  </div>
</div>

<ng-template #loading>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.actions.loading' | translate }}
</ng-template>
