<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="cancel()">&times;</a>
    <h3>
      {{ 'question_groups.edit.longmenu_solution.remove_title' | translate }}
    </h3>
  </div>
  <div class="modal-body">
    <p class="danger">
      {{
        'question_groups.edit.longmenu_solution.remove_disclaimer' | translate
      }}
    </p>
    <p>
      {{
        'question_groups.edit.longmenu_solution.save_description' | translate
      }}
    </p>
    <div class="checkbox-container">
      <input
        type="checkbox"
        [(ngModel)]="exportSolution"
        [ngModelOptions]="{ standalone: true }"
        id="long-menu-export-solution-checkbox"
      />
      <label for="long-menu-export-solution-checkbox">
        {{ 'question_groups.edit.longmenu_solution.save_checkbox' | translate }}
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-secondary-migrated"
      (click)="cancel()"
      style="margin: 5px"
    >
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      class="btn btn-primary-migrated"
      (click)="remove()"
      style="margin: 5px"
    >
      {{ 'question_groups.edit.longmenu_solution.remove' | translate }}
    </button>
  </div>
</div>
