<div class="d-flex justify-content-between mb-2 gap-2">
  <man-search-input
    placeholder="admin.pools.subjects.filter.placeholder"
    [disabled]="!(categories$ | async)"
    (search)="onSearch($event)"
    (clear)="onSearchClear()"
  >
  </man-search-input>
</div>

<div
  *ngIf="filteredCategories$ | async as categories"
  class="d-flex flex-column flex-grow-1"
>
  <p-table
    class="flex-grow-1 border rounded overflow-hidden bg-stripes-light"
    [value]="categories"
    dataKey="dimension.id"
    sortField="dimension.id"
    [scrollable]="true"
    scrollHeight="flex"
    rowGroupMode="subheader"
    groupRowsBy="dimension.id"
    styleClass="p-datatable-sm"
    [(selection)]="selectedCategories"
  >
    <ng-template pTemplate="groupheader" let-category let-rowIndex="rowIndex">
      <tr
        pRowGroupHeader
        class="bg-light"
        style="
          box-shadow: 0 1px hsl(210, 14%, 95%), 0 -1px hsl(210, 14%, 95%);
          height: 3rem;
        "
      >
        <td colspan="2">
          <strong>
            {{
              category.dimension
                | aggregateLocalisation : 'name'
                | localizeContent
            }}
          </strong>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-category let-rowIndex="rowIndex">
      <tr>
        <td style="width: 2rem">
          <p-checkbox
            name="groupname"
            [value]="category.id"
            [(ngModel)]="selectedCategories"
            (onChange)="onSelectionChange()"
          ></p-checkbox>
        </td>
        <td>
          <span class="image-text">{{
            category | aggregateLocalisation : 'name' | localizeContent
          }}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
