import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetSupervisorMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  user?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SetSupervisorMutation = { __typename?: 'Mutation', setSupervisor?: { __typename?: 'SetSupervisorPayload', successful: boolean } | null };

export const SetSupervisorDocument = gql`
    mutation SetSupervisor($poolId: ID!, $questionGroupIds: [ID!]!, $user: String) {
  setSupervisor(poolId: $poolId, questionGroupIds: $questionGroupIds, user: $user) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetSupervisorGQL extends Apollo.Mutation<SetSupervisorMutation, SetSupervisorMutationVariables> {
    document = SetSupervisorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }