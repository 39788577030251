(function () {
  function Controller($scope, Store, Selectors, Actions) {
    this.I18n = I18n;
    this.columns = [];

    this.toggle = function(column, event) {
      Store.dispatch(Actions.setColumnVisibility(column.name, !column.visible));
      event.preventDefault();
      event.stopPropagation();
    };

    var definitions = Store.subscribeOn(Selectors.getColumnDefinitionsAsArray, function(
      columns
    ) {
      this.columns = columns.filter(function(column) {
        return column.enabled;
      });
    }.bind(this));

    $scope.$on('destroy', function() {
      definitions();
    });
  }

  Controller.$inject = [
    '$scope',
    'Store',
    'Selectors::ExamQuestionGroupList',
    'Actions::ExamQuestionGroupList'
  ];

  angular.module('examManagement').component('examQuestionGroupListColumnVisibility', {
    transclude: true,
    template: `
      <div class="dropdown column-visibility-dropdown" uib-dropdown auto-close="outsideClick">
        <a href="#" class="btn btn-xs btn-primary" uib-dropdown-toggle ng-transclude></a>
        <ul uib-dropdown-menu class="pull-left dropdown-menu--lg">
          <li ng-repeat="column in $ctrl.columns">
            <a href="#" ng-click="$ctrl.toggle(column, $event)">
              <i class="fa fa-square-o layout-cell--right-spaced" ng-hide="column.visible"/>
              <i class="fa fa-check-square-o layout-cell--right-spaced" ng-show="column.visible"/>
              {{ $ctrl.I18n.t("activerecord.attributes.question_group." + column.name, column.translationParams) }}
            </a>
          </li>
        </ul>
      </div>
    `,
    controller: Controller
  });
})();
