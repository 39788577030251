import { Component, Input } from '@angular/core';
import {
  ValidationsSeverity,
  ValidationsMessage
} from 'src/generated/base-types';

@Component({
  selector: 'qf-icon-feedback',
  templateUrl: './icon-feedback.component.html'
})
export class IconFeedbackComponent {
  public isError = false;
  public isWarning = false;

  @Input() public set validations(list: ValidationsMessage[] | undefined) {
    this.isError = false;
    this.isWarning = false;

    if (!list || list?.length === 0) {
      return;
    }
    if (list.some(entry => entry.severity === ValidationsSeverity.Error)) {
      this.isError = true;
      this.isWarning = false;

      return;
    }
    if (list.some(entry => entry.severity === ValidationsSeverity.Warning)) {
      this.isError = false;
      this.isWarning = true;
    }
  }
}
