angular.module('common').service('SortByColumnFunctionality', ['Store', function (Store) {
  function SortByColumnFunctionality (setOrdering, getOrder, defaultSortKey, storagePrefix) {
    this.setOrdering = setOrdering;
    this.getOrder = getOrder;
    this.storagePrefix = storagePrefix;
    this.defaultSortKey = defaultSortKey;

    this._initSettings();

    this.updateMarkersSubscription =  Store.subscribeOn(this.getOrder, this._updateOrderMarkers.bind(this));
    this.saveSettingsSubscription = Store.subscribeOn(this.getOrder, this._saveSettings.bind(this));
  }

  SortByColumnFunctionality.prototype.orderBy = function (column) {
    var currentOrder = this.getOrder(Store.getState())[0];

    if (currentOrder.name == column && currentOrder.ascending) {
      Store.dispatch(this.setOrdering([{name: column, ascending: false}]));
    }
    else if (currentOrder.name == column && !currentOrder.ascending &&
             column !== this.defaultSortKey) {
      Store.dispatch(this.setOrdering([{name: this.defaultSortKey, ascending: false}]));
    }
    else {
      Store.dispatch(this.setOrdering([{name: column, ascending: true}]));
    }
  };

  SortByColumnFunctionality.prototype._updateOrderMarkers = function () {
    this.headers = this.getOrder(Store.getState()).reduce(function (acc, columnOrder) {
      acc[columnOrder.name] = columnOrder.ascending ? 'asc' : 'desc';
      return acc;
    }, {});
  };

  SortByColumnFunctionality.prototype._initSettings = function () {
    var order = JSON.parse(localStorage.getItem(this.storagePrefix + '-order')) || [];

    if (order.length === 0) {
      order = [{name: this.defaultSortKey, ascending: false}];
    }

    Store.dispatch(this.setOrdering(order));
  };

  SortByColumnFunctionality.prototype._saveSettings = function () {
    localStorage.setItem(this.storagePrefix + '-order',
                         JSON.stringify(this.getOrder(Store.getState())));
  };

  SortByColumnFunctionality.prototype.destroy = function () {
    this.updateMarkersSubscription();
    this.saveSettingsSubscription();
  };

  return SortByColumnFunctionality;

}]);
