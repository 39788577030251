angular.module('common').factory('GraphQLClient', [
  '$http',
  '$q',
  '$window',
  'notifications',
  'Store',
  'Selectors::User',
  'Actions::ConnectionStatus',
  function($http, $q, $window, notifications, Store, UserSelectors, Actions) {
    var MAX_NUMBER_OF_NOTIFICATIONS = 5;

    function GraphQLClient(url) {
      this.url = url;
    }

    function extractNotifications(response) {
      var messages, i;
      if (response.data && response.data.data) {
        messages = Object.values(response.data.data).reduce(function(acc, query) {
          if (query.erroneous && query.notifications) {
            return acc.concat(query.notifications);
          } else {
            return acc;
          }
        }, []);

        for (i = 0; i < messages.length && i < MAX_NUMBER_OF_NOTIFICATIONS; i++) {
          notifications.add(messages[i].content, messages[i].type);
        }
      }

      return response;
    }

    GraphQLClient.prototype.query = function(query, variables, options) {
      const language = UserSelectors.getLanguage(Store.getState());
      const mergedOptions = Object.assign({ headers: {} }, options);
      mergedOptions.headers['User-Language'] = language;

      return $http(
        Object.assign(
          {
            method: 'POST',
            url: this.url,
            data: {
              query: query,
              variables: variables
            }
          },
          mergedOptions
        )
      )
        .catch(function(response) {
          if (response.status === 401) {
            $window.location.href = '/login';
          }

          options = options || {};
          // As soon as we upgrade to AngularJS 1.7 we can replace the dirty timeout
          // check with a check against `xhrStatus`.
          if (response.status === -1 && (options.timeout === undefined || options.timeout.$$state.status === 0)) {
            Store.dispatch(Actions.setConnectionMissing());
          } else {
            Store.dispatch(Actions.setConnectionPresent());
          }

          return $q.reject();
        })
        .then(function(response) {
          Store.dispatch(Actions.setConnectionPresent());
          extractNotifications(response);
          return response;
        });
    };

    return GraphQLClient;
  }
]);
