<div class="bs4" [formGroup]="form">
  <ng-container *ngIf="questionGroupType == 'SINGLE'" formArrayName="questions">
    <ng-container *ngFor="let questionFormGroup of [questionsForm.controls[0]]">
      <qf-question-form
        [languageVisibility]="languageVisibility"
        [languages]="languages"
        [dimensions]="dimensions"
        [validations]="validations.questions[0]"
        formControlName="0"
        [sequentialNumber]="sequentialNumber"
        [sourceLanguage]="sourceLanguage"
      ></qf-question-form>
    </ng-container>
  </ng-container>

  <ng-container
    *ngIf="questionGroupType == 'SERIES' || questionGroupType == 'SEQUENCE'"
  >
    <div class="flex-row">
      <ul
        ngbNav
        #nav="ngbNav"
        [dndDropzone]
        [dndHorizontal]="true"
        (dndDrop)="onMoveTab($event)"
        [(activeId)]="activeQuestionIndex"
        [animation]="false"
        class="nav-tabs skin-container-cell--filled-light pl-4 col-sm-10"
      >
        <a dndPlaceholderRef class="tab-placeholder nav-item"></a>
        <li
          [ngbNavItem]="questionIndex"
          *ngFor="
            let questionFormGroup of questionsForm.controls;
            let questionIndex = index
          "
          formArrayName="questions"
        >
          <a
            ngbNavLink
            [dndDraggable]="questionIndex"
            [dndEffectAllowed]="'move'"
            href="#"
            class="hover-trigger font-weight-bold"
            >{{
              'questions.edit.question'
                | translate : { number: questionIndex + 1 }
            }}
            <small class="font-weight-light">
              {{ 'questions.edit.type' | translate }}
              {{ 'questions.type.' + questionFormGroup.value.type | translate }}
            </small>
            <span
              class="close pl-3 font-weight-light hover-trigger-item"
              *ngIf="!removalModeActive"
            >
              <i class="fa fa-arrows-h"></i>
            </span>
            <span
              class="close position-relative pl-3 font-weight-light"
              *ngIf="removalModeActive"
              (click)="onRemoveQuestion(questionIndex)"
            >
              <i class="fa fa-minus-circle"></i>
            </span>
          </a>
          <ng-template ngbNavContent>
            <qf-question-form
              [formControlName]="questionIndex"
              [languageVisibility]="languageVisibility"
              [languages]="languages"
              [dimensions]="dimensions"
              [validations]="validations.questions[questionIndex]"
              [sourceLanguage]="sourceLanguage"
            ></qf-question-form>
          </ng-template>
        </li>

        <a
          href="#"
          class="ml-2 pt-1 text-decoration-none text-muted"
          (click)="onAddQuestion()"
          *ngIf="!removalModeActive"
        >
          <i class="fa fa-plus-circle fa-2x"></i>
        </a>
      </ul>
      <div
        class="col-sm-2 skin-container-cell--filled-light nav-tabs text-right p-0 pr-3"
        [ngClass]="{ 'pt-2': !removalModeActive }"
      >
        <a
          href="#"
          class="text-decoration-none"
          (click)="toggleRemoveQuestions()"
          *ngIf="!removalModeActive"
        >
          {{ 'common.actions.edit' | translate }}
        </a>
        <a
          href="#"
          class="text-decoration-none"
          (click)="toggleRemoveQuestions()"
          *ngIf="removalModeActive"
        >
          <i class="fa fa-times-circle fa-2x"></i>
        </a>
      </div>
    </div>

    <div [ngbNavOutlet]="nav" class="col-sm-12 p-0"></div>
  </ng-container>
</div>
