<div class="modal-header">
  <h5 class="modal-title">{{ data.titleTranslationKey | translate }}</h5>
</div>
<div class="modal-body">
  <p>{{ data.messageTranslationKey | translate }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="onCancel()">
    {{ 'actions.cancel' | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()">
    {{ 'actions.ok' | translate }}
  </button>
</div>
