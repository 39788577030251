<div class="modal-wrapper bs4">
  <div class="modal-header">
    <h3 style="margin-top: 15px">{{ 'video_upload.title' | translate }}</h3>
  </div>
  <div class="modal-body">{{ 'video_upload.remove_video' | translate }}</div>
  <div class="modal-footer">
    <button
      class="btn btn-secondary-migrated"
      (click)="cancel()"
      style="margin: 5px"
    >
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      class="btn btn-primary-migrated"
      (click)="remove()"
      style="margin: 5px"
    >
      {{ 'common.actions.remove' | translate }}
    </button>
  </div>
</div>
