import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { IRootScopeService } from 'angular';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { QuestionContentAssetTransformerService } from 'src/app/common/services/question-content-transformer/question-content-asset-transformer.service';
import {
  Affiliation,
  Dimension,
  Language,
  ListMetadata,
  QuestionGroupInput,
  QuestionGroupType,
  QuestionType,
  Scalars,
  User
} from 'src/generated/base-types';
import { OriginReference } from '../../common/legacy.types';
import { ChooseQuestionGroupTypeModalComponent } from '../../common/modals/choose-question-group-type-modal/choose-question-group-type-modal.component';
import { UnsavedChangesModalComponent } from '../../common/modals/unsaved-changes-modal/unsaved-changes-modal.component';
import { Notifications } from '../../common/services/notifications';
import { UiRouterService } from '../../common/services/ui-router.service';
import { WindowService } from '../../common/services/window.service';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../common/utils/ng-bootstrap-modal';
import { RemoteData } from '../../common/utils/remote-data';
import { isDefined } from '../../common/utils/type-guards/is-defined';
import { ExamListState } from '../../exam-management/state/exam-list.state';
import { ToolbarManagementType } from '../../question-management/components/question-group-toolbar/question-group-toolbar.component';
import { LoadQuestionGroupPreview } from '../../question-management/state/question-details.actions';
import {
  ReloadQuestionGroupList,
  ReloadSelectedQuestionGroups,
  SetSelectedQuestionGroups
} from '../../question-management/state/question-list.actions';
import { QuestionListState } from '../../question-management/state/question-list.state';
import { AppContextState } from '../../state/app-context.state';
import { UpdateLanguageVisibility } from '../../state/settings.actions';
import { LanguageVisibility, SettingsState } from '../../state/settings.state';
import { TaskListState } from '../../task-management/state/task-list.state';
import { FormDimension } from '../form-types';
import { QuestionGroupFormComponent } from '../question-group-form/question-group-form.component';
import { CreateQuestionGroupGQL } from '../services/create-question-group.generated';
import { ValidationsQuestionGroupGraphQL } from '../services/graphql-question-group-types';
import { QuestionGroupListElementFragment } from '../services/load-question-group-list.generated';
import { preselectTruncatedDimensions } from '../services/preselect-truncated-dimensions';
import { stringToQuestionType } from '../services/type-helpers';
import { UpdateQuestionGroupGQL } from '../services/update-question-group.generated';
import {
  LoadExistingQuestionGroup,
  LoadFormOptions,
  LoadNewQuestionGroup
} from '../state/form.actions';
import { FormState } from '../state/form.state';
import { LoadQuestionGroup } from '../state/question-group.actions';
import { QuestionGroupState } from '../state/question-group.state';

export enum FormMode {
  New = 'new',
  Duplicate = 'duplicate',
  Edit = 'edit'
}

export interface LegacyIRootScopeService extends IRootScopeService {
  hasFormChanged: boolean; // used for checkFormChangesBeforeClick directive
}

@Component({
  selector: 'qf-edit-question-group',
  templateUrl: './edit-question-group.component.html',
  styleUrls: ['./edit-question-group.component.scss']
})
export class EditQuestionGroupComponent implements OnInit, OnDestroy {
  @Input()
  public questionGroupId: Scalars['ID'];
  @Input()
  public newQuestionType: string;
  @Input()
  public newQuestionGroupType: string;
  @Input()
  public duplicateQuestionGroupId: Scalars['ID'];

  @Select(FormState.questionGroupInput)
  public questionGroupInput$: Observable<QuestionGroupInput | undefined>;

  @Select(QuestionGroupState.questionGroup)
  public questionGroup$: Observable<
    RemoteData<QuestionGroupListElementFragment>
  >;

  @Select(FormState.validations)
  public validations$: Observable<ValidationsQuestionGroupGraphQL>;

  @Select(FormState.uploadingImage)
  public uploadingImage$: Observable<boolean>;

  @Select(QuestionListState.metadata)
  public metadata$: Observable<ListMetadata | undefined>;

  @Select(TaskListState.loadedQuestionGroupIds)
  public taskQuestionGroupIds$: Observable<string[]>;

  @Select(AppContextState.currentPoolId)
  private currentPoolId$: Observable<Scalars['ID'] | undefined>;

  @Select(FormState.affiliations)
  private affiliation$: Observable<Affiliation[]>;

  @Select(FormState.languages)
  private languages$: Observable<Language[]>;

  @Select(FormState.dimensions)
  private dimensions$: Observable<FormDimension[]>;

  @Select(FormState.authors)
  private authors$: Observable<string[]>;

  @Select(FormState.supervisors)
  private supervisors$: Observable<string[]>;

  @Select(SettingsState.languageVisibility)
  private languageVisibility$: Observable<LanguageVisibility>;

  @Select(AppContextState.currentUser)
  private currentUser$: Observable<
    Pick<User, 'firstName' | 'lastName'> | undefined
  >;

  @ViewChild(QuestionGroupFormComponent)
  private questionGroupFormComponent: QuestionGroupFormComponent;

  public formMode = FormMode;
  public validations: ValidationsQuestionGroupGraphQL = { questions: [] };
  public isSingleQuestion = false;
  public isQuestionSeries = false;
  public isQuestionSequence = false;
  public isSubmitting = false;
  public questionGroupToSave: QuestionGroupInput;
  public questionGroupInput: QuestionGroupInput;
  public questionGroup?: QuestionGroupListElementFragment;
  public formOptions: {
    affiliations: Affiliation[];
    languages: Language[];
    dimensions: FormDimension[];
    authors: string[];
    supervisors: string[];
  };
  public languageVisibility: LanguageVisibility;
  public mode: FormMode;
  public formHasChanged = false;
  public toggleNextQG: Subject<void> = new Subject();
  public togglePreviousQG: Subject<void> = new Subject();
  public managementType: ToolbarManagementType;
  public navigationIds: Scalars['ID'][] = [];
  public poolId: Scalars['ID'];
  private destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly updateQuestionGroupGQL: UpdateQuestionGroupGQL,
    private readonly createQuestionGroupGQL: CreateQuestionGroupGQL,
    private readonly contentTransformerService: QuestionContentAssetTransformerService,
    private readonly uiRouterService: UiRouterService,
    private readonly windowService: WindowService,
    public readonly ngbModal: NgbModal,
    private readonly translate: TranslateService,
    private readonly notifications: Notifications,
    @Inject('$rootScope') private rootScope: LegacyIRootScopeService
  ) {}

  public ngOnInit(): void {
    this.setNavigationIds();
    this.setFormMode();

    this.currentUser$.pipe(filter(isDefined), first()).subscribe(user => {
      if (this.mode === FormMode.New) {
        this.store.dispatch(
          new LoadNewQuestionGroup(
            user.firstName,
            user.lastName,
            stringToQuestionType(this.newQuestionType),
            this.newQuestionGroupType as QuestionGroupType
          )
        );
      }
    });

    this.currentPoolId$.pipe(filter(isDefined), first()).subscribe(poolId => {
      this.poolId = poolId;
      this.store.dispatch(new LoadFormOptions(poolId));
      if (this.mode === FormMode.Edit) {
        this.store.dispatch(
          new LoadExistingQuestionGroup(this.questionGroupId, poolId)
        );
        this.store.dispatch(
          new LoadQuestionGroup(this.questionGroupId.toString())
        );
      }
      if (this.mode === FormMode.Duplicate) {
        this.store.dispatch(
          new LoadExistingQuestionGroup(
            this.duplicateQuestionGroupId,
            poolId,
            true
          )
        );
      }
    });

    combineLatest(
      this.affiliation$,
      this.languages$,
      this.dimensions$,
      this.authors$,
      this.supervisors$
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([affiliations, languages, dimensions, authors, supervisors]) => {
          this.formOptions = {
            affiliations,
            languages,
            dimensions,
            authors,
            supervisors
          };
        }
      );

    this.languageVisibility$
      .pipe(takeUntil(this.destroy$))
      .subscribe(languageVisibility => {
        this.languageVisibility = languageVisibility;
      });

    combineLatest(this.questionGroupInput$, this.dimensions$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([questionGroupInput, dimensions]) => {
        if (!questionGroupInput) {
          return;
        }
        this.questionGroupInput = questionGroupInput;

        if (this.mode === FormMode.New) {
          questionGroupInput = preselectTruncatedDimensions(
            questionGroupInput,
            dimensions as Dimension[]
          );
        }
        this.isSingleQuestion =
          questionGroupInput?.type === QuestionGroupType.Single;
        this.isQuestionSeries =
          questionGroupInput?.type === QuestionGroupType.Series;
        this.isQuestionSequence =
          questionGroupInput?.type === QuestionGroupType.Sequence;
      });

    this.questionGroup$
      .pipe(
        filter(state => state.requestState === 'success'),
        takeUntil(this.destroy$)
      )
      .subscribe(state => (this.questionGroup = state.data));
    this.validations$
      .pipe(takeUntil(this.destroy$))
      .subscribe(validations => (this.validations = validations));
  }

  public async checkToggleNext(): Promise<void> {
    await this.checkIfFormHasChanges(() => this.toggleNextQG.next());
  }

  public async checkTogglePrevious(): Promise<void> {
    await this.checkIfFormHasChanges(() => this.togglePreviousQG.next());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public updateLanguageVisibility(language: Language, visible: boolean): void {
    this.store.dispatch(new UpdateLanguageVisibility(language, visible));
  }

  public async cancel(): Promise<void> {
    await this.checkIfFormHasChanges(() => {
      const firstRefParamChar = this.uiRouterService.params().ref?.[0];

      if (firstRefParamChar === '/') {
        this.windowService.href = this.uiRouterService.params().ref;
      } else {
        // go to origin or questionManagement if nothing provided
        this.uiRouterService.go(
          this.uiRouterService.params().ref ??
            OriginReference.QuestionManagement
        );
      }
    });
  }

  public async checkIfFormHasChanges(call: () => unknown): Promise<void> {
    if (this.formHasChanged) {
      const result = await this.openUnsavedChangesModal();
      if (result === true) {
        call();
      }
    } else {
      call();
    }
  }

  public openUnsavedChangesModal(): Promise<boolean> {
    const ref = this.ngbModal.open(
      UnsavedChangesModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );

    ref.componentInstance.modalInstance = ref;

    return ref.result
      .then((result: boolean) => {
        return result;
      })
      .catch(() => {
        return false;
      });
  }

  public openNewQuestionGroupModal(): void {
    const ref = this.ngbModal.open(
      ChooseQuestionGroupTypeModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );

    ref.componentInstance.hideQuestionGroupTypes = false;
    ref.componentInstance.modalInstance = ref;

    ref.result
      .then(
        (result: {
          questionType: QuestionType;
          questionGroupType: QuestionGroupType;
        }) => {
          this.uiRouterService.go('newQuestionForm', {
            questionType: result.questionType,
            questionGroupType: result.questionGroupType,
            ref: OriginReference.QuestionManagement
          });
        }
      )
      .catch(() => {
        // modal dismissed
      });
  }

  public save(): void {
    this.isSubmitting = true;
    this.validations = { questions: [] };
    this.resetFormHasChanged();
    if (this.questionGroupToSave === undefined) {
      const formValue = this.questionGroupFormComponent.form.value;
      this.questionGroupToSave = {
        ...formValue.metadata,
        questions: formValue.questions
      };
    }
    const transformedQuestionGroup =
      this.contentTransformerService.transformHTMLToInternalRepresentation(
        this.questionGroupToSave
      );

    if (this.mode === FormMode.Edit) {
      this.updateQuestionGroup(transformedQuestionGroup);
    } else {
      this.createQuestionGroup(transformedQuestionGroup);
    }
  }

  public setFormHasChanged(): void {
    this.formHasChanged = true;
    this.rootScope.hasFormChanged = true;
  }

  private setFormMode(): void {
    if (this.questionGroupId) {
      this.mode = FormMode.Edit;
    } else if (this.duplicateQuestionGroupId) {
      this.mode = FormMode.Duplicate;
    } else if (this.newQuestionType) {
      this.mode = FormMode.New;
    }
  }

  private setNavigationIds(): void {
    const origin = this.uiRouterService.params().ref;

    switch (origin) {
      case OriginReference.QuestionManagement: {
        const metadata = this.store.selectSnapshot(QuestionListState.metadata);
        this.navigationIds =
          metadata.totalNumberOfItems === metadata.filteredNumberOfItems
            ? metadata.allIds
            : metadata.allFilteredIds;

        break;
      }
      case OriginReference.TaskManagement: {
        this.navigationIds = this.store.selectSnapshot(
          TaskListState.loadedQuestionGroupIds
        );

        break;
      }
      case OriginReference.ExamManagement: {
        this.navigationIds = this.store.selectSnapshot(
          ExamListState.loadedQuestionGroupIds
        );

        break;
      }
      default: {
        this.navigationIds = [];
      }
    }
  }

  private resetFormHasChanged(): void {
    this.formHasChanged = false;
    this.rootScope.hasFormChanged = false;
  }

  private createQuestionGroup(
    transformedQuestionGroup: QuestionGroupInput
  ): void {
    this.createQuestionGroupGQL
      .mutate({ poolID: this.poolId, attributes: transformedQuestionGroup })
      .toPromise()
      .then(result => {
        const mutationResult = result?.data?.createQuestionGroup;
        this.validations = (mutationResult?.validations || {
          questions: []
        }) as ValidationsQuestionGroupGraphQL;
        this.isSubmitting = false;

        if (
          isDefined(mutationResult?.questionGroup) &&
          mutationResult?.successful === true
        ) {
          this.notifications.addSuccess(
            this.translate.instant('question_groups.form.save.success')
          );
          this.store.dispatch(new ReloadQuestionGroupList());
          this.store.dispatch(
            new SetSelectedQuestionGroups([mutationResult.questionGroup])
          );
          this.uiRouterService.go(
            'editQuestionForm',
            { id: mutationResult.questionGroup?.id },
            { notify: false, replace: true }
          );
        } else {
          this.notifications.addAlert(
            this.translate.instant('question_groups.form.save.error')
          );
        }
      });
  }

  private updateQuestionGroup(
    transformedQuestionGroup: QuestionGroupInput
  ): void {
    this.updateQuestionGroupGQL
      .mutate({
        poolId: this.poolId,
        questionGroupID: this.questionGroupId,
        attributes: transformedQuestionGroup
      })
      .toPromise()
      .then(result => {
        this.validations = result?.data?.updateQuestionGroup
          ?.validations as ValidationsQuestionGroupGraphQL;
        this.isSubmitting = false;

        if (result?.data?.updateQuestionGroup?.successful === true) {
          this.store.dispatch(new ReloadQuestionGroupList());
          this.store.dispatch(new ReloadSelectedQuestionGroups());
          this.store.dispatch(
            new LoadQuestionGroupPreview(this.questionGroupId)
          );
          this.notifications.addSuccess(
            this.translate.instant('question_groups.form.save.success')
          );
        } else {
          this.notifications.addAlert(
            this.translate.instant('question_groups.form.save.error')
          );
        }
      });
  }
}
