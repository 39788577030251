angular.module('common').factory('Repositories::CurrentUser', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getCurrentQuery =
        'query {current_user { id name first_name last_name language organisation email super_user' +
        '  permissions(current_only: true) {' +
        '    role' +
        '  }' +
        '}}',
      getRolesOfCurrentUser = 'query {current_user { permissions { role }}}';

    return {
      getCurrent: function() {
        return client.query(getCurrentQuery).then(function(response) {
          return response.data.data.current_user;
        });
      },
      getRolesOfCurrentUser: function() {
        return client.query(getRolesOfCurrentUser).then(function(response) {
          return response.data.data.current_user.permissions.map(function(permission) {
            return permission.role;
          });
        });
      }
    };
  }
]);
