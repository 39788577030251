import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { SetRevisionYearModalComponent } from '../modals/set-revision-year-modal/set-revision-year-modal.component';

angular
  .module('common')
  .directive(
    'coSetRevisionYearModal',
    downgradeComponent({ component: SetRevisionYearModalComponent })
  );
