<div
  *ngIf="announcements$ | async as announcements"
  class="grid h-100"
  style="grid-template-rows: auto 1fr"
>
  <div class="g-col-12 ms-auto order-1">
    <button (click)="onNew()" type="button" class="btn btn-dark">
      {{ 'admin.announcements.actions.new' | translate }}
    </button>
  </div>
  <man-table-wrapper class="g-col-12 order-3" [request]="announcements">
    <p-table
      name="Announcements"
      selectionMode="single"
      sortMode="multiple"
      [multiSortMeta]="[
        { field: 'enabled', order: -1 },
        { field: 'severity', order: 1 }
      ]"
      [value]="announcements.data | copyArray"
      [loading]="announcements.requestState === 'loading'"
      [scrollable]="true"
      [selection]="(formState$ | async)?.data"
      scrollHeight="flex"
      (onRowSelect)="onEdit($event.data)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="p-col-fixed p-col--icon"></th>
          <th>
            {{ 'admin.announcements.table.message' | translate }}
          </th>
          <th>{{ 'admin.announcements.table.enabled' | translate }}</th>
          <th class="p-col-fixed p-col--date">
            {{ 'admin.announcements.table.starts_at' | translate }}
          </th>
          <th class="p-col-fixed p-col--date">
            {{ 'admin.announcements.table.ends_at' | translate }}
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-announcement>
        <tr
          class="p-row"
          [pSelectableRow]="announcement"
          interactiveRow
          (delete)="onDelete(announcement.id)"
        >
          <td class="justify-content-center">
            <man-announcement-severity
              [severity]="announcement.severity"
              [description]="
                'common.severity.' + announcement.severity | translate
              "
            >
            </man-announcement-severity>
          </td>
          <td>
            <div>
              {{ announcement.message | localizeContent }}
            </div>
          </td>
          <td>
            <span *ngIf="announcement.enabled" class="badge text-bg-success">{{
              'admin.announcements.table.state.enabled' | translate
            }}</span>
            <span
              *ngIf="!announcement.enabled"
              class="badge text-bg-secondary"
              >{{
                'admin.announcements.table.state.disabled' | translate
              }}</span
            >
          </td>
          <td class="p-col-fixed p-col--date">
            {{ announcement.startsAt | date : 'dd.MM.yyyy' }}
          </td>
          <td class="p-col-fixed p-col--date">
            {{ announcement.endsAt | date : 'dd.MM.yyyy' }}
          </td>
          <td>
            <div>
              <man-action-menu
                [data]="announcement"
                [options]="announcementMenuOptions"
              ></man-action-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </man-table-wrapper>
</div>

<ng-container *ngIf="formState$ | async as formState">
  <man-side-panel
    *ngIf="formState.show"
    [title]="
      (formState.data
        ? 'admin.announcements.title.edit'
        : 'admin.announcements.title.create'
      ) | translate
    "
    [closeable]="!formState.disabled"
    (closed)="onClose()"
  >
    <man-announcement-form
      class="mx-n3"
      [disabled]="formState.disabled"
      [announcement]="formState.data"
      (create)="onCreate($event)"
      (update)="onUpdate($event)"
      (closed)="onDismiss()"
    ></man-announcement-form>
  </man-side-panel>
</ng-container>
