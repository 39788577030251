import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RevisionStatusListElementFragment = { __typename?: 'RevisionStatus', id: string, position: number, shortName: string, description: string };

export type LoadRevisionStatusQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type LoadRevisionStatusQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', revisionStatus: Array<{ __typename?: 'RevisionStatus', id: string, position: number, shortName: string, description: string }> } };

export const RevisionStatusListElementFragmentDoc = gql`
    fragment RevisionStatusListElement on RevisionStatus {
  id
  position
  shortName
  description
}
    `;
export const LoadRevisionStatusDocument = gql`
    query LoadRevisionStatus($id: ID!) {
  pool(id: $id) {
    revisionStatus {
      ...RevisionStatusListElement
    }
  }
}
    ${RevisionStatusListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadRevisionStatusGQL extends Apollo.Query<LoadRevisionStatusQuery, LoadRevisionStatusQueryVariables> {
    document = LoadRevisionStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }