<ng-container [formGroup]="form">
  <div class="question-content" *ngIf="languageVisibility.de">
    <label for="question_de">{{ 'scrudu.languages.de' | translate }}</label>
    <qf-rich-editor
      id="question_de"
      #editor="richEditor"
      formControlName="contentDe"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.content?.de"
      [inputField]="editor"
      [inputValue]="form.get('contentDe')?.value"
    ></qf-input-feedback>
  </div>
  <div class="question-content" *ngIf="languageVisibility.fr">
    <label for="question_fr">{{ 'scrudu.languages.fr' | translate }}</label>
    <qf-rich-editor
      id="question_fr"
      #editor="richEditor"
      formControlName="contentFr"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.content?.fr"
      [inputField]="editor"
      [inputValue]="form.get('contentFr')?.value"
    ></qf-input-feedback>
  </div>
  <div class="question-content" *ngIf="languageVisibility.en">
    <label for="question_en">{{ 'scrudu.languages.en' | translate }}</label>
    <qf-rich-editor
      id="question_en"
      #editor="richEditor"
      formControlName="contentEn"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.content?.en"
      [inputField]="editor"
      [inputValue]="form.get('contentEn')?.value"
    ></qf-input-feedback>
  </div>
  <div class="question-content" *ngIf="languageVisibility.it">
    <label for="question_it">{{ 'scrudu.languages.it' | translate }}</label>
    <qf-rich-editor
      id="question_it"
      #editor="richEditor"
      formControlName="contentIt"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.content?.it"
      [inputField]="editor"
      [inputValue]="form.get('contentIt')?.value"
    ></qf-input-feedback>
  </div>

  <div class="question-video" *ngIf="languageVisibility.de">
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'de' | async }}"
      [initiallyOpen]="!!form.get('videoDe')?.value"
    >
      <qf-video-upload formControlName="videoDe"></qf-video-upload>
    </co-single-panel-accordion>
  </div>
  <div class="question-video" *ngIf="languageVisibility.fr">
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'fr' | async }}"
      [initiallyOpen]="!!form.get('videoFr')?.value"
    >
      <qf-video-upload formControlName="videoFr"></qf-video-upload>
    </co-single-panel-accordion>
  </div>
  <div class="question-video" *ngIf="languageVisibility.en">
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'en' | async }}"
      [initiallyOpen]="!!form.get('videoEn')?.value"
    >
      <qf-video-upload formControlName="videoEn"></qf-video-upload>
    </co-single-panel-accordion>
  </div>
  <div class="question-video" *ngIf="languageVisibility.it">
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'it' | async }}"
      [initiallyOpen]="!!form.get('videoIt')?.value"
    >
      <qf-video-upload formControlName="videoIt"></qf-video-upload>
    </co-single-panel-accordion>
  </div>

  <div
    class="response-options"
    *ngIf="languageVisibility.de"
    formArrayName="responseOptions"
  >
    <label>{{ 'scrudu.languages.de' | translate }}</label>
    <div
      class="skin-container skin-container--default"
      [dndDropzone]="['de']"
      dndEffectAllowed="move"
      (dndDrop)="responseOptionMoved($event)"
      #solutionContainer
    >
      <div
        class="response-option response-option--placeholder"
        dndPlaceholderRef
      ></div>
      <div
        *ngFor="
          let responseOptionFormGroup of responseOptionsForm.controls;
          index as index
        "
        [formGroupName]="index"
        class="response-option"
        [dndDraggable]="index"
        [dndType]="'de'"
        [dndEffectAllowed]="'move'"
      >
        <co-radio-button-group
          [ngModel]="responseOptionFormGroup.get('correct')?.value"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="responseOptionClicked(responseOptionFormGroup)"
        >
          <button [value]="true" class="btn btn-secondary-migrated">
            <ng-container *ngIf="responseOptionFormGroup.get('correct')?.value">
              {{ 'question_groups.edit.correct' | translate }}
            </ng-container>
            <ng-container
              *ngIf="!responseOptionFormGroup.get('correct')?.value"
            >
              {{ 'question_groups.edit.incorrect' | translate }}
            </ng-container>
          </button>
        </co-radio-button-group>
        <label
          [for]="'question_de_option_' + index"
          class="response-option--label"
          >{{ index | indexAsCharacter }})</label
        >
        <div class="response-option--editor" *ngIf="!isRearranging">
          <qf-rich-editor
            [id]="'question_de_option_' + index"
            #editor="richEditor"
            formControlName="contentDe"
          ></qf-rich-editor>
          <qf-input-feedback
            [validations]="
              (validations?.responseOptions || [])[index]?.content?.de
            "
            [inputField]="editor"
            [inputValue]="responseOptionFormGroup.get('contentDe')?.value"
          ></qf-input-feedback>
        </div>
        <i
          class="fa fa-bars fa-lg fa-rounded-border response-option--drag-handle"
          dndHandle
        ></i>
      </div>
    </div>
    <qf-input-feedback
      [validations]="validations?.aMinusSolution"
      [inputField]="solutionContainer"
      [inputValue]="
        responseOptionsForm.controls[0]?.get('correct')?.value ||
        responseOptionsForm.controls[1]?.get('correct')?.value ||
        responseOptionsForm.controls[2]?.get('correct')?.value ||
        responseOptionsForm.controls[3]?.get('correct')?.value ||
        responseOptionsForm.controls[4]?.get('correct')?.value
      "
    ></qf-input-feedback>
  </div>
  <div
    class="response-options"
    *ngIf="languageVisibility.fr"
    formArrayName="responseOptions"
  >
    <label>{{ 'scrudu.languages.fr' | translate }}</label>
    <div
      class="skin-container skin-container--default"
      [dndDropzone]="['fr']"
      dndEffectAllowed="move"
      (dndDrop)="responseOptionMoved($event)"
      #solutionContainer
    >
      <div
        class="response-option response-option--placeholder"
        dndPlaceholderRef
      ></div>
      <div
        *ngFor="
          let responseOptionFormGroup of responseOptionsForm.controls;
          index as index
        "
        [formGroupName]="index"
        class="response-option"
        [dndDraggable]="index"
        [dndType]="'fr'"
        [dndEffectAllowed]="'move'"
      >
        <co-radio-button-group
          [ngModel]="responseOptionFormGroup.get('correct')?.value"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="responseOptionClicked(responseOptionFormGroup)"
        >
          <button [value]="true" class="btn btn-secondary-migrated">
            <ng-container *ngIf="responseOptionFormGroup.get('correct')?.value">
              {{ 'question_groups.edit.correct' | translate }}
            </ng-container>
            <ng-container
              *ngIf="!responseOptionFormGroup.get('correct')?.value"
            >
              {{ 'question_groups.edit.incorrect' | translate }}
            </ng-container>
          </button>
        </co-radio-button-group>
        <label
          [for]="'question_fr_option_' + index"
          class="response-option--label"
          >{{ index | indexAsCharacter }})</label
        >
        <div class="response-option--editor" *ngIf="!isRearranging">
          <qf-rich-editor
            [id]="'question_fr_option_' + index"
            #editor="richEditor"
            formControlName="contentFr"
          ></qf-rich-editor>
          <qf-input-feedback
            [validations]="
              (validations?.responseOptions || [])[index]?.content?.fr
            "
            [inputField]="editor"
            [inputValue]="responseOptionFormGroup.get('contentFr')?.value"
          ></qf-input-feedback>
        </div>
        <i
          class="fa fa-bars fa-lg fa-rounded-border response-option--drag-handle"
          dndHandle
        ></i>
      </div>
    </div>
    <qf-input-feedback
      [validations]="validations?.aMinusSolution"
      [inputField]="solutionContainer"
      [inputValue]="
        responseOptionsForm.controls[0]?.get('correct')?.value ||
        responseOptionsForm.controls[1]?.get('correct')?.value ||
        responseOptionsForm.controls[2]?.get('correct')?.value ||
        responseOptionsForm.controls[3]?.get('correct')?.value ||
        responseOptionsForm.controls[4]?.get('correct')?.value
      "
    ></qf-input-feedback>
  </div>
  <div
    class="response-options"
    *ngIf="languageVisibility.en"
    formArrayName="responseOptions"
  >
    <label>{{ 'scrudu.languages.en' | translate }}</label>
    <div
      class="skin-container skin-container--default"
      [dndDropzone]="['en']"
      dndEffectAllowed="move"
      (dndDrop)="responseOptionMoved($event)"
      #solutionContainer
    >
      <div
        class="response-option response-option--placeholder"
        dndPlaceholderRef
      ></div>
      <div
        *ngFor="
          let responseOptionFormGroup of responseOptionsForm.controls;
          index as index
        "
        [formGroupName]="index"
        class="response-option"
        [dndDraggable]="index"
        [dndType]="'en'"
        [dndEffectAllowed]="'move'"
      >
        <co-radio-button-group
          [ngModel]="responseOptionFormGroup.get('correct')?.value"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="responseOptionClicked(responseOptionFormGroup)"
        >
          <button [value]="true" class="btn btn-secondary-migrated">
            <ng-container *ngIf="responseOptionFormGroup.get('correct')?.value">
              {{ 'question_groups.edit.correct' | translate }}
            </ng-container>
            <ng-container
              *ngIf="!responseOptionFormGroup.get('correct')?.value"
            >
              {{ 'question_groups.edit.incorrect' | translate }}
            </ng-container>
          </button>
        </co-radio-button-group>
        <label
          [for]="'question_en_option_' + index"
          class="response-option--label"
          >{{ index | indexAsCharacter }})</label
        >
        <div class="response-option--editor" *ngIf="!isRearranging">
          <qf-rich-editor
            [id]="'question_en_option_' + index"
            #editor="richEditor"
            formControlName="contentEn"
          ></qf-rich-editor>
          <qf-input-feedback
            [validations]="
              (validations?.responseOptions || [])[index]?.content?.en
            "
            [inputField]="editor"
            [inputValue]="responseOptionFormGroup.get('contentEn')?.value"
          ></qf-input-feedback>
        </div>
        <i
          class="fa fa-bars fa-lg fa-rounded-border response-option--drag-handle"
          dndHandle
        ></i>
      </div>
    </div>
    <qf-input-feedback
      [validations]="validations?.aMinusSolution"
      [inputField]="solutionContainer"
      [inputValue]="
        responseOptionsForm.controls[0]?.get('correct')?.value ||
        responseOptionsForm.controls[1]?.get('correct')?.value ||
        responseOptionsForm.controls[2]?.get('correct')?.value ||
        responseOptionsForm.controls[3]?.get('correct')?.value ||
        responseOptionsForm.controls[4]?.get('correct')?.value
      "
    ></qf-input-feedback>
  </div>
  <div
    class="response-options"
    *ngIf="languageVisibility.it"
    formArrayName="responseOptions"
  >
    <label>{{ 'scrudu.languages.it' | translate }}</label>
    <div
      class="skin-container skin-container--default"
      [dndDropzone]="['it']"
      dndEffectAllowed="move"
      (dndDrop)="responseOptionMoved($event)"
      #solutionContainer
    >
      <div
        class="response-option response-option--placeholder"
        dndPlaceholderRef
      ></div>
      <div
        *ngFor="
          let responseOptionFormGroup of responseOptionsForm.controls;
          index as index
        "
        [formGroupName]="index"
        class="response-option"
        [dndDraggable]="index"
        [dndType]="'it'"
        [dndEffectAllowed]="'move'"
      >
        <co-radio-button-group
          [ngModel]="responseOptionFormGroup.get('correct')?.value"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="responseOptionClicked(responseOptionFormGroup)"
        >
          <button [value]="true" class="btn btn-secondary-migrated">
            <ng-container *ngIf="responseOptionFormGroup.get('correct')?.value">
              {{ 'question_groups.edit.correct' | translate }}
            </ng-container>
            <ng-container
              *ngIf="!responseOptionFormGroup.get('correct')?.value"
            >
              {{ 'question_groups.edit.incorrect' | translate }}
            </ng-container>
          </button>
        </co-radio-button-group>
        <label
          [for]="'question_it_option_' + index"
          class="response-option--label"
          >{{ index | indexAsCharacter }})</label
        >
        <div class="response-option--editor" *ngIf="!isRearranging">
          <qf-rich-editor
            [id]="'question_it_option_' + index"
            #editor="richEditor"
            formControlName="contentIt"
          ></qf-rich-editor>
          <qf-input-feedback
            [validations]="
              (validations?.responseOptions || [])[index]?.content?.it
            "
            [inputField]="editor"
            [inputValue]="responseOptionFormGroup.get('contentIt')?.value"
          ></qf-input-feedback>
        </div>
        <i
          class="fa fa-bars fa-lg fa-rounded-border response-option--drag-handle"
          dndHandle
        ></i>
      </div>
    </div>
    <qf-input-feedback
      [validations]="validations?.aMinusSolution"
      [inputField]="solutionContainer"
      [inputValue]="
        responseOptionsForm.controls[0]?.get('correct')?.value ||
        responseOptionsForm.controls[1]?.get('correct')?.value ||
        responseOptionsForm.controls[2]?.get('correct')?.value ||
        responseOptionsForm.controls[3]?.get('correct')?.value ||
        responseOptionsForm.controls[4]?.get('correct')?.value
      "
    ></qf-input-feedback>
  </div>
</ng-container>
