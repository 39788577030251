import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  TotalStatisticTypeAFragment,
  TotalStatisticTypeAMinusFragment
} from 'src/app/question-management/services/question-group-statistics.generated';

@Component({
  selector: 'co-type-a-statistics',
  templateUrl: './type-a-statistics.component.html',
  styleUrls: ['./type-a-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeAStatisticsComponent {
  @Input()
  public set statistics(
    value: TotalStatisticTypeAFragment | TotalStatisticTypeAMinusFragment
  ) {
    this._statistics = value;
    this.validStatistics =
      value.A !== undefined ||
      value.B !== undefined ||
      value.C !== undefined ||
      value.D !== undefined ||
      value.E !== undefined ||
      value.empty !== undefined;
  }
  public get statistics():
    | TotalStatisticTypeAFragment
    | TotalStatisticTypeAMinusFragment {
    return this._statistics;
  }

  public validStatistics = false;
  private _statistics:
    | TotalStatisticTypeAFragment
    | TotalStatisticTypeAMinusFragment;
}
