(function() {
  function Controller($scope, $window, Store, UserSelectors, UserActions) {
    this.I18n = I18n;

    this.select = function(language) {
      Store.dispatch(UserActions.setLanguage(language));
      $window.location.reload();
    };

    Store.subscribeOn(
      UserSelectors.getLanguage,
      function(language) {
        this.language = language;
      }.bind(this)
    );
  }

  Controller.$inject = ['$scope', '$window', 'Store', 'Selectors::User', 'Actions::User'];

  angular.module('common').component('languageSelection', {
    template: require('./language_selection.html'),
    controller: Controller
  });
})();
