import * as Types from '../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ExamMetadataFragment = { __typename?: 'Exam', id: string, name: string, dimensions: Array<{ __typename?: 'Dimension', id: string, name: string }> };

export type LoadExamQueryVariables = Types.Exact<{
  examId: Types.Scalars['ID'];
}>;


export type LoadExamQuery = { __typename?: 'Query', exam: { __typename?: 'Exam', id: string, name: string, dimensions: Array<{ __typename?: 'Dimension', id: string, name: string }> } };

export const ExamMetadataFragmentDoc = gql`
    fragment ExamMetadata on Exam {
  id
  name
  dimensions {
    id
    name
  }
}
    `;
export const LoadExamDocument = gql`
    query LoadExam($examId: ID!) {
  exam(id: $examId) {
    ...ExamMetadata
  }
}
    ${ExamMetadataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadExamGQL extends Apollo.Query<LoadExamQuery, LoadExamQueryVariables> {
    document = LoadExamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }