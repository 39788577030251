angular.module('examManagement').controller('ExamBookFormCtrl', [
  '$scope',
  '$uibModalInstance',
  'exam',
  'File',
  'Selectors::ExamList',
  'Store',
  'Repositories::ExamBook',
  function($scope, $uibModalInstance, exam, File, ExamSelectors, Store, ExamBookRepository) {
    function getSelectedExam() {
      return ExamSelectors.getSelectedItem(Store.getState());
    }

    function getExamLanguages(exam) {
      return ['de', 'fr', 'it', 'en'].filter(function(language) {
        return exam[language];
      });
    }

    $scope.I18n = I18n;

    $scope.languages = getExamLanguages(exam);
    $scope.scramblings = ['A', 'B'];
    $scope.exam = exam;
    $scope.examBook = {};
    $scope.examBook.exam_id = $scope.exam.id;
    $scope.examBook.scrambling = $scope.scramblings[0];
    $scope.examBook.language = $scope.languages[0];
    $scope.examBook.display_solution = false;
    $scope.examBook.display_type_desc = false;
    $scope.examBook.display_type_desc_position = true;
    $scope.examBook.display_page_numbers = true;
    $scope.examBook.display_exam_name = false;
    $scope.examBook.criteria = [false, false, false];
    $scope.examBook.inline_title = false;
    $scope.criteria = $scope.exam.presort_criteria;
    $scope.isLoading = false;

    $scope.isQuestionTypeFirstCriteria = function() {
      return $scope.exam.presort_criteria[0].type === 'question_type';
    };

    $scope.create = function() {
      $scope.isLoading = true;
      ExamBookRepository.get(
        $scope.examBook.exam_id,
        $scope.examBook.criteria,
        $scope.examBook.display_exam_name,
        $scope.examBook.display_page_numbers,
        $scope.examBook.display_type_desc,
        $scope.examBook.display_type_desc_position,
        $scope.examBook.inline_title,
        $scope.examBook.language,
        $scope.examBook.display_solution,
        $scope.examBook.scrambling
      ).then(function(data) {
        $scope.isLoading = false;
        File.download(data.url);
        $uibModalInstance.close();
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

    function getLanguages() {
      return $scope.languages.map(function(lang) {
        return {
          label: I18n.t('scrudu.languages.long.' + lang),
          value: lang
        };
      });
    }

    $scope.languageOptions = getLanguages();

    function isDimension(criterion) {
      return criterion.type === 'dimension';
    }

    $scope.areTwoBlueprintsAlreadyChecked = function(excludingIndex) {
      return (
        $scope.areThreeBlueprintsAvailable() &&
        $scope.examBook.criteria.filter(function(criterion, index) {
          return criterion && index !== excludingIndex;
        }).length === 2
      );
    };

    $scope.areThreeBlueprintsAvailable = function() {
      return $scope.criteria.filter(isDimension).length === 3;
    };

    $scope.anyPresortCriteraAvailable = function() {
      return (
        $scope.exam.first_presort_criterion ||
        $scope.exam.second_presort_criterion ||
        $scope.exam.third_presort_criterion
      );
    };

    $scope.anyCriteraEnabled = function() {
      return $scope.examBook.criteria.includes(true);
    };

    $scope.hasScramblingB = function() {
      return getSelectedExam() && getSelectedExam().has_scrambling_b;
    };
  }
]);
