<div
  *ngIf="applications$ | async as applications"
  class="grid h-100"
  style="grid-template-rows: auto 1fr"
>
  <div class="g-col-6 g-col-xl-8 ms-auto order-2">
    <button (click)="onNew()" type="button" class="btn btn-dark">
      {{ 'admin.applications.actions.new' | translate }}
    </button>
  </div>
  <div class="g-col-6 g-col-xl-4 order-1">
    <man-search-input
      [shortcutActive]="true"
      placeholder="admin.applications.filter.placeholder"
      [disabled]="applications.requestState !== 'success'"
      (search)="onSearch($event)"
      (clear)="onSearchClear()"
    >
    </man-search-input>
  </div>
  <man-table-wrapper class="g-col-12 order-3" [request]="applications">
    <p-table
      #tbl
      name="Applications"
      selectionMode="single"
      sortField="name"
      [sortOrder]="1"
      [globalFilterFields]="filterFields"
      [value]="applications.data | copyArray"
      [loading]="applications.requestState === 'loading'"
      [scrollable]="true"
      [selection]="(formState$ | async)?.data"
      scrollHeight="flex"
      (onRowSelect)="onEdit($event.data)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'admin.applications.table.name' | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-application>
        <tr
          [pSelectableRow]="application"
          interactiveRow
          (delete)="onDelete(application.id)"
        >
          <td>{{ application.name }}</td>
          <td>
            <man-action-menu
              [data]="application"
              [options]="applicationsMenuOptions"
            >
            </man-action-menu>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </man-table-wrapper>
</div>

<ng-container *ngIf="formState$ | async as formState">
  <man-side-panel
    *ngIf="formState.show"
    [title]="
      (formState.data
        ? 'admin.applications.title.edit'
        : 'admin.applications.title.add'
      ) | translate
    "
    [closeable]="!formState.disabled"
    (closed)="onClose()"
  >
    <man-application-form
      class="mx-n3"
      [disabled]="formState.disabled"
      [application]="formState.data"
      (create)="onCreate($event)"
      (update)="onUpdate($event)"
      (closed)="onDismiss()"
    ></man-application-form>
  </man-side-panel>
</ng-container>
