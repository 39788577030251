import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { NgSelectWrapperComponent } from '../ng-select-wrapper/ng-select-wrapper.component';

angular
  .module('common')
  .directive(
    'ngSelectWrapper',
    downgradeComponent({ component: NgSelectWrapperComponent })
  );
