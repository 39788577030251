<ng-container *ngIf="!value?.assetId || videoUpload.inProgress">
  <div class="video-container">
    <div
      class="video-thumbnail drop-container"
      ngFileDrop
      #dropContainer
      [options]="videoUpload.uploaderOptions"
      (uploadOutput)="videoUpload.changed($event)"
      [uploadInput]="videoUpload.input"
      [ngClass]="{
        'is-dragging-over': videoUpload.draggingOver,
        disabled: videoUpload.inProgress
      }"
    >
      <small>{{ 'video_upload.drag_here' | translate }}</small>
      <small>{{ 'common.or' | translate }}</small>

      <button
        class="btn btn-primary"
        [disabled]="videoUpload.inProgress"
        (click)="selectFile.click()"
        title="{{ 'video_upload.buttons.select' | translate }}"
      >
        {{ 'video_upload.select_video' | translate }}
      </button>
      <input
        #selectFile
        hidden
        type="file"
        ngFileSelect
        [options]="videoUpload.uploaderOptions"
        (uploadOutput)="videoUpload.changed($event)"
        [uploadInput]="videoUpload.input"
      />
    </div>
    <qf-input-feedback
      [validations]="validations"
      [inputField]="dropContainer"
      [inputValue]="value"
      class="container-padding text-center"
    ></qf-input-feedback>
    <co-progress-bar
      *ngIf="videoUpload.inProgress"
      [percentage]="videoUpload.percentage"
      [filename]="uploadingVideoFilename"
    ></co-progress-bar>
  </div>
  <div class="info-container container-padding wrap-text">
    <div>
      {{ 'video_upload.description' | translate }}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="value?.assetId">
  <div class="video-container">
    <div
      class="video-thumbnail drop-container"
      ngFileDrop
      [options]="thumbnailUpload.uploaderOptions"
      (uploadOutput)="thumbnailUpload.changed($event)"
      [uploadInput]="thumbnailUpload.input"
      [ngClass]="{
        'is-dragging-over': thumbnailUpload.draggingOver,
        disabled: thumbnailUpload.inProgress
      }"
      (click)="openVideo()"
    >
      <i class="fa fa-play-circle fa-3x"></i>
      <co-asset-image
        *ngIf="value?.thumbnailAssetId"
        [assetId]="value?.thumbnailAssetId"
      ></co-asset-image>
    </div>
    <div class="video-actions">
      <a
        href="{{ videoUrl }}"
        download="{{ value?.assetId }}"
        target="_blank"
        title="{{ 'video_upload.buttons.download' | translate }}"
      >
        <button class="btn btn-primary">
          <i class="fa fa-download"></i>
        </button>
      </a>
      <button
        class="btn btn-primary"
        (click)="thumbnailFileInput.click()"
        title="{{ 'video_upload.buttons.thumbnail' | translate }}"
      >
        <i class="fa fa-file-image-o"></i>
      </button>
      <input
        #thumbnailFileInput
        hidden
        type="file"
        ngFileSelect
        [options]="thumbnailUpload.uploaderOptions"
        (uploadOutput)="thumbnailUpload.changed($event)"
        [uploadInput]="thumbnailUpload.input"
      />
      <button
        class="btn btn-primary"
        (click)="removeFile()"
        title="{{ 'video_upload.buttons.remove' | translate }}"
      >
        <i class="fa fa-trash-o"></i>
      </button>
    </div>
    <qf-input-feedback
      [validations]="validations"
      [inputField]="thumbnailFileInput"
      [inputValue]="value?.thumbnailAssetId"
      class="container-padding text-center"
    ></qf-input-feedback>
    <co-progress-bar
      *ngIf="thumbnailUpload.inProgress"
      [percentage]="thumbnailUpload.percentage"
      [filename]="uploadingThumbnailFilename"
    ></co-progress-bar>
  </div>
  <div class="info-container py-2 px-3">
    <strong>{{ value?.filename }}</strong>
    <ng-container *ngIf="valueDescription">
      <p>
        {{ 'video_upload.info.size' | translate }}:
        {{ valueDescription.size | filesize }}
      </p>
      <p>
        {{ 'video_upload.info.duration' | translate }}:
        {{ valueDescription.duration }}
      </p>
      <p>
        {{ 'video_upload.info.resolution' | translate }}:
        {{ valueDescription.resolution }}
      </p>
      <p *ngIf="value?.thumbnailFilename">
        {{ 'video_upload.info.thumbnail' | translate }}:
        {{ value!.thumbnailFilename }}
      </p>
    </ng-container>
  </div>
</ng-container>
