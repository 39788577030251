<div class="modal-wrapper">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'exams.title_infos.details' | translate }}</h3>
  </div>
  <div class="modal-body">
    <ng-container *ngFor="let row of rows">
      <div class="key-value-row" *ngIf="row.value">
        <div>{{ row.key | translate }}</div>
        <div>{{ row.value }}</div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button (click)="dismiss()" class="btn btn-secondary">
      {{ 'common.actions.close' | translate }}
    </button>
  </div>
</div>
