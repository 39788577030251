angular.module('settings').factory('Selectors::CategorySelection', [
  function () {
    function getDimensionItems (object) {
      return (object && object.categorySelection && object.categorySelection.dimensions) || [];
    }

    function getDimensionSelection (object) {
      return object && object.categorySelection && object.categorySelection.selectedDimensionId;
    }

    function getCategoryItems (dimensionItems, selectedId) {
      var dimension = dimensionItems.find(function (d) {
        return d.id == selectedId;
      });
      if (dimension !== undefined) {
        return dimension.categories;
      }
      else {
        return [];
      }
    }

    return {
      getDimensionItems: getDimensionItems,
      getDimensionSelection: getDimensionSelection,
      getCategoryItems: Reselect.createSelector(getDimensionItems, getDimensionSelection, getCategoryItems)
    };
  }
]);
