<span
  style="margin-left: 10px"
  class="fa-stack feedback-outfilled"
  *ngIf="isWarning || isError"
  [ngClass]="{
    'is-warning': isWarning,
    'is-invalid': isError
  }"
  ><i class="fa fa-circle fa-stack-2x"></i
  ><i class="fa fa-stack-1x fa-inverse fa-exclamation"></i>&nbsp;</span
>
