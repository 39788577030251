angular.module('common').factory('QuestionGroupDetails', ['resourceFactory', 'Message', function(resourceFactory, Message) {

  var QuestionGroupDetails = resourceFactory('/api/question_group_details', {});

  QuestionGroupDetails.get = function(questionGroupId) {
    return this.action(questionGroupId, 'GET').then(function(questionGroup) {
      questionGroup.history = (questionGroup.history || []).map(function(message) {
        return new Message(message);
      });
      return questionGroup;
    });
  };
  return QuestionGroupDetails;
}]);
