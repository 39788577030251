/* eslint-disable unicorn/no-null */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Language,
  LongMenuListEntryInput,
  Maybe
} from '../../../../../generated/base-types';
export type LongMenuResponseSolutionInput = Maybe<number>[];

@Component({
  selector: 'qf-long-menu-response-solution',
  templateUrl: './long-menu-response-solution.component.html',
  styleUrls: ['./long-menu-response-solution.component.scss']
})
export class LongMenuResponseSolutionComponent {
  @Input()
  public index: number;

  @Input()
  public set responseSolution(value: LongMenuResponseSolutionInput) {
    if (value.length === 0) {
      this._responseSolution = [null];
      this.changed.emit(this._responseSolution);
    } else {
      this._responseSolution = value;
      this.responseSolutionEntries = this.fetchResponseSolutionEntries();
    }
  }

  @Input()
  public selectedEntryId: number | undefined;

  @Input()
  public set list(value: LongMenuListEntryInput[] | null) {
    this._list = value;
    this.responseSolutionEntries = this.fetchResponseSolutionEntries();
  }

  @Input()
  public language: Language;

  @Input()
  public disabled = false;

  @Input()
  public solutionRemovable = false;

  @Output()
  public changed = new EventEmitter<LongMenuResponseSolutionInput>();

  @Output()
  public removed = new EventEmitter<void>();

  public get hasEmptyAlternative(): boolean {
    return (
      this.responseSolutionEntries.includes(
        undefined as unknown as LongMenuListEntryInput
      ) || this.responseSolutionEntries[0] === undefined
    );
  }

  public responseSolutionEntries: LongMenuListEntryInput[];
  private _responseSolution: LongMenuResponseSolutionInput;
  private _list: LongMenuListEntryInput[] | null;

  public addSolutionResponse(): void {
    // add a placeholder
    const updatedSolution = [...this._responseSolution, null];
    this.changed.emit(updatedSolution);
  }

  public removeResponseSolution(): void {
    this.removed.emit();
  }

  public assignListEntry(index: number): void {
    if (this.selectedEntryId !== undefined) {
      const updatedSolution = this._responseSolution.slice();
      updatedSolution[index] = this.selectedEntryId;
      this.changed.emit(updatedSolution);
    }
  }

  public removeListEntry(index: number, entryId: number | undefined): void {
    let updatedSolution = this._responseSolution.slice();
    updatedSolution[index] = null;
    this.responseSolutionEntries = this.responseSolutionEntries.filter(
      entry => entry?.id !== entryId
    );
    updatedSolution = updatedSolution.filter(
      solutionIndex => solutionIndex !== null
    );
    this.changed.emit(updatedSolution);
  }

  private fetchResponseSolutionEntries(): LongMenuListEntryInput[] {
    const entryMap = new Map();
    this._list?.forEach(entry => entryMap.set(entry.id, entry));

    return this._responseSolution.map(id => entryMap.get(id));
  }
}
