import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { ExamDetailsComponent } from '../components/exam-details.component';

angular
  .module('examManagement')
  .directive(
    'emExamDetailsModal',
    downgradeComponent({ component: ExamDetailsComponent })
  );
