<div
  class="bs4 layout-cell layout--horizontal layout-cell--bottom-small-spaced"
  [ngClass]="{ disabled: isSubmitting }"
>
  <div class="col-sm-4 form-title" *ngIf="mode !== formMode.Edit">
    <b *ngIf="isSingleQuestion">{{
      'questions.edit.new_question' | translate
    }}</b>
    <b *ngIf="isQuestionSequence">{{
      'questions.edit.new_question_sequence' | translate
    }}</b>
    <b *ngIf="isQuestionSeries">{{
      'questions.edit.new_question_series' | translate
    }}</b>
  </div>
  <div
    class="col-sm-4 form-title"
    *ngIf="mode === formMode.Edit && questionGroup"
  >
    <b>
      <span *ngIf="isSingleQuestion">{{
        'question_groups.edit.question' | translate
      }}</span>
      <span *ngIf="!isSingleQuestion">{{
        'question_groups.edit.question_group' | translate
      }}</span>
      <span *ngIf="questionGroup"> {{ questionGroup.sequentialNumber }}</span>
    </b>
  </div>

  <div *ngIf="mode === formMode.Edit && questionGroup" class="col-sm-4">
    <qf-question-navigation
      [visitableIds]="navigationIds"
      [currentId]="questionGroup.id"
      [toggleNext]="toggleNextQG.asObservable()"
      [togglePrevious]="togglePreviousQG.asObservable()"
      (nextToggled)="checkToggleNext()"
      (previousToggled)="checkTogglePrevious()"
    ></qf-question-navigation>
  </div>

  <div class="col-sm-4">
    <div class="layout--horizontal layout--right-content">
      <button
        *ngIf="mode === formMode.Edit"
        class="btn btn-primary layout-cell--horizontally-small-spaced"
        (click)="openNewQuestionGroupModal()"
      >
        {{ 'question_management.toolbar.new' | translate }}
      </button>
      <button
        class="btn btn-primary layout-cell--horizontally-small-spaced"
        (click)="save()"
        [disabled]="isSubmitting || (uploadingImage$ | async)"
      >
        {{ 'common.actions.save' | translate }}
        <div
          class="spinner-border text-light"
          role="status"
          *ngIf="isSubmitting"
        ></div>
      </button>
      <button
        class="btn btn-secondary layout-cell--horizontally-small-spaced"
        (click)="cancel()"
      >
        {{ 'common.actions.close' | translate }}
      </button>
    </div>
  </div>
</div>

<div
  class="bs4 loading"
  *ngIf="!questionGroupInput || !formOptions || !languageVisibility"
>
  <div class="spinner-border text-primary" role="status"></div>
</div>

<ng-container
  *ngIf="
    questionGroupInput &&
    questionGroupInput.type &&
    formOptions &&
    formOptions.languages.length &&
    languageVisibility
  "
>
  <div
    *ngIf="mode === formMode.Edit && questionGroup"
    [ngClass]="{ disabled: isSubmitting }"
    class="layout-cell skin-container-cell--filled-light toolbar--container"
  >
    <div class="col-sm-12">
      <qf-toolbar
        [poolId]="poolId"
        [questionGroup]="questionGroup"
      ></qf-toolbar>
    </div>
  </div>

  <qf-question-group-form
    [ngClass]="{ disabled: isSubmitting }"
    class="layout-cell--resizable layout--vertical"
    [questionGroup]="questionGroupInput"
    (questionGroupChanged)="questionGroupToSave = $event"
    [questionGroupType]="questionGroupInput.type"
    [affiliations]="formOptions.affiliations"
    [languages]="formOptions.languages"
    [dimensions]="formOptions.dimensions"
    [authors]="formOptions.authors"
    [supervisors]="formOptions.supervisors"
    [languageVisibility]="languageVisibility"
    (languageVisibilityChanged)="
      updateLanguageVisibility($event.language, $event.visible)
    "
    [validations]="validations"
    (changed)="setFormHasChanged()"
  >
  </qf-question-group-form>
</ng-container>
