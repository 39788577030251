angular.module('examManagement').factory('Repositories::Candidate', [
  'GraphQLClient',
  function (GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getAllQuery =
        'query($id: Int! $order: [String!]) {' +
        '  exam(id: $id) { id' +
        '    candidates(order: $order) {' +
        '      id omr reg_number first_name last_name scrambling' +
        '      language location successful provision_failed' +
        '      room email phone_number tags' +
        '      destroy_failed measured_error_message' +
        '    } ' +
        '  }' +
        '}';

    return {
      getAll: function (examId, order) {
        return client
          .query(getAllQuery, { id: examId, order: order })
          .then(function (response) {
            return response.data.data.exam.candidates;
          });
      }
    };
  }
]);
