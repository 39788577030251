import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { ChooseQuestionGroupTypeModalComponent } from '../modals/choose-question-group-type-modal/choose-question-group-type-modal.component';

angular
  .module('common')
  .directive(
    'coChooseQuestionGroupTypeModal',
    downgradeComponent({ component: ChooseQuestionGroupTypeModalComponent })
  );
