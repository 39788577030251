import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { ExamQuestionGroupExportModalComponent } from '../modals/exam-question-group-export-modal/exam-question-group-export-modal.component';

angular
  .module('examManagement')
  .directive(
    'emQuestionGroupExportModal',
    downgradeComponent({ component: ExamQuestionGroupExportModalComponent })
  );
