<co-base-confirm-modal
  [title]="'question_management.task_modal.confirmation.title' | translate"
  (dismiss)="onDismiss()"
  (confirm)="onConfirm()"
>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.task_modal.confirmation.receiver' | translate }}
    </dt>
    <dd class="col-sm-9 text-break">
      <div *ngFor="let user of users">
        {{ user.firstName }} {{ user.lastName }} <{{ user.email }}>
      </div>
    </dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.task_modal.confirmation.subject' | translate }}
    </dt>
    <dd class="col-sm-9 text-break">{{ subject }}</dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.task_modal.confirmation.body' | translate }}
    </dt>
    <dd class="col-sm-9 text-break">
      {{ body }}
    </dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.create_task_modal.single_question' | translate }}
    </dt>
    <dd class="col-sm-9 text-break">
      <div *ngFor="let questionType of questionTypes">
        {{ 'questions.type.' + questionType | translate }}
      </div>
    </dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{
        'question_management.create_task_modal.question_group_type.title'
          | translate
      }}
    </dt>
    <dd class="col-sm-9 text-break">
      <div *ngFor="let questionGroupType of questionGroupTypes">
        {{
          'question_management.create_task_modal.question_group_type.' +
            questionGroupType.toLowerCase() | translate
        }}
      </div>
    </dd>
  </dl>
</co-base-confirm-modal>
