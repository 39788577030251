import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import deNewTranslations from '../i18n/new/de.json';
import enNewTranslations from '../i18n/new/en.json';
import frNewTranslations from '../i18n/new/fr.json';
import deOldTranslations from '../i18n/old/de.json';
import enOldTranslations from '../i18n/old/en.json';
import frOldTranslations from '../i18n/old/fr.json';
import itOldTranslations from '../i18n/old/it.json';
import { OLD_TRANSLATIONS_PREFIX } from './common/utils/old-translation-handler';
export class StaticTranslateLoader implements TranslateLoader {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'de': {
        return of({
          [OLD_TRANSLATIONS_PREFIX]: deOldTranslations,
          ...deNewTranslations
        });
      }
      case 'it': {
        return of({ ...itOldTranslations });
      }
      case 'fr': {
        return of({
          [OLD_TRANSLATIONS_PREFIX]: frOldTranslations,
          ...frNewTranslations
        });
      }
      default: {
        return of({
          [OLD_TRANSLATIONS_PREFIX]: enOldTranslations,
          ...enNewTranslations
        });
      }
    }
  }
}
