angular.module('examManagement').factory('Reducers::ExamManagementRoot', [
  'Reducers::ExamList',
  'Reducers::ExamQuestionGroupList',
  'Reducers::CandidateList',
  function (ExamListReducer, ExamQuestionGroupListReducer, CandidateListReducer) {
    function reducer (state, action) {
      state = state || {};
      state = Object.assign({}, state, {
        examList: ExamListReducer(state.examList, action),
        examQuestionGroupList: ExamQuestionGroupListReducer(state.examQuestionGroupList, action),
        candidateList: CandidateListReducer(state.candidateList, action)
      });
      return state;
    }

    return reducer;
  }
]);
