<div class="grid h-100" style="grid-template-rows: auto 1fr">
  <div class="g-col-1 g-col-md-3 g-col-xl-2">
    <a
      [routerLink]="['../']"
      [title]="'admin.navigation.pools' | translate"
      class="btn btn-light d-block d-md-inline-flex align-items-md-center gap-2"
    >
      <i class="fa-solid fa-sm fa-arrow-left" aria-hidden="true"></i>
      <span class="d-none d-md-inline-block">
        {{ 'admin.navigation.pools' | translate }}</span
      ></a
    >
  </div>
  <div class="g-col-11 g-col-md-9 g-col-xl-10 align-self-center">
    <h5 class="my-0">{{ poolName$ | async }}</h5>
  </div>
  <div class="g-col-1 g-col-md-3 g-col-xl-2">
    <nav class="nav nav-pills flex-column me-sm-0">
      <a
        [routerLink]="['./details']"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        [title]="'admin.pools.nav.details' | translate"
        class="nav-link d-inline-flex align-items-center gap-3 mw-100"
        ><i class="fa-regular fa-cards-blank" aria-hidden="true"></i>
        <span class="d-none d-md-inline-block text-truncate">
          {{ 'admin.pools.nav.details' | translate }}</span
        ></a
      >
      <a
        [routerLink]="['./permissions']"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        [title]="'admin.pools.nav.permissions' | translate"
        class="nav-link d-inline-flex align-items-center gap-3 mw-100"
        ><i class="fa-regular fa-user-lock" aria-hidden="true"></i>
        <span class="d-none d-md-inline-block text-truncate">
          {{ 'admin.pools.nav.permissions' | translate }}</span
        ></a
      >
      <a
        [routerLink]="['./affiliations']"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        [title]="'admin.pools.nav.affiliations' | translate"
        class="nav-link d-inline-flex align-items-center gap-3 mw-100"
        ><i class="fa-regular fa-anchor-lock" aria-hidden="true"></i>
        <span class="d-none d-md-inline-block text-truncate">
          {{ 'admin.pools.nav.affiliations' | translate }}</span
        ></a
      >
      <a
        [routerLink]="['./revision-status']"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        [title]="'admin.pools.nav.revision_status' | translate"
        class="nav-link d-inline-flex align-items-center gap-3 mw-100"
        ><i class="fa-regular fa-timeline-arrow" aria-hidden="true"></i>
        <span class="d-none d-md-inline-block text-truncate">
          {{ 'admin.pools.nav.revision_status' | translate }}</span
        ></a
      >
      <a
        [routerLink]="['./blueprints']"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        [title]="'admin.pools.nav.blueprints' | translate"
        class="nav-link d-inline-flex align-items-center gap-3 mw-100"
        ><i class="fa-regular fa-map" aria-hidden="true"></i>
        <span class="d-none d-md-inline-block text-truncate">
          {{ 'admin.pools.nav.blueprints' | translate }}</span
        ></a
      >
      <a
        [routerLink]="['./subjects']"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        [title]="'admin.pools.nav.subjects' | translate"
        class="nav-link d-inline-flex align-items-center gap-3 mw-100"
        ><i class="fa-regular fa-list-tree" aria-hidden="true"></i>
        <span class="d-none d-md-inline-block text-truncate">
          {{ 'admin.pools.nav.subjects' | translate }}</span
        ></a
      >
    </nav>
  </div>
  <div class="g-col-11 g-col-md-9 g-col-xl-10">
    <div *ngIf="failure$ | async" class="alert alert-info" role="alert">
      {{ 'state_error.general_error' | translate }}
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
