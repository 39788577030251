import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbButtonsModule,
  NgbNavModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DndModule } from 'ngx-drag-drop';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxUploaderModule } from 'ngx-uploader';
import { CommonModule as ManagerCommonModule } from '../common/common.module';
import './angularjs/module';
import { UnsavedChangesModalComponent } from '../common/modals/unsaved-changes-modal/unsaved-changes-modal.component';
import { ChangeTypeModalComponent } from './change-type-modal/change-type-modal.component';
import { ChangeTypeModalToggleComponent } from './change-type-modal-toggle/change-type-modal-toggle.component';
import { PreselectIfSingleValuedDirective } from './directives/preselect-if-single-valued.directive';
import './downgraded';
import { RemoveListConfirmModalComponent } from './downgraded/components/remove-list-confirm-modal/remove-list-confirm-modal.component';
import { ReplaceListConfirmModalComponent } from './downgraded/components/replace-list-confirm-modal/replace-list-confirm-modal.component';
import { VideoModalComponent } from './downgraded/components/video-modal.component';
import { VideoRemoveModalComponent } from './downgraded/components/video-remove-modal.component';
import { EditQuestionGroupComponent } from './edit-question-group/edit-question-group.component';
import { IconFeedbackComponent } from './icon-feedback/icon-feedback.component';
import { IndexAsCharacterPipe } from './index-as-character.pipe';
import { InputFeedbackComponent } from './input-feedback/input-feedback.component';
import { TranslateToLanguagePipe } from './pipes/translate-to-language.pipe';
import { QuestionContentTypeAFormComponent } from './question-content-type-a-form/question-content-type-a-form.component';
import { QuestionContentTypeAMinusFormComponent } from './question-content-type-a-minus-form/question-content-type-a-minus-form.component';
import { QuestionContentTypeEFormComponent } from './question-content-type-e-form/question-content-type-e-form.component';
import { QuestionContentTypeESolutionSelectionComponent } from './question-content-type-e-form/question-content-type-e-solution-selection/question-content-type-e-solution-selection.component';
import { QuestionContentTypeFreeTextFormComponent } from './question-content-type-free-text-form/question-content-type-free-text-form.component';
import { QuestionContentTypeKprimeFormComponent } from './question-content-type-kprime-form/question-content-type-kprime-form.component';
import { LongMenuResponseSolutionComponent } from './question-content-type-long-menu-form/long-menu-solution-container/long-menu-response-solution/long-menu-response-solution.component';
import { LongMenuSolutionContainerComponent } from './question-content-type-long-menu-form/long-menu-solution-container/long-menu-solution-container.component';
import { QuestionContentTypeLongMenuFormComponent } from './question-content-type-long-menu-form/question-content-type-long-menu-form.component';
import { QuestionLongMenuListComponent } from './question-content-type-long-menu-form/question-long-menu-list/question-long-menu-list.component';
import { QuestionFormComponent } from './question-form/question-form.component';
import { QuestionFormToolbarComponent } from './question-form-toolbar/question-form-toolbar.component';
import { QuestionGroupFormComponent } from './question-group-form/question-group-form.component';
import { QuestionGroupMetadataComponent } from './question-group-metadata/question-group-metadata.component';
import { QuestionNavigationComponent } from './question-navigation/question-navigation.component';
import { QuestionTabsComponent } from './question-tabs/question-tabs.component';
import { RichEditorComponent } from './rich-editor/rich-editor.component';
import { FormState } from './state/form.state';
import { QuestionGroupState } from './state/question-group.state';
import { SwitchPanelToggleComponent } from './switch-panel-toggle/switch-panel-toggle.component';
import { VideoUploadComponent } from './video-upload/video-upload.component';

@NgModule({
  declarations: [
    QuestionNavigationComponent,
    QuestionGroupMetadataComponent,
    IconFeedbackComponent,
    InputFeedbackComponent,
    QuestionTabsComponent,
    QuestionFormComponent,
    QuestionContentTypeAFormComponent,
    QuestionContentTypeAMinusFormComponent,
    QuestionContentTypeKprimeFormComponent,
    QuestionContentTypeFreeTextFormComponent,
    QuestionContentTypeLongMenuFormComponent,
    QuestionLongMenuListComponent,
    RichEditorComponent,
    QuestionContentTypeEFormComponent,
    QuestionContentTypeESolutionSelectionComponent,
    SwitchPanelToggleComponent,
    IndexAsCharacterPipe,
    TranslateToLanguagePipe,
    PreselectIfSingleValuedDirective,
    QuestionGroupFormComponent,
    VideoUploadComponent,
    VideoModalComponent,
    VideoRemoveModalComponent,
    LongMenuSolutionContainerComponent,
    LongMenuResponseSolutionComponent,
    ReplaceListConfirmModalComponent,
    RemoveListConfirmModalComponent,
    EditQuestionGroupComponent,
    UnsavedChangesModalComponent,
    ChangeTypeModalToggleComponent,
    ChangeTypeModalComponent,
    QuestionFormToolbarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbButtonsModule,
    NgbNavModule,
    DndModule,
    ManagerCommonModule,
    NgxUploaderModule,
    NgxFilesizeModule,
    NgbTooltipModule,
    NgxsModule.forFeature([FormState, QuestionGroupState]),
    EditorModule
  ]
})
export class QuestionFormModule {}
