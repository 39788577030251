(function () {
  angular.module('settings').component('poolPermissions', {
    template: require('./pool_permissions.html'),
    controller: Controller,
    bindings: {
      pool: '<'
    }
  });

  function Controller($element, Repository) {
    function createPermission(permission, poolId) {
      Repository.create({
        user_id: permission.user.id,
        pool_id: poolId,
        role: permission.role,
        subject_id: permission.subject && permission.subject.id
      });
    }

    function updatePermission(newPermission, oldPermission, poolId) {
      Repository.update(
        {
          user_id: oldPermission.user.id,
          pool_id: poolId,
          role: oldPermission.role,
          subject_id: oldPermission.subject && oldPermission.subject.id
        },
        {
          user_id: newPermission.user.id,
          pool_id: poolId,
          role: newPermission.role,
          subject_id: newPermission.subject && newPermission.subject.id
        }
      );
    }

    this.$onInit = function () {
      $element.addClass('layout--vertical');
      $element.css('height', '100%');
    };

    this.I18n = I18n;
    this.roles = [
      { key: 'administrator', value: I18n.t('pool_permissions.administrator') },
      { key: 'coordinator', value: I18n.t('pool_permissions.coordinator') },
      {
        key: 'question_author',
        value: I18n.t('pool_permissions.question_author')
      },
      {
        key: 'basic_evaluator',
        value: I18n.t('pool_permissions.basic_evaluator')
      },
      {
        key: 'advanced_evaluator',
        value: I18n.t('pool_permissions.advanced_evaluator')
      },
      { key: 'rater', value: I18n.t('pool_permissions.rater') }
    ];

    Repository.getAllByPoolId(this.pool.id).then(
      function (result) {
        this.permissions = result.permissions;
        this.users = result.users;
        this.subjects = result.subjects;
      }.bind(this)
    );

    this.enableEditMode = function (rowIndex) {
      if (this.editingRow === undefined) {
        this.editingRow = rowIndex;
        this.permissionSavepoint = Object.assign(
          {},
          this.permissions[rowIndex]
        );
      }
    }.bind(this);
    this.disableEditMode = function (rowIndex) {
      delete this.editingRow;
      delete this.permissionSavepoint;
    }.bind(this);

    this.saveChanges = function (rowIndex) {
      if (this.permissions[rowIndex].new) {
        createPermission(this.permissions[rowIndex], this.pool.id);
      } else {
        updatePermission(
          this.permissions[rowIndex],
          this.permissionSavepoint,
          this.pool.id
        );
      }
      this.disableEditMode(rowIndex);
    }.bind(this);

    this.deletePermission = function (permission) {
      Repository.delete({
        user_id: permission.user.id,
        pool_id: this.pool.id,
        role: permission.role,
        subject_id: permission.subject && permission.subject.id
      }).then(
        function () {
          var index = this.permissions.indexOf(permission);
          this.permissions.splice(index, 1);
        }.bind(this)
      );
    }.bind(this);

    this.addNewPermission = function () {
      this.permissions = [
        {
          new: true,
          user: this.users[0],
          role: 'question_author'
        }
      ].concat(this.permissions);
      this.enableEditMode(0);
    }.bind(this);

    this.roleChanged = function (permission) {
      if (permission.role === 'coordinator') {
        permission.subject = this.subjects[0];
      }
    }.bind(this);
  }

  Controller.$inject = ['$element', 'Repositories::Permission'];
})();
