import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { ReviseTaskModalComponent } from '../modals/revise-task-modal/revise-task-modal.component';

angular
  .module('common')
  .directive(
    'coReviseTaskModal',
    downgradeComponent({ component: ReviseTaskModalComponent })
  );
