import { ManagerError } from 'src/app/common/utils/error-parser';
import { Scalars } from 'src/generated/base-types';
import { PoolDetailsFragment } from '../services/load-pool.generated';

export class LoadPool {
  public static readonly type = '[NewPool] Load Pools';
  constructor(public readonly id: Scalars['ID']) {}
}

export class LoadPoolSuccess {
  public static readonly type = '[NewPool] Load Pool Success';
  constructor(public readonly pool: PoolDetailsFragment) {}
}

export class LoadPoolFailure {
  public static readonly type = '[NewPool] Load Pool Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Scalars['ID']
  ) {}
}
