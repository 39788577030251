import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { resetTimeToZero } from './date';

export const dateGteCompareValidatorFactory = (
  compareToControlName: string,
  controlName: string
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const ctrl = control.get(controlName);
    const compareToCtrl = control.get(compareToControlName);

    if (!ctrl) {
      throw new Error(`Control ${ctrl} not found.`);
    }
    if (!compareToCtrl) {
      throw new Error(`Control ${compareToCtrl} not found.`);
    }

    const date = new Date(ctrl.value);
    const compareToDate = resetTimeToZero(compareToCtrl.value);

    return date < compareToDate
      ? {
          mustBeAtOrAfterDate: {
            date: compareToDate
          }
        }
      : null; // eslint-disable-line unicorn/no-null -- null is the correct return value for a validator
  };
};
