angular.module('examManagement').factory('ConfirmMessages', [
  function() {
    return {
      examLockMessage: function() {
        return I18n.t("exams.exams_table.confirm_lock");
      },
      examDeleteMessage: function(nr) {
        return I18n.t("exams.exams_table.confirm_delete", { nr: nr });
      },
      examPresortMessage: function() {
        return I18n.t("exams.confirm_messages.presort");
      },
      questionGroupDeleteMessage: function(nr) {
        return I18n.t("exams.question_groups_table.confirm_delete", { nr: nr });
      },
      cantAct: function() {
        return I18n.t("exams.exams_table.alert_cant_act");
      },
      questionGroupUpdateVersion: function(nr) {
        return I18n.t("exams.question_groups_table.confirm_version_update", { nr: nr });
      }
    };
  }
]);
