import {
  QuestionAInput,
  QuestionEInput,
  QuestionFreeTextInput,
  QuestionInput,
  QuestionKPrimeInput,
  QuestionLongMenuInput,
  TranslatedContent
} from 'src/generated/base-types';
import { Voidable } from '../../utils/type-guards/voidable';

export interface ContentTransformer {
  (content: string | Voidable): string | undefined;
}

export class QuestionContentTransformer {
  constructor(public transformer: ContentTransformer) {}

  public transform(questionInput: QuestionInput): QuestionInput {
    return this.transformQuestionContent(questionInput);
  }

  private transformQuestionContent(
    questionInput: QuestionInput
  ): QuestionInput {
    return {
      ...questionInput,
      ...(questionInput.a && {
        a: this.transformQuestionAContent(questionInput.a)
      }),
      ...(questionInput.aMinus && {
        aMinus: this.transformQuestionAContent(questionInput.aMinus)
      }),
      ...(questionInput.kPrime && {
        kPrime: this.transformQuestionKPrimeContent(questionInput.kPrime)
      }),
      ...(questionInput.e && {
        e: this.transformQuestionEContent(questionInput.e)
      }),
      ...(questionInput.freeText && {
        freeText: this.transformQuestionFreeTextContent(questionInput.freeText)
      }),
      ...(questionInput.longMenu && {
        longMenu: this.transformQuestionLongMenuContent(questionInput.longMenu)
      })
    };
  }

  private transformQuestionFreeTextContent(
    questionInput: QuestionFreeTextInput
  ): QuestionFreeTextInput {
    return {
      ...questionInput,
      content: this.transformTranslatedContent(questionInput.content),
      solution: this.transformTranslatedContent(questionInput.solution)
    };
  }

  private transformQuestionAContent(
    questionInput: QuestionAInput
  ): QuestionAInput {
    return {
      ...questionInput,
      content: this.transformTranslatedContent(questionInput.content),
      responseOptions: questionInput.responseOptions.map(responseOption => {
        return {
          ...responseOption,
          ...(responseOption.content && {
            content: this.transformTranslatedContent(responseOption.content)
          })
        };
      })
    };
  }

  private transformQuestionKPrimeContent(
    questionInput: QuestionKPrimeInput
  ): QuestionKPrimeInput {
    return {
      ...questionInput,
      content: this.transformTranslatedContent(questionInput.content),
      propositions: questionInput.propositions.map(proposition => {
        return {
          ...proposition,
          ...(proposition.content && {
            content: this.transformTranslatedContent(proposition.content)
          })
        };
      })
    };
  }

  private transformQuestionEContent(
    questionInput: QuestionEInput
  ): QuestionEInput {
    return {
      ...questionInput,
      statementA: this.transformTranslatedContent(questionInput.statementA),
      statementB: this.transformTranslatedContent(questionInput.statementB)
    };
  }

  private transformQuestionLongMenuContent(
    questionInput: QuestionLongMenuInput
  ): QuestionLongMenuInput {
    return {
      ...questionInput,
      content: this.transformTranslatedContent(questionInput.content)
    };
  }

  private transformTranslatedContent(
    content: TranslatedContent
  ): TranslatedContent {
    return {
      ...(content.de !== undefined && { de: this.transformer(content.de) }),
      ...(content.en !== undefined && { en: this.transformer(content.en) }),
      ...(content.fr !== undefined && { fr: this.transformer(content.fr) }),
      ...(content.it !== undefined && { it: this.transformer(content.it) })
    };
  }
}
