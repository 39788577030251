import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Scalars } from '../../../../generated/base-types';
import { UibModalInstance } from '../../../common/legacy.types';
import {
  ExportConfig,
  ExportFormats
} from '../../../common/question-group-export/question-group-export.types';
import { isDefined } from '../../../common/utils/type-guards/is-defined';
import { ExamQuestionGroupExporterService } from '../../services/exam-question-group-exporter.service';
import { ExamListState } from '../../state/exam-list.state';
import { ExamQuestionGroupExportGQL } from './exam-question-group-export.generated';

@Component({
  selector: 'em-question-group-export-modal',
  templateUrl: './exam-question-group-export-modal.component.html',
  styleUrls: ['./exam-question-group-export-modal.component.scss']
})
export class ExamQuestionGroupExportModalComponent implements OnInit {
  @Input()
  public modalInstance: UibModalInstance;

  @Select(ExamListState.selectedExamId)
  private selectedExamId$: Observable<Scalars['ID'] | undefined>;

  public exportConfig: Omit<ExportConfig, 'type'> = {
    format: ExportFormats.Excel
  };
  public isSubmitting = false;
  private selectedExamId: Scalars['ID'];

  constructor(
    public examQuestionGroupExporterService: ExamQuestionGroupExporterService,
    public examQuestionGroupExportGQL: ExamQuestionGroupExportGQL
  ) {}

  public ngOnInit(): void {
    this.selectedExamId$.pipe(filter(isDefined), first()).subscribe(examId => {
      this.selectedExamId = examId;
    });
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public async generate(): Promise<void> {
    this.isSubmitting = true;

    const result = await firstValueFrom(
      this.examQuestionGroupExportGQL.fetch({ examId: this.selectedExamId })
    );

    const examQuestionGroups = result.data.exam.bookletA.examQuestionGroups;

    if (this.exportConfig.format === ExportFormats.Csv) {
      this.examQuestionGroupExporterService.saveAsCsv(examQuestionGroups);
    } else if (this.exportConfig.format === ExportFormats.Excel) {
      this.examQuestionGroupExporterService.saveAsXlsx(examQuestionGroups);
    }

    this.modalInstance.close();
  }
}
