/* eslint-disable camelcase */
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  assertIsDefined,
  isDefined
} from '../../common/utils/type-guards/is-defined';
import { AssetService, Metadata } from '../../services/asset.service';
import { ExamQuestionGroupDetailsGQL } from './exam-question-group-details/exam-question-groups.generated';

@Component({
  selector: 'em-exam-details-modal',
  templateUrl: './exam-details.component.html',
  styleUrls: ['./exam-details.component.scss']
})
export class ExamDetailsComponent implements OnInit {
  @Input()
  public exam: Exam;
  @Input()
  public modalInstance: { dismiss(): void };
  public rows: { key: string; value: string }[] = [];

  constructor(
    private readonly translate: TranslateService,
    private readonly datePipe: DatePipe,
    private readonly examQuestionGroupDetailsGQL: ExamQuestionGroupDetailsGQL,
    private readonly assetService: AssetService
  ) {}

  public ngOnInit(): void {
    const languages: string[] = [];

    this.rows = [
      {
        key: 'activerecord.attributes.exam.name',
        value: this.exam.name
      },
      {
        key: 'activerecord.attributes.exam.languages',
        value: ''
      }
    ];

    if (this.exam.de) {
      languages.push(this.translate.instant('scrudu.languages.long.de'));
      this.rows.push({
        key: 'activerecord.attributes.exam.content_de',
        value: this.exam.content_de
      });
    }
    if (this.exam.fr) {
      languages.push(this.translate.instant('scrudu.languages.long.fr'));
      this.rows.push({
        key: 'activerecord.attributes.exam.content_fr',
        value: this.exam.content_fr
      });
    }
    if (this.exam.it) {
      languages.push(this.translate.instant('scrudu.languages.long.it'));
      this.rows.push({
        key: 'activerecord.attributes.exam.content_it',
        value: this.exam.content_it
      });
    }
    if (this.exam.en) {
      languages.push(this.translate.instant('scrudu.languages.long.en'));
      this.rows.push({
        key: 'activerecord.attributes.exam.content_en',
        value: this.exam.content_en
      });
    }

    // set languages from before
    this.rows[1].value = languages.join(', ');

    this.rows.push(
      {
        key: 'activerecord.attributes.exam.date',
        value: this.exam.date
      },
      {
        key: 'activerecord.attributes.exam.duration',
        value: this.exam.duration
      },
      {
        key: 'activerecord.attributes.exam.medium',
        value: this.translate.instant(
          `choices.exams.medium.${this.exam.medium.toLowerCase()}`
        )
      },
      {
        key: 'activerecord.attributes.exam.typ',
        value: this.translate.instant(
          `choices.exams.typ.${this.exam.typ.toLowerCase()}`
        )
      },
      {
        key: 'activerecord.attributes.exam.locked_at_date',
        value:
          this.datePipe.transform(
            this.exam.locked_at_date,
            'dd-MM-yyyy HH:mm'
          ) ?? ''
      },
      {
        key: 'exams.question_group_presort.presort.first_criterion',
        value:
          this.exam.presort_criteria?.[0]?.name ??
          this.translate.instant(
            'exams.question_group_presort.presort.options.sort_by_question_type'
          )
      },
      {
        key: 'exams.question_group_presort.presort.second_criterion',
        value:
          this.exam.presort_criteria?.[1]?.name ??
          this.translate.instant(
            'exams.question_group_presort.presort.options.empty'
          )
      },
      {
        key: 'exams.question_group_presort.presort.third_criterion',
        value:
          this.exam.presort_criteria?.[2]?.name ??
          this.translate.instant(
            'exams.question_group_presort.presort.options.empty'
          )
      }
    );

    if (isDefined(this.exam.allow_question_comments)) {
      this.rows.push({
        key: 'exams.exam_provision_modal.form.allow_question_comments',
        value: this.exam.allow_question_comments
          ? this.translate.instant('common.true')
          : this.translate.instant('common.false')
      });
    }

    if (isDefined(this.exam.show_first_dimension_presort)) {
      this.rows.push({
        key: 'exams.title_infos.dimension_presorting',
        value: this.exam.show_first_dimension_presort
          ? this.translate.instant('common.true')
          : this.translate.instant('common.false')
      });
    }

    this.getTotalAssetMbSize(this.exam.id.toString()).then(size => {
      this.rows.push({
        key: this.translate.instant('activerecord.attributes.exam.asset_size'),
        value: `${size}`
      });
    });
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  private async getTotalAssetMbSize(examId: string): Promise<number> {
    const query = await this.examQuestionGroupDetailsGQL
      .fetch({ examId })
      .toPromise();

    assertIsDefined(query);

    const assetIds = [
      ...query.data.exam.imageAssets,
      ...query.data.exam.videoAssets
    ];

    if (assetIds.length === 0) {
      return 0;
    }

    const metadata = await this.assetService.getMultiMetadata<Metadata>(
      assetIds
    );
    const assetByteSize = metadata.reduce((acc, m) => acc + m.size, 0);
    const assetMbSize = Math.round(assetByteSize / (1000 * 1024));

    return assetMbSize;
  }
}

export interface Exam {
  id: number;
  name: string;
  de: boolean;
  fr: boolean;
  it: boolean;
  en: boolean;
  content_de: string;
  content_fr: string;
  content_it: string;
  content_en: string;
  date: string;
  duration: string;
  medium: string;
  typ: string;
  locked_at_date: string | undefined;
  allow_question_comments?: boolean;
  show_first_dimension_presort?: boolean;
  presort_criteria: { name: string; type: string; position: number }[];
}
