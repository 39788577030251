import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ExamQuestionGroupDetailsQueryVariables = Types.Exact<{
  examId: Types.Scalars['ID'];
}>;


export type ExamQuestionGroupDetailsQuery = { __typename?: 'Query', exam: { __typename?: 'Exam', imageAssets: Array<string>, videoAssets: Array<string>, questionGroups: Array<{ __typename?: 'QuestionSequence', type: Types.QuestionGroupType, totalScore: number } | { __typename?: 'QuestionSeries', type: Types.QuestionGroupType, totalScore: number } | { __typename?: 'SingleQuestion', type: Types.QuestionGroupType, totalScore: number }> } };

export const ExamQuestionGroupDetailsDocument = gql`
    query ExamQuestionGroupDetails($examId: ID!) {
  exam(id: $examId) {
    imageAssets
    videoAssets
    questionGroups {
      type
      totalScore
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExamQuestionGroupDetailsGQL extends Apollo.Query<ExamQuestionGroupDetailsQuery, ExamQuestionGroupDetailsQueryVariables> {
    document = ExamQuestionGroupDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }