import { RemoteData, RequestState } from './remote-data';

export const requestStateInitial = requestStateMatch('initial');
export const requestStateLoading = requestStateMatch('loading');
export const requestStateSuccess = requestStateMatch('success');
export const requestStateFailure = requestStateMatch('failure');

function requestStateMatch(
  requestState: RequestState
): <T>(remoteData: RemoteData<T>) => boolean {
  return ({ requestState: rs }) => rs === requestState;
}
