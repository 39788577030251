export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  ISO8601Date: string;
  ISO8601DateTime: string;
  JSON: object;
  StringifiedJSON: string;
};

export type AchievedPointsStatistic = {
  __typename?: 'AchievedPointsStatistic';
  p: Scalars['Float'];
  points: Scalars['Float'];
};

export type Affiliation = {
  __typename?: 'Affiliation';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
};

export type Announcement = {
  __typename?: 'Announcement';
  enabled: Scalars['Boolean'];
  endsAt: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  message: TranslatedContent;
  severity: AnnouncementSeverity;
  startsAt: Scalars['ISO8601Date'];
};

export type AnnouncementInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  endsAt?: InputMaybe<Scalars['ISO8601Date']>;
  message?: InputMaybe<TranslatedContentInput>;
  severity?: InputMaybe<AnnouncementSeverity>;
  startsAt?: InputMaybe<Scalars['ISO8601Date']>;
};

export enum AnnouncementSeverity {
  Danger = 'danger',
  Info = 'info',
  Warning = 'warning'
}

export type AnnouncementValidation = {
  __typename?: 'AnnouncementValidation';
  endsAt?: Maybe<Array<ValidationsMessage>>;
  message?: Maybe<ValidationsTranslatedContent>;
  startsAt?: Maybe<Array<ValidationsMessage>>;
};

/** Autogenerated return type of AssignExams. */
export type AssignExamsPayload = {
  __typename?: 'AssignExamsPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of AssignLabels. */
export type AssignLabelsPayload = {
  __typename?: 'AssignLabelsPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

export type BaseError = {
  code: ValidationCode;
  value?: Maybe<Scalars['String']>;
};

export type Booklet = {
  __typename?: 'Booklet';
  examQuestionGroups: Array<ExamQuestionGroup>;
};

export type Category = {
  __typename?: 'Category';
  customIdentifier?: Maybe<Scalars['String']>;
  dimension: Dimension;
  id: Scalars['ID'];
  name: Scalars['String'];
  nameDe?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  nameFr?: Maybe<Scalars['String']>;
  nameIt?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type CategoryInput = {
  customIdentifier?: InputMaybe<Scalars['String']>;
  nameDe?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  nameFr?: InputMaybe<Scalars['String']>;
  nameIt?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ChangeQuestion. */
export type ChangeQuestionPayload = {
  __typename?: 'ChangeQuestionPayload';
  question?: Maybe<Question>;
  successful: Scalars['Boolean'];
};

export type Comment = Message & {
  __typename?: 'Comment';
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  text: Scalars['String'];
};

export type CommentInput = {
  read?: InputMaybe<Scalars['Boolean']>;
};

export enum ContentValidationStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  Missing = 'MISSING',
  Undefined = 'UNDEFINED'
}

/** Autogenerated return type of CreateAffiliation. */
export type CreateAffiliationPayload = {
  __typename?: 'CreateAffiliationPayload';
  affiliation?: Maybe<Affiliation>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsAffiliation>;
};

/** Autogenerated return type of CreateAnnouncement. */
export type CreateAnnouncementPayload = {
  __typename?: 'CreateAnnouncementPayload';
  announcement?: Maybe<Announcement>;
  successful: Scalars['Boolean'];
  validations?: Maybe<AnnouncementValidation>;
};

/** Autogenerated return type of CreateCategory. */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category?: Maybe<Category>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of CreateComment. */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of CreateDimension. */
export type CreateDimensionPayload = {
  __typename?: 'CreateDimensionPayload';
  dimension?: Maybe<Dimension>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of CreateLabel. */
export type CreateLabelPayload = {
  __typename?: 'CreateLabelPayload';
  label?: Maybe<Label>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of CreateOauthApplication. */
export type CreateOauthApplicationPayload = {
  __typename?: 'CreateOauthApplicationPayload';
  oauthApplication?: Maybe<OAuthApplication>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsOauthApplication>;
};

/** Autogenerated return type of CreatePermission. */
export type CreatePermissionPayload = {
  __typename?: 'CreatePermissionPayload';
  permission?: Maybe<Permission>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of CreatePool. */
export type CreatePoolPayload = {
  __typename?: 'CreatePoolPayload';
  pool?: Maybe<Pool>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsPool>;
};

/** Autogenerated return type of CreateQuestionGroup. */
export type CreateQuestionGroupPayload = {
  __typename?: 'CreateQuestionGroupPayload';
  questionGroup?: Maybe<QuestionGroup>;
  /** true if validations contain no errors */
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsQuestionGroup>;
};

/** Autogenerated return type of CreateRevisionStatus. */
export type CreateRevisionStatusPayload = {
  __typename?: 'CreateRevisionStatusPayload';
  revisionStatus?: Maybe<RevisionStatus>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsRevisionStatus>;
};

/** Autogenerated return type of CreateSubject. */
export type CreateSubjectPayload = {
  __typename?: 'CreateSubjectPayload';
  subject?: Maybe<Subject>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsSubject>;
};

export type CreateTaskInput = {
  questionGroupTypes: Array<QuestionGroupType>;
  questionTypes: Array<QuestionType>;
};

/** Autogenerated return type of CreateTask. */
export type CreateTaskPayload = {
  __typename?: 'CreateTaskPayload';
  successful: Scalars['Boolean'];
  tasks?: Maybe<Array<Task>>;
  validations?: Maybe<ValidationsTask>;
};

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  successful: Scalars['Boolean'];
  user?: Maybe<User>;
  validations?: Maybe<ValidationsUser>;
};

export type CurrentUserInput = {
  alternativeMobilePhone?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  passwordConfirmation?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteAffiliation. */
export type DeleteAffiliationPayload = {
  __typename?: 'DeleteAffiliationPayload';
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteAnnouncement. */
export type DeleteAnnouncementPayload = {
  __typename?: 'DeleteAnnouncementPayload';
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteCategory. */
export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload';
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteComment. */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  /** true if validations contain no errors */
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteDimension. */
export type DeleteDimensionPayload = {
  __typename?: 'DeleteDimensionPayload';
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteLabel. */
export type DeleteLabelPayload = {
  __typename?: 'DeleteLabelPayload';
  label?: Maybe<Label>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteOauthApplication. */
export type DeleteOauthApplicationPayload = {
  __typename?: 'DeleteOauthApplicationPayload';
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeletePermission. */
export type DeletePermissionPayload = {
  __typename?: 'DeletePermissionPayload';
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeletePool. */
export type DeletePoolPayload = {
  __typename?: 'DeletePoolPayload';
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteQuestionGroups. */
export type DeleteQuestionGroupsPayload = {
  __typename?: 'DeleteQuestionGroupsPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteRevisionStatus. */
export type DeleteRevisionStatusPayload = {
  __typename?: 'DeleteRevisionStatusPayload';
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteStatistics. */
export type DeleteStatisticsPayload = {
  __typename?: 'DeleteStatisticsPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteSubject. */
export type DeleteSubjectPayload = {
  __typename?: 'DeleteSubjectPayload';
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteUser. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  successful: Scalars['Boolean'];
};

export type Dimension = {
  __typename?: 'Dimension';
  categories: Array<Category>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nameDe?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  nameFr?: Maybe<Scalars['String']>;
  nameIt?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  truncated: Scalars['Boolean'];
};

export type DimensionInput = {
  nameDe?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  nameFr?: InputMaybe<Scalars['String']>;
  nameIt?: InputMaybe<Scalars['String']>;
};

export type DownloadUrl = {
  __typename?: 'DownloadUrl';
  url: Scalars['String'];
};

/** Autogenerated return type of DuplicateQuestionGroup. */
export type DuplicateQuestionGroupPayload = {
  __typename?: 'DuplicateQuestionGroupPayload';
  questionGroup?: Maybe<QuestionGroup>;
  successful: Scalars['Boolean'];
};

export type Exam = {
  __typename?: 'Exam';
  bookletA: Booklet;
  bookletB: Booklet;
  content: TranslatedContent;
  date: Scalars['ISO8601Date'];
  de: Scalars['Boolean'];
  dimensions: Array<Dimension>;
  en: Scalars['Boolean'];
  /** Measured Exchange Object */
  exchangeObject: ExamExchangeObject;
  fr: Scalars['Boolean'];
  id: Scalars['ID'];
  imageAssets: Array<Scalars['String']>;
  it: Scalars['Boolean'];
  name: Scalars['String'];
  pool: Pool;
  questionGroups: Array<QuestionGroup>;
  videoAssets: Array<Scalars['String']>;
};

export type ExamExchangeObject = {
  __typename?: 'ExamExchangeObject';
  /** Measured Exchange Object v6 as JSON */
  v6: Scalars['JSON'];
  /** Measured Exchange Object v7 as JSON */
  v7: Scalars['JSON'];
  /** Measured Exchange Object v8 as JSON */
  v8: Scalars['JSON'];
};

export type ExamQuestionGroup = {
  __typename?: 'ExamQuestionGroup';
  complete: Scalars['Boolean'];
  exam: Exam;
  headVersionIsDeleted: Scalars['Boolean'];
  icebreaker: Scalars['Boolean'];
  id: Scalars['Int'];
  isHeadVersion: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  positionA: Scalars['Int'];
  positionB: Scalars['Int'];
  positionsBookletA: Array<Scalars['String']>;
  positionsBookletB: Array<Scalars['String']>;
  questionGroup: QuestionGroup;
  questionGroupHeightInCmDe?: Maybe<Scalars['Float']>;
  questionGroupHeightInCmEn?: Maybe<Scalars['Float']>;
  questionGroupHeightInCmFr?: Maybe<Scalars['Float']>;
  questionGroupHeightInCmIt?: Maybe<Scalars['Float']>;
  questionGroupHeightTypeDe?: Maybe<Scalars['String']>;
  questionGroupHeightTypeEn?: Maybe<Scalars['String']>;
  questionGroupHeightTypeFr?: Maybe<Scalars['String']>;
  questionGroupHeightTypeIt?: Maybe<Scalars['String']>;
};

export enum ExamSortAttribute {
  Date = 'DATE',
  Name = 'NAME'
}

export type ExamSortInput = {
  attribute: ExamSortAttribute;
  direction: SortDirection;
};

export type ExcelStatisticsInput = {
  answerOption1?: InputMaybe<Scalars['Float']>;
  answerOption2?: InputMaybe<Scalars['Float']>;
  answerOption3?: InputMaybe<Scalars['Float']>;
  answerOption4?: InputMaybe<Scalars['Float']>;
  answerOption5?: InputMaybe<Scalars['Float']>;
  eliminated?: InputMaybe<Scalars['Boolean']>;
  index: Scalars['Int'];
  lne?: InputMaybe<Scalars['Float']>;
  n?: InputMaybe<Scalars['Int']>;
  p?: InputMaybe<Scalars['Float']>;
  pDich?: InputMaybe<Scalars['Float']>;
  questionPosition: Scalars['Int'];
  r?: InputMaybe<Scalars['Float']>;
  sequentialNumber: Scalars['ID'];
  xi1?: InputMaybe<Scalars['Float']>;
  xi2?: InputMaybe<Scalars['Float']>;
};

export type ExcelStatisticsValidation = {
  __typename?: 'ExcelStatisticsValidation';
  answerOption1?: Maybe<Array<IndexedValidationError>>;
  answerOption2?: Maybe<Array<IndexedValidationError>>;
  answerOption3?: Maybe<Array<IndexedValidationError>>;
  answerOption4?: Maybe<Array<IndexedValidationError>>;
  answerOption5?: Maybe<Array<IndexedValidationError>>;
  eliminated?: Maybe<Array<IndexedValidationError>>;
  exam?: Maybe<Array<ValidationError>>;
  lne?: Maybe<Array<IndexedValidationError>>;
  n?: Maybe<Array<IndexedValidationError>>;
  p?: Maybe<Array<IndexedValidationError>>;
  questionPosition?: Maybe<Array<IndexedValidationError>>;
  r?: Maybe<Array<IndexedValidationError>>;
  sequentialNumber?: Maybe<Array<IndexedValidationError>>;
  xi1?: Maybe<Array<IndexedValidationError>>;
  xi2?: Maybe<Array<IndexedValidationError>>;
};

export type FilterQueryItem = {
  children?: InputMaybe<Array<FilterQueryItem>>;
  name?: InputMaybe<Scalars['String']>;
  operator: Scalars['String'];
  value?: InputMaybe<Scalars['Any']>;
};

/** Autogenerated return type of ImportExcelStatistics. */
export type ImportExcelStatisticsPayload = {
  __typename?: 'ImportExcelStatisticsPayload';
  exam?: Maybe<Exam>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ExcelStatisticsValidation>;
};

/** Autogenerated return type of ImportStatistics. */
export type ImportStatisticsPayload = {
  __typename?: 'ImportStatisticsPayload';
  successful: Scalars['Boolean'];
};

export type IndexedValidationError = BaseError & {
  __typename?: 'IndexedValidationError';
  code: ValidationCode;
  index: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export type Label = {
  __typename?: 'Label';
  color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  usageCount: Scalars['Int'];
};

export enum Language {
  De = 'de',
  En = 'en',
  Fr = 'fr',
  It = 'it'
}

export type LanguageSpecificUrl = {
  __typename?: 'LanguageSpecificUrl';
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
};

export type ListMetadata = {
  __typename?: 'ListMetadata';
  allFilteredIds: Array<Scalars['ID']>;
  allIds: Array<Scalars['ID']>;
  filteredNumberOfItems: Scalars['Int'];
  from: Scalars['Int'];
  to: Scalars['Int'];
  totalNumberOfItems: Scalars['Int'];
};

export type LongMenuListEntry = {
  __typename?: 'LongMenuListEntry';
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: TranslatedContent;
  position: Scalars['Int'];
};

export type LongMenuListEntryInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: TranslatedContentInput;
  position: Scalars['Int'];
};

export type Message = {
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
};

export enum MoveDirection {
  Down = 'down',
  Up = 'up'
}

export type Mutation = {
  __typename?: 'Mutation';
  assignExams?: Maybe<AssignExamsPayload>;
  assignLabels?: Maybe<AssignLabelsPayload>;
  changeQuestionType?: Maybe<ChangeQuestionPayload>;
  createAffiliation?: Maybe<CreateAffiliationPayload>;
  createAnnouncement?: Maybe<CreateAnnouncementPayload>;
  createCategory?: Maybe<CreateCategoryPayload>;
  createComment?: Maybe<CreateCommentPayload>;
  createDimension?: Maybe<CreateDimensionPayload>;
  createLabel?: Maybe<CreateLabelPayload>;
  createOauthApplication?: Maybe<CreateOauthApplicationPayload>;
  createPermission?: Maybe<CreatePermissionPayload>;
  createPool?: Maybe<CreatePoolPayload>;
  createQuestionGroup?: Maybe<CreateQuestionGroupPayload>;
  createRevisionStatus?: Maybe<CreateRevisionStatusPayload>;
  createSubject?: Maybe<CreateSubjectPayload>;
  createTask?: Maybe<CreateTaskPayload>;
  createUser?: Maybe<CreateUserPayload>;
  deleteAffiliation?: Maybe<DeleteAffiliationPayload>;
  deleteAnnouncement?: Maybe<DeleteAnnouncementPayload>;
  deleteCategory?: Maybe<DeleteCategoryPayload>;
  deleteComment?: Maybe<DeleteCommentPayload>;
  deleteDimension?: Maybe<DeleteDimensionPayload>;
  deleteLabel?: Maybe<DeleteLabelPayload>;
  deleteOauthApplication?: Maybe<DeleteOauthApplicationPayload>;
  deletePermission?: Maybe<DeletePermissionPayload>;
  deletePool?: Maybe<DeletePoolPayload>;
  deleteQuestionGroups?: Maybe<DeleteQuestionGroupsPayload>;
  deleteRevisionStatus?: Maybe<DeleteRevisionStatusPayload>;
  deleteStatistics?: Maybe<DeleteStatisticsPayload>;
  deleteSubject?: Maybe<DeleteSubjectPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  duplicateQuestionGroup?: Maybe<DuplicateQuestionGroupPayload>;
  /** Imports statistics from Excel file and does not overwrite old exam statistics */
  importExcelStatistics?: Maybe<ImportExcelStatisticsPayload>;
  /** Imports statistics from Exam Exchange Object and overwrites old exam statistics */
  importStatistics?: Maybe<ImportStatisticsPayload>;
  setAffiliation?: Maybe<SetAffiliationPayload>;
  setBlueprint?: Maybe<SetBlueprintPayload>;
  setCategoryPosition?: Maybe<SetCategoryPositionPayload>;
  setDimensionPosition?: Maybe<SetDimensionPositionPayload>;
  setPositionAffiliation?: Maybe<SetPositionAffiliationPayload>;
  setPositionRevisionStatus?: Maybe<SetPositionRevisionStatusPayload>;
  setRevisionStatus?: Maybe<SetRevisionStatusPayload>;
  setRevisionYear?: Maybe<SetRevisionYearPayload>;
  setSourceLanguage?: Maybe<SetSourceLanguagePayload>;
  setSupervisor?: Maybe<SetSupervisorPayload>;
  updateAffiliation?: Maybe<UpdateAffiliationPayload>;
  updateAnnouncement?: Maybe<UpdateAnnouncementPayload>;
  updateCategory?: Maybe<UpdateCategoryPayload>;
  updateComment?: Maybe<UpdateCommentPayload>;
  updateCurrentUser?: Maybe<UpdateCurrentUserPayload>;
  updateDimension?: Maybe<UpdateDimensionPayload>;
  updateLabel?: Maybe<UpdateLabelPayload>;
  updateOauthApplication?: Maybe<UpdateOauthApplicationPayload>;
  updatePermission?: Maybe<UpdatePermissionPayload>;
  updatePool?: Maybe<UpdatePoolPayload>;
  updateQuestionGroup?: Maybe<UpdateQuestionGroupPayload>;
  updateRevisionStatus?: Maybe<UpdateRevisionStatusPayload>;
  updateSubject?: Maybe<UpdateSubjectPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
};


export type MutationAssignExamsArgs = {
  examIdsToAdd?: InputMaybe<Array<Scalars['ID']>>;
  examIdsToRemove?: InputMaybe<Array<Scalars['ID']>>;
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
};


export type MutationAssignLabelsArgs = {
  labelIdsToAdd?: InputMaybe<Array<Scalars['ID']>>;
  labelIdsToRemove?: InputMaybe<Array<Scalars['ID']>>;
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
};


export type MutationChangeQuestionTypeArgs = {
  poolId: Scalars['ID'];
  questionId: Scalars['ID'];
  removeResponseOption?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type MutationCreateAffiliationArgs = {
  name: Scalars['String'];
  poolId: Scalars['ID'];
};


export type MutationCreateAnnouncementArgs = {
  attributes: AnnouncementInput;
};


export type MutationCreateCategoryArgs = {
  attributes: CategoryInput;
  dimensionId: Scalars['ID'];
};


export type MutationCreateCommentArgs = {
  content: Scalars['String'];
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
};


export type MutationCreateDimensionArgs = {
  attributes: DimensionInput;
  poolId: Scalars['ID'];
};


export type MutationCreateLabelArgs = {
  color: Scalars['String'];
  name: Scalars['String'];
  poolId: Scalars['ID'];
};


export type MutationCreateOauthApplicationArgs = {
  attributes: OauthApplicationInput;
};


export type MutationCreatePermissionArgs = {
  poolId: Scalars['ID'];
  role: PermissionRole;
  userId: Scalars['ID'];
};


export type MutationCreatePoolArgs = {
  attributes: PoolInput;
};


export type MutationCreateQuestionGroupArgs = {
  attributes: QuestionGroupInput;
  poolId: Scalars['ID'];
};


export type MutationCreateRevisionStatusArgs = {
  attributes: RevisionStatusInput;
  poolId: Scalars['ID'];
};


export type MutationCreateSubjectArgs = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  poolId: Scalars['ID'];
};


export type MutationCreateTaskArgs = {
  attributes: TaskInput;
  poolId: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  attributes: UserInput;
};


export type MutationDeleteAffiliationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAnnouncementArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
  poolId: Scalars['ID'];
};


export type MutationDeleteDimensionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLabelArgs = {
  labelId: Scalars['ID'];
  poolId: Scalars['ID'];
};


export type MutationDeleteOauthApplicationArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePermissionArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePoolArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteQuestionGroupsArgs = {
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
};


export type MutationDeleteRevisionStatusArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStatisticsArgs = {
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
};


export type MutationDeleteSubjectArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicateQuestionGroupArgs = {
  poolId: Scalars['ID'];
  questionGroupId: Scalars['ID'];
  targetPoolId: Scalars['ID'];
};


export type MutationImportExcelStatisticsArgs = {
  examId: Scalars['ID'];
  input: Array<ExcelStatisticsInput>;
  poolId: Scalars['ID'];
};


export type MutationImportStatisticsArgs = {
  examExchangeObject: Scalars['StringifiedJSON'];
  examId: Scalars['ID'];
};


export type MutationSetAffiliationArgs = {
  affiliationId?: InputMaybe<Scalars['ID']>;
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
};


export type MutationSetBlueprintArgs = {
  category0Id?: InputMaybe<Scalars['ID']>;
  category1Id?: InputMaybe<Scalars['ID']>;
  category2Id?: InputMaybe<Scalars['ID']>;
  category3Id?: InputMaybe<Scalars['ID']>;
  category4Id?: InputMaybe<Scalars['ID']>;
  category5Id?: InputMaybe<Scalars['ID']>;
  category6Id?: InputMaybe<Scalars['ID']>;
  category7Id?: InputMaybe<Scalars['ID']>;
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
};


export type MutationSetCategoryPositionArgs = {
  direction: MoveDirection;
  id: Scalars['ID'];
  times?: InputMaybe<Scalars['Int']>;
};


export type MutationSetDimensionPositionArgs = {
  direction: MoveDirection;
  id: Scalars['ID'];
  times?: InputMaybe<Scalars['Int']>;
};


export type MutationSetPositionAffiliationArgs = {
  direction: MoveDirection;
  id: Scalars['ID'];
  times?: InputMaybe<Scalars['Int']>;
};


export type MutationSetPositionRevisionStatusArgs = {
  direction: MoveDirection;
  id: Scalars['ID'];
  times?: InputMaybe<Scalars['Int']>;
};


export type MutationSetRevisionStatusArgs = {
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
  revisionStatusId?: InputMaybe<Scalars['ID']>;
};


export type MutationSetRevisionYearArgs = {
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
  revisionYear?: InputMaybe<Scalars['Int']>;
};


export type MutationSetSourceLanguageArgs = {
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
  sourceLanguage: Language;
};


export type MutationSetSupervisorArgs = {
  poolId: Scalars['ID'];
  questionGroupIds: Array<Scalars['ID']>;
  user?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAffiliationArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateAnnouncementArgs = {
  attributes: AnnouncementInput;
  id: Scalars['ID'];
};


export type MutationUpdateCategoryArgs = {
  attributes: CategoryInput;
  id: Scalars['ID'];
};


export type MutationUpdateCommentArgs = {
  attributes: CommentInput;
  id: Scalars['ID'];
  poolId: Scalars['ID'];
};


export type MutationUpdateCurrentUserArgs = {
  attributes: CurrentUserInput;
};


export type MutationUpdateDimensionArgs = {
  attributes: DimensionInput;
  id: Scalars['ID'];
};


export type MutationUpdateLabelArgs = {
  color: Scalars['String'];
  labelId: Scalars['ID'];
  name: Scalars['String'];
  poolId: Scalars['ID'];
};


export type MutationUpdateOauthApplicationArgs = {
  attributes: OauthApplicationInput;
  id: Scalars['ID'];
};


export type MutationUpdatePermissionArgs = {
  attributes: PermissionInput;
  id: Scalars['ID'];
};


export type MutationUpdatePoolArgs = {
  attributes: PoolInput;
  id: Scalars['ID'];
};


export type MutationUpdateQuestionGroupArgs = {
  attributes: QuestionGroupInput;
  id: Scalars['ID'];
  poolId: Scalars['ID'];
};


export type MutationUpdateRevisionStatusArgs = {
  attributes: RevisionStatusInput;
  id: Scalars['ID'];
};


export type MutationUpdateSubjectArgs = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  attributes: UserInput;
  id: Scalars['ID'];
};

export type OAuthApplication = {
  __typename?: 'OAuthApplication';
  id: Scalars['ID'];
  name: Scalars['String'];
  redirectUri: Scalars['String'];
  secret: Scalars['String'];
  uid: Scalars['ID'];
};

export type OauthApplicationInput = {
  name?: InputMaybe<Scalars['String']>;
  redirectUri?: InputMaybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  pool: Pool;
  role: PermissionRole;
  subject?: Maybe<Subject>;
  user: User;
};

export type PermissionInput = {
  poolId?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<PermissionRole>;
  subjectId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export enum PermissionRole {
  Administrator = 'administrator',
  AdvancedEvaluator = 'advanced_evaluator',
  BasicEvaluator = 'basic_evaluator',
  Coordinator = 'coordinator',
  QuestionAuthor = 'question_author',
  Rater = 'rater'
}

export type Pool = {
  __typename?: 'Pool';
  affiliations: Array<Affiliation>;
  authors: Array<Scalars['String']>;
  deEnabled: Scalars['Boolean'];
  dimensions: Array<Dimension>;
  enEnabled: Scalars['Boolean'];
  exams: Array<Exam>;
  frEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  itEnabled: Scalars['Boolean'];
  labels: Array<Label>;
  languages: Array<Language>;
  name: Scalars['String'];
  permissions: Array<Permission>;
  questionGroup?: Maybe<QuestionGroup>;
  questionGroupList: QuestionGroupList;
  questionGroups: Array<QuestionGroup>;
  questionTypeTransformations?: Maybe<Array<QuestionTypeTransformation>>;
  responsibleEmail: Scalars['String'];
  responsibleFirstName?: Maybe<Scalars['String']>;
  responsibleLanguage?: Maybe<Language>;
  responsibleLastName?: Maybe<Scalars['String']>;
  revisionDocument: DownloadUrl;
  revisionStatus: Array<RevisionStatus>;
  selfAssessment: Scalars['Boolean'];
  subjects?: Maybe<Array<Subject>>;
  supervisors: Array<Scalars['String']>;
  taskQuestionGroups: Array<TaskQuestionGroup>;
  tasks: Array<Task>;
  users: Array<User>;
};


export type PoolExamsArgs = {
  beforeDate?: InputMaybe<Scalars['String']>;
  hasCandidates?: InputMaybe<Scalars['Boolean']>;
  inFuture?: InputMaybe<Scalars['Boolean']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  provisioned?: InputMaybe<Scalars['Boolean']>;
  selfAssessment?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<ExamSortInput>;
};


export type PoolQuestionGroupArgs = {
  id: Scalars['ID'];
};


export type PoolQuestionGroupListArgs = {
  filter?: InputMaybe<FilterQueryItem>;
  from?: InputMaybe<Scalars['Int']>;
  includingIds?: InputMaybe<Array<Scalars['ID']>>;
  minNumberOfItems?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<QuestionGroupSortInput>>;
  to?: InputMaybe<Scalars['Int']>;
};


export type PoolQuestionGroupsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type PoolQuestionTypeTransformationsArgs = {
  questionId: Scalars['ID'];
};


export type PoolRevisionDocumentArgs = {
  displayOptions: RevisionDocumentInput;
};


export type PoolTaskQuestionGroupsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  taskIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type PoolTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  onlyCurrentUser?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<TaskSortInput>>;
  type?: InputMaybe<TaskType>;
};

export type PoolInput = {
  deEnabled?: InputMaybe<Scalars['Boolean']>;
  enEnabled?: InputMaybe<Scalars['Boolean']>;
  frEnabled?: InputMaybe<Scalars['Boolean']>;
  itEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  responsibleEmail: Scalars['String'];
  responsibleFirstName?: InputMaybe<Scalars['String']>;
  responsibleLanguage?: InputMaybe<Language>;
  responsibleLastName?: InputMaybe<Scalars['String']>;
  selfAssessment?: InputMaybe<Scalars['Boolean']>;
};

export enum PreviewGenerationStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

export type Proposition = {
  __typename?: 'Proposition';
  content: TranslatedContent;
  id: Scalars['ID'];
};

export type PropositionInput = {
  content?: InputMaybe<TranslatedContentInput>;
};

export type Query = {
  __typename?: 'Query';
  announcements: Array<Announcement>;
  currentUser?: Maybe<User>;
  exam: Exam;
  oauthApplications: Array<OAuthApplication>;
  pool: Pool;
  pools: Array<Pool>;
  questionTypeTransformations?: Maybe<Array<QuestionTypeTransformation>>;
  revisionDocument: DownloadUrl;
  user: User;
  users: Array<User>;
};


export type QueryExamArgs = {
  id: Scalars['ID'];
};


export type QueryPoolArgs = {
  id: Scalars['ID'];
};


export type QueryPoolsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryQuestionTypeTransformationsArgs = {
  questionId: Scalars['ID'];
};


export type QueryRevisionDocumentArgs = {
  displayOptions: RevisionDocumentInput;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  from?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
};

export type Question = {
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  /** Only set when parent is bookletA */
  fixedNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  learningTarget?: Maybe<Scalars['String']>;
  literaryReference?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  type: QuestionType;
  video: TranslatedVideo;
};

export type QuestionA = Question & {
  __typename?: 'QuestionA';
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  content: TranslatedContent;
  /** Only set when parent is bookletA */
  fixedNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  learningTarget?: Maybe<Scalars['String']>;
  literaryReference?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  responseOptions: Array<ResponseOption>;
  score: Scalars['Float'];
  solution?: Maybe<Scalars['ID']>;
  type: QuestionType;
  video: TranslatedVideo;
};

export type QuestionAInput = {
  content: TranslatedContentInput;
  responseOptions: Array<ResponseOptionInput>;
  /** ID of correct response option */
  solution?: InputMaybe<Scalars['ID']>;
};

export type QuestionAMinus = Question & {
  __typename?: 'QuestionAMinus';
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  content: TranslatedContent;
  /** Only set when parent is bookletA */
  fixedNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  learningTarget?: Maybe<Scalars['String']>;
  literaryReference?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  responseOptions: Array<ResponseOption>;
  score: Scalars['Float'];
  solution?: Maybe<Scalars['ID']>;
  type: QuestionType;
  video: TranslatedVideo;
};

export type QuestionE = Question & {
  __typename?: 'QuestionE';
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  /** Only set when parent is bookletA */
  fixedNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  learningTarget?: Maybe<Scalars['String']>;
  literaryReference?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  solution?: Maybe<QuestionESolution>;
  statementA: TranslatedContent;
  statementB: TranslatedContent;
  type: QuestionType;
  video: TranslatedVideo;
};

export type QuestionEInput = {
  solution?: InputMaybe<QuestionESolution>;
  statementA: TranslatedContentInput;
  statementB: TranslatedContentInput;
};

export enum QuestionESolution {
  AB = 'A_B',
  ABecauseB = 'A_BECAUSE_B',
  ANotB = 'A_NOT_B',
  NotAB = 'NOT_A_B',
  NotANotB = 'NOT_A_NOT_B'
}

export type QuestionFreeText = Question & {
  __typename?: 'QuestionFreeText';
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  content: TranslatedContent;
  /** Only set when parent is bookletA */
  fixedNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  learningTarget?: Maybe<Scalars['String']>;
  literaryReference?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  solution: TranslatedContent;
  suggestedNumberOfLines: Scalars['Int'];
  type: QuestionType;
  video: TranslatedVideo;
};

export type QuestionFreeTextInput = {
  content: TranslatedContentInput;
  solution: TranslatedContentInput;
  suggestedNumberOfLines: Scalars['Int'];
};

export type QuestionGroup = {
  affiliation?: Maybe<Affiliation>;
  author?: Maybe<Scalars['String']>;
  cacheLastUsageEliminated?: Maybe<Scalars['Boolean']>;
  cacheLastUsageLne?: Maybe<Scalars['Float']>;
  cacheLastUsageN?: Maybe<Scalars['Int']>;
  cacheLastUsageP?: Maybe<Scalars['Float']>;
  cacheLastUsageR?: Maybe<Scalars['Float']>;
  cacheLastUsageXi?: Maybe<Scalars['String']>;
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  contentChangedSinceLastUsage: Scalars['Boolean'];
  contentValidation: QuestionGroupContentValidation;
  createdAt: Scalars['ISO8601DateTime'];
  duplicates: Array<QuestionGroup>;
  editor?: Maybe<User>;
  futureExams: Array<Exam>;
  hasActiveTasks: Scalars['Boolean'];
  hasImages: Scalars['Boolean'];
  hasVideos: Scalars['Boolean'];
  id: Scalars['ID'];
  labels: Array<Label>;
  lastUsage?: Maybe<Scalars['ISO8601Date']>;
  latestStatistic?: Maybe<Scalars['Int']>;
  legacyId?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  metadataChangedSinceLastUsage: Scalars['Boolean'];
  numberOfUsages: Scalars['Int'];
  pastExams: Array<Exam>;
  pool: Pool;
  preview: QuestionGroupPreview;
  revisionStatus?: Maybe<RevisionStatus>;
  revisionYear?: Maybe<Scalars['Int']>;
  sequentialNumber: Scalars['Int'];
  sourceLanguage: Language;
  statistics: Array<QuestionGroupStatistics>;
  supervisor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalScore: Scalars['Int'];
  type: QuestionGroupType;
  updatedAt: Scalars['ISO8601DateTime'];
  validations?: Maybe<ValidationsQuestionGroup>;
};

export type QuestionGroupContentValidation = {
  __typename?: 'QuestionGroupContentValidation';
  de: ContentValidationStatus;
  en: ContentValidationStatus;
  fr: ContentValidationStatus;
  it: ContentValidationStatus;
  structurallyComplete: Scalars['Boolean'];
};

export type QuestionGroupInput = {
  affiliationId?: InputMaybe<Scalars['ID']>;
  author?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  revisionYear?: InputMaybe<Scalars['Int']>;
  sourceLanguage?: InputMaybe<Language>;
  supervisor?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<QuestionGroupType>;
};

export type QuestionGroupList = {
  __typename?: 'QuestionGroupList';
  items: Array<QuestionGroup>;
  metadata: ListMetadata;
};

export type QuestionGroupPreview = {
  __typename?: 'QuestionGroupPreview';
  status: PreviewGenerationStatus;
  url?: Maybe<LanguageSpecificUrl>;
};

export enum QuestionGroupSortAttribute {
  Affiliation = 'AFFILIATION',
  Author = 'AUTHOR',
  CacheLastUsageEliminated = 'CACHE_LAST_USAGE_ELIMINATED',
  CacheLastUsageN = 'CACHE_LAST_USAGE_N',
  CacheLastUsageP = 'CACHE_LAST_USAGE_P',
  CacheLastUsageR = 'CACHE_LAST_USAGE_R',
  Category_0 = 'CATEGORY_0',
  Category_1 = 'CATEGORY_1',
  Category_2 = 'CATEGORY_2',
  Category_3 = 'CATEGORY_3',
  Category_4 = 'CATEGORY_4',
  Category_5 = 'CATEGORY_5',
  Category_6 = 'CATEGORY_6',
  Category_7 = 'CATEGORY_7',
  ContentValidationStateDe = 'CONTENT_VALIDATION_STATE_DE',
  ContentValidationStateEn = 'CONTENT_VALIDATION_STATE_EN',
  ContentValidationStateFr = 'CONTENT_VALIDATION_STATE_FR',
  ContentValidationStateIt = 'CONTENT_VALIDATION_STATE_IT',
  CreatedAt = 'CREATED_AT',
  Duplicates = 'DUPLICATES',
  Editor = 'EDITOR',
  HasImages = 'HAS_IMAGES',
  HasVideos = 'HAS_VIDEOS',
  LastUsage = 'LAST_USAGE',
  LatestStatistic = 'LATEST_STATISTIC',
  LegacyId = 'LEGACY_ID',
  LiteraryReference = 'LITERARY_REFERENCE',
  NumberOfUsages = 'NUMBER_OF_USAGES',
  QuestionTypes = 'QUESTION_TYPES',
  RevisionYear = 'REVISION_YEAR',
  Score = 'SCORE',
  SequentialNumber = 'SEQUENTIAL_NUMBER',
  SourceLanguage = 'SOURCE_LANGUAGE',
  Supervisor = 'SUPERVISOR',
  Title = 'TITLE',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT'
}

export type QuestionGroupSortInput = {
  attribute: QuestionGroupSortAttribute;
  direction: SortDirection;
};

export type QuestionGroupStatistics = {
  __typename?: 'QuestionGroupStatistics';
  exam: Exam;
  questionGroup: QuestionGroup;
  questions: Array<QuestionStatistics>;
};

export enum QuestionGroupType {
  Sequence = 'SEQUENCE',
  Series = 'SERIES',
  Single = 'SINGLE'
}

export type QuestionInput = {
  a?: InputMaybe<QuestionAInput>;
  aMinus?: InputMaybe<QuestionAInput>;
  category0Id?: InputMaybe<Scalars['ID']>;
  category1Id?: InputMaybe<Scalars['ID']>;
  category2Id?: InputMaybe<Scalars['ID']>;
  category3Id?: InputMaybe<Scalars['ID']>;
  category4Id?: InputMaybe<Scalars['ID']>;
  category5Id?: InputMaybe<Scalars['ID']>;
  category6Id?: InputMaybe<Scalars['ID']>;
  category7Id?: InputMaybe<Scalars['ID']>;
  e?: InputMaybe<QuestionEInput>;
  freeText?: InputMaybe<QuestionFreeTextInput>;
  id?: InputMaybe<Scalars['ID']>;
  kPrime?: InputMaybe<QuestionKPrimeInput>;
  learningTarget?: InputMaybe<Scalars['String']>;
  literaryReference?: InputMaybe<Scalars['String']>;
  longMenu?: InputMaybe<QuestionLongMenuInput>;
  remarks?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<QuestionType>;
  video?: InputMaybe<TranslatedVideoInput>;
};

export type QuestionKPrimeInput = {
  content: TranslatedContentInput;
  propositions: Array<PropositionInput>;
  /** corresponds to propositions */
  solution: Array<InputMaybe<Scalars['Boolean']>>;
};

export type QuestionKprime = Question & {
  __typename?: 'QuestionKprime';
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  content: TranslatedContent;
  /** Only set when parent is bookletA */
  fixedNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  learningTarget?: Maybe<Scalars['String']>;
  literaryReference?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  propositions: Array<Proposition>;
  remarks?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  solution: Array<Maybe<Scalars['Boolean']>>;
  type: QuestionType;
  video: TranslatedVideo;
};

export type QuestionLongMenu = Question & {
  __typename?: 'QuestionLongMenu';
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  content: TranslatedContent;
  /** Only set when parent is bookletA */
  fixedNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  learningTarget?: Maybe<Scalars['String']>;
  list?: Maybe<Array<LongMenuListEntry>>;
  literaryReference?: Maybe<Scalars['String']>;
  numberOfResponses?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  solution?: Maybe<Array<Array<Maybe<LongMenuListEntry>>>>;
  type: QuestionType;
  video: TranslatedVideo;
};

export type QuestionLongMenuInput = {
  content: TranslatedContentInput;
  list?: InputMaybe<Array<LongMenuListEntryInput>>;
  numberOfResponses: Scalars['Int'];
  solution?: InputMaybe<Array<Array<InputMaybe<Scalars['Int']>>>>;
};

export type QuestionSequence = QuestionGroup & {
  __typename?: 'QuestionSequence';
  affiliation?: Maybe<Affiliation>;
  author?: Maybe<Scalars['String']>;
  cacheLastUsageEliminated?: Maybe<Scalars['Boolean']>;
  cacheLastUsageLne?: Maybe<Scalars['Float']>;
  cacheLastUsageN?: Maybe<Scalars['Int']>;
  cacheLastUsageP?: Maybe<Scalars['Float']>;
  cacheLastUsageR?: Maybe<Scalars['Float']>;
  cacheLastUsageXi?: Maybe<Scalars['String']>;
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  contentChangedSinceLastUsage: Scalars['Boolean'];
  contentValidation: QuestionGroupContentValidation;
  createdAt: Scalars['ISO8601DateTime'];
  duplicates: Array<QuestionGroup>;
  editor?: Maybe<User>;
  futureExams: Array<Exam>;
  hasActiveTasks: Scalars['Boolean'];
  hasImages: Scalars['Boolean'];
  hasVideos: Scalars['Boolean'];
  id: Scalars['ID'];
  labels: Array<Label>;
  lastUsage?: Maybe<Scalars['ISO8601Date']>;
  latestStatistic?: Maybe<Scalars['Int']>;
  legacyId?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  metadataChangedSinceLastUsage: Scalars['Boolean'];
  numberOfUsages: Scalars['Int'];
  pastExams: Array<Exam>;
  pool: Pool;
  preview: QuestionGroupPreview;
  questions?: Maybe<Array<Question>>;
  revisionStatus?: Maybe<RevisionStatus>;
  revisionYear?: Maybe<Scalars['Int']>;
  sequentialNumber: Scalars['Int'];
  sourceLanguage: Language;
  statistics: Array<QuestionGroupStatistics>;
  supervisor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalScore: Scalars['Int'];
  type: QuestionGroupType;
  updatedAt: Scalars['ISO8601DateTime'];
  validations?: Maybe<ValidationsQuestionGroup>;
};

export type QuestionSeries = QuestionGroup & {
  __typename?: 'QuestionSeries';
  affiliation?: Maybe<Affiliation>;
  author?: Maybe<Scalars['String']>;
  cacheLastUsageEliminated?: Maybe<Scalars['Boolean']>;
  cacheLastUsageLne?: Maybe<Scalars['Float']>;
  cacheLastUsageN?: Maybe<Scalars['Int']>;
  cacheLastUsageP?: Maybe<Scalars['Float']>;
  cacheLastUsageR?: Maybe<Scalars['Float']>;
  cacheLastUsageXi?: Maybe<Scalars['String']>;
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  contentChangedSinceLastUsage: Scalars['Boolean'];
  contentValidation: QuestionGroupContentValidation;
  createdAt: Scalars['ISO8601DateTime'];
  duplicates: Array<QuestionGroup>;
  editor?: Maybe<User>;
  futureExams: Array<Exam>;
  hasActiveTasks: Scalars['Boolean'];
  hasImages: Scalars['Boolean'];
  hasVideos: Scalars['Boolean'];
  id: Scalars['ID'];
  labels: Array<Label>;
  lastUsage?: Maybe<Scalars['ISO8601Date']>;
  latestStatistic?: Maybe<Scalars['Int']>;
  legacyId?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  metadataChangedSinceLastUsage: Scalars['Boolean'];
  numberOfUsages: Scalars['Int'];
  pastExams: Array<Exam>;
  pool: Pool;
  preview: QuestionGroupPreview;
  questions?: Maybe<Array<Question>>;
  revisionStatus?: Maybe<RevisionStatus>;
  revisionYear?: Maybe<Scalars['Int']>;
  sequentialNumber: Scalars['Int'];
  sourceLanguage: Language;
  statistics: Array<QuestionGroupStatistics>;
  supervisor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalScore: Scalars['Int'];
  type: QuestionGroupType;
  updatedAt: Scalars['ISO8601DateTime'];
  validations?: Maybe<ValidationsQuestionGroup>;
};

export type QuestionStatistics = {
  __typename?: 'QuestionStatistics';
  question: Question;
  subgroups: Array<SubgroupStatistic>;
  total: TotalStatistic;
};

export enum QuestionType {
  A = 'a',
  AMinus = 'aMinus',
  E = 'e',
  FreeText = 'freeText',
  KPrime = 'kPrime',
  LongMenu = 'longMenu'
}

export type QuestionTypeTransformation = {
  __typename?: 'QuestionTypeTransformation';
  deleteSolution: Scalars['Boolean'];
  necessaryOperations: Array<Scalars['String']>;
  type: Scalars['String'];
};

export type ResponseOption = {
  __typename?: 'ResponseOption';
  content: TranslatedContent;
  id: Scalars['ID'];
};

export type ResponseOptionInput = {
  content?: InputMaybe<TranslatedContentInput>;
  id: Scalars['ID'];
};

export type ResponseStatistic = {
  __typename?: 'ResponseStatistic';
  dM?: Maybe<Scalars['Float']>;
  p: Scalars['Float'];
};

export type ResponseStatisticWithSolution = {
  __typename?: 'ResponseStatisticWithSolution';
  dM?: Maybe<Scalars['Float']>;
  p: Scalars['Float'];
  solution?: Maybe<Scalars['Boolean']>;
};

export type ReviseTaskInput = {
  languageOption: TaskLanguagesOption;
};

export type RevisionDocumentInput = {
  blueprint: Scalars['Boolean'];
  comments: Scalars['Boolean'];
  includePageBreaks: Scalars['Boolean'];
  languages: Array<Scalars['String']>;
  orderBy: Array<Scalars['String']>;
  pageBreakBetweenQuestionGroups: Scalars['Boolean'];
  preview: Scalars['Boolean'];
  questionGroupAffiliation: Scalars['Boolean'];
  questionGroupAuthor: Scalars['Boolean'];
  questionGroupCreatedAt: Scalars['Boolean'];
  questionGroupIds: Array<Scalars['ID']>;
  questionGroupLastUsageYear: Scalars['Boolean'];
  questionGroupNumber: Scalars['Boolean'];
  questionGroupNumberOfUsages: Scalars['Boolean'];
  questionGroupRevisionStatus: Scalars['Boolean'];
  questionGroupRevisionYear: Scalars['Boolean'];
  questionGroupScore: Scalars['Boolean'];
  questionGroupSourceLanguage: Scalars['Boolean'];
  questionGroupSupervisor: Scalars['Boolean'];
  questionGroupType: Scalars['Boolean'];
  questionGroupUpdatedAt: Scalars['Boolean'];
  questionLearningTarget: Scalars['Boolean'];
  questionReference: Scalars['Boolean'];
  questionRemarks: Scalars['Boolean'];
  solution: Scalars['Boolean'];
  statistics: Scalars['Boolean'];
  statisticsAmount: Scalars['Int'];
  text: Scalars['Boolean'];
  title: Scalars['Boolean'];
};

export type RevisionStatus = {
  __typename?: 'RevisionStatus';
  description: Scalars['String'];
  id: Scalars['ID'];
  position: Scalars['Int'];
  shortName: Scalars['String'];
};

export type RevisionStatusInput = {
  description?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SetAffiliation. */
export type SetAffiliationPayload = {
  __typename?: 'SetAffiliationPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of SetBlueprint. */
export type SetBlueprintPayload = {
  __typename?: 'SetBlueprintPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of SetCategoryPosition. */
export type SetCategoryPositionPayload = {
  __typename?: 'SetCategoryPositionPayload';
  categories?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of SetDimensionPosition. */
export type SetDimensionPositionPayload = {
  __typename?: 'SetDimensionPositionPayload';
  dimension?: Maybe<Dimension>;
  dimensions?: Maybe<Array<Dimension>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of SetPositionAffiliation. */
export type SetPositionAffiliationPayload = {
  __typename?: 'SetPositionAffiliationPayload';
  affiliation?: Maybe<Affiliation>;
  affiliations?: Maybe<Array<Affiliation>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of SetPositionRevisionStatus. */
export type SetPositionRevisionStatusPayload = {
  __typename?: 'SetPositionRevisionStatusPayload';
  revisionStatus?: Maybe<RevisionStatus>;
  revisionStatusList?: Maybe<Array<RevisionStatus>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of SetRevisionStatus. */
export type SetRevisionStatusPayload = {
  __typename?: 'SetRevisionStatusPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of SetRevisionYear. */
export type SetRevisionYearPayload = {
  __typename?: 'SetRevisionYearPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of SetSourceLanguage. */
export type SetSourceLanguagePayload = {
  __typename?: 'SetSourceLanguagePayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of SetSupervisor. */
export type SetSupervisorPayload = {
  __typename?: 'SetSupervisorPayload';
  questionGroups?: Maybe<Array<QuestionGroup>>;
  successful: Scalars['Boolean'];
};

export type SingleQuestion = QuestionGroup & {
  __typename?: 'SingleQuestion';
  affiliation?: Maybe<Affiliation>;
  author?: Maybe<Scalars['String']>;
  cacheLastUsageEliminated?: Maybe<Scalars['Boolean']>;
  cacheLastUsageLne?: Maybe<Scalars['Float']>;
  cacheLastUsageN?: Maybe<Scalars['Int']>;
  cacheLastUsageP?: Maybe<Scalars['Float']>;
  cacheLastUsageR?: Maybe<Scalars['Float']>;
  cacheLastUsageXi?: Maybe<Scalars['String']>;
  category0?: Maybe<Category>;
  category1?: Maybe<Category>;
  category2?: Maybe<Category>;
  category3?: Maybe<Category>;
  category4?: Maybe<Category>;
  category5?: Maybe<Category>;
  category6?: Maybe<Category>;
  category7?: Maybe<Category>;
  contentChangedSinceLastUsage: Scalars['Boolean'];
  contentValidation: QuestionGroupContentValidation;
  createdAt: Scalars['ISO8601DateTime'];
  duplicates: Array<QuestionGroup>;
  editor?: Maybe<User>;
  futureExams: Array<Exam>;
  hasActiveTasks: Scalars['Boolean'];
  hasImages: Scalars['Boolean'];
  hasVideos: Scalars['Boolean'];
  id: Scalars['ID'];
  labels: Array<Label>;
  lastUsage?: Maybe<Scalars['ISO8601Date']>;
  latestStatistic?: Maybe<Scalars['Int']>;
  legacyId?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  metadataChangedSinceLastUsage: Scalars['Boolean'];
  numberOfUsages: Scalars['Int'];
  pastExams: Array<Exam>;
  pool: Pool;
  preview: QuestionGroupPreview;
  question?: Maybe<Question>;
  revisionStatus?: Maybe<RevisionStatus>;
  revisionYear?: Maybe<Scalars['Int']>;
  sequentialNumber: Scalars['Int'];
  sourceLanguage: Language;
  statistics: Array<QuestionGroupStatistics>;
  supervisor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalScore: Scalars['Int'];
  type: QuestionGroupType;
  updatedAt: Scalars['ISO8601DateTime'];
  validations?: Maybe<ValidationsQuestionGroup>;
};

export enum SortDirection {
  /** Sort attribute by ascending order */
  Asc = 'ASC',
  /** Sort attribute by descending order */
  Desc = 'DESC'
}

export type SubgroupStatistic = {
  eliminated: Scalars['Boolean'];
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type SubgroupStatisticTypeA = SubgroupStatistic & {
  __typename?: 'SubgroupStatisticTypeA';
  A?: Maybe<ResponseStatisticWithSolution>;
  B?: Maybe<ResponseStatisticWithSolution>;
  C?: Maybe<ResponseStatisticWithSolution>;
  D?: Maybe<ResponseStatisticWithSolution>;
  E?: Maybe<ResponseStatisticWithSolution>;
  eliminated: Scalars['Boolean'];
  empty?: Maybe<ResponseStatistic>;
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type SubgroupStatisticTypeAMinus = SubgroupStatistic & {
  __typename?: 'SubgroupStatisticTypeAMinus';
  A?: Maybe<ResponseStatisticWithSolution>;
  B?: Maybe<ResponseStatisticWithSolution>;
  C?: Maybe<ResponseStatisticWithSolution>;
  D?: Maybe<ResponseStatisticWithSolution>;
  E?: Maybe<ResponseStatisticWithSolution>;
  eliminated: Scalars['Boolean'];
  empty?: Maybe<ResponseStatistic>;
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type SubgroupStatisticTypeE = SubgroupStatistic & {
  __typename?: 'SubgroupStatisticTypeE';
  aB?: Maybe<ResponseStatisticWithSolution>;
  aBecauseB?: Maybe<ResponseStatisticWithSolution>;
  aNotB?: Maybe<ResponseStatisticWithSolution>;
  eliminated: Scalars['Boolean'];
  empty?: Maybe<ResponseStatistic>;
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  notAB?: Maybe<ResponseStatisticWithSolution>;
  notANotB?: Maybe<ResponseStatisticWithSolution>;
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type SubgroupStatisticTypeFreeText = SubgroupStatistic & {
  __typename?: 'SubgroupStatisticTypeFreeText';
  achievedPoints: Array<AchievedPointsStatistic>;
  eliminated: Scalars['Boolean'];
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type SubgroupStatisticTypeKprime = SubgroupStatistic & {
  __typename?: 'SubgroupStatisticTypeKprime';
  A?: Maybe<ResponseStatisticWithSolution>;
  B?: Maybe<ResponseStatisticWithSolution>;
  C?: Maybe<ResponseStatisticWithSolution>;
  D?: Maybe<ResponseStatisticWithSolution>;
  eliminated: Scalars['Boolean'];
  empty?: Maybe<ResponseStatistic>;
  fullPoints?: Maybe<ResponseStatistic>;
  halfPoints?: Maybe<ResponseStatistic>;
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noPoints?: Maybe<ResponseStatistic>;
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type SubgroupStatisticTypeLongMenu = SubgroupStatistic & {
  __typename?: 'SubgroupStatisticTypeLongMenu';
  eliminated: Scalars['Boolean'];
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type Subject = {
  __typename?: 'Subject';
  categories: Array<Category>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SystemMessageQuestionGroupUpdated = Message & {
  __typename?: 'SystemMessageQuestionGroupUpdated';
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
};

export type SystemMessageQuestionTypeTransformed = Message & {
  __typename?: 'SystemMessageQuestionTypeTransformed';
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  questionNumber: Scalars['Int'];
};

export type SystemMessageStatisticsDeleted = Message & {
  __typename?: 'SystemMessageStatisticsDeleted';
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
};

export type Task = {
  __typename?: 'Task';
  createdAt: Scalars['ISO8601DateTime'];
  creator: User;
  emailBody: Scalars['String'];
  emailSubject: Scalars['String'];
  endsAt: Scalars['ISO8601DateTime'];
  finishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['Int'];
  receiver: User;
  softDeleted: Scalars['Boolean'];
  startsAt: Scalars['ISO8601DateTime'];
  status: TaskStatus;
  type: TaskType;
};

export type TaskInput = {
  create?: InputMaybe<CreateTaskInput>;
  deadline: Scalars['ISO8601Date'];
  emailBody: Scalars['String'];
  emailSubject: Scalars['String'];
  questionGroupIds: Array<Scalars['ID']>;
  receiverIds: Array<Scalars['ID']>;
  revise?: InputMaybe<ReviseTaskInput>;
  translate?: InputMaybe<TranslateTaskInput>;
  type: TaskType;
};

export enum TaskLanguagesOption {
  All = 'ALL',
  Source = 'SOURCE'
}

export type TaskQuestionGroup = {
  __typename?: 'TaskQuestionGroup';
  changedByAuthor: Scalars['Boolean'];
  hasComment: Scalars['Boolean'];
  id: Scalars['Int'];
  initialQuestionGroupVersion: QuestionGroup;
  questionGroup: QuestionGroup;
  task: Task;
};

export enum TaskSortAttribute {
  CreatedAt = 'CREATED_AT',
  Creator = 'CREATOR',
  EndsAt = 'ENDS_AT',
  FinishedAt = 'FINISHED_AT',
  Id = 'ID',
  Receiver = 'RECEIVER',
  StartsAt = 'STARTS_AT',
  Type = 'TYPE'
}

export type TaskSortInput = {
  attribute: TaskSortAttribute;
  direction: SortDirection;
};

export enum TaskStatus {
  Finished = 'FINISHED',
  Given = 'GIVEN',
  InProgress = 'IN_PROGRESS'
}

export enum TaskType {
  Comment = 'COMMENT',
  Create = 'CREATE',
  Revise = 'REVISE',
  Translate = 'TRANSLATE'
}

export type TotalStatistic = {
  eliminated: Scalars['Boolean'];
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type TotalStatisticTypeA = TotalStatistic & {
  __typename?: 'TotalStatisticTypeA';
  A?: Maybe<ResponseStatisticWithSolution>;
  B?: Maybe<ResponseStatisticWithSolution>;
  C?: Maybe<ResponseStatisticWithSolution>;
  D?: Maybe<ResponseStatisticWithSolution>;
  E?: Maybe<ResponseStatisticWithSolution>;
  eliminated: Scalars['Boolean'];
  empty?: Maybe<ResponseStatistic>;
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type TotalStatisticTypeAMinus = TotalStatistic & {
  __typename?: 'TotalStatisticTypeAMinus';
  A?: Maybe<ResponseStatisticWithSolution>;
  B?: Maybe<ResponseStatisticWithSolution>;
  C?: Maybe<ResponseStatisticWithSolution>;
  D?: Maybe<ResponseStatisticWithSolution>;
  E?: Maybe<ResponseStatisticWithSolution>;
  eliminated: Scalars['Boolean'];
  empty?: Maybe<ResponseStatistic>;
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type TotalStatisticTypeE = TotalStatistic & {
  __typename?: 'TotalStatisticTypeE';
  aB?: Maybe<ResponseStatisticWithSolution>;
  aBecauseB?: Maybe<ResponseStatisticWithSolution>;
  aNotB?: Maybe<ResponseStatisticWithSolution>;
  eliminated: Scalars['Boolean'];
  empty?: Maybe<ResponseStatistic>;
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  notAB?: Maybe<ResponseStatisticWithSolution>;
  notANotB?: Maybe<ResponseStatisticWithSolution>;
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type TotalStatisticTypeFreeText = TotalStatistic & {
  __typename?: 'TotalStatisticTypeFreeText';
  achievedPoints: Array<AchievedPointsStatistic>;
  eliminated: Scalars['Boolean'];
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type TotalStatisticTypeKprime = TotalStatistic & {
  __typename?: 'TotalStatisticTypeKprime';
  A?: Maybe<ResponseStatisticWithSolution>;
  B?: Maybe<ResponseStatisticWithSolution>;
  C?: Maybe<ResponseStatisticWithSolution>;
  D?: Maybe<ResponseStatisticWithSolution>;
  eliminated: Scalars['Boolean'];
  empty?: Maybe<ResponseStatistic>;
  fullPoints?: Maybe<ResponseStatistic>;
  halfPoints?: Maybe<ResponseStatistic>;
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  noPoints?: Maybe<ResponseStatistic>;
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type TotalStatisticTypeLongMenu = TotalStatistic & {
  __typename?: 'TotalStatisticTypeLongMenu';
  eliminated: Scalars['Boolean'];
  lne?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  p?: Maybe<Scalars['Float']>;
  pDichotomous?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
  xi1?: Maybe<Scalars['Float']>;
  xi2?: Maybe<Scalars['Float']>;
};

export type TranslateTaskInput = {
  targetLanguage: Language;
};

export type TranslatedContent = {
  __typename?: 'TranslatedContent';
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
};

export type TranslatedContentInput = {
  de?: InputMaybe<Scalars['String']>;
  en?: InputMaybe<Scalars['String']>;
  fr?: InputMaybe<Scalars['String']>;
  it?: InputMaybe<Scalars['String']>;
};

export type TranslatedVideo = {
  __typename?: 'TranslatedVideo';
  de?: Maybe<VideoAsset>;
  en?: Maybe<VideoAsset>;
  fr?: Maybe<VideoAsset>;
  it?: Maybe<VideoAsset>;
};

export type TranslatedVideoInput = {
  de?: InputMaybe<VideoAssetInput>;
  en?: InputMaybe<VideoAssetInput>;
  fr?: InputMaybe<VideoAssetInput>;
  it?: InputMaybe<VideoAssetInput>;
};

/** Autogenerated return type of UpdateAffiliation. */
export type UpdateAffiliationPayload = {
  __typename?: 'UpdateAffiliationPayload';
  affiliation?: Maybe<Affiliation>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsAffiliation>;
};

/** Autogenerated return type of UpdateAnnouncement. */
export type UpdateAnnouncementPayload = {
  __typename?: 'UpdateAnnouncementPayload';
  announcement?: Maybe<Announcement>;
  successful: Scalars['Boolean'];
  validations?: Maybe<AnnouncementValidation>;
};

/** Autogenerated return type of UpdateCategory. */
export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  category?: Maybe<Category>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateComment. */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  comment?: Maybe<Comment>;
  /** true if validations contain no errors */
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateCurrentUser. */
export type UpdateCurrentUserPayload = {
  __typename?: 'UpdateCurrentUserPayload';
  successful: Scalars['Boolean'];
  user?: Maybe<User>;
  validations?: Maybe<ValidationsCurrentUser>;
};

/** Autogenerated return type of UpdateDimension. */
export type UpdateDimensionPayload = {
  __typename?: 'UpdateDimensionPayload';
  dimension?: Maybe<Dimension>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateLabel. */
export type UpdateLabelPayload = {
  __typename?: 'UpdateLabelPayload';
  label?: Maybe<Label>;
  successful: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateOauthApplication. */
export type UpdateOauthApplicationPayload = {
  __typename?: 'UpdateOauthApplicationPayload';
  oauthApplication?: Maybe<OAuthApplication>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsOauthApplication>;
};

/** Autogenerated return type of UpdatePermission. */
export type UpdatePermissionPayload = {
  __typename?: 'UpdatePermissionPayload';
  permission?: Maybe<Permission>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsPermission>;
};

/** Autogenerated return type of UpdatePool. */
export type UpdatePoolPayload = {
  __typename?: 'UpdatePoolPayload';
  pool?: Maybe<Pool>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsPool>;
};

/** Autogenerated return type of UpdateQuestionGroup. */
export type UpdateQuestionGroupPayload = {
  __typename?: 'UpdateQuestionGroupPayload';
  questionGroup?: Maybe<QuestionGroup>;
  /** true if validations contain no errors */
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsQuestionGroup>;
};

/** Autogenerated return type of UpdateRevisionStatus. */
export type UpdateRevisionStatusPayload = {
  __typename?: 'UpdateRevisionStatusPayload';
  revisionStatus?: Maybe<RevisionStatus>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsRevisionStatus>;
};

/** Autogenerated return type of UpdateSubject. */
export type UpdateSubjectPayload = {
  __typename?: 'UpdateSubjectPayload';
  subject?: Maybe<Subject>;
  successful: Scalars['Boolean'];
  validations?: Maybe<ValidationsSubject>;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  successful: Scalars['Boolean'];
  user?: Maybe<User>;
  validations?: Maybe<ValidationsUser>;
};

export type User = {
  __typename?: 'User';
  alternativeEmail?: Maybe<Scalars['String']>;
  alternativeMobilePhone?: Maybe<Scalars['String']>;
  currentSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  language: Language;
  lastName: Scalars['String'];
  mobilePhone?: Maybe<Scalars['String']>;
  organisation?: Maybe<Scalars['String']>;
  permissions: Array<Permission>;
  /** Pools accessible to user */
  pools: Array<Pool>;
  superUser: Scalars['Boolean'];
  useEmailForMtan: Scalars['Boolean'];
};

export type UserInput = {
  alternativeEmail?: InputMaybe<Scalars['String']>;
  alternativeMobilePhone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  passwordConfirmation?: InputMaybe<Scalars['String']>;
  superUser?: InputMaybe<Scalars['Boolean']>;
  useEmailForMtan?: InputMaybe<Scalars['Boolean']>;
};

export enum ValidationCode {
  /** Field cannot be blank */
  BasicBlank = 'BASIC__BLANK',
  /** Field must be equal to a specific value */
  BasicEqualTo = 'BASIC__EQUAL_TO',
  /** Field must be an even number */
  BasicEven = 'BASIC__EVEN',
  /** Field must be greater than a specific value */
  BasicGreaterThan = 'BASIC__GREATER_THAN',
  /** Field must be greater than or equal to a specific value */
  BasicGreaterThanOrEqualTo = 'BASIC__GREATER_THAN_OR_EQUAL_TO',
  /** Field must be included in a given set */
  BasicInclusion = 'BASIC__INCLUSION',
  /** Field is invalid */
  BasicInvalid = 'BASIC__INVALID',
  /** Field must be less than a specific value */
  BasicLessThan = 'BASIC__LESS_THAN',
  /** Field must be less than or equal to a specific value */
  BasicLessThanOrEqualTo = 'BASIC__LESS_THAN_OR_EQUAL_TO',
  /** Field must be an integer */
  BasicNotAnInteger = 'BASIC__NOT_AN_INTEGER',
  /** Field must be an odd number */
  BasicOdd = 'BASIC__ODD',
  /** Field must be unique */
  BasicTaken = 'BASIC__TAKEN',
  /** Field is too long */
  BasicTooLong = 'BASIC__TOO_LONG',
  /** Field has the wrong length */
  BasicWrongLength = 'BASIC__WRONG_LENGTH',
  StatisticsImportAllItemsMustBelongToExam = 'STATISTICS_IMPORT__ALL_ITEMS_MUST_BELONG_TO_EXAM',
  StatisticsImportDuplicateQuestionPosition = 'STATISTICS_IMPORT__DUPLICATE_QUESTION_POSITION',
  StatisticsImportExamMustBeInPast = 'STATISTICS_IMPORT__EXAM_MUST_BE_IN_PAST',
  StatisticsImportMustBeInRange = 'STATISTICS_IMPORT__MUST_BE_IN_RANGE',
  StatisticsImportMustBePosInt = 'STATISTICS_IMPORT__MUST_BE_POS_INT',
  StatisticsImportQuestionPositionNotFound = 'STATISTICS_IMPORT__QUESTION_POSITION_NOT_FOUND'
}

export type ValidationError = BaseError & {
  __typename?: 'ValidationError';
  code: ValidationCode;
  value?: Maybe<Scalars['String']>;
};

export type ValidationsAffiliation = {
  __typename?: 'ValidationsAffiliation';
  name?: Maybe<Array<ValidationsMessage>>;
};

export type ValidationsCurrentUser = {
  __typename?: 'ValidationsCurrentUser';
  firstName?: Maybe<Array<ValidationsMessage>>;
  language?: Maybe<Array<ValidationsMessage>>;
  lastName?: Maybe<Array<ValidationsMessage>>;
  password?: Maybe<Array<ValidationsMessage>>;
  passwordConfirmation?: Maybe<Array<ValidationsMessage>>;
};

export type ValidationsMessage = {
  __typename?: 'ValidationsMessage';
  message: Scalars['String'];
  severity: ValidationsSeverity;
};

export type ValidationsOauthApplication = {
  __typename?: 'ValidationsOauthApplication';
  name?: Maybe<Array<ValidationsMessage>>;
  redirectUri?: Maybe<Array<ValidationsMessage>>;
};

export type ValidationsPermission = {
  __typename?: 'ValidationsPermission';
  subject?: Maybe<Array<ValidationsMessage>>;
};

export type ValidationsPool = {
  __typename?: 'ValidationsPool';
  deEnabled?: Maybe<Array<ValidationsMessage>>;
  enEnabled?: Maybe<Array<ValidationsMessage>>;
  frEnabled?: Maybe<Array<ValidationsMessage>>;
  itEnabled?: Maybe<Array<ValidationsMessage>>;
  name?: Maybe<Array<ValidationsMessage>>;
  responsibleEmail?: Maybe<Array<ValidationsMessage>>;
  selfAssessment?: Maybe<Array<ValidationsMessage>>;
};

export type ValidationsProposition = {
  __typename?: 'ValidationsProposition';
  content: ValidationsTranslatedContent;
};

export type ValidationsQuestion = {
  category0: Array<ValidationsMessage>;
  category1: Array<ValidationsMessage>;
  category2: Array<ValidationsMessage>;
  category3: Array<ValidationsMessage>;
  category4: Array<ValidationsMessage>;
  category5: Array<ValidationsMessage>;
  category6: Array<ValidationsMessage>;
  category7: Array<ValidationsMessage>;
  score: Array<ValidationsMessage>;
};

export type ValidationsQuestionA = ValidationsQuestion & {
  __typename?: 'ValidationsQuestionA';
  category0: Array<ValidationsMessage>;
  category1: Array<ValidationsMessage>;
  category2: Array<ValidationsMessage>;
  category3: Array<ValidationsMessage>;
  category4: Array<ValidationsMessage>;
  category5: Array<ValidationsMessage>;
  category6: Array<ValidationsMessage>;
  category7: Array<ValidationsMessage>;
  content: ValidationsTranslatedContent;
  responseOptions: Array<ValidationsResponseOption>;
  score: Array<ValidationsMessage>;
  solution: Array<ValidationsMessage>;
};

export type ValidationsQuestionAMinus = ValidationsQuestion & {
  __typename?: 'ValidationsQuestionAMinus';
  category0: Array<ValidationsMessage>;
  category1: Array<ValidationsMessage>;
  category2: Array<ValidationsMessage>;
  category3: Array<ValidationsMessage>;
  category4: Array<ValidationsMessage>;
  category5: Array<ValidationsMessage>;
  category6: Array<ValidationsMessage>;
  category7: Array<ValidationsMessage>;
  content: ValidationsTranslatedContent;
  responseOptions: Array<ValidationsResponseOption>;
  score: Array<ValidationsMessage>;
  solution: Array<ValidationsMessage>;
};

export type ValidationsQuestionE = ValidationsQuestion & {
  __typename?: 'ValidationsQuestionE';
  category0: Array<ValidationsMessage>;
  category1: Array<ValidationsMessage>;
  category2: Array<ValidationsMessage>;
  category3: Array<ValidationsMessage>;
  category4: Array<ValidationsMessage>;
  category5: Array<ValidationsMessage>;
  category6: Array<ValidationsMessage>;
  category7: Array<ValidationsMessage>;
  score: Array<ValidationsMessage>;
  solution: Array<ValidationsMessage>;
  statementA: ValidationsTranslatedContent;
  statementB: ValidationsTranslatedContent;
};

export type ValidationsQuestionFreeText = ValidationsQuestion & {
  __typename?: 'ValidationsQuestionFreeText';
  category0: Array<ValidationsMessage>;
  category1: Array<ValidationsMessage>;
  category2: Array<ValidationsMessage>;
  category3: Array<ValidationsMessage>;
  category4: Array<ValidationsMessage>;
  category5: Array<ValidationsMessage>;
  category6: Array<ValidationsMessage>;
  category7: Array<ValidationsMessage>;
  content: ValidationsTranslatedContent;
  score: Array<ValidationsMessage>;
  solution: ValidationsTranslatedContent;
  suggestedNumberOfLines: Array<ValidationsMessage>;
};

export type ValidationsQuestionGroup = {
  __typename?: 'ValidationsQuestionGroup';
  affiliation?: Maybe<Array<ValidationsMessage>>;
  author?: Maybe<Array<ValidationsMessage>>;
  general?: Maybe<Array<ValidationsMessage>>;
  questions: Array<ValidationsQuestion>;
  sourceLanguage?: Maybe<Array<ValidationsMessage>>;
  supervisor?: Maybe<Array<ValidationsMessage>>;
  title?: Maybe<Array<ValidationsMessage>>;
};

export type ValidationsQuestionKPrime = ValidationsQuestion & {
  __typename?: 'ValidationsQuestionKPrime';
  category0: Array<ValidationsMessage>;
  category1: Array<ValidationsMessage>;
  category2: Array<ValidationsMessage>;
  category3: Array<ValidationsMessage>;
  category4: Array<ValidationsMessage>;
  category5: Array<ValidationsMessage>;
  category6: Array<ValidationsMessage>;
  category7: Array<ValidationsMessage>;
  content: ValidationsTranslatedContent;
  propositions: Array<ValidationsProposition>;
  score: Array<ValidationsMessage>;
  solution: Array<ValidationsMessage>;
};

export type ValidationsQuestionLongMenu = ValidationsQuestion & {
  __typename?: 'ValidationsQuestionLongMenu';
  category0: Array<ValidationsMessage>;
  category1: Array<ValidationsMessage>;
  category2: Array<ValidationsMessage>;
  category3: Array<ValidationsMessage>;
  category4: Array<ValidationsMessage>;
  category5: Array<ValidationsMessage>;
  category6: Array<ValidationsMessage>;
  category7: Array<ValidationsMessage>;
  content: ValidationsTranslatedContent;
  list: Array<ValidationsMessage>;
  score: Array<ValidationsMessage>;
  solution: Array<ValidationsMessage>;
};

export type ValidationsResponseOption = {
  __typename?: 'ValidationsResponseOption';
  content: ValidationsTranslatedContent;
};

export type ValidationsRevisionStatus = {
  __typename?: 'ValidationsRevisionStatus';
  description?: Maybe<Array<ValidationsMessage>>;
  shortName?: Maybe<Array<ValidationsMessage>>;
};

export enum ValidationsSeverity {
  Error = 'ERROR',
  Warning = 'WARNING'
}

export type ValidationsSubject = {
  __typename?: 'ValidationsSubject';
  name?: Maybe<Array<ValidationsMessage>>;
};

export type ValidationsTask = {
  __typename?: 'ValidationsTask';
  emailBody?: Maybe<Array<ValidationsMessage>>;
  emailSubject?: Maybe<Array<ValidationsMessage>>;
  endsAt?: Maybe<Array<ValidationsMessage>>;
  languageOption?: Maybe<Array<ValidationsMessage>>;
  questionTypes?: Maybe<Array<ValidationsMessage>>;
  receiverIds?: Maybe<Array<ValidationsMessage>>;
  targetLanguage?: Maybe<Array<ValidationsMessage>>;
};

export type ValidationsTranslatedContent = {
  __typename?: 'ValidationsTranslatedContent';
  de: Array<ValidationsMessage>;
  en: Array<ValidationsMessage>;
  fr: Array<ValidationsMessage>;
  it: Array<ValidationsMessage>;
};

export type ValidationsUser = {
  __typename?: 'ValidationsUser';
  alternativeEmail?: Maybe<Array<ValidationsMessage>>;
  email?: Maybe<Array<ValidationsMessage>>;
  firstName?: Maybe<Array<ValidationsMessage>>;
  language?: Maybe<Array<ValidationsMessage>>;
  lastName?: Maybe<Array<ValidationsMessage>>;
  password?: Maybe<Array<ValidationsMessage>>;
  passwordConfirmation?: Maybe<Array<ValidationsMessage>>;
};

export type VideoAsset = {
  __typename?: 'VideoAsset';
  assetId: Scalars['String'];
  filename: Scalars['String'];
  thumbnailAssetId?: Maybe<Scalars['String']>;
  thumbnailFilename?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type VideoAssetInput = {
  assetId: Scalars['String'];
  filename: Scalars['String'];
  thumbnailAssetId?: InputMaybe<Scalars['String']>;
  thumbnailFilename?: InputMaybe<Scalars['String']>;
};
