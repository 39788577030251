angular.module('taskManagement').config(["$httpProvider", "$sceProvider", "$uibModalProvider", function($httpProvider, $sceProvider, $uibModalProvider) {
    var authToken;
    $uibModalProvider.options['backdrop'] = 'static';
    $uibModalProvider.options['keyboard'] = false;
    $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    authToken = document.querySelector('meta[name="csrf-token"]');
    if (authToken) {
      $httpProvider.defaults.headers.common["X-CSRF-TOKEN"] = authToken.getAttribute('content');
    }
    $sceProvider.enabled(false);
  }
]).

run([
  'Store', 'Actions::TaskList', 'Selectors::TaskList',
  function (Store, Actions, Selectors) {
    var visibilities = JSON.parse(localStorage.getItem('taskList.columns.visibility')),
    columns = {
      id: {enabled: true, visible: true, sortable: true},
      type: {enabled: true, visible: true, sortable: true},
      receiver: {enabled: true, visible: true, sortable: true},
      starts_at: {enabled: true, visible: true, sortable: true},
      ends_at: {enabled: true, visible: true, sortable: true},
      finished_at: {enabled: true, visible: true, sortable: true},
      creator: {enabled: true, visible: true, sortable: true},
      status: {enabled: true, visible: true, sortable: true}
    };
    for(var k in visibilities) {
      columns[k].visible = visibilities[k];
    }
    Store.dispatch(Actions.setColumnDefinitions(columns));

    Store.subscribeOn(Selectors.getColumnVisibilities, function (v) {
      localStorage.setItem('taskList.columns.visibility', JSON.stringify(v));
    });
  }
]).

run([
  'Store', 'Actions::TaskQuestionGroupList', 'Selectors::TaskQuestionGroupList', 'Selectors::Pool',
  function(Store, Actions, Selectors, PoolSelectors) {
    var visibilities = JSON.parse(localStorage.getItem('taskQuestionGroupList.columns.visibility')),
    columns = {
      sequential_number: {enabled: true, visible: true, sortable: true},
      legacy_id: {enabled: true, visible: false, sortable: true},
      literary_reference: {enabled: true, visible: false, sortable: true},
      source_language: {enabled: true, visible: false, sortable: true},
      title: {enabled: true, visible: false, sortable: true},
      supervisor: {enabled: true, visible: false, sortable: true},
      affiliation: {enabled: true, visible: false, sortable: true},
      content_validation_state_de: {enabled: false, visible: false, sortable: true},
      content_validation_state_fr: {enabled: false, visible: false, sortable: true},
      content_validation_state_it: {enabled: false, visible: false, sortable: true},
      content_validation_state_en: {enabled: false, visible: false, sortable: true},
      type: {enabled: true, visible: true, sortable: true},
      question_types: {enabled: true, visible: true, sortable: true},
      score: {enabled: true, visible: false, sortable: true},
      has_images: {enabled: true, visible: true, sortable: true},
      has_videos: {enabled: true, visible: true, sortable: true},
      category_0: {enabled: false, visible: true, sortable: true},
      category_1: {enabled: false, visible: true, sortable: true},
      category_2: {enabled: false, visible: true, sortable: true},
      category_3: {enabled: false, visible: true, sortable: true},
      category_4: {enabled: false, visible: true, sortable: true},
      category_5: {enabled: false, visible: true, sortable: true},
      category_6: {enabled: false, visible: true, sortable: true},
      category_7: {enabled: false, visible: true, sortable: true},
      created_at: {enabled: true, visible: true, sortable: true},
      updated_at: {enabled: true, visible: false, sortable: true},
      editor: {enabled: true, visible: false, sortable: true},
      author: {enabled: true, visible: false, sortable: true},
      number_of_usages: {enabled: true, visible: true, sortable: true},
      last_usage: {enabled: true, visible: false, sortable: true},
      latest_statistic: {enabled: true, visible: false, sortable: true},
      cache_last_usage_n: {enabled: true, visible: false, sortable: true},
      cache_last_usage_p: {enabled: true, visible: true, sortable: true},
      cache_last_usage_r: {enabled: true, visible: true, sortable: true},
      cache_last_usage_lne: {enabled: true, visible: false, sortable: false},
      cache_last_usage_eliminated: {enabled: true, visible: true, sortable: true},
      exams: {enabled: true, visible: true, sortable: false},
      labels: {enabled: true, visible: true, sortable: false},
      revision_status: {enabled: true, visible: true, sortable: false},
      revision_year: {enabled: true, visible: false, sortable: true}
    };
    for(var k in visibilities) {
      if (columns[k] !== undefined) {
        columns[k].visible = visibilities[k];
      }
    }
    Store.dispatch(Actions.setColumnDefinitions(columns));

    Store.subscribeOn(Selectors.getColumnVisibilities, function (v) {
      localStorage.setItem('taskQuestionGroupList.columns.visibility', JSON.stringify(v));
    });

    Store.subscribeOn(PoolSelectors.getCurrentPool, function (pool) {
      if (pool) {
        columns = Object.assign({}, columns, {
          content_validation_state_de: {enabled: (pool.languages.indexOf('de') > -1), visible: false, sortable: true},
          content_validation_state_fr: {enabled: (pool.languages.indexOf('fr') > -1), visible: false, sortable: true},
          content_validation_state_it: {enabled: (pool.languages.indexOf('it') > -1), visible: false, sortable: true},
          content_validation_state_en: {enabled: (pool.languages.indexOf('en') > -1), visible: false, sortable: true},
          question_types: {enabled: true, visible: true, sortable: true},
          score: {enabled: true, visible: false, sortable: true},
          has_images: {enabled: true, visible: true, sortable: true},
          has_videos: {enabled: true, visible: true, sortable: true},
          category_0: {enabled: (pool.dimensions.length > 0), visible: true, sortable: true,
            translationParams: {name: pool.dimensions[0] && pool.dimensions[0].name}},
          category_1: {enabled: (pool.dimensions.length > 1), visible: true, sortable: true,
            translationParams: {name: pool.dimensions[1] && pool.dimensions[1].name}},
          category_2: {enabled: (pool.dimensions.length > 2), visible: true, sortable: true,
            translationParams: {name: pool.dimensions[2] && pool.dimensions[2].name}},
          category_3: {enabled: (pool.dimensions.length > 3), visible: true, sortable: true,
            translationParams: {name: pool.dimensions[3] && pool.dimensions[3].name}},
          category_4: {enabled: (pool.dimensions.length > 4), visible: true, sortable: true,
            translationParams: {name: pool.dimensions[4] && pool.dimensions[4].name}},
          category_5: {enabled: (pool.dimensions.length > 5), visible: true, sortable: true,
            translationParams: {name: pool.dimensions[5] && pool.dimensions[5].name}},
          category_6: {enabled: (pool.dimensions.length > 6), visible: true, sortable: true,
            translationParams: {name: pool.dimensions[6] && pool.dimensions[6].name}},
          category_7: {enabled: (pool.dimensions.length > 7), visible: true, sortable: true,
            translationParams: {name: pool.dimensions[7] && pool.dimensions[7].name}},
        });
        Store.dispatch(Actions.setColumnDefinitions(columns));
      }
    });
  }
]);
