angular.module('common').factory('Selectors::QuestionGroupDetails', function() {
  function isRequestInProgress(object) {
    return (
      (object && object.questionGroupDetails && object.questionGroupDetails.requestInProgress) ||
      false
    );
  }

  function isQuestionGroupPresent(object) {
    return (
      (object &&
        object.questionGroupDetails &&
        object.questionGroupDetails.questionGroup !== undefined) ||
      false
    );
  }

  function getQuestionGroupId(object) {
    return (
      object &&
      object.questionGroupDetails &&
      object.questionGroupDetails.questionGroup &&
      object.questionGroupDetails.questionGroup.id
    );
  }

  function getMessages(object) {
    return (
      (object &&
        object.questionGroupDetails &&
        object.questionGroupDetails.questionGroup &&
        object.questionGroupDetails.questionGroup.messages) ||
      []
    );
  }

  function getPreviews(object) {
    return (
      object &&
      object.questionGroupDetails &&
      object.questionGroupDetails.questionGroup &&
      object.questionGroupDetails.questionGroup.previews
    );
  }

  function getStatistics(object) {
    return (
      (object &&
        object.questionGroupDetails &&
        object.questionGroupDetails.questionGroup &&
        object.questionGroupDetails.questionGroup.statistics) ||
      []
    );
  }

  return {
    isRequestInProgress: isRequestInProgress,
    isQuestionGroupPresent: isQuestionGroupPresent,
    getQuestionGroupId: getQuestionGroupId,
    getMessages: getMessages,
    getPreviews: getPreviews,
    getStatistics: getStatistics
  };
});
