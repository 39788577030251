import { TranslateService } from '@ngx-translate/core';
import { TINY_MCE_PAGEBREAK_CLASS } from './image.plugin';
import type { TinyMCEEditor } from './tiny-mce.types';

declare let tinyMCE: {
  PluginManager: {
    add(name: string, callback: (editor: TinyMCEEditor) => void): void;
  };
};

export const RESOLUTION_INFO_PLUGIN_CSS = `
.resolution-marker {
  border: 3px;
}
.resolution-marker--valid {
  border-color: green;
  border-style: solid;
}
.resolution-marker--invalid {
  border-color: red;
  border-style: dashed;
}
`;
enum ToolTipState {
  VALID = 'VALID',
  INVALID = 'INVALID'
}
const CSS_CLASS_TOOLTIP = 'image-validation-tooltip';
const CSS_CLASS_VALID = 'resolution-marker--valid';
const CSS_CLASS_INVALID = 'resolution-marker--invalid';
const CSS_CLASS_DANGER = 'tooltip-danger';
const CSS_CLASS_SUCCESS = 'tooltip-success';
const TRANSLATION_VALID = 'editor.tooltips.image.valid_size';
const TRANSLATION_INVALID = 'editor.tooltips.image.too_big';
const MOUSE_POINTER_PADDING = 15;

const removeObsoleteTooltipElements = (): void => {
  document.querySelectorAll('.' + CSS_CLASS_TOOLTIP).forEach(tooltip => {
    tooltip.remove();
  });
};

export function setupIMLResolutionPlugin(
  translateService: TranslateService
): void {
  tinyMCE.PluginManager.add(
    'iml-resolution-info',
    function (editor: TinyMCEEditor) {
      const EXAM_BOOK_CONTENT_WIDTH = 1747;
      const cache = new Map<string, HTMLImageElement>();

      let editorPositionX = 0;
      let editorPositionY = 0;

      function findOriginalImageDimensions(
        image: HTMLImageElement,
        tooltipCallback: (width: number, height: number) => void
      ): void {
        let originalImage: HTMLImageElement;
        if (cache.has(image.src)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          originalImage = cache.get(image.src)!;
          tooltipCallback(
            originalImage.naturalWidth,
            originalImage.naturalHeight
          );
        } else {
          originalImage = new Image();
          originalImage.addEventListener('load', function () {
            tooltipCallback(
              originalImage.naturalWidth,
              originalImage.naturalHeight
            );
          });
          originalImage.src = image.src;
          cache.set(image.src, originalImage);
        }
      }

      function getImageScaleFactor(
        image: HTMLImageElement,
        newImageWidth: number
      ): number {
        const containerWidth = editor.contentAreaContainer.offsetWidth;
        const maximumImageWidthInContainer =
          (newImageWidth * containerWidth) / EXAM_BOOK_CONTENT_WIDTH;

        return image.offsetWidth / maximumImageWidthInContainer;
      }

      function updateAllImageResolutionMarkers(editor: TinyMCEEditor): void {
        const images: NodeListOf<HTMLImageElement> =
          editor.contentDocument.querySelectorAll(
            'img:not(' + TINY_MCE_PAGEBREAK_CLASS + ')'
          ) ?? [];
        images.forEach(image => {
          updateResolutionMarker(image);
        });
      }

      function createTooltip(
        image: HTMLImageElement,
        width: number,
        height: number,
        type: ToolTipState
      ): void {
        const tooltipCSSClass =
          type === ToolTipState.VALID ? CSS_CLASS_SUCCESS : CSS_CLASS_DANGER;
        const parentCSSClass =
          type === ToolTipState.VALID ? CSS_CLASS_VALID : CSS_CLASS_INVALID;
        const translationKey =
          type === ToolTipState.VALID ? TRANSLATION_VALID : TRANSLATION_INVALID;

        image.classList.add(parentCSSClass);

        image.addEventListener('mouseleave', removeObsoleteTooltipElements);
        image.addEventListener('blur', removeObsoleteTooltipElements);

        const tooltipFactory = function (): void {
          removeObsoleteTooltipElements();

          const tooltipWidth = 200;
          const scaleFactor =
            Math.ceil(getImageScaleFactor(image, width) * 100) / 100;

          const div = document.createElement('div');
          div.classList.add(CSS_CLASS_TOOLTIP);
          div.classList.add(tooltipCSSClass);
          div.setAttribute(
            'style',
            `z-index: 1001; display: none; background-color: black; color: white; width: ${tooltipWidth}px; white; position: fixed; border-radius: 5px; border: 5px solid black;`
          );
          div.innerHTML = translateService.instant(translationKey, {
            scaleFactor: scaleFactor,
            imageWidth: width,
            imageHeight: height
          });

          document.body.append(div);
        };

        removeEventListener(image, tooltipFactory, 'mouseenter');
        image.addEventListener('mouseenter', tooltipFactory, false);
      }

      function prepareResolutionMarkers(editor: TinyMCEEditor): void {
        const images =
          editor.contentDocument.querySelectorAll(
            'img:not(' + TINY_MCE_PAGEBREAK_CLASS + ')'
          ) ?? [];

        images.forEach(image => {
          image.classList.add('resolution-marker');
        });

        const mouseMoveCallback = function (e: {
          pageX: number;
          pageY: number;
        }): void {
          const editorID = editor.contentWindow.document.body.dataset.id;
          const editorRef = window.parent?.document?.querySelector(
            '#' + editorID
          )?.parentElement;

          const iframeRef = editorRef?.querySelector('iframe');
          const scrollLeft =
            iframeRef?.contentDocument?.querySelector('html')?.scrollLeft ?? 0;

          // stop if no editor rendered
          if (editorRef === undefined || editorRef === null) {
            return;
          }

          const absolutPostion = getAbsolutePosition(editorRef);
          editorPositionX = absolutPostion.x;
          editorPositionY = editorRef.getBoundingClientRect().y || 0;

          mouseX = e.pageX;
          mouseY = e.pageY;

          const left =
            mouseX + editorPositionX + MOUSE_POINTER_PADDING - scrollLeft;
          const top = mouseY + editorPositionY + MOUSE_POINTER_PADDING;

          const tooltip = document.querySelector(
            '.' + CSS_CLASS_TOOLTIP
          ) as HTMLDivElement;

          if (tooltip !== undefined && tooltip !== null) {
            tooltip.style.left = left + 'px';
            tooltip.style.top = top + 'px';
            tooltip.style.display = 'block';
          }
        };

        editor.contentDocument.addEventListener('mousemove', mouseMoveCallback);
      }

      function updateResolutionMarker(image: HTMLImageElement): void {
        findOriginalImageDimensions(
          image,
          function (width: number, height: number) {
            image?.classList.remove(CSS_CLASS_VALID);
            image?.classList.remove(CSS_CLASS_INVALID);

            removeObsoleteTooltipElements();

            if (getImageScaleFactor(image, width) > 1) {
              createTooltip(image, width, height, ToolTipState.INVALID);
            } else {
              createTooltip(image, width, height, ToolTipState.VALID);
            }
          }
        );
      }

      function updateResolutionMarkerOnResize(image: HTMLImageElement): void {
        findOriginalImageDimensions(
          image,
          function (width: number, _height: number) {
            image?.classList.remove(CSS_CLASS_VALID);
            image?.classList.remove(CSS_CLASS_INVALID);

            getImageScaleFactor(image, width);

            if (getImageScaleFactor(image, width) > 1) {
              image?.classList.add(CSS_CLASS_INVALID);
            } else {
              image?.classList.add(CSS_CLASS_VALID);
            }
          }
        );
      }

      const resizingFn = function (): void {
        const cloned = editor.contentDocument.querySelector(
          '.mce-clonedresizable'
        );
        if (cloned?.tagName === 'IMG') {
          updateResolutionMarkerOnResize(cloned as HTMLImageElement);
        }
      };

      editor.on('change', function (): void {
        removeObsoleteTooltipElements();
        updateAllImageResolutionMarkers(editor);
      });

      editor.on('init', function (): void {
        document.addEventListener('mousemove', resizingFn);
        prepareResolutionMarkers(editor);
        updateAllImageResolutionMarkers(editor);
      });

      editor.on('remove', function (): void {
        removeEventListener(document, resizingFn, 'mousemove');
      });

      editor.on(
        'ObjectResizeStart',
        function (e: { target: HTMLElement }): void {
          if (e.target?.tagName === 'IMG') {
            e.target?.classList.remove(CSS_CLASS_VALID);
            e.target?.classList.remove(CSS_CLASS_INVALID);
            editor.contentDocument.addEventListener('mousemove', resizingFn);
          }
        }
      );

      editor.on('ObjectResized', function (): void {
        removeEventListener(editor.contentDocument, resizingFn, 'mousemove');
      });
    }
  );

  let mouseX = 0;
  let mouseY = 0;
}

function removeEventListener(
  element: Document | Element | HTMLElement,
  toRemove: EventListenerOrEventListenerObject,
  event: string
): void {
  element.removeEventListener(event, toRemove, {
    passive: true
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any); // Succeeds
  element.removeEventListener(event, toRemove, {
    capture: false
  }); // Succeeds
  element.removeEventListener(event, toRemove, {
    capture: true
  }); // Fails
  element.removeEventListener(event, toRemove, {
    passive: false
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any); // Succeeds
  element.removeEventListener(event, toRemove, false); // Succeeds
  element.removeEventListener(event, toRemove, true); // Fails
}

function getAbsolutePosition(element: HTMLElement): { x: number; y: number } {
  const r = { x: element.offsetLeft, y: element.offsetTop };
  if (element.offsetParent) {
    const absolutePosition = getAbsolutePosition(
      element.offsetParent as HTMLElement
    );
    r.x += absolutePosition.x;
    r.y += absolutePosition.y;
  }

  return r;
}
