import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbNavModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { CommonModule as ManagerCommonModule } from '../common/common.module';
import { CollapsableDirective } from '../common/directives/collapsable.directive';
import './angularjs/module';
import { QuestionGroupColumnVisibilityComponent } from './components/question-group-column-visibility/question-group-column-visibility.component';
import { QuestionGroupDetailsComponent } from './components/question-group-details/question-group-details.component';
import { QuestionGroupExportModalComponent } from './components/question-group-export-modal/question-group-export-modal.component';
import { QuestionGroupFilterComponent } from './components/question-group-filter/question-group-filter.component';
import { QuestionGroupFilterAdvancedComponent } from './components/question-group-filter-advanced/question-group-filter-advanced.component';
import { FilterCheckboxComponent } from './components/question-group-filter-checkbox/filter-checkbox.component';
import { FilterSelectComponent } from './components/question-group-filter-select/filter-select.component';
import { QuestionGroupListComponent } from './components/question-group-list/question-group-list.component';
import { QuestionGroupTaskToolbarComponent } from './components/question-group-toolbar/question-group-task-toolbar.component';
import { QuestionGroupToolbarComponent } from './components/question-group-toolbar/question-group-toolbar.component';
import { QuestionManagementComponent } from './components/question-management/question-management.component';
import './downgraded';
import { QuestionDetailsState } from './state/question-details.state';
import { QuestionFilterState } from './state/question-filter/question-filter.state';
import { QuestionListState } from './state/question-list.state';

@NgModule({
  declarations: [
    FilterCheckboxComponent,
    FilterSelectComponent,
    QuestionGroupFilterComponent,
    QuestionGroupFilterAdvancedComponent,
    QuestionGroupExportModalComponent,
    QuestionGroupColumnVisibilityComponent,
    QuestionGroupDetailsComponent,
    QuestionGroupToolbarComponent,
    QuestionGroupTaskToolbarComponent,
    CollapsableDirective,
    QuestionGroupListComponent,
    QuestionManagementComponent
  ],
  imports: [
    NgbNavModule,
    NgbAccordionModule,
    NgxsModule.forFeature([
      QuestionListState,
      QuestionDetailsState,
      QuestionFilterState
    ]),
    ManagerCommonModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    SkeletonModule
  ],
  providers: [DecimalPipe]
})
export class QuestionManagementModule {}
