angular.module('settings').factory('Actions::PoolList', function () {
  var actions = {
    FETCH_POOLS_REQUEST: 'POOLS_LIST_FETCH_POOLS_REQUEST',
    FETCH_POOLS_SUCCESS: 'POOLS_LIST_FETCH_POOLS_SUCCESS',
    SET_POOL_SELECTION: 'POOLS_LIST_SET_POOL_SELECTION'
  };

  function fetchPoolsRequest () {
    return {
      type: actions.FETCH_POOLS_REQUEST
    };
  }

  function fetchPoolsSuccess (pools) {
    return {
      type: actions.FETCH_POOLS_SUCCESS,
      pools: pools
    };
  }

  function setPoolSelection (id) {
    return {
      type: actions.SET_POOL_SELECTION,
      selectedId: id
    };
  }

  return Object.assign({
    fetchPoolsRequest: fetchPoolsRequest,
    fetchPoolsSuccess: fetchPoolsSuccess,
    setPoolSelection: setPoolSelection
  }, actions);
});
