<div class="grid h-100" style="grid-template-rows: auto 1fr">
  <div class="g-col-1 g-col-md-3 g-col-xl-2">
    <a
      [routerLink]="['../']"
      [title]="'admin.navigation.pools' | translate"
      class="btn btn-light d-block d-md-inline-flex align-items-md-center gap-2"
    >
      <i class="fa-solid fa-sm fa-arrow-left" aria-hidden="true"></i>
      <span class="d-none d-md-inline-block">
        {{ 'admin.navigation.pools' | translate }}</span
      ></a
    >
  </div>
  <div class="g-col-11 g-col-md-9 g-col-xl-10 align-self-center">
    <h5 class="my-0">{{ 'admin.pools.title.create' | translate }}</h5>
  </div>
  <div class="g-col-1 g-col-md-3 g-col-xl-2"></div>
  <div class="g-col-11 g-col-md-9 g-col-xl-10">
    <man-pool-form
      [saving]="saving$ | async"
      [disabled]="saving$ | async"
      (save)="onSave($event)"
      (stateChange)="onStateChange($event)"
    >
    </man-pool-form>
  </div>
</div>
