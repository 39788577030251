import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/admin/common/modal/modal.service';
import { CreateSubjectMutationVariables } from 'src/app/admin/services/create-subject.generated';
import { UpdateSubjectMutationVariables } from 'src/app/admin/services/update-subject.generated';
import { Subject } from 'src/generated/base-types';

@Component({
  selector: 'man-subject-form',
  templateUrl: './subject-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectFormComponent implements OnInit {
  @Input() public subject?: Subject;
  @Input() public canDismissWhileDirty?: boolean;
  @Input() public set disabled(value: boolean | undefined) {
    value === true ? this.form?.disable() : this.form?.enable();
  }
  public get disabled(): boolean {
    return this.form?.disabled ?? true;
  }

  @Output() public create = new EventEmitter<
    Pick<CreateSubjectMutationVariables, 'name' | 'categoryIds'>
  >();
  @Output() public update = new EventEmitter<UpdateSubjectMutationVariables>();
  @Output() public closed = new EventEmitter<void>();

  public form: FormGroup;

  public get edit(): boolean {
    return this.subject !== undefined;
  }

  constructor(
    public translate: TranslateService,
    private readonly modalService: ModalService
  ) {}

  public ngOnInit(): void {
    this.initialiseForm();
  }

  public async onClose(): Promise<void> {
    if (this.disabled) return;
    await this.safeClose();
  }

  public onSave(): void {
    if (!this.form.valid || !this.form.dirty) return;

    this.subject === undefined
      ? this.create.emit({
          name: this.form?.value.name,
          categoryIds: this.form?.value.categoryIds ?? []
        })
      : this.update.emit({
          id: this.subject.id,
          name: this.form?.value.name,
          categoryIds: this.form?.value.categoryIds
        });
  }

  private initialiseForm(): void {
    this.form = new FormGroup({
      name: new FormControl<Subject['name']>('', [Validators.required]),
      categoryIds: new FormControl<string[]>([])
    });

    this.form.patchValue({
      name: this.subject?.name,
      categoryIds: this.subject?.categories.map(category => category.id)
    });
  }

  private async safeClose(): Promise<void> {
    if (this.canDismissWhileDirty === true || !this.form.dirty) {
      this.closed.emit();

      return;
    }

    const discardChanges = await this.modalService.confirmUnsavedChanges();
    if (discardChanges) {
      this.closed.emit();
    }
  }
}
