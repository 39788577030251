angular.module('common').directive('inlineInput', ['$compile', '$parse',
  function ($compile, $parse) {
    return {
      restrict: 'A',
      replace: false,
      link: function (scope, textElement, attrs) {
        var inputElement = $compile('<div class="col-xs-10 ng-hide"><input class="form-control input-sm" type="text" ng-model="' + attrs.inlineInput + '"></div>')(scope),
        buttonElement = $compile('<button class="ng-hide btn btn-sm btn-primary"><i class="fa fa-check"></i></button>')(scope),
        changeCallback = attrs.onChange,
        editMode = attrs.editMode,
        editModeGetter = $parse(editMode);

        function enterEditMode () {
          inputElement.removeClass('ng-hide');
          buttonElement.removeClass('ng-hide');
          textElement.addClass('ng-hide');
        }

        function leaveEditMode () {
          inputElement.addClass('ng-hide');
          buttonElement.addClass('ng-hide');
          textElement.removeClass('ng-hide');
          if (changeCallback) {
            $parse(changeCallback)(scope);
          }
        }

        textElement.on('dblclick', function () {
          enterEditMode();
          editModeGetter.assign(scope, true);
        });
        buttonElement.on('click', function () {
          leaveEditMode();
          editModeGetter.assign(scope, false);
        });
        textElement.after(buttonElement);
        textElement.after(inputElement);

        scope.$watch(editMode, function (newValue, oldValue) {
          if (newValue && !oldValue) {
            enterEditMode();
          }
          else if (oldValue && !newValue) {
            leaveEditMode();
          }
        });
      }
    };
  }
]);
