angular.module('common').directive('checkFormChangesBeforeClick', ['$window', '$parse', '$rootScope', 'ConfirmationModal', function ($window, $parse, $rootScope, ConfirmationModal) {
  return {
    link: function (scope, element, attrs) {
      function isCallbackEmpty () {
        return attrs.checkFormChangesBeforeClick.length === 0;
      }

      function invokeCallback () {
        var fn = $parse(attrs.checkFormChangesBeforeClick);
        fn(scope);
      }

      function redirectUrl () {
        $window.location.href = attrs.href;
      }

      var clickHandler;
      if (isCallbackEmpty()) {
        clickHandler = redirectUrl;
      }
      else {
        clickHandler = invokeCallback;
      }

      element.on('click', function (event) {
        event.preventDefault();

        if ($rootScope.hasFormChanged) {
          ConfirmationModal.show(I18n.t('question_management.form_changes_modal.title'),
            I18n.t('question_management.form_changes_modal.content')).result
            .then(function () {
              clickHandler();
            });
        }
        else {
          clickHandler();
        }
      });
    }
  };
}]);
