angular.module('main').controller('headerCtrl', [
  '$scope',
  '$http',
  '$window',
  'Store',
  'Selectors::User',
  function ($scope, $http, $window, Store, UserSelectors) {
    $scope.I18n = I18n;
    $scope.logout = function () {
      $http.delete('/logout').then(function () {
        $window.location.href = '/login';
      });
    };

    Store.subscribeOn(UserSelectors.getCurrentUser, function (user) {
      if (user) {
        $scope.superUser = user.super_user;
        $scope.coordinator = user.permissions.some(function (permission) {
          return permission.role == 'coordinator';
        });
      }
    });
  }
])
