(function() {
  angular.module('common').factory('SelectionHolder', ['$cookies', 'UniqueArray', function ($cookies, UniqueArray) {

    function Holder() {
      this.store = {};
    }

    // --------- helper functions ----------

    Holder.prototype._contains = function (array, value) {
      for(var i = 0; i < array.length; i++) {
        if (array[i] === value) {
          return true;
        }
      }
      return false;
    }

    // ----------- visibility functions ---------------

    Holder.prototype.initFromCookie = function(property, items) {
       selectedItemsIds = $cookies.getObject('common.SelectionHolder.' + property + '.ids');

      if(Array.isArray(selectedItemsIds)) {
        var self = this;
        var selectedItems = items.filter(function(element) {
          return selectedItemsIds.indexOf(element.id) > -1;
        });

        this.put(property, new UniqueArray(selectedItems));
      } else {
        var selectedItem = items.filter(function(element) {
          return element.id === selectedItemsIds;
        })[0];

        this.put(property, selectedItem);
      }
    };

    Holder.prototype.get = function(property) {
      return this.store[property];
    };

    Holder.prototype.put = function(property, items) {
      this.store[property] = items;
    };

    return new Holder();
  }]);
}).call(this);
