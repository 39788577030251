angular.module('common').directive('panel', [
  '$rootScope',
  'Panel',
  'Store',
  function($rootScope, Panel, Store) {
    return {
      restrict: 'A',
      replace: false,
      link: function(scope, element, attrs) {
        var panelType = attrs.panel;
        var currentWidth = null;
        var overlay;
        var hiddenSubscription, loadingSubscription;

        function hidePanel() {
          currentWidth = element.width();
          element.css('width', '');
          element.addClass('sheet--hidden');
          $rootScope.$broadcast('panel.resize');
        }

        function showPanel() {
          element.removeClass('sheet--hidden');
          if (currentWidth !== null) {
            element.width(currentWidth);
          }
          $rootScope.$broadcast('panel.resize');
        }

        function startLoadingAnimation() {
          overlay = createPanelOverlay();
          element.append(overlay);
        }

        function stopLoadingAnimation() {
          if (overlay) {
            overlay.remove();
          }
        }

        function createPanelOverlay() {
          var width = element.width();
          var height = element.height();
          var overlay = angular.element('<div class="sheet-overlay"></div>');
          overlay.width(width);
          overlay.height(height);
          return overlay;
        }

        loadingSubscription = Store.subscribeOn(Panel.isLoading.bind(this, panelType), function(
          newValue
        ) {
          if (newValue) {
            startLoadingAnimation();
          } else {
            stopLoadingAnimation();
          }
        });

        if (panelType !== 'center') {
          hiddenSubscription = Store.subscribeOn(Panel.isHidden.bind(this, panelType), function(
            newValue
          ) {
            if (newValue) {
              hidePanel();
            } else {
              showPanel();
            }
          });
        }

        scope.$on('$destroy', function() {
          loadingSubscription();
          if (hiddenSubscription) {
            hiddenSubscription();
          }
        });
      }
    };
  }
]);
