<ng-container *ngIf="severity">
  <ng-container [ngSwitch]="severity">
    <i
      *ngSwitchCase="AnnouncementSeverity.Info"
      [attr.title]="description"
      aria-hidden="true"
      class="fa-regular fa-circle-info text-info"
    ></i>
    <i
      *ngSwitchCase="AnnouncementSeverity.Warning"
      [attr.title]="description"
      aria-hidden="true"
      class="fa-regular fa-triangle-exclamation text-warning"
    ></i>
    <i
      *ngSwitchCase="AnnouncementSeverity.Danger"
      [attr.title]="description"
      aria-hidden="true"
      class="fa-regular fa-circle-exclamation text-danger"
    ></i>
  </ng-container>
  <span *ngIf="description" class="visually-hidden">{{ description }}</span>
</ng-container>
