<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'common.source_language_modal.title' | translate }}</h3>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="form-group">
        <div class="col-sm-12 text-center">
          <div class="btn-group">
            <button
              *ngFor="let language of languages"
              class="btn btn-primary"
              [ngClass]="{ active: language === selectedLanguage }"
              (click)="selectedLanguage = language"
            >
              {{ 'scrudu.languages.long.' + language | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="dismiss()" class="btn btn-secondary-migrated">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      (click)="save()"
      [disabled]="isSaving || !selectedLanguage"
      class="btn btn-primary-migrated"
    >
      {{ 'common.actions.save' | translate }}
    </button>
  </div>
</div>
