import { Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { Language } from 'src/generated/base-types';
import { LegacyProvider } from '../ajs-upgraded-providers';
import {
  LoadExam,
  SetLoadedExamIds,
  SetLoadedExamQuestionGroupIds,
  SetSelectedExamId,
  SetSelectedExamQuestionGroupId
} from '../exam-management/state/exam-list.actions';
import {
  SetCurrentLanguage,
  SetCurrentPool
} from '../state/app-context.actions';
import { LoadPool } from '../state/pool.actions';
import {
  SetLoadedTaskIds,
  SetLoadedTaskQuestionGroupIds,
  SetSelectedTaskIds,
  SetSelectedTaskQuestionGroupIds
} from '../task-management/state/task-list.actions';

export function syncReduxWithNgXs(injector: Injector): void {
  const reduxStore = injector.get(LegacyProvider.Store);
  const poolSelector = injector.get(LegacyProvider.SelectorPool);
  const userSelector = injector.get(LegacyProvider.SelectorUser);
  const taskManagementTaskListSelector = injector.get(
    LegacyProvider.SelectorTaskList
  );
  const taskManagementQuestionGroupListSelector = injector.get(
    LegacyProvider.SelectorTaskQuestionGroupList
  );

  const examManagementExamListSelector = injector.get(
    LegacyProvider.SelectorExamList
  );
  const examManagementQuestionGroupListSelector = injector.get(
    LegacyProvider.SelectorExamQuestionGroupList
  );

  const ngxsStore = injector.get(Store);

  reduxStore.subscribeOn(
    poolSelector.getCurrentPool,
    (pool: { id: number; languages: Language[] } | undefined) => {
      if (pool) {
        // load the pool to the new store
        ngxsStore.dispatch(new LoadPool(String(pool.id)));
        ngxsStore.dispatch(new SetCurrentPool(pool.id + '', pool.languages));
      }
    }
  );

  reduxStore.subscribeOn(
    userSelector.getLanguage,
    (language: Language | undefined) => {
      if (language) {
        ngxsStore.dispatch(new SetCurrentLanguage(language));
      }
    }
  );

  reduxStore.subscribeOn(
    examManagementExamListSelector.getSelection,
    (examId: number) => {
      if (examId) {
        ngxsStore.dispatch(new SetSelectedExamId(examId.toString()));
        ngxsStore.dispatch(new LoadExam(examId.toString()));
      }
    }
  );

  reduxStore.subscribeOn(
    examManagementExamListSelector.getItems,
    (exams: { id: number }[]) => {
      const ids = exams.map(exam => exam.id.toString());
      ngxsStore.dispatch(new SetLoadedExamIds(ids));
    }
  );

  reduxStore.subscribeOn(
    taskManagementTaskListSelector.getAllIds,
    (taskIds: number[]) => {
      ngxsStore.dispatch(
        new SetLoadedTaskIds(taskIds.map(id => id.toString()))
      );
    }
  );

  reduxStore.subscribeOn(
    taskManagementQuestionGroupListSelector.getFlatItems,
    (taskQuestionGroups: { id: number; question_group: { id: number } }[]) => {
      const questionGroupIds = taskQuestionGroups.map(taskQg =>
        taskQg.question_group.id.toString()
      );
      const taskQuestionGroupIds = taskQuestionGroups.map(taskQg =>
        taskQg.id.toString()
      );
      ngxsStore.dispatch(
        new SetLoadedTaskQuestionGroupIds(
          taskQuestionGroupIds,
          questionGroupIds
        )
      );
    }
  );

  reduxStore.subscribeOn(
    taskManagementTaskListSelector.getSelection,
    (taskIds: number[]) => {
      ngxsStore.dispatch(
        new SetSelectedTaskIds(taskIds.map(id => id.toString()))
      );
    }
  );

  reduxStore.subscribeOn(
    taskManagementQuestionGroupListSelector.getSelectedItems,
    (taskQuestionGroups: { id: number; question_group: { id: number } }[]) => {
      const questionGroupIds = taskQuestionGroups.map(taskQg =>
        taskQg.question_group.id.toString()
      );
      const taskQuestionGroupIds = taskQuestionGroups.map(taskQg =>
        taskQg.id.toString()
      );
      ngxsStore.dispatch(
        new SetSelectedTaskQuestionGroupIds(
          taskQuestionGroupIds,
          questionGroupIds
        )
      );
    }
  );

  reduxStore.subscribeOn(
    examManagementQuestionGroupListSelector.getSelection,
    (examQuestionGroupId: number) => {
      if (examQuestionGroupId) {
        ngxsStore.dispatch(
          new SetSelectedExamQuestionGroupId(examQuestionGroupId.toString())
        );
      }
    }
  );

  reduxStore.subscribeOn(
    examManagementQuestionGroupListSelector.getItems,
    (examQGs: { question_group: { id: number } }[]) => {
      ngxsStore.dispatch(
        new SetLoadedExamQuestionGroupIds(
          examQGs.map(examQG => examQG.question_group.id.toString())
        )
      );
    }
  );
}
