import { QuestionGroupPreview, Scalars } from 'src/generated/base-types';
import { ManagerError } from '../../common/utils/error-parser';
import { QuestionGroupMessageFragment } from '../services/question-group-messages.generated';
import { QuestionGroupStatisticsFragment } from '../services/question-group-statistics.generated';
import { QuestionDetailsTabs } from './question-details.state';

export class LoadQuestionGroupPreview {
  public static readonly type =
    '[QuestionManagement Details] Load Question Group Preview';
  constructor(public readonly questionGroupId: Scalars['ID']) {}
}

export class LoadQuestionGroupPreviewSuccess {
  public static readonly type =
    '[QuestionManagement Details] Load Question Group Preview Success';
  constructor(public readonly preview: QuestionGroupPreview) {}
}

export class LoadQuestionGroupPreviewFailure {
  public static readonly type =
    '[QuestionManagement Details] Load Question Group Preview Failure';
}

export class LoadQuestionGroupMessages {
  public static readonly type =
    '[QuestionManagement Details] Load Question Group Messages';
  constructor(public readonly questionGroupId: Scalars['ID']) {}
}

export class LoadQuestionGroupStatistics {
  public static readonly type =
    '[QuestionManagement Details] Load Question Group Statistics';
  constructor(public readonly questionGroupId: Scalars['ID']) {}
}

export class LoadQuestionGroupStatisticsSuccess {
  public static readonly type =
    '[QuestionManagement Details] Load Question Group Statistics Success';
  constructor(public readonly statistics: QuestionGroupStatisticsFragment[]) {}
}

export class LoadQuestionGroupStatisticsFailure {
  public static readonly type =
    '[QuestionManagement Details] Load Question Group Statistics Failure';
  constructor(public readonly error: ManagerError) {}
}

export class LoadQuestionGroupMessagesSuccess {
  public static readonly type =
    '[QuestionManagement Details] Load Question Group Messages Success';
  constructor(public readonly messages: QuestionGroupMessageFragment[]) {}
}

export class LoadQuestionGroupMessagesFailure {
  public static readonly type =
    '[QuestionManagement Details] Load Question Group Messages Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdateCommentReadState {
  public static readonly type =
    '[QuestionManagement Details] Update Comment Read State';
  constructor(
    public readonly commentId: Scalars['ID'],
    public readonly read: Scalars['Boolean']
  ) {}
}

export class UpdateCommentReadStateSuccess {
  public static readonly type =
    '[QuestionManagement Details] Update Comment Read State Success';
  constructor(
    public readonly commentId: Scalars['ID'],
    public readonly read: Scalars['Boolean']
  ) {}
}

export class UpdateCommentReadStateFailure {
  public static readonly type =
    '[QuestionManagement Details] Update Comment Read State Failure';
  constructor(
    public readonly commentId: Scalars['ID'],
    public readonly error: ManagerError
  ) {}
}

export class DeleteComment {
  public static readonly type = '[QuestionManagement Details] Delete Comment';
  constructor(public readonly commentId: Scalars['ID']) {}
}

export class DeleteCommentSuccess {
  public static readonly type =
    '[QuestionManagement Details] Delete Comment Success';
  constructor(public readonly commentId: Scalars['ID']) {}
}

export class DeleteCommentFailure {
  public static readonly type =
    '[QuestionManagement Details] Delete Comment Failure';
  constructor(
    public readonly commentId: Scalars['ID'],
    public readonly error: ManagerError
  ) {}
}

export class UpdateTabSelection {
  public static readonly type =
    '[QuestionManagement Details] Update Tab Selection';
  constructor(public readonly activeTab: QuestionDetailsTabs) {}
}

export class ReloadAllQuestionDetails {
  public static readonly type =
    '[QuestionManagement Details] Reload All Question Details';
}

export class ToggleDetailsPanel {
  public static readonly type = '[QuestionManagement Details] Toggle Panel';
}

export class SetDetailsWidth {
  public static readonly type = '[QuestionManagement Details] Set Width';
  constructor(public readonly width: number) {}
}
