import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Type } from '../../services/toasts.service';

type ToastState = 'show' | 'idle' | 'hide';

@Component({
  selector: 'co-toast',
  templateUrl: './toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {
  @Input() public message?: string;
  @Input() public type?: Type;
  @Input() public duration?: number;

  @Output() public remove = new EventEmitter<void>();

  public state: ToastState = 'show';

  public animationEnd(_ev: AnimationEvent): void {
    switch (this.state) {
      case 'show': {
        this.state = 'idle';
        break;
      }
      case 'idle': {
        this.state = 'hide';
        break;
      }
      case 'hide': {
        this.remove.emit();
        break;
      }
    }
  }

  public onRemove(): void {
    this.state = 'hide';
  }
}
