angular.module('settings').factory('Repositories::Permission', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getAllByPoolIdQuery =
        'query($pool_id: Int!) {' +
        '  pool(pool_id: $pool_id) {' +
        '    permissions {' +
        '      user {id name email} role subject {id name}' +
        '    }' +
        '    subjects {id name}' +
        '  }' +
        '  users {id name email}' +
        '}',
      getAllByUserIdQuery =
        'query($user_id: Int!) {' +
        '  user(user_id: $user_id) {' +
        '    permissions {pool {id name subjects {id name}} role subject {id name}}' +
        '  }' +
        '  pools {id name subjects {id name}}' +
        '}',
      updateQuery =
        'mutation($source_permission: PermissionInput!, $target_permission: PermissionInput!) {' +
        '  update_permission(source_permission: $source_permission, target_permission: $target_permission) {' +
        '    permission {user {id name email} role subject {name}}' +
        '    erroneous' +
        '  }' +
        '}',
      deleteQuery =
        'mutation($permission: PermissionInput!) {' +
        '  delete_permission(permission: $permission) {' +
        '    permission {user {id name email} role subject {name}}' +
        '    erroneous' +
        '  }' +
        '}',
      createQuery =
        'mutation($permission: PermissionInput!) {' +
        '  create_permission(permission: $permission) {' +
        '    permission {user {id name email} role subject {name}}' +
        '    erroneous' +
        '  }' +
        '}';

    return {
      getAllByPoolId: function(poolId) {
        return client.query(getAllByPoolIdQuery, { pool_id: poolId }).then(function(response) {
          return {
            permissions: response.data.data.pool.permissions,
            subjects: response.data.data.pool.subjects,
            users: response.data.data.users
          };
        });
      },

      getAllByUserId: function(userId) {
        return client.query(getAllByUserIdQuery, { user_id: userId }).then(function(response) {
          return {
            permissions: response.data.data.user.permissions,
            pools: response.data.data.pools
          };
        });
      },

      update: function(sourcePermission, targetPermission) {
        return client
          .query(updateQuery, {
            source_permission: {
              pool_id: sourcePermission.pool_id,
              user_id: sourcePermission.user_id,
              role: sourcePermission.role,
              subject_id: sourcePermission.subject_id
            },
            target_permission: {
              pool_id: targetPermission.pool_id,
              user_id: targetPermission.user_id,
              role: targetPermission.role,
              subject_id: targetPermission.subject_id
            }
          })
          .then(function(response) {
            return response.data.data.update_permission;
          });
      },

      delete: function(permission) {
        return client
          .query(deleteQuery, {
            permission: {
              pool_id: permission.pool_id,
              user_id: permission.user_id,
              role: permission.role,
              subject_id: permission.subject_id
            }
          })
          .then(function(response) {
            return response.data.data.delete_permission;
          });
      },

      create: function(permission) {
        return client
          .query(createQuery, {
            permission: {
              pool_id: permission.pool_id,
              user_id: permission.user_id,
              role: permission.role,
              subject_id: permission.subject_id
            }
          })
          .then(function(response) {
            return response.data.data.create_permission;
          });
      }
    };
  }
]);
