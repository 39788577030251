import {
  Directive,
  AfterViewInit,
  Input,
  ElementRef,
  HostListener,
  OnDestroy
} from '@angular/core';
import { isDefined } from '../utils/type-guards/is-defined';

type CollapsableElement = {
  item: HTMLElement & { currentStyle: CSSStyleDeclaration };
  width: number;
};

export enum CSS_VISIBILITY {
  VISIBLE = 'visible',
  HIDDEN = 'hidden'
}

@Directive({
  selector: '[collapsable]'
})
export class CollapsableDirective implements OnDestroy, AfterViewInit {
  @Input()
  public collapsable: HTMLElement;

  @Input()
  public toggleButton?: HTMLElement;

  @Input()
  public collapsedPosition: 'left' | 'right' = 'left';

  @Input()
  public buttonContent = '';

  private readonly margin = 250;
  private readonly items: CollapsableElement[] = [];
  private readonly watchers: Element[] = [];
  private collapsedItems: CollapsableElement[] = [];

  constructor(private readonly el: ElementRef) {}

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.rearrange();
  }

  public ngAfterViewInit(): void {
    this.collapsable.classList.add('collapsable-target');

    for (const item of this.el.nativeElement.children) {
      this.items.push({
        item: item,
        width: getWidth(item)
      });
    }

    this.rearrange();

    document.querySelectorAll('.collapsable-watcher').forEach(toWatch => {
      toWatch.addEventListener('click', this.rearrangeCallback);
      toWatch.addEventListener('resize', this.rearrangeCallback);
      this.watchers.push(toWatch);
    });
  }

  public ngOnDestroy(): void {
    this.watchers.forEach(watcher => {
      watcher.removeEventListener('click', this.rearrangeCallback);
      watcher.removeEventListener('resize', this.rearrangeCallback);
    });
  }

  // indirection over arrow function needed to ensure access to "this"
  private readonly rearrangeCallback: () => void = () => this.rearrange();

  private rearrange(): void {
    this.el.nativeElement.parentElement.style.display = 'block';
    this.el.nativeElement.style.visibility = CSS_VISIBILITY.HIDDEN;
    this.collapsedItems = [];

    const maxWidth: number = getWidth(this.el.nativeElement) - this.margin;

    let enoughSpace = true;
    let summedWidth = 0;
    this.items.forEach(item => {
      if (enoughSpace && summedWidth + item.width <= maxWidth) {
        summedWidth += item.width;
      } else {
        enoughSpace = false;
        this.collapsedItems.push(item);
      }
    });

    this.moveToCollapsed();
    this.el.nativeElement.style.visibility = CSS_VISIBILITY.VISIBLE;
    this.el.nativeElement.parentElement.style.display = 'flex';

    if (isDefined(this.toggleButton)) {
      this.toggleButton.style.visibility =
        this.collapsedItems.length === 0
          ? CSS_VISIBILITY.HIDDEN
          : CSS_VISIBILITY.VISIBLE;
    }
  }

  private moveToCollapsed(): void {
    // remove buttons
    this.collapsedItems.forEach(item => {
      this.collapsable.append(item.item);
    });

    // clear
    this.el.nativeElement.innerHTML = '';

    // add visible buttons
    this.items
      .filter(x => !this.collapsedItems.includes(x))
      .forEach(item => {
        this.el.nativeElement.append(item.item);
      });
  }
}

function getWidth(
  element: HTMLElement & { currentStyle: CSSStyleDeclaration }
): number {
  const style = element.currentStyle ?? window.getComputedStyle(element),
    width = element.offsetWidth,
    margin =
      Number.parseFloat(style.marginLeft) +
      Number.parseFloat(style.marginRight),
    border =
      Number.parseFloat(style.borderLeftWidth) +
      Number.parseFloat(style.borderRightWidth);

  return width + margin + border;
}
