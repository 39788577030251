import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentages'
})
export class PercentagesPipe implements PipeTransform {
  public transform(value: number[]): number[] {
    return value.map(v => v * 100);
  }
}
