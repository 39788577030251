import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateLabelMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  labelId: Types.Scalars['ID'];
  name: Types.Scalars['String'];
  color: Types.Scalars['String'];
}>;


export type UpdateLabelMutation = { __typename?: 'Mutation', updateLabel?: { __typename?: 'UpdateLabelPayload', successful: boolean } | null };

export const UpdateLabelDocument = gql`
    mutation UpdateLabel($poolId: ID!, $labelId: ID!, $name: String!, $color: String!) {
  updateLabel(poolId: $poolId, labelId: $labelId, name: $name, color: $color) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLabelGQL extends Apollo.Mutation<UpdateLabelMutation, UpdateLabelMutationVariables> {
    document = UpdateLabelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }