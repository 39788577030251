<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_management.comment_modal.title' | translate }}</h3>
  </div>
  <div class="modal-body">
    <textarea
      autofocus
      [(ngModel)]="comment"
      class="form-control"
      placeholder="{{
        'question_management.comment_modal.form.placeholder' | translate
      }}"
    ></textarea>
  </div>
  <div class="modal-footer">
    <button (click)="dismiss()" class="btn btn-secondary-migrated">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      (click)="save()"
      [disabled]="isSaving || !comment"
      class="btn btn-primary-migrated"
    >
      {{ 'common.actions.save' | translate }}
    </button>
  </div>
</div>
