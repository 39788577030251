<h5>
  {{
    'common.question_group_details.statistics.type_kprime_statistics.title'
      | translate
  }}
</h5>
<b *ngIf="!validStatistics">
  {{
    'common.question_group_details.statistics.type_kprime_statistics.no_statistics'
      | translate
  }}
</b>
<table class="table" *ngIf="validStatistics">
  <thead>
    <tr>
      <th>
        {{
          'common.question_group_details.statistics.response_options.response'
            | translate
        }}
      </th>
      <th>
        {{ 'common.question_group_details.statistics.table.p' | translate }}
      </th>
      <th>
        {{
          'common.question_group_details.statistics.response_options.solution'
            | translate
        }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container
      *ngTemplateOutlet="
        propositionRow;
        context: { option: 'A', stats: statistics.A }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        propositionRow;
        context: { option: 'B', stats: statistics.B }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        propositionRow;
        context: { option: 'C', stats: statistics.C }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        propositionRow;
        context: { option: 'D', stats: statistics.D }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        summaryRow;
        context: { name: 'fullPoints', stats: statistics.fullPoints }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        summaryRow;
        context: { name: 'halfPoints', stats: statistics.halfPoints }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        summaryRow;
        context: { name: 'noPoints', stats: statistics.noPoints }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        summaryRow;
        context: { name: 'empty', stats: statistics.empty }
      "
    >
    </ng-container>
  </tbody>
</table>

<ng-template #propositionRow let-option="option" let-stats="stats">
  <tr
    *ngIf="stats !== undefined && stats !== null"
    [ngClass]="{ selected: stats.solution }"
  >
    <td>
      {{ option }}
    </td>
    <td>{{ stats.p | percentage | fixedNumber : 1 }}</td>
    <td>{{ 'common.' + stats.solution | translate }}</td>
  </tr>
</ng-template>

<ng-template #summaryRow let-name="name" let-stats="stats">
  <tr *ngIf="stats !== undefined && stats !== null">
    <td>
      {{
        'common.question_group_details.statistics.type_kprime_statistics.statistics.' +
          name | translate
      }}
    </td>
    <td>
      {{ stats.p | percentage | fixedNumber : 1 }}
    </td>
    <td></td>
  </tr>
</ng-template>
