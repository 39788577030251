import { isValidNG2Route, ng2LinkParamsParser } from '../../routing-utils';

angular.module('main').config([
  '$httpProvider',
  '$sceProvider',
  '$locationProvider',
  '$stateProvider',
  '$urlRouterProvider',
  function($httpProvider, $sceProvider, $locationProvider, $stateProvider, $urlRouterProvider) {
    var authToken;
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    authToken = document.querySelector('meta[name="csrf-token"]');
    if (authToken) {
      $httpProvider.defaults.headers.common['X-CSRF-TOKEN'] = authToken.getAttribute('content');
    }

    $sceProvider.enabled(false);

    $stateProvider.state('invalid', {});

    // for any unmatched url in the angularjs router, route via ng2Router
    $urlRouterProvider.otherwise(($injector, $location) => {
      const $state = $injector.get('$state');
      const ng2Router = $injector.get('ng2Router');
      const url = $location.url();
      if (isValidNG2Route(url)) {
        $state.go('invalid');
        ng2Router.navigate(...ng2LinkParamsParser(url));
      } else {
        $state.go('/');
      }
    });

    $locationProvider.html5Mode(true);

    I18n.defaultLocale = 'en';
    I18n.locale = 'en';
  }
])
  .run([
    '$q',
    '$transitions',
    'Repositories::Pool',
    'Repositories::CurrentUser',
    'Actions::Pool',
    'Actions::User',
    'Selectors::User',
    'Store',
    '$rootScope',
    function($q, $transitions, PoolRepository, UserRepository,
      PoolActions, UserActions, UserSelectors, Store, $rootScope) {
      function setApplicationLanguage(userLanguage) {
        var language = localStorage.getItem('language') || userLanguage;
        Store.dispatch(UserActions.setLanguage(language));
      }

      // Matomo POC for analytics - track navigation on application. Hook into AngularJS routing events
      $rootScope.$on("$locationChangeStart", function(event, next, current) {
        // handle route changes
        if ( window['Matomo'] != undefined ) {
          var tracker =  window['Matomo'].getTracker();
          if ( tracker != undefined ) {
            tracker.trackLink(next, 'link');
          }
        }
      });

      Store.subscribeOn(UserSelectors.getLanguage, function (language) {
        if (language) {
          localStorage.setItem('language', language);

          I18n.locale = language;
        }
      });

        var remove = $transitions.onStart({}, function() {

          if (!isValidNG2Route(window.location.pathname)) {
          remove();
          var poolPromise = PoolRepository.getCurrentDetailed().then(function(pool) {
            Store.dispatch(PoolActions.setCurrentPool(pool));
          }),
          languagePromise = UserRepository.getCurrent().then(function (user) {
            setApplicationLanguage(user.language);
            Store.dispatch(UserActions.setCurrentUser(user));
          });

          return $q.all([poolPromise, languagePromise]);
        }});
      }

  ])
  .run([
    '$transitions',
    '$state',
    'Repositories::CurrentUser',
    '$injector',
    function($transitions, $state, CurrentUserRepository, $injector) {

        $transitions.onStart({}, function(transition) {
          if (!isValidNG2Route(window.location.pathname)) {
          return CurrentUserRepository.getCurrent().then(function(user) {
            var hasAccess = $injector.invoke(transition.to().authorize, this, { user: user });
            if (!hasAccess) {
              return transition.router.stateService.transitionTo('questionManagement');
            }
          });
        }});
      }

  ]);
