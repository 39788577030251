angular.module('common').factory('Actions::QuestionGroupNavigationList', function () {
  var actions = {
    ADD_IDS: 'QUESTION_GROUP_NAVIGATION_LIST_ADD_IDS'
  };

  function addIds (ids) {
    return {
      type: actions.ADD_IDS,
      ids: ids
    };
  }

  return Object.assign({
    addIds: addIds
  }, actions);
});
