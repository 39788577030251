import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'co-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmModalComponent {
  @Input() public modalInstance: NgbModalRef;
  @Input() public title?: string;
  @Input() public cancelButtonTranslationKey?: string;
  @Input() public confirmButtonTranslationKey?: string;
  @Input() public message?: string;

  public onDismiss(): void {
    this.modalInstance.dismiss();
  }

  public onConfirm(): void {
    this.modalInstance.close();
  }
}
