import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { QuestionGroupListComponent } from '../components/question-group-list/question-group-list.component';

angular.module('common').directive(
  'qmQuestionGroupList',
  downgradeComponent({
    component: QuestionGroupListComponent
  })
);
