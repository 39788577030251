import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type QuestionGroupPreviewQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupId: Types.Scalars['ID'];
}>;


export type QuestionGroupPreviewQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', questionGroup?: { __typename?: 'QuestionSequence', preview: { __typename?: 'QuestionGroupPreview', status: Types.PreviewGenerationStatus, url?: { __typename?: 'LanguageSpecificUrl', de?: string | null, fr?: string | null, en?: string | null, it?: string | null } | null } } | { __typename?: 'QuestionSeries', preview: { __typename?: 'QuestionGroupPreview', status: Types.PreviewGenerationStatus, url?: { __typename?: 'LanguageSpecificUrl', de?: string | null, fr?: string | null, en?: string | null, it?: string | null } | null } } | { __typename?: 'SingleQuestion', preview: { __typename?: 'QuestionGroupPreview', status: Types.PreviewGenerationStatus, url?: { __typename?: 'LanguageSpecificUrl', de?: string | null, fr?: string | null, en?: string | null, it?: string | null } | null } } | null } };

export const QuestionGroupPreviewDocument = gql`
    query QuestionGroupPreview($poolId: ID!, $questionGroupId: ID!) {
  pool(id: $poolId) {
    questionGroup(id: $questionGroupId) {
      preview {
        status
        url {
          de
          fr
          en
          it
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QuestionGroupPreviewGQL extends Apollo.Query<QuestionGroupPreviewQuery, QuestionGroupPreviewQueryVariables> {
    document = QuestionGroupPreviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }