import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetBlueprintMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  category0Id?: Types.InputMaybe<Types.Scalars['ID']>;
  category1Id?: Types.InputMaybe<Types.Scalars['ID']>;
  category2Id?: Types.InputMaybe<Types.Scalars['ID']>;
  category3Id?: Types.InputMaybe<Types.Scalars['ID']>;
  category4Id?: Types.InputMaybe<Types.Scalars['ID']>;
  category5Id?: Types.InputMaybe<Types.Scalars['ID']>;
  category6Id?: Types.InputMaybe<Types.Scalars['ID']>;
  category7Id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type SetBlueprintMutation = { __typename?: 'Mutation', setBlueprint?: { __typename?: 'SetBlueprintPayload', successful: boolean } | null };

export const SetBlueprintDocument = gql`
    mutation SetBlueprint($poolId: ID!, $questionGroupIds: [ID!]!, $category0Id: ID, $category1Id: ID, $category2Id: ID, $category3Id: ID, $category4Id: ID, $category5Id: ID, $category6Id: ID, $category7Id: ID) {
  setBlueprint(
    poolId: $poolId
    questionGroupIds: $questionGroupIds
    category0Id: $category0Id
    category1Id: $category1Id
    category2Id: $category2Id
    category3Id: $category3Id
    category4Id: $category4Id
    category5Id: $category5Id
    category6Id: $category6Id
    category7Id: $category7Id
  ) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetBlueprintGQL extends Apollo.Mutation<SetBlueprintMutation, SetBlueprintMutationVariables> {
    document = SetBlueprintDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }