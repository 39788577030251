<h5>
  {{
    'common.question_group_details.statistics.type_a_statistics.title'
      | translate
  }}
</h5>
<b *ngIf="!validStatistics">
  {{
    'common.question_group_details.statistics.type_a_statistics.no_statistics'
      | translate
  }}
</b>
<table class="table" *ngIf="validStatistics">
  <thead>
    <tr>
      <th>
        {{
          'common.question_group_details.statistics.response_options.response'
            | translate
        }}
      </th>
      <th>
        {{ 'common.question_group_details.statistics.table.p' | translate }}
      </th>
      <th>
        {{
          'common.question_group_details.statistics.response_options.solution'
            | translate
        }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'A', stats: statistics.A }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'B', stats: statistics.B }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'C', stats: statistics.C }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'D', stats: statistics.D }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'E', stats: statistics.E }
      "
    >
    </ng-container>
    <tr *ngIf="statistics.empty !== undefined && statistics.empty !== null">
      <td>
        {{
          'common.question_group_details.statistics.type_a_statistics.statistics.empty'
            | translate
        }}
      </td>
      <td>
        {{ statistics.empty.p | percentage | fixedNumber : 1 }}
      </td>
      <td></td>
    </tr>
  </tbody>
</table>

<ng-template #responseOptionRow let-option="option" let-stats="stats">
  <tr
    *ngIf="stats !== undefined && stats !== null"
    [ngClass]="{ selected: stats.solution }"
  >
    <td>
      {{ option }}
    </td>
    <td>{{ stats.p | percentage | fixedNumber : 1 }}</td>
    <td>{{ 'common.' + stats.solution | translate }}</td>
  </tr>
</ng-template>
