import { MoveDirection } from 'src/generated/base-types';

export function getDirectionFromIndices(
  dragIndex: number,
  dropIndex: number
): MoveDirection {
  return dragIndex < dropIndex ? MoveDirection.Down : MoveDirection.Up;
}

export function getMoveQuantity(dragIndex: number, dropIndex: number): number {
  return Math.abs(dragIndex - dropIndex);
}
