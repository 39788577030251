import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetRevisionYearMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  revisionYear?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SetRevisionYearMutation = { __typename?: 'Mutation', setRevisionYear?: { __typename?: 'SetRevisionYearPayload', successful: boolean } | null };

export const SetRevisionYearDocument = gql`
    mutation SetRevisionYear($poolId: ID!, $questionGroupIds: [ID!]!, $revisionYear: Int) {
  setRevisionYear(
    poolId: $poolId
    questionGroupIds: $questionGroupIds
    revisionYear: $revisionYear
  ) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetRevisionYearGQL extends Apollo.Mutation<SetRevisionYearMutation, SetRevisionYearMutationVariables> {
    document = SetRevisionYearDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }