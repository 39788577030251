import { UserInput } from '../../../generated/base-types';
import { LoadUsersFieldsFragment } from '../../admin/services/load-users.fragments.generated';
import { ManagerError } from '../../common/utils/error-parser';

export class LoadProfile {
  public static readonly type = '[Profile] Load Profile';
}

export class LoadProfileSuccess {
  public static readonly type = '[Profile] Load Profile Success';
  constructor(public readonly profile: LoadUsersFieldsFragment) {}
}

export class LoadProfileFailure {
  public static readonly type = '[Profile] Load Profile Failure';
  constructor(public readonly error: ManagerError) {}
}

export class UpdateProfile {
  public static readonly type = '[Profile] Update Profile';
  constructor(public readonly attributes: UserInput) {}
}

export class UpdateProfileSuccess {
  public static readonly type = '[Profile] Update Profile Success';
  constructor(public readonly profile: LoadUsersFieldsFragment) {}
}

export class UpdateProfileFailure {
  public static readonly type = '[Profile] Update Profile Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly attributes: UserInput
  ) {}
}
