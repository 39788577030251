import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LabelValuePair } from 'src/app/common/ng-select-wrapper/ng-select-wrapper.component';
import { Scalars } from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { OriginReference } from '../../legacy.types';
import { Notifications } from '../../services/notifications';
import { UiRouterService } from '../../services/ui-router.service';
import { assertIsDefined } from '../../utils/type-guards/is-defined';
import { isVoid } from '../../utils/type-guards/voidable';
import {
  DuplicateQuestionDetailsGQL,
  PoolNameAndIdFragment
} from './duplicate-question-details.generated';
import { DuplicateQuestionGroupGQL } from './duplicate-question-group.generated';

export enum DuplicationTargetPool {
  Other = 'other',
  Current = 'current'
}

@Component({
  selector: 'co-duplicate-question-group-modal',
  templateUrl: './duplicate-question-group-modal.component.html'
})
export class DuplicateQuestionGroupModalComponent implements OnInit {
  @Input()
  public modalInstance: NgbModalRef;

  @Input()
  public currentPoolId: Scalars['ID'];

  @Input()
  public questionGroups: QuestionGroupListElementFragment[];

  public mode = DuplicationTargetPool.Current;
  public modes = DuplicationTargetPool;
  public pools: LabelValuePair<PoolNameAndIdFragment>[];
  public currentPool: PoolNameAndIdFragment;
  public targetPool?: LabelValuePair<PoolNameAndIdFragment>;
  public questionGroup: QuestionGroupListElementFragment;

  constructor(
    private readonly translate: TranslateService,
    private notifications: Notifications,
    private readonly uiRouterService: UiRouterService,
    private duplicateQuestionDetailsGQL: DuplicateQuestionDetailsGQL,
    private duplicateQuestionGroupGQL: DuplicateQuestionGroupGQL
  ) {}

  public async ngOnInit(): Promise<void> {
    const response = await this.duplicateQuestionDetailsGQL.fetch().toPromise();
    assertIsDefined(
      response?.data?.currentUser,
      'Current user must be defined'
    );

    this.pools = response.data.currentUser.pools
      .map(pool => {
        return { label: pool.name, value: pool };
      })
      .filter(pool => pool.value.id !== this.currentPoolId);

    this.currentPool = response.data.currentUser.pools.find(
      pools => pools.id === this.currentPoolId
    ) as PoolNameAndIdFragment;
    this.questionGroup = this.questionGroups[0];
  }

  public setMode(mode: DuplicationTargetPool): void {
    this.targetPool = undefined;
    this.mode = mode;
  }

  public setPool(value: PoolNameAndIdFragment): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.targetPool = this.pools.find(
      (pool: LabelValuePair<PoolNameAndIdFragment>) => {
        return (
          (pool.value as PoolNameAndIdFragment).id ===
          (value as PoolNameAndIdFragment).id
        );
      }
    )!;
  }

  public async duplicate(): Promise<void> {
    if (this.mode === DuplicationTargetPool.Current) {
      this.modalInstance.close();
      this.uiRouterService.go('duplicateQuestionForm', {
        // eslint-disable-next-line camelcase
        copy_of: this.questionGroup.id,
        ref: OriginReference.QuestionManagement
      });
    } else if (this.mode === DuplicationTargetPool.Other) {
      if (isVoid(this.targetPool)) {
        throw 'No target pool set!'; // this should not happen
      }

      const result = await this.duplicateQuestionGroupGQL
        .mutate({
          poolId: this.currentPoolId,
          questionGroupId: this.questionGroup.id,
          targetPoolId: (this.targetPool?.value as PoolNameAndIdFragment).id
        })
        .toPromise();
      if (result?.data?.duplicateQuestionGroup?.successful !== true) {
        throw `Unable to duplicate question group '${
          this.questionGroup.id
        }' to pool '${(this.targetPool?.value as PoolNameAndIdFragment).id}'`;
      }

      this.modalInstance.close();
      this.notifications.addSuccess(
        this.translate.instant(
          'common.duplicate_question_group_modal.other_pool.duplicated',
          {
            sequentialNumber: this.questionGroup.sequentialNumber,
            poolName: this.targetPool?.label
          }
        )
      );
    }
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }
}
