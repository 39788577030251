import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { isDefined } from 'angular';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { takeGraphQLResult } from 'src/app/common/operators/take-graphql-response';
import { ToastsService } from 'src/app/common/services/toasts.service';
import { parseError } from 'src/app/common/utils/error-parser';
import { RemoteData, RequestState } from 'src/app/common/utils/remote-data';
import { requestStateLoading } from 'src/app/common/utils/remote-data-utils';
import { assertIsDefined } from 'src/app/common/utils/type-guards/is-defined';
import { DeletePermissionGQL } from '../../../services/delete-permission.generated';
import { PoolPermissionsFragment } from '../../../services/load-pool-permissions.fragments.generated';
import { LoadPoolPermissionsGQL } from '../../../services/load-pool-permissions.generated';
import { UpdatePermissionGQL } from '../../../services/update-permission.generated';
import { PoolFormState } from '../form/pool-form.state';
import {
  DeletePoolPermission,
  DeletePoolPermissionFailure,
  DeletePoolPermissionSuccess,
  LoadPoolPermissions,
  LoadPoolPermissionsFailure,
  LoadPoolPermissionsSuccess,
  UpdatePoolPermission,
  UpdatePoolPermissionFailure,
  UpdatePoolPermissionSuccess
} from './permissions.actions';

export type PoolPermissionsStateModel = {
  permissions: RemoteData<PoolPermissionsFragment[]>;
  mutation?: RemoteData<PoolPermissionsFragment>;
};

@State<PoolPermissionsStateModel>({
  name: 'poolPermissions',
  defaults: {
    permissions: { requestState: 'initial' }
  }
})
@Injectable({ providedIn: 'root' })
export class PermissionsState {
  constructor(
    private readonly updatePoolPermissionService: UpdatePermissionGQL,
    private readonly loadPoolPermissionsService: LoadPoolPermissionsGQL,
    private readonly deletePoolPermissionService: DeletePermissionGQL,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService,
    private readonly store: Store
  ) {}

  @Selector()
  public static permissions(
    state: PoolPermissionsStateModel
  ): RemoteData<PoolPermissionsFragment[]> {
    return { ...state.permissions, actions: { retry: LoadPoolPermissions } };
  }

  @Selector()
  public static permissionsLoading(state: PoolPermissionsStateModel): boolean {
    return requestStateLoading(state.permissions);
  }

  @Selector()
  public static mutationRequestState(
    state: PoolPermissionsStateModel
  ): RequestState | undefined {
    return state.mutation?.requestState;
  }

  @Action(LoadPoolPermissions)
  public loadPoolPermissions(
    ctx: StateContext<PoolPermissionsStateModel>
  ): Observable<void> {
    const pool = this.store.selectSnapshot(PoolFormState.pool);
    assertIsDefined(pool.data, 'Pool not found');
    const poolId = pool.data.id;

    ctx.patchState({ permissions: { requestState: 'loading' } });

    return this.loadPoolPermissionsService.fetch({ poolId: poolId }).pipe(
      takeGraphQLResult(),
      switchMap(result =>
        ctx.dispatch(new LoadPoolPermissionsSuccess(result.pool.permissions))
      ),
      catchError((err: unknown) => {
        return ctx.dispatch(new LoadPoolPermissionsFailure(parseError(err)));
      })
    );
  }

  @Action(LoadPoolPermissionsSuccess)
  public loadPoolPermissionsSuccess(
    ctx: StateContext<PoolPermissionsStateModel>,
    { poolPermissions }: LoadPoolPermissionsSuccess
  ): void {
    ctx.patchState({
      permissions: {
        requestState: 'success',
        data: poolPermissions
      }
    });
  }

  @Action(LoadPoolPermissionsFailure)
  public loadPoolPermissionsFailure(
    ctx: StateContext<PoolPermissionsStateModel>,
    { error }: LoadPoolPermissionsFailure
  ): void {
    ctx.patchState({
      permissions: {
        requestState: 'failure',
        error
      }
    });
  }

  @Action(UpdatePoolPermission)
  public updatePoolPermission(
    ctx: StateContext<PoolPermissionsStateModel>,
    { id, attributes }: UpdatePoolPermission
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.updatePoolPermissionService.mutate({ id, attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.updatePermission?.permission),
      filter(isDefined),
      switchMap(permission =>
        ctx.dispatch(new UpdatePoolPermissionSuccess(permission))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new UpdatePoolPermissionFailure(parseError(err)))
      )
    );
  }

  @Action(UpdatePoolPermissionSuccess)
  public updatePoolPermissionSuccess(
    ctx: StateContext<PoolPermissionsStateModel>,
    { permission }: UpdatePoolPermissionSuccess
  ): void {
    ctx.patchState({
      permissions: {
        ...ctx.getState().permissions,
        data: ctx
          .getState()
          .permissions.data?.map(existingPermission =>
            existingPermission.id === permission.id
              ? permission
              : existingPermission
          )
      },
      mutation: { requestState: 'success', data: permission }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('common.models.permission')
      })
    );
  }

  @Action(UpdatePoolPermissionFailure)
  public updatePoolPermissionFailure(
    ctx: StateContext<PoolPermissionsStateModel>,
    { error }: UpdatePoolPermissionFailure
  ): void {
    ctx.patchState({
      mutation: { requestState: 'failure', error }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(DeletePoolPermission)
  public deletePoolPermission(
    ctx: StateContext<PoolPermissionsStateModel>,
    { id }: DeletePoolPermission
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.deletePoolPermissionService.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeletePoolPermissionSuccess(id))),
      catchError((err: unknown) =>
        ctx.dispatch(new DeletePoolPermissionFailure(parseError(err)))
      )
    );
  }

  @Action(DeletePoolPermissionSuccess)
  public deletePoolPermissionSuccess(
    ctx: StateContext<PoolPermissionsStateModel>,
    { id }: DeletePoolPermissionSuccess
  ): void {
    ctx.patchState({
      permissions: {
        ...ctx.getState().permissions,
        data: ctx
          .getState()
          .permissions.data?.filter(permission => permission.id !== id)
      },
      mutation: { data: undefined, requestState: 'success' }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('activerecord.models.permission')
      })
    );
  }

  @Action(DeletePoolPermissionFailure)
  public deletePoolPermissionFailure(
    ctx: StateContext<PoolPermissionsStateModel>,
    { error }: DeletePoolPermissionFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }
}
