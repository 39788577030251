angular.module('common').factory('QuestionGroupActions', [
  '$http',
  'GraphQLClient',
  'Store',
  'Actions::QuestionGroup',
  function($http, GraphQLClient, Store, Actions) {
    var QuestionGroupActions = {},
      client = new GraphQLClient('/api/v1/graphql'),
      setRevisionStatusQuery =
        'mutation($question_group_ids: [Int!]!, $revision_status_id: Int) {' +
        '  set_revision_status(question_group_ids: $question_group_ids, revision_status_id: $revision_status_id) {' +
        '    question_groups {id revision_status { id short_name }} erroneous' +
        '  }' +
        '}',
      assignExamsQuery =
        'mutation($question_group_ids: [Int!]!, $exam_ids_to_add: [Int!], $exam_ids_to_remove: [Int!]) {' +
        '  assign_exams(question_group_ids: $question_group_ids, exam_ids_to_add: $exam_ids_to_add, exam_ids_to_remove: $exam_ids_to_remove) {' +
        '   question_groups {id future_exams {id name}} erroneous' +
        '  }' +
        '}',
      createCommentQuery =
        'mutation($question_group_ids: [Int!]!, $content: String!) {' +
        '  create_comment(question_group_ids: $question_group_ids, content: $content) {' +
        '    question_groups {id} erroneous' +
        '  }' +
        '}',
      assignLabelsQuery =
        'mutation($question_group_ids: [Int!]!, $label_ids_to_add: [Int!], $label_ids_to_remove: [Int!]) {' +
        '  assign_labels(question_group_ids: $question_group_ids, label_ids_to_add: $label_ids_to_add, label_ids_to_remove: $label_ids_to_remove) {' +
        '    question_groups {id labels {id name color}} erroneous' +
        '  }' +
        '}',
      setSupervisorQuery =
        'mutation($question_group_ids: [Int!]!, $user: String!) {' +
        '  set_supervisor(question_group_ids: $question_group_ids, user: $user) {' +
        '    question_groups {id supervisor structurally_complete} erroneous' +
        '  }' +
        '}',
      setAffiliationQuery =
        'mutation($question_group_ids: [Int!]!, $affiliation_id: Int) {' +
        '  set_affiliation(question_group_ids: $question_group_ids, affiliation_id: $affiliation_id) {' +
        '    question_groups {id affiliation { name } structurally_complete} erroneous' +
        '  }' +
        '}',
      setRevisionYearQuery =
        'mutation($question_group_ids: [Int!]!, $revision_year: Int) {' +
        '  set_revision_year(question_group_ids: $question_group_ids, revision_year: $revision_year) {' +
        '    question_groups {id revision_year} erroneous' +
        '  }' +
        '}',
      setSourceLanguageQuery =
        'mutation($question_group_ids: [Int!]!, $source_language: String!) {' +
        '  set_source_language(question_group_ids: $question_group_ids, source_language: $source_language) {' +
        '    question_groups {id source_language} erroneous' +
        '  }' +
        '}',
      setBlueprintQuery =
        'mutation($question_group_ids: [Int!]!, $category_0_id: Int, $category_1_id: Int, $category_2_id: Int, $category_3_id: Int, ' +
        '  $category_4_id: Int, $category_5_id: Int, $category_6_id: Int, $category_7_id: Int) {' +
        '  set_blueprint(question_group_ids: $question_group_ids, category_0_id: $category_0_id, category_1_id: $category_1_id, category_2_id: $category_2_id, category_3_id: $category_3_id, ' +
        '    category_4_id: $category_4_id, category_5_id: $category_5_id, category_6_id: $category_6_id, category_7_id: $category_7_id) {' +
        '      question_groups {' +
        '        id category_0 { name } category_1 { name } category_2 { name } category_3 { name }' +
        '        category_4 { name } category_5 { name } category_6 { name } category_7 { name }' +
        '      } erroneous' +
        '  }' +
        '}',
      reloadQuery =
        'query($ids: [Int!]!) {question_groups(ids: $ids) {id title revision_status { id short_name } }}',
      getWordDiffQuery =
        'query($left_id: Int!, $right_id: Int!) {' +
        '  question_group_diff(left_id: $left_id, right_id: $right_id) {name word}' +
        '}',
      deleteStatisticsQuery =
        'mutation($question_group_ids: [Int!]!) {' +
        '  delete_statistics(question_group_ids: $question_group_ids) { ' +
        '    question_groups {' +
        '      id updated_at latest_statistic cache_last_usage_n cache_last_usage_p ' +
        '      cache_last_usage_r cache_last_usage_eliminated cache_last_usage_lne ' +
        '      content_changed_since_last_usage metadata_changed_since_last_usage ' +
        '    } erroneous' +
        '  }' +
        '}',
      getLabelsQuery =
        'query($ids: [Int!]!) {' +
        '  question_groups(ids: $ids) { id labels { id name color } }' +
        '}';

    QuestionGroupActions.setRevisionStatus = function(questionGroups, revisionStatusId) {
      return client
        .query(setRevisionStatusQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          }),
          revision_status_id: revisionStatusId
        })
        .then(function(response) {
          Store.dispatch(
            Actions.updateQuestionGroups(response.data.data.set_revision_status.question_groups)
          );
          return response;
        });
    };

    QuestionGroupActions.assignExams = function(questionGroups, examIdsToAdd, examIdsToRemove) {
      return client
        .query(assignExamsQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          }),
          exam_ids_to_add: examIdsToAdd,
          exam_ids_to_remove: examIdsToRemove
        })
        .then(function(response) {
          Store.dispatch(
            Actions.updateQuestionGroups(response.data.data.assign_exams.question_groups)
          );
          return response;
        });
    };

    QuestionGroupActions.createComment = function(questionGroups, content) {
      return client
        .query(createCommentQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          }),
          content: content
        })
        .then(function(response) {
          Store.dispatch(
            Actions.updateQuestionGroups(response.data.data.create_comment.question_groups)
          );
          return response;
        });
    };

    QuestionGroupActions.assignLabels = function(questionGroups, labelIdsToAdd, labelIdsToRemove) {
      return client
        .query(assignLabelsQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          }),
          label_ids_to_add: labelIdsToAdd,
          label_ids_to_remove: labelIdsToRemove
        })
        .then(function(response) {
          Store.dispatch(
            Actions.updateQuestionGroups(response.data.data.assign_labels.question_groups)
          );
          return response;
        });
    };

    QuestionGroupActions.setSupervisor = function(questionGroups, user) {
      return client
        .query(setSupervisorQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          }),
          user: user
        })
        .then(function(response) {
          Store.dispatch(
            Actions.updateQuestionGroups(response.data.data.set_supervisor.question_groups)
          );
          return response;
        });
    };

    QuestionGroupActions.deleteStatistics = function(questionGroups) {
      return client
        .query(deleteStatisticsQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          })
        })
        .then(function(response) {
          Store.dispatch(Actions.updateQuestionGroups(response.data.data.delete_statistics.question_groups));
          return response;
        });
    };

    QuestionGroupActions.setAffiliation = function(questionGroups, affiliationId) {
      return client
        .query(setAffiliationQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          }),
          affiliation_id: affiliationId
        })
        .then(function(response) {
          Store.dispatch(
            Actions.updateQuestionGroups(response.data.data.set_affiliation.question_groups)
          );
          return response;
        });
    };

    QuestionGroupActions.setRevisionYear = function(questionGroups, revisionYear) {
      return client
        .query(setRevisionYearQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          }),
          revision_year: revisionYear
        })
        .then(function(response) {
          Store.dispatch(
            Actions.updateQuestionGroups(response.data.data.set_revision_year.question_groups)
          );
          return response;
        });
    };

    QuestionGroupActions.setSourceLanguage = function(questionGroups, sourceLanguage) {
      return client
        .query(setSourceLanguageQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          }),
          source_language: sourceLanguage
        })
        .then(function(response) {
          Store.dispatch(Actions.updateQuestionGroups(response.data.data.set_source_language.question_groups));
          return response;
        });
    };

    QuestionGroupActions.setBlueprint = function(questionGroups, blueprint) {
      return client
        .query(setBlueprintQuery, {
          question_group_ids: questionGroups.map(function(q) {
            return q.id;
          }),
          category_0_id: blueprint.category_0_id,
          category_1_id: blueprint.category_1_id,
          category_2_id: blueprint.category_2_id,
          category_3_id: blueprint.category_3_id,
          category_4_id: blueprint.category_4_id,
          category_5_id: blueprint.category_5_id,
          category_6_id: blueprint.category_6_id,
          category_7_id: blueprint.category_7_id
        })
        .then(function(response) {
          Store.dispatch(Actions.updateQuestionGroups(response.data.data.set_blueprint.question_groups));
          return response;
        });
    };

    QuestionGroupActions.reload = function(questionGroups) {
      return client
        .query(reloadQuery, {
          ids: questionGroups.map(function(q) {
            return q.id;
          })
        })
        .then(function(response) {
          Store.dispatch(Actions.updateQuestionGroups(response.data.data.question_groups));
          return response;
        });
    };

    QuestionGroupActions.reloadLabels = function(questionGroups) {
      return client
        .query(getLabelsQuery, {
          ids: questionGroups.map(function(q) {
            return q.id;
          })
        })
        .then(function(response) {
          Store.dispatch(Actions.updateQuestionGroups(response.data.data.question_groups));
          return response;
        });
    };

    QuestionGroupActions.getWordDiff = function(questionGroup1, questionGroup2) {
      return client
        .query(getWordDiffQuery, {
          left_id: questionGroup1.id,
          right_id: questionGroup2.id
        })
        .then(function(response) {
          return response.data.data.question_group_diff;
        });
    };

    return QuestionGroupActions;
  }
]);
