(function() {
  angular.module('examManagement').component('examQuestionGroupList', {
    template: require('./exam_question_group_list.html'),
    controller: ExamQuestionGroupListController
  });

  ExamQuestionGroupListController.$inject = [
    '$state',
    '$timeout',
    'ConfirmMessages',
    'SingleSelection',
    'PageBreakDeterminator',
    'Numerator',
    'notifications',
    'ConfirmationModal',
    'QuestionGroupNavigationList',
    'TableColumnVisibilityFunctionality',
    'Selectors::ExamQuestionGroupList',
    'Store',
    'Selectors::ExamList',
    'Actions::ExamQuestionGroupList',
    'Repositories::ExamQuestionGroup',
    'Selectors::Pool',
    '$scope'
  ];

  function ExamQuestionGroupListController(
    $state,
    $timeout,
    ConfirmMessages,
    SingleSelection,
    PageBreakDeterminator,
    Numerator,
    notifications,
    ConfirmationModal,
    QuestionGroupNavigationList,
    TableColumnVisibilityFunctionality,
    Selectors,
    Store,
    ExamSelectors,
    Actions,
    ExamQuestionGroupRepository,
    PoolSelectors,
    $scope
  ) {
    var sortableElement = void 0;
    var addExamBookNumbers,
      checkExamLocking,
      constructPositionData,
      getPositionAttribute;

    function loadList(exam) {
      Store.dispatch(Actions.fetchExamQuestionGroupClustersRequest());
      ExamQuestionGroupRepository.getAll(exam.id).then(function(data) {
        Store.dispatch(Actions.fetchExamQuestionGroupClustersSuccess(data));
      });
    }

    this.I18n = I18n;
    this.items = [];
    this.stats = {
      numberOfSingleQuestions: 0,
      numberOfQuestionGroups: 0,
      totalScore: 0
    };
    this.dimensions = [];
    this.examId = undefined;
    this.selection = new SingleSelection(
      Actions.setSelection,
      Actions.removeSelection,
      Selectors.getSelection,
      Selectors.getFlatItems,
      'examQuestionGroupList.selection'
    );

    var poolSubscription = Store.subscribeOn(
      PoolSelectors.getCurrentPool,
      function(pool) {
        this.languages = pool ? pool.languages : [];
      }.bind(this)
    );

    var requestInProgressSubscription = Store.subscribeOn(
      Selectors.isRequestInProgress,
      function(inProgress) {
        this.requestInProgress = inProgress;
      }.bind(this)
    );

    getPositionAttribute = function() {
      var scrambling = Selectors.getScrambling(Store.getState());
      if (scrambling === 'B') {
        return 'position_b';
      } else {
        return 'position_a';
      }
    };

    function getSelectedExam() {
      return ExamSelectors.getSelectedItem(Store.getState());
    }

    var dataSubscription = Store.subscribeOn(
      Selectors.getFlatItems,
      function(items) {
        this.items = items;
      }.bind(this)
    );

    var statsSubscription = Store.subscribeOn(
      Selectors.getStats,
      function(stats) {
        this.stats = stats;
      }.bind(this)
    );

    var loadDataSubscription = Store.subscribeOn(
      ExamSelectors.getSelectedItem,
      function(exam) {
        if (angular.isDefined(exam)) {
          loadList(exam);
          this.dimensions = exam.dimensions;
        }
      }.bind(this)
    );

    addExamBookNumbers = function(items) {
      var numerator;
      if (!items) {
        return;
      }
      numerator = new Numerator(items);
      numerator.run();
    };

    var selectedExamSubscription = Store.subscribeOn(
      ExamSelectors.getSelection,
      function(examId) {
        if (examId) {
          this.examId = examId;
        }
      }.bind(this)
    );

    var updateExamBookNumbers = Store.subscribeOn(
      Selectors.getFlatItems,
      function(items) {
        addExamBookNumbers(items);
      }.bind(this)
    );

    $scope.$on(
      '$destroy',
      function() {
        poolSubscription();
        dataSubscription();
        statsSubscription();
        loadDataSubscription();
        updateExamBookNumbers();
        requestInProgressSubscription();
        selectedExamSubscription();
      }.bind(this)
    );

    constructPositionData = function() {
      var index = 0;

      return this.items.map(function(item) {
        index = index + 1;
        var o = {
          id: item.id
        };
        o[getPositionAttribute()] = index - 1;
        return o;
      });
    }.bind(this);

    this.sortableOptions = {
      isEnabled: function() {
        return getSelectedExam() && !getSelectedExam().locked;
      },
      start: function(event, tbody, row) {
        row.classList.add('pv-list__body__drag-item');
        tbody.querySelectorAll('tr').forEach(function(clusterRow) {
          clusterRow.classList.add('pv-list__body__drag-container');
        });
      },
      stop: function(event, tbody, row) {
        var data;
        row.classList.remove('pv-list__body__drag-item');
        tbody
          .querySelectorAll('.pv-list__body__drag-container')
          .forEach(function(r) {
            r.classList.remove('pv-list__body__drag-container');
          });

        data = constructPositionData();

        ExamQuestionGroupRepository.updatePositions(data).then(function(
          response
        ) {
          Store.dispatch(
            Actions.updateExamQuestionGroups(response.exam_question_groups)
          );
          addExamBookNumbers();
        });
      }
    };

    this.isLocked = function() {
      return getSelectedExam() && getSelectedExam().locked;
    };

    this.isHeadVersionDeleted = function(examQuestionGroup) {
      return examQuestionGroup.head_version_is_deleted;
    };

    this.isNotHeadVersion = function(examQuestionGroup) {
      return !examQuestionGroup.is_head_version;
    };

    this.isHeadVersion = function(examQuestionGroup) {
      return examQuestionGroup.is_head_version;
    };

    this.isLanguageSupported = function(language) {
      if (this.languages) {
        return this.languages.indexOf(language) !== -1;
      }
    };

    checkExamLocking = function(callback) {
      if (this.isLocked()) {
        notifications.addAlert(I18n.t('exams.exams_table.alert_cant_act'));
      } else {
        callback();
      }
    }.bind(this);

    this.delete = function(examQuestionGroup) {
      checkExamLocking(function() {
        ConfirmationModal.show(
          I18n.t('exams.title_infos.remove'),
          ConfirmMessages.questionGroupDeleteMessage(
            examQuestionGroup.question_group.sequential_number
          )
        ).result.then(function() {
          ExamQuestionGroupRepository.delete(examQuestionGroup.id).then(
            function() {
              Store.dispatch(Actions.removeSelection());
              Store.dispatch(
                Actions.deleteExamQuestionGroup(examQuestionGroup.id)
              );
            }
          );
        });
      });
    };

    this.toggleIcebreaker = function(examQuestionGroup) {
      checkExamLocking(function() {
        ExamQuestionGroupRepository.toggleIcebreaker(examQuestionGroup.id).then(
          function(data) {
            loadList(getSelectedExam());
          }
        );
      });
    };

    this.updateVersion = function(examQuestionGroup) {
      checkExamLocking(function() {
        ConfirmationModal.show(
          I18n.t('exams.update_version.modal.title'),
          ConfirmMessages.questionGroupUpdateVersion(
            examQuestionGroup.question_group.sequential_number
          )
        ).result.then(function() {
          ExamQuestionGroupRepository.updateVersion(examQuestionGroup.id).then(
            function() {
              loadList(getSelectedExam());
            }
          );
        });
      });
    };

    this.editQuestionGroup = function(item) {
      checkExamLocking(
        function() {
          if (this.isHeadVersion(item)) {
            var allIds = this.items
              .filter(
                function(item) {
                  return this.isHeadVersion(item);
                }.bind(this)
              )
              .map(function(item) {
                return item.question_group.id;
              });
            QuestionGroupNavigationList.set(allIds);
            $state.go('editQuestionForm', {
              id: item.question_group.id,
              ref: 'examManagement'
            });
          } else {
            notifications.addAlert(
              I18n.t('exams.question_groups_table.alert_cant_edit')
            );
          }
        }.bind(this)
      );
    }.bind(this);

    this.visibility = new TableColumnVisibilityFunctionality(
      Selectors.getColumnDefinitions
    );
  }
})();
