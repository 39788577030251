// helpers to convert our GraphQL sort structure from/to PrimeNGs SortMeta interface

import { SortMeta } from 'primeng/api';
import { SortDirection } from '../../../generated/base-types';

export interface GenericSortInput<T> {
  attribute: T;
  direction: SortDirection;
}

export function sortInputToPrimeNGSelect<T>(
  attributes: GenericSortInput<T>[]
): SortMeta[] {
  return attributes.map(attrs => {
    return {
      field: attrs.attribute as unknown as string,
      order: attrs.direction === SortDirection.Asc ? 1 : -1
    };
  });
}

export function primeNGSelectToSortInput<T>(
  attributes: SortMeta[]
): GenericSortInput<T>[] {
  return attributes.map(sortAttribute => {
    const direction =
      sortAttribute.order > 0 ? SortDirection.Asc : SortDirection.Desc;

    return {
      direction,
      attribute: sortAttribute.field as unknown as T
    };
  });
}
