import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FilterDimensionType } from '../../services/filter/filter-helper.service';
import { SimpleFilter } from '../../state/question-filter/question-filter.state.model';

@Component({
  selector: 'qm-filter-select',
  templateUrl: './filter-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSelectComponent {
  @Input() public name: string;
  @Input() public filter: SimpleFilter;
  @Input() public isOpen: boolean;
  @Output() public filterChange = new EventEmitter<SimpleFilter>();
  @Output() public isOpenChange = new EventEmitter<boolean>();

  public readonly filterID = Math.random().toString(36).slice(2, 6);

  public get isFullSelection(): boolean {
    return this.filter.selection.every(item => item === null);
  }

  public toggleFullSelection(): void {
    this.filter.selection = this.isFullSelection ? this.defaultSelection() : [];
  }

  public toggleOpen(): void {
    this.isOpenChange.emit(!this.isOpen);
  }

  public onFilterChange(
    value: SimpleFilter['selection'][number],
    index: number
  ): void {
    const selection = [...this.filter.selection];

    selection[index] = value;

    this.filterChange.emit({ ...this.filter, selection });
  }

  private defaultSelection(): SimpleFilter['selection'][number][] {
    const firstOption = this.filter.options[0] as FilterDimensionType;

    return [firstOption.items[1].value];
  }
}
