import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TotalStatisticTypeKprimeFragment } from 'src/app/question-management/services/question-group-statistics.generated';

@Component({
  selector: 'co-type-kprime-statistics',
  templateUrl: './type-kprime-statistics.component.html',
  styleUrls: ['./type-kprime-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeKprimeStatisticsComponent {
  @Input()
  public set statistics(value: TotalStatisticTypeKprimeFragment) {
    this._statistics = value;
    this.validStatistics =
      value.A !== undefined ||
      value.B !== undefined ||
      value.C !== undefined ||
      value.D !== undefined ||
      value.fullPoints !== undefined ||
      value.halfPoints !== undefined ||
      value.noPoints !== undefined ||
      value.empty !== undefined;
  }
  public get statistics(): TotalStatisticTypeKprimeFragment {
    return this._statistics;
  }

  public validStatistics = false;
  private _statistics: TotalStatisticTypeKprimeFragment;
}
