<div class="filter-group">
  <div class="filter-group__header">
    <input
      type="checkbox"
      [id]="filterID + '_root'"
      [checked]="isFullSelection"
      [indeterminate]="!isFullSelection"
      (click)="toggleFullSelection()"
    />
    <label [for]="filterID + '_root'">
      {{ name | translate | uppercase }}
    </label>
    <button class="btn btn-icon" type="button" (click)="toggleOpen()">
      <i class="fa fa-angle-down" [ngClass]="{ 'fa-rotate-180': !isOpen }"></i>
    </button>
  </div>
  <ol class="filter-group__options" *ngIf="isOpen && filter.options">
    <li *ngFor="let option of filter.options; let i = index" class="mt-4">
      <label> {{ i + 1 }}. </label>
      <ng-select-wrapper
        [value]="filter.selection[i]"
        [options]="$any(option).items"
        (valueChange)="onFilterChange($event, i)"
      >
      </ng-select-wrapper>
    </li>
  </ol>
</div>
