import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateRevisionStatusMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  attributes: Types.RevisionStatusInput;
}>;


export type CreateRevisionStatusMutation = { __typename?: 'Mutation', createRevisionStatus?: { __typename?: 'CreateRevisionStatusPayload', successful: boolean, revisionStatus?: { __typename?: 'RevisionStatus', id: string, description: string, shortName: string, position: number } | null, validations?: { __typename?: 'ValidationsRevisionStatus', description?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null, shortName?: Array<{ __typename?: 'ValidationsMessage', message: string, severity: Types.ValidationsSeverity }> | null } | null } | null };

export const CreateRevisionStatusDocument = gql`
    mutation CreateRevisionStatus($poolId: ID!, $attributes: RevisionStatusInput!) {
  createRevisionStatus(poolId: $poolId, attributes: $attributes) {
    revisionStatus {
      id
      description
      shortName
      position
    }
    successful
    validations {
      description {
        message
        severity
      }
      shortName {
        message
        severity
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRevisionStatusGQL extends Apollo.Mutation<CreateRevisionStatusMutation, CreateRevisionStatusMutationVariables> {
    document = CreateRevisionStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }