angular.module('examManagement').factory('SolutionCsvBuilder', [
  'notifications',
  function (notifications) {
    var columnDelimiter = ',',
      lineDelimiter = '\n',
      headers = [
        I18n.t('activerecord.attributes.question_group.sequential_number'),
        I18n.t('activerecord.attributes.question_group.position_in_group'),
        I18n.t('activerecord.attributes.question.type'),
        I18n.t('activerecord.attributes.question_group.id_in_book_a'),
        I18n.t('activerecord.attributes.question_group.id_in_book_b'),
        I18n.t('exams.solution_export.solution')
      ],
      attributes = [
        'sequentialNumber',
        'type',
        'positionBookA',
        'positionBookB',
        'solution'
      ];

    function arrayOfArraysToCSV(table) {
      return table
        .map(function (row) {
          return row.join(columnDelimiter);
        })
        .join(lineDelimiter);
    }

    function SolutionCsvBuilder(examQuestionGroups) {
      this.examQuestionGroups = examQuestionGroups;
    }

    SolutionCsvBuilder.prototype.build = function () {
      return this._toCSV();
    };

    SolutionCsvBuilder.prototype._hasOnlySingleQuestions = function () {
      return this.examQuestionGroups.every(function (examQuestionGroup) {
        return examQuestionGroup.question_group.type === 'SINGLE';
      });
    };

    SolutionCsvBuilder.prototype._toCSV = function () {
      const mappedData = this.examQuestionGroups.flatMap(examQuestionGroup => {
        const questions = examQuestionGroup.question_group.questions;

        return questions.map((question, index) => [
          examQuestionGroup.question_group.sequential_number,
          `(${index + 1}/${questions.length})`,
          question.type,
          examQuestionGroup.examBookNumberA,
          examQuestionGroup.examBookNumberB,
          question.solution_key
        ]);
      });

      return arrayOfArraysToCSV([headers].concat(mappedData));
    };

    return SolutionCsvBuilder;
  }
]);
