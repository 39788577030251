import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { Scalars } from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { LoadQuestionGroupMessages } from '../../../question-management/state/question-details.actions';
import { CreateCommentGQL } from './create-comment.generated';

@Component({
  selector: 'co-write-comment-modal',
  templateUrl: './write-comment-modal.component.html',
  styleUrls: ['./write-comment-modal.component.scss']
})
export class WriteCommentModalComponent implements OnInit, OnDestroy {
  @Input()
  public modalInstance: NgbModalRef;

  @Input()
  public currentPoolId: Scalars['ID'];

  @Input()
  public questionGroups: QuestionGroupListElementFragment[];

  public isSaving = false;
  public comment = '';
  public lastQuestionGroupId: Scalars['ID']; // this is the ID for which the comments are displayed

  private destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly createCommentGQL: CreateCommentGQL
  ) {}

  public ngOnInit(): void {
    this.lastQuestionGroupId =
      this.questionGroups[this.questionGroups.length - 1].id;
  }

  public async save(): Promise<void> {
    this.isSaving = true;
    const result = await this.createCommentGQL
      .mutate({
        poolId: this.currentPoolId,
        questionGroupIds: this.questionGroups.map(qg => qg.id),
        content: this.comment.trimEnd()
      })
      .toPromise();

    if (result?.data?.createComment?.successful !== true) {
      throw `Unable to create comment for ${this.questionGroups.map(
        qg => qg.id
      )}`;
    }

    this.store.dispatch(
      new LoadQuestionGroupMessages(this.lastQuestionGroupId)
    );
    this.isSaving = false;
    this.modalInstance.close();
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
