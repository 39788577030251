/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import {
  ObjectWithTranslatedContent,
  QuestionA,
  QuestionAMinus,
  QuestionE,
  QuestionFreeText,
  QuestionGroup,
  QuestionKprime,
  QuestionLongMenu
} from '../question-group';
import {
  acceptQuestion,
  acceptQuestionGroup,
  QuestionGroupVisitor
} from '../question-group-traversal';

const assetEndpointPrefix = '/api/v1/assets/';
const parser: DOMParser = new DOMParser();

function transformHTMLToInternalString(
  html: string | undefined
): string | undefined {
  if (html === undefined || html === null || html === '') {
    return html;
  }

  const doc = parser.parseFromString(html, 'text/html');
  doc.querySelectorAll('img').forEach(element => {
    const url = element.getAttribute('src');
    element.dataset.assetId = (url ?? '').replace(assetEndpointPrefix, '');
    element.removeAttribute('src');
  });

  return doc.body.innerHTML;
}

class QuestionContentTransformationVisitor implements QuestionGroupVisitor {
  public transformedQuestionGroup: QuestionGroup;

  constructor(
    private transformator: (content: string | undefined) => string | undefined
  ) {}

  public visitQuestionGroup(questionGroup: QuestionGroup): void {
    this.transformedQuestionGroup = {
      ...questionGroup,
      questions: []
    };
    questionGroup.questions.forEach(question => acceptQuestion(question, this));
  }

  public visitQuestionTypeA(question: QuestionA): void {
    this.transformedQuestionGroup.questions.push({
      ...question,
      ...this.transformTranslatedContent(question),
      response_options: question.response_options.map(option => {
        return {
          ...option,
          ...this.transformTranslatedContent(option)
        };
      })
    });
  }

  public visitQuestionTypeAMinus(question: QuestionAMinus): void {
    this.transformedQuestionGroup.questions.push({
      ...question,
      ...this.transformTranslatedContent(question),
      response_options: question.response_options.map(option => {
        return {
          ...option,
          ...this.transformTranslatedContent(option)
        };
      })
    });
  }

  public visitQuestionTypeKprime(question: QuestionKprime): void {
    this.transformedQuestionGroup.questions.push({
      ...question,
      ...this.transformTranslatedContent(question),
      response_options: question.response_options.map(option => {
        return {
          ...option,
          ...this.transformTranslatedContent(option)
        };
      })
    });
  }

  public visitQuestionTypeE(question: QuestionE): void {
    this.transformedQuestionGroup.questions.push({
      ...question,
      content_de_part_1: this.transformator(question.content_de_part_1),
      content_de_part_2: this.transformator(question.content_de_part_2),
      content_fr_part_1: this.transformator(question.content_fr_part_1),
      content_fr_part_2: this.transformator(question.content_fr_part_2),
      content_it_part_1: this.transformator(question.content_it_part_1),
      content_it_part_2: this.transformator(question.content_it_part_2),
      content_en_part_1: this.transformator(question.content_en_part_1),
      content_en_part_2: this.transformator(question.content_en_part_2),
      response_options: question.response_options.map(option => {
        return {
          ...option,
          ...this.transformTranslatedContent(option)
        };
      })
    });
  }

  public visitQuestionTypeFreeText(question: QuestionFreeText): void {
    this.transformedQuestionGroup.questions.push({
      ...question,
      ...this.transformTranslatedContent(question),
      open_response: {
        ...question.open_response,
        ...this.transformTranslatedContent(question.open_response)
      }
    });
  }

  public visitQuestionTypeLongMenu(question: QuestionLongMenu): void {
    this.transformedQuestionGroup.questions.push({
      ...question,
      ...this.transformTranslatedContent(question)
    });
  }

  private transformTranslatedContent(
    object: ObjectWithTranslatedContent
  ): ObjectWithTranslatedContent {
    return {
      content_de: this.transformator(object.content_de),
      content_fr: this.transformator(object.content_fr),
      content_it: this.transformator(object.content_it),
      content_en: this.transformator(object.content_en)
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class QuestionContentTransformatorService {
  public transformInternalRepresentationToHTML(
    questionGroup: QuestionGroup
  ): QuestionGroup {
    const visitor = new QuestionContentTransformationVisitor(
      this.transformInternalStringToHTML
    );
    acceptQuestionGroup(questionGroup, visitor);

    return visitor.transformedQuestionGroup;
  }

  public transformHTMLToInternalRepresentation(
    questionGroup: QuestionGroup
  ): QuestionGroup {
    const visitor = new QuestionContentTransformationVisitor(
      transformHTMLToInternalString
    );
    acceptQuestionGroup(questionGroup, visitor);

    return visitor.transformedQuestionGroup;
  }

  public transformInternalStringToHTML(
    internalContent: string | undefined
  ): string | undefined {
    if (
      internalContent === undefined ||
      internalContent === null ||
      internalContent === ''
    ) {
      return;
    }

    const doc = parser.parseFromString(internalContent, 'text/html');
    doc.querySelectorAll('img').forEach(element => {
      const assetId = element.dataset.assetId;
      element.setAttribute('src', `${assetEndpointPrefix}${assetId}`);
      delete element.dataset.assetId;
    });

    return doc.body.innerHTML;
  }

  /* There are some invalid style attributes on img tags within the content of questions that needs to be removed. */
  public fixImageTag<T>(
    obj: T,
    properties = ['content_', 'response_options']
  ): T {
    properties.forEach(property => this.traverseProperties(obj, '', property));

    return obj;
  }

  private traverseProperties(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    obj: any,
    stack: string,
    property: string
  ): void {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (typeof obj[prop] === 'object') {
          this.traverseProperties(obj[prop], stack + '.' + prop, property);
        } else {
          if (
            obj[prop] !== undefined &&
            obj[prop] !== null &&
            prop.slice(0, property.length) === property
          ) {
            obj[prop] = this.removeStyleAttribute(obj[prop]);
          }
        }
      }
    }
  }

  private removeStyleAttribute(content: string): string {
    const parser: DOMParser = new DOMParser();
    const parsedContent = parser.parseFromString(content, 'text/html');
    parsedContent.querySelectorAll('img').forEach(element => {
      if (
        element !== undefined &&
        element !== null &&
        element.hasAttribute('style')
      ) {
        element.removeAttribute('style');
      }
    });

    return parsedContent.body.innerHTML;
  }
}
