import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationsQuestionEGraphQL } from '../../services/graphql-question-group-types';

const NUMBER_OF_RESPONSE_OPTIONS = 5;

@Component({
  selector: 'qf-question-content-type-e-solution-selection',
  templateUrl: './question-content-type-e-solution-selection.component.html',
  styleUrls: ['./question-content-type-e-solution-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => QuestionContentTypeESolutionSelectionComponent
      ),
      multi: true
    }
  ]
})
export class QuestionContentTypeESolutionSelectionComponent
  implements ControlValueAccessor
{
  @Input()
  public language = 'en';

  @Input()
  public validations: ValidationsQuestionEGraphQL | undefined;

  public solution: number | null;
  public responseOptions = Array.from({
    length: NUMBER_OF_RESPONSE_OPTIONS
  }).fill(0);

  public writeValue(solution: number | null): void {
    this.solution = solution;
  }

  public registerOnChange(fn: (value: number | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public setAnswer(chosenIndex: number): void {
    this.solution = chosenIndex;
    this.onChange(this.solution);
    this.onTouch();
  }

  private onChange: (value: number | null) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}
