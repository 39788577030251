<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_management.revision_year_modal.title' | translate }}</h3>
  </div>
  <div class="modal-body">
    <label for="revision">{{
      'question_groups.edit.revision_year' | translate
    }}</label>
    <ng-select-wrapper
      [options]="revisions"
      (valueChange)="revision = $event"
    ></ng-select-wrapper>
  </div>
  <div class="modal-footer">
    <button (click)="dismiss()" class="btn btn-secondary-migrated">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      (click)="save()"
      [disabled]="revision === undefined"
      class="btn btn-primary-migrated"
    >
      {{ 'common.actions.save' | translate }}
    </button>
  </div>
</div>
