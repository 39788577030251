<form class="page form" [formGroup]="form" (submit)="onSave()" novalidate>
  <div class="page-content">
    <man-field>
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          formControlName="enabled"
          id="enabled"
        />
        <label for="enabled" class="form-check-label">{{
          'admin.announcements.form.label.enabled' | translate
        }}</label>
      </div>
    </man-field>
    <ng-container formGroupName="message">
      <man-field
        labelFor="message-de"
        [label]="'admin.announcements.form.label.message_de' | translate"
        [errors]="form | fieldErrors : 'message.de'"
      >
        <textarea
          id="message-de"
          formControlName="de"
          class="form-control"
          cdkFocusInitial
          rows="3"
          placeholder="admin.announcements.form.placeholder.message"
        ></textarea>
      </man-field>
      <man-field
        labelFor="message-fr"
        [label]="'admin.announcements.form.label.message_fr' | translate"
        [errors]="form | fieldErrors : 'message.fr'"
      >
        <textarea
          id="message-fr"
          formControlName="fr"
          class="form-control"
          rows="3"
          placeholder="admin.announcements.form.placeholder.message"
        ></textarea>
      </man-field>
      <man-field
        labelFor="message-en"
        [label]="'admin.announcements.form.label.message_en' | translate"
        [errors]="form | fieldErrors : 'message.en'"
      >
        <textarea
          id="message-en"
          formControlName="en"
          class="form-control"
          rows="3"
          placeholder="admin.announcements.form.placeholder.message"
        ></textarea>
      </man-field>
    </ng-container>
    <man-field
      labelFor="starts-at"
      [label]="'admin.announcements.form.label.starts_at' | translate"
      [errors]="form | fieldErrors : 'startsAt'"
    >
      <input
        #startsAt
        id="starts-at"
        type="date"
        formControlName="startsAt"
        class="form-control"
        [min]="dateInputToday"
    /></man-field>
    <man-field
      labelFor="ends-at"
      [label]="'admin.announcements.form.label.ends_at' | translate"
      [errors]="form | fieldErrors : 'endsAt'"
    >
      <input
        id="ends-at"
        type="date"
        formControlName="endsAt"
        class="form-control"
        [min]="startsAt.value"
      />
      <ng-container *ngIf="form.touched || form.dirty">
        <div
          class="d-block invalid-feedback"
          error-message
          *ngIf="form.errors?.mustBeAtOrAfterDate as validatorError"
        >
          {{
            'common.form.errors.mustBeAtOrAfterDate'
              | translate
                : {
                    date: (validatorError.date | date : 'dd.MM.yyyy')
                  }
          }}
        </div>
      </ng-container>
    </man-field>
    <man-field
      labelFor="severity"
      [label]="'admin.announcements.form.label.severity' | translate"
      [errors]="form | fieldErrors : 'severity'"
    >
      <select id="severity" formControlName="severity" class="form-select">
        <option selected disabled value="">
          {{ 'common.messages.please_select' | translate }}
        </option>
        <option *ngFor="let option of severityOptions" [value]="option.value">
          {{ option.label | translate }}
        </option>
      </select>
    </man-field>
  </div>
  <div class="page-footer">
    <button
      type="button"
      [disabled]="disabled"
      class="btn btn-light order-1"
      (click)="onClose()"
    >
      {{ 'common.actions.close' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-primary order-2"
      [disabled]="disabled || !form.dirty || !form.valid"
      (click)="onSave()"
    >
      <ng-container *ngIf="!disabled; else loading">
        {{
          (edit ? 'common.actions.save_changes' : 'common.actions.create')
            | translate
        }}
      </ng-container>
    </button>
  </div>
</form>
<ng-template #loading>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.actions.loading' | translate }}
</ng-template>
