<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_management.toolbar.label' | translate }}</h3>
  </div>
  <div class="modal-body">
    <!-- LABLES EXIST -->
    <div class="existing-labels">
      <h4>
        {{ 'labels.titles.exisiting_label' | translate }}
      </h4>
      <div *ngFor="let label of labels" class="label-entry">
        <div class="label-description">
          <div class="color-select" [style.background-color]="label.color">
            <input
              type="color"
              (change)="updateLabel($event.target.value, label)"
            />
          </div>
          <p>
            {{ label.name }}
          </p>
        </div>

        <co-radio-button-group
          [(ngModel)]="actions[label.id]"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <button
            class="btn btn-secondary-migrated"
            [value]="assignmentActions.IGNORE"
            title="{{ 'common.actions.no_action' | translate }}"
          >
            <i class="fa fa-circle"></i>
          </button>
          <button
            class="btn btn-secondary-migrated"
            [value]="assignmentActions.REMOVE"
            title="{{ 'common.actions.remove' | translate }}"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            class="btn btn-secondary-migrated"
            [value]="assignmentActions.ADD"
            title="{{ 'common.actions.add' | translate }}"
          >
            <i class="fa fa-check"></i>
          </button>
        </co-radio-button-group>

        <button
          class="btn btn-primary"
          (click)="removeLabel(label)"
          title="{{ 'common.actions.remove' | translate }}"
        >
          <i class="fa fa-trash-o"></i>
        </button>
      </div>
      <!-- NO LABLES EXIST -->
      <p *ngIf="labels?.length === 0">
        {{ 'labels.no_label_exists' | translate }}
      </p>
    </div>
    <!-- NEW LABEL -->
    <div class="new-label">
      <h4>
        {{ 'labels.titles.new_label' | translate }}
      </h4>
      <div class="label-entry">
        <div class="label-description__full-width">
          <div class="color-select" [style.background-color]="newLabel.color">
            <input [(ngModel)]="newLabel.color" type="color" ngDefaultControl />
          </div>
          <input
            class="form-control"
            type="text"
            placeholder="{{ 'activerecord.attributes.label.name' | translate }}"
            [(ngModel)]="newLabel.name"
          />
          <button
            class="btn btn-primary"
            (click)="addLabel()"
            title="{{ 'common.actions.add' | translate }}"
            [disabled]="!newLabel.name"
          >
            <i class="fa fa-plus"></i>
            {{ 'common.actions.add' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="dismiss()" class="btn btn-secondary-migrated">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button (click)="save()" class="btn btn-primary-migrated">
      {{ 'common.actions.save' | translate }}
    </button>
  </div>
</div>
