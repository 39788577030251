angular.module('common').factory('SingleSelection', [
  'Store', 'Selectors::Pool',
  function (Store, PoolSelectors) {
    var ARROW_UP = 38, ARROW_DOWN = 40;

    function SingleSelection (setSelectionAction, removeSelectionAction,
      getSelection, getItems, persistencePath) {
      this.setSelectionAction = setSelectionAction;
      this.removeSelectionAction = removeSelectionAction;
      this.getSelection = getSelection;
      this.getItems = getItems;
      this.subscriptions = [];

      this.subscriptions.push(Store.subscribeOn(PoolSelectors.getCurrentPool, function (pool) {
        if (pool) {
          this.persistencePath = 'pool-' + pool.id + '.' + persistencePath;
          this._loadSelection();
        }
      }.bind(this)));
      this.subscriptions.push(Store.subscribeOn(getSelection, this._saveSelection.bind(this)));
      this.subscriptions.push(Store.subscribeOn(getSelection, this._updateSelection.bind(this)));
      this.subscriptions.push(Store.subscribeOn(getItems, this._updateSelection.bind(this)));
    }

    SingleSelection.prototype._loadSelection = function () {
      var data;
      try {
        data = JSON.parse(localStorage.getItem(this.persistencePath));
      } catch (e) {
        data = undefined;
      }
      Store.dispatch(this.setSelectionAction(data));
    };

    SingleSelection.prototype._saveSelection = function (selection) {
      localStorage.setItem(this.persistencePath, JSON.stringify(selection));
    };

    SingleSelection.prototype.destroy = function () {
      this.subscriptions.forEach(function (subscription) {
        subscription();
      });
    };

    SingleSelection.prototype.select = function (itemId) {
      Store.dispatch(this.setSelectionAction(itemId));
    };

    SingleSelection.prototype.keyPressed = function (event) {
      var selection = this.getSelection(Store.getState()),
      items = this.getItems(Store.getState()),
      lastSelectedIndex = items.findIndex(function (item) {
        return item.id == selection;
      });

      event.preventDefault();
      if (event.keyCode == ARROW_DOWN && lastSelectedIndex > -1 && lastSelectedIndex < items.length - 1) {
        Store.dispatch(this.setSelectionAction(items[lastSelectedIndex + 1].id));
        return false;
      }
      else if (event.keyCode == ARROW_UP && lastSelectedIndex > 0) {
        Store.dispatch(this.setSelectionAction(items[lastSelectedIndex - 1].id));
        return false;
      }
    };

    SingleSelection.prototype._updateSelection = function () {
      var selection = this.getSelection(Store.getState()),
      items = this.getItems(Store.getState());
      this.choices = items.map(function (item) {
        return selection == item.id;
      });
    };

    return SingleSelection;
  }
]);
