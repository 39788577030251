<div class="page-content" [ngClass]="{ loading: (loading$ | async) }">
  <div class="grid h-100" style="grid-template-rows: auto 1fr">
    <ng-container *ngIf="userPermissions$ | async as userPermissions">
      <div class="g-col-6 g-col-xl-8 ms-auto order-2">
        <button (click)="onNew()" type="button" class="btn btn-dark">
          {{ 'admin.pools.permissions.create' | translate }}
        </button>
      </div>
      <div class="g-col-6 g-col-xl-4 order-1">
        <man-search-input
          [shortcutActive]="true"
          placeholder="admin.pools.permissions.filter.placeholder"
          [disabled]="userPermissions.requestState !== 'success'"
          (search)="onSearch($event)"
          (clear)="onSearchClear()"
        >
        </man-search-input>
      </div>
      <man-table-wrapper class="g-col-12 order-3" [request]="userPermissions">
        <p-table
          #table
          name="UserPermissions"
          selectionMode="single"
          sortMode="multiple"
          [scrollable]="true"
          scrollHeight="flex"
          [multiSortMeta]="[
            { field: 'pool.name', order: 1 },
            { field: 'role', order: 1 }
          ]"
          [globalFilterFields]="filterFields"
          [value]="userPermissions.data | copyArray"
          [loading]="userPermissions.requestState === 'loading'"
        >
          <ng-template pTemplate="header">
            <tr>
              <th translate="admin.users.permissions.table.pool"></th>
              <th translate="admin.pools.permissions.table.role"></th>
              <th translate="admin.pools.permissions.table.subject"></th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-permission>
            <tr
              interactiveRow
              (edit)="onEdit(permission)"
              (delete)="onDelete(permission)"
            >
              <td>
                {{ permission.pool.name }}
              </td>
              <td>
                {{ 'pool_permissions.' + permission.role | translate }}
              </td>
              <td>{{ permission.subject?.name }}</td>
              <td class="text-end">
                <man-action-menu
                  [data]="permission"
                  [options]="menuOptions"
                ></man-action-menu>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5" class="text-center">
                {{ 'admin.pools.permissions.table.messages.empty' | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </man-table-wrapper>
    </ng-container>
  </div>
</div>

<ng-template #error>
  <div class="alert alert-danger" role="alert">
    <h4 translate="common.messages.something_went_wrong"></h4>
    <p translate="common.messages.reloading_the_page_could_solve_problem"></p>
  </div>
</ng-template>

<ng-container *ngIf="formState$ | async as formState">
  <man-side-panel
    *ngIf="formState.show"
    [title]="
      (formState.data
        ? 'admin.pools.permissions.form.title.edit'
        : 'admin.pools.permissions.form.title.create'
      ) | translate
    "
    [closeable]="true"
    (closed)="onClose()"
  >
    <man-permission-form
      *ngIf="userId$ | async as userId"
      class="mx-n3"
      [disabled]="formState.disabled"
      [permission]="formState.data"
      selectionMode="pool"
      (update)="onUpdate($event)"
      (closed)="onClose()"
    ></man-permission-form>
  </man-side-panel>
</ng-container>
