/*jshint multistr: true */
angular.module('common').controller('ConfirmationModalCtrl', ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
  $scope.I18n = I18n;

  $scope.confirm = function () {
    $uibModalInstance.close();
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss();
  };
}]);

angular.module('common').service('ConfirmationModal', ['$uibModal', '$rootScope', function ($uibModal, $rootScope) {
  return {
    show: function (title, content, options) {
      var modalOptions = options || {},
      scopeVariables = modalOptions.scope || {};

      modalOptions.scope = $rootScope.$new();
      modalOptions.scope.title = title;
      modalOptions.scope.content = content;
      modalOptions.scope.cancelLabel = modalOptions.cancel || I18n.t('actions.cancel');
      delete modalOptions.cancel;
      modalOptions.scope.okLabel = modalOptions.ok || I18n.t('common.ok');
      delete modalOptions.ok;
      modalOptions.controller = modalOptions.controller || 'ConfirmationModalCtrl';
      for(var key in scopeVariables) {
        if (scopeVariables.hasOwnProperty(key)) {
          modalOptions.scope[key] = scopeVariables[key];
        }
      }

      if (angular.isUndefined(modalOptions.template)) {
        modalOptions.template = '<div class="modal-wrapper js-confirmation-modal">\
  <div class="modal-header">\
    <a class="close" ng-click="cancel()">&times;</a>\
    <h3>' + title + '</h3>\
  </div>\
  <div class="modal-body">' + content.replace(/\n/g, '<br>') + '</div>\
  <div class="modal-footer">\
    <button class="btn btn-default" ng-click="cancel()" type="button">' + modalOptions.scope.cancelLabel + '</button>\
    <button class="btn btn-primary" ng-click="confirm()" type="button">' + modalOptions.scope.okLabel + '</button>\
  </div>\
</div>';
      }

      return $uibModal.open(modalOptions);
    }
  };
}]);
