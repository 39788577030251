import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, Store } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { StateContext } from '@ngxs/store/src/symbols';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { SetPositionRevisionStatusGQL } from 'src/app/admin/services/set-position-revision-status.generated';
import { takeGraphQLResult } from 'src/app/common/operators/take-graphql-response';
import { ToastsService } from 'src/app/common/services/toasts.service';
import {
  assertIsDefined,
  isDefined
} from 'src/app/common/utils/type-guards/is-defined';
import { RevisionStatus } from 'src/generated/base-types';
import { parseError } from '../../../../common/utils/error-parser';
import { RemoteData, RequestState } from '../../../../common/utils/remote-data';
import { requestStateLoading } from '../../../../common/utils/remote-data-utils';
import { CreateRevisionStatusGQL } from '../../../services/create-revision-status.generated';
import { DeleteRevisionStatusGQL } from '../../../services/delete-revision-status.generated';
import {
  LoadRevisionStatusGQL,
  RevisionStatusListElementFragment
} from '../../../services/load-revision-status.generated';
import { UpdateRevisionStatusGQL } from '../../../services/update-revision-status.generated';
import { PoolFormState } from '../form/pool-form.state';
import {
  CreateRevisionStatus,
  CreateRevisionStatusFailure,
  CreateRevisionStatusSuccess,
  DeleteRevisionStatus,
  DeleteRevisionStatusFailure,
  DeleteRevisionStatusSuccess,
  LoadRevisionStatus,
  LoadRevisionStatusFailure,
  LoadRevisionStatusSuccess,
  SetRevisionStatusPosition,
  SetRevisionStatusPositionFailure,
  SetRevisionStatusPositionSuccess,
  UpdateRevisionStatus,
  UpdateRevisionStatusFailure,
  UpdateRevisionStatusSuccess
} from './revision-status.actions';

export interface RevisionStatusStateModel {
  revisionStatus: RemoteData<RevisionStatusListElementFragment[]>;
  mutation?: RemoteData<RevisionStatusListElementFragment>;
}

@State<RevisionStatusStateModel>({
  name: 'revisionStatus',
  defaults: {
    revisionStatus: { requestState: 'initial' },
    mutation: { requestState: 'initial' }
  }
})
@Injectable({
  providedIn: 'root'
})
export class RevisionStatusState {
  constructor(
    private readonly loadRevisionStatusService: LoadRevisionStatusGQL,
    private readonly updateRevisionStatusService: UpdateRevisionStatusGQL,
    private readonly deleteRevisionStatusService: DeleteRevisionStatusGQL,
    private readonly createRevisionStatusService: CreateRevisionStatusGQL,
    private readonly setPositionRevisionStatusService: SetPositionRevisionStatusGQL,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService,
    private readonly store: Store
  ) {}

  @Selector()
  public static findOne(
    state: RevisionStatusStateModel
  ): (id: string) => RevisionStatusListElementFragment | undefined {
    return (id: string) => {
      return state.revisionStatus.data?.find(rs => rs.id === id);
    };
  }

  @Selector()
  public static findRevisionStatusAtIndex(
    state: RevisionStatusStateModel
  ): (index: number) => RevisionStatusListElementFragment | undefined {
    return (index: number) => {
      return state.revisionStatus.data?.find(el => el.position === index);
    };
  }

  @Selector()
  public static revisionStatus(
    state: RevisionStatusStateModel
  ): RemoteData<RevisionStatusListElementFragment[]> {
    return { ...state.revisionStatus, actions: { retry: LoadRevisionStatus } };
  }

  @Selector()
  public static revisionStatusLoading(
    state: RevisionStatusStateModel
  ): boolean {
    return requestStateLoading(state.revisionStatus);
  }

  @Selector()
  public static mutationRequestState(
    state: RevisionStatusStateModel
  ): RequestState | undefined {
    return state.mutation?.requestState;
  }

  @Action(LoadRevisionStatus, { cancelUncompleted: true })
  public loadRevisionStatus(
    ctx: StateContext<RevisionStatusStateModel>
  ): Observable<void> {
    const pool = this.store.selectSnapshot(PoolFormState.pool);
    assertIsDefined(pool.data, 'Pool not found');
    const poolId = pool.data.id;

    ctx.patchState({ revisionStatus: { requestState: 'loading' } });

    return this.loadRevisionStatusService.fetch({ id: poolId }).pipe(
      switchMap(res =>
        ctx.dispatch(
          new LoadRevisionStatusSuccess(res.data.pool.revisionStatus)
        )
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new LoadRevisionStatusFailure(parseError(err)))
      )
    );
  }

  @Action(LoadRevisionStatusSuccess)
  public loadRevisionStatusSuccess(
    ctx: StateContext<RevisionStatusStateModel>,
    { revisionStatus }: LoadRevisionStatusSuccess
  ): void {
    ctx.patchState({
      revisionStatus: {
        data: revisionStatus,
        requestState: 'success'
      }
    });
  }

  @Action(LoadRevisionStatusFailure)
  public loadRevisionStatusFailure(
    ctx: StateContext<RevisionStatusStateModel>,
    { error }: LoadRevisionStatusFailure
  ): void {
    ctx.patchState({
      revisionStatus: { requestState: 'failure', error }
    });
  }

  @Action(UpdateRevisionStatus)
  public updateRevisionStatus(
    ctx: StateContext<RevisionStatusStateModel>,
    { id, attributes }: UpdateRevisionStatus
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.updateRevisionStatusService.mutate({ id, attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.updateRevisionStatus?.revisionStatus),
      filter(isDefined),
      switchMap(revisionStatus =>
        ctx.dispatch(new UpdateRevisionStatusSuccess(revisionStatus))
      ),
      catchError((err: unknown) => {
        return ctx.dispatch(new UpdateRevisionStatusFailure(parseError(err)));
      })
    );
  }

  @Action(UpdateRevisionStatusSuccess)
  public updateRevisionStatusSuccess(
    ctx: StateContext<RevisionStatusStateModel>,
    { revisionStatus }: UpdateRevisionStatusSuccess
  ): void {
    ctx.patchState({
      revisionStatus: {
        ...ctx.getState().revisionStatus,
        data: ctx
          .getState()
          .revisionStatus.data?.map(item =>
            item.id === revisionStatus.id ? revisionStatus : item
          )
      },
      mutation: {
        data: revisionStatus,
        requestState: 'success'
      }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('common.models.revision_status')
      })
    );
  }

  @Action(UpdateRevisionStatusFailure)
  public updateRevisionStatusFailure(
    ctx: StateContext<RevisionStatusStateModel>,
    { error }: UpdateRevisionStatusFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(DeleteRevisionStatus)
  public deleteRevisionStatus(
    ctx: StateContext<RevisionStatusStateModel>,
    { id }: DeleteRevisionStatus
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });

    return this.deleteRevisionStatusService.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeleteRevisionStatusSuccess(id))),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteRevisionStatusFailure(parseError(err)))
      )
    );
  }

  @Action(DeleteRevisionStatusSuccess)
  public deleteRevisionStatusSuccess(
    ctx: StateContext<RevisionStatusStateModel>,
    { id }: DeleteRevisionStatusSuccess
  ): void {
    ctx.patchState({
      revisionStatus: {
        ...ctx.getState().revisionStatus,
        data: ctx.getState().revisionStatus.data?.filter(item => item.id !== id)
      },
      mutation: { data: undefined, requestState: 'success' }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('common.models.revision_status')
      })
    );
  }

  @Action(DeleteRevisionStatusFailure)
  public deleteRevisionStatusFailure(
    ctx: StateContext<RevisionStatusStateModel>,
    { error }: DeleteRevisionStatusFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(CreateRevisionStatus)
  public createRevisionStatus(
    ctx: StateContext<RevisionStatusStateModel>,
    { attributes }: CreateRevisionStatus
  ): Observable<void> {
    ctx.setState(
      patch<RevisionStatusStateModel>({
        ...ctx.getState(),
        mutation: { requestState: 'loading' }
      })
    );

    const pool = this.store.selectSnapshot(PoolFormState.pool);

    if (pool.data?.id === undefined) throw new Error('Pool not found');

    return this.createRevisionStatusService
      .mutate({
        poolId: pool.data?.id,
        attributes: {
          shortName: attributes.shortName,
          description: attributes.description
        }
      })
      .pipe(
        takeGraphQLResult(),
        map(
          mutationResult => mutationResult?.createRevisionStatus?.revisionStatus
        ),
        filter(isDefined),
        switchMap(revisionStatus =>
          ctx.dispatch(new CreateRevisionStatusSuccess(revisionStatus))
        ),
        catchError((err: unknown) =>
          ctx.dispatch(new CreateRevisionStatusFailure(parseError(err)))
        )
      );
  }

  @Action(CreateRevisionStatusSuccess)
  public createRevisionStatusSuccess(
    ctx: StateContext<RevisionStatusStateModel>,
    { revisionStatus }: CreateRevisionStatusSuccess
  ): void {
    ctx.setState(
      patch<RevisionStatusStateModel>({
        ...ctx.getState(),
        mutation: { requestState: 'success' },
        revisionStatus: patch<RevisionStatusStateModel['revisionStatus']>({
          data: append([revisionStatus])
        })
      })
    );

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.create', {
        resource: this.translate.instant('activerecord.models.revision_status')
      })
    );
  }

  @Action(CreateRevisionStatusFailure)
  public createRevisionStatusFailure(
    ctx: StateContext<RevisionStatusStateModel>,
    { error }: CreateRevisionStatusFailure
  ): void {
    ctx.setState(
      patch<RevisionStatusStateModel>({
        ...ctx.getState(),
        mutation: { error, requestState: 'failure' }
      })
    );

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(SetRevisionStatusPosition)
  public setRevisionStatusPosition(
    ctx: StateContext<RevisionStatusStateModel>,
    { id, direction, times }: SetRevisionStatusPosition
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.setPositionRevisionStatusService
      .mutate({
        id,
        direction,
        times
      })
      .pipe(
        takeGraphQLResult(),
        map(result => ({
          revisionStatus: result?.setPositionRevisionStatus?.revisionStatus,
          revisionStatusList:
            result?.setPositionRevisionStatus?.revisionStatusList
        })),
        filter(
          result =>
            isDefined(result.revisionStatus) &&
            isDefined(result.revisionStatusList)
        ),
        switchMap(({ revisionStatus, revisionStatusList }) => {
          assertIsDefined(revisionStatus);
          assertIsDefined(revisionStatusList);

          return ctx.dispatch(
            new SetRevisionStatusPositionSuccess(
              revisionStatus as RevisionStatus,
              revisionStatusList as RevisionStatus[]
            )
          );
        }),
        catchError((err: unknown) =>
          ctx.dispatch(new SetRevisionStatusPositionFailure(parseError(err)))
        )
      );
  }

  @Action(SetRevisionStatusPositionSuccess)
  public setRevisionStatusPositionSuccess(
    ctx: StateContext<RevisionStatusStateModel>,
    { revisionStatus, revisionStatusList }: SetRevisionStatusPositionSuccess
  ): void {
    ctx.patchState({
      revisionStatus: {
        data: revisionStatusList as RevisionStatus[],
        requestState: 'success'
      },
      mutation: {
        data: revisionStatus as RevisionStatus,
        requestState: 'success'
      }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('activerecord.models.revision_status')
      })
    );
  }

  @Action(SetRevisionStatusPositionFailure)
  public setRevisionStatusPositionFailure(
    ctx: StateContext<RevisionStatusStateModel>,
    { error }: SetRevisionStatusPositionFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }
}
