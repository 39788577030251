<p-table
  #table
  tableKeyboardNavigation
  *ngIf="metadata$ | async as metadata"
  [loading]="(loading$ | async) ?? true"
  [value]="(questionGroups$ | async) || []"
  dataKey="id"
  [lazy]="true"
  (onLazyLoad)="loadMore($event)"
  [paginator]="true"
  sortMode="multiple"
  [multiSortMeta]="sortAttributes"
  [showInitialSortBadge]="false"
  (onSort)="table.resetScrollTop()"
  [resizableColumns]="true"
  [rows]="rows$ | async : 25"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="
    ((metadata.totalNumberOfItems === metadata.filteredNumberOfItems
      ? 'question_management.question_list.footer.unfiltered'
      : 'question_management.question_list.footer.filtered'
    ) | translate) +
    (selection.length > 0
      ? ', ' + selection.length + ' ' + ('candidates.marked' | translate)
      : '')
  "
  [totalRecords]="
    (metadata.totalNumberOfItems === metadata.filteredNumberOfItems
      ? metadata.totalNumberOfItems
      : metadata.filteredNumberOfItems) || 0
  "
  [(first)]="first"
  [metaKeySelection]="true"
  [rowHover]="true"
  [scrollable]="true"
  scrollHeight="flex"
  selectionMode="multiple"
  [(selection)]="selection"
  [selectionPageOnly]="true"
  (selectionChange)="onSelectionChange()"
  [rowsPerPageOptions]="[25, 50, 150, 250]"
>
  <ng-container *ngIf="columns$ | async as columns">
    <ng-template pTemplate="header">
      <tr class="sticky-header-row">
        <th pFrozenColumn>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.sequential_number'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.sequentialNumber)"
          [pSortableColumn]="QuestionGroupSortAttribute.SequentialNumber"
        >
          {{ 'common.question_list.columns.sequential_number' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.SequentialNumber"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.legacy_id' | translate
          }}"
          *ngIf="isColumnVisible(columns.legacyId)"
          [pSortableColumn]="QuestionGroupSortAttribute.LegacyId"
        >
          {{ 'common.question_list.columns.legacy_id' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.LegacyId"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.literary_reference'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.literaryReference)"
          [pSortableColumn]="QuestionGroupSortAttribute.LiteraryReference"
        >
          {{ 'common.question_list.columns.literary_reference' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.LiteraryReference"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.source_language' | translate
          }}"
          *ngIf="isColumnVisible(columns.sourceLanguage)"
          [pSortableColumn]="QuestionGroupSortAttribute.SourceLanguage"
        >
          {{ 'common.question_list.columns.source_language' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.SourceLanguage"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.title' | translate
          }}"
          *ngIf="isColumnVisible(columns.title)"
          [pSortableColumn]="QuestionGroupSortAttribute.Title"
        >
          {{ 'common.question_list.columns.title' | translate }}
          <p-sortIcon [field]="QuestionGroupSortAttribute.Title"></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.supervisor' | translate
          }}"
          *ngIf="isColumnVisible(columns.supervisor)"
          [pSortableColumn]="QuestionGroupSortAttribute.Supervisor"
        >
          {{ 'common.question_list.columns.supervisor' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Supervisor"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.affiliation' | translate
          }}"
          *ngIf="isColumnVisible(columns.affiliation)"
          [pSortableColumn]="QuestionGroupSortAttribute.Affiliation"
        >
          {{ 'common.question_list.columns.affiliation' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Affiliation"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.content_validation_state_de'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.contentValidationStateDe)"
          [pSortableColumn]="
            QuestionGroupSortAttribute.ContentValidationStateDe
          "
        >
          {{
            'common.question_list.columns.content_validation_state_de'
              | translate
          }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.ContentValidationStateDe"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.content_validation_state_fr'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.contentValidationStateFr)"
          [pSortableColumn]="
            QuestionGroupSortAttribute.ContentValidationStateFr
          "
        >
          {{
            'common.question_list.columns.content_validation_state_fr'
              | translate
          }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.ContentValidationStateFr"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.content_validation_state_it'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.contentValidationStateIt)"
          [pSortableColumn]="
            QuestionGroupSortAttribute.ContentValidationStateIt
          "
        >
          {{
            'common.question_list.columns.content_validation_state_it'
              | translate
          }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.ContentValidationStateIt"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.content_validation_state_en'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.contentValidationStateEn)"
          [pSortableColumn]="
            QuestionGroupSortAttribute.ContentValidationStateEn
          "
        >
          {{
            'common.question_list.columns.content_validation_state_en'
              | translate
          }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.ContentValidationStateEn"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.type' | translate
          }}"
          *ngIf="isColumnVisible(columns.type)"
          [pSortableColumn]="QuestionGroupSortAttribute.Type"
        >
          {{ 'common.question_list.columns.type' | translate }}
          <p-sortIcon [field]="QuestionGroupSortAttribute.Type"></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.question_types' | translate
          }}"
          *ngIf="isColumnVisible(columns.questionTypes)"
          [pSortableColumn]="QuestionGroupSortAttribute.QuestionTypes"
        >
          {{ 'common.question_list.columns.question_types' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.QuestionTypes"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.score' | translate
          }}"
          *ngIf="isColumnVisible(columns.score)"
          [pSortableColumn]="QuestionGroupSortAttribute.Score"
        >
          {{ 'common.question_list.columns.score' | translate }}
          <p-sortIcon [field]="QuestionGroupSortAttribute.Score"></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.has_images' | translate
          }}"
          *ngIf="isColumnVisible(columns.hasImages)"
          [pSortableColumn]="QuestionGroupSortAttribute.HasImages"
        >
          {{ 'common.question_list.columns.has_images' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.HasImages"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.has_videos' | translate
          }}"
          *ngIf="isColumnVisible(columns.hasVideos)"
          [pSortableColumn]="QuestionGroupSortAttribute.HasVideos"
        >
          {{ 'common.question_list.columns.has_videos' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.HasVideos"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.category_0'
              | translate : columns.category0.translationParams
          }}"
          *ngIf="isColumnVisible(columns.category0)"
          [pSortableColumn]="QuestionGroupSortAttribute.Category_0"
        >
          {{ 'common.question_list.columns.category_0' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Category_0"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.category_1'
              | translate : columns.category1.translationParams
          }}"
          *ngIf="isColumnVisible(columns.category1)"
          [pSortableColumn]="QuestionGroupSortAttribute.Category_1"
        >
          {{ 'common.question_list.columns.category_1' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Category_1"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.category_2'
              | translate : columns.category2.translationParams
          }}"
          *ngIf="isColumnVisible(columns.category2)"
          [pSortableColumn]="QuestionGroupSortAttribute.Category_2"
        >
          {{ 'common.question_list.columns.category_2' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Category_2"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.category_3'
              | translate : columns.category3.translationParams
          }}"
          *ngIf="isColumnVisible(columns.category3)"
          [pSortableColumn]="QuestionGroupSortAttribute.Category_3"
        >
          {{ 'common.question_list.columns.category_3' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Category_3"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.category_4'
              | translate : columns.category4.translationParams
          }}"
          *ngIf="isColumnVisible(columns.category4)"
          [pSortableColumn]="QuestionGroupSortAttribute.Category_4"
        >
          {{ 'common.question_list.columns.category_4' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Category_4"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.category_5'
              | translate : columns.category5.translationParams
          }}"
          *ngIf="isColumnVisible(columns.category5)"
          [pSortableColumn]="QuestionGroupSortAttribute.Category_5"
        >
          {{ 'common.question_list.columns.category_5' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Category_5"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.category_6'
              | translate : columns.category6.translationParams
          }}"
          *ngIf="isColumnVisible(columns.category6)"
          [pSortableColumn]="QuestionGroupSortAttribute.Category_6"
        >
          {{ 'common.question_list.columns.category_6' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Category_6"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.category_7'
              | translate : columns.category7.translationParams
          }}"
          *ngIf="isColumnVisible(columns.category7)"
          [pSortableColumn]="QuestionGroupSortAttribute.Category_7"
        >
          {{ 'common.question_list.columns.category_7' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Category_7"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.created_at' | translate
          }}"
          *ngIf="isColumnVisible(columns.createdAt)"
          [pSortableColumn]="QuestionGroupSortAttribute.CreatedAt"
        >
          {{ 'common.question_list.columns.created_at' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.CreatedAt"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.updated_at' | translate
          }}"
          *ngIf="isColumnVisible(columns.updatedAt)"
          [pSortableColumn]="QuestionGroupSortAttribute.UpdatedAt"
        >
          {{ 'common.question_list.columns.updated_at' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.UpdatedAt"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.editor' | translate
          }}"
          *ngIf="isColumnVisible(columns.editor)"
          [pSortableColumn]="QuestionGroupSortAttribute.Editor"
        >
          {{ 'common.question_list.columns.editor' | translate }}
          <p-sortIcon [field]="QuestionGroupSortAttribute.Editor"></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.author' | translate
          }}"
          *ngIf="isColumnVisible(columns.author)"
          [pSortableColumn]="QuestionGroupSortAttribute.Author"
        >
          {{ 'common.question_list.columns.author' | translate }}
          <p-sortIcon [field]="QuestionGroupSortAttribute.Author"></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.number_of_usages'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.numberOfUsages)"
          [pSortableColumn]="QuestionGroupSortAttribute.NumberOfUsages"
        >
          {{ 'common.question_list.columns.number_of_usages' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.NumberOfUsages"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.last_usage' | translate
          }}"
          *ngIf="isColumnVisible(columns.lastUsage)"
          [pSortableColumn]="QuestionGroupSortAttribute.LastUsage"
        >
          {{ 'common.question_list.columns.last_usage' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.LastUsage"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.latest_statistic'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.latestStatistic)"
          [pSortableColumn]="QuestionGroupSortAttribute.LatestStatistic"
        >
          {{ 'common.question_list.columns.latest_statistic' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.LatestStatistic"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.cache_last_usage_n'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.cacheLastUsageN)"
          [pSortableColumn]="QuestionGroupSortAttribute.CacheLastUsageN"
        >
          {{ 'common.question_list.columns.cache_last_usage_n' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.CacheLastUsageN"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.cache_last_usage_p'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.cacheLastUsageP)"
          [pSortableColumn]="QuestionGroupSortAttribute.CacheLastUsageP"
        >
          {{ 'common.question_list.columns.cache_last_usage_p' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.CacheLastUsageP"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.cache_last_usage_r'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.cacheLastUsageR)"
          [pSortableColumn]="QuestionGroupSortAttribute.CacheLastUsageR"
        >
          {{ 'common.question_list.columns.cache_last_usage_r' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.CacheLastUsageR"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.cache_last_usage_lne'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.cacheLastUsageLne)"
        >
          {{ 'common.question_list.columns.cache_last_usage_lne' | translate }}
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.cache_last_usage_xi'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.cacheLastUsageXi)"
        >
          {{ 'common.question_list.columns.cache_last_usage_xi' | translate }}
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.cache_last_usage_eliminated'
              | translate
          }}"
          *ngIf="isColumnVisible(columns.cacheLastUsageEliminated)"
          [pSortableColumn]="
            QuestionGroupSortAttribute.CacheLastUsageEliminated
          "
        >
          {{
            'common.question_list.columns.cache_last_usage_eliminated'
              | translate
          }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.CacheLastUsageEliminated"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.exams' | translate
          }}"
          *ngIf="isColumnVisible(columns.exams)"
        >
          {{ 'common.question_list.columns.exams' | translate }}
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.labels' | translate
          }}"
          *ngIf="isColumnVisible(columns.labels)"
        >
          {{ 'common.question_list.columns.labels' | translate }}
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.duplicates' | translate
          }}"
          *ngIf="isColumnVisible(columns.duplicates)"
          [pSortableColumn]="QuestionGroupSortAttribute.Duplicates"
        >
          {{ 'common.question_list.columns.duplicates' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.Duplicates"
          ></p-sortIcon>
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.revision_status' | translate
          }}"
          *ngIf="isColumnVisible(columns.revisionStatus)"
        >
          {{ 'common.question_list.columns.revision_status' | translate }}
        </th>
        <th
          title="{{
            'activerecord.attributes.question_group.revision_year' | translate
          }}"
          *ngIf="isColumnVisible(columns.revisionYear)"
          [pSortableColumn]="QuestionGroupSortAttribute.RevisionYear"
        >
          {{ 'common.question_list.columns.revision_year' | translate }}
          <p-sortIcon
            [field]="QuestionGroupSortAttribute.RevisionYear"
          ></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="visibleColumnsCount + 1" class="text-center text-muted">
          {{
            (metadata.totalNumberOfItems === metadata.filteredNumberOfItems
              ? 'common.list.no_entries'
              : 'common.list.no_filtered_entries'
            ) | translate
          }}
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr *ngFor="let i of [].constructor(30)">
        <td pFrozenColumn class="loading-checkbox-cell">
          <p-tableCheckbox></p-tableCheckbox>
        </td>
        <td *ngFor="let i of [].constructor(visibleColumnsCount)">
          <p-skeleton></p-skeleton>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-questionGroup let-rowIndex="rowIndex">
      <tr
        (dblclick)="openQuestionGroup(questionGroup.id)"
        [pSelectableRow]="questionGroup"
        [pSelectableRowIndex]="rowIndex"
      >
        <td pFrozenColumn>
          <p-tableCheckbox
            [value]="questionGroup"
            (click)="$event.stopPropagation()"
          ></p-tableCheckbox>
        </td>
        <td *ngIf="isColumnVisible(columns.sequentialNumber)">
          {{ questionGroup.sequentialNumber }}
        </td>
        <td *ngIf="isColumnVisible(columns.legacyId)">
          {{ questionGroup.legacyId }}
        </td>
        <td *ngIf="isColumnVisible(columns.literaryReference)">
          <ng-container *ngIf="questionGroup.type === 'SINGLE'">
            {{ questionGroup.question.literaryReference }}
          </ng-container>
          <ng-container
            *ngIf="
              questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE'
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ question.literaryReference }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td *ngIf="isColumnVisible(columns.sourceLanguage)">
          {{
            'scrudu.languages.long.' + questionGroup.sourceLanguage | translate
          }}
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.title)">
          {{ questionGroup.title }}
        </td>
        <td *ngIf="isColumnVisible(columns.supervisor)">
          {{ questionGroup.supervisor }}
        </td>
        <td *ngIf="isColumnVisible(columns.affiliation)">
          {{ questionGroup.affiliation?.name }}
        </td>
        <td *ngIf="isColumnVisible(columns.contentValidationStateDe)">
          <div>
            <i
              class="fa"
              [ngClass]="{
                'fa-check skin-message--success':
                  questionGroup.contentValidation.de === 'COMPLETE',
                'fa-exclamation skin-message--warning':
                  questionGroup.contentValidation.de === 'INCOMPLETE',
                'fa-ban skin-message--alert':
                  questionGroup.contentValidation.de === 'MISSING' ||
                  questionGroup.contentValidation.de === 'UNDEFINED'
              }"
            ></i>
            {{
              'choices.question_groups.content_validation_state.' +
                (questionGroup.contentValidation.de === 'UNDEFINED'
                  ? 'missing'
                  : questionGroup.contentValidation.de.toLowerCase())
                | translate
            }}
          </div>
        </td>
        <td *ngIf="isColumnVisible(columns.contentValidationStateFr)">
          <div>
            <i
              class="fa"
              [ngClass]="{
                'fa-check skin-message--success':
                  questionGroup.contentValidation.fr === 'COMPLETE',
                'fa-exclamation skin-message--warning':
                  questionGroup.contentValidation.fr === 'INCOMPLETE',
                'fa-ban skin-message--alert':
                  questionGroup.contentValidation.fr === 'MISSING' ||
                  questionGroup.contentValidation.fr === 'UNDEFINED'
              }"
            ></i>
            {{
              'choices.question_groups.content_validation_state.' +
                (questionGroup.contentValidation.fr === 'UNDEFINED'
                  ? 'missing'
                  : questionGroup.contentValidation.fr.toLowerCase())
                | translate
            }}
          </div>
        </td>
        <td *ngIf="isColumnVisible(columns.contentValidationStateIt)">
          <div>
            <i
              class="fa"
              [ngClass]="{
                'fa-check skin-message--success':
                  questionGroup.contentValidation.it === 'COMPLETE',
                'fa-exclamation skin-message--warning':
                  questionGroup.contentValidation.it === 'INCOMPLETE',
                'fa-ban skin-message--alert':
                  questionGroup.contentValidation.it === 'MISSING' ||
                  questionGroup.contentValidation.it === 'UNDEFINED'
              }"
            ></i>
            {{
              'choices.question_groups.content_validation_state.' +
                (questionGroup.contentValidation.it === 'UNDEFINED'
                  ? 'missing'
                  : questionGroup.contentValidation.it.toLowerCase())
                | translate
            }}
          </div>
        </td>
        <td *ngIf="isColumnVisible(columns.contentValidationStateEn)">
          <div>
            <i
              class="fa"
              [ngClass]="{
                'fa-check skin-message--success':
                  questionGroup.contentValidation.en === 'COMPLETE',
                'fa-exclamation skin-message--warning':
                  questionGroup.contentValidation.en === 'INCOMPLETE',
                'fa-ban skin-message--alert':
                  questionGroup.contentValidation.en === 'MISSING' ||
                  questionGroup.contentValidation.en === 'UNDEFINED'
              }"
            ></i>
            {{
              'choices.question_groups.content_validation_state.' +
                questionGroup.contentValidation.en ===
              'UNDEFINED'
                ? 'missing'
                : (questionGroup.contentValidation.en.toLowerCase() | translate)
            }}
          </div>
        </td>
        <td *ngIf="isColumnVisible(columns.type)">
          {{
            'activerecord.choices.question_group.type.' + questionGroup.type
              | translate
          }}
        </td>
        <td *ngIf="isColumnVisible(columns.questionTypes)">
          <ng-container
            *ngIf="questionGroup.type === 'SINGLE' && questionGroup.question"
          >
            {{ 'questions.type.' + questionGroup.question.type | translate }}
          </ng-container>
          <ng-container
            *ngIf="
              (questionGroup.type === 'SERIES' ||
                questionGroup.type === 'SEQUENCE') &&
              questionGroup.questions
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ 'questions.type.' + question.type | translate }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td *ngIf="isColumnVisible(columns.score)">
          {{ questionGroup.totalScore }}
        </td>
        <td *ngIf="isColumnVisible(columns.hasImages)">
          <i class="fa fa-picture-o" *ngIf="questionGroup.hasImages"></i>
        </td>
        <td *ngIf="isColumnVisible(columns.hasVideos)">
          <i class="fa fa-video-camera" *ngIf="questionGroup.hasVideos"></i>
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.category0)">
          <ng-container
            *ngIf="questionGroup.type === 'SINGLE' && questionGroup.question"
          >
            {{ questionGroup.question.category0?.name }}
          </ng-container>
          <ng-container
            *ngIf="
              (questionGroup.type === 'SERIES' ||
                questionGroup.type === 'SEQUENCE') &&
              questionGroup.questions
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ question.category0?.name }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.category1)">
          <ng-container
            *ngIf="questionGroup.type === 'SINGLE' && questionGroup.question"
          >
            {{ questionGroup.question.category1?.name }}
          </ng-container>
          <ng-container
            *ngIf="
              (questionGroup.type === 'SERIES' ||
                questionGroup.type === 'SEQUENCE') &&
              questionGroup.questions
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ question.category1?.name }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.category2)">
          <ng-container
            *ngIf="questionGroup.type === 'SINGLE' && questionGroup.question"
          >
            {{ questionGroup.question.category2?.name }}
          </ng-container>
          <ng-container
            *ngIf="
              (questionGroup.type === 'SERIES' ||
                questionGroup.type === 'SEQUENCE') &&
              questionGroup.questions
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ question.category2?.name }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.category3)">
          <ng-container
            *ngIf="questionGroup.type === 'SINGLE' && questionGroup.question"
          >
            {{ questionGroup.question.category3?.name }}
          </ng-container>
          <ng-container
            *ngIf="
              (questionGroup.type === 'SERIES' ||
                questionGroup.type === 'SEQUENCE') &&
              questionGroup.questions
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ question.category3?.name }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.category4)">
          <ng-container
            *ngIf="questionGroup.type === 'SINGLE' && questionGroup.question"
          >
            {{ questionGroup.question.category4?.name }}
          </ng-container>
          <ng-container
            *ngIf="
              (questionGroup.type === 'SERIES' ||
                questionGroup.type === 'SEQUENCE') &&
              questionGroup.questions
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ question.category4?.name }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.category5)">
          <ng-container
            *ngIf="questionGroup.type === 'SINGLE' && questionGroup.question"
          >
            {{ questionGroup.question.category5?.name }}
          </ng-container>
          <ng-container
            *ngIf="
              (questionGroup.type === 'SERIES' ||
                questionGroup.type === 'SEQUENCE') &&
              questionGroup.questions
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ question.category5?.name }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.category6)">
          <ng-container
            *ngIf="questionGroup.type === 'SINGLE' && questionGroup.question"
          >
            {{ questionGroup.question.category6?.name }}
          </ng-container>
          <ng-container
            *ngIf="
              (questionGroup.type === 'SERIES' ||
                questionGroup.type === 'SEQUENCE') &&
              questionGroup.questions
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ question.category6?.name }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.category7)">
          <ng-container
            *ngIf="questionGroup.type === 'SINGLE' && questionGroup.question"
          >
            {{ questionGroup.question.category7?.name }}
          </ng-container>
          <ng-container
            *ngIf="
              (questionGroup.type === 'SERIES' ||
                questionGroup.type === 'SEQUENCE') &&
              questionGroup.questions
            "
          >
            <ng-container *ngFor="let question of questionGroup.questions">
              {{ question.category7?.name }}<br />
            </ng-container>
          </ng-container>
        </td>
        <td *ngIf="isColumnVisible(columns.createdAt)">
          {{ questionGroup.createdAt | date : 'dd.MM.yyyy HH:mm' }}
        </td>
        <td *ngIf="isColumnVisible(columns.updatedAt)">
          {{ questionGroup.updatedAt | date : 'dd.MM.yyyy HH:mm' }}
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.editor)">
          {{ questionGroup.editor?.firstName }}
          {{ questionGroup.editor?.lastName }}
        </td>
        <td class="text-wrap" *ngIf="isColumnVisible(columns.author)">
          {{ questionGroup.author }}
        </td>
        <td *ngIf="isColumnVisible(columns.numberOfUsages)">
          {{ questionGroup.numberOfUsages }}
        </td>
        <td *ngIf="isColumnVisible(columns.lastUsage)">
          {{ questionGroup.lastUsage | date : 'dd.MM.yyyy HH:mm' }}
        </td>
        <td *ngIf="isColumnVisible(columns.latestStatistic)">
          {{ questionGroup.latestStatistic }}
        </td>
        <td *ngIf="isColumnVisible(columns.cacheLastUsageN)">
          {{ questionGroup.cacheLastUsageN }}
        </td>
        <td *ngIf="isColumnVisible(columns.cacheLastUsageP)">
          {{ questionGroup.cacheLastUsageP }}
        </td>
        <td *ngIf="isColumnVisible(columns.cacheLastUsageR)">
          {{ questionGroup.cacheLastUsageR }}
        </td>
        <td *ngIf="isColumnVisible(columns.cacheLastUsageLne)">
          {{ questionGroup.cacheLastUsageLne }}
        </td>
        <td *ngIf="isColumnVisible(columns.cacheLastUsageXi)">
          {{ questionGroup.cacheLastUsageXi }}
        </td>
        <td *ngIf="isColumnVisible(columns.cacheLastUsageEliminated)">
          {{ questionGroup.cacheLastUsageEliminated | yesOrBlank }}
        </td>
        <td *ngIf="isColumnVisible(columns.exams)">
          <span *ngFor="let futureExam of questionGroup.futureExams">{{
            futureExam.name
          }}</span>
        </td>
        <td *ngIf="isColumnVisible(columns.labels)">
          <span
            class="colored-label d-block mb-1"
            [title]="label.name"
            [style.--color]="label.color | rgb"
            *ngFor="let label of questionGroup.labels"
            >{{ label.name }}</span
          >
        </td>
        <td *ngIf="isColumnVisible(columns.duplicates)">
          {{ questionGroup.duplicates?.length }}
        </td>
        <td *ngIf="isColumnVisible(columns.revisionStatus)">
          {{ questionGroup.revisionStatus?.shortName }}
        </td>
        <td *ngIf="isColumnVisible(columns.revisionYear)">
          {{ questionGroup.revisionYear }}
        </td>
        <td>
          <div>
            <div *ngIf="!questionGroup.contentValidation.structurallyComplete">
              <i class="fa fa-exclamation text-warning fa-fw"></i>
              {{
                'common.question_list.tooltips.structurally_incomplete'
                  | translate
              }}
            </div>

            <div *ngIf="questionGroup.hasActiveTasks">
              <i class="fa fa-tasks text-warning fa-fw"></i>
              {{
                'question_management.question_list.tooltips.has_active_tasks'
                  | translate
              }}
            </div>

            <div *ngIf="questionGroup.contentChangedSinceLastUsage">
              <i class="fa fa-paragraph text-warning fa-fw"></i>
              {{
                'question_management.question_list.tooltips.content_changed_since_last_usage'
                  | translate
              }}
            </div>

            <div *ngIf="questionGroup.metadataChangedSinceLastUsage">
              <i class="fa fa-code text-warning fa-fw"></i>
              {{
                'question_management.question_list.tooltips.metadata_changed_since_last_usage'
                  | translate
              }}
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </ng-container>
</p-table>
