(function () {
  VersionVerifierController.$inject = ['$scope',
                                       '$window',
                                       '$interval',
                                       'Repositories::Version',
                                       'Store',
                                       'Actions::Version',
                                       'Selectors::Version'];

  function VersionVerifierController($scope, $window, $interval, VersionRepository, Store, Actions, Selectors) {
    this.I18n            = I18n;
    this.versionOutdated = false;
    var FifteenMinutes   = 900000; // 15 minutes

    this.reload = function () {
      this.versionOutdated = false;
      $window.location.reload();
    };

    var getVersion = function () {
      return VersionRepository.getVersion();
    };

    var checkVersion = function () {
      getVersion().then(function (backendVersion) {
        var frontendVersion = Selectors.getVersion(Store.getState());
        if (backendVersion !== frontendVersion) {
          this.versionOutdated = true;
        }
      }.bind(this));
    }.bind(this);

    getVersion().then(function(version) {
      Store.dispatch(Actions.setVersion(version));
    });

    var interval = $interval(checkVersion, FifteenMinutes);

    $scope.$on('$destroy', function () {
      $interval.cancel(interval);
    });
  }

  angular.module('common').component('versionVerifier', {
    template: require('./version_verifier.html'),
    controller: VersionVerifierController
  });
})();
