import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DuplicateQuestionGroupMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupId: Types.Scalars['ID'];
  targetPoolId: Types.Scalars['ID'];
}>;


export type DuplicateQuestionGroupMutation = { __typename?: 'Mutation', duplicateQuestionGroup?: { __typename?: 'DuplicateQuestionGroupPayload', successful: boolean } | null };

export const DuplicateQuestionGroupDocument = gql`
    mutation DuplicateQuestionGroup($poolId: ID!, $questionGroupId: ID!, $targetPoolId: ID!) {
  duplicateQuestionGroup(
    poolId: $poolId
    questionGroupId: $questionGroupId
    targetPoolId: $targetPoolId
  ) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DuplicateQuestionGroupGQL extends Apollo.Mutation<DuplicateQuestionGroupMutation, DuplicateQuestionGroupMutationVariables> {
    document = DuplicateQuestionGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }