angular.module('common').directive('inlineError', ['InputErrorHandler', 'baseInlineErrorFunctionality', function (InputErrorHandler, baseInlineErrorFunctionality) {
  return {
    restrict: 'E',
    scope: true,
    transclude: true,
    template: '<div class="inline-error__tooltip-position-wrapper"><span class="inline-error__tooltip skin-tooltip" ng-show="message.content && (hasFocus || hasMouse)">{{message.content}}</span></div>\
      <div class="inline-error__container"><span class="fa-stack inline-error__icon">\
      <i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-stack-1x fa-inverse" ng-class="{\'fa-exclamation-triangle\': message.type == \'alert\', \'fa-info\': message.type == \'info\', \'fa-exclamation\': message.type == \'warning\'}"></i>\
      </span>\
      <ng-transclude/></div>',
    link: function ($scope, $element, $attrs) {
      var errorHandler = new InputErrorHandler($element);
      errorHandler.onShowMessage(function (message) {
        $scope.message = message;
      });

      $element.on('change keyup', 'input, textarea', function() {
        errorHandler.updateMessageVisibility();
      });

      baseInlineErrorFunctionality('input, textarea', $scope, $element, $attrs, errorHandler);
    }
  };
}]);
