angular.module('common').directive('subgroupStatistics', [
  function() {
    return {
      restrict: 'E',
      template: require('./subgroup_statistics.html'),
      scope: {
        statistic: '='
      },
      link: function($scope) {
        $scope.I18n = I18n;
      }
    };
  }
]);
