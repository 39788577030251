/*jshint multistr: true */
angular.module('common').controller('DiffModalCtrl', [
  '$scope',
  '$uibModalInstance',
  function($scope, $uibModalInstance) {
    $scope.I18n = I18n;

    $scope.close = function() {
      $uibModalInstance.close();
    };
  }
]);

angular.module('common').service('DiffModal', [
  '$uibModal',
  'QuestionContentTransformator',
  function($uibModal, QuestionContentTransformator) {
    function transformDiffToTable(diff) {
      return diff
        .map(function(attributeDiff) {
          var text = attributeDiff.word
            .replace(
              /\[\-(<img.+?)\-\]/g,
              '<span class="diff-deletion diff-image">$1</span>'
            )
            .replace(
              /\{\+(<img.+?)\+\}/g,
              '<span class="diff-addition diff-image">$1</span>'
            )
            .replace(
              /\[\-(.*?)\-\]/g,
              '<span class="diff-deletion diff-text">$1</span>'
            )
            .replace(
              /\{\+(.*?)\+\}/g,
              '<span class="diff-addition diff-text">$1</span>'
            );

          text =
            QuestionContentTransformator.transformInternalStringToHTML(text) ||
            '';

          var node = angular.element('<div>' + text + '</div>');
          node.find('img').replaceWith(function() {
            this.removeAttribute('height');
            this.setAttribute(
              'width',
              (this.dataset.relativeWidth || 100) + '%'
            );
            return this;
          });

          return (
            '<tr><td class="diff-attribute">' +
            attributeDiff.name +
            '</td><td class="diff-code">' +
            node.html() +
            '</td></tr>'
          );
        })
        .join('');
    }

    return {
      show: function(diffAction) {
        return new Promise(function(resolve, reject) {
          diffAction().then(function(data) {
            $uibModal
              .open({
                size: 'lg',
                controller: 'DiffModalCtrl',
                template:
                  '<div class="modal-wrapper">\
                <div class="modal-header">\
                  <a class="close" ng-click="close()">&times;</a>\
                  <h3>{{::I18n.t("common.diff_modal.title")}}</h3>\
                </div>\
                <div class="modal-body"><table class="diff-table"><tbody>' +
                  transformDiffToTable(data) +
                  '</tbody></table></div>\
                <div class="modal-footer">\
                  <button class="btn btn-primary" ng-click="close()" type="button">{{::I18n.t("actions.close")}}</button>\
                </div>\
              </div>'
              })
              .result.then(resolve, reject);
          });
        });
      }
    };
  }
]);
