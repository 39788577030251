(function() {
  function Controller($scope, Store, ListSelectors) {
    var selectionSubscription;

    this.$onInit = function() {
      selectionSubscription = Store.subscribeOn(ListSelectors.getSelectedQuestionGroup, function(
        questionGroup
      ) {
        this.questionGroupId = questionGroup?.id.toString(); // qm-question-group-details needs a string
      }.bind(this));

      $scope.$on('$destroy', function () {
        selectionSubscription();
      });
    };
  }

  Controller.$inject = [
    '$scope',
    'Store',
    'Selectors::ExamQuestionGroupList',
  ];

  angular.module('examManagement').component('examQuestionGroupDetails', {
    template: `
    <qm-question-group-details
        ng-if='$ctrl.questionGroupId'
        [question-group-id]="$ctrl.questionGroupId"
        class="bs4"
    ></qm-question-group-details>
    `,
    controller: Controller
  });
})();
