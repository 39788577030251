angular.module('settings').factory('Reducers::SubjectList', ['Actions::Subject',
  function (Actions) {
    function compareSubjects (a, b) {
      return a.name.localeCompare(b.name);
    }

    function reducer (state, action) {
      var items, index;

      state = state || {};

      switch (action.type) {
      case Actions.FETCH_SUBJECTS_REQUEST:
        return Object.assign({}, state, {
          requestInProgress: true});

      case Actions.FETCH_SUBJECTS_SUCCESS:
        return Object.assign({}, state, {
          items: action.subjects,
          selectedId: action.subjects.length === 0 ? undefined : action.subjects[0].id,
          requestInProgress: false});

      case Actions.SET_SELECTION:
        return Object.assign({}, state, {
          selectedId: action.selectedId});
      case Actions.REMOVE_SELECTION:
        return Object.assign({}, state, {
          selectedId: undefined});

      case Actions.SET_SUBJECT:
        index = state.items.findIndex(function (item) {
          return item.id == action.subject.id;
        });
        items = state.items.slice(0);
        items[index] = action.subject;
        items.sort(compareSubjects);
        return Object.assign({}, state, {items: items});

      case Actions.ADD_SUBJECT:
        items = state.items.concat([action.subject]);
        items.sort(compareSubjects);
        return Object.assign({}, state, {
          items: items,
          selectedId: items.length === 1 ? items[0].id : state.selectedId
        });

      case Actions.DELETE_SUBJECT:
        index = state.items.findIndex(function (item) {
          return item.id == action.subjectId;
        });
        items = state.items.slice(0);
        items.splice(index, 1);
        return Object.assign({}, state, {
          items: items,
          selectedId: action.subjectId == state.selectedId ? undefined : state.selectedId
        });

      default:
        return state;
      }

    }

    return reducer;
  }
]);
