angular.module('common').filter('replaceLineBreaks', ['$sce', function ($sce) {
  return function (input) {
    return $sce.trustAsHtml(input.replace(/\n/g, '<br>'));
  };
}]);

angular.module('common').directive('notifications', ['$timeout', 'notifications', function($timeout, notifications) {
  return {
    restrict: 'E',
    replace: true,
    template: '\
    <div class="pv-notifications">\
      <div ng-repeat="message in notifications.get()" class="pv-notifications__message" ng-class="\'pv-notifications__message--\' + message.type">\
        <div class="pv-notifications__icon-column">\
          <span class="fa-stack fa-lg">\
            <i class="fa fa-circle fa-stack-2x fa-inverse"></i>\
            <i class="fa fa-stack-1x pv-notifications__icon" ng-class="{\'fa-check\': message.type == \'success\', \'fa-exclamation-triangle\': message.type == \'alert\', \'fa-info\': message.type == \'info\', \'fa-exclamation\': message.type == \'warning\'}"></i>\
          </span>\
        </div>\
        <div class="pv-notifications__content-column">\
          <span class="pv-notifications__content" ng-bind-html="message.content | replaceLineBreaks"></span>\
        </div>\
        <div class="pv-notifications__dismiss-column">\
          <i class="fa fa-times" ng-click="closeMessage($index)"></i>\
        </div>\
      </div>\
    </div>\
    ',
    link: function($scope) {
      $scope.notifications = notifications;
      var messageTimeouts = [];

      $scope.notifications.onAdd(function() {
        messageTimeouts.unshift($timeout(function() {
          $scope.notifications.pop();
          messageTimeouts.pop();
        }, 10000));
      });

      $scope.closeMessage = function(index) {
        this.notifications.remove(index);
        $timeout.cancel(messageTimeouts[index]);
        messageTimeouts.splice(index, 1);
      };
    }
  };
}]);
