angular.module('common').factory('Repositories::TaskQuestionGroup', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getSingleQuery =
        'query($ids: [Int!]) {' +
        '  task_question_groups(ids: $ids) { id ' +
        '    question_group {id} ' +
        '    initial_question_group_version {id}' +
        '}}',
      getByTaskIdQuery =
        'query($task_ids: [Int!], $order: [String!]) {' +
        'task_question_groups(task_ids: $task_ids, order: $order) {' +
        '  id has_comment changed_by_author question_group {' +
        '    id type legacy_id sequential_number source_language'+
        '    title supervisor affiliation {name} content_validation_state_de content_validation_state_fr'+
        '    content_validation_state_it content_validation_state_en structurally_complete has_images has_videos'+
        '    category_0 { name } category_1 { name } category_2 { name } category_3 { name }'+
        '    category_4 { name } category_5 { name } category_6 { name } category_7 { name }'+
        '    created_at updated_at editor { name }'+
        '    author number_of_usages past_exams { name date id } last_usage'+
        '    cache_last_usage_n cache_last_usage_p cache_last_usage_r cache_last_usage_eliminated' +
        '    cache_last_usage_lne' +
        '    labels { id color name } revision_status { short_name } revision_year'+
        '    has_active_tasks content_changed_since_last_usage metadata_changed_since_last_usage'+
        '    future_exams {id name}' +
        '    duplicates {sequential_number updated_at pool {name}}' +
        '    latest_statistics {' +
        '      n_min n_max p_min p_max r_min r_max lne_min lne_max' +
        '      last_usage_year_min last_usage_year_max eliminations' +
        '    }' +
        '    questions {' +
        '      literary_reference type score' +
        '    }' +
        '  }' +
        '  task { id }' +
        '}}';

    return {
      get: function(id) {
        return client.query(getSingleQuery, { ids: [id] }).then(function(response) {
          return response.data.data.task_question_groups[0];
        });
      },
      query: function(task_ids, order, options) {
        return client
          .query(getByTaskIdQuery, { task_ids: task_ids, order: order }, options)
          .then(function(response) {
            return response.data.data.task_question_groups;
          });
      }
    };
  }
]);
