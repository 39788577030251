import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Comment, Scalars } from 'src/generated/base-types';
import { QuestionGroupMessageFragment } from '../../question-management/services/question-group-messages.generated';
import { Group, GroupedHistory } from '../utils/grouped-history';
import {
  isComment,
  isSystemMessageQuestionGroupUpdated,
  isSystemMessageQuestionTypeTransformed,
  isSystemMessageStatisticsDeleted
} from '../utils/type-guards/message';

interface GroupViewModel {
  year: number;
  visible: boolean;
}

interface MessageViewModel {
  id: Scalars['ID'];
  author: string;
  date: Date;
  text: string;
  isComment: boolean;
  isRead: boolean;
}

@Component({
  selector: 'co-question-group-messages',
  templateUrl: './question-group-messages.component.html',
  styleUrls: ['./question-group-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionGroupMessagesComponent {
  @Input()
  public set messages(value: QuestionGroupMessageFragment[]) {
    this.messageGroups = transformToMessageGroups(value, this.translateService);
  }

  @Input()
  public showReadMessages: boolean;

  @Input()
  public showSystemMessages: boolean;

  @Output()
  public commentReadStateChanged = new EventEmitter<[Comment['id'], boolean]>();

  @Output()
  public showReadMessageChanged = new EventEmitter<boolean>();

  @Output()
  public showSystemMessageChanged = new EventEmitter<boolean>();

  @Output()
  public commentDeleted = new EventEmitter<Comment['id']>();

  public messageGroups: Group<GroupViewModel, MessageViewModel>[] = [];

  constructor(private translateService: TranslateService) {}

  public trackByGroupName(
    _index: number,
    group: Group<GroupViewModel, MessageViewModel>
  ): number {
    return group.name.year;
  }

  public trackByMessageId(_index: number, message: MessageViewModel): string {
    return message.id;
  }

  public onToggleGroupVisibility(index: number): void {
    this.messageGroups[index].name.visible =
      !this.messageGroups[index].name.visible;
  }

  public onToggleMessageRead({ id, isRead }: MessageViewModel): void {
    this.commentReadStateChanged.emit([id, !isRead]);
  }

  public onDeleteComment({ id }: MessageViewModel): void {
    this.commentDeleted.emit(id);
  }

  public onToggleShowReadMessages(): void {
    this.showReadMessageChanged.emit(!this.showReadMessages);
  }

  public onToggleShowSystemMessages(): void {
    this.showSystemMessageChanged.emit(!this.showSystemMessages);
  }
}

function transformToMessageGroups(
  messages: QuestionGroupMessageFragment[],
  translateService: TranslateService
): Group<GroupViewModel, MessageViewModel>[] {
  const messageViewModels = messages.map(message =>
    messageViewModelFromMessage(message, translateService)
  );
  messageViewModels.sort((a, b) => b.date.getTime() - a.date.getTime());

  const groups = new GroupedHistory(messageViewModels, {
    groupByFn: message => message.date.getFullYear()
  }).getGroups();

  return groups.map(({ name, items }) => {
    return {
      name: {
        year: name,
        visible: true
      },
      items
    };
  });
}

function messageViewModelFromMessage(
  message: QuestionGroupMessageFragment,
  translateService: TranslateService
): MessageViewModel {
  const comment = isComment(message);

  return {
    id: message.id,
    date: new Date(message.createdAt),
    author: `${message.author.firstName} ${message.author.lastName}`,
    text: comment
      ? message.text
      : translateSystemMessage(message, translateService),
    isComment: comment,
    isRead: comment && message.read
  };
}

function translateSystemMessage(
  message: QuestionGroupMessageFragment,
  translateService: TranslateService
): string {
  if (isSystemMessageQuestionGroupUpdated(message)) {
    return translateService.instant(
      'common.question_group_details.history.system_messages.question_group_updated'
    );
  }

  if (isSystemMessageStatisticsDeleted(message)) {
    return translateService.instant(
      'common.question_group_details.history.system_messages.statistics_deleted'
    );
  }

  if (isSystemMessageQuestionTypeTransformed(message)) {
    return translateService.instant(
      'common.question_group_details.history.system_messages.question_type_transformed',
      { questionNumber: message.questionNumber }
    );
  }

  throw new Error('Unknown message type');
}
