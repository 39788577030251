import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QuestionStatisticsFragment } from 'src/app/question-management/services/question-group-statistics.generated';

@Component({
  selector: 'co-subgroup-statistics',
  templateUrl: './subgroup-statistics.component.html',
  styleUrls: ['./subgroup-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubgroupStatisticsComponent {
  @Input()
  public statistics: QuestionStatisticsFragment;
}
