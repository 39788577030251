angular.module('common').service('TableColumnVisibilityFunctionality', [
  'Store',
  function(Store) {
    function TableColumnVisibilityFunctionality(columnSelector) {
      this.columnsSubscription = Store.subscribeOn(
        columnSelector,
        function(columns) {
          this.columnVisibilities = {};
          Object.keys(columns).forEach(function(key) {
            if (columns[key] !== undefined) {
              this.columnVisibilities[key] = columns[key].enabled && columns[key].visible;
            }
          }, this);
        }.bind(this)
      );
    }

    TableColumnVisibilityFunctionality.prototype.destroy = function() {
      this.columnsSubscription();
    };

    return TableColumnVisibilityFunctionality;
  }
]);
