import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadAffiliations } from '../../../state/pool-details/affiliations/affiliations.actions';

@Injectable({ providedIn: 'root' })
export class AffiliationsListGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    this.store.dispatch(new LoadAffiliations());

    return true;
  }
}
