import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CurrentPoolForFilterQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type CurrentPoolForFilterQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', languages: Array<Types.Language>, supervisors: Array<string>, revisionStatus: Array<{ __typename?: 'RevisionStatus', id: string, shortName: string }>, labels: Array<{ __typename?: 'Label', id: string, name: string, color: string }>, affiliations: Array<{ __typename?: 'Affiliation', id: string, name: string }>, exams: Array<{ __typename?: 'Exam', id: string, name: string, date: string }>, dimensions: Array<{ __typename?: 'Dimension', id: string, position: number, categories: Array<{ __typename?: 'Category', id: string, name: string }> }> } };

export const CurrentPoolForFilterDocument = gql`
    query CurrentPoolForFilter($poolId: ID!) {
  pool(id: $poolId) {
    languages
    revisionStatus {
      id
      shortName
    }
    labels {
      id
      name
      color
    }
    affiliations {
      id
      name
    }
    exams(sort: {attribute: DATE, direction: DESC}) {
      id
      name
      date
    }
    supervisors
    dimensions {
      id
      position
      categories {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentPoolForFilterGQL extends Apollo.Query<CurrentPoolForFilterQuery, CurrentPoolForFilterQueryVariables> {
    document = CurrentPoolForFilterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }