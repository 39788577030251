import { read, utils, WorkSheet, writeFile } from 'xlsx';
import { assertIsDefined, isDefined } from './type-guards/is-defined';
import { isVoid } from './type-guards/voidable';

export type ParseXlsxFileOptions = {
  indexRows?: boolean;
  i18nBoolColumns?: string[];
};

// we need this as Excel has different strings for booleans
// in German and French
const EXCEL_BOOLEAN_MAPPING: { [key: string]: boolean } = {
  true: true,
  wahr: true,
  vrai: true,
  false: false,
  falsch: false,
  faux: false
};

const convertI18nBoolColumns = <T>(row: T, boolColumns: (keyof T)[]): T => {
  boolColumns.forEach(column => {
    const colValue = row[column];

    // take values weren't picked up as boolean
    // due to the booleans values not being in english, e.g. WAHR and FALSCH
    if (typeof colValue === 'string') {
      const mappedBool = EXCEL_BOOLEAN_MAPPING[colValue.toLowerCase()];
      assertIsDefined(mappedBool, `"${colValue}" can't be mapped to a boolean`);

      row[column] = mappedBool as unknown as T[keyof T];
    }
  });

  return { ...row };
};

export const createXlsxFile = (
  data: WorkSheet[],
  worksheetName: string,
  fileName: string
): void => {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, worksheetName);

  // generate Excel file and initiate download
  writeFile(workbook, fileName);
};

export async function parseXlsxFile<T>(
  event: Event,
  opts?: ParseXlsxFileOptions
): Promise<T[] | undefined> {
  const options = { indexRows: false, ...opts };
  const files = (event.target as HTMLInputElement).files;
  if (isVoid(files) || isVoid(files[0])) return;

  const xlsxFile = files[0]; // only taking first file
  const worksheet = await parseBlobAsWorksheet(xlsxFile);

  let data = utils.sheet_to_json<T>(worksheet);

  if (options.indexRows) {
    data = data.map((row, index) => ({ ...row, index }));
  }

  if (isDefined(options.i18nBoolColumns)) {
    data = data.map(col =>
      convertI18nBoolColumns(col, options.i18nBoolColumns as (keyof T)[])
    );
  }

  return data;
}

export const parseBlobAsWorksheet = (blob: Blob): Promise<WorkSheet> => {
  return new Promise<WorkSheet>(resolve => {
    const reader = new FileReader();

    reader.addEventListener('load', event => {
      if (event.target?.result === undefined) {
        return;
      }

      const fileContent = event.target.result as string;
      const workBook = read(fileContent, { type: 'binary' });
      const firstSheetName = workBook.SheetNames[0]; // pick first sheet

      resolve(workBook.Sheets[firstSheetName]);
    });

    reader.readAsBinaryString(blob);
  });
};
