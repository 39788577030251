import { Component, Input } from '@angular/core';

@Component({
  selector: 'qf-video-remove-modal',
  templateUrl: './video-remove-modal.component.html'
})
export class VideoRemoveModalComponent {
  @Input()
  public modalInstance: { dismiss(): void; close(): void };

  public cancel(): void {
    this.modalInstance.dismiss();
  }

  public remove(): void {
    this.modalInstance.close();
  }
}
