<div class="form-horizontal">
  <div class="form-group">
    <label class="control-label col-sm-6 text-left">
      {{
        'question_groups.edit.longmenu_solution.number_responses' | translate
      }}
    </label>
    <ng-select-wrapper
      class="col-sm-2"
      [value]="numberOfResponses"
      [options]="numberResponsesList"
      (valueChange)="setNumberOfResponses($event)"
    ></ng-select-wrapper>
  </div>
</div>
<h4>{{ 'question_groups.edit.longmenu_solution.title' | translate }}</h4>
<p class="example-container">
  <span>{{
    'question_groups.edit.longmenu_solution.description_intro' | translate
  }}</span
  >&nbsp;
  <span
    ngbTooltip="{{
      'question_groups.edit.longmenu_solution.description_intro_example'
        | translate
    }}"
    tooltipClass="angular-tooltip"
    >{{ 'question_groups.edit.longmenu_solution.example' | translate }}</span
  >
</p>
<p>
  {{
    'question_groups.edit.longmenu_solution.description_alternatives'
      | translate
  }}
</p>
<p class="example-container">
  <span>
    {{
      'question_groups.edit.longmenu_solution.description_solution' | translate
    }}</span
  >&nbsp;
  <span
    ngbTooltip="{{
      'question_groups.edit.longmenu_solution.description_solution_example'
        | translate
    }}"
    tooltipClass="angular-tooltip"
    >{{ 'question_groups.edit.longmenu_solution.example' | translate }}</span
  >
</p>
<div #solutionElement class="response-solution-container">
  <qf-long-menu-response-solution
    *ngFor="let responseSolution of solution; let i = index"
    (removed)="removeResponseSolution(i)"
    (changed)="changeResponseSolution(i, $event)"
    [index]="i"
    [responseSolution]="responseSolution"
    [disabled]="!list"
    [solutionRemovable]="i + 1 > numberOfResponses"
    [list]="list"
    [language]="language"
    [selectedEntryId]="selectedEntryId"
  ></qf-long-menu-response-solution>
</div>
<qf-input-feedback
  [validations]="validations"
  [inputField]="solutionElement"
  [inputValue]="solution"
></qf-input-feedback>
<button
  (click)="addResponseSolution()"
  class="btn btn-primary vertically-spaced"
  [disabled]="!list"
>
  {{ 'question_groups.edit.longmenu_solution.add_solution' | translate }}
</button>
