/* eslint-disable camelcase, unicorn/no-null */
import {
  Maybe,
  QuestionGroupInput,
  QuestionGroupType,
  QuestionInput,
  QuestionType,
  TranslatedContentInput,
  TranslatedVideoInput
} from '../../../generated/base-types';
import {
  isOfQuestionGroupType,
  isOfQuestionType,
  QuestionAGraphQL,
  QuestionAMinusGraphQL,
  QuestionEGraphQL,
  QuestionFreeTextGraphQL,
  QuestionGroupTypesGraphQL,
  QuestionKPrimeGraphQL,
  QuestionLongMenuGraphQL,
  QuestionTypesGraphQL,
  SingleQuestionGraphQL
} from './graphql-question-group-types';

export function transformQuestionGroupToInput(
  questionGroup: QuestionGroupTypesGraphQL
): QuestionGroupInput {
  return {
    title: questionGroup.title,
    type: questionGroup.type,
    supervisor: questionGroup.supervisor,
    author: questionGroup.author,
    sourceLanguage: questionGroup.sourceLanguage,
    revisionYear: questionGroup.revisionYear,
    affiliationId: questionGroup.affiliation?.id,
    questions: transformQuestionGroupQuestionsToInput(questionGroup)
  };
}

function transformQuestionGroupQuestionsToInput(
  questionGroup: QuestionGroupTypesGraphQL
): QuestionInput[] {
  let questions: QuestionTypesGraphQL[];

  if (
    isOfQuestionGroupType<SingleQuestionGraphQL>(
      questionGroup,
      QuestionGroupType.Single
    )
  ) {
    questions = questionGroup.question ? [questionGroup.question] : [];
  } else {
    questions = questionGroup.questions ?? [];
  }

  return questions.map(question => transformQuestionToInput(question));
}

function transformQuestionToInput(
  question: QuestionTypesGraphQL
): QuestionInput {
  return {
    id: question.id,
    type: question.type,
    score: question.score,
    learningTarget: question.learningTarget,
    literaryReference: question.literaryReference,
    remarks: question.remarks,
    category0Id: question.category0?.id,
    category1Id: question.category1?.id,
    category2Id: question.category2?.id,
    category3Id: question.category3?.id,
    category4Id: question.category4?.id,
    category5Id: question.category5?.id,
    category6Id: question.category6?.id,
    category7Id: question.category7?.id,
    video: transformVideo(question),
    ...transformQuestionTypeSpecificAttributesToInput(question)
  };
}

function transformVideo(question: QuestionTypesGraphQL): TranslatedVideoInput {
  return {
    en: question.video?.en,
    de: question.video?.de,
    fr: question.video?.fr,
    it: question.video?.it
  };
}

function transformQuestionTypeSpecificAttributesToInput(
  question: QuestionTypesGraphQL
): QuestionInput {
  if (isOfQuestionType<QuestionAGraphQL>(question, QuestionType.A)) {
    return transformTypeAQuestionToInput(question);
  } else if (
    isOfQuestionType<QuestionAMinusGraphQL>(question, QuestionType.AMinus)
  ) {
    return transformTypeAMinusQuestionToInput(question);
  } else if (
    isOfQuestionType<QuestionKPrimeGraphQL>(question, QuestionType.KPrime)
  ) {
    return transformTypeKPrimeQuestionToInput(question);
  } else if (isOfQuestionType<QuestionEGraphQL>(question, QuestionType.E)) {
    return transformTypeEQuestionToInput(question);
  } else if (
    isOfQuestionType<QuestionFreeTextGraphQL>(question, QuestionType.FreeText)
  ) {
    return transformTypeFreeTextQuestionToInput(question);
  } else if (
    isOfQuestionType<QuestionLongMenuGraphQL>(question, QuestionType.LongMenu)
  ) {
    return transformTypeLongMenuQuestionToInput(question);
  }

  return {};
}

function transformTypeAQuestionToInput(
  question: QuestionAGraphQL
): QuestionInput {
  return {
    a: {
      content: transformContentToInput(question),
      solution: question.questionASolution,
      responseOptions: (question.responseOptions || []).map(responseOption => {
        return {
          id: responseOption.id,
          content: {
            en: responseOption.content.en,
            fr: responseOption.content.fr,
            de: responseOption.content.de,
            it: responseOption.content.it
          }
        };
      })
    }
  };
}

function transformTypeAMinusQuestionToInput(
  question: QuestionAMinusGraphQL
): QuestionInput {
  return {
    aMinus: {
      content: transformContentToInput(question),
      solution: question.questionAMinusSolution,
      responseOptions: (question.responseOptions || []).map(responseOption => {
        return {
          id: responseOption.id,
          content: {
            en: responseOption.content.en,
            fr: responseOption.content.fr,
            de: responseOption.content.de,
            it: responseOption.content.it
          }
        };
      })
    }
  };
}

function transformTypeKPrimeQuestionToInput(
  question: QuestionKPrimeGraphQL
): QuestionInput {
  return {
    kPrime: {
      solution: question.questionKprimeSolution || [],
      content: transformContentToInput(question),
      propositions: (question.propositions || []).map(proposition => {
        return {
          content: {
            en: proposition.content.en,
            fr: proposition.content.fr,
            de: proposition.content.de,
            it: proposition.content.it
          }
        };
      })
    }
  };
}

function transformTypeEQuestionToInput(
  question: QuestionEGraphQL
): QuestionInput {
  return {
    e: {
      statementA: {
        en: question.statementA?.en,
        de: question.statementA?.de,
        fr: question.statementA?.fr,
        it: question.statementA?.it
      },
      statementB: {
        en: question.statementB?.en,
        de: question.statementB?.de,
        fr: question.statementB?.fr,
        it: question.statementB?.it
      },
      solution: question.questionESolution
    }
  };
}

function transformTypeLongMenuQuestionToInput(
  question: QuestionLongMenuGraphQL
): QuestionInput {
  const solution = (question.questionLongMenuSolution ?? []).reduce(
    (acc, solutions) => {
      const solutionIds = solutions.map(solution => solution?.id ?? null);
      acc.push(solutionIds);

      return acc;
    },
    [] as Maybe<number>[][]
  );

  return {
    longMenu: {
      content: transformContentToInput(question),
      solution: question.questionLongMenuSolution && solution,
      numberOfResponses: question.numberOfResponses ?? 0,
      list: question.list
    }
  };
}

function transformTypeFreeTextQuestionToInput(
  question: QuestionFreeTextGraphQL
): QuestionInput {
  return {
    freeText: {
      content: transformContentToInput(question),
      solution: {
        en: question.questionFreeTextSolution?.en,
        fr: question.questionFreeTextSolution?.fr,
        de: question.questionFreeTextSolution?.de,
        it: question.questionFreeTextSolution?.it
      },
      suggestedNumberOfLines: question.suggestedNumberOfLines ?? 0
    }
  };
}

function transformContentToInput(
  question:
    | QuestionAGraphQL
    | QuestionAMinusGraphQL
    | QuestionKPrimeGraphQL
    | QuestionFreeTextGraphQL
    | QuestionLongMenuGraphQL
): TranslatedContentInput {
  return {
    en: question.content?.en,
    fr: question.content?.fr,
    de: question.content?.de,
    it: question.content?.it
  };
}
