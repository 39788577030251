// Allows access to AngularJS's UIRouter
// to remove once we've migrated completely to Angular2+
import { Injectable } from '@angular/core';

export interface UIRouterParams {
  [key: string]: string;
}

export abstract class UIRouter {
  public params: UIRouterParams;
  public abstract go(...args: unknown[]): void;
}

@Injectable({
  providedIn: 'root'
})
export class UiRouterService {
  constructor(private readonly uiRouter: UIRouter) {}

  // https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html#params
  public params(): UIRouterParams {
    return this.uiRouter.params;
  }

  // https://ui-router.github.io/ng1/docs/latest/classes/state.stateservice.html#go
  public go(...routeArgs: (Record<string, unknown> | string)[]): void {
    this.uiRouter.go(...routeArgs);
  }
}
