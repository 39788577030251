import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { QuestionGroupDetailsComponent } from '../components/question-group-details/question-group-details.component';

angular.module('questionManagement').directive(
  'qmQuestionGroupDetails',
  downgradeComponent({
    component: QuestionGroupDetailsComponent
  })
);
