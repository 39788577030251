import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ChangeQuestionTypeMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionId: Types.Scalars['ID'];
  type: Types.Scalars['String'];
  removeResponseOption?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ChangeQuestionTypeMutation = { __typename?: 'Mutation', changeQuestionType?: { __typename?: 'ChangeQuestionPayload', successful: boolean } | null };

export const ChangeQuestionTypeDocument = gql`
    mutation ChangeQuestionType($poolId: ID!, $questionId: ID!, $type: String!, $removeResponseOption: Int) {
  changeQuestionType(
    poolId: $poolId
    questionId: $questionId
    type: $type
    removeResponseOption: $removeResponseOption
  ) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeQuestionTypeGQL extends Apollo.Mutation<ChangeQuestionTypeMutation, ChangeQuestionTypeMutationVariables> {
    document = ChangeQuestionTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }