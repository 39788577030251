angular.module('common').directive('loadingButton', [ "$compile", function($compile) {
  return {
    restrict: 'A',
    link: function($scope, $element, $attrs) {
      $element.append('<i style="margin: auto;" class="ng-hide fa fa-spinner fa-spin"></i>');

      function displayLoading() {
        var width = $element.width(),
        contentElements = $element.find(':not(.fa-spinner)'),
        spinnerElement = $element.find('.fa-spinner');

        $element.attr('disabled', 'disabled');
        contentElements.addClass('ng-hide');
        spinnerElement.removeClass('ng-hide');
        $element.addClass('is-loading');

        // The CSS display trick forces Chrome to redraw the button immediately.
        $element.css('display', 'none');
        $element.width(width);
        $element.css('display', '');
      }

      function removeLoading() {
        var contentElements = $element.find(':not(.fa-spinner)'),
        spinnerElement = $element.find('.fa-spinner');

        $element.removeClass('is-loading');
        contentElements.removeClass('ng-hide');
        spinnerElement.addClass('ng-hide');
        $element.removeAttr('disabled');
      }

      $scope.$watch($attrs.loadingButton, function(loadingState) {
        if(angular.isUndefined(loadingState)) {
          return;
        }
        if(loadingState) {
          displayLoading();
        } else {
          removeLoading();
        }
      });
    }
  };
}]);
