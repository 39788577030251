(function() {
  angular.module('settings').component('settingsUsers', {
    template: require('./settings_users.html'),
    controller: Controller,
    bindings: {
      users: '<'
    }
  });

  function Controller(timeout) {
    var searchTimeout;

    this.I18n = I18n;

    this.searchUpdated = function(text) {
      if (searchTimeout) {
        timeout.cancel(searchTimeout);
      }
      searchTimeout = timeout(
        function() {
          this.search = text;
        }.bind(this),
        500
      );
    }.bind(this);
  }

  Controller.$inject = ['$timeout'];
})();
