import { TranslateService } from '@ngx-translate/core';
/*
class that can be used to change the LOCALE_ID dynamically
which is necessary to match the ng-bootstrap datepicker language to the rest of the interface
https://github.com/angular/angular/issues/15039#issuecomment-456345066
*/
export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  public toString(): string {
    return this.service.currentLang;
  }
}
