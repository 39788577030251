angular.module('settings').factory('Selectors::Subject', [
  function () {
    function getItems (object) {
      return (object && object.subjectList && object.subjectList.items) || [];
    }

    function getSelection (object) {
      return object && object.subjectList && object.subjectList.selectedId;
    }

    function getSelectedSubject (items, selectedId) {
      return items.find(function (i) {
        return i.id == selectedId;
      });
    }

    return {
      getItems: getItems,
      getSelection: getSelection,
      getSelectedSubject: Reselect.createSelector(getItems, getSelection, getSelectedSubject)
    };
  }
]);
