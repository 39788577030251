angular.module('common').factory('QuestionGroup', ['resourceFactory', function(resourceFactory) {
  var QuestionGroup = resourceFactory('/api/question_groups', {
    init: function(data) {
      this.version = 0;
      if (this.questions === undefined) {
        this.questions = [];
      }
    },

    validate: function () {
      return this.action('validate');
    },

    updateWithoutValidation: function () {
      var params = this._getRequestParams(),
      url = QuestionGroup.urlFactory.getCustomObjectActionUrl('without_validation', params);
      return this._wrapInProgress('updateWithoutValidation', function () {
        return QuestionGroup.makeRequest('PUT', url, {data: params}, {}, function (data) {
          this.setAttributes(data);
          return data;
        }.bind(this));
      });
    },

    getUniqueId: function() {
      return this.id + ':' + this.version;
    },

    getUndeletedQuestions: function () {
      var result = [];
      for(var i = 0; i < this.questions.length; i++) {
        if (!this.questions[i]._destroy)
          result.push(this.questions[i]);
      }
      return result;
    },

    hasMoreThanOneQuestion: function () {
      return this.getUndeletedQuestions().length > 1;
    },

    getTotalScore: function () {
      return this.getUndeletedQuestions().reduce(function (sum, questions) {
        if (questions.score)
          return sum + questions.score;
        else
          return sum;
      }, 0);
    },

    deleteStatistics: function () {
      return this.action('statistics', 'DELETE');
    }
  });

  var originalSetAttributes = QuestionGroup.prototype.setAttributes;
  QuestionGroup.prototype.setAttributes = function (attributes) {
    originalSetAttributes.call(this, attributes);
    this.version += 1;
  };

  return QuestionGroup;
}]);
