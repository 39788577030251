import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateTaskMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  attributes: Types.TaskInput;
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask?: { __typename?: 'CreateTaskPayload', successful: boolean } | null };

export const CreateTaskDocument = gql`
    mutation CreateTask($poolId: ID!, $attributes: TaskInput!) {
  createTask(poolId: $poolId, attributes: $attributes) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTaskGQL extends Apollo.Mutation<CreateTaskMutation, CreateTaskMutationVariables> {
    document = CreateTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }