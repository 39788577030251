<co-base-confirm-modal
  [title]="'question_management.task_modal.confirmation.title' | translate"
  (dismiss)="onDismiss()"
  (confirm)="onConfirm()"
>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.task_modal.confirmation.receiver' | translate }}
    </dt>
    <dd class="col-sm-9 text-break">
      <div *ngFor="let user of users" class="text-break">
        {{ user.firstName }} {{ user.lastName }} &lt;{{ user.email }}&gt;
      </div>
    </dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.task_modal.confirmation.subject' | translate }}
    </dt>
    <dd class="col-sm-9 text-break">
      {{ subject }}
    </dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-3 text-right text-break">
      {{ 'question_management.task_modal.confirmation.body' | translate }}
    </dt>
    <dd class="col-sm-9">
      <p class="text-raw text-break">{{ body }}</p>
      <p class="text-break">
        {{ 'task_mailer.task_created_email.link' | translate }}
      </p>
    </dd>
  </dl>
</co-base-confirm-modal>
