import { Scalars } from '../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../services/load-question-group-list.generated';

export class LoadQuestionGroup {
  public static readonly type = '[QuestionGroup] Load Question Group';

  constructor(public readonly id: Scalars['ID']) {}
}

export class LoadQuestionGroupFailure {
  public static readonly type = '[QuestionGroup] Load Question Group Failure';
}

export class LoadQuestionGroupSuccess {
  public static readonly type = '[QuestionGroup] Load Question Group Success';

  constructor(
    public readonly questionGroup: QuestionGroupListElementFragment
  ) {}
}
