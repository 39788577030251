angular.module('common').factory('VisibilityHelper', [function () {
  var EXTRA_SCROLLING = 80;

  function isAbove (element, container) {
    var absoluteUpperElementBorder = container.scrollTop() + element.position().top,
    absoluteUpperContainerBorder = container.scrollTop() + getHeaderHeight(container);
    return absoluteUpperElementBorder < absoluteUpperContainerBorder;
  }

  function isBelow (element, container) {
    var absoluteLowerElementBorder = container.scrollTop() + element.position().top + element.outerHeight(true),
    absoluteLowerContainerBorder = container.scrollTop() + container.height() + getHeaderHeight(container);
    return absoluteLowerElementBorder > absoluteLowerContainerBorder;
  }

  function scrollUp (element, container) {
    var absoluteUpperElementBorder = container.scrollTop() + element.position().top;
    container.scrollTop(absoluteUpperElementBorder - getHeaderHeight(container) - EXTRA_SCROLLING);
  }

  function scrollDown (element, container) {
    var absoluteLowerElementBorder = container.scrollTop() + element.position().top + element.outerHeight(true);
    container.scrollTop(absoluteLowerElementBorder - container.height() - getHeaderHeight(container) + EXTRA_SCROLLING);
  }

  function findTable (element) {
    while (element[0].nodeName !== 'TABLE') {
      element = element.parent();
    }
    return element;
  }

  function getHeaderHeight (container) {
    // this will not work with non fixed header tables
    var headerElement = container.find('.pv-fixed-table-header__wrapped-header');
    return headerElement.outerHeight(true);
  }

  return {
    scrollIntoView: function (element) {
      if (element.length == 0) return;
      var tableElement = findTable(element),
      container = tableElement.parent();

      if (element.length > 0) {
        // wait for the angular to propagate all watcher values
        // else the height the rows may not be accurate
        setTimeout(function () {
          if (isAbove(element, container))
            scrollUp(element, container);
          if (isBelow(element, container))
            scrollDown(element, container);
        }, 0);
      }
    }
  };
}]);
