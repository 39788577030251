import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { QuestionGroupColumnVisibilityComponent } from '../components/question-group-column-visibility/question-group-column-visibility.component';

angular.module('common').directive(
  'qmQuestionGroupColumnVisibility',
  downgradeComponent({
    component: QuestionGroupColumnVisibilityComponent
  })
);
