(function () {
  angular.module('settings').component('categorySelection', {
    template: require('./category_selection.html'),
    controller: CategorySelectionController
  });

  CategorySelectionController.$inject = [
    'Repositories::Dimension',
    'Repositories::Subject',
    'Store',
    'Actions::CategorySelection',
    'Actions::Subject',
    'Selectors::CategorySelection',
    'Selectors::Subject',
    'Selectors::PoolList',
    'SingleSelection',
    '$transitions',
    '$state',
    '$scope'
  ];

  function CategorySelectionController(Repository, SubjectRepository, Store, Actions,
    SubjectActions, CategorySelector, SubjectSelector, PoolSelector,
    SingleSelection, $transitions, $state, $scope) {

    var dimensionSubscription, categorySubscription, selectedSubjectSubscription,
      transitionSubscription, poolSubscription;

    function toId (object) {return object.id;}

    /* -------------- Controller functions ------------- */
    this.updateSubject = function () {
      var selectedIds = this.getSelectedCategories().map(function (c) {return c.id;}),
      visibleIds = this.categories.map(function (c) {return c.id;}),
      subject = SubjectSelector.getSelectedSubject(Store.getState());

      if (subject !== undefined) {
        selectedIds = subject.categories.map(function (c) {return c.id;}).
          filter(function (id) {return !visibleIds.includes(id);}).
          concat(selectedIds);
        SubjectRepository.updateCategories(subject, selectedIds).then(function (response) {
          Store.dispatch(SubjectActions.setSubject(response.subject));
        });
      }
      else {
        this.categoryChoice = [];
      }
    }.bind(this);

    this.getSelectedCategories = function () {
      return this.categories.filter(function (category, index) {
        return this.categoryChoice[index];
      }, this)
    }.bind(this);

    this.loadDimensions = function () {
      Store.dispatch(Actions.fetchDimensionsRequest(this.poolId));
      Repository.getAll(this.poolId).then(function (data) {
        Store.dispatch(Actions.fetchDimensionsSuccess(data));
      }.bind(this));
    }.bind(this);

    this.updateCategorySelection = function (subject) {
      var subjectCategoryIds;

      this.categoryChoice = [];
      if (subject !== undefined) {
        subjectCategoryIds = subject.categories.map(function (c) {
          return c.id;
        });

        this.categoryChoice = (this.categories || []).map(function (c) {
          return subjectCategoryIds.includes(c.id);
        });
      }
    }.bind(this);

    this.selectRow = function (index) {
      this.categoryChoice[index] = !this.categoryChoice[index];
      this.updateSubject();
    }.bind(this);

    this.updateSelectionCount = function () {
      this.selectionCountPerDimension = this.dimensions.map(function (dimension) {
        var categoryIds = dimension.categories.map(toId);
        if (this.subject) {
          return this.subject.categories.map(toId).filter(function (id) {
            return categoryIds.includes(id);
          }).length;
        }
        else {
          return 0;
        }
      }, this);
    }.bind(this);


    /* -------------- Subscriptions -------------- */
    dimensionSubscription = Store.subscribeOn(CategorySelector.getDimensionItems, function (items) {
      this.dimensions = items;
      this.updateSelectionCount();
    }.bind(this));

    categorySubscription = Store.subscribeOn(CategorySelector.getCategoryItems, function (items) {
      this.categories = items;
      this.updateCategorySelection(SubjectSelector.getSelectedSubject(Store.getState()));
    }.bind(this));

    selectedSubjectSubscription = Store.subscribeOn(SubjectSelector.getSelectedSubject, function (subject) {
      this.subject = subject;
      this.updateSelectionCount();
      this.updateCategorySelection(subject);
    }.bind(this));

    poolSubscription = Store.subscribeOn(PoolSelector.getItems, function () {
      this.pool = PoolSelector.getPool(Store.getState(), this.poolId);
    }.bind(this));

    transitionSubscription = $transitions.onEnter({entering: 'settings.pools.base.subjects'}, function(transition, state) {
      this.poolId = parseInt(transition.params().poolId);
      this.pool = PoolSelector.getPool(Store.getState(), this.poolId);
      this.loadDimensions();
    }.bind(this));

    $scope.$on('$destroy', function () {
      transitionSubscription();
      dimensionSubscription();
      categorySubscription();
      selectedSubjectSubscription();
      poolSubscription();
    });


    /* -------------- Initialization ------------- */
    this.poolId = parseInt($state.params.poolId);
    this.pool = PoolSelector.getPool(Store.getState(), this.poolId);
    this.I18n = I18n;
    this.dimensions = [];
    this.categories = [];
    this.loadDimensions();

    this.dimensionSelection = new SingleSelection(Actions.setDimensionSelection, Actions.removeDimensionSelection,
      CategorySelector.getDimensionSelection, CategorySelector.getDimensionItems,
      'settings.categorySelection.selectedDimension');
    this.dimensionSelection.choices = [true];
  }
})();
