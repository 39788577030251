import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TaskUserFragment } from '../create-task-modal/load-task-options.generated';

@Component({
  selector: 'co-confirm-translate-task-modal',
  templateUrl: './confirm-translate-task-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmTranslateTaskModalComponent {
  @Input() public modalInstance: NgbModalRef;
  @Input() public users: TaskUserFragment[];
  @Input() public subject: string;
  @Input() public body: string;
  @Input() public targetLanguage: string;

  public onConfirm(): void {
    this.modalInstance.close();
  }

  public onDismiss(): void {
    this.modalInstance.dismiss();
  }
}
