import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { SetSourceLanguageModalComponent } from '../modals/set-source-language-modal/set-source-language-modal.component';

angular
  .module('common')
  .directive(
    'coSetSourceLanguageModal',
    downgradeComponent({ component: SetSourceLanguageModalComponent })
  );
