(function() {
  function Controller(
    $state,
    $scope,
    $q,
    DiffModal,
    QuestionGroupActions,
    Repository,
    QuestionGroupNavigationList,
    TableColumnVisibilityFunctionality,
    SortByColumnFunctionality,
    Store,
    Selectors,
    Actions,
    MultiSelection,
    TaskListSelectors,
    PoolSelectors
  ) {
    var reloadSubscription,
      itemsSubscription,
      requestInProgressSubscription,
      poolSubscription,
      requestTimeout;

    this.I18n = I18n;

    this.editQuestionGroup = function(item) {
      const allQuestionGroupIds = this.items.map((item) => item.question_group.id);
      QuestionGroupNavigationList.set(allQuestionGroupIds);
      $state.go('editQuestionForm', { id: item.id, ref: 'taskManagement' });
    };

    this.showDiff = function(taskQuestionGroup) {
      Repository.get(taskQuestionGroup.id).then(function(task_question_group) {
        DiffModal.show(
          QuestionGroupActions.getWordDiff.bind(
            this,
            task_question_group.initial_question_group_version,
            task_question_group.question_group
          )
        );
      });
    };

    /*
     * Additional functionality
     */
    this.visibility = new TableColumnVisibilityFunctionality(Selectors.getColumnDefinitions);

    this.selection = new MultiSelection(
      Actions.setSelection,
      Actions.addToSelection,
      Actions.removeFromSelection,
      Selectors.getSelection,
      Selectors.getAllIds,
      Selectors.getFlatItems,
      'taskQuestionGroupList.selection'
    );

    this.sortByColumn = new SortByColumnFunctionality(
      Actions.setOrder,
      Selectors.getOrder,
      'sequential_number',
      'taskQuestionGroupList'
    );

    function loadList() {
      if (Selectors.isRequestInProgress(Store.getState())) {
        requestTimeout.resolve();
      }
      var taskIds = TaskListSelectors.getSelection(Store.getState());
      if (taskIds.length > 0) {
        requestTimeout = $q.defer();
        Store.dispatch(Actions.fetchItemsRequest());
        return Repository.query(
          TaskListSelectors.getSelection(Store.getState()),
          Selectors.getOrderAsParameter(Store.getState()),
          { timeout: requestTimeout.promise }
        )
          .then(function(items) {
            requestTimeout = undefined;
            Store.dispatch(Actions.fetchItemsSuccess(items));
            return items;
          })
          .then(function(items) {
            if (Selectors.getSelectedItems(Store.getState()).length === 0 && items.length > 0) {
              Store.dispatch(Actions.setSelection([items[0].id]));
            }
          });
      }
    }

    this.$onInit = function() {
      itemsSubscription = Store.subscribeOn(
        Selectors.getFlatItems,
        function(items) {
          this.items = items;
        }.bind(this)
      );

      requestInProgressSubscription = Store.subscribeOn(
        Selectors.isRequestInProgress,
        function(requestInProgress) {
          this.requestInProgress = requestInProgress;
        }.bind(this)
      );

      reloadSubscription = Store.subscribeOn(function (state) {
        return JSON.stringify(Selectors.getOrder(state)) + '-' + TaskListSelectors.getSelection(state);
      }, loadList)

      poolSubscription = Store.subscribeOn(PoolSelectors.getCurrentPool, function (pool) {
        if (pool) {
          this.dimensions = pool.dimensions;
        }
      }.bind(this));

      angular.element('.pv-fixed-table-header__scroll-area').bind('scroll', function() {
        angular.element('exam-popover').popover('hide');
      });
    };

    /*
     * Destruction
     */
    $scope.$on(
      '$destroy',
      function() {
        this.visibility.destroy();
        this.selection.destroy();
        this.sortByColumn.destroy();
        reloadSubscription();
        itemsSubscription();
        requestInProgressSubscription();
        poolSubscription();
      }.bind(this)
    );
  }

  Controller.$inject = [
    '$state',
    '$scope',
    '$q',
    'DiffModal',
    'QuestionGroupActions',
    'Repositories::TaskQuestionGroup',
    'QuestionGroupNavigationList',
    'TableColumnVisibilityFunctionality',
    'SortByColumnFunctionality',
    'Store',
    'Selectors::TaskQuestionGroupList',
    'Actions::TaskQuestionGroupList',
    'MultiSelection',
    'Selectors::TaskList',
    'Selectors::Pool'
  ];

  angular.module('taskManagement').component('taskQuestionGroupList', {
    template: require('./task_question_group_list.html'),
    controller: Controller
  });
})();
