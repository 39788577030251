import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'co-checkbox',
  templateUrl: './checkbox.component.html',
  host: { class: 'co-checkbox' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
  @Input() public checked?: boolean;

  @Input()
  @HostBinding('class.co-checkbox--disabled')
  public disabled?: boolean;

  @Output() public changed = new EventEmitter<boolean>();

  public onChange(): void {
    if (this.disabled === true) {
      return;
    }

    this.changed.emit(this.checked === true ? false : true);
  }
}
