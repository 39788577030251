angular.module('common').factory('Role', function() {
  return {
    hasRole: function(user, role) {
      return user.permissions.some(function (permission) {
        return permission.role === role;
      });
    },

    isSuperUser: function (user) {
      return user.super_user;
    }
  };
});
