angular.module('examManagement').factory('PageBreakDeterminator', [function() {
  function PageBreakDeterminator(clusteredItems, language) {
    this.clusteredItems    = clusteredItems;
    this.language          = language;
    this.a4ContentHeight   = 25.0;
    this.HEIGHT_KEY        = "question_group_height_in_cm_" + this.language;
  }

  PageBreakDeterminator.prototype.run = function() {
    this.currentPageHeight = 0;
    for(var i = 0; i < this.clusteredItems.length; i++) {
      for(var x = 0; x < this.clusteredItems[i].length; x++) {
        var currentItem = this.clusteredItems[i][x];
        var itemHeight = currentItem[this.HEIGHT_KEY];
        var newPageHeight = this.currentPageHeight + itemHeight;

        this._disablePageBreak(currentItem);

        if(newPageHeight > this.a4ContentHeight) {
          this._enablePageBreak(this._getPreviousItem(this.clusteredItems[i], x));
          this._setCurrentPageHeight(itemHeight);
        }
        else {
          this._setCurrentPageHeight(newPageHeight);
        }

        if(this._endOfCluster(i, currentItem)) {
          this._enablePageBreak(currentItem);
          this._setCurrentPageHeight(0);
        }
      }
    }
  };

  PageBreakDeterminator.prototype._getPreviousItem = function(cluster, index) {
    index = (index === 0) ? 0 : index -1;
    return cluster[index];
  };

  PageBreakDeterminator.prototype._endOfCluster = function(index, item) {
    return this._isLastItemInCluster(this.clusteredItems[index], item) && this._isNotTheLastCluster(index);
  };

  PageBreakDeterminator.prototype._isNotTheLastCluster = function(index) {
    return this.clusteredItems.length - 1 !== index;
  };

  PageBreakDeterminator.prototype._isLastItemInCluster = function(cluster, item) {
    return cluster.indexOf(item) === cluster.length - 1;
  };

  PageBreakDeterminator.prototype._heightIncludingNextItem = function(item) {
    return this.currentPageHeight +
      item["question_group_height_in_cm_" + this.language];
  };

  PageBreakDeterminator.prototype._setCurrentPageHeight = function(value) {
    this.currentPageHeight = value;
  };

  PageBreakDeterminator.prototype._enablePageBreak = function(item){
    item["pageBreak" + this.language.toUpperCase()] = true;
  };

  PageBreakDeterminator.prototype._disablePageBreak = function(item){
    item["pageBreak" + this.language.toUpperCase()] = false;
  };

  return PageBreakDeterminator;
}]);
