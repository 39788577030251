import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { isDefined } from '../common/utils/type-guards/is-defined';
import { LoadPool } from './state/pool.actions';
import { PoolState } from './state/pool.state';

@Injectable({ providedIn: 'root' })
export class PoolGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(PoolState.pool).pipe(
      take(1),
      filter(isDefined),
      map(({ data }) => data?.id),
      tap(id => {
        if (id === route.params.id) {
          return;
        }

        this.store.dispatch(new LoadPool(route.params.id));
      }),
      map(() => true)
    );
  }
}
