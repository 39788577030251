import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Language, UserInput } from '../../../generated/base-types';
import { emailValidator } from '../../admin/form/utils/email.validator';
import { matchValidator } from '../../admin/form/utils/match.validator';
import { LoadUsersFieldsFragment } from '../../admin/services/load-users.fragments.generated';
import { ProfileFormState } from '../state/profile-form.state';

const languages = Object.values(Language).map(language => ({
  label: `common.languages.${language}`,
  value: language
}));

@Component({
  selector: 'man-profile-form',
  templateUrl: './profile-form.component.html',
  host: { class: 'container content d-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileFormComponent {
  @Input() public set user(value: LoadUsersFieldsFragment) {
    this.form.reset(value);
  }

  @Output() public create = new EventEmitter<UserInput>();
  @Output() public update = new EventEmitter<UserInput & { id: string }>();
  @Output() public closed = new EventEmitter<void>();
  @Output() public readonly stateChange: Observable<boolean>;

  @ViewChild('passwordInput')
  public passwordInput: ElementRef<HTMLInputElement>;

  public readonly languageOptions: {
    label: string;
    value: Language;
  }[] = languages;
  public form: FormGroup;
  public readonly disabled$: Observable<boolean>;
  public passwordFieldVisible = false;

  constructor(
    public translate: TranslateService,
    private store: Store,
    private formBuilder: FormBuilder
  ) {
    this.form = this.createForm();
    this.disabled$ = this.store
      .select(ProfileFormState.mutationRequestState)
      .pipe(
        map(requestState => requestState === 'loading'),
        distinctUntilChanged()
      );

    this.stateChange = this.form.valueChanges.pipe(
      map(() => this.form.dirty),
      distinctUntilChanged()
    );
  }

  public onSave(): void {
    if (!this.form.valid || !this.form.dirty) {
      return;
    }

    // filtering out email and alternativeEmail
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const { _email, _alternativeEmail, ...rest } = this.form.value;
    this.update.emit(rest);
  }

  public showPasswordField(): void {
    this.passwordFieldVisible = true;
    setTimeout(() => {
      this.passwordInput.nativeElement.focus();
    }, 0);
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      email: [
        { value: '', disabled: true },
        [emailValidator, Validators.required]
      ],
      alternativeEmail: [{ value: '', disabled: true }, [emailValidator]],
      password: [
        undefined,
        [
          Validators.minLength(8),
          Validators.maxLength(128),
          matchValidator('passwordConfirmation', true)
        ]
      ],
      passwordConfirmation: [
        undefined,
        [
          Validators.minLength(8),
          Validators.maxLength(128),
          matchValidator('password')
        ]
      ],
      language: [Language.En, [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobilePhone: '',
      alternativeMobilePhone: ''
    });
  }
}
