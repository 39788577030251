angular.module('common').factory('Panel', [ 'Store', 'Actions::Panel', function (Store, Actions) {
  var Panel;
  Panel = (function() {
    var storageKey = 'panels';

    function Panel() {
      this.restoreData();
    }

    Panel.prototype.storeData = function() {
      localStorage.setItem(storageKey, JSON.stringify({
        left: {hidden: this.isHidden('left')},
        center: {hidden: this.isHidden('center')},
        right: {hidden: this.isHidden('right')}
      }));
    };

    Panel.prototype.restoreData = function() {
      var data = JSON.parse(localStorage.getItem(storageKey)) || {};
      if (data.left && data.left.hidden) {
        Store.dispatch(Actions.hide('left'));
      }
      if (data.center && data.center.hidden) {
        Store.dispatch(Actions.hide('center'));
      }
      if (data.right && data.right.hidden) {
        Store.dispatch(Actions.hide('right'));
      }
    };

    Panel.prototype.hide = function(panel) {
      Store.dispatch(Actions.hide(panel));
      this.storeData();
    };

    Panel.prototype.show = function(panel) {
      Store.dispatch(Actions.show(panel));
      this.storeData();
    };

    Panel.prototype.toggle = function(panel) {
      Store.dispatch(Actions.toggle(panel));
      this.storeData();
    };

    Panel.prototype.loading = function(panel) {
      Store.dispatch(Actions.startLoading(panel));
    };

    Panel.prototype.stopLoading = function(panel) {
      Store.dispatch(Actions.stopLoading(panel));
    };

    Panel.prototype.isHidden = function(panel) {
      return Store.getState().panels[panel].hidden;
    };

    Panel.prototype.isLoading = function(panel) {
      return Store.getState().panels[panel].loading;
    };

    return Panel;

  })();

  return new Panel();
}]);
