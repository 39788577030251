<div class="d-flex justify-content-center pt-3 pb-2 mb-2">
  <button (click)="openExamsModal()" class="btn btn-primary mr-3">
    {{ 'question_management.toolbar.for_exam' | translate }}
  </button>
  <button (click)="openCommentModal()" class="btn btn-primary mr-3">
    {{ 'question_management.toolbar.comment' | translate }}
  </button>
  <button (click)="openLabelModal()" class="btn btn-primary mr-3">
    {{ 'question_management.toolbar.label' | translate }}
  </button>
  <button (click)="openRevisionStatusModal()" class="btn btn-primary mr-3">
    {{ 'question_management.toolbar.revision_status' | translate }}
  </button>
  <button (click)="openRevisionDocumentModal()" class="btn btn-primary mr-3">
    {{ 'question_management.toolbar.revision_document' | translate }}
  </button>
  <button (click)="openDeleteStatisticsModal()" class="btn btn-primary">
    {{ 'question_management.toolbar.delete_statistics' | translate }}
  </button>
</div>
