/*
 * Testing this directive is very hard since it relies on x and y coordinates
 * of elements. So we leave this untested.
 * If one changes this code, he needs to test all drag & drop functionality
 * thoroughly by hand.
 */

angular.module('common').directive('sortableTable', function () {
  function DraggableTable (baseElement, config) {
    this.draggedRow = null;
    this.baseElement = baseElement;
    this.config = config;
    this.moveRowCallbacks = [];

    baseElement.addEventListener('mousedown', this._startDragHandler.bind(this), false);
    baseElement.addEventListener('mousemove', this._mouseMoveHandler.bind(this), false);
    baseElement.addEventListener('mouseup', this._finishDragHandler.bind(this), false);
  }

  DraggableTable.prototype._startDragHandler = function (event) {
    if (this._isDragHandlePartOfElement(event.target) && this._isEnabled()) {
      this.draggedRow  = this._findNearestRow(event.target);

      if (this.config.start) {
        this.config.start(event, this.baseElement, this.draggedRow);
      }

      return false;
    }
  };

  DraggableTable.prototype._mouseMoveHandler = function (event) {
    if (this.draggedRow && event.movementY) {
      var draggedRowIndex, currentRowIndex,
      movingDown = event.movementY > 0,
      currentRow = this._findDropTargetRow({x: event.clientX, y: event.clientY});

      if (currentRow) {
        draggedRowIndex = this._findElementIndex(this.draggedRow);
        currentRowIndex = this._findElementIndex(currentRow);
        if (movingDown && this.draggedRow != currentRow) {
          if (this.config.moveDomElements) {
            this.draggedRow.parentNode.insertBefore(this.draggedRow, currentRow.nextSibling);
          }
          this.notifyMoveRowCallbacks(draggedRowIndex, currentRowIndex);
        } else if (! movingDown && this.draggedRow != currentRow) {
          if (this.config.moveDomElements) {
            this.draggedRow.parentNode.insertBefore(this.draggedRow, currentRow);
          }
          this.notifyMoveRowCallbacks(draggedRowIndex, currentRowIndex);
        }
      }
      return false;
    }
  };

  DraggableTable.prototype._finishDragHandler = function (event) {
    if (this.draggedRow) {
      if (this.config.stop) {
        this.config.stop(event, this.baseElement, this.draggedRow);
      }
      this.draggedRow = null;
    }
  };

  DraggableTable.prototype._isEnabled = function () {
    if (this.config.isEnabled) {
      return this.config.isEnabled();
    }
    else {
      return true;
    }
  };

  DraggableTable.prototype._isDragHandlePartOfElement = function (element) {
    while(element !== this.baseElement) {
      if (element.classList.contains('js-drag-handle')) {
        return true;
      }
      element = element.parentNode;
    }
    return false;
  };

  DraggableTable.prototype._findElementIndex = function (element) {
    return [].indexOf.call(element.parentNode.children, element);
  };

  DraggableTable.prototype._findDropTargetRow = function (pos) {
    var element = this._findNearestRow(document.elementFromPoint(pos.x, pos.y));

    if (this._isElementRowOfCurrentTable(element)) {
      return element;
    }
    else {
      return null;
    }
  };

  DraggableTable.prototype._findNearestRow = function (element) {
    while (!(element.nodeName === 'TR' || element.classList.contains('js-row')) && element !== document.body) {
      element = element.parentNode;
    }
    return element;
  };

  DraggableTable.prototype._isElementRowOfCurrentTable = function (element) {
    while (element !== document.body) {
      if (element === this.baseElement) {
        return true;
      }
      element = element.parentNode;
    }
    return false;
  };

  DraggableTable.prototype.onMoveRow = function (callback) {
    this.moveRowCallbacks.push(callback);
  };

  DraggableTable.prototype.notifyMoveRowCallbacks = function (oldIndex, newIndex) {
    for(var i = 0; i < this.moveRowCallbacks.length; i++) {
      this.moveRowCallbacks[i](oldIndex, newIndex);
    }
  };

  return {
    restrict: 'A',
    require: 'ngModel',
    link: function ($scope, $element, $attrs, ngModel) {
      var unwatchConfig = $scope.$watch($attrs.sortableTable, function (value) {
        if (value !== undefined) {
          unwatchConfig();
          var tableSorter = new DraggableTable($element[0], $scope.$eval($attrs.sortableTable));
          tableSorter.onMoveRow(function (oldIndex, newIndex) {
            var movedItem = ngModel.$modelValue.splice(oldIndex, 1)[0];
            ngModel.$modelValue.splice(newIndex, 0, movedItem);
            $scope.$digest();
          });
        }
      });
    }
  };
});
