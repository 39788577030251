import { isVoid, Voidable } from './voidable';

export function isDefined<T>(value: T | Voidable): value is T {
  return value !== null && value !== undefined;
}

export function assertIsDefined<T>(
  value: T | undefined | null,
  message?: string
): asserts value is T {
  if (isVoid(value)) {
    throw new Error(message ?? 'An essential value is undefined.');
  }
}
