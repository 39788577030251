/* eslint-disable unicorn/no-null */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ModalService } from 'src/app/admin/common/modal/modal.service';
import { PoolPermissionsFragment } from 'src/app/admin/services/load-pool-permissions.fragments.generated';
import { isDefined } from 'src/app/common/utils/type-guards/is-defined';
import { PermissionInput } from 'src/generated/base-types';

@Component({
  selector: 'man-permission-form',
  templateUrl: './permission-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionFormComponent implements OnInit, OnDestroy {
  @Input() public permission?: Partial<PoolPermissionsFragment>;
  @Input() public selectionMode: 'pool' | 'user';
  @Input() public set poolId(value: string | undefined) {
    this.form.get('poolId')!.setValue(value);
  }
  public get poolId(): string {
    return this.form.get('poolId')?.value ?? '';
  }

  @Input() public set disabled(value: boolean | undefined) {
    value === true ? this.form?.disable() : this.form?.enable();
  }
  public get disabled(): boolean {
    return this.form?.disabled ?? true;
  }

  @Output() public create = new EventEmitter<PermissionInput>();
  @Output() public update = new EventEmitter<PermissionInput>();
  @Output() public closed = new EventEmitter<void>();

  public form = new FormGroup({
    role: new FormControl<PermissionInput['role']>(null, [Validators.required]),
    userId: new FormControl<PermissionInput['userId']>(null, [
      Validators.required
    ]),
    subjectId: new FormControl<PermissionInput['subjectId']>(null),
    poolId: new FormControl<PermissionInput['poolId']>(null)
  });

  public get edit(): boolean {
    return this.permission !== undefined;
  }

  private destroy$ = new Subject<void>();

  constructor(private readonly modalService: ModalService) {}

  public ngOnInit(): void {
    this.initialiseForm();
    this.handleRoleChange();
    this.handlePoolChange();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async onClose(): Promise<void> {
    if (this.disabled) return;
    await this.safeClose();
  }

  public onSave(): void {
    if (!isDefined(this.form) || !this.form.valid || !this.form.dirty) return;

    isDefined(this.permission)
      ? this.update.emit(this.form.value)
      : this.create.emit(this.form.value);
  }

  private async safeClose(): Promise<void> {
    if (this.form?.dirty ?? false) {
      const discardChanges = await this.modalService.confirmUnsavedChanges();
      if (discardChanges) {
        this.closed.emit();
      }
    } else {
      this.closed.emit();
    }
  }

  private initialiseForm(): void {
    if (this.permission === undefined) return;

    this.form.patchValue(
      {
        role: this.permission.role,
        userId: this.permission.user?.id,
        subjectId: this.permission.subject?.id,
        poolId: this.permission.pool?.id
      },
      { emitEvent: false }
    );
  }

  private handleRoleChange(): void {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => prev.role === curr.role),
        tap(() =>
          this.form.patchValue({ subjectId: null }, { emitEvent: false })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private handlePoolChange(): void {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => prev.poolId === curr.poolId),
        tap(() => {
          this.form.patchValue({ subjectId: null }, { emitEvent: false });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
