<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_group_form.change_type_modal.title' | translate }}</h3>
  </div>
  <div class="loading p-5" *ngIf="loading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div class="modal-body" *ngIf="!loading">
    <div class="form-group row bs5">
      <label class="col-sm-3 valign" for="type">
        {{ 'question_group_form.change_type_modal.type' | translate }}
      </label>
      <div class="btn-group valign" *ngIf="transformation">
        <co-radio-button-group
          [(ngModel)]="transformation.type"
          (ngModelChange)="questionTypeChanged()"
          class="form-control"
        >
          <ng-container *ngFor="let transform of transformations">
            <button [value]="transform.type" class="btn btn-primary-migrated">
              {{ 'questions.type.' + transform.type | translate }}
            </button>
          </ng-container>
        </co-radio-button-group>
      </div>
    </div>
    <div
      class="form-group row"
      *ngIf="transformation?.necessaryOperations?.length === 0"
    >
      <p class="col-sm-12">
        {{
          'question_group_form.change_type_modal.user_interactions.none.info'
            | translate : { type: translatedType }
        }}
      </p>
    </div>
    <div
      class="form-group row"
      *ngFor="let operation of transformation?.necessaryOperations"
    >
      <p class="col-sm-12" *ngIf="operation === 'remove_response_option'">
        {{
          'question_group_form.change_type_modal.user_interactions.remove_response_option.info'
            | translate : { type: translatedType }
        }}
      </p>
      <div class="col-sm-12">
        <co-radio-button-group>
          <ng-container
            *ngFor="let solution of getResponseOptions(); index as index"
          >
            <div>
              <input
                type="radio"
                [id]="'select-solution-checkbox-' + solution.id"
                [checked]="index === solutionToDelete"
                (change)="solutionToDelete = index"
              />
              <label
                [for]="'select-solution-checkbox-' + solution.id"
                placement="right"
                [innerHtml]="solution.content![language]"
              >
              </label>
            </div>
          </ng-container>
        </co-radio-button-group>
      </div>
    </div>
    <div
      class="form-group row skin-message--alert"
      *ngIf="transformation?.deleteSolution"
    >
      <p class="col-sm-12">
        <b>{{ 'question_group_form.change_type_modal.warning' | translate }}</b>
        {{
          'question_group_form.change_type_modal.delete_solution' | translate
        }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default" (click)="dismiss()" type="button">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      class="btn btn-primary"
      (click)="transform()"
      [disabled]="!canSubmit()"
      type="button"
    >
      {{
        'question_group_form.change_type_modal.actions.transform' | translate
      }}
    </button>
  </div>
</div>
