import { QuestionType } from 'src/generated/base-types';

export function stringToQuestionType(type: string): QuestionType {
  switch (type) {
    case 'a': {
      return QuestionType.A;
    }

    case 'aMinus': {
      return QuestionType.AMinus;
    }

    case 'e': {
      return QuestionType.E;
    }

    case 'kPrime': {
      return QuestionType.KPrime;
    }

    case 'freeText': {
      return QuestionType.FreeText;
    }

    // used as type in AngularJS URL queries
    case 'Essay': {
      return QuestionType.FreeText;
    }

    case 'longMenu': {
      return QuestionType.LongMenu;
    }

    default: {
      throw new Error('Unknown question type');
    }
  }
}
