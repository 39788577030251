angular.module('common').factory('Repositories::Label', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getAllQuery = 'query {current_pool {labels {id name color usage_count}}}',
      createQuery =
        'mutation($name: String!, $color: String!) {' +
        '  create_label(name: $name, color: $color) {label {id name color}}' +
        '}',
      updateQuery =
        'mutation($label_id: Int!, $name: String!, $color: String!) {' +
        '  update_label(label_id: $label_id, name: $name, color: $color) {label {id name color}}' +
        '}',
      deleteQuery = 'mutation($label_id: Int!) {delete_label(label_id: $label_id) {label {id}}}';

    return {
      getAll: function() {
        return client.query(getAllQuery).then(function(response) {
          return response.data.data.current_pool.labels;
        });
      },

      create: function(name, color) {
        return client.query(createQuery, { name: name, color: color }).then(function(response) {
          return response.data.data.create_label;
        });
      },

      update: function(id, name, color) {
        return client
          .query(updateQuery, { label_id: id, name: name, color: color })
          .then(function(response) {
            return response.data.data.update_label;
          });
      },

      delete: function(id) {
        return client.query(deleteQuery, { label_id: id }).then(function(response) {
          return response.data.data.delete_label;
        });
      }
    };
  }
]);
