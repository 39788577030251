<h5>
  {{
    'common.question_group_details.statistics.type_e_statistics.title'
      | translate
  }}
</h5>
<b *ngIf="!validStatistics">
  {{
    'common.question_group_details.statistics.type_e_statistics.no_statistics'
      | translate
  }}
</b>
<table class="table" *ngIf="validStatistics">
  <thead>
    <tr>
      <th>
        {{
          'common.question_group_details.statistics.response_options.response'
            | translate
        }}
      </th>
      <th>
        {{ 'common.question_group_details.statistics.table.p' | translate }}
      </th>
      <th>
        {{
          'common.question_group_details.statistics.response_options.solution'
            | translate
        }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'aBecauseB', stats: statistics.aBecauseB }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'aB', stats: statistics.aB }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'aNotB', stats: statistics.aNotB }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'notAB', stats: statistics.notAB }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        responseOptionRow;
        context: { option: 'notANotB', stats: statistics.notANotB }
      "
    >
    </ng-container>
    <tr *ngIf="statistics.empty !== undefined && statistics.empty !== null">
      <td>
        {{
          'common.question_group_details.statistics.type_e_statistics.statistics.empty'
            | translate
        }}
      </td>
      <td>
        {{ statistics.empty.p | percentage | fixedNumber : 1 }}
      </td>
      <td></td>
    </tr>
  </tbody>
</table>

<ng-template #responseOptionRow let-option="option" let-stats="stats">
  <tr
    *ngIf="stats !== undefined && stats !== null"
    [ngClass]="{ selected: stats.solution }"
  >
    <td>
      {{
        'common.question_group_details.statistics.type_e_statistics.statistics.' +
          option | translate
      }}
    </td>
    <td>{{ stats.p | percentage | fixedNumber : 1 }}</td>
    <td>{{ 'common.' + stats.solution | translate }}</td>
  </tr>
</ng-template>
