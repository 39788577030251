import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteAnnouncementMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteAnnouncementMutation = { __typename?: 'Mutation', deleteAnnouncement?: { __typename?: 'DeleteAnnouncementPayload', successful: boolean } | null };

export const DeleteAnnouncementDocument = gql`
    mutation DeleteAnnouncement($id: ID!) {
  deleteAnnouncement(id: $id) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAnnouncementGQL extends Apollo.Mutation<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables> {
    document = DeleteAnnouncementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }