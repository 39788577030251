import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { AnnouncementListElementFragmentDoc } from './load-announcements.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateAnnouncementMutationVariables = Types.Exact<{
  attributes: Types.AnnouncementInput;
}>;


export type CreateAnnouncementMutation = { __typename?: 'Mutation', createAnnouncement?: { __typename?: 'CreateAnnouncementPayload', successful: boolean, announcement?: { __typename?: 'Announcement', id: string, startsAt: string, endsAt: string, severity: Types.AnnouncementSeverity, enabled: boolean, message: { __typename?: 'TranslatedContent', de?: string | null, fr?: string | null, en?: string | null, it?: string | null } } | null } | null };

export const CreateAnnouncementDocument = gql`
    mutation CreateAnnouncement($attributes: AnnouncementInput!) {
  createAnnouncement(attributes: $attributes) {
    announcement {
      ...AnnouncementListElement
    }
    successful
  }
}
    ${AnnouncementListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAnnouncementGQL extends Apollo.Mutation<CreateAnnouncementMutation, CreateAnnouncementMutationVariables> {
    document = CreateAnnouncementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }