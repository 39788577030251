<div class="modal-wrapper">
  <div class="modal-header">
    <a class="close" (click)="cancel()">&times;</a>
    <h3>
      {{ 'question_management.form_changes_modal.title' | translate }}
    </h3>
  </div>
  <div class="modal-body">
    <p>
      {{ 'question_management.form_changes_modal.content' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="cancel()" style="margin: 5px">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button class="btn btn-primary" (click)="save()" style="margin: 5px">
      {{ 'common.ok' | translate }}
    </button>
  </div>
</div>
