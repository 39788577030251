import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/generated/base-types';

@Pipe({
  name: 'formatName'
})
export class FormatNamePipe implements PipeTransform {
  public transform(
    user: Pick<User, 'firstName' | 'lastName'>,
    format: 'full' | 'short' | 'reverse' = 'full'
  ): string {
    switch (format) {
      case 'short': {
        return `${user.firstName.charAt(0)}. ${user.lastName}`;
      }
      case 'reverse': {
        return ` ${user.lastName}, ${user.firstName}`;
      }
      default: {
        return `${user.firstName} ${user.lastName}`;
      }
    }
  }
}
