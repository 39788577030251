angular.module('common').factory('NiceCheckboxInputErrorHandler', ['BasicErrorHandler', function (BasicErrorHandler) {
  function NiceCheckboxInputErrorHandler (element) {
    BasicErrorHandler.bind(this)(element, {
      fieldSelector: 'nice-checkbox',
      iconSelector: '.nice-checkbox-inline-error__icon',
      tooltipSelector: '.inline-error__tooltip'
    });
  }

  NiceCheckboxInputErrorHandler.prototype = angular.copy(BasicErrorHandler.prototype);

  NiceCheckboxInputErrorHandler.prototype.readFieldValue = function () {
    return this.element.find('i').hasClass('fa-check');
  };

  return NiceCheckboxInputErrorHandler;
}]);
