<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="dismiss()">&times;</a>
    <h3>{{ 'question_management.supervisor_modal.title' | translate }}</h3>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="form-group">
        <label class="col-sm-3 control-label">{{
          'questions.edit.supervisor' | translate
        }}</label>
        <div class="col-sm-9">
          <ng-select
            [items]="supervisorOptions"
            [addTag]="addTag"
            addTagText="{{ 'actions.add' | translate }}"
            bindValue="value"
            [(ngModel)]="supervisor"
            [clearable]="false"
            [ngModelOptions]="{ standalone: true }"
            id="supervisor"
            ngDefaultControl
          ></ng-select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="dismiss()" class="btn btn-secondary-migrated">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button
      (click)="save()"
      [disabled]="isSaving"
      class="btn btn-primary-migrated"
    >
      {{ 'common.actions.save' | translate }}
    </button>
  </div>
</div>
