angular.module('examManagement').factory('Repositories::Solution', [
  'GraphQLClient',
  'Numerator',
  function(GraphQLClient, Numerator) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getAllQuery =
        'query($id: Int!) {' +
        '  exam(id: $id) { id' +
        '    booklet_a {' +
        '      exam_question_groups {' +
        '        position_a position_b' +
        '        question_group {' +
        '          type sequential_number ' +
        '          questions {' +
        '            solution_key type' +
        '          }' +
        '        }' +
        '      }' +
        '    }' +
        '  }' +
        '}';

    return {
      getAll: function(examId) {
        return client.query(getAllQuery, { id: examId }).then(function(response) {
          var examQuestionGroups = response.data.data.exam.booklet_a.exam_question_groups;
          new Numerator(examQuestionGroups).run();
          return examQuestionGroups;
        });
      }
    };
  }
]);
