/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/no-empty-function */

import { QuestionGroupListElementFragment } from 'src/app/question-form/services/load-question-group-list.generated';
import { ListMetadata, QuestionGroupSortInput } from 'src/generated/base-types';

export class LoadQuestionGroupList {
  public static readonly type =
    '[QuestionManagement List] Load Question Groups List';
  constructor(
    public readonly from: number,
    public readonly rows?: number,
    public readonly sort?: QuestionGroupSortInput[]
  ) {}
}

export class LoadQuestionGroupListSuccess {
  public static readonly type =
    '[QuestionManagement List] Load Question Groups List Success';
  constructor(
    public readonly questionGroupList: QuestionGroupListElementFragment[],
    public readonly questionGroupListMetadata: ListMetadata
  ) {}
}

export class LoadQuestionGroupListFailure {
  public static readonly type =
    '[QuestionManagement List] Load Question Groups List Failure';
}

export class ReloadQuestionGroupList {
  public static readonly type =
    '[QuestionManagement List] Reload Question Groups List';
  constructor() {}
}

export class SetSelectedQuestionGroups {
  public static readonly type =
    '[QuestionManagement List] Set Selected Question Group IDs';
  constructor(
    public readonly questionGroups: QuestionGroupListElementFragment[]
  ) {}
}

export class ReloadSelectedQuestionGroups {
  public static readonly type =
    '[QuestionManagement List] Reload Selected Question Groups';
  constructor() {}
}

export class ResetSelectedQuestionGroups {
  public static readonly type =
    '[QuestionManagement List] Reset Selected Question Groups';
  constructor() {}
}

export class SetSortingQuestionGroupList {
  public static readonly type =
    '[QuestionManagement List] Set sorting attributes';
  constructor(public sortAttributes?: QuestionGroupSortInput[]) {}
}
