import { utils, write } from 'xlsx';
import { ExportColumnDefinition } from './export-column-definition';

export class XlsxExportGeneratorService<T> {
  private objects: T[] = [];

  constructor(private columnDefinitions: ExportColumnDefinition<T>[]) {}

  public add(object: T): void {
    this.objects = [...this.objects, object];
  }

  public addObjects(objects: T[]): void {
    this.objects = [...this.objects, ...objects];
  }

  public toString(): ArrayBuffer {
    const headers = this.columnDefinitions.map(definition => {
      return definition.name;
    });
    const rows = this.objects.map(object => {
      return this.columnDefinitions.map(definition => definition.value(object));
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(
      workbook,
      utils.aoa_to_sheet([headers].concat(rows)),
      'Export'
    );

    return write(workbook, { bookType: 'xlsx', type: 'array' });
  }
}
