angular.module('common').factory('Reducers::ConnectionStatus', [
  'Actions::ConnectionStatus',
  function(Actions) {
    function reducer(state, action) {
      state = state || {};
      switch (action.type) {
        case Actions.SET_CONNECTION_PRESENT:
          return Object.assign({}, state, {
            connected: true
          });
        case Actions.SET_CONNECTION_MISSING:
          return Object.assign({}, state, {
            connected: false
          });

        default:
          return state;
      }
    }

    return reducer;
  }
]);
