/* eslint-disable @typescript-eslint/no-explicit-any */

export enum OriginReference {
  QuestionManagement = 'questionManagement',
  TaskManagement = 'taskManagement',
  ExamManagement = 'examManagement'
}
export interface UibModalInstance {
  result: Promise<any>;
  close: (...args: any[]) => void;
  dismiss: (reason?: any) => void;
}

export interface LegacyStore {
  dispatch(action: unknown): void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  subscribeOn(selector: unknown, callback: Function): () => void;
  getState(): unknown;
}
