angular.module('common').directive('responseOptionsStatisticsMultipleSolutions', [function () {
  return {
    restrict: 'E',
    template: require('./response_options_multiple_solutions.html'),
    scope: {
      statistic: '='
    },
    controller: ['$scope', function($scope) {
      $scope.I18n = I18n;
    }]
  };
}]);
