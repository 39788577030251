angular.module('common').directive('statistics', [
  'calculateStatsRangeFilter',
  'percentageFilter',
  function (calculateStatsRange, percentage) {
    var QuestionGroupStatisticView, QuestionStatisticView, calculateRange;

    calculateRange = function (
      questionStatistics,
      valueName,
      precision = 3,
      transformator = function (value) {
        return value;
      }
    ) {
      if (!questionStatistics) {
        return;
      }

      const questionsValue = questionStatistics
        .map(statistic => statistic.total[valueName])
        .map(transformator);

      return calculateStatsRange(questionsValue, precision);
    };

    QuestionGroupStatisticView = (function () {
      function QuestionGroupStatisticView(striped, statistic) {
        this.isQuestionGroup = true;
        this.striped = striped;
        this.hidden = false;
        this.questionsHidden = true;
        this.exam = statistic.exam.name;
        this.date = statistic.exam.date;

        this.n = calculateRange(statistic.questions, 'n', 0);
        this.p_gp = calculateRange(statistic.questions, 'p_gp', 1, percentage);
        this.p_hp = calculateRange(statistic.questions, 'p_hp', 1, percentage);
        this.r = calculateRange(statistic.questions, 'r', 2);
        this.lne = calculateRange(statistic.questions, 'lne', 3);
        this.eliminated = statistic.questions.map(
          question => question.total.eliminated
        );

        this.origin = null;
        this.questions = [];
      }

      QuestionGroupStatisticView.prototype.addQuestion = function (question) {
        this.questions.push(question);
      };

      QuestionGroupStatisticView.prototype.showQuestions = function () {
        this.questionsHidden = false;
        this.questions.forEach(function (question) {
          question.show();
        });
      };

      QuestionGroupStatisticView.prototype.hideQuestions = function () {
        this.questionsHidden = true;
        this.questions.forEach(function (question) {
          question.hide();
        });
      };

      return QuestionGroupStatisticView;
    })();

    QuestionStatisticView = (function () {
      function QuestionStatisticView(striped, statistic) {
        this.isQuestionGroup = false;
        this.striped = striped;
        this.hidden = true;
        this.position = statistic.question.position;
        this.n = statistic.total.n;
        this.p_gp = percentage(statistic.total.p_gp);
        this.p_hp = percentage(statistic.total.p_hp);
        this.r = statistic.total.r;
        this.lne = statistic.total.lne;
        this.eliminated = statistic.total.eliminated;
        this.origin = statistic;
        if (this.origin.diverter && this.origin.diverter.A === null) {
          delete this.origin.diverter.A;
        }
        if (this.origin.diverter && this.origin.diverter.B === null) {
          delete this.origin.diverter.B;
        }
        if (this.origin.diverter && this.origin.diverter.C === null) {
          delete this.origin.diverter.C;
        }
        if (this.origin.diverter && this.origin.diverter.D === null) {
          delete this.origin.diverter.D;
        }
        if (this.origin.diverter && this.origin.diverter.E === null) {
          delete this.origin.diverter.E;
        }
      }

      QuestionStatisticView.prototype.show = function () {
        return (this.hidden = false);
      };

      QuestionStatisticView.prototype.hide = function () {
        return (this.hidden = true);
      };

      return QuestionStatisticView;
    })();

    return {
      restrict: 'E',
      template: require('./statistics.html'),
      scope: {
        statistics: '='
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.I18n = I18n;
          $scope.questionStatisticsVisible = [];
          $scope.$watch(
            function () {
              return $scope.statistics;
            },
            function () {
              $scope.questionStatisticsVisible = [];
              $scope.activeStatistic = void 0;
              $scope.flatStatistics = [];
              let striped = true;
              ($scope.statistics || []).forEach(questionGroupStatistic => {
                striped = !striped;
                const questionGroupView = new QuestionGroupStatisticView(
                  striped,
                  questionGroupStatistic
                );
                $scope.flatStatistics.push(questionGroupView);
                (questionGroupStatistic.questions || []).forEach(
                  questionStatistic => {
                    const questionView = new QuestionStatisticView(
                      striped,
                      questionStatistic
                    );
                    questionGroupView.addQuestion(questionView);
                    $scope.flatStatistics.push(questionView);
                  }
                );
              });
              selectFirstQuestionInitially();
            }
          );

          $scope.showQuestionStatistics = function (questionGroup) {
            var i, statistics, statistic;
            if (!questionGroup.isQuestionGroup) {
              return;
            }
            statistics = $scope.flatStatistics;
            for (i = 0; i < statistics.length; i++) {
              statistic = statistics[i];
              if (statistic.isQuestionGroup) {
                statistic.hideQuestions();
              }
            }
            questionGroup.showQuestions();
          };

          $scope.showSubgroups = function (statistic) {
            $scope.activeStatistic = statistic.origin;
          };

          var selectFirstQuestionInitially = function () {
            if ($scope.flatStatistics.length < 2) {
              return;
            }
            $scope.showQuestionStatistics($scope.flatStatistics[0]);
            $scope.showSubgroups($scope.flatStatistics[1]);
          };
        }
      ]
    };
  }
]);
