import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RevisionDocumentQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  displayOptions: Types.RevisionDocumentInput;
}>;


export type RevisionDocumentQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', revisionDocument: { __typename?: 'DownloadUrl', url: string } } };

export const RevisionDocumentDocument = gql`
    query RevisionDocument($poolId: ID!, $displayOptions: RevisionDocumentInput!) {
  pool(id: $poolId) {
    revisionDocument(displayOptions: $displayOptions) {
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RevisionDocumentGQL extends Apollo.Query<RevisionDocumentQuery, RevisionDocumentQueryVariables> {
    document = RevisionDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }