import { Injectable } from '@angular/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import {
  Affiliation,
  Language,
  QuestionGroup,
  QuestionGroupInput
} from '../../../generated/base-types';
import { takeGraphQLResult } from '../../common/operators/take-graphql-response';
import { QuestionContentAssetTransformerService } from '../../common/services/question-content-transformer/question-content-asset-transformer.service';
import { parseError } from '../../common/utils/error-parser';
import { RemoteData } from '../../common/utils/remote-data';
import { isDefined } from '../../common/utils/type-guards/is-defined';
import { FormDimension } from '../form-types';
import { fillQuestionGroupQuestionsResponseOptions } from '../services/fill-question-response-options';
import { FormOptionsGQL } from '../services/form-options.generated';
import {
  QuestionGroupTypesGraphQL,
  ValidationsQuestionGroupGraphQL
} from '../services/graphql-question-group-types';
import {
  LoadDuplicateQuestionGroupGQL,
  LoadQuestionGroupGQL
} from '../services/load-question-group.generated';
import { QuestionFactoryService } from '../services/question-factory.service';
import { transformQuestionGroupToInput } from '../services/question-group-to-input';
import {
  LoadExistingQuestionGroup,
  LoadFormOptions,
  LoadFormOptionsFailure,
  LoadFormOptionsSuccess,
  LoadNewQuestionGroup,
  LoadNewQuestionGroupFromExistingFormData,
  SetUploadingImage
} from './form.actions';

interface FormOptionsModel {
  languages: Language[];
  affiliations: Affiliation[];
  dimensions: FormDimension[];
  authors: string[];
  supervisors: string[];
}

export type FormQuestionGroup = Pick<QuestionGroup, 'id' | 'sequentialNumber'>;

export interface FormStateModel {
  options: RemoteData<FormOptionsModel>;
  questionGroupInput: RemoteData<QuestionGroupInput>;
  questionGroup: RemoteData<FormQuestionGroup>;
  validations: RemoteData<ValidationsQuestionGroupGraphQL>;
  uploadingImage: boolean;
}

@State<FormStateModel>({
  name: 'form',
  defaults: {
    options: {
      requestState: 'initial',
      data: {
        languages: [],
        affiliations: [],
        dimensions: [],
        authors: [],
        supervisors: []
      }
    },
    questionGroupInput: {
      requestState: 'initial'
    },
    questionGroup: {
      requestState: 'initial'
    },
    validations: {
      requestState: 'initial'
    },
    uploadingImage: false
  }
})
@Injectable({
  providedIn: 'root'
})
export class FormState {
  constructor(
    private formOptionsService: FormOptionsGQL,
    private loadQuestionGroupGQL: LoadQuestionGroupGQL,
    private loadDuplicateQuestionGroupGQL: LoadDuplicateQuestionGroupGQL,
    private questionFactory: QuestionFactoryService,
    private contentTransformerService: QuestionContentAssetTransformerService
  ) {}

  @Selector()
  public static questionGroupInput(
    state: FormStateModel
  ): QuestionGroupInput | undefined {
    return state.questionGroupInput.data;
  }

  @Selector()
  public static questionGroup(
    state: FormStateModel
  ): FormQuestionGroup | undefined {
    return state.questionGroup.data;
  }

  @Selector()
  public static validations(
    state: FormStateModel
  ): ValidationsQuestionGroupGraphQL {
    return state.validations.data || { questions: [] };
  }

  @Selector()
  public static affiliations(state: FormStateModel): Affiliation[] {
    return state.options.data?.affiliations || [];
  }

  @Selector()
  public static languages(state: FormStateModel): Language[] {
    return state.options.data?.languages || [];
  }

  @Selector()
  public static dimensions(state: FormStateModel): FormDimension[] {
    return state.options.data?.dimensions || [];
  }

  @Selector()
  public static authors(state: FormStateModel): string[] {
    return state.options.data?.authors || [];
  }

  @Selector()
  public static supervisors(state: FormStateModel): string[] {
    return state.options.data?.supervisors || [];
  }

  @Selector()
  public static uploadingImage(state: FormStateModel): boolean {
    return state.uploadingImage;
  }

  @Action(LoadFormOptions)
  public loadFormOptions(
    ctx: StateContext<FormStateModel>,
    action: LoadFormOptions
  ): Observable<void> {
    ctx.patchState({ options: { requestState: 'loading' } });

    return this.formOptionsService.fetch({ poolId: action.id }).pipe(
      takeGraphQLResult(),
      switchMap(({ pool }) =>
        ctx.dispatch(
          new LoadFormOptionsSuccess(
            pool.languages,
            pool.affiliations,
            pool.dimensions,
            pool.authors,
            pool.supervisors
          )
        )
      ),
      catchError((error: unknown) =>
        ctx.dispatch(new LoadFormOptionsFailure(parseError(error)))
      )
    );
  }

  @Action(LoadFormOptionsSuccess)
  public loadFormOptionsSuccess(
    ctx: StateContext<FormStateModel>,
    action: LoadFormOptionsSuccess
  ): void {
    ctx.patchState({
      options: {
        requestState: 'success',
        data: {
          languages: action.languages,
          affiliations: action.affiliations,
          dimensions: action.dimensions,
          authors: action.authors,
          supervisors: action.supervisors
        }
      }
    });
  }

  @Action(LoadFormOptionsFailure)
  public loadFormOptionsFailure(
    ctx: StateContext<FormStateModel>,
    action: LoadFormOptionsFailure
  ): void {
    ctx.patchState({
      options: {
        requestState: 'failure',
        data: {
          languages: [],
          affiliations: [],
          dimensions: [],
          authors: [],
          supervisors: []
        },
        error: action.error
      }
    });
  }

  @Action(LoadNewQuestionGroupFromExistingFormData)
  public loadNewQuestionGroupFromExistingFormData(
    ctx: StateContext<FormStateModel>,
    action: LoadNewQuestionGroupFromExistingFormData
  ): void {
    ctx.patchState({
      questionGroupInput: {
        requestState: 'success',
        data: {
          title: action.questionGroupInput.title,
          revisionYear: action.questionGroupInput.revisionYear,
          sourceLanguage: action.questionGroupInput.sourceLanguage,
          affiliationId: action.questionGroupInput.affiliationId,
          supervisor: action.questionGroupInput.supervisor,
          author: action.questionGroupInput.author,
          questions: action.questionGroupInput.questions,
          type: action.questionGroupInput.type
        }
      },
      validations: {
        requestState: 'success',
        data: undefined
      }
    });
  }

  @Action(LoadNewQuestionGroup)
  public async loadNewQuestionGroup(
    ctx: StateContext<FormStateModel>,
    action: LoadNewQuestionGroup
  ): Promise<void> {
    const newQuestion = await this.questionFactory.createQuestion(
      action.questionType
    );
    ctx.patchState({
      questionGroupInput: {
        requestState: 'success',
        data: {
          supervisor: `${action.lastName} ${action.firstName}`,
          author: `${action.lastName} ${action.firstName}`,
          type: action.questionGroupType,
          questions: [newQuestion]
        }
      },
      validations: {
        requestState: 'success',
        data: undefined
      }
    });
  }

  @Action(LoadExistingQuestionGroup)
  public loadExistingQuestionGroup(
    ctx: StateContext<FormStateModel>,
    action: LoadExistingQuestionGroup
  ): Observable<void> {
    ctx.patchState({
      questionGroupInput: { requestState: 'loading' },
      questionGroup: { requestState: 'loading' }
    });

    const loadQuestionGroupService = action.isDuplicate
      ? this.loadDuplicateQuestionGroupGQL
      : this.loadQuestionGroupGQL;

    return loadQuestionGroupService
      .fetch({ questionGroupId: action.id, poolId: action.poolId })
      .pipe(
        takeGraphQLResult(),
        map(({ pool }) => pool.questionGroup),
        filter(isDefined),
        map(group => {
          const questionGroup = fillQuestionGroupQuestionsResponseOptions(
            group as QuestionGroupTypesGraphQL
          );
          let questionGroupInput = transformQuestionGroupToInput(questionGroup);
          questionGroupInput =
            this.contentTransformerService.transformInternalRepresentationToHTML(
              questionGroupInput
            );
          const validations =
            group.validations as ValidationsQuestionGroupGraphQL;

          ctx.patchState({
            questionGroup: {
              requestState: 'success',
              data: {
                id: questionGroup.id,
                sequentialNumber: questionGroup.sequentialNumber
              }
            },
            questionGroupInput: {
              requestState: 'success',
              data: questionGroupInput
            },
            validations: {
              requestState: 'success',
              data: validations
            }
          });
        })
      );
  }

  @Action(SetUploadingImage)
  public setUploadingImage(
    ctx: StateContext<FormStateModel>,
    action: SetUploadingImage
  ): void {
    ctx.patchState({
      uploadingImage: action.uploading
    });
  }
}
