angular.module('common').factory('RailsParamsSerializer', function () {

  function RailsParamsSerializer () {}

  // --------- helper functions ----------

  function sortedKeys(obj) {
    return Object.keys(obj).sort();
  }

  function forEachSorted(obj, iterator, context) {
    var keys = sortedKeys(obj);
    for (var i = 0; i < keys.length; i++) {
      iterator.call(context, obj[keys[i]], keys[i]);
    }
    return keys;
  }

  function serializeValue(v) {
    if (angular.isObject(v)) {
      return angular.isDate(v) ? v.toISOString() : angular.toJson(v);
    }
    return v;
  }

  // ----------- visibility functions ---------------

  RailsParamsSerializer.prototype.build = function(params) {
    if (!params) return '';
    var parts = [];
    forEachSorted(params, function(value, key) {
      if (value === null || angular.isUndefined(value)) return;
      if (angular.isArray(value)) {
        angular.forEach(value, function(v, k) {
          parts.push(encodeURIComponent(key)  + '[]=' + encodeURIComponent(serializeValue(v)));
        });
      } else {
        parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(serializeValue(value)));
      }
    });

    return parts.join('&');
  };

  return new RailsParamsSerializer();
});
