<form class="page form" [formGroup]="form!" (submit)="onSave()" noValidate>
  <div class="page-content">
    <man-field
      class="g-col-4"
      labelFor="shortName"
      [label]="
        'admin.pools.revision_status.table.form.label.short_name' | translate
      "
      [errors]="form! | fieldErrors : 'shortName'"
    >
      <input
        id="shortName"
        type="text"
        formControlName="shortName"
        class="form-control"
        cdkFocusInitial
      />
    </man-field>
    <man-field
      class="g-col-4"
      labelFor="description"
      [label]="
        'admin.pools.revision_status.table.form.label.description' | translate
      "
      [errors]="form! | fieldErrors : 'description'"
    >
      <input
        id="description"
        type="text"
        formControlName="description"
        class="form-control"
        cdkFocusInitial
      />
    </man-field>
  </div>

  <div class="page-footer">
    <button
      type="submit"
      class="btn btn-primary order-2"
      [disabled]="disabled || !form?.dirty || !form?.valid"
    >
      <ng-container *ngIf="!disabled; else loading">
        {{ (edit ? 'actions.save' : 'actions.create') | translate }}
      </ng-container>
    </button>
    <button
      type="button"
      [disabled]="disabled"
      class="btn btn-light order-1"
      (click)="onClose()"
    >
      {{ 'actions.close' | translate }}
    </button>
  </div>
</form>

<ng-template #loading>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.loading' | translate }}
</ng-template>
