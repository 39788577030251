angular
  .module('examManagement')
  .factory('Selectors::ExamQuestionGroupList', function() {
    function getItems(object) {
      switch (object.examQuestionGroupList.scrambling) {
        case 'A':
          return object.examQuestionGroupList.bookletA
            ? object.examQuestionGroupList.bookletA.exam_question_groups
            : [];
        case 'B':
          return object.examQuestionGroupList.bookletB
            ? object.examQuestionGroupList.bookletB.exam_question_groups
            : [];
        default:
          return [];
      }
    }

    function transformToFlatItems(items) {
      return items.map(function(item) {
        var questions = item.question_group.questions || [],
          future_exams = item.question_group.future_exams || [];

        return Object.assign({}, item, {
          question_group: Object.assign({}, item.question_group, {
            literary_reference: truncate(
              questions
                .map(function(question) {
                  return question.literary_reference;
                })
                .join(', ')
            ),
            question_types: questions
              .map(function(question) {
                return I18n.t('questions.type.' + question.type);
              })
              .join(', '),
            score: questions.reduce(function(acc, question) {
              return acc + question.score;
            }, 0),
            future_exam_names: future_exams
              .map(function(exam) {
                return exam.name;
              })
              .join(', ')
          })
        });
      });
    }

    function truncate(string) {
      if (string.length > 20) {
        return string.substr(0, 20) + '...';
      } else {
        return string;
      }
    }

    function extractQuestionGroups(items) {
      return items.filter(function(item) {
        return item.question_group.type !== 'SINGLE';
      });
    }

    function getColumnDefinitions(object) {
      return (
        object &&
        object.examQuestionGroupList &&
        object.examQuestionGroupList.columns
      );
    }

    function getScrambling(object) {
      return (
        object &&
        object.examQuestionGroupList &&
        object.examQuestionGroupList.scrambling
      );
    }

    function transformToArray(columns) {
      return Object.keys(columns).reduce(function(acc, columnName) {
        acc.push(Object.assign({ name: columnName }, columns[columnName]));
        return acc;
      }, []);
    }

    function extractColumnVisibilities(columns) {
      return Object.keys(columns).reduce(function(acc, column) {
        acc[column] = columns[column] && columns[column].visible;
        return acc;
      }, {});
    }

    function getSelection(object) {
      return (
        object &&
        object.examQuestionGroupList &&
        object.examQuestionGroupList.selection
      );
    }

    function getQuestionGroup(selection, items) {
      var item = extractSelectedItem(selection, items);
      return item && item.question_group;
    }

    function extractSelectedItem(selection, items) {
      return items.find(function(item) {
        return item.id == selection;
      });
    }

    function getTotalScore(object) {
      return (getItems(object) || []).reduce(function(acc, examQuestionGroup) {
        var score = examQuestionGroup.question_group.questions.reduce(function(
          acc,
          question
        ) {
          return acc + question.score;
        },
        0);

        return acc + score;
      }, 0);
    }

    function isRequestInProgress(object) {
      return (
        (object &&
          object.examQuestionGroupList &&
          object.examQuestionGroupList.requestInProgress) ||
        false
      );
    }

    function getStats(object) {
      var items = getItems(object);
      var numberOfQuestionGroups = extractQuestionGroups(items).length;
      var numberOfSingleQuestions = items.length - numberOfQuestionGroups;

      return {
        numberOfSingleQuestions: numberOfSingleQuestions,
        numberOfQuestionGroups: numberOfQuestionGroups,
        totalScore: getTotalScore(object)
      };
    }

    return {
      getColumnDefinitions: getColumnDefinitions,
      getColumnDefinitionsAsArray: Reselect.createSelector(
        getColumnDefinitions,
        transformToArray
      ),
      getColumnVisibilities: Reselect.createSelector(
        getColumnDefinitions,
        extractColumnVisibilities
      ),
      getSelection: getSelection,
      getSelectedQuestionGroup: Reselect.createSelector(
        getSelection,
        getItems,
        getQuestionGroup
      ),
      getItems: getItems,
      getFlatItems: Reselect.createSelector(getItems, transformToFlatItems),
      getStats: getStats,
      isRequestInProgress: isRequestInProgress,
      getSelectedItem: Reselect.createSelector(
        getSelection,
        getItems,
        extractSelectedItem
      ),
      getScrambling: getScrambling
    };
  });
