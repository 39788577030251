/* eslint-disable unicorn/no-null */
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isVoid } from 'src/app/common/utils/type-guards/voidable';

// using RFC 2822 compliant regex
// https://stackoverflow.com/a/1373724
export const emailValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  if (isVoid(control.value) || control.value === '') return null;

  const railsEmailRegex =
    /[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*@(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z][\da-z-]*[\da-z]/;

  return railsEmailRegex.test(control.value) ? null : { email: true };
};
