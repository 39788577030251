angular.module('examManagement').controller('ExamFormCtrl', [
  '$scope',
  '$uibModalInstance',
  'exam',
  'languages',
  'allowSelfAssessment',
  'Store',
  'Actions::ExamList',
  'Repositories::Exam',
  function(
    $scope,
    $uibModalInstance,
    exam,
    languages,
    allowSelfAssessment,
    Store,
    Actions,
    Repository
  ) {
    $scope.I18n = I18n;

    $scope.isExamNew = function() {
      return angular.isUndefined(exam.id);
    };

    $scope.exam = Object.assign(exam, { date: new Date(exam.date) });

    if (allowSelfAssessment) {
      $scope.examTypeOptions = [
        { value: 'summative', label: I18n.t('summative', { scope: 'choices.exams.typ' }) },
        { value: 'formative', label: I18n.t('formative', { scope: 'choices.exams.typ' }) },
        {
          value: 'self_assessment',
          label: I18n.t('self_assessment', { scope: 'choices.exams.typ' })
        }
      ];
    } else {
      $scope.examTypeOptions = [
        { value: 'summative', label: I18n.t('summative', { scope: 'choices.exams.typ' }) },
        { value: 'formative', label: I18n.t('formative', { scope: 'choices.exams.typ' }) }
      ];
    }

    $scope.languages = languages;
    $scope.examLanguages = [];

    languages.forEach(function(lang) {
      if ($scope.exam[lang]) {
        $scope.examLanguages.push(lang);
      }
    });

    $scope.errors = {};
    $scope.tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

    $scope.save = function() {
      var errorCallback = function(data) {
        $scope.errors = data.field_messages;
      };
      if ($scope.exam.id) {
        Repository.update($scope.exam).then(function(updated_exam) {
          Store.dispatch(Actions.setExam(updated_exam));
          $uibModalInstance.close(updated_exam);
        }, errorCallback);
      } else {
        Repository.create($scope.exam).then(function(exam) {
          Store.dispatch(Actions.addExam(exam));
          $uibModalInstance.close(exam);
        }, errorCallback);
      }
    };

    $scope.updateExamLanguage = function() {
      $scope.examLanguages = [];
      languages.forEach(function(lang) {
        if ($scope.exam[lang]) {
          $scope.examLanguages.push(lang);
        }
      });
    };
  }
]);
