angular.module('common').factory('Repositories::Announcement', [
  'GraphQLClient',
  function (GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
        getAll = 'query { announcements { text icon severity link } }';
    return {
      getAll: function () {
        return client.query(getAll).then(function (response) {
          return response.data.data.announcements;
        });
      },
    };
  }
]);
