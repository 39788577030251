angular.module('common').directive('inputFeedback', ['$compile', function ($compile) {
  var defaultFeedbackConfiguration = {
    feedbackClass: 'has-warning',
    feedbackIconClass: 'fa-exclamation',
    tooltipClass: 'tooltip-warning'
  },
  feedbackConfigurations = {
    alert: {
      feedbackClass: 'has-error',
      feedbackIconClass: 'fa-exclamation-triangle',
      tooltipClass: 'tooltip-danger'
    },
    warning: defaultFeedbackConfiguration
  },
  messageTypePriorities = {
    alert: 0,
    warning: 1
  };

  function getMostSevereFeedback (messages) {
    var messagesCopy = messages.slice(0), mostSevereMessage;
    messagesCopy.sort(function (a, b) {
      var aPriority = messageTypePriorities[a.type] === undefined ? 100 : messageTypePriorities[a.type],
      bPriority = messageTypePriorities[b.type] === undefined ? 100 : messageTypePriorities[b.type];
      return aPriority - bPriority;
    });
    return messagesCopy[0];
  }

  return {
    restrict: 'A',
    replace: false,
    priority: 2000,
    terminal: true,
    compile: function (element, attrs) {
      var errorsModel = attrs.inputFeedback,
      uniqueId = 'v' + Math.floor(Math.random() * 1000000).toString(16);

      element.find('input').attr('uib-tooltip-html', uniqueId + '.messagesAsHtml').
        attr('tooltip-placement', 'top-right').
        attr('tooltip-class', 'input-feedback-tooltip {{' + uniqueId + '.tooltipClass}}').
        attr('tooltip-enable', errorsModel + '.length > 0 && !' + uniqueId + '.hideDueToInputChange').
        attr('tooltip-is-open', uniqueId + '.show');
      element.removeAttr('input-feedback');

      return {
        pre: function () {},
        post: function (scope, element, attrs) {
          function setFeedback (messages) {
            var message = getMostSevereFeedback(messages),
            configuration = feedbackConfigurations[message.type] || defaultFeedbackConfiguration;

            scope[uniqueId].messagesAsHtml = message.content;
            scope[uniqueId].tooltipClass = configuration.tooltipClass;
            element.addClass('has-feedback ' + configuration.feedbackClass);
            angular.element('<div class="form-control-feedback"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-stack-1x fa-inverse ' + configuration.feedbackIconClass + '"></i></span></div>').
              insertAfter(element.find('input'));
          }

          function removeFeedback () {
            element.removeClass('has-feedback has-error has-warning');
            element.find('.form-control-feedback').detach();
            scope[uniqueId].hideDueToInputChange = true;
          }

          scope[uniqueId] = scope[uniqueId] || {};
          $compile(element)(scope);

          element.find('input').
            on('focus', function () {
              scope[uniqueId].show = true;
            }).
            on('blur', function () {
              scope[uniqueId].show = false;
            });

          scope.$watch(attrs.ngModel, function () {
            removeFeedback();
          });

          scope.$watch(errorsModel, function (newValue) {
            removeFeedback();
            scope[uniqueId].messagesAsHtml = '';
            scope[uniqueId].hideDueToInputChange = false;
            if (Array.isArray(newValue)) {
              if (newValue.length > 0) {
                setFeedback(newValue);
              }
            }
          });
        }
      };
    }
  };
}]);
