angular.module('common').factory('Actions::QuestionGroup', function() {
  var actions = {
    UPDATE_QUESTION_GROUPS: 'QUESTION_GROUPS_UPDATE',
    REMOVE_LABEL: 'QUESTION_GROUPS_REMOVE_LABEL'
  };

  function updateQuestionGroups(questionGroups) {
    return {
      type: actions.UPDATE_QUESTION_GROUPS,
      questionGroups: questionGroups
    };
  }

  function removeLabel(labelId) {
    return {
      type: actions.REMOVE_LABEL,
      labelId: labelId
    };
  }

  return Object.assign(
    {
      updateQuestionGroups: updateQuestionGroups,
      removeLabel: removeLabel
    },
    actions
  );
});
