angular.module('common').factory("Actions::QuestionGroupDetails", function() {
  var actions = {
    LOAD_QUESTION_GROUP_REQUEST:
      "QUESTION_GROUP_DETAILS_LOAD_QUESTION_GROUP_REQUEST",
    LOAD_QUESTION_GROUP_SUCCESS:
      "QUESTION_GROUP_DETAILS_LOAD_QUESTION_GROUP_SUCCESS",
    SET_PREVIEWS: "QUESTION_GROUP_DETAILS_SET_PREVIEWS"
  };

  function loadQuestionGroupRequest() {
    return {
      type: actions.LOAD_QUESTION_GROUP_REQUEST
    };
  }

  function loadQuestionGroupSuccess(questionGroup) {
    return {
      type: actions.LOAD_QUESTION_GROUP_SUCCESS,
      questionGroup: questionGroup
    };
  }

  function setPreviews(previews) {
    return {
      type: actions.SET_PREVIEWS,
      previews: previews
    };
  }

  return Object.assign(
    {
      loadQuestionGroupRequest: loadQuestionGroupRequest,
      loadQuestionGroupSuccess: loadQuestionGroupSuccess,
      setPreviews: setPreviews
    },
    actions
  );
});
