import type { StateOperator } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ɵPatchSpec } from '@ngxs/store/operators/patch';
import type { NoInfer } from '@ngxs/store/operators/types';
import { ManagerError } from '../error-parser';
import { RemoteListData, RemoteListItem, RequestState } from '../remote-data';

export function patchListItem<T>(
  selector: (item: T) => boolean,
  state: RequestState,
  patchData: NoInfer<ɵPatchSpec<T>>,
  error?: ManagerError
): StateOperator<RemoteListData<T>> {
  return patch({
    list: updateItem<RemoteListItem<T>>(
      item => item !== undefined && selector(item.data),
      patch({
        requestState: state,
        error,
        // casting type as ngxs has type issues with patching
        // even though patchData is of the exact same type as the only argument of patch
        // patch<T extends Record<string, any>>(patchObject: NoInfer<ɵPatchSpec<T>>)
        data: patch(patchData as NoInfer<T>)
      })
    )
  });
}

export function deleteListItem<T>(
  selector: (item: T) => boolean
): StateOperator<RemoteListData<T>> {
  return patch({
    list: removeItem<RemoteListItem<T>>(
      item => item !== undefined && selector(item.data)
    )
  });
}
