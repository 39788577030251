angular.module('examManagement').factory('Numerator', [function() {

  function Numerator(items) {
    this.items = items.slice(0);
    this.counter = { kprime: 1, a: 1 };
  }

  Numerator.prototype.run = function() {
    this._countFor("examBookNumberA", "position_a");
    this._countFor("examBookNumberB", "position_b");
  };

  Numerator.prototype._countFor = function(property, position) {
    this._resetCounter();
    this.items.sort(this._sortBy(position));
    for(var i = 0; i < this.items.length; i++) {
      var item = this.items[i];
      var type = item.question_group.questions.map(q => q.type);

      if(type[0] === "Kprime") {
        item[property] = "K" + (++this.counter.kprime);
      } else {
        item[property] = ++this.counter.a;
      }
    }
  };

  Numerator.prototype._sortBy = function(property) {
    return function(a, b) {
      if(a[property] > b[property]) {
        return 1;
      }
      if(a[property] < b[property]) {
        return -1;
      }
      return 0;
    };
  };

  Numerator.prototype._resetCounter = function() {
    this.counter = { kprime: 0, a: 0 };
  };

  return Numerator;
}]);
