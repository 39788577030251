<div
  class="border rounded overflow-hidden bg-stripes-light"
  style="height: 100%; position: relative"
>
  <ng-container *ngIf="request.requestState !== 'failure'; else error">
    <ng-content></ng-content>
  </ng-container>

  <ng-template #error>
    <man-table-error [request]="request"></man-table-error>
  </ng-template>
</div>
