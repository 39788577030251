import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManagerErrorCode } from '../../../common/utils/error-parser';
import { managerErrorTranslationMap } from '../../../common/utils/error-translation-map';
import { MODAL_DATA } from './modal.service';

export interface ErrorModalData {
  errorCode: ManagerErrorCode;
}

@Component({
  templateUrl: './error-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorModalComponent {
  public readonly translationKey: string;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    @Inject(MODAL_DATA) public data: ErrorModalData
  ) {
    this.translationKey =
      managerErrorTranslationMap.get(data.errorCode) ??
      'state_error.general_error';
  }

  public onRetry(): void {
    this.ngbActiveModal.close();
  }

  public onCancel(): void {
    this.ngbActiveModal.dismiss();
  }
}
