<div ngbDropdown container="body" #dropdown>
  <button type="button" class="btn btn-icon" id="trigger" ngbDropdownToggle>
    <i aria-hidden="true" class="fa-regular fa-ellipsis"></i>
  </button>
  <div
    class="menu"
    ngbDropdownMenu
    aria-labelledby="trigger"
    (mouseleave)="onMouseLeave()"
    (mouseenter)="onMouseEnter()"
  >
    <button
      *ngFor="let option of options"
      ngbDropdownItem
      [disabled]="!!option.disabled"
      (click)="onOptionClick(option)"
      [ngClass]="{ 'text-danger': option.danger }"
    >
      {{ option.label | translate }}
    </button>
  </div>
</div>
