import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UnlockedExamFragment = { __typename?: 'Exam', id: string, name: string };

export type UnlockedExamsQueryVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
}>;


export type UnlockedExamsQuery = { __typename?: 'Query', pool: { __typename?: 'Pool', exams: Array<{ __typename?: 'Exam', id: string, name: string }> } };

export const UnlockedExamFragmentDoc = gql`
    fragment UnlockedExam on Exam {
  id
  name
}
    `;
export const UnlockedExamsDocument = gql`
    query UnlockedExams($poolId: ID!) {
  pool(id: $poolId) {
    exams(provisioned: false, inFuture: true, locked: false) {
      ...UnlockedExam
    }
  }
}
    ${UnlockedExamFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UnlockedExamsGQL extends Apollo.Query<UnlockedExamsQuery, UnlockedExamsQueryVariables> {
    document = UnlockedExamsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }