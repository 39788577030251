import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateLabelMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  name: Types.Scalars['String'];
  color: Types.Scalars['String'];
}>;


export type CreateLabelMutation = { __typename?: 'Mutation', createLabel?: { __typename?: 'CreateLabelPayload', successful: boolean, label?: { __typename?: 'Label', color: string, usageCount: number, name: string, id: string } | null } | null };

export const CreateLabelDocument = gql`
    mutation CreateLabel($poolId: ID!, $name: String!, $color: String!) {
  createLabel(poolId: $poolId, name: $name, color: $color) {
    successful
    label {
      color
      usageCount
      name
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateLabelGQL extends Apollo.Mutation<CreateLabelMutation, CreateLabelMutationVariables> {
    document = CreateLabelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }