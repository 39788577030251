angular.module('common').factory('BtnGroupErrorHandler', ['BasicErrorHandler', function (BasicErrorHandler) {
  function BtnGroupErrorHandler (element) {
    BasicErrorHandler.bind(this)(element, {
      fieldSelector: '.btn-group',
      iconSelector: '.btn-group-inline-error__icon',
      tooltipSelector: '.inline-error__tooltip'
    });
  }

  BtnGroupErrorHandler.prototype = angular.copy(BasicErrorHandler.prototype);

  BtnGroupErrorHandler.prototype.readFieldValue = function () {
    return this.element.find('.btn-group button').index(this.element.find('button.active'));
  };

  return BtnGroupErrorHandler;
}]);
