/* eslint-disable camelcase, unicorn/no-null */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import type { DndDropEvent } from 'ngx-drag-drop';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isDefined } from 'src/app/common/utils/type-guards/is-defined';
import { Voidable } from 'src/app/common/utils/type-guards/voidable';
import { LanguageVisibility } from 'src/app/state/settings.state';
import { QuestionInput } from 'src/generated/base-types';
import { FormPropositionInput } from '../form-types';
import { QuestionGroupFormComponent } from '../question-group-form/question-group-form.component';
import {
  isSecureIndexRange,
  moveObject,
  normalizeIndexRange,
  transformDropEventToIndexRange
} from '../services/drag-and-drop';
import { ValidationsQuestionKPrimeGraphQL } from '../services/graphql-question-group-types';

export const KPRIME_PROPOSITIONS_AMOUNT = 4;

@Component({
  selector: 'qf-question-content-type-kprime-form',
  templateUrl: './question-content-type-kprime-form.component.html',
  styleUrls: ['./question-content-type-kprime-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionContentTypeKprimeFormComponent),
      multi: true
    }
  ]
})
export class QuestionContentTypeKprimeFormComponent
  implements ControlValueAccessor, OnDestroy
{
  @Input() public languageVisibility: LanguageVisibility;

  @Input()
  public set validations(value: ValidationsQuestionKPrimeGraphQL | undefined) {
    this._validations = value;
    this.validations$.next(value);
    // special case: solution in Kprime
    this.checkKPrimeSolutionValidations();
  }
  public get validations(): ValidationsQuestionKPrimeGraphQL | undefined {
    return this._validations;
  }

  public form: UntypedFormGroup;
  public helperForm: UntypedFormGroup;

  public isRearranging: boolean;
  private _validations: ValidationsQuestionKPrimeGraphQL | undefined;

  private question$ = new Subject<QuestionInput>();
  private validations$ = new Subject<
    ValidationsQuestionKPrimeGraphQL | undefined
  >();
  private destroy$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    public readonly builder: UntypedFormBuilder
  ) {
    this.form = this.builder.group({
      contentDe: null,
      contentFr: null,
      contentEn: null,
      contentIt: null,
      videoDe: null,
      videoFr: null,
      videoEn: null,
      videoIt: null,
      propositions: this.builder.array([])
    });

    this.helperForm = this.builder.group({
      allPropositionSolutionsSelected: null
    });

    setTimeout(() => {
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(changes => {
          this.onChange({
            video: {
              de: changes.videoDe,
              fr: changes.videoFr,
              en: changes.videoEn,
              it: changes.videoIt
            },
            kPrime: {
              content: {
                de: changes.contentDe,
                fr: changes.contentFr,
                en: changes.contentEn,
                it: changes.contentIt
              },
              propositions: changes.propositions.map(
                (proposition: FormPropositionInput) => ({
                  content: {
                    de: proposition.contentDe,
                    fr: proposition.contentFr,
                    en: proposition.contentEn,
                    it: proposition.contentIt
                  }
                })
              ),
              solution: changes.propositions.map(
                (proposition: FormPropositionInput) => proposition.correct
              )
            }
          });
          this.onTouch();
        });
    }, QuestionGroupFormComponent.CHANGE_DETECTION_TIMEOUT);

    this.question$.pipe(takeUntil(this.destroy$)).subscribe(question => {
      this.propositionsForm.clear();
      (question.kPrime?.propositions || []).forEach(() => {
        this.propositionsForm.push(
          this.builder.group({
            correct: null,
            contentDe: null,
            contentFr: null,
            contentEn: null,
            contentIt: null
          })
        );
      });
      this.form.setValue({
        contentDe: question.kPrime?.content.de ?? null,
        contentFr: question.kPrime?.content.fr ?? null,
        contentEn: question.kPrime?.content.en ?? null,
        contentIt: question.kPrime?.content.it ?? null,
        videoDe: question.video?.de ?? null,
        videoFr: question.video?.fr ?? null,
        videoEn: question.video?.en ?? null,
        videoIt: question.video?.it ?? null,
        propositions: (question.kPrime?.propositions || []).map(
          (proposition, index) => ({
            correct: question.kPrime?.solution[index] ?? null,
            contentDe: proposition.content?.de ?? null,
            contentFr: proposition.content?.fr ?? null,
            contentEn: proposition.content?.en ?? null,
            contentIt: proposition.content?.it ?? null
          })
        )
      });
    });

    combineLatest(this.question$, this.validations$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([question]) => {
        this.unifyErrorSizeWithPropositions(question);
      });
  }

  public get propositionsForm(): UntypedFormArray {
    return this.form.get('propositions') as UntypedFormArray;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(question: QuestionInput): void {
    this.question$.next(question);
  }

  public registerOnChange(fn: (value: QuestionInput) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public propositionClicked(
    propositionFormGroup: AbstractControl,
    button: { target: { value: string } }
  ): void {
    propositionFormGroup
      .get('correct')
      ?.setValue(JSON.parse(button.target.value));

    // special case: solution in Kprime
    this.checkKPrimeSolutionValidations();
  }

  public responseOptionMoved(event: DndDropEvent): void {
    this.isRearranging = true;
    this.changeDetector.detectChanges();

    const indexRange = transformDropEventToIndexRange(event);
    if (!isSecureIndexRange(indexRange)) {
      return;
    }

    const normalizedIndexRange = normalizeIndexRange(indexRange);
    this.moveResponseOption(normalizedIndexRange.from, normalizedIndexRange.to);

    this.isRearranging = false;
    this.changeDetector.detectChanges();
  }

  private checkKPrimeSolutionValidations(): void {
    // kPrime solution helper (shared accross all languages)
    // the solution field from response options is also used
    // by the single items, meaning if something happen in a child
    // field it is displayed as general error. Only display overarching
    // errors on the container of the items
    const amountSolutions = this.propositionsForm.value.reduce(
      (acc: number, response: { correct: boolean | Voidable }) =>
        isDefined(response.correct) ? acc + 1 : acc,
      0
    );
    const allPropositionSolutionsSelected =
      amountSolutions === KPRIME_PROPOSITIONS_AMOUNT ? true : undefined;
    this.helperForm
      .get('allPropositionSolutionsSelected')
      ?.setValue(allPropositionSolutionsSelected);
  }

  private moveResponseOption(from: number, to: number): void {
    if (this.validations?.propositions) {
      this.validations = {
        ...this.validations,
        propositions: moveObject(this.validations.propositions, from, to)
      };
    }

    const movedFormGroup = this.propositionsForm.at(from);
    this.propositionsForm.removeAt(from);
    this.propositionsForm.insert(to, movedFormGroup);
  }

  private unifyErrorSizeWithPropositions(question: QuestionInput): void {
    if (this.validations?.propositions) {
      this.validations.propositions.length = (
        question.kPrime?.propositions || []
      ).length;
    }
  }

  private onChange: (value: QuestionInput) => void = () => void 0;
  private onTouch: () => void = () => void 0;
}
