angular.module('common').factory('Reducers::Version', ['Actions::Version',
  function (Actions) {
    function reducer (state, action) {
      state = state || {};
      switch (action.type) {
        case Actions.SET_VERSION:
        return Object.assign({}, state, {
          version: action.version
        });

        default:
          return state;
      }
    }

    return reducer;
  }
]);
