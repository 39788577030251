import { Editor, EditorEvent } from 'tinymce';
import { openEditModal, openInsertModal, updateActiveState } from './helpers';
import { setTranslations } from './translations';
import { TinyMCEInstance, ToggleMenuItemInstanceApi } from './types';

declare let tinyMCE: TinyMCEInstance; // is already instantiated

export const LATEX_PLUGIN_CSS_CLASS = 'tiny-latex-formula';
export const LATEX_PLUGIN_NAME = 'latex';
export const LATEX_PLUGIN_VALID_ELEMENTS =
  'span[class|id|contenteditable|*],math[*],maction[*],maligngroup[*],malignmark[*],menclose[*],merror[*],mfenced[*],mfrac[*],mglyph[*],mi[*],mlabeledtr[*],mlongdiv[*],mmultiscripts[*],mn[*],mo[*],mover[*],mpadded[*],mphantom[*],mroot[*],mrow[*],ms[*],mscarries[*],mscarry[*],msgroup[*],msline[*],mspace[*],msqrt[*],msrow[*],mstack[*],mstyle[*],msub[*],msup[*],msubsup[*],mtable[*],mtd[*],mtext[*],mtr[*],munder[*],munderover[*],none,annotation[*],semantics[*]';
export const LATEX_PLUGIN_CSS = `
span.tiny-latex-formula[id][contenteditable="false"] {
  cursor: pointer;
}

span.tiny-latex-formula {
  font-size: 1.2em;
}

span.tiny-latex-formula * {
  pointer-events: none;
}
`;

export function setupLatexPlugin(): void {
  tinyMCE.PluginManager.add(LATEX_PLUGIN_NAME, (editor: Editor) => {
    setTranslations(tinyMCE, editor);

    editor.ui.registry.addToggleButton(LATEX_PLUGIN_NAME, {
      icon: 'gamma',
      onAction: () => {
        const selectedNode = editor.selection.getNode();
        const mathMLSpanSelected =
          selectedNode?.classList.contains(LATEX_PLUGIN_CSS_CLASS) ?? false;

        if (mathMLSpanSelected) return openEditModal(selectedNode, editor);

        openInsertModal(editor);
      },
      onSetup: (api: ToggleMenuItemInstanceApi) => {
        updateActiveState(api, editor);

        editor.on('NodeChange', function (_event: EditorEvent<unknown>) {
          updateActiveState(api, editor);
        });

        return () => editor.off('NodeChange'); // removes the event listener when the button is destroyed
      }
    });

    // opens the edit modal when span containing a MathML element is clicked
    editor.on('click', (event: EditorEvent<MouseEvent>) => {
      const mathMLSpan = event.target;
      const mathMLSpanSelected = mathMLSpan.classList.contains(
        LATEX_PLUGIN_CSS_CLASS
      );

      if (mathMLSpanSelected === true) openEditModal(mathMLSpan, editor);
    });
  });
}
