import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixedNumber'
})
export class FixedNumberPipe implements PipeTransform {
  public transform(value: number, digits?: number): string {
    return digits === undefined ? value.toString() : value.toFixed(digits);
  }
}
