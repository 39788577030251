<label *ngIf="label" class="form-label" [attr.for]="labelFor">
  {{ label }}
</label>
<ng-content></ng-content>
<div class="d-block invalid-feedback" *ngFor="let error of errors | keyvalue">
  {{ 'common.form.errors.' + error.key | translate : { value: error.value } }}
</div>
<ng-content select="[error]"></ng-content>
<div class="form-text" *ngIf="helpText" [attr.id]="describedById">
  {{ helpText }}
</div>
