angular.module('examManagement').factory('Reducers::ExamList', [
  'Actions::ExamList',
  function(Actions) {
    function compareExams(a, b) {
      return new Date(b.date) - new Date(a.date);
    }

    function reducer(state, action) {
      var items, index, selection;

      state = state || {};

      switch (action.type) {
        case Actions.FETCH_EXAMS_REQUEST:
          return Object.assign({}, state, { requestInProgress: true });

        case Actions.FETCH_EXAMS_SUCCESS:
          selection =
            action.exams.find(function(exam) {
              return exam.id == state.selection;
            }) || {};
          return Object.assign({}, state, {
            items: action.exams,
            requestInProgress: false,
            selection: selection.id || (action.exams[0] && action.exams[0].id)
          });

        case Actions.SET_EXAM:
          index = state.items.findIndex(function(item) {
            return item.id == action.exam.id;
          });
          items = state.items.slice(0);
          items[index] = Object.assign({}, items[index], action.exam);
          items.sort(compareExams);
          return Object.assign({}, state, {
            items: items
          });

        case Actions.ADD_EXAM:
          items = state.items.concat([action.exam]);
          items.sort(compareExams);
          return Object.assign({}, state, {
            items: items,
            selection: action.exam.id
          });

        case Actions.DELETE_EXAM:
          return Object.assign({}, state, {
            items: state.items.filter(function(item) {
              return item.id !== action.examId && item.id !== undefined;
            }),
            selection: state.items[0] && state.items[0].id
          });

        case Actions.SET_SELECTION:
          return Object.assign({}, state, {
            selection: action.id
          });

        case Actions.REMOVE_SELECTION:
          return Object.assign({}, state, {
            selection: undefined
          });

        default:
          return state;
      }
    }

    return reducer;
  }
]);
