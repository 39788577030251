<div class="bs4 form-horizontal" [formGroup]="form">
  <div class="col-sm-6">
    <div class="form-group">
      <label class="control-label col-sm-3" for="title">
        {{ 'question_groups.edit.title' | translate }}
      </label>
      <div class="col-sm-9">
        <input
          #titleInput
          class="form-control"
          id="title"
          name="title"
          [maxlength]="50"
          formControlName="title"
          placeholder="{{ 'question_groups.edit.title' | translate }}"
        />
        <qf-input-feedback
          [validations]="validations?.title"
          [inputField]="titleInput"
          [inputValue]="form.controls.title.value"
        ></qf-input-feedback>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-3" for="type">
        {{ 'question_groups.type' | translate }}
      </label>
      <div class="col-sm-3">
        <ng-select
          [items]="questionGroupTypes"
          bindValue="value"
          bindLabel="label"
          labelForId="type"
          formControlName="type"
          [clearable]="false"
        >
        </ng-select>
      </div>

      <label class="control-label col-sm-3" for="revision_year">
        {{ 'question_groups.edit.revision_year' | translate }}
      </label>
      <div class="col-sm-3">
        <ng-select
          [items]="revisionYears"
          labelForId="revision_year"
          formControlName="revisionYear"
        >
        </ng-select>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-3" for="source_language">
        {{ 'question_groups.edit.source_language' | translate }}
      </label>
      <div class="col-sm-3">
        <ng-select
          preselectIfSingleValued
          #sourceLanguageInput
          [items]="languageOptions"
          bindValue="value"
          labelForId="source_language"
          formControlName="sourceLanguage"
        >
        </ng-select>
        <qf-input-feedback
          [validations]="validations?.sourceLanguage"
          [inputField]="sourceLanguageInput"
          [inputValue]="form.controls.sourceLanguage.value"
        ></qf-input-feedback>
      </div>
      <ng-container *ngIf="affiliations.length > 0">
        <label class="control-label col-sm-3" for="affiliation">
          {{ 'questions.edit.affiliation' | translate }}
        </label>
        <div class="col-sm-3">
          <ng-select
            #affiliationInput
            [items]="affiliations"
            bindValue="id"
            bindLabel="name"
            labelForId="affiliation"
            formControlName="affiliationId"
          >
          </ng-select>
          <qf-input-feedback
            [validations]="validations?.affiliation"
            [inputField]="affiliationInput"
            [inputValue]="form.controls.affiliationId.value"
          ></qf-input-feedback>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="form-group">
      <label class="control-label col-sm-3" for="supervisor">
        {{ 'questions.edit.supervisor' | translate }}
      </label>
      <div class="col-sm-9">
        <ng-select
          #supervisorInput
          [addTag]="true"
          addTagText="{{ 'common.actions.add' | translate }}"
          [items]="supervisors"
          labelForId="supervisor"
          formControlName="supervisor"
        >
        </ng-select>
        <qf-input-feedback
          [validations]="validations?.supervisor"
          [inputField]="supervisorInput"
          [inputValue]="form.controls.supervisor.value"
        ></qf-input-feedback>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-3" for="author">
        {{ 'questions.edit.author' | translate }}
      </label>
      <div class="col-sm-9">
        <ng-select
          #authorInput
          [addTag]="true"
          addTagText="{{ 'common.actions.add' | translate }}"
          [items]="authors"
          labelForId="author"
          formControlName="author"
        >
        </ng-select>
        <qf-input-feedback
          [validations]="validations?.author"
          [inputField]="authorInput"
          [inputValue]="form.controls.author.value"
        ></qf-input-feedback>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-3" for="language">{{
        'question_groups.form.toggleLanguage' | translate
      }}</label>
      <div class="col-md-9">
        <div class="btn-group-check" role="group">
          <ng-container *ngFor="let language of languages">
            <input
              class="btn-check"
              type="checkbox"
              [id]="language + '_language'"
              [ngModel]="languageVisibility[language]"
              [ngModelOptions]="{ standalone: true }"
              (click)="languageVisibilityClicked(language)"
            />
            <label class="btn btn-check-label" [for]="language + '_language'">
              {{ 'scrudu.languages.long.' + language | translate }}
            </label>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-sm-offset-3">
      {{
        'question_group_form.total_score'
          | translate : { score: totalScore, questions: numberOfQuestions }
      }}
    </div>
  </div>
</div>
