<ngb-accordion
  [activeIds]="initiallyOpen ? ['accordionPanel'] : []"
  (panelChange)="toggle($event)"
>
  <ngb-panel id="accordionPanel">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link">
        <i class="fa fa-chevron-circle-down fa-lg pr-3" *ngIf="open"></i>
        <i class="fa fa-chevron-circle-right fa-lg pr-3" *ngIf="!open"></i>
        {{ title }}
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <ng-content></ng-content>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
