(function() {
  var MAX_NUMBER_OF_NOTIFICATIONS = 5;

  angular.module('examManagement').controller('CandidatesImportFormCtrl', [
    '$scope',
    'Upload',
    '$uibModalInstance',
    '$window',
    'notifications',
    'Store',
    'Selectors::ExamList',
    function($scope, Upload, $uibModalInstance, $window, notifications, Store, ExamSelectors) {
      $scope.I18n = I18n;
      $scope.alerts = [];

      function interpolate(path, valueMap) {
        return path.replace(/:(\w+)/, function(match, key) {
          return valueMap[key];
        });
      }

      function isValidFileType(file) {
        return /\.csv$/.test(file.name);
      }

      function abortPendingUpload(scope) {
        if (scope.upload && $scope.upload.length && scope.upload[0] !== null) {
          $scope.upload[0].abort();
        }
        $scope.upload = [];
      }

      $scope.isFileReaderSupported = function() {
        return $window.FileReader && (!$window.FileAPI || $window.FileAPI.html5);
      };

      $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
      };

      $scope.cancel = function() {
        $uibModalInstance.dismiss();
      };

      $scope.getDragOverClass = function($event) {
        var file = $event.dataTransfer.items[0];
        return isValidFileType(file) ? 'drag-over' : 'drag-over-invalid';
      };

      $scope.onFileSelect = function($files) {
        var file,
          apiUrl = interpolate($uibModalInstance.url, {
            examId: ExamSelectors.getSelection(Store.getState())
          });

        abortPendingUpload($scope);
        $scope.alerts = [];

        if (!$files && !$files.length) {
          return;
        }

        file = $files[0];

        if (isValidFileType(file)) {
          $scope.upload = Upload.upload({ url: apiUrl, file: file })
            .success(function(data) {
              $uibModalInstance.close(data);
            })
            .error(function(err) {
              for (
                var i = 0;
                i < err.notifications.length && i < MAX_NUMBER_OF_NOTIFICATIONS;
                i++
              ) {
                notifications.add(err.notifications[i].content, err.notifications[i].type);
              }
              $uibModalInstance.close(err);
            });
        } else {
          notifications.addInfo(I18n.t('candidates.import.supported_file_types'));
        }
      };
    }
  ]);
}.call(this));
