angular.module('common').filter('calculateStatsRange', [
  function () {
    return function (stats, precision = 3) {
      const uniqueStringStats = [...new Set(stats)].filter(s => {
        return s !== null && s !== undefined;
      });
      const uniqueStats = Array.from(uniqueStringStats, Number);

      switch (uniqueStats.length) {
        case 0:
          return '-';
        case 1:
          return Number(uniqueStats[0].toFixed(precision)).toString();
        default: {
          const minValue = Math.min.apply(null, uniqueStats);
          const maxValue = Math.max.apply(null, uniqueStats);
          const formattedMinValue = Number(minValue.toFixed(precision));
          const formattedMaxValue = Number(maxValue.toFixed(precision));

          return `${formattedMinValue}-${formattedMaxValue}`;
        }
      }
    };
  }
]);
