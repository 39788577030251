import { Selector } from '@ngxs/store';
import { RemoteData, RequestState } from 'src/app/common/utils/remote-data';
import {
  requestStateFailure,
  requestStateLoading,
  requestStateSuccess
} from 'src/app/common/utils/remote-data-utils';
import { assertIsDefined } from 'src/app/common/utils/type-guards/is-defined';
import { Category, Dimension } from 'src/generated/base-types';
import { SubjectsListElementFragment } from '../services/load-subjects.generated';
import {
  DimensionData,
  DimensionsState
} from './pool-details/dimensions/dimensions.state';
import { SubjectsState } from './pool-details/subjects/subjects.state';

export type SubjectWithNamedDimensions = SubjectsListElementFragment & {
  categories: Category[];
};

export class MetaSelectors {
  @Selector([SubjectsState.subjects, DimensionsState.dimensions])
  public static subjectCategoriesByDimension(
    _subjects: RemoteData<SubjectsListElementFragment[]>,
    _dimensions: RemoteData<DimensionData[]>
  ): RemoteData<SubjectWithNamedDimensions[]> {
    const subjectsData = _subjects.data || [];
    const dimensionsData = _dimensions.data || [];

    const data: SubjectWithNamedDimensions[] = subjectsData.map(subject => {
      const categories = subject.categories.map(category => {
        const dimension =
          dimensionsData.find(d => d.id === category.dimension.id) ??
          category.dimension;

        assertIsDefined(dimension, `Dimension not found`);

        return { ...category, dimension: dimension as Dimension };
      });

      return {
        id: subject.id,
        name: subject.name,
        categories
      };
    });

    const isLoading =
      requestStateLoading(_subjects) || requestStateLoading(_dimensions);
    const isFailure =
      requestStateFailure(_subjects) || requestStateFailure(_dimensions);
    const isSuccess =
      requestStateSuccess(_subjects) && requestStateSuccess(_dimensions);

    let requestState: RequestState = 'initial';

    if (isLoading) {
      requestState = 'loading';
    } else if (isFailure) {
      requestState = 'failure';
    } else if (isSuccess) {
      requestState = 'success';
    }

    return {
      data,
      requestState
    };
  }
}
