import { Injectable } from '@angular/core';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MultiLanguageTranslateService {
  constructor(
    private translate: TranslateService,
    private parser: TranslateParser
  ) {}

  public get(
    language: string,
    key: string,
    interpolateParams?: unknown
  ): Observable<string | undefined> {
    return this.translate.getTranslation(language).pipe(
      map(translationData => {
        const translation = this.parser.getValue(translationData, key);

        return this.parser.interpolate(translation, interpolateParams);
      })
    );
  }
}
