angular.module('settings').factory('Selectors::OauthApplicationList', [
  function() {
    function getItems(object) {
      return (object && object.applicationList && object.applicationList.items) || [];
    }

    return {
      getItems: getItems
    };
  }
]);
