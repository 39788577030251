import { DOCUMENT } from '@angular/common';
import { Inject, OnInit, Component, Input } from '@angular/core';
import { FileSaverService } from 'src/app/common/services/file-saver.service';
import { Video } from 'src/app/question-form/video-upload/video-upload.component';
@Component({
  selector: 'qf-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
  public static readonly WINDOW_CLASS = 'video-modal';

  @Input() public video: Video;

  public player: HTMLVideoElement & Fullscreenable;

  public currentTime = '0:00';
  public totalTime: string;

  public currentProgress = '0%';
  public remainingProgress = '100%';

  private progressBar: HTMLProgressElement;
  private progressContainer: HTMLDivElement;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private fileSaverService: FileSaverService
  ) {}

  public ngOnInit(): void {
    this.player = <HTMLVideoElement & Fullscreenable>(
      this._document.querySelector('video')
    );

    this.player.addEventListener('loadedmetadata', () => {
      this.totalTime = this.getFriendlyTime(this.player.duration);

      this.progressBar = <HTMLProgressElement>(
        this._document.querySelector('.progress-current')
      );
      this.progressContainer = <HTMLDivElement>(
        this._document.querySelector('.progress-container')
      );
      this.player.addEventListener('timeupdate', () => {
        this.updateProgress();
      });
    });

    const url = window.URL.createObjectURL(
      new Blob([this.video.buffer], { type: this.video.mime })
    );
    this.player.src = url;
  }

  public switchPlaying(): void {
    if (this.player.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  public play(): void {
    this.player.play();
  }

  public pause(): void {
    this.player.pause();
  }

  public mute(): void {
    this.player.muted = true;
  }

  public unmute(): void {
    this.player.muted = false;
  }

  public jump(e: { offsetX: number; srcElement: { className: string } }): void {
    const progressMax = this.progressContainer.getBoundingClientRect().width;

    let progressInPercent = 0;

    progressInPercent =
      e.srcElement.className === 'progress-remaining'
        ? (this.progressBar.clientWidth + e.offsetX) / progressMax
        : (progressInPercent = e.offsetX / progressMax);

    this.player.currentTime = progressInPercent * this.player.duration;
  }

  public fullscreen(): void {
    if (
      this.player.requestFullscreen !== undefined &&
      this.player.requestFullscreen !== null
    )
      this.player.requestFullscreen();
    else if (
      this.player.webkitRequestFullscreen !== undefined &&
      this.player.webkitRequestFullscreen !== null
    )
      this.player.webkitRequestFullscreen();
    else if (
      this.player.msRequestFullScreen !== undefined &&
      this.player.msRequestFullScreen !== null
    )
      this.player.msRequestFullScreen();
  }

  public download(): void {
    this.fileSaverService.saveStringAs(
      this.video.buffer,
      this.video.filename,
      this.video.mime
    );
  }

  // from https://stackoverflow.com/questions/3733227/javascript-seconds-to-minutes-and-seconds#:~:text=To%20get%20the%20number%20of,floor(time%20%2F%2060)%3B
  private getFriendlyTime(duration: number): string {
    // Hours, minutes and seconds
    const hrs = Math.trunc(duration / 3600);
    const mins = Math.trunc((duration % 3600) / 60);
    const secs = Math.trunc(duration % 60);
    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';
    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }
    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;

    return ret;
  }

  private updateProgress(): void {
    this.currentTime = this.getFriendlyTime(this.player.currentTime);

    const progress = Math.floor(
      (this.player.currentTime / this.player.duration) * 100
    );
    const remaining = 100 - progress;

    this.currentProgress = `${progress}%`;
    this.remainingProgress = `${remaining}%`;
  }
}

export interface Fullscreenable {
  webkitRequestFullscreen: () => void;
  msRequestFullScreen: () => void;
}
