/* eslint-disable unicorn/no-null */
import { AbstractControl, ValidationErrors } from '@angular/forms';

const uriRegex =
  /* eslint-disable-next-line no-useless-escape */
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w!#$&'()*+,./:;=?@[\]~\-]+$/;

export const uriValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!uriRegex.test(control.value) && control.value !== '') {
    return { invalidURI: true };
  }

  return null;
};
