/* eslint-disable unicorn/no-null */
import { Injectable } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import type { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const DELIMITER = '.';

/*
This parser is to format the ng-bootstrap datepicker date in the format e.g. 01.12.2022
https://ng-bootstrap.github.io/#/components/datepicker/overview
*/
@Injectable()
export class NgbCustomDateParserFormatter extends NgbDateParserFormatter {
  public parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(DELIMITER);

      return {
        day: Number.parseInt(date[0], 10),
        month: Number.parseInt(date[1], 10),
        year: Number.parseInt(date[2], 10)
      };
    }

    return null;
  }

  public format(date: NgbDateStruct | null): string {
    if (!date) {
      return '';
    }

    const paddedDay = date.day < 10 ? `0${date.day}` : date.day;
    const paddedMonth = date.month < 10 ? `0${date.month}` : date.month;

    return paddedDay + DELIMITER + paddedMonth + DELIMITER + date.year;
  }
}
