import { Component, Input } from '@angular/core';
import { Language } from '../../../../../generated/base-types';
import { TaskOptionQuestionGroupFragment } from '../../create-task-modal/load-task-options.generated';

@Component({
  selector: 'co-translate-task-table',
  templateUrl: './translate-task-table.component.html'
})
export class TranslateTaskTableComponent {
  @Input()
  public questionGroups: TaskOptionQuestionGroupFragment[];
  @Input() public set languages(languages: Language[]) {
    this.deEnabled = languages.includes(Language.De);
    this.frEnabled = languages.includes(Language.Fr);
    this.itEnabled = languages.includes(Language.It);
    this.enEnabled = languages.includes(Language.En);
  }

  public deEnabled = false;
  public frEnabled = false;
  public itEnabled = false;
  public enEnabled = false;
}
