import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { VideoModalComponent } from './components/video-modal.component';

angular
  .module('questionForm')
  .directive(
    'qfVideoModalComponent',
    downgradeComponent({ component: VideoModalComponent })
  );
