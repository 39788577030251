angular.module('questionForm').config([
  '$stateProvider',
  '$locationProvider',
  function ($stateProvider, $locationProvider) {
    $stateProvider
      .state('newQuestionForm', {
        url: '/question_management/new?questionType&questionGroupType',
        params: {
          ref: null
        },
        views: {
          'main@': {
            component: 'editQuestionGroupWrapper'
          }
        },
        resolve: {
          newQuestionType: [
            '$stateParams',
            function ($stateParams) {
              return $stateParams.questionType;
            }
          ],
          newQuestionGroupType: [
            '$stateParams',
            function ($stateParams) {
              return $stateParams.questionGroupType;
            }
          ]
        },
        authorize: [
          'Role',
          'user',
          function (Role, user) {
            return (
              Role.isSuperUser(user) ||
              Role.hasRole(user, 'administrator') ||
              Role.hasRole(user, 'coordinator')
            );
          }
        ]
      })
      .state('duplicateQuestionForm', {
        url: '/question_management/new?copy_of',
        params: {
          ref: null
        },
        views: {
          'main@': {
            component: 'editQuestionGroupWrapper'
          }
        },
        authorize: [
          'Role',
          'user',
          function (Role, user) {
            return (
              Role.isSuperUser(user) ||
              Role.hasRole(user, 'administrator') ||
              Role.hasRole(user, 'coordinator')
            );
          }
        ],
        resolve: {
          duplicateQuestionGroupId: [
            '$stateParams',
            function ($stateParams) {
              return parseInt($stateParams.copy_of);
            }
          ]
        }
      })
      .state('editQuestionForm', {
        url: '/question_management/edit/:id?ref',
        authorize: [
          'Role',
          'user',
          function (Role, user) {
            return (
              Role.isSuperUser(user) ||
              Role.hasRole(user, 'administrator') ||
              Role.hasRole(user, 'coordinator')
            );
          }
        ],
        views: {
          'main@': {
            component: 'editQuestionGroupWrapper'
          }
        },
        resolve: {
          // used for old AngularJS toolbar in Question Group form
          questionGroupId: [
            '$stateParams',
            function ($stateParams) {
              return $stateParams.id;
            }
          ]
        }
      });
    $locationProvider.html5Mode(true);
  }
]);
