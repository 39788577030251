angular.module('examManagement').controller('QuestionGroupCtrl', [
  '$scope', 'QuestionGroupDetails', 'Panel', 'Store', 'Selectors::ExamQuestionGroupList',
  function($scope, QuestionGroupDetails, Panel, Store, Selectors) {
    var loadQuestionGroup = function(examQuestionGroup) {
      var resource = QuestionGroupDetails.get(examQuestionGroup.question_group.id);
      resource.then(function(data) {
        $scope.selectedQuestionGroup = data;
      });
      return resource;
    };

    function getSelectedExamQuestionGroup () {
      return Selectors.getSelectedItem(Store.getState());
    }

    $scope.$watchCollection(function() {
      return getSelectedExamQuestionGroup();
    }, function (examQuestionGroup) {
      if (examQuestionGroup !== undefined && examQuestionGroup !== null && examQuestionGroup.id !== null) {
        Panel.loading('right');
        loadQuestionGroup(examQuestionGroup).then(function() {
          Panel.stopLoading('right');
        });
      } else {
        $scope.selectedQuestionGroup = void 0;
      }
    });
  }
]);
