import { Component } from '@angular/core';

@Component({
  selector: 'co-app',
  template: `
    <man-app-header></man-app-header>
    <router-outlet></router-outlet>
    <co-toasts-container></co-toasts-container>
  `,
  host: { class: 'app' }
})
export class AppComponent {}
