import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RemoteData } from 'src/app/common/utils/remote-data';

@Component({
  selector: 'man-table-wrapper',
  templateUrl: './table-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableWrapperComponent {
  @Input() public request: RemoteData<unknown>;
}
