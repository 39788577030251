angular.module('common').factory('InputErrorHandler', ['BasicErrorHandler', function (BasicErrorHandler) {
  function InputErrorHandler (element) {
    BasicErrorHandler.bind(this)(element, {
      fieldSelector: 'input, textarea',
      iconSelector: '.inline-error__icon',
      tooltipSelector: '.inline-error__tooltip'
    });
  }

  InputErrorHandler.prototype = angular.copy(BasicErrorHandler.prototype);

  InputErrorHandler.prototype.readFieldValue = function () {
    return this.element.find('input, textarea').val();
  };

  return InputErrorHandler;
}]);
