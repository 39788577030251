import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';

export const OLD_TRANSLATIONS_PREFIX = 'old';

// ngx-translate handler that tries to look a key in the old translation files
// if the key is not found in the new translation files
export class OldTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    // prevent infinite key lookup when key is simply missing in old translations
    if (params.key.startsWith(OLD_TRANSLATIONS_PREFIX)) {
      // remove prefix from missing key for clarity
      return params.key.replace(`${OLD_TRANSLATIONS_PREFIX}.`, '');
    }

    const oldTranslationKey = `${OLD_TRANSLATIONS_PREFIX}.${params.key}`;

    return params.translateService.instant(
      oldTranslationKey,
      params.interpolateParams
    );
  }
}
