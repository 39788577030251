import { Injectable, InjectionToken, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ConfirmModalComponent,
  ConfirmModalData
} from './confirm-modal.component';
import { ErrorModalComponent, ErrorModalData } from './error-modal.component';

export const MODAL_DATA = new InjectionToken('MODAL_DATA');

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private readonly ngbModal: NgbModal) {}

  public confirmDelete(): Promise<boolean> {
    return this.confirm({
      titleTranslationKey: 'admin.common.confirm_modal.delete.title',
      messageTranslationKey: 'admin.common.confirm_modal.delete.message'
    });
  }

  public confirmUnsavedChanges(): Promise<boolean> {
    return this.confirm({
      titleTranslationKey: 'admin.common.confirm_modal.unsaved_changes.title',
      messageTranslationKey:
        'admin.common.confirm_modal.unsaved_changes.message'
    });
  }

  public confirm(data: ConfirmModalData): Promise<boolean> {
    const modalRef = this.ngbModal.open(ConfirmModalComponent, {
      centered: true,
      keyboard: true,
      injector: createDataInjector(data)
    });

    return modalRef.result.then(
      () => true,
      () => false
    );
  }

  public error(data: ErrorModalData): Promise<boolean> {
    const modalRef = this.ngbModal.open(ErrorModalComponent, {
      centered: true,
      backdrop: 'static',
      injector: createDataInjector(data)
    });

    return modalRef.result.then(
      () => true,
      () => false
    );
  }
}

function createDataInjector<T>(data: T): Injector {
  return Injector.create({
    providers: [
      {
        provide: MODAL_DATA,
        useValue: data
      }
    ]
  });
}
