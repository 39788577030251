import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { DuplicateQuestionGroupModalComponent } from '../modals/duplicate-question-group-modal/duplicate-question-group-modal.component';

angular
  .module('common')
  .directive(
    'coDuplicateQuestionGroupModal',
    downgradeComponent({ component: DuplicateQuestionGroupModalComponent })
  );
