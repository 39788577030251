import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadDimensions } from '../../../state/pool-details/dimensions/dimensions.actions';

@Injectable({ providedIn: 'root' })
export class DimensionsListGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    this.store.dispatch(new LoadDimensions());

    return true;
  }
}
