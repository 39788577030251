import type { DndDropEvent } from 'ngx-drag-drop';

export interface IndexRange {
  from: number;
  to: number;
}

export interface InsecureIndexRange {
  from: number | undefined;
  to: number | undefined;
}

export function isSecureIndexRange(
  range: InsecureIndexRange
): range is IndexRange {
  return range.from !== undefined && range.to !== undefined;
}

export function transformDropEventToIndexRange(
  event: DndDropEvent
): InsecureIndexRange {
  const from = Number.isInteger(event.data) ? event.data : undefined;
  const to = event.index;

  return {
    from,
    to
  };
}

export function normalizeIndexRange(indexRange: IndexRange): IndexRange {
  // When moving downwards the target index is one index too big because it
  // the moving item is also counted.
  return indexRange.to > indexRange.from
    ? {
        from: indexRange.from,
        to: indexRange.to - 1
      }
    : {
        from: indexRange.from,
        to: indexRange.to
      };
}

export function moveObject<T>(
  objects: T[],
  fromIndex: number,
  toIndex: number
): T[] {
  const movedObjects = [...objects];
  const removedObjects = movedObjects.splice(fromIndex, 1);
  movedObjects.splice(toIndex, 0, ...removedObjects);

  return movedObjects;
}
