import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { ExcelStatisticsValidation } from '../../../generated/base-types';
import { ModalService } from '../../admin/common/modal/modal.service';
import { takeGraphQLResult } from '../../common/operators/take-graphql-response';
import { ToastsService } from '../../common/services/toasts.service';
import { ManagerErrorCode, parseError } from '../../common/utils/error-parser';
import { RemoteData } from '../../common/utils/remote-data';
import {
  requestStateFailure,
  requestStateLoading,
  requestStateSuccess
} from '../../common/utils/remote-data-utils';
import {
  assertIsDefined,
  isDefined
} from '../../common/utils/type-guards/is-defined';
import { ImportExcelStatisticsGQL } from '../services/import-excel-statistics.generated';
import { ExamState, ExamStateModel } from './exam.state';
import {
  ImportExcelStatistics,
  ImportExcelStatisticsFailure,
  ImportExcelStatisticsSuccess,
  ResetMutation
} from './excel-statistics.actions';
import { PoolState, PoolStateModel } from './pool.state';

export interface ExcelStatisticsStateModel {
  mutation: RemoteData<void, ExcelStatisticsValidation>;
}

@State<ExcelStatisticsStateModel>({
  name: 'excelStatistics',
  defaults: {
    mutation: { requestState: 'initial' }
  }
})
@Injectable({
  providedIn: 'root'
})
export class ExcelStatisticsState {
  constructor(
    private readonly importExcelStatisticsService: ImportExcelStatisticsGQL,
    private readonly toastsService: ToastsService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly store: Store
  ) {}

  @Selector()
  public static mutationLoading(state: ExcelStatisticsStateModel): boolean {
    return requestStateLoading(state.mutation);
  }

  @Selector()
  public static mutationFailure(state: ExcelStatisticsStateModel): boolean {
    return requestStateFailure(state.mutation);
  }

  @Selector()
  public static mutationSuccess(state: ExcelStatisticsStateModel): boolean {
    return requestStateSuccess(state.mutation);
  }

  @Selector()
  public static mutationValidations(
    state: ExcelStatisticsStateModel
  ): ExcelStatisticsValidation | undefined {
    return state.mutation.error?.validations as
      | ExcelStatisticsValidation
      | undefined;
  }

  @Action(ImportExcelStatistics)
  public importExcelStatistics(
    ctx: StateContext<ExcelStatisticsStateModel>,
    { attributes }: ImportExcelStatistics
  ): Observable<void> {
    ctx.patchState({ mutation: { requestState: 'loading' } });
    const pool = this.store.selectSnapshot<PoolStateModel>(PoolState).pool.data;
    const exam = this.store.selectSnapshot<ExamStateModel>(ExamState).exam.data;

    assertIsDefined(
      pool,
      'Cannot set question column definition without a pool'
    );
    assertIsDefined(
      exam,
      'Cannot set question column definition without a pool'
    );

    return this.importExcelStatisticsService
      .mutate({ poolId: pool.id, examId: exam.id, input: attributes })
      .pipe(
        takeGraphQLResult(),
        map(result => result?.importExcelStatistics?.exam),
        filter(isDefined),
        mergeMap(exam => ctx.dispatch(new ImportExcelStatisticsSuccess(exam))),
        catchError((err: unknown) =>
          ctx.dispatch(
            new ImportExcelStatisticsFailure(
              parseError<ExcelStatisticsValidation>(err),
              attributes
            )
          )
        )
      );
  }

  @Action(ImportExcelStatisticsSuccess)
  public importExcelStatisticsSuccess(
    ctx: StateContext<ExcelStatisticsStateModel>,
    _: ImportExcelStatisticsSuccess
  ): void {
    ctx.patchState({
      mutation: { requestState: 'success' }
    });

    this.toastsService.addSuccess(
      this.translateService.instant(
        'pool.exams.upload_statistics.notification.created'
      )
    );
  }

  @Action(ImportExcelStatisticsFailure)
  public importExcelStatisticsFailure(
    ctx: StateContext<ExcelStatisticsStateModel>,
    { error, attributes }: ImportExcelStatisticsFailure
  ): void {
    ctx.patchState({
      mutation: {
        requestState: 'failure',
        error
      }
    });

    if (error.code === ManagerErrorCode.ValidationError) {
      this.toastsService.addWarning(
        this.translateService.instant(
          'pool.exams.upload_statistics.notification.failed'
        )
      );
    } else {
      this.modalService.error({ errorCode: error.code }).then(retry => {
        ctx.dispatch(
          retry ? new ImportExcelStatistics(attributes) : new ResetMutation()
        );
      });
    }
  }

  @Action(ResetMutation)
  public resetMutation(ctx: StateContext<ExcelStatisticsStateModel>): void {
    ctx.patchState({
      mutation: {
        requestState: 'initial'
      }
    });
  }
}
