angular.module('common').filter('yesOrBlank', function() {
  return function(boolean) {
    const booleans = [boolean].flat();

    if (!booleans.length) {
      return;
    }

    return booleans
      .map(bool => (bool ? I18n.t('common.yes') : '-'))
      .join(', ');
  };
});
