import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DATA } from './modal.service';

export interface ConfirmModalData {
  titleTranslationKey: string;
  messageTranslationKey: string;
}

@Component({
  templateUrl: './confirm-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmModalComponent {
  constructor(
    private ngbActiveModal: NgbActiveModal,
    @Inject(MODAL_DATA) public data: ConfirmModalData
  ) {}

  public onConfirm(): void {
    this.ngbActiveModal.close();
  }

  public onCancel(): void {
    this.ngbActiveModal.dismiss();
  }
}
