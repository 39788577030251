import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
export type LoadUsersFieldsFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null, language: Types.Language, organisation?: string | null, alternativeEmail?: string | null, mobilePhone?: string | null, alternativeMobilePhone?: string | null, currentSignInAt?: string | null, superUser: boolean, useEmailForMtan: boolean };

export const LoadUsersFieldsFragmentDoc = gql`
    fragment LoadUsersFields on User {
  id
  firstName
  lastName
  email
  language
  organisation
  alternativeEmail
  mobilePhone
  alternativeMobilePhone
  currentSignInAt
  superUser
  useEmailForMtan
}
    `;