import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { QuestionGroupTaskToolbarComponent } from '../components/question-group-toolbar/question-group-task-toolbar.component';

angular.module('common').directive(
  'qmQuestionGroupTaskToolbar',
  downgradeComponent({
    component: QuestionGroupTaskToolbarComponent
  })
);
