angular.module('settings').factory('Selectors::PoolList', [function () {
    function getItems (object) {
      return (object && object.poolList && object.poolList.items) || [];
    }

    function getPool (object, id) {
      return getItems(object).find(function (item) {
        return item.id == id;
      });
    }

    return {
      getItems: getItems,
      getPool: getPool
    };
  }
]);
