angular.module('examManagement').controller('SelfAssessmentModalCtrl', [
  '$scope', '$uibModalInstance', 'exam', 'ConfirmationModal',
  function ($scope, $uibModalInstance, exam, ConfirmationModal) {

    $scope.I18n = I18n;
    $scope.exam = exam;
    $scope.showWarnings = false;
    $scope.messages = [];
    $scope.result = {
      requirePassword: false
    };

    var validate = function () {
      $scope.messages = [];
      if($scope.result.requirePassword) {
        if(!$scope.result.password || !$scope.result.passwordRepeat){
          $scope.messages.push({
            type: 'alert',
            content: I18n.t('exams.self_assessment_modal.validations.password_missing')
          });
          return false;
        }
        else if($scope.result.password !== $scope.result.passwordRepeat) {
          $scope.messages.push({
            type: 'alert',
            content: I18n.t('exams.self_assessment_modal.validations.password_not_matching')
          });
          return false;
        }
        return true;
      }
      else {
        $scope.result.password = undefined;
        $scope.result.passwordRepeat = undefined;
        return true;
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };

    $scope.save = function () {
      if(validate()) {
        $scope.showWarnings = false;
        ConfirmationModal.show(
          I18n.t('exams.self_assessment_modal.confirm.title'),
          I18n.t('exams.self_assessment_modal.confirm.content'))
          .result.then(function () {
            $uibModalInstance.close($scope.result);
          });
      }
      else{
        $scope.showWarnings = true;
      }
    };
  }]);
