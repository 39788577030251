<table class="table table-striped">
  <thead>
    <tr>
      <th>
        {{ 'common.question_list.columns.sequential_number' | translate }}
      </th>
      <th>{{ 'common.question_list.columns.supervisor' | translate }}</th>
      <th>{{ 'common.question_list.columns.question_types' | translate }}</th>
      <th>{{ 'common.question_list.columns.source_language' | translate }}</th>
      <th *ngIf="deEnabled">
        {{
          'common.question_list.columns.content_validation_state_de' | translate
        }}
      </th>
      <th *ngIf="frEnabled">
        {{
          'common.question_list.columns.content_validation_state_fr' | translate
        }}
      </th>
      <th *ngIf="itEnabled">
        {{
          'common.question_list.columns.content_validation_state_it' | translate
        }}
      </th>
      <th *ngIf="enEnabled">
        {{
          'common.question_list.columns.content_validation_state_en' | translate
        }}
      </th>
    </tr>

    <tr></tr>
  </thead>

  <tbody>
    <tr *ngFor="let questionGroup of questionGroups">
      <td>{{ questionGroup.sequentialNumber }}</td>
      <td>{{ questionGroup.supervisor }}</td>
      <td>
        <ng-container
          *ngIf="
            questionGroup.type === 'SINGLE' && $any(questionGroup).question
          "
        >
          {{
            'questions.type.' + $any(questionGroup).question.type | translate
          }}
        </ng-container>
        <ng-container
          *ngIf="
            (questionGroup.type === 'SERIES' ||
              questionGroup.type === 'SEQUENCE') &&
            $any(questionGroup).questions
          "
        >
          <span
            *ngFor="
              let question of $any(questionGroup).questions;
              last as lastItem
            "
          >
            {{ 'questions.type.' + question.type | translate }}
            <span *ngIf="lastItem">,</span>
          </span>
        </ng-container>
      </td>
      <td>{{ questionGroup.sourceLanguage }}</td>
      <td *ngIf="deEnabled">{{ questionGroup.contentValidation.de }}</td>
      <td *ngIf="frEnabled">{{ questionGroup.contentValidation.fr }}</td>
      <td *ngIf="itEnabled">{{ questionGroup.contentValidation.it }}</td>
      <td *ngIf="enEnabled">{{ questionGroup.contentValidation.en }}</td>
    </tr>
  </tbody>
</table>
