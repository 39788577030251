angular.module('examManagement').factory('TabSelection', ['$cookies', function($cookies) {

  function Selection() {
    this.selection = undefined;
  }

  // --------- helper functions ----------

  Selection.prototype._putIntoStore = function(index) {
    $cookies.putObject('examManagement.TabSelection.index', index);
  };

  Selection.prototype._getFromStore = function(index) {
    return $cookies.getObject('examManagement.TabSelection.index');
  };

  // --------- visibility functions ---------

  Selection.prototype.init = function(indexes) {
    var selectedIndex = this._getFromStore();

    if(selectedIndex) {
      var selectedTab = indexes.filter(function(index) {
        return index === selectedIndex;
      })[0];

      this.set(selectedIndex);
    }
  };

  Selection.prototype.set = function(index) {
    this.selection = index;
    this._putIntoStore(index);
  };

  Selection.prototype.get = function() {
    return this.selection;
  };

  Selection.prototype.removeTab = function() {
    this.selection = undefined;
  };

  return new Selection();
}]);
