import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from '../../../generated/base-types';
import { AppContextState } from '../../state/app-context.state';
import { WindowService } from '../services/window.service';

@Component({
  selector: 'man-app-header',
  templateUrl: './app-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent {
  @Select(AppContextState.currentUser)
  public currentUser$: Observable<
    Pick<User, 'firstName' | 'lastName'> | undefined
  >;

  constructor(private readonly windowService: WindowService) {}

  public onLogout(): Promise<void> {
    return fetch('/logout', { method: 'DELETE' }).then(() => {
      this.windowService.href = '/login';
    });
  }
}
