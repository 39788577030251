import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadUsers } from '../state/users.actions';

@Injectable({ providedIn: 'root' })
export class UsersGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): boolean {
    this.store.dispatch(new LoadUsers());

    return true;
  }
}
