<label class="checkbox-label">
  <input
    type="checkbox"
    class="checkbox-input sr-only"
    [disabled]="disabled || undefined"
    [attr.checked]="checked || undefined"
    (change)="onChange()"
  />
  <i
    class="checkbox-icon fa fa-border icon-primary"
    [ngClass]="{
      'fa-check': checked === true,
      'fa-minus': checked === undefined
    }"
  ></i
  ><ng-content></ng-content>
</label>
