angular.module('common').factory('Message', ['resourceFactory' ,function(resourceFactory) {

  var Message = resourceFactory('/api/comments', {});


  Message.prototype.isSystemMessage = function() {
    return this.type === "system";
  };

  Message.prototype.isComment = function(comment) {
    return this.type === "comment";
  };

  return Message;
}]);
