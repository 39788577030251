<ng-container [formGroup]="form">
  <div class="language-specific-content">
    <!-- DE -->
    <div *ngIf="languageVisibility.de" class="long-menu-container__languages">
      <label for="question_text_de">{{
        'scrudu.languages.de' | translate
      }}</label>
      <qf-rich-editor
        id="question_text_de"
        #editor="richEditor"
        formControlName="contentDe"
      ></qf-rich-editor>
      <qf-input-feedback
        [validations]="validations?.content?.de"
        [inputField]="editor"
        [inputValue]="form.get('contentDe')?.value"
      ></qf-input-feedback>
    </div>

    <!-- FR -->
    <div *ngIf="languageVisibility.fr" class="long-menu-container__languages">
      <label for="question_text_fr">{{
        'scrudu.languages.fr' | translate
      }}</label>
      <qf-rich-editor
        id="question_text_fr"
        #editor="richEditor"
        formControlName="contentFr"
      ></qf-rich-editor>
      <qf-input-feedback
        [validations]="validations?.content?.fr"
        [inputField]="editor"
        [inputValue]="form.get('contentFr')?.value"
      ></qf-input-feedback>
    </div>

    <!-- IT -->
    <div *ngIf="languageVisibility.it" class="long-menu-container__languages">
      <label for="question_text_it">{{
        'scrudu.languages.it' | translate
      }}</label>
      <qf-rich-editor
        id="question_text_it"
        #editor="richEditor"
        formControlName="contentIt"
      ></qf-rich-editor>
      <qf-input-feedback
        [validations]="validations?.content?.it"
        [inputField]="editor"
        [inputValue]="form.get('contentIt')?.value"
      ></qf-input-feedback>
    </div>

    <!-- EN -->
    <div *ngIf="languageVisibility.en" class="long-menu-container__languages">
      <label for="question_text_en">{{
        'scrudu.languages.en' | translate
      }}</label>
      <qf-rich-editor
        id="question_text_en"
        #editor="richEditor"
        formControlName="contentEn"
      ></qf-rich-editor>
      <qf-input-feedback
        [validations]="validations?.content?.en"
        [inputField]="editor"
        [inputValue]="form.get('contentEn')?.value"
      ></qf-input-feedback>
    </div>

    <div
      class="long-menu-container__question-video"
      *ngIf="languageVisibility.de"
    >
      <co-single-panel-accordion
        title="{{ 'video_upload.title' | translateToLanguage : 'de' | async }}"
        [initiallyOpen]="!!form.get('videoDe')?.value"
      >
        <qf-video-upload formControlName="videoDe"></qf-video-upload>
      </co-single-panel-accordion>
    </div>
    <div
      class="long-menu-container__question-video"
      *ngIf="languageVisibility.fr"
    >
      <co-single-panel-accordion
        title="{{ 'video_upload.title' | translateToLanguage : 'fr' | async }}"
        [initiallyOpen]="!!form.get('videoFr')?.value"
      >
        <qf-video-upload formControlName="videoFr"></qf-video-upload>
      </co-single-panel-accordion>
    </div>
    <div
      class="long-menu-container__question-video"
      *ngIf="languageVisibility.en"
    >
      <co-single-panel-accordion
        title="{{ 'video_upload.title' | translateToLanguage : 'en' | async }}"
        [initiallyOpen]="!!form.get('videoEn')?.value"
      >
        <qf-video-upload formControlName="videoEn"></qf-video-upload>
      </co-single-panel-accordion>
    </div>
    <div
      class="long-menu-container__question-video"
      *ngIf="languageVisibility.it"
    >
      <co-single-panel-accordion
        title="{{ 'video_upload.title' | translateToLanguage : 'it' | async }}"
        [initiallyOpen]="!!form.get('videoIt')?.value"
      >
        <qf-video-upload formControlName="videoIt"></qf-video-upload>
      </co-single-panel-accordion>
    </div>
  </div>

  <div class="long-menu-specific-content">
    <qf-question-long-menu-list
      [languages]="languages"
      [validations]="validations?.list"
      [solution]="solution"
      formControlName="list"
      [(selectedEntryId)]="selectedListEntryId"
      (languageChanged)="selectedLanguage = $event"
      (listChangeEvent)="clearSolution($event)"
    ></qf-question-long-menu-list>
    <qf-long-menu-solution-container
      formControlName="responses"
      [selectedEntryId]="selectedListEntryId"
      [list]="list"
      [language]="selectedLanguage"
      [validations]="validations?.longMenuSolution"
    ></qf-long-menu-solution-container>
  </div>
</ng-container>
