import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { QuestionGroupToolbarComponent } from '../components/question-group-toolbar/question-group-toolbar.component';

angular.module('common').directive(
  'qmQuestionGroupToolbar',
  downgradeComponent({
    component: QuestionGroupToolbarComponent,
    inputs: ['managementType']
  })
);
