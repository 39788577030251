(function() {
  angular.module('examManagement').controller('CandidatesCtrl', [
    '$scope', '$uibModal', 'Candidate', 'TabSelection', 'List',
    'OrderedList', 'Panel', 'ConfirmationModal',
    'Store', 'Actions::CandidateList', 'Selectors::CandidateList', 'Selectors::ExamList', 'Repositories::Candidate',
    function ($scope, $uibModal, Candidate, TabSelection, List,
              OrderedList, Panel, ConfirmationModal,
              Store, Actions, Selectors, ExamSelectors, CandidateRepository) {

      function getSelectedExam () {
        return ExamSelectors.getSelectedItem(Store.getState());
      }

      function deleteCandidates() {
        var selections = Selectors.getSelection(Store.getState());
        if (Selectors.getSelectionCount(Store.getState()) > 0) {
          Candidate.destroyAll(getSelectedExam().id, selections)
                   .then(function (data) {
                     Store.dispatch(Actions.deleteCandidates(selections));
                     $scope.candidateDestroyJobId = data.job_id;
                   });
        }
      };

      $scope.isExamSelected = function () {
        return getSelectedExam() !== 'undefined';
      }

      $scope.hasSelectedItems = function () {
        return Selectors.getSelectionCount(Store.getState()) > 0;
      };

      $scope.isExamDateInPast = function () {
        var exam = getSelectedExam();
        if (angular.isDefined(exam)) {
          var today = new Date();
          var examDate = new Date(exam.date);
          return examDate.getTime() > today.getTime();
        } else {
          return false;
        }
      };

      $scope.isExamProvisioned = function () {
        var selectedExam = getSelectedExam();
        return selectedExam && selectedExam.provisioned;
      };

      $scope.openImportForm = function ($event) {
        var eventData = $event.target.dataset,
            instance = $uibModal.open({ template: require('./candidates_import_form.html'),
                                        controller: 'CandidatesImportFormCtrl' });
        instance.url = eventData.url;

        instance.result.then(function (data) {
          $scope.candidateUploadJobId = data.job_id;
        });
      };

      $scope.openAccessPaperForm = function () {
        $uibModal.open({ template: require('./access_paper_form.html'),
                         controller: 'AccessPaperFormCtrl',
                         resolve: {
                           exam: function () {
                             return getSelectedExam();
                           },
                         }});
      };

      $scope.uploadApiExamCandidatesPath = "/api/exams/:examId/candidates/upload";

      $scope.openMasterAccessForm = function () {
        $uibModal.open({ template: require('./master_access_form.html'),
                         controller: 'MasterAccessFormCtrl' });
      };

      $scope.openDeleteConfirmationForm = function($event) {
        var instance = ConfirmationModal.show(
          I18n.t('candidates.delete_confirmation.title'),
          I18n.t('candidates.delete_confirmation.content', {amount: Selectors.getSelectionCount(Store.getState())}));

        instance.result.then(function () {
          deleteCandidates();
        });
      };

      $scope.$on('job.finished', function (event, job) {
        if (job.id == $scope.candidateUploadJobId || job.id == $scope.candidateDestroyJobId) {
          initList();
        }
      });

      var initList = function() {
        if (TabSelection.get() === 1) {
          Store.dispatch(Actions.fetchCandidatesRequest());
          var order = Selectors.getOrderAsParameter(Store.getState());
          CandidateRepository.getAll(getSelectedExam().id, order[0]).then(function (data) {
            Store.dispatch(Actions.fetchCandidatesSuccess(data));
          });
        }
      }
    }
  ]);
}).call(this);
