angular.module('common').directive('questionGroupPreview', [function() {
  return {
    restrict: 'E',
    scope: {
      ready: '=',
      urlDe: '=',
      urlFr: '=',
      urlIt: '=',
      urlEn: '='
    },
    template: require('./question_group_preview.html'),
    controller: [
      '$scope', '$timeout', 'QuestionGroupPreview',
      'Repositories::QuestionGroup', 'Store', 'Selectors::Pool',
      function($scope, $timeout, QuestionGroupPreview, Repository, Store, Selectors) {
        var reloadTimeout, pool, poolSelector;

        function loadVisibility () {
          var visibilities = JSON.parse(localStorage.getItem('common.questionGroup.activePreview')) || {};
          pool.languages.forEach(function (language) {
            if (angular.isUndefined(visibilities[language])) {
              visibilities[language] = false;
            }
          });
          return visibilities;
        }

        function openAccordionIfNecessary() {
          var foundVisibleAccodrion =
            ($scope.urlDe !== undefined && $scope.urlDe !== null && $scope.accordionVisibility.de) ||
            ($scope.urlFr !== undefined && $scope.urlFr !== null && $scope.accordionVisibility.fr) ||
            ($scope.urlIt !== undefined && $scope.urlIt !== null && $scope.accordionVisibility.it) ||
            ($scope.urlEn !== undefined && $scope.urlEn !== null && $scope.accordionVisibility.en);
          if (!foundVisibleAccodrion) {
            $scope.accordionVisibility[pool.languages[0]] = true;
          }
        }

        $scope.saveVisibility = function () {
          localStorage.setItem('common.questionGroup.activePreview', JSON.stringify($scope.accordionVisibility));
        };

        $scope.I18n = I18n;
        poolSelector = Store.subscribeOn(Selectors.getCurrentPool, function (p) {
          pool = p;
          if (pool) {
            $scope.accordionVisibility = loadVisibility();
          }
        });

        $scope.$on('$destroy', function () {
          $timeout.cancel(reloadTimeout);
          poolSelector();
        });

        $scope.$watch(function () {
          return $scope.urlDe + $scope.urlFr + $scope.urlIt + $scope.urlEn;
        }, function(newValue) {
          if (angular.isDefined(newValue)) {
            openAccordionIfNecessary();
          }
        });
      }
    ]
  };
}]);
