import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuperUserGuard } from './super-user-guard';

export const routes: Routes = [
  {
    path: 'newpools',
    loadChildren: () => import('./pool/pool.module').then(m => m.PoolModule)
  },
  {
    path: 'newadmin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [SuperUserGuard]
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then(m => m.ProfileModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      // "computed" should fix issues with navigation canceling when the "unsaved changes" dialog is shown after the back button is pressed
      canceledNavigationResolution: 'computed'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
