import * as Types from '../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CurrentUserFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, superUser: boolean, language: Types.Language, email?: string | null };

export type CurrentUserDetailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserDetailsQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, firstName: string, lastName: string, superUser: boolean, language: Types.Language, email?: string | null } | null };

export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
  firstName
  lastName
  superUser
  language
  email
}
    `;
export const CurrentUserDetailsDocument = gql`
    query CurrentUserDetails {
  currentUser {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserDetailsGQL extends Apollo.Query<CurrentUserDetailsQuery, CurrentUserDetailsQueryVariables> {
    document = CurrentUserDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }