import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { TranslateTaskModalComponent } from '../modals/translate-task-modal/translate-task-modal.component';

angular
  .module('common')
  .directive(
    'coTranslateTaskModal',
    downgradeComponent({ component: TranslateTaskModalComponent })
  );
