import { Language, Scalars } from 'src/generated/base-types';
import {
  assertIsDefined,
  isDefined
} from '../common/utils/type-guards/is-defined';
import { ExamMetadataFragment } from '../services/load-exam.generated';
import { PoolMetadataFragment } from '../services/load-pool.generated';

const DIMENSIONS_LENGTH = 8;

export type PoolId = Scalars['ID'];

export interface ColumnConfig {
  translationKeyName: string;
  enabled: boolean;
  visible: boolean;
  sortable?: boolean;
  translationParams?: { name?: string };
}

export type ColumnKeys =
  | QuestionColumnKey
  | ExamColumnKey
  | TaskColumnKey
  | TaskQuestionColumnKey;

export interface ListColumn extends ColumnConfig {
  name: ColumnKeys;
  translationKey: string;
}

export interface TaskColumnConfiguration {
  id: ColumnConfig;
  type: ColumnConfig;
  receiver: ColumnConfig;
  startsAt: ColumnConfig;
  endsAt: ColumnConfig;
  finishedAt: ColumnConfig;
  creator: ColumnConfig;
  status: ColumnConfig;
}

export type TaskColumnKey = keyof TaskColumnConfiguration;

export interface ExamColumnConfiguration {
  sequentialNumber: ColumnConfig;
  idInBookA: ColumnConfig;
  idInBookB: ColumnConfig;
  legacyId: ColumnConfig;
  literaryReference: ColumnConfig;
  title: ColumnConfig;
  supervisor: ColumnConfig;
  affiliation: ColumnConfig;
  contentValidationStateDe: ColumnConfig;
  contentValidationStateFr: ColumnConfig;
  contentValidationStateIt: ColumnConfig;
  contentValidationStateEn: ColumnConfig;
  type: ColumnConfig;
  questionTypes: ColumnConfig;
  score: ColumnConfig;
  hasImages: ColumnConfig;
  hasVideos: ColumnConfig;
  category0: ColumnConfig;
  category1: ColumnConfig;
  category2: ColumnConfig;
  category3: ColumnConfig;
  category4: ColumnConfig;
  category5: ColumnConfig;
  category6: ColumnConfig;
  category7: ColumnConfig;
  createdAt: ColumnConfig;
  updatedAt: ColumnConfig;
  editor: ColumnConfig;
  author: ColumnConfig;
  numberOfUsages: ColumnConfig;
  lastUsage: ColumnConfig;
  latestStatistic: ColumnConfig;
  cacheLastUsageN: ColumnConfig;
  cacheLastUsageP: ColumnConfig;
  cacheLastUsageR: ColumnConfig;
  cacheLastUsageLne: ColumnConfig;
  cacheLastUsageEliminated: ColumnConfig;
  exams: ColumnConfig;
  contentSizeDe: ColumnConfig;
  contentSizeFr: ColumnConfig;
  contentSizeIt: ColumnConfig;
  contentSizeEn: ColumnConfig;
  revisionStatus: ColumnConfig;
  revisionYear: ColumnConfig;
}

export type ExamColumnKey = keyof ExamColumnConfiguration;

export interface QuestionColumnConfiguration {
  sequentialNumber: ColumnConfig;
  legacyId: ColumnConfig;
  literaryReference: ColumnConfig;
  sourceLanguage: ColumnConfig;
  title: ColumnConfig;
  supervisor: ColumnConfig;
  affiliation: ColumnConfig;
  contentValidationStateDe: ColumnConfig;
  contentValidationStateFr: ColumnConfig;
  contentValidationStateIt: ColumnConfig;
  contentValidationStateEn: ColumnConfig;
  type: ColumnConfig;
  questionTypes: ColumnConfig;
  score: ColumnConfig;
  hasImages: ColumnConfig;
  hasVideos: ColumnConfig;
  category0: ColumnConfig;
  category1: ColumnConfig;
  category2: ColumnConfig;
  category3: ColumnConfig;
  category4: ColumnConfig;
  category5: ColumnConfig;
  category6: ColumnConfig;
  category7: ColumnConfig;
  createdAt: ColumnConfig;
  updatedAt: ColumnConfig;
  editor: ColumnConfig;
  author: ColumnConfig;
  numberOfUsages: ColumnConfig;
  lastUsage: ColumnConfig;
  latestStatistic: ColumnConfig;
  cacheLastUsageN: ColumnConfig;
  cacheLastUsageP: ColumnConfig;
  cacheLastUsageR: ColumnConfig;
  cacheLastUsageLne: ColumnConfig;
  cacheLastUsageXi: ColumnConfig;
  cacheLastUsageEliminated: ColumnConfig;
  exams: ColumnConfig;
  labels: ColumnConfig;
  duplicates?: ColumnConfig;
  revisionStatus: ColumnConfig;
  revisionYear: ColumnConfig;
}

export type QuestionColumnKey = keyof QuestionColumnConfiguration;

export type TaskQuestionColumnConfiguration = Omit<
  QuestionColumnConfiguration,
  'duplicates'
>;

export type TaskQuestionColumnKey = keyof TaskQuestionColumnConfiguration;

interface DimensionEnum {
  category0: ColumnConfig;
  category1: ColumnConfig;
  category2: ColumnConfig;
  category3: ColumnConfig;
  category4: ColumnConfig;
  category5: ColumnConfig;
  category6: ColumnConfig;
  category7: ColumnConfig;
}

type DimensionColumn = {
  [Property in keyof DimensionEnum]: ColumnConfig;
};

export interface ColumnConfiguration {
  questionMgmt?: QuestionColumnConfiguration;
  taskQuestionMgmt?: TaskQuestionColumnConfiguration;
  taskMgmt?: TaskColumnConfiguration;
  examMgmt?: ExamColumnConfiguration;
}

export function getQuestionListColumns(
  columnConfig: QuestionColumnConfiguration
): ListColumn[] {
  return Object.keys(columnConfig).map(key => {
    const column = columnConfig[key as QuestionColumnKey];
    assertIsDefined(column, `Column ${key} was not found in column config`);

    return {
      ...column,
      name: key as QuestionColumnKey,
      translationKey: `activerecord.attributes.question_group.${column.translationKeyName}`
    };
  });
}

export const defaultTaskQuestionColumnConfig: QuestionColumnConfiguration = {
  sequentialNumber: {
    translationKeyName: 'sequential_number',
    enabled: true,
    visible: true,
    sortable: true
  },
  legacyId: {
    translationKeyName: 'legacy_id',
    enabled: true,
    visible: false,
    sortable: true
  },
  literaryReference: {
    translationKeyName: 'literary_reference',
    enabled: true,
    visible: false,
    sortable: true
  },
  sourceLanguage: {
    translationKeyName: 'source_language',
    enabled: true,
    visible: false,
    sortable: true
  },
  title: {
    translationKeyName: 'title',
    enabled: true,
    visible: false,
    sortable: true
  },
  supervisor: {
    translationKeyName: 'supervisor',
    enabled: true,
    visible: false,
    sortable: true
  },
  affiliation: {
    translationKeyName: 'affiliation',
    enabled: true,
    visible: false,
    sortable: true
  },
  contentValidationStateDe: {
    translationKeyName: 'content_validation_state_de',
    enabled: false,
    visible: false,
    sortable: true
  },
  contentValidationStateFr: {
    translationKeyName: 'content_validation_state_fr',
    enabled: false,
    visible: false,
    sortable: true
  },
  contentValidationStateIt: {
    translationKeyName: 'content_validation_state_it',
    enabled: false,
    visible: false,
    sortable: true
  },
  contentValidationStateEn: {
    translationKeyName: 'content_validation_state_en',
    enabled: false,
    visible: false,
    sortable: true
  },
  type: {
    translationKeyName: 'type',
    enabled: true,
    visible: true,
    sortable: true
  },
  questionTypes: {
    translationKeyName: 'question_types',
    enabled: true,
    visible: true,
    sortable: true
  },
  score: {
    translationKeyName: 'score',
    enabled: true,
    visible: false,
    sortable: true
  },
  hasImages: {
    translationKeyName: 'has_images',
    enabled: true,
    visible: true,
    sortable: true
  },
  hasVideos: {
    translationKeyName: 'has_videos',
    enabled: true,
    visible: true,
    sortable: true
  },
  category0: {
    translationKeyName: 'category_0',
    enabled: false,
    visible: true,
    sortable: true
  },
  category1: {
    translationKeyName: 'category_1',
    enabled: false,
    visible: true,
    sortable: true
  },
  category2: {
    translationKeyName: 'category_2',
    enabled: false,
    visible: true,
    sortable: true
  },
  category3: {
    translationKeyName: 'category_3',
    enabled: false,
    visible: true,
    sortable: true
  },
  category4: {
    translationKeyName: 'category_4',
    enabled: false,
    visible: true,
    sortable: true
  },
  category5: {
    translationKeyName: 'category_5',
    enabled: false,
    visible: true,
    sortable: true
  },
  category6: {
    translationKeyName: 'category_6',
    enabled: false,
    visible: true,
    sortable: true
  },
  category7: {
    translationKeyName: 'category_7',
    enabled: false,
    visible: true,
    sortable: true
  },
  createdAt: {
    translationKeyName: 'created_at',
    enabled: true,
    visible: true,
    sortable: true
  },
  updatedAt: {
    translationKeyName: 'updated_at',
    enabled: true,
    visible: false,
    sortable: true
  },
  editor: {
    translationKeyName: 'editor',
    enabled: true,
    visible: false,
    sortable: true
  },
  author: {
    translationKeyName: 'author',
    enabled: true,
    visible: false,
    sortable: true
  },
  numberOfUsages: {
    translationKeyName: 'number_of_usages',
    enabled: true,
    visible: true,
    sortable: true
  },
  lastUsage: {
    translationKeyName: 'last_usage',
    enabled: true,
    visible: false,
    sortable: true
  },
  latestStatistic: {
    translationKeyName: 'latest_statistic',
    enabled: true,
    visible: false,
    sortable: true
  },
  cacheLastUsageN: {
    translationKeyName: 'cache_last_usage_n',
    enabled: true,
    visible: false,
    sortable: true
  },
  cacheLastUsageP: {
    translationKeyName: 'cache_last_usage_p',
    enabled: true,
    visible: true,
    sortable: true
  },
  cacheLastUsageR: {
    translationKeyName: 'cache_last_usage_r',
    enabled: true,
    visible: true,
    sortable: true
  },
  cacheLastUsageLne: {
    translationKeyName: 'cache_last_usage_lne',
    enabled: true,
    visible: false,
    sortable: false
  },
  cacheLastUsageXi: {
    translationKeyName: 'cache_last_usage_xi',
    enabled: true,
    visible: false,
    sortable: false
  },
  cacheLastUsageEliminated: {
    translationKeyName: 'cache_last_usage_eliminated',
    enabled: true,
    visible: true,
    sortable: true
  },
  exams: {
    translationKeyName: 'exams',
    enabled: true,
    visible: true,
    sortable: false
  },
  labels: {
    translationKeyName: 'labels',
    enabled: true,
    visible: true,
    sortable: false
  },
  revisionStatus: {
    translationKeyName: 'revision_status',
    enabled: true,
    visible: true,
    sortable: false
  },
  revisionYear: {
    translationKeyName: 'revision_year',
    enabled: true,
    visible: false,
    sortable: true
  }
};

export const defaultExamColumnConfig: ExamColumnConfiguration = {
  sequentialNumber: {
    translationKeyName: 'sequential_number',
    enabled: true,
    visible: true
  },
  idInBookA: {
    translationKeyName: 'id_in_book_a',
    enabled: true,
    visible: false
  },
  idInBookB: {
    translationKeyName: 'id_in_book_b',
    enabled: true,
    visible: false
  },
  legacyId: {
    translationKeyName: 'legacy_id',
    enabled: true,
    visible: false
  },
  literaryReference: {
    translationKeyName: 'literary_reference',
    enabled: true,
    visible: false
  },
  title: {
    translationKeyName: 'title',
    enabled: true,
    visible: false
  },
  supervisor: {
    translationKeyName: 'supervisor',
    enabled: true,
    visible: false
  },
  affiliation: {
    translationKeyName: 'affiliation',
    enabled: true,
    visible: false
  },
  contentValidationStateDe: {
    translationKeyName: 'content_validation_state_de',
    enabled: false,
    visible: false
  },
  contentValidationStateFr: {
    translationKeyName: 'content_validation_state_fr',
    enabled: false,
    visible: false
  },
  contentValidationStateIt: {
    translationKeyName: 'content_validation_state_it',
    enabled: false,
    visible: false
  },
  contentValidationStateEn: {
    translationKeyName: 'content_validation_state_en',
    enabled: false,
    visible: false
  },
  type: {
    translationKeyName: 'type',
    enabled: true,
    visible: true
  },
  questionTypes: {
    translationKeyName: 'question_types',
    enabled: true,
    visible: true
  },
  score: {
    translationKeyName: 'score',
    enabled: true,
    visible: false
  },
  hasImages: {
    translationKeyName: 'has_images',
    enabled: true,
    visible: true
  },
  hasVideos: {
    translationKeyName: 'has_videos',
    enabled: true,
    visible: true
  },
  category0: {
    translationKeyName: 'category_0',
    enabled: false,
    visible: true
  },
  category1: {
    translationKeyName: 'category_1',
    enabled: false,
    visible: true
  },
  category2: {
    translationKeyName: 'category_2',
    enabled: false,
    visible: true
  },
  category3: {
    translationKeyName: 'category_3',
    enabled: false,
    visible: true
  },
  category4: {
    translationKeyName: 'category_4',
    enabled: false,
    visible: true
  },
  category5: {
    translationKeyName: 'category_5',
    enabled: false,
    visible: true
  },
  category6: {
    translationKeyName: 'category_6',
    enabled: false,
    visible: true
  },
  category7: {
    translationKeyName: 'category_7',
    enabled: false,
    visible: true
  },
  createdAt: {
    translationKeyName: 'created_at',
    enabled: true,
    visible: true
  },
  updatedAt: {
    translationKeyName: 'updated_at',
    enabled: true,
    visible: false
  },
  editor: {
    translationKeyName: 'editor',
    enabled: true,
    visible: false
  },
  author: {
    translationKeyName: 'author',
    enabled: true,
    visible: false
  },
  numberOfUsages: {
    translationKeyName: 'number_of_usages',
    enabled: true,
    visible: true
  },
  lastUsage: {
    translationKeyName: 'last_usage',
    enabled: true,
    visible: false
  },
  latestStatistic: {
    translationKeyName: 'latest_statistic',
    enabled: true,
    visible: false
  },
  cacheLastUsageN: {
    translationKeyName: 'cache_last_usage_n',
    enabled: true,
    visible: false
  },
  cacheLastUsageP: {
    translationKeyName: 'cache_last_usage_p',
    enabled: true,
    visible: true
  },
  cacheLastUsageR: {
    translationKeyName: 'cache_last_usage_r',
    enabled: true,
    visible: true
  },
  cacheLastUsageLne: {
    translationKeyName: 'cache_last_usage_lne',
    enabled: true,
    visible: false
  },
  cacheLastUsageEliminated: {
    translationKeyName: 'cache_last_usage_eliminated',
    enabled: true,
    visible: true
  },
  exams: {
    translationKeyName: 'exams',
    enabled: true,
    visible: true
  },
  contentSizeDe: {
    translationKeyName: 'content_size_de',
    enabled: false,
    visible: true
  },
  contentSizeFr: {
    translationKeyName: 'content_size_fr',
    enabled: false,
    visible: true
  },
  contentSizeIt: {
    translationKeyName: 'content_size_it',
    enabled: false,
    visible: true
  },
  contentSizeEn: {
    translationKeyName: 'content_size_en',
    enabled: false,
    visible: true
  },
  revisionStatus: {
    translationKeyName: 'revision_status',
    enabled: true,
    visible: true
  },
  revisionYear: {
    translationKeyName: 'revision_year',
    enabled: true,
    visible: false
  }
};

export const defaultQuestionColumnConfig: QuestionColumnConfiguration = {
  sequentialNumber: {
    translationKeyName: 'sequential_number',
    enabled: true,
    visible: true,
    sortable: true
  },
  legacyId: {
    translationKeyName: 'legacy_id',
    enabled: true,
    visible: false,
    sortable: true
  },
  literaryReference: {
    translationKeyName: 'literary_reference',
    enabled: true,
    visible: false,
    sortable: true
  },
  sourceLanguage: {
    translationKeyName: 'source_language',
    enabled: true,
    visible: false,
    sortable: true
  },
  title: {
    translationKeyName: 'title',
    enabled: true,
    visible: false,
    sortable: true
  },
  supervisor: {
    translationKeyName: 'supervisor',
    enabled: true,
    visible: false,
    sortable: true
  },
  affiliation: {
    translationKeyName: 'affiliation',
    enabled: true,
    visible: false,
    sortable: true
  },
  contentValidationStateDe: {
    translationKeyName: 'content_validation_state_de',
    enabled: false,
    visible: false,
    sortable: true
  },
  contentValidationStateFr: {
    translationKeyName: 'content_validation_state_fr',
    enabled: false,
    visible: false,
    sortable: true
  },
  contentValidationStateIt: {
    translationKeyName: 'content_validation_state_it',
    enabled: false,
    visible: false,
    sortable: true
  },
  contentValidationStateEn: {
    translationKeyName: 'content_validation_state_en',
    enabled: false,
    visible: false,
    sortable: true
  },
  type: {
    translationKeyName: 'type',
    enabled: true,
    visible: true,
    sortable: true
  },
  questionTypes: {
    translationKeyName: 'question_types',
    enabled: true,
    visible: true,
    sortable: true
  },
  score: {
    translationKeyName: 'score',
    enabled: true,
    visible: false,
    sortable: true
  },
  hasImages: {
    translationKeyName: 'has_images',
    enabled: true,
    visible: true,
    sortable: true
  },
  hasVideos: {
    translationKeyName: 'has_videos',
    enabled: true,
    visible: true,
    sortable: true
  },
  category0: {
    translationKeyName: 'category_0',
    enabled: false,
    visible: true,
    sortable: true
  },
  category1: {
    translationKeyName: 'category_1',
    enabled: false,
    visible: true,
    sortable: true
  },
  category2: {
    translationKeyName: 'category_2',
    enabled: false,
    visible: true,
    sortable: true
  },
  category3: {
    translationKeyName: 'category_3',
    enabled: false,
    visible: true,
    sortable: true
  },
  category4: {
    translationKeyName: 'category_4',
    enabled: false,
    visible: true,
    sortable: true
  },
  category5: {
    translationKeyName: 'category_5',
    enabled: false,
    visible: true,
    sortable: true
  },
  category6: {
    translationKeyName: 'category_6',
    enabled: false,
    visible: true,
    sortable: true
  },
  category7: {
    translationKeyName: 'category_7',
    enabled: false,
    visible: true,
    sortable: true
  },
  createdAt: {
    translationKeyName: 'created_at',
    enabled: true,
    visible: true,
    sortable: true
  },
  updatedAt: {
    translationKeyName: 'updated_at',
    enabled: true,
    visible: false,
    sortable: true
  },
  editor: {
    translationKeyName: 'editor',
    enabled: true,
    visible: false,
    sortable: true
  },
  author: {
    translationKeyName: 'author',
    enabled: true,
    visible: false,
    sortable: true
  },
  numberOfUsages: {
    translationKeyName: 'number_of_usages',
    enabled: true,
    visible: true,
    sortable: true
  },
  lastUsage: {
    translationKeyName: 'last_usage',
    enabled: true,
    visible: false,
    sortable: true
  },
  latestStatistic: {
    translationKeyName: 'latest_statistic',
    enabled: true,
    visible: false,
    sortable: true
  },
  cacheLastUsageN: {
    translationKeyName: 'cache_last_usage_n',
    enabled: true,
    visible: false,
    sortable: true
  },
  cacheLastUsageP: {
    translationKeyName: 'cache_last_usage_p',
    enabled: true,
    visible: true,
    sortable: true
  },
  cacheLastUsageR: {
    translationKeyName: 'cache_last_usage_r',
    enabled: true,
    visible: true,
    sortable: true
  },
  cacheLastUsageLne: {
    translationKeyName: 'cache_last_usage_lne',
    enabled: true,
    visible: false,
    sortable: false
  },
  cacheLastUsageXi: {
    translationKeyName: 'cache_last_usage_xi',
    enabled: true,
    visible: false,
    sortable: false
  },
  cacheLastUsageEliminated: {
    translationKeyName: 'cache_last_usage_eliminated',
    enabled: true,
    visible: true,
    sortable: true
  },
  exams: {
    translationKeyName: 'exams',
    enabled: true,
    visible: true,
    sortable: false
  },
  labels: {
    translationKeyName: 'labels',
    enabled: true,
    visible: true,
    sortable: false
  },
  duplicates: {
    translationKeyName: 'duplicates',
    enabled: true,
    visible: true,
    sortable: true
  },
  revisionStatus: {
    translationKeyName: 'revision_status',
    enabled: true,
    visible: true,
    sortable: false
  },
  revisionYear: {
    translationKeyName: 'revision_year',
    enabled: true,
    visible: false,
    sortable: true
  }
};

export const defaultTaskColumnConfig: TaskColumnConfiguration = {
  id: {
    translationKeyName: 'id',
    enabled: true,
    visible: true,
    sortable: true
  },
  type: {
    translationKeyName: 'type',
    enabled: true,
    visible: true,
    sortable: true
  },
  receiver: {
    translationKeyName: 'receiver',
    enabled: true,
    visible: true,
    sortable: true
  },
  startsAt: {
    translationKeyName: 'starts_at',
    enabled: true,
    visible: true,
    sortable: true
  },
  endsAt: {
    translationKeyName: 'ends_at',
    enabled: true,
    visible: true,
    sortable: true
  },
  finishedAt: {
    translationKeyName: 'finished_at',
    enabled: true,
    visible: true,
    sortable: true
  },
  creator: {
    translationKeyName: 'creator',
    enabled: true,
    visible: true,
    sortable: true
  },
  status: {
    translationKeyName: 'status',
    enabled: true,
    visible: true,
    sortable: true
  }
};

export function updatePoolVisibilities(
  pool: PoolMetadataFragment,
  columnConfig: QuestionColumnConfiguration
): QuestionColumnConfiguration {
  return {
    ...columnConfig,
    contentValidationStateDe: {
      ...columnConfig.contentValidationStateDe,
      enabled: pool.languages.includes(Language.De)
    },
    contentValidationStateFr: {
      ...columnConfig.contentValidationStateFr,
      enabled: pool.languages.includes(Language.Fr)
    },
    contentValidationStateIt: {
      ...columnConfig.contentValidationStateIt,
      enabled: pool.languages.includes(Language.It)
    },
    contentValidationStateEn: {
      ...columnConfig.contentValidationStateEn,
      enabled: pool.languages.includes(Language.En)
    },
    category0: {
      ...columnConfig.category0,
      enabled: pool.dimensions.length > 0,
      translationParams: {
        name: pool.dimensions[0]?.name
      }
    },
    category1: {
      ...columnConfig.category1,
      enabled: pool.dimensions.length > 1,
      translationParams: {
        name: pool.dimensions[1]?.name
      }
    },
    category2: {
      ...columnConfig.category2,
      enabled: pool.dimensions.length > 2,
      translationParams: {
        name: pool.dimensions[2]?.name
      }
    },
    category3: {
      ...columnConfig.category3,
      enabled: pool.dimensions.length > 3,
      translationParams: {
        name: pool.dimensions[3]?.name
      }
    },
    category4: {
      ...columnConfig.category4,
      enabled: pool.dimensions.length > 4,
      translationParams: {
        name: pool.dimensions[4]?.name
      }
    },
    category5: {
      ...columnConfig.category5,
      enabled: pool.dimensions.length > 5,
      translationParams: {
        name: pool.dimensions[5]?.name
      }
    },
    category6: {
      ...columnConfig.category6,
      enabled: pool.dimensions.length > 6,
      translationParams: {
        name: pool.dimensions[6]?.name
      }
    },
    category7: {
      ...columnConfig.category7,
      enabled: pool.dimensions.length > 7,
      translationParams: {
        name: pool.dimensions[7]?.name
      }
    }
  };
}

export function updateExamPoolVisibilites(
  pool: PoolMetadataFragment
): Partial<ExamColumnConfiguration> {
  return {
    contentValidationStateDe: {
      translationKeyName: 'content_validation_state_de',
      enabled: pool.languages.includes(Language.De),
      visible: false
    },
    contentValidationStateFr: {
      translationKeyName: 'content_validation_state_fr',
      enabled: pool.languages.includes(Language.Fr),
      visible: false
    },
    contentValidationStateIt: {
      translationKeyName: 'content_validation_state_it',
      enabled: pool.languages.includes(Language.It),
      visible: false
    },
    contentValidationStateEn: {
      translationKeyName: 'content_validation_state_en',
      enabled: pool.languages.includes(Language.En),
      visible: false
    },
    contentSizeDe: {
      translationKeyName: 'content_size_de',
      enabled: pool.languages.includes(Language.De),
      visible: true
    },
    contentSizeFr: {
      translationKeyName: 'content_size_fr',
      enabled: pool.languages.includes(Language.Fr),
      visible: true
    },
    contentSizeIt: {
      translationKeyName: 'content_size_it',
      enabled: pool.languages.includes(Language.It),
      visible: true
    },
    contentSizeEn: {
      translationKeyName: 'content_size_en',
      enabled: pool.languages.includes(Language.En),
      visible: true
    }
  };
}

export function updateExamDimensions(
  exam: ExamMetadataFragment | undefined
): DimensionColumn | void {
  if (!isDefined(exam)) {
    return;
  }

  const columns = Array.from({ length: DIMENSIONS_LENGTH })
    .fill(0)
    .reduce((acc: DimensionColumn, _value, index) => {
      (acc as { [key: string]: ColumnConfig })['category' + index] = {
        translationKeyName: `category_${index}`,
        visible: true,
        enabled: index < exam.dimensions.length,
        translationParams: {
          name:
            exam.dimensions[index]?.name?.length > 0
              ? exam.dimensions[index].name
              : undefined
        }
      };

      return acc;
    }, {} as DimensionEnum);

  return columns;
}
