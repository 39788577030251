angular.module('common').factory('Task', ['resourceFactory', function (resourceFactory) {
  function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  var Task = resourceFactory('/api/tasks', {
    attributeTransformations: {
      ends_at: {
        in: function (value) {
          return new Date(value);
        },
        out: function (value) {
          if (value === undefined || value === null) {
            return null;
          }
          else {
            return value.getFullYear() +
              '-' + pad(value.getMonth() + 1) +
              '-' + pad(value.getDate());
          }
        }
      }
    }
  });

  Task.prototype.getUrlToExternalView = function () {
    return this.action('external_url', 'GET');
  };

  Task.validate = function (task) {
    return this.action('validate', 'POST', task);
  };

  Task.recentEmailBodies = function (type) {
    return this.action('recent_email_bodies', 'GET', {type: type});
  };

  return Task;
}]);
