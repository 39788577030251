import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteAffiliationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteAffiliationMutation = { __typename?: 'Mutation', deleteAffiliation?: { __typename?: 'DeleteAffiliationPayload', successful: boolean } | null };

export const DeleteAffiliationDocument = gql`
    mutation DeleteAffiliation($id: ID!) {
  deleteAffiliation(id: $id) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAffiliationGQL extends Apollo.Mutation<DeleteAffiliationMutation, DeleteAffiliationMutationVariables> {
    document = DeleteAffiliationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }