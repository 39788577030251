angular.module('common').factory('Reducers::Common', [
  'Reducers::Panel',
  'Reducers::AnnouncementList',
  'Reducers::Version',
  'Reducers::QuestionGroupDetails',
  'Reducers::PoolSelection',
  'Reducers::ConnectionStatus',
  'Reducers::User',
  function(
    PanelReducer,
    AnnouncementListReducer,
    VersionReducer,
    DetailsReducer,
    PoolReducer,
    ConnectionStatusReducer,
    UserReducer
  ) {
    return function(state, action) {
      state = state || {};
      state = VersionReducer(state, action);
      state = PoolReducer(state, action);
      state = UserReducer(state, action);
      return Object.assign({}, state, {
        panels: PanelReducer(state.panels, action),
        announcementList: AnnouncementListReducer(state.announcements, action),
        questionGroupDetails: DetailsReducer(state.questionGroupDetails, action),
        connectionStatus: ConnectionStatusReducer(state.connectionStatus, action)
      });
    };
  }
]);
