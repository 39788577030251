angular.module('settings').component('settingsNavigation', {
  template: require('./settings_navigation.html'),
  controller: function SettingsNavigationController() {
    this.entries = [
      {
        name: I18n.t('settings.navigation.pools'),
        url: 'pools',
        sref: 'settings.pools',
      },
      {
        name: I18n.t('settings.navigation.users'),
        url: 'users',
        sref: 'settings.users'
      },
      {
        name: I18n.t('settings.navigation.oauth_applications'),
        user: 'applications',
        sref: 'settings.applications'
      }
    ]
  }
});
