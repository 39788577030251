import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetService {
  private readonly endpoint = '/api/v1/assets';

  constructor(private http: HttpClient) {}

  public getMetadata<T>(assetId: string): Promise<T> {
    return firstValueFrom(
      this.http.get<T>(`${this.endpoint}/${assetId}/metadata`)
    );
  }

  public getMultiMetadata<T>(assetIds: string[]): Promise<T[]> {
    return firstValueFrom(
      this.http
        // eslint-disable-next-line camelcase
        .post<T[]>(`/api/v1/assets/metadata`, { asset_ids: assetIds })
    );
  }

  public getAsset(assetId: string): Promise<ArrayBuffer> {
    return firstValueFrom(
      this.http.get(`${this.endpoint}/${assetId}`, {
        responseType: 'arraybuffer'
      })
    );
  }

  public getUrl(assetId: string): string {
    return `${this.endpoint}/${assetId}`;
  }
}

// based on the Measured Mediaserver documentation
// https://github.com/iml-it/measured-mediaserver/wiki/API-Documentation#types
interface BaseMetadata {
  mime: string;
  size: number;
}

export type VideoMetadata = BaseMetadata & {
  imageHeight: number;
  imageWidth: number;
  duration: number;
};

export type ImageMetadata = BaseMetadata & {
  imageHeight: number;
  imageWidth: number;
};

export type Metadata = VideoMetadata | ImageMetadata;
