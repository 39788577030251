<div class="actions">
  <div class="action">
    <co-checkbox
      [checked]="showReadMessages"
      (changed)="onToggleShowReadMessages()"
      >{{ 'common.question_group_details.history.display_read' | translate }}
    </co-checkbox>
  </div>
  <div class="action">
    <co-checkbox
      [checked]="showSystemMessages"
      (changed)="onToggleShowSystemMessages()"
      >{{ 'common.question_group_details.history.display_system' | translate }}
    </co-checkbox>
  </div>
</div>
<ol class="groups" *ngIf="messageGroups?.length">
  <li
    *ngFor="let group of messageGroups; index as i; trackBy: trackByGroupName"
  >
    <div class="group-header">
      <span class="pill name">{{ group.name.year }}</span>
      <button
        class="btn btn-primary xs group-actions"
        (click)="onToggleGroupVisibility(i)"
      >
        <i
          class="fa"
          [ngClass]="{
            'fa-angle-up': group.name.visible,
            'fa-angle-down': !group.name.visible
          }"
        ></i>
      </button>
    </div>
    <ol class="items" *ngIf="group.name.visible">
      <li
        class="item"
        [ngClass]="{
          'item-comment': message.isComment,
          'item-system-message': !message.isComment
        }"
        *ngFor="let message of group.items; trackBy: trackByMessageId"
      >
        <i
          class="fa item-icon"
          [ngClass]="{
            'fa-user': message.isComment,
            'fa-desktop': !message.isComment
          }"
        ></i>
        <div class="item-author">
          {{ message.author }}
        </div>
        <div class="item-date">
          {{ message.date | date : 'dd.MM.yyyy HH:mm zzzz' }}
        </div>
        <div class="item-text">{{ message.text }}</div>
        <div class="item-actions" *ngIf="message.isComment">
          <co-checkbox
            class="small"
            [checked]="message.isRead"
            (changed)="onToggleMessageRead(message)"
          ></co-checkbox>
          <button class="btn btn-primary xs" (click)="onDeleteComment(message)">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </li>
    </ol>
  </li>
</ol>
