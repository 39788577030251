import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Scalars } from '../../../../generated/base-types';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { assertIsDefined } from '../../utils/type-guards/is-defined';
import { AssignExamsGQL } from './assign-exam.generated';
import {
  UnlockedExamFragment,
  UnlockedExamsGQL
} from './unlocked-exams.generated';

export enum AssignmentActions {
  IGNORE = 'ignore',
  ADD = 'add',
  REMOVE = 'remove'
}

@Component({
  selector: 'co-assign-exam-modal',
  templateUrl: './assign-exam-modal.component.html',
  styleUrls: ['./assign-exam-modal.component.scss']
})
export class AssignExamModalComponent implements OnInit {
  @Input()
  public modalInstance: NgbModalRef;

  @Input()
  public currentPoolId: Scalars['ID'];

  @Input()
  public questionGroups: QuestionGroupListElementFragment[];

  public actions: { [index: string]: AssignmentActions } = {};
  public assignmentActions = AssignmentActions;
  public isSaving = false;
  public exams: UnlockedExamFragment[];

  constructor(
    private readonly unlockedExamsGQL: UnlockedExamsGQL,
    private readonly assignExamsGQL: AssignExamsGQL
  ) {}

  public async ngOnInit(): Promise<void> {
    this.exams = await this.unlockedExamsGQL
      .fetch({ poolId: this.currentPoolId })
      .toPromise()
      .then(result => {
        assertIsDefined(result?.data.pool.exams);

        return result.data.pool.exams;
      });

    this.actions = this.exams.reduce(
      (
        action: { [index: string]: AssignmentActions },
        exam: UnlockedExamFragment
      ) => {
        const questionGroupsInExam = this.questionGroups.filter(
          questionGroup => {
            return questionGroup.futureExams
              .map(exam => exam.id)
              .includes(exam.id);
          }
        );
        if (questionGroupsInExam.length === this.questionGroups.length) {
          action[exam.id] = AssignmentActions.ADD;
        } else if (questionGroupsInExam.length > 0) {
          action[exam.id] = AssignmentActions.IGNORE;
        } else {
          action[exam.id] = AssignmentActions.REMOVE;
        }

        return action;
      },
      {}
    );
  }

  public async save(): Promise<void> {
    this.isSaving = true;
    const result = await this.assignExamsGQL
      .mutate({
        poolId: this.currentPoolId,
        questionGroupIds: this.questionGroups.map(qg => qg.id),
        examIdsToAdd: this.filterExamsIdsByAction(AssignmentActions.ADD),
        examIdsToRemove: this.filterExamsIdsByAction(AssignmentActions.REMOVE)
      })
      .toPromise();

    if (result?.data?.assignExams?.successful !== true) {
      throw `Unable to assign exams for ${this.questionGroups.map(
        qg => qg.id
      )}`;
    }

    this.modalInstance.close();
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  private filterExamsIdsByAction(action: AssignmentActions): string[] {
    return this.exams
      .filter(exam => this.actions[exam.id] === action)
      .map(exam => exam.id);
  }
}
