angular.module('common').factory('Actions::Pool', function() {
  var actions = {
    SET_CURRENT_POOL: 'POOL_SELECTION_SET_CURRENT_POOL'
  };

  function setCurrentPool(pool) {
    return {
      type: actions.SET_CURRENT_POOL,
      pool: pool
    };
  }

  return Object.assign(
    {
      setCurrentPool: setCurrentPool
    },
    actions
  );
});
