import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { takeGraphQLResult } from 'src/app/common/operators/take-graphql-response';
import { parseError } from 'src/app/common/utils/error-parser';
import { RemoteData, RequestState } from 'src/app/common/utils/remote-data';
import { requestStateLoading } from 'src/app/common/utils/remote-data-utils';
import { ToastsService } from '../../common/services/toasts.service';
import { isDefined } from '../../common/utils/type-guards/is-defined';
import { DeletePermissionGQL } from '../services/delete-permission.generated';
import { PoolPermissionsFragment } from '../services/load-pool-permissions.fragments.generated';
import { LoadUserPermissionsGQL } from '../services/load-user-permissions.generated';
import { UpdatePermissionGQL } from '../services/update-permission.generated';
import {
  DeleteUserPermission,
  DeleteUserPermissionFailure,
  DeleteUserPermissionSuccess,
  LoadUserPermissions,
  LoadUserPermissionsFailure,
  LoadUserPermissionsSuccess,
  UpdateUserPermission,
  UpdateUserPermissionFailure,
  UpdateUserPermissionSuccess
} from './user-permissions.actions';

export type UserPermissionsStateModel = {
  permissions: RemoteData<PoolPermissionsFragment[]>;
  mutation?: RemoteData<PoolPermissionsFragment>;
};

@State<UserPermissionsStateModel>({
  name: 'userPermissions',
  defaults: {
    permissions: { requestState: 'initial' }
  }
})
@Injectable({ providedIn: 'root' })
export class UserPermissionsState {
  constructor(
    private readonly loadUserPermissionsService: LoadUserPermissionsGQL,
    private readonly updatePoolPermissionService: UpdatePermissionGQL,
    private readonly deletePoolPermissionService: DeletePermissionGQL,
    private readonly toasts: ToastsService,
    private readonly translate: TranslateService
  ) {}

  @Selector()
  public static userPermissions(
    state: UserPermissionsStateModel
  ): RemoteData<PoolPermissionsFragment[]> {
    return state.permissions;
  }

  @Selector()
  public static permissionsLoading(state: UserPermissionsStateModel): boolean {
    return requestStateLoading(state.permissions);
  }

  @Selector()
  public static mutationRequestState(
    state: UserPermissionsStateModel
  ): RequestState | undefined {
    return state.mutation?.requestState;
  }

  @Action(LoadUserPermissions)
  public loadUserPermissions(
    ctx: StateContext<UserPermissionsStateModel>,
    { userId }: LoadUserPermissions
  ): Observable<void> {
    ctx.patchState({
      permissions: { requestState: 'loading' }
    });

    return this.loadUserPermissionsService.fetch({ userId: userId }).pipe(
      takeGraphQLResult(),
      switchMap(result =>
        ctx.dispatch(new LoadUserPermissionsSuccess(result.user.permissions))
      ),
      catchError((err: unknown) => {
        return ctx.dispatch(new LoadUserPermissionsFailure(parseError(err)));
      })
    );
  }

  @Action(LoadUserPermissionsSuccess)
  public loadUserPermissionsSuccess(
    ctx: StateContext<UserPermissionsStateModel>,
    { userPermissions }: LoadUserPermissionsSuccess
  ): void {
    ctx.patchState({
      permissions: {
        requestState: 'success',
        data: userPermissions
      }
    });
  }

  @Action(LoadUserPermissionsFailure)
  public loadUserPermissionsFailure(
    ctx: StateContext<UserPermissionsStateModel>,
    { error }: LoadUserPermissionsFailure
  ): void {
    ctx.patchState({
      permissions: {
        requestState: 'failure',
        error
      }
    });
  }

  @Action(UpdateUserPermission)
  public updateUserPermission(
    ctx: StateContext<UserPermissionsStateModel>,
    { id, attributes }: UpdateUserPermission
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.updatePoolPermissionService.mutate({ id, attributes }).pipe(
      takeGraphQLResult(),
      map(result => result?.updatePermission?.permission),
      filter(isDefined),
      switchMap(permission =>
        ctx.dispatch(new UpdateUserPermissionSuccess(permission))
      ),
      catchError((err: unknown) =>
        ctx.dispatch(new UpdateUserPermissionFailure(parseError(err)))
      )
    );
  }

  @Action(UpdateUserPermissionSuccess)
  public updateUserPermissionSuccess(
    ctx: StateContext<UserPermissionsStateModel>,
    { permission }: UpdateUserPermissionSuccess
  ): void {
    ctx.patchState({
      permissions: {
        ...ctx.getState().permissions,
        data: ctx
          .getState()
          .permissions.data?.map(existingPermission =>
            existingPermission.id === permission.id
              ? permission
              : existingPermission
          )
      },
      mutation: { requestState: 'success', data: permission }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.update', {
        resource: this.translate.instant('common.models.permission')
      })
    );
  }

  @Action(UpdateUserPermissionFailure)
  public updateUserPermissionFailure(
    ctx: StateContext<UserPermissionsStateModel>,
    { error }: UpdateUserPermissionFailure
  ): void {
    ctx.patchState({
      mutation: { requestState: 'failure', error }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }

  @Action(DeleteUserPermission)
  public deleteUserPermission(
    ctx: StateContext<UserPermissionsStateModel>,
    { id }: DeleteUserPermission
  ): Observable<void> {
    ctx.patchState({
      mutation: { requestState: 'loading' }
    });

    return this.deletePoolPermissionService.mutate({ id }).pipe(
      takeGraphQLResult(),
      filter(isDefined),
      switchMap(() => ctx.dispatch(new DeleteUserPermissionSuccess(id))),
      catchError((err: unknown) =>
        ctx.dispatch(new DeleteUserPermissionFailure(parseError(err)))
      )
    );
  }

  @Action(DeleteUserPermissionSuccess)
  public deleteUserPermissionSuccess(
    ctx: StateContext<UserPermissionsStateModel>,
    { id }: DeleteUserPermissionSuccess
  ): void {
    ctx.patchState({
      permissions: {
        ...ctx.getState().permissions,
        data: ctx
          .getState()
          .permissions.data?.filter(permission => permission.id !== id)
      },
      mutation: { data: undefined, requestState: 'success' }
    });

    this.toasts.addSuccess(
      this.translate.instant('toast_success_messages.destroy', {
        resource: this.translate.instant('activerecord.models.permission')
      })
    );
  }

  @Action(DeleteUserPermissionFailure)
  public deleteUserPermissionFailure(
    ctx: StateContext<UserPermissionsStateModel>,
    { error }: DeleteUserPermissionFailure
  ): void {
    ctx.patchState({
      mutation: { error, requestState: 'failure' }
    });

    this.toasts.addWarning(this.translate.instant('error_modal.title'));
  }
}
