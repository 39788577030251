<ng-container *ngIf="filters$ | async as filters">
  <section class="p-3">
    <div class="input-group mb-2 mr-sm-2">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'question_groups.filter.search' | translate }}"
        [(ngModel)]="filters.query"
        (keyup.esc)="filters.query = ''"
        (keydown.enter)="onSubmit(filters)"
      />

      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-primary input-group-text"
          *ngIf="filters.query"
          (click)="filters.query = ''"
        >
          <i class="fa fa-times"></i>
        </button>
        <button
          type="button"
          class="btn btn-primary input-group-text"
          (click)="onSubmit(filters)"
        >
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </section>

  <section class="p-3">
    <qm-question-group-filter-advanced
      *ngIf="filters.advancedFilter as advancedFilter"
      [filter]="advancedFilter"
      [(isOpen)]="openCategories['advancedFilter']"
      (changed)="onChangeAdvanced($event, filters)"
    ></qm-question-group-filter-advanced>
    <ng-container
      *ngFor="
        let filter of filters.simpleFilters;
        index as index;
        first as first
      "
    >
      <qm-filter-select
        *ngIf="first"
        [name]="getName(filter.category)"
        [(filter)]="filters.simpleFilters[index]"
        [(isOpen)]="openCategories[filter.category]"
      ></qm-filter-select>

      <qm-filter-checkbox
        *ngIf="!first"
        [name]="getName(filter.category)"
        [(filter)]="filters.simpleFilters[index]"
        [(isOpen)]="openCategories[filter.category]"
      ></qm-filter-checkbox>
    </ng-container>
  </section>

  <section class="p-3">
    <button
      class="btn btn-primary btn-block btn-lg"
      [disabled]="isDisabled() || !isDirty(filters)"
      (click)="onSubmit(filters)"
    >
      {{ 'question_groups.filter.submit' | translate }}
    </button>
    <button
      class="btn btn-secondary btn-block py-3 text-primary"
      (click)="onReset()"
    >
      {{ 'question_groups.filter.reset' | translate }}
    </button>
  </section>
</ng-container>
