<ng-container *ngIf="users$ | async as users">
  <p-dropdown
    [options]="users"
    [optionLabel]="'label'"
    [optionValue]="'id'"
    [filter]="true"
    [filterBy]="'fistName,lastName,label,email'"
    [placeholder]="'common.messages.please_select' | translate"
    [virtualScroll]="true"
    [virtualScrollItemSize]="16"
    [(ngModel)]="selectedUserId"
    (ngModelChange)="onUserChange($event)"
  >
    <ng-template pTemplate="selectedItem">
      <div
        class="user-item"
        *ngIf="findUser(users, selectedUserId) as selectedUser"
      >
        <div>{{ selectedUser.label }}</div>
        <div *ngIf="showEmail" class="email" [title]="selectedUser.email">
          {{ selectedUser.email }}
        </div>
      </div>
    </ng-template>
    <ng-template let-user pTemplate="item">
      <div class="user-item">
        <div class="name" [title]="user.fullName">
          {{ user.label }}
        </div>
        <div *ngIf="showEmail" class="email" [title]="user.email">
          {{ user.email }}
        </div>
      </div>
    </ng-template>
  </p-dropdown>
</ng-container>
