import { ManagerError } from 'src/app/common/utils/error-parser';
import {
  ExcelStatisticsInput,
  ExcelStatisticsValidation
} from 'src/generated/base-types';
import { ExamDetailsFragment } from '../services/load-exam.generated';

export class ImportExcelStatistics {
  public static readonly type = '[Excel Statistics] Import';
  constructor(public readonly attributes: ExcelStatisticsInput[]) {}
}

export class ImportExcelStatisticsSuccess {
  public static readonly type = '[Excel Statistics] Import Success';
  constructor(public readonly exam: ExamDetailsFragment) {}
}

export class ImportExcelStatisticsFailure {
  public static readonly type = '[Excel Statistics] Import Failure';
  constructor(
    public readonly error: ManagerError<ExcelStatisticsValidation>,
    public readonly attributes: ExcelStatisticsInput[]
  ) {}
}

export class ResetMutation {
  public static readonly type = '[Excel Statistics] Reset Mutation';
}
