/* eslint-disable unicorn/no-null */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuestionGroupType } from '../../../../generated/base-types';
import { CurrentPoolForFilterQuery } from './current-pool-for-filter.generated';

export interface FilterLabelType {
  label: string;
  value: string | number | null;
}

export interface FilterHtmlLabelType extends FilterLabelType {
  color: string | null;
}

export interface FilterAttributeType {
  type: string;
  name: string;
  id: string | number;
  operators: FilterLabelType[];
  options: null | FilterLabelType[];
}

export interface FilterDimensionType {
  position: number;
  items: FilterLabelType[];
}

@Injectable({
  providedIn: 'root'
})
export class FilterHelperService {
  private translate: TranslateService;

  constructor(translate: TranslateService) {
    this.translate = translate;
  }

  private static labelValue(
    label: string,
    value: string | number | null
  ): FilterLabelType {
    return { label: label, value: value };
  }

  private static labelData(
    label: string,
    value: string | number | null,
    color: string | null
  ): FilterHtmlLabelType {
    return { label: label, value: value, color: color };
  }

  public numberAttribute(name: string, id: string): FilterAttributeType {
    return {
      type: 'number',
      name: name,
      id: id,
      operators: [
        FilterHelperService.labelValue('=', '='),
        FilterHelperService.labelValue('≠', '!='),
        FilterHelperService.labelValue('<', '<'),
        FilterHelperService.labelValue('≤', '<='),
        FilterHelperService.labelValue('≥', '>='),
        FilterHelperService.labelValue('>', '>')
      ],
      options: []
    };
  }

  public stringAttribute(name: string, id: string): FilterAttributeType {
    return {
      type: 'string',
      name: name,
      id: id,
      operators: [
        FilterHelperService.labelValue(
          this.translate.instant('common.filter.operators.equal'),
          '='
        ),
        FilterHelperService.labelValue(
          this.translate.instant('common.filter.operators.not_equal'),
          '!='
        ),
        FilterHelperService.labelValue(
          this.translate.instant('common.filter.operators.includes'),
          'includes'
        )
      ],
      options: []
    };
  }

  public selectAttribute(
    name: string,
    id: string,
    options: FilterLabelType[]
  ): FilterAttributeType {
    return {
      type: 'select',
      name: name,
      id: id,
      operators: [
        FilterHelperService.labelValue(
          this.translate.instant('common.filter.operators.equal'),
          '='
        ),
        FilterHelperService.labelValue(
          this.translate.instant('common.filter.operators.not_equal'),
          '!='
        )
      ],
      options: options.slice()
    };
  }

  public booleanAttribute(name: string, id: string): FilterAttributeType {
    return {
      type: 'boolean',
      name: name,
      id: id,
      operators: [FilterHelperService.labelValue('=', '=')],
      options: []
    };
  }

  public dateAttribute(name: string, id: string): FilterAttributeType {
    return {
      type: 'date',
      name: name,
      id: id,
      operators: [
        FilterHelperService.labelValue('=', '='),
        FilterHelperService.labelValue('≠', '!='),
        FilterHelperService.labelValue('<', '<'),
        FilterHelperService.labelValue('≤', '<='),
        FilterHelperService.labelValue('≥', '>='),
        FilterHelperService.labelValue('>', '>')
      ],
      options: []
    };
  }

  public examOptions(
    exams: CurrentPoolForFilterQuery['pool']['exams']
  ): FilterLabelType[] {
    const options = exams.map(exam => {
      return FilterHelperService.labelValue(
        `${exam.name} (${exam.date.split('-').reverse().join('.')})`,
        exam.id
      );
    });
    options.unshift(
      FilterHelperService.labelValue(
        this.translate.instant('question_groups.filter.no_exam'),
        null
      )
    );

    return options;
  }

  public affiliationOptions(
    affiliations: CurrentPoolForFilterQuery['pool']['affiliations']
  ): FilterLabelType[] {
    const options = affiliations.map(affiliation => {
      return FilterHelperService.labelValue(affiliation.name, affiliation.id);
    });
    options.unshift(
      FilterHelperService.labelValue(
        this.translate.instant('question_groups.filter.no_affiliation'),
        null
      )
    );

    return options;
  }

  public supervisorOptions(supervisors: (string | null)[]): FilterLabelType[] {
    return supervisors.map(supervisor =>
      FilterHelperService.labelValue(supervisor ?? '', supervisor)
    );
  }

  public contentValidationStateOptions(
    languages: string[],
    includeHtmlInLabels = true
  ): FilterLabelType[] {
    const options = languages.map(language => {
      const languageName = this.translate.instant(
        `scrudu.languages.${language.toLowerCase()}`
      );

      return includeHtmlInLabels
        ? [
            FilterHelperService.labelValue(
              `${languageName} <i class='fa fa-check skin-message--success'></i> (${this.translate.instant(
                'choices.question_groups.content_validation_state.complete'
              )})`,
              `${language.toLowerCase()}.complete`
            ),
            FilterHelperService.labelValue(
              `${languageName} <i class='fa fa-exclamation skin-message--warning'></i> (${this.translate.instant(
                'choices.question_groups.content_validation_state.incomplete'
              )})`,
              `${language.toLowerCase()}.incomplete`
            ),
            FilterHelperService.labelValue(
              `${languageName} <i class='fa fa-ban skin-message--alert'></i> (${this.translate.instant(
                'choices.question_groups.content_validation_state.missing'
              )})`,
              `${language.toLowerCase()}.missing`
            )
          ]
        : [
            FilterHelperService.labelValue(
              `${languageName} (${this.translate.instant(
                'choices.question_groups.content_validation_state.complete'
              )})`,
              `${language.toLowerCase()}.complete`
            ),
            FilterHelperService.labelValue(
              `${languageName} (${this.translate.instant(
                'choices.question_groups.content_validation_state.incomplete'
              )})`,
              `${language.toLowerCase()}.incomplete`
            ),
            FilterHelperService.labelValue(
              `${languageName} (${this.translate.instant(
                'choices.question_groups.content_validation_state.missing'
              )})`,
              `${language.toLowerCase()}.missing`
            )
          ];
    });

    return options.flat();
  }

  public dimensionsWithAllCategoriesOptions(
    dimensions: CurrentPoolForFilterQuery['pool']['dimensions']
  ): FilterDimensionType[] {
    return dimensions.map(dimension => {
      const { position, categories } = dimension;
      const categoryLabels = categories.map(category => {
        return FilterHelperService.labelValue(category.name, category.id);
      });
      categoryLabels.unshift(
        FilterHelperService.labelValue(
          this.translate.instant('question_groups.filter.all_categories'),
          null
        )
      );

      return { position, items: categoryLabels };
    });
  }

  public dimensionsWithNoCategoriesOptions(
    dimensions: CurrentPoolForFilterQuery['pool']['dimensions']
  ): FilterDimensionType[] {
    return dimensions.map(dimension => {
      const { position, categories } = dimension;
      const categoryLabels = categories.map(category => {
        return FilterHelperService.labelValue(category.name, category.id);
      });
      categoryLabels.unshift(
        FilterHelperService.labelValue(
          this.translate.instant(
            'question_groups.advanced_filter.dimension.no_selection'
          ),
          null
        )
      );

      return { position, items: categoryLabels };
    });
  }

  public labelOptions(
    labels: CurrentPoolForFilterQuery['pool']['labels'],
    includeHtmlInLabels = true
  ): (FilterLabelType | FilterHtmlLabelType)[] {
    if (includeHtmlInLabels) {
      const options = labels.map(label => {
        return FilterHelperService.labelValue(
          `<i class='fa fa-circle' style='color: ${label.color}'></i>${label.name}`,
          label.id
        );
      });
      options.unshift(
        FilterHelperService.labelValue(
          this.translate.instant('question_groups.filter.no_label'),
          null
        )
      );

      return options;
    } else {
      const options = labels.map(label => {
        return FilterHelperService.labelData(label.name, label.id, label.color);
      });
      options.unshift(
        FilterHelperService.labelData(
          this.translate.instant('question_groups.filter.no_label'),
          null,
          null
        )
      );

      return options;
    }
  }

  public questionGroupTypeOptions(): FilterLabelType[] {
    return [
      {
        label: this.translate.instant(
          'activerecord.choices.question_group.type.SINGLE'
        ),
        value: QuestionGroupType.Single
      },
      {
        label: this.translate.instant(
          'activerecord.choices.question_group.type.SERIES'
        ),
        value: QuestionGroupType.Series
      },
      {
        label: this.translate.instant(
          'activerecord.choices.question_group.type.SEQUENCE'
        ),
        value: QuestionGroupType.Sequence
      }
    ];
  }

  public questionTypeOptions(): FilterLabelType[] {
    const types = [
      { class: 'Questions::A', key: 'A' },
      { class: 'Questions::AMinus', key: 'A-' },
      { class: 'Questions::Kprime', key: 'Kprime' },
      { class: 'Questions::E', key: 'E' },
      { class: 'Questions::Essay', key: 'Essay' },
      { class: 'Questions::LongMenu', key: 'LongMenu' }
    ];

    return types.map(type => {
      return FilterHelperService.labelValue(
        this.translate.instant(`questions.type.${type.class}`),
        type.key
      );
    });
  }

  public revisionStatusOptions(
    revisionStati: CurrentPoolForFilterQuery['pool']['revisionStatus']
  ): FilterLabelType[] {
    const options = revisionStati.map(revisionStatus => {
      return FilterHelperService.labelValue(
        revisionStatus.shortName,
        revisionStatus.id
      );
    });
    options.unshift(
      FilterHelperService.labelValue(
        this.translate.instant('question_groups.filter.no_revision_status'),
        null
      )
    );

    return options;
  }

  public languageOptions(languages: string[]): FilterLabelType[] {
    return languages.map(language => {
      return FilterHelperService.labelValue(language, language);
    });
  }
}
