angular.module('examManagement').factory('Candidate', ['resourceFactory', function (resourceFactory) {
  var ExamCandidate = resourceFactory('/api/exams/:exam_id/candidates', {});

  ExamCandidate.fetchAccessPapers = function(examId, params) {
    var opts = angular.copy(params);
    opts.exam_id = examId;
    return this.action('access_papers', 'POST', opts);
  };

  ExamCandidate.reloadAll = function(candidateIds) {
    return Candidate.reloadAll(candidateIds);
  };

  ExamCandidate.destroyAll = function(examId, candidateIds) {
    return Candidate.destroyAll(examId, candidateIds);
  };

  var Candidate = resourceFactory('/api/candidates', {});

  Candidate.destroyAll = function(examId, candidateIds) {
    return this.action('destroy_all', 'PUT', {exam_id: examId, ids: candidateIds});
  };

  Candidate.reloadAll = function(candidateIds) {
    return this.action('reload_all', 'GET', {'ids[]': candidateIds});
  };

  return ExamCandidate;
}]);
