angular.module('questionManagement').config([
  '$stateProvider',
  '$locationProvider',
  function ($stateProvider, $locationProvider) {
    $stateProvider.state('questionManagement', {
      url: '/',
      views: {
        'main@': {
          component: 'appQuestionManagement'
        }
      },
      authorize: [
        'Role',
        'user',
        function (Role, user) {
          return true;
        }
      ]
    });
    $locationProvider.html5Mode(true);
  }
]);
