<ng-container [formGroup]="form">
  <!-- DE -->
  <div *ngIf="languageVisibility.de" class="free-text-container__languages">
    <label for="question_text_de">{{
      'scrudu.languages.de' | translate
    }}</label>
    <qf-rich-editor
      id="question_text_de"
      #editor="richEditor"
      formControlName="contentDe"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.content?.de"
      [inputField]="editor"
      [inputValue]="form.get('contentDe')?.value"
    ></qf-input-feedback>
  </div>

  <!-- FR -->
  <div *ngIf="languageVisibility.fr" class="free-text-container__languages">
    <label for="question_text_fr">{{
      'scrudu.languages.fr' | translate
    }}</label>
    <qf-rich-editor
      id="question_text_fr"
      #editor="richEditor"
      formControlName="contentFr"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.content?.fr"
      [inputField]="editor"
      [inputValue]="form.get('contentFr')?.value"
    ></qf-input-feedback>
  </div>

  <!-- IT -->
  <div *ngIf="languageVisibility.it" class="free-text-container__languages">
    <label for="question_text_it">{{
      'scrudu.languages.it' | translate
    }}</label>
    <qf-rich-editor
      id="question_text_it"
      #editor="richEditor"
      formControlName="contentIt"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.content?.it"
      [inputField]="editor"
      [inputValue]="form.get('contentIt')?.value"
    ></qf-input-feedback>
  </div>

  <!-- EN -->
  <div *ngIf="languageVisibility.en" class="free-text-container__languages">
    <label for="question_text_en">{{
      'scrudu.languages.en' | translate
    }}</label>
    <qf-rich-editor
      id="question_text_en"
      #editor="richEditor"
      formControlName="contentEn"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.content?.en"
      [inputField]="editor"
      [inputValue]="form.get('contentEn')?.value"
    ></qf-input-feedback>
  </div>

  <div
    class="free-text-container__question-video"
    *ngIf="languageVisibility.de"
  >
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'de' | async }}"
      [initiallyOpen]="!!form.get('videoDe')?.value"
    >
      <qf-video-upload formControlName="videoDe"></qf-video-upload>
    </co-single-panel-accordion>
  </div>
  <div
    class="free-text-container__question-video"
    *ngIf="languageVisibility.fr"
  >
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'fr' | async }}"
      [initiallyOpen]="!!form.get('videoFr')?.value"
    >
      <qf-video-upload formControlName="videoFr"></qf-video-upload>
    </co-single-panel-accordion>
  </div>
  <div
    class="free-text-container__question-video"
    *ngIf="languageVisibility.en"
  >
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'en' | async }}"
      [initiallyOpen]="!!form.get('videoEn')?.value"
    >
      <qf-video-upload formControlName="videoEn"></qf-video-upload>
    </co-single-panel-accordion>
  </div>
  <div
    class="free-text-container__question-video"
    *ngIf="languageVisibility.it"
  >
    <co-single-panel-accordion
      title="{{ 'video_upload.title' | translateToLanguage : 'it' | async }}"
      [initiallyOpen]="!!form.get('videoIt')?.value"
    >
      <qf-video-upload formControlName="videoIt"></qf-video-upload>
    </co-single-panel-accordion>
  </div>

  <!-- DE -->
  <div
    *ngIf="languageVisibility.de"
    class="free-text-container__solution"
    formGroupName="solution"
  >
    <label for="question_solution_de"
      >{{ 'questions.edit.solution_text' | translate }}
      {{ 'scrudu.languages.de' | translate }}</label
    >
    <qf-rich-editor
      id="question_solution_de"
      #editor="richEditor"
      formControlName="contentDe"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.freeTextSolution?.de"
      [inputField]="editor"
      [inputValue]="form.get('solution')?.get('contentDe')?.value"
    ></qf-input-feedback>
  </div>

  <!-- FR -->
  <div
    *ngIf="languageVisibility.fr"
    class="free-text-container__solution"
    formGroupName="solution"
  >
    <label for="question_solution_fr"
      >{{ 'questions.edit.solution_text' | translate }}
      {{ 'scrudu.languages.fr' | translate }}</label
    >
    <qf-rich-editor
      id="question_solution_fr"
      #editor="richEditor"
      formControlName="contentFr"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.freeTextSolution?.fr"
      [inputField]="editor"
      [inputValue]="form.get('solution')?.get('contentFr')?.value"
    ></qf-input-feedback>
  </div>

  <!-- IT -->
  <div
    *ngIf="languageVisibility.it"
    class="free-text-container__solution"
    formGroupName="solution"
  >
    <label for="question_solution_it"
      >{{ 'questions.edit.solution_text' | translate }}
      {{ 'scrudu.languages.it' | translate }}</label
    >
    <qf-rich-editor
      id="question_solution_it"
      #editor="richEditor"
      formControlName="contentIt"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.freeTextSolution?.it"
      [inputField]="editor"
      [inputValue]="form.get('solution')?.get('contentIt')?.value"
    ></qf-input-feedback>
  </div>

  <!-- EN -->
  <div
    *ngIf="languageVisibility.en"
    class="free-text-container__solution"
    formGroupName="solution"
  >
    <label for="question_solution_en"
      >{{ 'questions.edit.solution_text' | translate }}
      {{ 'scrudu.languages.en' | translate }}</label
    >
    <qf-rich-editor
      id="question_solution_en"
      #editor="richEditor"
      formControlName="contentEn"
    ></qf-rich-editor>
    <qf-input-feedback
      [validations]="validations?.freeTextSolution?.en"
      [inputField]="editor"
      [inputValue]="form.get('solution')?.get('contentEn')?.value"
    ></qf-input-feedback>
  </div>

  <div
    class="free-text-container__lines"
    formGroupName="solution"
    #freeTextContainerLines
  >
    <label for="question_open_response_lines">{{
      'questions.edit.lines' | translate
    }}</label>
    <input
      type="number"
      class="form-control"
      id="question_open_response_lines"
      min="0"
      formControlName="suggestedNumberOfLines"
    />
  </div>
</ng-container>
