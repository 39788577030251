import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { requestStateSuccess } from 'src/app/common/utils/remote-data-utils';
import { isDefined } from 'src/app/common/utils/type-guards/is-defined';
import { PoolFormState } from '../../state/pool-details/form/pool-form.state';

@Component({
  templateUrl: './pool-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'container content' }
})
export class PoolEditComponent {
  public poolName$: Observable<string>;
  public failure$: Observable<boolean>;

  constructor(private store: Store) {
    this.failure$ = this.store.select(PoolFormState.poolFailure);
    this.poolName$ = this.store.select(PoolFormState.pool).pipe(
      filter(requestStateSuccess),
      map(({ data }) => data?.name),
      filter(isDefined)
    );
  }
}
