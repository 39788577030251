angular.module('settings').factory('Reducers::PoolList', ['Actions::PoolList', function (Actions) {
    function reducer (state, action) {
      state = state || {};

      switch (action.type) {
      case Actions.FETCH_POOLS_REQUEST:
        return Object.assign({}, state, {requestInProgress: true});

      case Actions.FETCH_POOLS_SUCCESS:
        return Object.assign({}, state, {
          items: action.pools,
          requestInProgress: false});

      case Actions.SET_POOL_SELECTION:
        return Object.assign({}, state, {
          selectedId: action.selectedId});

      default:
        return state;
      }

    }

    return reducer;
  }
]);
