(function() {
  angular.module('settings').component('oauthApplicationDetails', {
    template: require('./oauth_application_details.html'),
    controller: Controller,
    bindings: {
      application: '<'
    }
  });

  function Controller($state, Repository, Store, Actions, Selectors) {
    function handleCreationResponse(response) {
      if (response.erroneous) {
        this.errors = response.errors.field_messages;
      } else {
        this.application = response.oauth_application;
        Store.dispatch(Actions.addApplication(this.application));
        $state.go('settings.applications.edit', { uid: this.application.uid });
      }
    }

    function handleUpdateResponse(response) {
      if (response.erroneous) {
        this.errors = response.errors.field_messages;
      } else {
        this.application = response.oauth_application;
        Store.dispatch(Actions.updateApplication(this.application));
      }
    }

    this.I18n = I18n;

    this.save = function() {
      if (this.application.uid === undefined) {
        Repository.create(this.application).then(handleCreationResponse.bind(this));
      } else {
        Repository.update(this.application).then(handleUpdateResponse.bind(this));
      }
    }.bind(this);

    this.delete = function() {
      Repository.delete(this.application.uid).then(
        function() {
          Store.dispatch(Actions.deleteApplication(this.application.uid));
          var applications = Selectors.getItems(Store.getState());
          if (applications.length === 0) {
            $state.go('settings.applications.new');
          } else {
            $state.go('settings.applications.edit', { uid: applications[0].uid });
          }
        }.bind(this)
      );
    }.bind(this);

    this.$onChanges = function(changes) {
      if (changes.application !== undefined && changes.application.currentValue !== undefined) {
        if (changes.application.currentValue.uid !== undefined) {
          this.title = I18n.t('settings.applications.edit.title', {
            name: changes.application.currentValue.name
          });
        } else {
          this.title = I18n.t('settings.applications.new.title');
        }
      }
    }.bind(this);
  }

  Controller.$inject = [
    '$state',
    'Repositories::OauthApplication',
    'Store',
    'Actions::OauthApplicationList',
    'Selectors::OauthApplicationList'
  ];
})();
