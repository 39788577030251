angular.module('settings', ['common', 'ui.router', 'ngResource', 'ngCookies', 'ui.bootstrap']);

require('./config');
require('./routes');

require('./actions/category_selection');
require('./actions/oauth_application_list');
require('./actions/pool_list');
require('./actions/subject');

require('./components/category_selection');
require('./components/oauth_application_details');
require('./components/oauth_applications');
require('./components/pool_permissions');
require('./components/pool_sub_navigation');
require('./components/pools_subjects');
require('./components/pools');
require('./components/settings_navigation');
require('./components/settings_user_permissions');
require('./components/settings_users');
require('./components/subjects');
require('./components/user_sub_navigation');

require('./reducers/category_selection');
require('./reducers/oauth_application_list');
require('./reducers/pool_list');
require('./reducers/settings_root');
require('./reducers/subject_list');

require('./repositories/dimension');
require('./repositories/oauth_application');
require('./repositories/permission');
require('./repositories/subject');

require('./selectors/category_selection');
require('./selectors/oauth_application_list');
require('./selectors/pool_list');
require('./selectors/subject');
