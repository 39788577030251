import { Scalars } from '../../../generated/base-types';

const SET_SELECTED_TASK_IDS_TYPE =
  '[TaskManagement List] Set Selected Task IDs';
const SET_LOADED_TASK_IDS_TYPE = '[TaskManagement List] Set Loaded Task IDs';
const SET_SELECTED_QUESTION_GROUP_IDS_TYPE =
  '[TaskManagement List] Set Selected Question Group IDs';
const SET_LOADED_QUESTION_GROUP_IDS_TYPE =
  '[TaskManagement List] Set Loaded Question Group IDs';

export class SetSelectedTaskIds {
  public static readonly type = SET_SELECTED_TASK_IDS_TYPE;
  constructor(public readonly ids: Scalars['ID'][]) {}
}

export class SetLoadedTaskIds {
  public static readonly type = SET_LOADED_TASK_IDS_TYPE;
  constructor(public readonly ids: Scalars['ID'][]) {}
}

export class SetSelectedTaskQuestionGroupIds {
  public static readonly type = SET_SELECTED_QUESTION_GROUP_IDS_TYPE;
  constructor(
    public readonly taskQuestionGroupIds: Scalars['ID'][],
    public readonly questionGroupIds: Scalars['ID'][]
  ) {}
}

export class SetLoadedTaskQuestionGroupIds {
  public static readonly type = SET_LOADED_QUESTION_GROUP_IDS_TYPE;
  constructor(
    public readonly taskQuestionGroupIds: Scalars['ID'][],
    public readonly questionGroupIds: Scalars['ID'][]
  ) {}
}
