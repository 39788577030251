import { Scalars } from 'src/generated/base-types';
import {
  FilterAttributeType,
  FilterDimensionType,
  FilterHtmlLabelType,
  FilterLabelType
} from '../../services/filter/filter-helper.service';

export type PoolId = Scalars['ID'];

export type FilterOption =
  | FilterDimensionType
  | FilterLabelType
  | FilterHtmlLabelType;

export enum SimpleFilterCategory {
  Dimensions = 'dimensions',
  Exams = 'exams',
  Labels = 'labels',
  ContentValidationStates = 'contentValidationStates',
  Supervisors = 'supervisors',
  Affiliations = 'affiliations',
  RevisionStatus = 'revisionStatus',
  QuestionTypes = 'questionTypes'
}

export interface SimpleFilter {
  category: SimpleFilterCategory;
  options: FilterOption[];
  selection: FilterLabelType['value'][];
}

export interface AdvancedFilterValue {
  combinationOperator: string;
  id: string;
  operator: string;
  value: string | number | boolean;
}

export interface AdvancedFilter {
  options: FilterAttributeType[];
  selection: AdvancedFilterValue[];
}

export interface QuestionFilters {
  query: string;
  simpleFilters: SimpleFilter[];
  advancedFilter: AdvancedFilter;
  hidden: boolean;
}

export interface QuestionFilterStateModel {
  [key: PoolId]: QuestionFilters;
}
