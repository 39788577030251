import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { VideoRemoveModalComponent } from './components/video-remove-modal.component';

angular
  .module('questionForm')
  .directive(
    'qfVideoRemoveModalComponent',
    downgradeComponent({ component: VideoRemoveModalComponent })
  );
