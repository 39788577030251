import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TotalStatisticTypeEFragment } from 'src/app/question-management/services/question-group-statistics.generated';

@Component({
  selector: 'co-type-e-statistics',
  templateUrl: './type-e-statistics.component.html',
  styleUrls: ['./type-e-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeEStatisticsComponent {
  @Input()
  public set statistics(value: TotalStatisticTypeEFragment) {
    this._statistics = value;
    this.validStatistics =
      value.aBecauseB !== undefined ||
      value.aB !== undefined ||
      value.aNotB !== undefined ||
      value.notAB !== undefined ||
      value.notANotB !== undefined ||
      value.empty !== undefined;
  }
  public get statistics(): TotalStatisticTypeEFragment {
    return this._statistics;
  }

  public validStatistics = false;
  private _statistics: TotalStatisticTypeEFragment;
}
