<ng-container *ngIf="pool$ | async as pool">
  <div class="page-content" [ngClass]="{ loading: (loading$ | async) }">
    <div class="grid h-100" style="grid-template-rows: auto 1fr">
      <ng-container *ngIf="dimensions$ | async as dimensions">
        <div class="g-col-6 g-col-xl-8 ms-auto order-2">
          <button (click)="onNewDimension()" type="button" class="btn btn-dark">
            {{ 'admin.pools.dimensions.actions.new' | translate }}
          </button>
        </div>
        <div class="g-col-6 g-col-xl-4 order-1">
          <man-search-input
            [shortcutActive]="true"
            placeholder="admin.pools.dimensions.filter.placeholder"
            [disabled]="dimensions.requestState !== 'success'"
            (search)="onSearch($event)"
            (clear)="onSearchClear()"
          >
          </man-search-input>
        </div>
        <man-table-wrapper class="g-col-12 order-3" [request]="dimensions">
          <p-table
            #tbl
            name="Dimensions"
            [globalFilterFields]="filterFields"
            [value]="dimensions.data | copyArray"
            [loading]="dimensions.requestState === 'loading'"
            [scrollable]="true"
            scrollHeight="flex"
            [sortField]="sortBy"
            dataKey="id"
            selectionMode="single"
            (onRowSelect)="onDimensionRowSelect($event.data)"
            (onRowReorder)="onReorderDimension($event)"
          >
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th>
                  {{
                    'admin.pools.dimensions.table.header.position' | translate
                  }}
                </th>
                <th *ngIf="pool.deEnabled">
                  {{
                    'admin.pools.dimensions.table.header.name_de' | translate
                  }}
                </th>
                <th *ngIf="pool.frEnabled">
                  {{
                    'admin.pools.dimensions.table.header.name_fr' | translate
                  }}
                </th>
                <th *ngIf="pool.enEnabled">
                  {{
                    'admin.pools.dimensions.table.header.name_en' | translate
                  }}
                </th>
                <th *ngIf="pool.itEnabled">
                  {{
                    'admin.pools.dimensions.table.header.name_it' | translate
                  }}
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-dimension
              let-expanded="expanded"
              let-index="rowIndex"
            >
              <tr
                interactiveRow
                (edit)="onDimensionRowSelect(dimension)"
                [pReorderableRow]="index"
              >
                <td>
                  <i
                    aria-hidden="true"
                    class="fa-regular fa-bars"
                    [title]="'common.messages.drag_and_drop' | translate"
                    [pReorderableRowHandle]="index"
                  ></i>
                </td>
                <td>
                  <span class="badge rounded-pill text-bg-secondary">
                    {{
                      'admin.pools.dimensions.table.body.position'
                        | translate : { position: dimension.position + 1 }
                    }}
                  </span>
                </td>
                <td *ngIf="pool.deEnabled">
                  {{ dimension.nameDe }}
                </td>
                <td *ngIf="pool.frEnabled">
                  {{ dimension.nameFr }}
                </td>
                <td *ngIf="pool.enEnabled">
                  {{ dimension.nameEn }}
                </td>
                <td *ngIf="pool.itEnabled">
                  {{ dimension.nameIt }}
                </td>
                <td>
                  <div class="d-flex justify-content-end gap-2">
                    <man-action-menu
                      [data]="dimension"
                      [options]="dimensionMenuOptions"
                    ></man-action-menu>
                    <button
                      class="btn btn-icon"
                      type="button"
                      [title]="
                        'admin.pools.dimensions.table.actions.dimension.expand'
                          | translate
                      "
                      [pRowToggler]="dimension"
                    >
                      <i
                        aria-hidden="true"
                        class="fa-regular"
                        [ngClass]="{
                          'fa-chevron-up': expanded,
                          'fa-chevron-down': !expanded
                        }"
                      ></i>
                    </button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colSpan="8" class="text-center">
                  {{
                    'admin.pools.dimensions.table.messages.empty' | translate
                  }}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-dimension>
              <tr>
                <td colSpan="8" class="py-0 pe-0 ps-3">
                  <p-table
                    name="Categories"
                    [value]="dimension.categories | copyArray"
                    dataKey="id"
                    selectionMode="single"
                    (onRowSelect)="onCategoryRowSelect($event.data)"
                    (onRowReorder)="onReorderCategory($event, dimension)"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th
                          class="p-col-fixed p-col--icon border-bottom-0"
                        ></th>
                        <th colspan="7" class="border-bottom-0">
                          {{
                            'admin.pools.dimensions.table.captions.categories'
                              | translate
                          }}
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th>
                          {{
                            'admin.pools.dimensions.table.header.position'
                              | translate
                          }}
                        </th>
                        <th class="text-nowrap">
                          {{
                            'admin.pools.dimensions.table.header.custom_identifier'
                              | translate
                          }}
                        </th>
                        <th *ngIf="pool.deEnabled">
                          {{
                            'admin.pools.dimensions.table.header.name_fr'
                              | translate
                          }}
                        </th>
                        <th *ngIf="pool.frEnabled">
                          {{
                            'admin.pools.dimensions.table.header.name_en'
                              | translate
                          }}
                        </th>
                        <th *ngIf="pool.enEnabled">
                          {{
                            'admin.pools.dimensions.table.header.name_en'
                              | translate
                          }}
                        </th>
                        <th *ngIf="pool.itEnabled">
                          {{
                            'admin.pools.dimensions.table.header.name_it'
                              | translate
                          }}
                        </th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-category
                      let-index="rowIndex"
                    >
                      <tr
                        interactiveRow
                        (edit)="onEditCategory(category)"
                        [pReorderableRow]="index"
                      >
                        <td>
                          <i
                            aria-hidden="true"
                            class="fa-regular fa-bars draggable"
                            [title]="
                              'common.messages.drag_and_drop' | translate
                            "
                            [pReorderableRowHandle]="index"
                          ></i>
                        </td>
                        <td>
                          <span class="badge rounded-pill text-bg-secondary">
                            {{ category.position + 1 }}
                          </span>
                        </td>
                        <td>
                          {{ category.customIdentifier }}
                        </td>
                        <td *ngIf="pool.deEnabled">
                          {{ category.nameDe }}
                        </td>
                        <td *ngIf="pool.frEnabled">
                          {{ category.nameFr }}
                        </td>
                        <td *ngIf="pool.enEnabled">
                          {{ category.nameEn }}
                        </td>
                        <td *ngIf="pool.itEnabled">
                          {{ category.nameIt }}
                        </td>
                        <td>
                          <div class="d-flex justify-content-end gap-2">
                            <man-action-menu
                              [data]="category"
                              [options]="categoryMenuOptions"
                            ></man-action-menu>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                      <tr>
                        <td colspan="8" class="py-2 border-0"></td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colSpan="8" class="text-center">
                          {{
                            'admin.pools.dimensions.table.messages.empty'
                              | translate
                          }}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </man-table-wrapper>
      </ng-container>
    </div>
  </div>
  <ng-template #loader>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    {{ 'common.loading' | translate }}
  </ng-template>

  <ng-container *ngIf="formState$ | async as formState">
    <man-side-panel
      *ngIf="formState.show.showDimension"
      [title]="
        (formState.data?.dimensionData
          ? 'admin.pools.dimensions.table.form.title.dimension.edit'
          : 'admin.pools.dimensions.table.form.title.dimension.create'
        ) | translate
      "
      [closeable]="true"
      (closed)="dimensionForm.onClose()"
    >
      <man-dimension-form
        #dimensionForm
        class="mx-n3"
        [disabled]="formState.disabled"
        [dimension]="formState.data?.dimensionData"
        [dimensionNumber]="
          generateDimensionNumber(dimensions$, formState.data?.dimensionData)
            | async
        "
        [pool]="pool"
        (create)="onCreateDimension($event)"
        (update)="onUpdateDimension($event)"
        (closed)="onDismiss()"
      >
      </man-dimension-form>
    </man-side-panel>
  </ng-container>

  <ng-container *ngIf="formState$ | async as formState">
    <man-side-panel
      *ngIf="formState.show.showCategory"
      [title]="
        (formState.data?.categoryData
          ? 'admin.pools.dimensions.table.form.title.category.edit'
          : 'admin.pools.dimensions.table.form.title.category.create'
        ) | translate
      "
      [closeable]="true"
      (closed)="categoryForm.onClose()"
    >
      <man-category-form
        #categoryForm
        class="mx-n3"
        [disabled]="formState.disabled"
        [category]="formState.data?.categoryData"
        [categoryNumber]="
          generateCategoryNumber(dimensions$, formState.data?.categoryData)
            | async
        "
        [pool]="pool"
        (update)="onUpdateCategory($event)"
        (closed)="onDismiss()"
      >
      </man-category-form>
    </man-side-panel>
  </ng-container>
</ng-container>
