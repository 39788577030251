import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { SelectableRow, Table, TableService } from 'primeng/table';

@Directive({
  selector: '[interactiveRow]'
})
export class InteractiveRowDirective extends SelectableRow {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input('interactiveRow') public data: any;

  @Output() public edit = new EventEmitter<void>();
  @Output() public delete = new EventEmitter<void>();

  @HostBinding('style.cursor') public cursor = 'pointer';

  private mouseIsDown = false;
  private mouseIsDragging = false;
  private mouseInitialPosition: { x: number; y: number };
  private readonly mouseMovementThreshold = 5;

  constructor(public dt: Table, public tableService: TableService) {
    super(dt, tableService);
  }

  // Override the onClick of the SelectableRow directive
  // see https://github.com/primefaces/primeng/blob/f0b6fc98d08285a61e63a0200cd410f75f195414/src/app/components/table/table.ts#L2930-L3066
  @HostListener('click', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onClick(): void {}

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    this.mouseIsDown = true;
    this.mouseIsDragging = false;
    this.mouseInitialPosition = { x: event.clientX, y: event.clientY };
  }

  @HostListener('mousemove', ['$event'])
  public onMouseMove(event: MouseEvent): void {
    if (!this.mouseIsDown) return;
    const displacementX = Math.abs(event.clientX - this.mouseInitialPosition.x);
    const displacementY = Math.abs(event.clientY - this.mouseInitialPosition.y);
    this.mouseIsDragging =
      displacementX > this.mouseMovementThreshold ||
      displacementY > this.mouseMovementThreshold;
  }

  @HostListener('mouseup', ['$event'])
  public onMouseUp(event: MouseEvent): void {
    this.mouseIsDown = false;

    if (this.mouseIsDragging) return;
    if (event.target instanceof HTMLButtonElement) return;

    this.edit.emit();
  }

  @HostListener('keydown.backspace', ['$event'])
  public onDelete(): void {
    if (!this.isEnabled()) return;
    this.delete.emit();
  }

  @HostListener('keydown.enter', ['$event'])
  public onSelect(): void {
    if (!this.isEnabled()) return;
    this.edit.emit();
  }
}
