import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Scalars } from '../../../../generated/base-types';
import {
  ExportConfig,
  ExportFormats,
  ExportTypes
} from '../../../common/question-group-export/question-group-export.types';
import { assertIsDefined } from '../../../common/utils/type-guards/is-defined';
import { QuestionGroupListElementFragment } from '../../../question-form/services/load-question-group-list.generated';
import { QuestionGroupExportGQL } from '../../components/question-group-export-modal/question-group-export.generated';
import { QuestionGroupExporterService } from '../../services/question-group-exporter.service';
import { QuestionListState } from '../../state/question-list.state';

@Component({
  selector: 'qm-question-group-export-modal',
  templateUrl: './question-group-export-modal.component.html',
  styleUrls: ['./question-group-export-modal.component.scss']
})
export class QuestionGroupExportModalComponent implements OnInit, OnDestroy {
  @Input()
  public modalInstance: NgbModalRef;

  @Input()
  public currentPoolId: Scalars['ID'];

  @Input()
  public questionGroups: QuestionGroupListElementFragment[];

  @Select(QuestionListState.filteredQuestionGroupIds)
  private filteredQuestionGroupIds$: Observable<Scalars['ID'][]>;

  @Select(QuestionListState.selectedQuestionGroupsIds)
  private selectedQuestionGroupsIds$: Observable<Scalars['ID'][]>;

  public exportConfig: ExportConfig = {
    type: ExportTypes.All,
    format: ExportFormats.Excel
  };
  public filteredQuestionGroupIds: Scalars['ID'][];
  public selectedQuestionGroupIds: Scalars['ID'][];
  public isSubmitting = false;

  private destroy$ = new Subject<void>();

  constructor(
    public questionGroupExportGQL: QuestionGroupExportGQL,
    public questionGroupExporterService: QuestionGroupExporterService
  ) {}

  public ngOnInit(): void {
    this.filteredQuestionGroupIds$
      .pipe(takeUntil(this.destroy$))
      .subscribe(ids => (this.filteredQuestionGroupIds = ids));
    this.selectedQuestionGroupsIds$
      .pipe(takeUntil(this.destroy$))
      .subscribe(ids => (this.selectedQuestionGroupIds = ids));

    this.selectedQuestionGroupIds = this.questionGroups.map(qg => qg.id);
    this.exportConfig.type =
      this.selectedQuestionGroupIds.length === 0
        ? ExportTypes.All
        : ExportTypes.Selected;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public async generate(): Promise<void> {
    this.isSubmitting = true;

    const questionGroupIds =
      this.exportConfig.type === ExportTypes.Selected
        ? this.selectedQuestionGroupIds
        : this.filteredQuestionGroupIds;
    const result = await this.questionGroupExportGQL
      .fetch({
        poolId: this.currentPoolId,
        questionGroupIds: questionGroupIds.map(id => id.toString())
      })
      .toPromise();
    const questionGroups = result?.data.pool.questionGroups;

    assertIsDefined(questionGroups);

    if (this.exportConfig.format === ExportFormats.Excel) {
      this.questionGroupExporterService.saveAsXlsx(questionGroups);
    } else {
      this.questionGroupExporterService.saveAsCsv(questionGroups);
    }

    this.modalInstance.close();
  }
}
