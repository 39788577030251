import { Editor, EditorManager } from 'tinymce';

interface TranslationData {
  [key: string]: {
    [key: string]: string;
  };
}

const translations: TranslationData = {
  en: {
    'tiny_latex.edit_title': 'Edit LaTeX',
    'tiny_latex.insert_title': 'Insert LaTeX',
    'tiny_latex.input_label': 'LaTeX',
    'tiny_latex.preview_label': 'Preview',
    'tiny_latex.update_button': 'Update',
    'tiny_latex.cancel_button': 'Cancel',
    'tiny_latex.insert_button': 'Insert'
  },
  // eslint-disable-next-line camelcase
  fr_FR: {
    'tiny_latex.edit_title': 'Edit LaTeX',
    'tiny_latex.insert_title': 'Insert LaTeX',
    'tiny_latex.input_label': 'LaTeX',
    'tiny_latex.preview_label': 'Aperçu',
    'tiny_latex.update_button': 'Mettre à jour',
    'tiny_latex.cancel_button': 'Annuler',
    'tiny_latex.insert_button': 'Insérer'
  },
  de: {
    'tiny_latex.edit_title': 'Edit LaTeX',
    'tiny_latex.insert_title': 'Insert LaTeX',
    'tiny_latex.input_label': 'LaTeX',
    'tiny_latex.preview_label': 'Vorschau',
    'tiny_latex.update_button': 'Aktualisieren',
    'tiny_latex.cancel_button': 'Abbrechen',
    'tiny_latex.insert_button': 'Einfügen'
  }
};

export const setTranslations = (
  tinyMCE: EditorManager,
  editor: Editor
): void => {
  const language = editor.getParam('language') ?? 'en';

  if (translations[language] === undefined)
    throw new Error(`Language ${language} is not supported`);

  tinyMCE.addI18n(language, translations[language]);
};
