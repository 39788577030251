import * as Types from '../../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetRevisionStatusMutationVariables = Types.Exact<{
  poolId: Types.Scalars['ID'];
  questionGroupIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  revisionStatusId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type SetRevisionStatusMutation = { __typename?: 'Mutation', setRevisionStatus?: { __typename?: 'SetRevisionStatusPayload', successful: boolean } | null };

export const SetRevisionStatusDocument = gql`
    mutation SetRevisionStatus($poolId: ID!, $questionGroupIds: [ID!]!, $revisionStatusId: ID) {
  setRevisionStatus(
    poolId: $poolId
    questionGroupIds: $questionGroupIds
    revisionStatusId: $revisionStatusId
  ) {
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetRevisionStatusGQL extends Apollo.Mutation<SetRevisionStatusMutation, SetRevisionStatusMutationVariables> {
    document = SetRevisionStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }