import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {
  public get window(): Window {
    return window;
  }

  public get href(): string {
    return window.location.href;
  }

  public set href(url: string) {
    window.location.href = url;
  }
}
