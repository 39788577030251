/* eslint-disable camelcase, unicorn/no-null, @typescript-eslint/no-non-null-assertion */
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import type { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateAdapter,
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  QuestionGroupType,
  QuestionType,
  Scalars,
  TaskInput,
  TaskType
} from '../../../../generated/base-types';
import { LabelValuePair } from '../../ng-select-wrapper/ng-select-wrapper.component';
import { Notifications } from '../../services/notifications';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../utils/ng-bootstrap-modal';
import { assertIsDefined } from '../../utils/type-guards/is-defined';
import {
  buildRecentEmailBodyOptions,
  buildUserOptions
} from '../task-modal-options.tool';
import { ConfirmCreateTaskModalComponent } from './confirm-create-task-modal.component';
import { CreateTaskGQL } from './create-task.generated';
import {
  LoadTaskOptionsGQL,
  TaskDimensionFragment,
  TaskOptionQuestionGroupFragment,
  TaskUserFragment
} from './load-task-options.generated';

export const secondsInADay = 24 * 60 * 60 * 1000;
export const secondsInAYear = secondsInADay * 365;

@Component({
  selector: 'co-create-task-modal',
  templateUrl: './create-task-modal.component.html',
  styleUrls: ['./create-task-modal.component.scss']
})
export class CreateTaskModalComponent implements OnInit {
  @Input()
  public modalInstance: NgbModalRef;

  @Input()
  public questionGroupIds: string[];

  @Input()
  public poolId: Scalars['ID'];

  public form: UntypedFormGroup;
  public receiverIds: string[] = [];
  public userOptions: LabelValuePair<string>[] = [];
  public recentEmailBodies: LabelValuePair<string>[] = [];
  public date: { year: number; month: number };
  public maxDate: NgbDateStruct;
  public minDate: NgbDateStruct;
  public dimensions: TaskDimensionFragment[];
  public questionGroups: TaskOptionQuestionGroupFragment[];
  public loading = true;
  public isSubmitting = false;
  public showDetails = false;

  private users: TaskUserFragment[];

  constructor(
    private loadTaskOptionsGQL: LoadTaskOptionsGQL,
    private datePipe: DatePipe,
    private createTaskGQL: CreateTaskGQL,
    private readonly translate: TranslateService,
    private notifications: Notifications,
    private readonly ngbModal: NgbModal,
    private ngbDateAdapter: NgbDateAdapter<Date>
  ) {
    this.maxDate = this.ngbDateAdapter.fromModel(
      new Date(Date.now() + 7 * secondsInAYear)
    )!; // we're guaranteed to get a NgbDateStruct in return
    this.minDate = this.ngbDateAdapter.fromModel(new Date(Date.now()))!; // we're guaranteed to get a NgbDateStruct in return

    this.form = new UntypedFormGroup({
      emailBody: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      emailSubject: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      a: new UntypedFormControl(true),
      aMinus: new UntypedFormControl(true),
      e: new UntypedFormControl(true),
      freeText: new UntypedFormControl(true),
      kPrime: new UntypedFormControl(true),
      sequence: new UntypedFormControl(true),
      series: new UntypedFormControl(true),
      receiverIds: new UntypedFormControl(
        [],
        [Validators.required, Validators.minLength(1)]
      ),
      deadline: new UntypedFormControl(null, [Validators.required])
    });
  }

  public async ngOnInit(): Promise<void> {
    const query = await this.loadTaskOptionsGQL
      .fetch({
        poolId: this.poolId,
        questionGroupIds: this.questionGroupIds.map(id => id.toString()),
        taskType: TaskType.Create
      })
      .toPromise();

    assertIsDefined(query);

    this.users = query.data.pool.users;
    this.dimensions = query.data.pool.dimensions;
    this.questionGroups = query.data.pool.questionGroups;
    this.userOptions = buildUserOptions(this.users);
    this.recentEmailBodies = buildRecentEmailBodyOptions(
      query.data.pool.tasks,
      this.datePipe
    );
    this.loading = false;
  }

  public dismiss(): void {
    this.modalInstance.dismiss();
  }

  public async save(): Promise<void> {
    const formValues = this.form.getRawValue();
    const questionGroupTypes = [
      ...(formValues.sequence === true ? [QuestionGroupType.Sequence] : []),
      ...(formValues.series === true ? [QuestionGroupType.Series] : [])
    ];
    const questionTypes = [
      ...(formValues.a === true ? [QuestionType.A] : []),
      ...(formValues.aMinus === true ? [QuestionType.AMinus] : []),
      ...(formValues.kPrime === true ? [QuestionType.KPrime] : []),
      ...(formValues.e === true ? [QuestionType.E] : []),
      ...(formValues.freeText === true ? [QuestionType.FreeText] : [])
    ];
    const taskInput: TaskInput = {
      deadline: formValues.deadline?.toISOString(),
      emailBody: formValues.emailBody,
      emailSubject: formValues.emailSubject,
      questionGroupIds: this.questionGroupIds.map(id => id.toString()),
      create: {
        questionGroupTypes: questionGroupTypes,
        questionTypes: questionTypes
      },
      receiverIds: formValues.receiverIds,
      type: TaskType.Create
    };

    const ngbInstance = this.ngbModal.open(
      ConfirmCreateTaskModalComponent,
      NG_MODAL_DEFAULT_OPTIONS
    );

    const users = this.users.filter(user =>
      formValues.receiverIds.includes(user.id)
    );
    const componentInstance: ConfirmCreateTaskModalComponent =
      ngbInstance.componentInstance;
    componentInstance.modalInstance = ngbInstance;
    componentInstance.users = users;
    componentInstance.subject = formValues.emailSubject;
    componentInstance.body = formValues.emailBody;
    componentInstance.questionTypes = questionTypes;
    componentInstance.questionGroupTypes = questionGroupTypes;

    try {
      await ngbInstance.result;
      this.isSubmitting = true;
      const result = await this.createTaskGQL
        .mutate({
          poolId: this.poolId,
          attributes: taskInput
        })
        .toPromise();

      if (result?.data?.createTask?.successful !== true) {
        throw `Unable to create comment task on pool ${this.poolId}`;
      }

      this.isSubmitting = false;
      this.modalInstance.close();
      this.notifications.addSuccess(
        this.translate.instant(
          'question_management.create_task_modal.notifications.task_created'
        )
      );
    } catch (_rejected: unknown) {
      // do nothing
    }
  }

  public setEmailBody(value: string): void {
    this.form.patchValue({ emailBody: value });
  }

  public isControlInvalid(name: string): boolean {
    const control = this.form.get(name);
    if (control === null) {
      return false;
    }

    return control.invalid;
  }
}
