import { CommonModule, DOCUMENT } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { setStyles } from '../routing-utils';

import './angularjs/module';
import { initPathObserver } from './path-observer';

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class MainModule {
  private currentPath: string;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.currentPath = this.document.location.pathname;
    initPathObserver({
      currentPath: this.currentPath,
      setStyles: setStyles,
      document: this.document
    });
  }
}
