(function () {
  angular.module('common').component('loader', {
    template: require('./loader.html'),
    controller: LoaderController
  });

  LoaderController.$inject = [];

  function LoaderController() {
  }
})();
