import { Directive, HostListener } from '@angular/core';
import { Table } from 'primeng/table';

// allows select primeng table items using up an down key
@Directive({
  selector: '[tableKeyboardNavigation]'
})
export class TableKeyboardNavigationDirective {
  constructor(private table: Table) {
    if (table.virtualScroll === true) {
      throw 'TableKeyboardNavigationDirective does not support virtual scroll';
    }
  }

  @HostListener('keydown.ArrowDown')
  public onArrowDown(): void {
    if (this.table.selection.length === 0) {
      return;
    }

    const indexFirstSelectedElement = this.getFirstSelectedElIndex();
    const endOfList = indexFirstSelectedElement === this.table.value.length - 1;

    if (endOfList) return;

    const nextElement = this.table.value[indexFirstSelectedElement + 1];
    this.table.selectionChange.emit([nextElement]);
  }

  @HostListener('keydown.ArrowUp')
  public onArrowUp(): void {
    if (this.table.selection.length === 0) {
      return;
    }

    const indexFirstSelectedElement = this.getFirstSelectedElIndex();
    const startOfList = indexFirstSelectedElement === 0;

    if (startOfList) return;

    const previousElement = this.table.value[indexFirstSelectedElement - 1];
    this.table.selectionChange.emit([previousElement]);
  }

  private getFirstSelectedElIndex(): number {
    return this.table.value.indexOf(this.table.selection[0]);
  }
}
