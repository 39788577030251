import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../../generated/base-types';
import { isDefined } from '../../common/utils/type-guards/is-defined';

@Pipe({
  name: 'localizeContent',
  pure: false
})
export class LocalizeContentPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(
    localizedContent: { [lang in Language]?: string } = {},
    fallbackLanguage?: Language
  ): string {
    const lang = this.translateService.currentLang as Language;
    const content =
      localizedContent[lang] ??
      (fallbackLanguage && localizedContent[fallbackLanguage]);

    if (isDefined(content)) {
      return content;
    }

    const rescueLanguage = Object.keys(localizedContent)[0] as Language;
    const rescueContent = localizedContent[rescueLanguage];

    if (!isDefined(rescueContent)) {
      throw new Error('Invalid content provided');
    }

    return rescueContent;
  }
}
