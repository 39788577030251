import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoadPoolsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LoadPoolsQuery = { __typename?: 'Query', pools: Array<{ __typename?: 'Pool', id: string, name: string }> };

export type PoolListElementFragment = { __typename?: 'Pool', id: string, name: string };

export const PoolListElementFragmentDoc = gql`
    fragment PoolListElement on Pool {
  id
  name
}
    `;
export const LoadPoolsDocument = gql`
    query LoadPools {
  pools {
    ...PoolListElement
  }
}
    ${PoolListElementFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadPoolsGQL extends Apollo.Query<LoadPoolsQuery, LoadPoolsQueryVariables> {
    document = LoadPoolsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }