<div
  class="toast"
  [ngClass]="{
    'success text-bg-success': type === 'SUCCESS',
    'info text-bg-info': type === 'INFO',
    'warning text-bg-warning': type === 'WARNING'
  }"
  [class]="state"
  [style.--progress-duration]="duration || 1000"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
  (animationend)="animationEnd($event)"
>
  <div class="toast__progress"></div>
  <div class="toast__body">
    <i
      class="fa-solid fa-lg me-3"
      [ngClass]="{
        'fa-circle-check': type === 'SUCCESS',
        'fa-circle-info': type === 'INFO',
        'fa-circle-exclamation': type === 'WARNING'
      }"
    ></i>
    {{ message }}
  </div>
  <button
    type="button"
    class="btn-close"
    [ngClass]="{ 'btn-close-white': type === 'SUCCESS' }"
    (click)="onRemove()"
    aria-label="Close"
  ></button>
</div>
