angular.module('common').factory('Actions::User', function() {
  var actions = {
    SET_LANGUAGE: 'SET_LANGUAGE',
    SET_CURRENT_USER: 'SET_CURRENT_USER'
  };

  function setLanguage(language) {
    return {
      type: actions.SET_LANGUAGE,
      language: language
    };
  }

  function setCurrentUser(user) {
    return {
      type: actions.SET_CURRENT_USER,
      user: user
    };
  }

  return Object.assign(
    {
      setLanguage: setLanguage,
      setCurrentUser: setCurrentUser
    },
    actions
  );
});
