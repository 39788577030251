import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { SetAffiliationModalComponent } from '../modals/set-affiliation-modal/set-affiliation-modal.component';

angular
  .module('common')
  .directive(
    'coSetAffiliationModal',
    downgradeComponent({ component: SetAffiliationModalComponent })
  );
