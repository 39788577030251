import * as Types from '../../../generated/base-types';
import { ManagerError } from '../../common/utils/error-parser';
import { LoadUsersFieldsFragment } from '../services/load-users.fragments.generated';

export class LoadUsers {
  public static readonly type = '[Users] Load Users';
}

export class LoadUsersSuccess {
  public static readonly type = '[Users] Load Users Success';
  constructor(public readonly users: LoadUsersFieldsFragment[]) {}
}

export class LoadUsersFailure {
  public static readonly type = '[Users] Load Users Failure';
  constructor(public readonly error: ManagerError) {}
}

export class DeleteUser {
  public static readonly type = '[Users] Delete User';
  constructor(public readonly id: Types.Scalars['ID']) {}
}

export class DeleteUserSuccess {
  public static readonly type = '[Users] Delete User Success';
  constructor(public readonly id: Types.Scalars['ID']) {}
}

export class DeleteUserFailure {
  public static readonly type = '[Users] Delete User Failure';
  constructor(
    public readonly error: ManagerError,
    public readonly id: Types.Scalars['ID']
  ) {}
}
