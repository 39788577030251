import { Component, Input } from '@angular/core';
import {
  TaskDimensionFragment,
  TaskOptionQuestionGroupFragment
} from '../../create-task-modal/load-task-options.generated';

// Generic component which can be used in all task modals
@Component({
  selector: 'co-task-questions-table',
  templateUrl: './task-questions-table.component.html'
})
export class TaskQuestionsTableComponent {
  @Input() public dimensions: TaskDimensionFragment[];
  @Input() public questionGroups: TaskOptionQuestionGroupFragment[];
}
