<nav class="navbar navbar-dark bg-dark">
  <div class="container">
    <a class="navbar-brand" href="/">
      <img src="assets/logo_white.png" style="height: 24px" />
    </a>

    <div class="d-flex">
      <div ngbDropdown class="d-inline-block">
        <a
          role="button"
          *ngIf="currentUser$ | async as currentUser"
          class="text-white text-decoration-none"
          ngbDropdownToggle
        >
          <i class="fa-regular fa-circle-user mx-1 align-middle"></i>
          <span class="align-middle">
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </span>
        </a>
        <div ngbDropdownMenu>
          <a
            ngbDropdownItem
            [routerLink]="'/profile'"
            routerLinkActive="active"
            >{{ 'app_header.user_menu.my_profile' | translate }}</a
          >
          <button ngbDropdownItem (click)="onLogout()">
            {{ 'app_header.user_menu.log_out' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>
