import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileSaverService {
  public saveStringAs(
    data: string | ArrayBuffer,
    filename: string,
    mimeType: string
  ): void {
    saveAs(new Blob([data], { type: mimeType }), filename);
  }

  public saveAs(url: string): void {
    saveAs(url);
  }
}
