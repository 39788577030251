export interface Group<S, T> {
  name: S;
  items: T[];
}

interface GroupedHistoryOptions<S, T> {
  groupByFn: (item: T) => S;
}

export class GroupedHistory<S, T> {
  constructor(
    private items: T[],
    private options: GroupedHistoryOptions<S, T>
  ) {}

  public push(...items: T[]): void {
    this.items.push(...items);
  }

  public getGroups(): Group<S, T>[] {
    return this.items.reduce((result, item) => {
      const itemGroupName = this.options.groupByFn(item);
      const currentGroup = result[result.length - 1];
      if (result.length === 0 || currentGroup.name !== itemGroupName) {
        result.push({
          name: itemGroupName,
          items: [item]
        });
      } else {
        currentGroup.items.push(item);
      }

      return result;
    }, [] as Group<S, T>[]);
  }
}
