<div class="modal-wrapper bs4">
  <div class="modal-header">
    <a class="close" (click)="cancel()">&times;</a>
    <h3>{{ 'question_groups.new_question_modal.title' | translate }}</h3>
  </div>
  <div class="modal-body">
    <div class="form-group" *ngIf="!hideQuestionGroupTypes">
      <label for="questionGroupType">{{
        'question_groups.new_question_modal.question_group_type' | translate
      }}</label>
      <co-radio-button-group
        id="questionGroupType"
        [(ngModel)]="questionGroupType"
        class="form-control"
      >
        <ng-container *ngFor="let type of allQuestionGroupTypes">
          <button [value]="type" class="btn btn-secondary-migrated">
            {{
              'activerecord.choices.question_group.type.' + type.toUpperCase()
                | translate
            }}
          </button>
        </ng-container>
      </co-radio-button-group>
    </div>
    <div class="form-group">
      <label for="questionType">{{
        'question_groups.new_question_modal.question_type' | translate
      }}</label>
      <co-radio-button-group
        id="questionType"
        [(ngModel)]="questionType"
        class="form-control"
      >
        <ng-container *ngFor="let type of allQuestionTypes">
          <button [value]="type" class="btn btn-secondary-migrated">
            {{ 'questions.type.' + type | translate }}
          </button>
        </ng-container>
      </co-radio-button-group>
    </div>
    <p *ngIf="questionType == 'a'">
      {{ 'question_groups.new_question_modal.type_description.A' | translate }}
    </p>
    <p *ngIf="questionType == 'aMinus'">
      {{ 'question_groups.new_question_modal.type_description.A-' | translate }}
    </p>
    <p *ngIf="questionType == 'e'">
      {{ 'question_groups.new_question_modal.type_description.E' | translate }}
    </p>
    <p *ngIf="questionType == 'kPrime'">
      {{
        'question_groups.new_question_modal.type_description.Kprime' | translate
      }}
    </p>
    <p *ngIf="questionType == 'freeText'">
      {{
        'question_groups.new_question_modal.type_description.FreeText'
          | translate
      }}
    </p>
    <p *ngIf="questionType == 'longMenu'">
      {{
        'question_groups.new_question_modal.type_description.LongMenu'
          | translate
      }}
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary-migrated" (click)="cancel()">
      {{ 'common.actions.cancel' | translate }}
    </button>
    <button class="btn btn-primary-migrated" (click)="close()">
      {{ 'question_groups.new_question_modal.save_button' | translate }}
    </button>
  </div>
</div>
