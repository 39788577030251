(function () {
  angular.module('common').factory('UniqueArray', function () {
    var UniqueArray = function(items) {
      this.data = [];
      if (items) {
        this.addAll(items);
      }
    };

    UniqueArray.prototype.add = function (item) {
      if (this.data.indexOf(item) === -1) {
        this.data.push(item);
      }
    };

    UniqueArray.prototype.addAll = function (items) {
      var i = 0;

      for(; i < items.length; i++) {
        this.add(items[i]);
      }
    };

    UniqueArray.prototype.remove = function (item) {
      var index = this.indexOf(item);

      this.data.splice(index, 1);
    };

    UniqueArray.prototype.removeAll = function (items) {
      this.data.length = 0;
    };

    UniqueArray.prototype.at = function (index) {
      return this.data[index];
    };

    UniqueArray.prototype.length = function () {
      return this.data.length;
    };

    UniqueArray.prototype.isEmpty = function() {
      return this.length() === 0;
    };

    UniqueArray.prototype.indexOf = function (item) {
      return this.data.indexOf(item);
    };

    UniqueArray.prototype.has = function (item) {
      return this.indexOf(item) > -1;
    };

    UniqueArray.prototype.getItems = function () {
      return this.data;
    };

    UniqueArray.prototype.getLast = function() {
      return this.data[this.data.length - 1];
    };

    UniqueArray.prototype.map = function(callback) {
      var result = [];
      for(var i = 0; i < this.data.length; i++) {
        result.push(callback(this.data[i]));
      }
      return result;
    };

    UniqueArray.prototype.filter = function(callback) {
      return this.data.filter(callback);
    };

    UniqueArray.prototype.each = function(callback) {
      for(var i = 0; i < this.data.length; i++) {
        callback(this.data[i]);
      }
    };

    return UniqueArray;
  });
}).call(this);
