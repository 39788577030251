angular.module('common').factory('Selectors::User', function() {
  function getLanguage(object) {
    return object.language;
  }

  function getCurrentUser(object) {
    return object.user;
  }

  return {
    getLanguage: getLanguage,
    getCurrentUser: getCurrentUser
  };
});
