import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Table } from 'primeng/table';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { FormState } from 'src/app/common/types/form-types';
import { RemoteData } from 'src/app/common/utils/remote-data';
import { isDefined } from 'src/app/common/utils/type-guards/is-defined';
import {
  PermissionInput,
  PermissionRole,
  Scalars
} from 'src/generated/base-types';
import { ActionMenuOption } from '../../common/action-menu/action-menu.component';
import { ModalService } from '../../common/modal/modal.service';
import { PermissionFormComponent } from '../../pools/pool/pool-permissions/permission-form/permission-form.component';
import { PoolPermissionsFragment } from '../../services/load-pool-permissions.fragments.generated';
import {
  DeleteUserPermission,
  UpdateUserPermission
} from '../../state/user-permissions.actions';
import { UserPermissionsState } from '../../state/user-permissions.state';

@Component({
  templateUrl: './user-permissions.component.html',
  host: { class: 'page' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPermissionsComponent implements OnDestroy {
  @ViewChild('table', { static: false })
  public table?: Table;
  @ViewChild(PermissionFormComponent)
  public formComponent: PermissionFormComponent;

  public readonly filterFields = ['pool.name', 'role'];
  public readonly loading$: Observable<boolean>;
  public readonly userPermissions$: Observable<
    RemoteData<PoolPermissionsFragment[]>
  >;
  public readonly formState$: Observable<
    FormState<Partial<PoolPermissionsFragment>>
  >;
  public readonly menuOptions: ActionMenuOption[] = [
    {
      label: 'common.actions.edit',
      callback: (data: PoolPermissionsFragment) => this.onEdit(data)
    },
    {
      label: 'common.actions.delete',
      danger: true,
      callback: (data: PoolPermissionsFragment) => this.onDelete(data)
    }
  ];
  public userId$: Observable<Scalars['ID'] | undefined>;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.formState$ = this.formState();
    this.dismissSidebarOnSuccessfulMutation();
    this.userPermissions$ = this.store.select(
      UserPermissionsState.userPermissions
    );
    this.loading$ = this.store.select(UserPermissionsState.permissionsLoading);
    this.userId$ = this.route.parent!.paramMap.pipe(
      map(params => params.get('id') ?? undefined)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSearch(value: string): void {
    this.table?.filterGlobal(value, 'contains');
  }

  public onSearchClear(): void {
    this.table?.clear();
  }

  public onNew(): void {
    this.routeToForm('new');
  }

  public onEdit({ id }: PoolPermissionsFragment): void {
    this.routeToForm(id);
  }

  public onUpdate(permissionInput: PermissionInput): void {
    const userPermissionInput: PermissionInput = {
      role: permissionInput.role as PermissionRole,
      subjectId: permissionInput.subjectId,
      userId: permissionInput.userId,
      poolId: permissionInput.poolId
    };

    this.route.queryParams
      .pipe(
        map<{ editing?: string }, string | undefined>(
          params => params?.editing
        ),
        filter(isDefined),
        take(1),
        switchMap(editing => {
          return this.store.dispatch(
            new UpdateUserPermission(editing, userPermissionInput)
          );
        })
      )
      .subscribe();
  }

  public onClose(): void {
    this.routeToForm();
  }

  public async onDelete(permission: PoolPermissionsFragment): Promise<void> {
    const confirmDelete = await this.modalService.confirmDelete();
    if (confirmDelete) {
      this.store.dispatch(new DeleteUserPermission(permission.id));
    }
  }

  private formState(): Observable<FormState<Partial<PoolPermissionsFragment>>> {
    const editingParam$ = this.route.queryParamMap.pipe(
      map(params => params.get('editing') ?? undefined)
    );
    const disabled$ = this.store
      .select(UserPermissionsState.mutationRequestState)
      .pipe(
        map(requestState => requestState === 'loading'),
        distinctUntilChanged()
      );

    return combineLatest([editingParam$, disabled$]).pipe(
      switchMap(([editingParam, disabled]) => {
        const show: boolean = editingParam !== undefined;
        const id = show && editingParam !== 'new' ? editingParam : undefined;

        return this.userPermissions$.pipe(
          filter(({ requestState }) => requestState === 'success'),
          take(1),
          map(({ data }) =>
            id === undefined ? undefined : data?.find(a => a.id === id)
          ),
          map(data => ({ show, disabled, data }))
        );
      })
    );
  }

  private dismissSidebarOnSuccessfulMutation(): void {
    this.store
      .select(UserPermissionsState.mutationRequestState)
      .pipe(
        pairwise(),
        filter(
          ([lastState, currentState]) =>
            lastState === 'loading' && currentState === 'success'
        ),
        tap({ next: () => this.onClose() }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private routeToForm(editing?: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { editing },
      queryParamsHandling: 'merge'
    });
  }
}
