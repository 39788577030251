import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AnnouncementInput,
  AnnouncementSeverity,
  Scalars
} from '../../../../generated/base-types';
import { ModalService } from '../../common/modal/modal.service';
import { dateGteCompareValidatorFactory } from '../../form/utils/date-gte-compare.validator';
import { gteTodayValidator } from '../../form/utils/date-gte.validator';
import { AnnouncementListElementFragment as AnnouncementListElement } from '../../services/load-announcements.generated';

@Component({
  selector: 'man-announcement-form',
  templateUrl: './announcement-form.component.html',
  host: { class: 'd-block h-100' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementFormComponent implements OnInit {
  @Input()
  public announcement?: AnnouncementListElement;
  @Input()
  public canDismissWhileDirty?: boolean;
  @Input()
  public set disabled(value: boolean | undefined) {
    value === true ? this.form?.disable() : this.form?.enable();
  }

  @Output()
  public readonly update = new EventEmitter<
    AnnouncementInput & { id: Scalars['ID'] }
  >();
  @Output()
  public readonly create = new EventEmitter<AnnouncementInput>();
  @Output()
  public readonly closed = new EventEmitter<void>();

  // NOTE: The native input type "date" requires the specific format "yyyy-MM-dd" when the value is set
  public readonly dateInputToday = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  public readonly severityOptions: {
    label: string;
    value: AnnouncementSeverity;
  }[] = [
    { label: 'common.severity.info', value: AnnouncementSeverity.Info },
    { label: 'common.severity.warning', value: AnnouncementSeverity.Warning },
    { label: 'common.severity.danger', value: AnnouncementSeverity.Danger }
  ];
  public form: FormGroup;

  public get edit(): boolean {
    return this.announcement !== undefined;
  }

  constructor(private readonly modalService: ModalService) {}

  public ngOnInit(): void {
    this.initialiseForm();
  }

  public onSave(): void {
    if (!this.form.valid || !this.form.dirty) {
      return;
    }

    // NOTE: Set the italian message to an empty string because announcements are not displayed in italian
    const annoucementInput = {
      ...this.form.value,
      message: { it: '', ...this.form.value.message }
    };

    this.announcement === undefined
      ? this.create.emit(annoucementInput)
      : this.update.emit({
          id: this.announcement.id,
          ...annoucementInput
        });
  }

  public async onClose(): Promise<void> {
    if (this.form.disabled) {
      return;
    }

    await this.safeClose();
  }

  private initialiseForm(): void {
    this.form = new FormGroup(
      {
        message: new FormGroup({
          de: new FormControl('', [Validators.required]),
          fr: new FormControl('', [Validators.required]),
          en: new FormControl('', [Validators.required])
        }),
        startsAt: new FormControl('', [Validators.required, gteTodayValidator]),
        endsAt: new FormControl('', [Validators.required]),
        severity: new FormControl('', [Validators.required]),
        enabled: new FormControl(true, [Validators.required])
      },
      { validators: [dateGteCompareValidatorFactory('startsAt', 'endsAt')] }
    );

    this.form.patchValue(this.formPatchValue());
  }

  private formPatchValue(): Partial<AnnouncementListElement> {
    if (this.announcement !== undefined) {
      return this.announcement;
    }

    return {
      startsAt: this.dateInputToday,
      endsAt: this.dateInputToday
    };
  }

  private async safeClose(): Promise<void> {
    if (this.canDismissWhileDirty === true || !this.form.dirty) {
      this.closed.emit();

      return;
    }

    const discardChanges = await this.modalService.confirmUnsavedChanges();
    if (discardChanges) {
      this.closed.emit();
    }
  }
}
