angular.module('common').factory('QuestionGroupNavigationList', [
  'Store', 'Actions::QuestionGroupNavigationList', 'Selectors::Pool',
  function (Store, Actions, PoolSelectors) {

    var storageKey, QuestionGroupNavigationList = {};

    QuestionGroupNavigationList._initList = function () {
      var ids = JSON.parse(localStorage.getItem(storageKey)) || [];
      Store.dispatch(Actions.addIds(ids));
    };

    QuestionGroupNavigationList.set = function (ids) {
      Store.dispatch(Actions.addIds(ids));
      localStorage.setItem(storageKey, JSON.stringify(ids));
    };

    QuestionGroupNavigationList.get = function () {
      return Store.getState().questionGroupNavigationList.ids;
    };

    Store.subscribeOn(PoolSelectors.getCurrentPool, function (pool) {
      if (pool) {
        storageKey = 'questionGroupNavigationList-pool-' + pool.id + '-list';
        QuestionGroupNavigationList._initList();
      }
    });

    return QuestionGroupNavigationList;

  }
]);
