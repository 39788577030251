angular.module('common').factory('ExamActions', [
  'notifications',
  'ConfirmationModal',
  'ExamUnlockModal',
  'Repositories::Exam',
  function(notifications, ConfirmationModal, ExamUnlockModal, Repository) {
    var ExamActions = {};

    var unlockStrategies = {
      find: function(method) {
        if (method == 'revoke') {
          return new RevokeStrategy();
        } else if (method == 'confirmation') {
          return new ConfirmationStrategy();
        } else if (method == 'security') {
          return new SecurityStrategy();
        }
      }
    };

    function convertMessagesToHtml(messages) {
      return messages
        .map(function(message) {
          if (message.type == 'normal') {
            return '<span>' + message.text + '</span>';
          } else {
            return '<span class="text-' + message.type + '">' + message.text + '</span>';
          }
        })
        .join('<br>');
    }

    function RevokeStrategy() {}
    RevokeStrategy.prototype.run = function(data) {
      var text = convertMessagesToHtml(data.messages);
      notifications.add(text, 'alert');
    };

    function ConfirmationStrategy() {}
    ConfirmationStrategy.prototype.run = function(data, exam_id) {
      var text = convertMessagesToHtml(data.messages);
      return ConfirmationModal.show(I18n.t('exams.unlock.modal.title'), text, {
        ok: I18n.t('exams.unlock.modal.unlock_action')
      }).result.then(function() {
        return Repository.unlock(exam_id).then(function(exam) {
          notifications.add(I18n.t('exams.unlock.notifications.exam_unlocked'), 'info');
          return exam;
        });
      });
    };

    function SecurityStrategy() {}
    SecurityStrategy.prototype.run = function(data, exam_id) {
      var text = convertMessagesToHtml(data.messages);
      return ExamUnlockModal.show(I18n.t('exams.unlock.modal.title'), text, {
        ok: I18n.t('exams.unlock.modal.unlock_action')
      }).result.then(function(unlockCode) {
        return Repository.unlock(exam_id, unlockCode).then(function(exam) {
          notifications.add(I18n.t('exams.unlock.notifications.exam_unlocked'), 'info');
          return exam;
        });
      });
    };

    ExamActions.startUnlockProcess = function(exam_id) {
      return Repository.getUnlockStrategy(exam_id).then(function(unlockStrategy) {
        var strategy = unlockStrategies.find(unlockStrategy.method);
        return strategy.run(unlockStrategy, exam_id);
      });
    };

    return ExamActions;
  }
]);
