import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnnouncementSeverity } from '../../../generated/base-types';

@Component({
  templateUrl: './announcement-severity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'man-announcement-severity',
  host: { class: 'd-block d-optional' }
})
export class AnnouncementSeverityComponent {
  @Input()
  public severity?: AnnouncementSeverity;
  @Input()
  public description?: string;

  public readonly AnnouncementSeverity = AnnouncementSeverity;
}
