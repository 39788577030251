import * as Types from '../../../generated/base-types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetCategoryPositionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  direction: Types.MoveDirection;
  times?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SetCategoryPositionMutation = { __typename?: 'Mutation', setCategoryPosition?: { __typename?: 'SetCategoryPositionPayload', successful: boolean, category?: { __typename?: 'Category', id: string, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, position: number } | null, categories?: Array<{ __typename?: 'Category', id: string, name: string, nameDe?: string | null, nameFr?: string | null, nameEn?: string | null, nameIt?: string | null, position: number, dimension: { __typename?: 'Dimension', id: string } }> | null } | null };

export const SetCategoryPositionDocument = gql`
    mutation SetCategoryPosition($id: ID!, $direction: MoveDirection!, $times: Int) {
  setCategoryPosition(id: $id, direction: $direction, times: $times) {
    category {
      id
      name
      nameDe
      nameFr
      nameEn
      nameIt
      position
    }
    categories {
      id
      name
      nameDe
      nameFr
      nameEn
      nameIt
      position
      dimension {
        id
      }
    }
    successful
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetCategoryPositionGQL extends Apollo.Mutation<SetCategoryPositionMutation, SetCategoryPositionMutationVariables> {
    document = SetCategoryPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }