const { saveAs } = require('file-saver');

angular.module('common').factory('File', [
  '$window',
  function($window) {
    return {
      download: function(filePath) {
        $window.location.href = filePath;
      },
      saveAs: function(fileName, mimeType, data) {
        var blob = new Blob([data], { type: mimeType });
        saveAs(blob, fileName);
      }
    };
  }
]);
