import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'qf-replace-list-confirm-modal',
  templateUrl: './replace-list-confirm-modal.component.html',
  styleUrls: ['./replace-list-confirm-modal.component.scss']
})
export class ReplaceListConfirmModalComponent {
  @Input()
  public modalInstance: NgbModalRef;

  public exportSolution = true;

  public cancel(): void {
    this.modalInstance.dismiss();
  }

  public replace(): void {
    this.modalInstance.close(this.exportSolution);
  }
}
