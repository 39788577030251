angular.module('common').factory('FilteredList', [function () {
  var FilteredList = {
    setFilter: function (filter) {
      this.requestOptions.filter = filter;
    },
    getTotalItemsCount: function () {
      return this.metadata && this.metadata.totalFilteredItems;
    }
  };

  return {
    extend: function (baseObject) {
      angular.extend(Object.getPrototypeOf(baseObject), FilteredList);
    }
  };
}]);
