angular.module('examManagement').factory('Repositories::ExamQuestionGroup', [
  'GraphQLClient',
  function(GraphQLClient) {
    var client = new GraphQLClient('/api/v1/graphql'),
      getAllQuery =
        'query($id: Int!) {' +
        '  exam(id: $id) { id' +
        '    booklet_a {' +
        '      exam_question_groups {' +
        '        exam { id }' +
        '        id position_a position_b position_a_as_string position_b_as_string' +
        '        head_version_is_deleted is_head_version locked icebreaker complete' +
        '        question_group_height_type_de question_group_height_in_cm_de' +
        '        question_group_height_type_fr question_group_height_in_cm_fr' +
        '        question_group_height_type_en question_group_height_in_cm_en' +
        '        question_group_height_type_it question_group_height_in_cm_it' +
        '        question_group {' +
        '          id type legacy_id sequential_number source_language' +
        '          title supervisor affiliation {name} content_validation_state_de content_validation_state_fr' +
        '          content_validation_state_it content_validation_state_en structurally_complete has_images has_videos' +
        '          category_0 { name } category_1 { name } category_2 { name } category_3 { name }' +
        '          category_4 { name } category_5 { name } category_6 { name } category_7 { name }' +
        '          created_at updated_at editor { name }' +
        '          author number_of_usages past_exams { name date id } last_usage latest_statistic' +
        '          cache_last_usage_n cache_last_usage_p cache_last_usage_r cache_last_usage_eliminated' +
        '          cache_last_usage_lne' +
        '          labels { id color name } revision_status { short_name } revision_year' +
        '          has_active_tasks content_changed_since_last_usage metadata_changed_since_last_usage' +
        '          future_exams {id name}' +
        '          duplicates {sequential_number updated_at pool {name}}' +
        '          latest_statistics {' +
        '            n_min n_max p_min p_max r_min r_max lne_min lne_max' +
        '            last_usage_year_min last_usage_year_max eliminations' +
        '          }' +
        '          questions {' +
        '            literary_reference type score' +
        '          }' +
        '        }' +
        '      }' +
        '    }' +
        '    booklet_b {' +
        '      exam_question_groups {' +
        '        exam { id }' +
        '        id position_a position_b position_a_as_string position_b_as_string' +
        '        head_version_is_deleted is_head_version locked icebreaker complete' +
        '        question_group_height_type_de question_group_height_in_cm_de' +
        '        question_group_height_type_fr question_group_height_in_cm_fr' +
        '        question_group_height_type_en question_group_height_in_cm_en' +
        '        question_group_height_type_it question_group_height_in_cm_it' +
        '        question_group {' +
        '          id type legacy_id sequential_number source_language' +
        '          title supervisor affiliation {name} content_validation_state_de content_validation_state_fr' +
        '          content_validation_state_it content_validation_state_en structurally_complete has_images has_videos' +
        '          category_0 { name } category_1 { name } category_2 { name } category_3 { name }' +
        '          category_4 { name } category_5 { name } category_6 { name } category_7 { name }' +
        '          created_at updated_at editor { name }' +
        '          author number_of_usages past_exams { name date id } last_usage' +
        '          labels { id color name } revision_status { short_name } revision_year' +
        '          has_active_tasks content_changed_since_last_usage metadata_changed_since_last_usage' +
        '          future_exams {id name}' +
        '          duplicates {sequential_number updated_at pool {name}}' +
        '          latest_statistics {' +
        '            n_min n_max p_min p_max r_min r_max lne_min lne_max' +
        '            last_usage_year_min last_usage_year_max eliminations' +
        '          }' +
        '          questions {' +
        '            literary_reference type score' +
        '          }' +
        '        }' +
        '      }' +
        '    }' +
        '  }' +
        '}',
      updatePositionsQuery =
        'mutation($exam_question_groups: [ExamQuestionGroupInput!]!) {' +
        '  update_exam_question_groups(exam_question_groups: $exam_question_groups) {' +
        '    exam_question_groups {id position_a position_b}' +
        '  }' +
        '}',
      deleteQuery = 'mutation($id: Int!) { delete_exam_question_group(id: $id) {' +
        '  erroneous' +
        '} }',
      toggleIcebreakerQuery =
        'mutation($id: Int!) {' +
        '  toggle_icebreaker(id: $id) {' +
        '    exam_question_group {id icebreaker} erroneous' +
        '  }' +
        '}',
      updateVersionQuery =
        'mutation($id: Int!) {' +
        '  update_exam_question_group_version(id: $id) {' +
        '    exam_question_group {id is_head_version head_version_is_deleted} erroneous' +
        '  }' +
        '}';

    return {
      getAll: function(examId) {
        return client.query(getAllQuery, { id: examId }).then(function(response) {
          return response.data.data.exam;
        });
      },
      updatePositions: function(exam_question_groups) {
        return client
          .query(updatePositionsQuery, { exam_question_groups: exam_question_groups })
          .then(function(response) {
            return response.data.data.update_exam_question_groups;
          });
      },
      delete: function(id) {
        return client.query(deleteQuery, { id: id }).then(function(response) {
          return response.data.data.delete_exam_question_group;
        });
      },
      toggleIcebreaker: function(id) {
        return client.query(toggleIcebreakerQuery, { id: id }).then(function(response) {
          return response.data.data.toggle_icebreaker;
        });
      },
      updateVersion: function(id) {
        return client.query(updateVersionQuery, { id: id }).then(function(response) {
          return response.data.data.update_exam_question_group_version;
        });
      }
    };
  }
]);
