import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { MultiLanguageTranslateService } from 'src/app/common/services/multi-language-translate.service';

@Pipe({
  name: 'translateToLanguage'
})
export class TranslateToLanguagePipe implements PipeTransform {
  constructor(private readonly translate: MultiLanguageTranslateService) {}

  public transform(
    key: string,
    targetLanguage: string
  ): Observable<string | undefined> {
    return this.translate.get(targetLanguage, key);
  }
}
