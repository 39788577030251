import { downgradeInjectable } from '@angular/upgrade/static';
import angular from 'angular';
import { QuestionContentTransformatorService } from '../services/question-content-transformator.service';

angular
  .module('common')
  .factory(
    'QuestionContentTransformator',
    downgradeInjectable(QuestionContentTransformatorService)
  );
