angular.module('common').factory('List', ['$q', function ($q) {
  function List (resource, requestOptions) {
    this.resource = resource;
    this.requestOptions = requestOptions || {};
    this.items = [];
    this.metadata = {};
    this.busy = false;
    this.resource.options.query = {
      transform: function (listData) {
        var items = [], Resource = this;
        for(var i = 0; i < listData.items.length; i++) {
          items.push(new Resource(listData.items[i]));
        }
        listData.items = items;
        return listData;
      }
    };
  }

  List.prototype.getItemsCount = function () {
    return this.items.length;
  };

  List.prototype.getItems = function () {
    return this.items;
  };

  List.prototype.getItemAt = function (index) {
    return this.items[index];
  };

  List.prototype.getById = function (id) {
    return this.items.find(function (item) {
      return item.id == id;
    });
  };

  List.prototype.isEmpty = function () {
    return this.items.length === 0;
  };

  List.prototype.getMetadata = function () {
    return this.metadata;
  };

  List.prototype.getTotalItemsCount = function () {
    return this.metadata && this.metadata.totalItemsCount;
  };

  List.prototype.getAllIds = function () {
    return (this.metadata && this.metadata.allIds) || [];
  };

  List.prototype.allItemsLoaded = function () {
    return true;
  };

  List.prototype.onBeforeLoad = function (callback) {
    this.beforeLoadCallback = callback;
  };

  List.prototype.onAfterLoad = function (callback) {
    this.afterLoadCallback = callback;
  };

  List.prototype._emitBeforeLoadEvent = function () {
    if (this.beforeLoadCallback) {
      this.beforeLoadCallback();
    }
  };

  List.prototype._emitAfterLoadEvent = function () {
    if (this.afterLoadCallback) {
      this.afterLoadCallback();
    }
  };

  List.prototype.isBusy = function () {
    return this.busy;
  };

  List.prototype.load = function () {
    var self = this;

    if (this.busy)
      return $q.reject();

    this._emitBeforeLoadEvent();
    this.busy = true;

    return this.resource.query(this.requestOptions).then(function (data) {
      self.items = data.items;
      self.metadata = data.metadata;
      self._emitAfterLoadEvent();
      self.busy = false;
    }, function () {
      self._emitAfterLoadEvent();
      self.busy = false;
    });
  };

  List.prototype.setOrderRequestOptions = function (param, order) {
    this.requestOptions[param] = order.map(function (spec) {
      return spec.name + '.' + (spec.ascending ? 'asc' : 'desc');
    });
  };

  List.prototype.reload = List.prototype.load;

  List.prototype.add = function (item) {
    this.items.push(item);
  };

  List.prototype.insert = function (item, index) {
    this.items.splice(index, 0, item);
  };

  List.prototype.remove = function (item) {
    this.items.splice(this.items.indexOf(item), 1);
  };

  List.prototype.removeAll = function (items) {
    for (var i = 0; i < items.length; i = i + 1) {
      this.remove(items[i]);
    }
  };

  List.prototype.findById = function (id) {
    var item;

    for (var i = 0; i < this.items.length; i = i + 1) {
      item = this.items[i];
      if (item.id === id) {
        return item;
      }
    }

    return undefined;
  };

  return List;
}]);
