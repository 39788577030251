angular.module('examManagement').controller('ExamProvisionModalCtrl', [
  '$scope',
  '$uibModalInstance',
  'exam',
  'languages',
  function($scope, $uibModalInstance, exam, languages) {
    function getFirstDimension(exam) {
      return exam.presort_criteria && exam.presort_criteria[0];
    }

    $scope.I18n = I18n;
    $scope.languages = languages;
    $scope.exam = exam;
    $scope.result = {
      showBlueprintDimension: false,
      epilogue: {},
      allowQuestionComments: false
    };

    $scope.getDimensionPosition = function() {
      return getFirstDimension(exam).position;
    };

    $scope.getDimensionName = function() {
      return getFirstDimension(exam).name;
    };

    $scope.isFirstPresortCriterionOfTypeDimension = function() {
      return getFirstDimension(exam) && getFirstDimension(exam).type == 'dimension';
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

    $scope.save = function() {
      $uibModalInstance.close($scope.result);
    };
  }
]);
