(function () {
  angular.module('settings').component('subjects', {
    template: require('./subjects.html'),
    controller: SubjectsController
  });

  SubjectsController.$inject = ['Repositories::Subject',
                                'Store',
                                'Actions::Subject',
                                'Selectors::Subject',
                                'Selectors::PoolList',
                                'SingleSelection',
                                '$transitions',
                                '$state',
                                '$scope'];

  function SubjectsController(SubjectRepository, Store, Actions, Selector,
    PoolSelector, SingleSelection, $transitions, $state, $scope) {
    var itemsSubscription, transitionSubscription, poolSubscription;

    /* ---------- Controller functions ------------ */
    this.saveSubject = function (subject) {
      SubjectRepository.update(subject).then(function (response) {
        Store.dispatch(Actions.setSubject(response.subject));
      });
    };

    this.createSubject = function () {
      SubjectRepository.create(this.poolId, {name: '---'}).then(function (response) {
        Store.dispatch(Actions.addSubject(response.subject));
      });
    };

    this.deleteSubject = function (subject) {
      SubjectRepository.delete(subject).then(function () {
        Store.dispatch(Actions.deleteSubject(subject.id));
      });
    };

    this.loadSubjects = function () {
      Store.dispatch(Actions.fetchSubjectsRequest());
      SubjectRepository.getAll(this.poolId).then(function (data) {
        Store.dispatch(Actions.fetchSubjectsSuccess(data));
      }.bind(this));
    };


    /* ------------ Subscriptions -------------- */
    itemsSubscription = Store.subscribeOn(Selector.getItems, function (items) {
      this.subjects = items;
    }.bind(this));

    transitionSubscription = $transitions.onEnter({entering: 'settings.pools.base.subjects'}, function(transition, state) {
      this.poolId = parseInt(transition.params().poolId);
      this.pool = PoolSelector.getPool(Store.getState(), this.poolId);
      this.loadSubjects();
    }.bind(this));

    poolSubscription = Store.subscribeOn(PoolSelector.getItems, function () {
      this.pool = PoolSelector.getPool(Store.getState(), this.poolId);
    }.bind(this));

    $scope.$on('$destroy', function () {
      transitionSubscription();
      itemsSubscription();
      poolSubscription();
    });

    /* ----------- Initialization -------------- */
    this.poolId = parseInt($state.params.poolId);
    this.pool = PoolSelector.getPool(Store.getState(), this.poolId);
    this.I18n = I18n;
    this.loadSubjects();

    this.selection = new SingleSelection(Actions.setSelection, Actions.removeSelection,
      Selector.getSelection, Selector.getItems, 'settings.subjects.selection');
  }
})();
