export interface PathObserverOptions {
  currentPath: string;
  setStyles: (path: string) => void;
  document: Document;
}

export function initPathObserver(options: PathObserverOptions): void {
  // setStyles on initial load
  options.setStyles(options.currentPath);
  /*  init MutationObserver to watch for changes
      to the body element and the first level of children */
  new MutationObserver(() => {
    mutationObserverCallback(options);
  }).observe(options.document.body, {
    childList: true
  });
}

export function mutationObserverCallback(options: PathObserverOptions): void {
  const newPath = options.document.location.pathname;
  if (options.currentPath !== newPath) {
    options.currentPath = newPath;
    // setStyles on every path change
    options.setStyles(newPath);
  }
}
