<div
  *ngIf="pools$ | async as pools"
  class="grid h-100"
  style="grid-template-rows: auto 1fr"
>
  <div class="g-col-6 g-col-xl-8 ms-auto order-2">
    <button (click)="onCreate()" type="button" class="btn btn-dark">
      {{ 'admin.pools.actions.new' | translate }}
    </button>
  </div>
  <div class="g-col-6 g-col-xl-4 order-1">
    <man-search-input
      [shortcutActive]="true"
      placeholder="admin.pools.filter.placeholder"
      [disabled]="pools.requestState !== 'success'"
      (search)="onSearch($event)"
      (clear)="onSearchClear()"
    >
    </man-search-input>
  </div>
  <man-table-wrapper class="g-col-12 order-3" [request]="pools">
    <p-table
      #tbl
      name="Pools"
      [globalFilterFields]="filterFields"
      [value]="pools.data || []"
      [loading]="pools.requestState === 'loading'"
      [scrollable]="true"
      scrollHeight="flex"
      [virtualScroll]="true"
      [virtualScrollItemSize]="RowHeight"
      [style.--row-height.px]="RowHeight"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="p-col-fixed p-col--id">
            {{ 'admin.pools.table.id' | translate }}
          </th>
          <th>{{ 'admin.pools.table.description' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pool>
        <tr class="p-row-fixed" interactiveRow (edit)="onEdit(pool)">
          <td class="justify-content-end p-col-fixed p-col--id">
            {{ pool.id }}
          </td>
          <td>{{ pool.name }}</td>
        </tr>
      </ng-template>
    </p-table>
  </man-table-wrapper>
</div>
