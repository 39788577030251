angular.module('common', [
  'ngAnimate',
  'ngResource',
  'ngCookies',
  'ui.select',
  'ngSanitize',
  'ui.bootstrap'
]);

require('./config');

require('./actions/announcement_list');
require('./actions/connection_status');
require('./actions/panel');
require('./actions/pool');
require('./actions/question_group_details');
require('./actions/question_group_navigation_list');
require('./actions/question_group');
require('./actions/user');
require('./actions/version');

require('./components/announcement_list');
require('./components/api_access_check');
require('./components/connection_status');
require('./components/language_selection');
require('./components/user_menu');
require('./components/loader');
require('./components/version_verifier');

require('./directives/check_form_changes_before_click');
require('./directives/class_on_mouse_hover');
require('./directives/column_visibility');
require('./directives/exam_popover');
require('./directives/fixed_table_header');
require('./directives/history');
require('./directives/history_item');
require('./directives/inline_errors/base_inline_error_functionality');
require('./directives/inline_errors/btn_group_inline_error');
require('./directives/inline_errors/btn_inline_error');
require('./directives/inline_errors/inline_error');
require('./directives/inline_errors/nice_checkbox_inline_error');
require('./directives/inline_errors/pv_select_inline_error');
require('./directives/inline_input');
require('./directives/input_feedback_ui_select');
require('./directives/input_feedback');
require('./directives/loading_button');
require('./directives/nice_checkbox');
require('./directives/notifications');
require('./directives/panel');
require('./directives/pool_selection');
require('./directives/progress_of_job');
require('./directives/pv_select');
require('./directives/question_group_preview');
require('./directives/resize_panel');
require('./directives/show_on_table_row_hover');
require('./directives/sortable_table');
require('./directives/statistics');
require('./directives/statistics/response_options_multiple_solutions');
require('./directives/statistics/response_options_single_solution');
require('./directives/statistics/subgroup_statistics');
require('./directives/toggle_panel');

require('./filters/question_group_type_to_string');
require('./filters/newline_to_break');
require('./filters/number_or_placeholder');
require('./filters/translate');
require('./filters/yes_or_blank');
require('./filters/calculate_stats_range.js');
require('./filters/percentage.js');

require('./reducers/announcement_list');
require('./reducers/common');
require('./reducers/connection_status');
require('./reducers/panel');
require('./reducers/pool_selection');
require('./reducers/question_group_details');
require('./reducers/question_group_navigation_list');
require('./reducers/user');
require('./reducers/version');

require('./repositories/announcement');
require('./repositories/comment');
require('./repositories/current_user');
require('./repositories/exam');
require('./repositories/job');
require('./repositories/label');
require('./repositories/pool');
require('./repositories/question_group');
require('./repositories/question');
require('./repositories/revision_status');
require('./repositories/task_question_group');
require('./repositories/task');
require('./repositories/user');
require('./repositories/version');

require('./selectors/announcement_list');
require('./selectors/connection_status');
require('./selectors/pool');
require('./selectors/question_group_details');
require('./selectors/question_group_navigation_list');
require('./selectors/user');
require('./selectors/version');

require('./services/panel.js');
require('./services/single_selection.js');
require('./services/task.js');
require('./services/question_group_preview.js');
require('./services/list');
require('./services/list/filtered_list.js');
require('./services/list/ordered_list.js');
require('./services/question_group_details.js');
require('./services/exam_actions.js');
require('./services/exception-handler.js');
require('./services/selection_holder.js');
require('./services/visibility_helper.js');
require('./services/question_group_actions.js');
require('./services/file.js');
require('./services/unique_array.js');
require('./services/confirmation_modal.js');
require('./services/label.js');
require('./services/graphql_client.js');
require('./services/message.js');
require('./services/resource.js');
require('./services/multi_selection.js');
require('./services/table_column_visibility_functionality.js');
require('./services/redux/helpers.js');
require('./services/error_handlers/pv_select_error_handler.js');
require('./services/error_handlers/nice_checkbox_input_error_handler.js');
require('./services/error_handlers/basic_error_handler.js');
require('./services/error_handlers/btn_error_handler.js');
require('./services/error_handlers/input_error_handler.js');
require('./services/error_handlers/btn_group_error_handler.js');
require('./services/question_group.js');
require('./services/question_group_navigation_list.js');
require('./services/role.js');
require('./services/exam_unlock_modal.js');
require('./services/rails_params_serializer.js');
require('./services/diff_modal.js');
require('./services/list.js');
require('./services/table_columns.js');
require('./services/notifications.js');
require('./services/scroll_to_selection_functionality.js');
require('./services/sort_by_column.js');
require('./services/store.js');
