import { EventEmitter } from '@angular/core';
import type { UploadOutput, UploadInput, UploaderOptions } from 'ngx-uploader';

type StartUploadCallback = (name: string) => void;
type FinishUploadCallback<T> = (name: string, response: T) => void;
type ErrorCallback = (error?: string) => void;

interface NgxUploadAdapterOptions {
  uploaderOptions: {
    maxFileSize: number;
    allowedContentTypes: string[];
  };
  request: {
    url: string;
    method: 'GET' | 'POST';
    fieldName: string;
  };
}

export class NgxUploadAdapter<T> {
  public readonly input = new EventEmitter<UploadInput>();

  private _inProgress = false;
  private _percentage = 0;
  private _draggingOver = false;

  constructor(private options: NgxUploadAdapterOptions) {}

  public get uploaderOptions(): UploaderOptions {
    return {
      concurrency: 0,
      maxUploads: 100,
      ...this.options.uploaderOptions
    };
  }

  public get percentage(): number {
    return this._percentage;
  }

  public get inProgress(): boolean {
    return this._inProgress;
  }

  public get draggingOver(): boolean {
    return this._draggingOver;
  }

  public changed(event: UploadOutput): void {
    switch (event.type) {
      case 'addedToQueue': {
        if (event.file) {
          this._inProgress = true;
          this._percentage = 0;
          this.onStartUpload(event.file.name);
        }
        break;
      }

      case 'allAddedToQueue': {
        this.input.emit({
          type: 'uploadAll',
          url: this.options.request.url,
          method: this.options.request.method,
          fieldName: this.options.request.fieldName
        });
        break;
      }

      case 'uploading': {
        if (event.file) {
          this._percentage = event.file.progress.data?.percentage ?? 0;
        }
        break;
      }

      case 'done': {
        if (event.file?.responseStatus === 400) {
          this._inProgress = false;
          this._percentage = 0;
          this.onError(event.file?.response.error);
        } else if (event.file) {
          this._inProgress = false;
          this._percentage = 100;
          this.onFinishedUpload(event.file.name, event.file.response);
        }
        break;
      }

      case 'rejected': {
        this._inProgress = false;
        this._percentage = 0;
        this.onError();
        break;
      }

      case 'dragOver': {
        this._draggingOver = true;
        break;
      }

      case 'dragOut':
      case 'drop': {
        this._draggingOver = false;
        break;
      }
    }
  }

  public registerOnStartUpload(callback: StartUploadCallback): void {
    this.onStartUpload = callback;
  }

  public registerOnFinishedUpload(callback: FinishUploadCallback<T>): void {
    this.onFinishedUpload = callback;
  }

  public registerOnError(callback: ErrorCallback): void {
    this.onError = callback;
  }

  private onStartUpload: StartUploadCallback = () => void 0;
  private onFinishedUpload: FinishUploadCallback<T> = () => void 0;
  private onError: ErrorCallback = () => void 0;
}
