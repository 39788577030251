import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'man-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  animations: [
    trigger('sidePanelAnimation', [
      transition('*<=>*', [
        group([
          query('@backdrop', animateChild()),
          query('@panel', animateChild())
        ])
      ])
    ]),
    trigger('backdrop', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms linear', style({ opacity: 0.5 }))
      ]),
      transition(':leave', [animate('150ms linear', style({ opacity: 0 }))])
    ]),
    trigger('panel', [
      transition(':enter', [
        style({ transform: 'translateX(40%)', opacity: 0 }),
        animate(
          '300ms cubic-bezier(0,1,0,1)',
          style({ transform: 'translateX(0)', opacity: 1 })
        )
      ]),
      transition(':leave', [
        animate(
          '150ms cubic-bezier(0.5,1,1,1)',
          style({ transform: 'translateX(40%)', opacity: 0 })
        )
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidePanelComponent {
  @Input() public title: string | undefined;
  @Input() public closeable?: boolean;
  @Input() public large? = false;
  @Output() public closed = new EventEmitter<void>();

  @HostBinding('@sidePanelAnimation')
  @HostListener('keydown.escape')
  public onClose(): void {
    if (this.closeable !== true) return;
    this.closed.emit();
  }
}
