import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Scalars } from '../../../generated/base-types';
import { AssignExamModalComponent } from '../../common/modals/assign-exam-modal/assign-exam-modal.component';
import { AssignLabelModalComponent } from '../../common/modals/assign-label-modal/assign-label-modal.component';
import { ConfirmModalComponent } from '../../common/modals/confirm-modal/confirm-modal.component';
import { RevisionDocumentModalComponent } from '../../common/modals/revision-document-modal/revision-document-modal.component';
import { SetRevisionModalComponent } from '../../common/modals/set-revision-modal/set-revision-modal.component';
import { WriteCommentModalComponent } from '../../common/modals/write-comment-modal/write-comment-modal.component';
import { NG_MODAL_DEFAULT_OPTIONS } from '../../common/utils/ng-bootstrap-modal';
import { DeleteStatisticsGQL } from '../../question-management/components/question-group-toolbar/delete-statistics.generated';
import { ReloadAllQuestionDetails } from '../../question-management/state/question-details.actions';
import { ReloadPool } from '../../state/pool.actions';
import { QuestionGroupListElementFragment } from '../services/load-question-group-list.generated';
import { LoadQuestionGroup } from '../state/question-group.actions';

@Component({
  selector: 'qf-toolbar',
  templateUrl: './question-form-toolbar.component.html',
  host: { class: 'bs4' }
})
export class QuestionFormToolbarComponent {
  @Input()
  public poolId: Scalars['ID'];

  @Input()
  public questionGroup: QuestionGroupListElementFragment;

  constructor(
    private readonly store: Store,
    private readonly ngbModal: NgbModal,
    private readonly translateService: TranslateService,
    private readonly deleteStatisticsGQL: DeleteStatisticsGQL
  ) {}

  public openExamsModal(): Promise<void> {
    const ref = this.prepareModal(AssignExamModalComponent);

    return ref.result
      .then(_result => {
        this.store.dispatch(new LoadQuestionGroup(this.questionGroup.id));
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openCommentModal(): Promise<void> {
    const ref = this.prepareModal(WriteCommentModalComponent);

    return ref.result
      .then(_result => {
        this.store.dispatch(new ReloadAllQuestionDetails());
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openLabelModal(): Promise<void> {
    const ref = this.prepareModal(AssignLabelModalComponent);

    return ref.result
      .then(_result => {
        this.store.dispatch(new ReloadPool());
        this.store.dispatch(new LoadQuestionGroup(this.questionGroup.id));
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openRevisionStatusModal(): Promise<void> {
    const ref = this.prepareModal(SetRevisionModalComponent);

    return ref.result
      .then(_result => {
        this.store.dispatch(new ReloadPool());
        this.store.dispatch(new LoadQuestionGroup(this.questionGroup.id));
      })
      .catch(() => {
        // modal dismissed
      });
  }

  public openRevisionDocumentModal(): Promise<void> {
    const ref = this.prepareModal(RevisionDocumentModalComponent);

    return ref.result;
  }

  public openDeleteStatisticsModal(): Promise<void> {
    const title = this.translateService.instant(
      'common.delete_statistics_modal.confirmation.title'
    );
    const message = this.translateService.instant(
      'common.delete_statistics_modal.confirmation.content'
    );

    const ref = this.prepareModal(ConfirmModalComponent);

    ref.componentInstance.title = title;
    ref.componentInstance.message = message;

    return ref.result
      .then(async _result => {
        const result = await this.deleteStatisticsGQL
          .mutate({
            poolId: this.poolId,
            questionGroupIds: [this.questionGroup.id]
          })
          .toPromise();

        if (result?.data?.deleteStatistics?.successful !== true) {
          throw `Unable to delete statistics for question group ${this.questionGroup.id}`;
        }

        this.store.dispatch(new ReloadAllQuestionDetails());
      })
      .catch(() => {
        // modal dismissed
      });
  }

  private prepareModal(symbol: unknown): NgbModalRef {
    const ref = this.ngbModal.open(symbol, NG_MODAL_DEFAULT_OPTIONS);
    ref.componentInstance.modalInstance = ref;
    ref.componentInstance.questionGroups = [this.questionGroup];
    ref.componentInstance.currentPoolId = this.poolId;

    return ref;
  }
}
