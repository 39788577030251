<form
  class="page form"
  [ngClass]="{ loading: saving || loading }"
  [formGroup]="form"
  (submit)="onSave()"
  novalidate
>
  <div class="page-content">
    <input type="hidden" formControlName="id" />
    <fieldset class="form-fieldset grid">
      <legend class="form-legend g-col-4">
        {{ 'admin.pools.details.form.legend.basic' | translate }}
      </legend>
      <man-field
        labelFor="name"
        [label]="'admin.pools.details.form.label.name' | translate"
        [errors]="form | fieldErrors : 'name'"
        class="g-col-4"
      >
        <input
          id="name"
          type="text"
          formControlName="name"
          class="form-control"
          cdkInitialFocus
        />
      </man-field>
      <man-field class="form-check g-col-4">
        <input
          id="self-assessment"
          type="checkbox"
          formControlName="selfAssessment"
          class="form-check-input"
        />
        <label for="self-assessment" class="form-check-label">{{
          'admin.pools.details.form.label.self_assessment' | translate
        }}</label>
      </man-field>
    </fieldset>
    <fieldset class="form-fieldset grid">
      <legend class="form-legend g-col-4">
        {{ 'admin.pools.details.form.legend.languages' | translate }}
      </legend>
      <man-field class="g-col-4">
        <div class="d-flex flex-row gap-4">
          <div class="form-check">
            <input
              id="de-enabled"
              type="checkbox"
              formControlName="deEnabled"
              class="form-check-input"
            />
            <label for="de-enabled" class="form-check-label">{{
              'admin.pools.details.form.label.de' | translate
            }}</label>
          </div>
          <div class="form-check">
            <input
              id="fr-enabled"
              type="checkbox"
              formControlName="frEnabled"
              class="form-check-input"
            />
            <label for="fr-enabled" class="form-check-label">{{
              'admin.pools.details.form.label.fr' | translate
            }}</label>
          </div>
          <div class="form-check">
            <input
              id="it-enabled"
              type="checkbox"
              formControlName="itEnabled"
              class="form-check-input"
            />
            <label for="it-enabled" class="form-check-label">{{
              'admin.pools.details.form.label.it' | translate
            }}</label>
          </div>
          <div class="form-check">
            <input
              id="en-enabled"
              type="checkbox"
              formControlName="enEnabled"
              class="form-check-input"
            />
            <label for="en-enabled" class="form-check-label">{{
              'admin.pools.details.form.label.en' | translate
            }}</label>
          </div>
        </div>
        <ng-container *ngIf="form.touched || form.dirty">
          <div
            class="d-block invalid-feedback"
            error-message
            *ngIf="form.errors?.requireOneOf as validatorError"
          >
            {{ 'common.form.errors.requireOneOf' | translate }}
          </div>
        </ng-container>
      </man-field>
    </fieldset>
    <fieldset class="form-fieldset grid">
      <legend class="form-legend g-col-4">
        {{ 'admin.pools.details.form.legend.supervisor' | translate }}
      </legend>
      <man-field
        labelFor="responsible-firstname"
        [label]="
          'admin.pools.details.form.label.responsible_first_name' | translate
        "
        [errors]="form | fieldErrors : 'responsibleFirstName'"
        class="g-col-4 g-col-md-2"
      >
        <input
          id="responsible-firstname"
          type="text"
          formControlName="responsibleFirstName"
          class="form-control"
        />
      </man-field>
      <man-field
        labelFor="responsible-lastname"
        [label]="
          'admin.pools.details.form.label.responsible_last_name' | translate
        "
        [errors]="form | fieldErrors : 'responsibleLastName'"
        class="g-col-4 g-col-md-2"
      >
        <input
          id="responsible-lastname"
          type="text"
          formControlName="responsibleLastName"
          class="form-control"
        />
      </man-field>
      <man-field
        labelFor="responsible-email"
        [label]="'admin.pools.details.form.label.responsible_email' | translate"
        [errors]="form | fieldErrors : 'responsibleEmail'"
        class="g-col-4 g-col-xl-2"
      >
        <input
          id="responsible-email"
          type="text"
          formControlName="responsibleEmail"
          class="form-control"
        />
      </man-field>
      <man-field
        labelFor="responsible-language"
        [label]="
          'admin.pools.details.form.label.responsible_language' | translate
        "
        [errors]="form | fieldErrors : 'responsibleLanguage'"
        class="g-col-4 g-col-md-2"
        style="grid-column-start: 1"
      >
        <select
          id="responsible-language"
          formControlName="responsibleLanguage"
          class="form-select"
        >
          <option selected disabled value="">
            {{ 'common.messages.please_select' | translate }}
          </option>
          <option *ngFor="let option of languageOptions" [value]="option.value">
            {{ option.label | translate }}
          </option>
        </select>
      </man-field>
    </fieldset>
  </div>
  <div class="page-footer">
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="
        !form.dirty || !form.valid || form.disabled || saving || loading
      "
    >
      <ng-container *ngIf="!saving && !loading; else loader">
        {{ 'common.actions.save' | translate }}
      </ng-container>
    </button>
  </div>
</form>
<ng-template #loader>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
  {{ 'common.actions.loading' | translate }}
</ng-template>
