import { Pipe, PipeTransform } from '@angular/core';

const BASE_CHARACTER = 'A'.codePointAt(0);

@Pipe({
  name: 'indexAsCharacter'
})
export class IndexAsCharacterPipe implements PipeTransform {
  public transform(index: number): string {
    return String.fromCodePoint((BASE_CHARACTER as number) + index);
  }
}
