import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { QuestionGroupFilterComponent } from '../components/question-group-filter/question-group-filter.component';

angular
  .module('questionManagement')
  .directive(
    'qmGroupFilter',
    downgradeComponent({ component: QuestionGroupFilterComponent })
  );
